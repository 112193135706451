<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.generic.settings"
    :subTitle="texts.navigationItems.brochures.settings.description"
  >
    <Loader v-if="loading" data-testid="loading-configuration"></Loader>
    <BrochureConfigurationForm
      v-else-if="configuration"
      :formValues="convertToFormValues(configuration)"
      :selectedFormIds="selectedFormIds"
      @submit="submitUpdate"
    >
      <ButtonSubmit :loading="actionLoading" />
      <ButtonCancel @click="visibleInternal = false" />
    </BrochureConfigurationForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import Loader from "@/components/common/loader/Loader.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";

import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import {
  BrochureConfigurationFormValues,
  convertToDTO,
  convertToFormValues,
} from "./BrochureConfigurationForm.types";
import BrochureConfigurationForm from "./BrochureConfigurationForm.vue";
import { BrochureConfigurationDto } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{
  visible: boolean;
  configuration?: BrochureConfigurationDto;
  selectedFormIds?: string[];
  loading?: boolean;
}>();

const emit = defineEmits(["update:visible", "update:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

// Update BrochureConfiguration
const actionLoading = ref<boolean>(false);
const submitUpdate = (values: BrochureConfigurationFormValues) => {
  if (!props.configuration) {
    throw new Error("Update values lacks configuration");
  }

  actionLoading.value = true;
  eduConfigurationServiceClient
    .updateBrochureConfiguration(props.configuration.id, convertToDTO(values))
    .then(() => {
      Notify.success(texts.navigationItems.brochures.settings.edit.success);
      visibleInternal.value = false;
      emit("update:succeeded");
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.brochures.settings.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
