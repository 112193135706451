import { Culture } from "@/enums";

export interface MetadataFormValues {
  localizations: MetadataFormValuesLocalization[];
  sequenceNumber?: number;
}

type MetadataFormValuesLocalization = {
  locale: Culture;
  name: string;
};

interface MetadataDTO {
  localizations: MetadataDTOLocalization[];
  sequenceNumber?: number;
}

type MetadataDTOLocalization = {
  locale: string;
  name: string;
};

export function convertToFormValues(dto: MetadataDTO): MetadataFormValues {
  return {
    localizations: dto.localizations as MetadataFormValuesLocalization[],
    sequenceNumber: dto.sequenceNumber,
  };
}
