import { DateTime } from "luxon";

export interface RecruitmentYearDTO {
  dayStart: number;
  monthStart: number;
  dayEnd: number;
  monthEnd: number;
}

export interface RecruitmentYearDates {
  start: DateTime;
  end: DateTime;
}

export class RecruitmentYear {
  dayStart: number;
  monthStart: number;
  dayEnd: number;
  monthEnd: number;

  constructor(dto: RecruitmentYearDTO) {
    this.dayStart = dto.dayStart;
    this.monthStart = dto.monthStart;
    this.dayEnd = dto.dayEnd;
    this.monthEnd = dto.monthEnd;
  }

  toCurrentRecruitmentYearDates(): RecruitmentYearDates {
    const currentDate = DateTime.now();

    const startDate = this.toStartDate(currentDate.year);

    if (currentDate > startDate) {
      // If currentDate is after startDate
      return {
        start: startDate,
        end: this.toEndDate(startDate.year),
      };
    } else {
      // If today is before startDate
      return {
        start: this.toStartDate(currentDate.year - 1),
        end: this.toEndDate(currentDate.year - 1),
      };
    }
  }

  toPreviousRecruitmentYearDates(): RecruitmentYearDates {
    const current = this.toCurrentRecruitmentYearDates();

    return {
      start: current.start.minus({ year: 1 }),
      end: current.end.minus({ year: 1 }),
    };
  }

  toStartDate(startYear: number): DateTime {
    return DateTime.fromObject({
      year: startYear,
      month: this.monthStart,
      day: this.dayStart,
    });
  }

  toEndDate(startYear: number): DateTime {
    const endYear = startYear + 1;

    return DateTime.fromObject({
      year: endYear,
      month: this.monthEnd,
      day: this.dayEnd,
    });
  }
}

export interface RecruitmentYearEndDTO {
  dayEnd: number;
  monthEnd: number;
}

export class RecruitmentYearEnd {
  dayEnd: number;
  monthEnd: number;

  constructor(dto: RecruitmentYearEndDTO) {
    this.dayEnd = dto.dayEnd;
    this.monthEnd = dto.monthEnd;
  }
}
