<template>
  <FormLayout @submit.prevent="submitForm">
    <FormFieldArrayHelper
      v-slot="{ key, field }"
      :fieldId="fieldId"
      :fields="localizations"
    >
      <FormFieldTextInput
        :id="`${key}.name`"
        :label="`${txtSessionGroup.name} (${
          texts.enums.culture[field.locale]
        })`"
      />
    </FormFieldArrayHelper>

    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { Culture } from "@/enums";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { SessionGroupFormValues } from "./SessionGroupForm.types";
import { watch } from "vue";
import {
  normalizeLocalizations,
  useLocalizationFields,
} from "@/models/helpers/localizable";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import texts from "@/utils/texts";
import FormLayout from "@/components/common/form/FormLayout.vue";

const props = defineProps<{
  formValues?: SessionGroupFormValues;
}>();

const emit = defineEmits<{
  (e: "submit", values: SessionGroupFormValues): void;
}>();

const txtSessionGroup = texts.models.sessionGroup;

const form = useForm<SessionGroupFormValues>({
  validationSchema: yup.object({
    localizations: yup.array().of(
      yup.object({
        locale: yup.mixed<Culture>().required(),
        name: yup.string().requiredWhenMainLocale(),
      }),
    ),
  }),
});

const { fieldId, fields: localizations } = useLocalizationFields();

const submitForm = async () => {
  await form.validate().then((result) => {
    if (result.valid) {
      emit("submit", form.values);
    }
  });
};

watch(
  () => props.formValues,
  (values) => {
    const localizations = normalizeLocalizations(values?.localizations);
    form.setValues({ ...values, localizations });
  },
  { immediate: true },
);
</script>
