<template>
  <div
    class="flex w-full flex-col rounded-lg border border-gray-200 bg-white shadow-md"
  >
    <div
      class="flex items-center justify-between gap-3 rounded-t-lg border-b border-b-gray-200 px-3 py-3"
    >
      <div class="flex items-center gap-3">
        <div class="h-3 w-3 rounded-full border"></div>
        <div class="h-3 w-3 rounded-full border"></div>
        <div class="h-3 w-3 rounded-full border"></div>
      </div>
      <slot name="actions"></slot>
    </div>
    <slot name="banner"></slot>
    <div class="flex w-full flex-col items-center gap-6 px-3 py-6">
      <slot></slot>
    </div>
    <div v-if="$slots.footer" class="border-b"></div>
    <div v-if="$slots.footer" class="w-full px-3 py-3">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
