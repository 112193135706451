<template>
  <div class="flex items-center gap-8">
    <TextInputWithCopy :modelValue="affLink.uri" class="flex-1" />
    <DropDown
      data-testid="actions"
      :label="texts.actions.actions"
      :origin="DropdownOrigin.TopRight"
      :loading="isDownloading || isSendingReportMail"
    >
      <DropdownItem
        :label="
          texts.navigationItems.organize.activity.affiliateLinks.mail.button
        "
        :href="affiliateMailToHref(affLink)"
        icon="send"
      >
      </DropdownItem>
      <DropdownItem
        :label="
          texts.navigationItems.organize.activity.affiliateLinks.reportMail
            .button
        "
        icon="send"
        @click="sendReportMailLink(affLink.affiliateId)"
      >
      </DropdownItem>
      <DropdownItem
        :label="
          texts.navigationItems.organize.activity.affiliateLinks.downloadButton
        "
        icon="download"
        @click="downloadReport(affLink.affiliateId)"
      ></DropdownItem>
    </DropDown>
  </div>
</template>

<script setup lang="ts">
import DropDown from "@/components/common/dropdown/Dropdown.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import { ref } from "vue";
import texts from "@/utils/texts";
import TextInputWithCopy from "@/components/common/text-input/TextInputWithCopy.vue";
import { IAffiliateLinkOverviewItemDto } from "@/lib/eduConfigurationServiceClient";
import {
  affiliateMailToHref,
  downloadAffiliateLinkReport,
  sendAffiliateLinkRegistrationsReportMail,
} from "@/views/organize/activities/activity/affiliate-links/AffiliateLink.types";

const props = defineProps<{
  affLink: IAffiliateLinkOverviewItemDto;
  activityId: string;
}>();

const isDownloading = ref(false);
const downloadReport = async (affiliateId: string) => {
  if (isDownloading.value) return;
  isDownloading.value = true;
  await downloadAffiliateLinkReport(props.activityId, affiliateId).finally(
    () => {
      isDownloading.value = false;
    },
  );
};

const isSendingReportMail = ref(false);
const sendReportMailLink = async (affiliateId: string) => {
  if (isSendingReportMail.value) return;
  isSendingReportMail.value = true;
  await sendAffiliateLinkRegistrationsReportMail(
    props.activityId,
    affiliateId,
  ).finally(() => {
    isSendingReportMail.value = false;
  });
};
</script>
