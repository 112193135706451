<!-- 
  Modeled after https://tailwindui.com/components/application-ui/navigation/steps#component-1f0d948b6dc88b825bef07f7503e3087
-->
<template>
  <nav aria-label="Progress">
    <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
      <li v-for="(step, index) in steps" :key="index" class="md:flex-1">
        <NavigationStep
          v-bind="step"
          :status="getStatus(index)"
        ></NavigationStep>
      </li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import NavigationStep from "@/components/common/navigation/NavigationStep.vue";
import {
  NavigationStep as Step,
  NavigationStepStatus,
} from "./NavigationStep.types";

const props = defineProps<{
  steps: Step[];
  currentStepIndex: number;
}>();

const getStatus = (index: number) => {
  if (index < props.currentStepIndex) {
    return NavigationStepStatus.Complete;
  } else if (index === props.currentStepIndex) {
    return NavigationStepStatus.Current;
  } else {
    return NavigationStepStatus.Upcoming;
  }
};
</script>
