import { IconSize } from "@/components/common/icon/Icon.types";

export enum TagSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}
export const toIconSize = {
  [TagSize.xs]: IconSize.xs,
  [TagSize.sm]: IconSize.sm,
  [TagSize.md]: IconSize.md,
  [TagSize.lg]: IconSize.lg,
  [TagSize.xl]: IconSize.xl,
};
