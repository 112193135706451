<template>
  <SlideOver
    v-model:visible="visibleInternal"
    data-testid="create-slide-over"
    :title="texts.models.session.title"
  >
    <SessionForm
      :activityStartDateTime="activity.startDateTime"
      :activityEndDateTime="activity.endDateTime"
      :isActivityOnline="activity.isOnline"
      :groups="groups"
      :locations="locations"
      :types="types"
      :registrationLinks="registrationLinks"
      :studyProgramIds="activity.studyProgramIds"
      @submit="submitCreate"
    >
      <ButtonSubmit :loading="actionLoading" class="xl:col-start-2">
        {{ texts.actions.create }}
        <span class="text-sm font-light">{{ texts.actions.createSuffix }}</span>
      </ButtonSubmit>
      <ButtonCancel @click="visibleInternal = false"></ButtonCancel>
    </SessionForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import logger from "@/plugins/logger";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import Notify from "@/utils/notify";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import texts from "@/utils/texts";
import SessionForm from "./SessionForm.vue";
import { convertToDto, SessionFormValues } from "./SessionForm.types";
import SessionGroup from "@/models/sessionGroup";
import {
  ActivityDTO,
  ActivityLocationLocalizedDTO,
  LocalizedSessionTypeDto,
  RegistrationLinkWithRegistrationCountDTO,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";

const props = defineProps<{
  visible: boolean;
  activity: ActivityDTO;
  groups: SessionGroup[];
  locations: ActivityLocationLocalizedDTO[];
  types: LocalizedSessionTypeDto[];
  registrationLinks: RegistrationLinkWithRegistrationCountDTO[];
}>();

const emit = defineEmits(["update:visible", "create:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const actionLoading = ref<boolean>(false);
const submitCreate = async (values: SessionFormValues) => {
  actionLoading.value = true;
  try {
    await eduConfigurationServiceClient.createSession(
      props.activity.id,
      convertToDto(values),
    );
    Notify.success(texts.notifications.create.successCached, [
      texts.models.session.title,
    ]);
    visibleInternal.value = false;
    emit("create:succeeded");
  } catch (e) {
    Notify.failure(texts.notifications.create.failure, [
      texts.models.session.title,
    ]);
    logger.error(e as Error);
  } finally {
    actionLoading.value = false;
  }
};
</script>
