<template>
  <FormLayout @submit="submit">
    <FormFieldArrayHelper
      v-slot="{ field, key }"
      :fieldId="fieldId"
      :fields="localizations"
    >
      <FormFieldTextInput
        :id="`${key}.name`"
        :label="`${texts.models.activity.name} (${
          texts.enums.culture[field.locale]
        })`"
        :displayMode="FormFieldDisplayMode.Row"
        :data-testid="`name_${field.locale}`"
      />
    </FormFieldArrayHelper>
    <FormFieldSelect
      id="studyProgramModeId"
      :label="texts.models.studyProgramMode.title"
      :displayMode="FormFieldDisplayMode.Row"
      data-testid="studyProgramModeId"
      :items="modeSelectOptions"
      allowUnset
    />
    <FormFieldSelect
      id="studyProgramDepartmentId"
      :label="texts.models.studyProgramDepartment.title"
      :displayMode="FormFieldDisplayMode.Row"
      data-testid="studyProgramDepartmentId"
      :items="departmentSelectOptions"
      allowUnset
    />
    <FormFieldSelect
      id="studyProgramLevelId"
      :label="texts.models.studyProgramLevel.title"
      :displayMode="FormFieldDisplayMode.Row"
      data-testid="studyProgramLevelId"
      :items="levelSelectOptions"
      allowUnset
    />
    <FormFieldSelect
      id="programLanguageId"
      :label="texts.models.studyProgramLanguage.title"
      :displayMode="FormFieldDisplayMode.Row"
      data-testid="programLanguageId"
      :items="languageSelectOptions"
      allowUnset
    />
    <ComboboxField
      :loading="loadingLocations"
      formFieldId="studyProgramLocationIds"
      :label="texts.models.studyProgramLocation.title"
      :displayMode="FormFieldDisplayMode.Row"
      :options="locations"
      :formatOptionDisplayString="locationsOptionDisplayFormatter"
      :searchFilter="locationsSearchFilter"
    />
    <ComboboxField
      formFieldId="studyProgramAreaOfInterestIds"
      :displayMode="FormFieldDisplayMode.Row"
      :label="texts.models.studyProgramAreaOfInterest.title"
      :options="areasOfInterest"
      :loading="loadingAreasOfInterest"
      :formatOptionDisplayString="areaOfInterestOptionDisplayFormatter"
      :searchFilter="areasOfInterestSearchFilter"
    />
    <FormFieldTextInput
      id="externalReference"
      :label="texts.models.studyProgram.externalReference"
      :displayMode="FormFieldDisplayMode.Row"
      data-testid="externalReference"
    />
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { PropType, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import logger from "@/plugins/logger";

import ComboboxField from "@/components/common/combobox/ComboboxField.vue";

import { useForm } from "vee-validate";
import * as yup from "yup";
import { StudyProgramFormValues } from "./StudyProgramForm.types";
import { SelectOption } from "@/components/common/select/SelectOption";

import {
  getStudyProgramAreasOfInterest,
  getStudyProgramDepartments,
  getStudyProgramLevels,
  getStudyProgramLocations,
  getStudyProgramModes,
} from "@/services/study-program.service";
import { StudyProgramLocation } from "@/models/study-program-location";
import { StudyProgramDepartment } from "@/models/study-program-department";
import { StudyProgramMode } from "@/models/study-program-mode";
import { StudyProgramLevel } from "@/models/study-program-level";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import {
  normalizeLocalizations,
  useLocalizationFields,
} from "@/models/helpers/localizable";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormLayout from "@/components/common/form/FormLayout.vue";
import settings from "@/store/context/settings.context";

const emit = defineEmits(["submit"]);

const props = defineProps({
  formValues: {
    type: Object as PropType<StudyProgramFormValues>,
    required: false,
    default: undefined,
  },
});

// Store and router setup
const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const languageSelectOptions = computed<Array<SelectOption>>(() =>
  settings.availableLanguages.map((at) => ({
    label: at.name,
    value: at.id,
  })),
);

// Locations data
const loadingLocations = ref(true);
const errorLoadingLocations = ref(false);
const locations = ref<StudyProgramLocation[]>([]);
const locationsSearchFilter = (location: StudyProgramLocation, query: string) =>
  location.name.toLowerCase().includes(query.toLowerCase());
const locationsOptionDisplayFormatter = (location: StudyProgramLocation) =>
  location.name;
getStudyProgramLocations()
  .then((res) => (locations.value = res))
  .catch((e) => {
    errorLoadingLocations.value = true;
    logger.log(e);
  })
  .finally(() => (loadingLocations.value = false));

// Areas Of Interest data
const loadingAreasOfInterest = ref(true);
const errorLoadingAreasOfInterest = ref(false);
const areasOfInterest = ref<StudyProgramLocation[]>([]);
const areasOfInterestSearchFilter = (
  areaOfInterest: StudyProgramLocation,
  query: string,
) => areaOfInterest.name.toLowerCase().includes(query.toLowerCase());
const areaOfInterestOptionDisplayFormatter = (
  areaOfInterest: StudyProgramLocation,
) => areaOfInterest.name;
getStudyProgramAreasOfInterest()
  .then((res) => (areasOfInterest.value = res))
  .catch((e) => {
    errorLoadingAreasOfInterest.value = true;
    logger.log(e);
  })
  .finally(() => (loadingAreasOfInterest.value = false));

// Departments data
const loadingDepartments = ref(true);
const errorLoadingDepartments = ref(false);
const departments = ref<StudyProgramDepartment[]>([]);
const departmentSelectOptions = computed<Array<SelectOption>>(() =>
  departments.value.map((department) => ({
    label: department.name,
    value: department.id,
  })),
);
getStudyProgramDepartments()
  .then((res) => (departments.value = res))
  .catch((e) => {
    errorLoadingDepartments.value = true;
    logger.log(e);
  })
  .finally(() => (loadingDepartments.value = false));

// Mode data
const loadingModes = ref(true);
const errorLoadingModes = ref(false);
const modes = ref<StudyProgramMode[]>([]);
const modeSelectOptions = computed<Array<SelectOption>>(() =>
  modes.value.map((mode) => ({
    label: mode.name,
    value: mode.id,
  })),
);
getStudyProgramModes()
  .then((res) => (modes.value = res))
  .catch((e) => {
    errorLoadingModes.value = true;
    logger.log(e);
  })
  .finally(() => (loadingModes.value = false));

// Level data
const loadingLevels = ref(true);
const errorLoadingLevels = ref(false);
const levels = ref<StudyProgramLevel[]>([]);
const levelSelectOptions = computed<Array<SelectOption>>(() =>
  levels.value.map((level) => ({
    label: level.name,
    value: level.id,
  })),
);
getStudyProgramLevels()
  .then((res) => (levels.value = res))
  .catch((e) => {
    errorLoadingLevels.value = true;
    logger.log(e);
  })
  .finally(() => (loadingLevels.value = false));

// Form data
const validationSchema = yup.object({
  localizations: yup.array().of(
    yup.object({
      locale: yup.mixed<Culture>().required(),
      name: yup.string().requiredWhenMainLocale(),
    }),
  ),
  programLanguageId: yup.string(),
  studyProgramLocationIds: yup.array(),
  studyProgramAreaOfInterestIds: yup.array(),
  studyProgramDepartmentId: yup.string(),
  studyProgramModeId: yup.string(),
  studyProgramLevelId: yup.string(),
  //externalReference: yup.string().max(128),
  externalReference: yup
    .string()
    .max(128)
    .test(
      "unique-external-reference",
      texts.navigationItems.manage.settings.studyProgramSettings.form
        .externalReferenceIsNotUnique,
      function (value) {
        if (!value) {
          return true;
        }
        const { id } = this.parent;
        if (
          settings.allStudyPrograms.some(
            (program) =>
              program.externalReference === value && program.id !== id,
          )
        ) {
          return false;
        }
        return true;
      },
    ),
});

const form = useForm<StudyProgramFormValues>({
  validationSchema,
});

const { fieldId, fields: localizations } = useLocalizationFields();

watch(
  () => props.formValues,
  (values) => {
    const localizations = normalizeLocalizations(values?.localizations);
    form.setValues({ ...values, localizations });
  },
  { immediate: true },
);

const submit = form.handleSubmit((values) => emit("submit", values));
</script>
