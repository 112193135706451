<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-4 py-8">
    <div class="flex flex-wrap items-center justify-between gap-4">
      <div class="flex items-center gap-4">
        <Search v-model="search" class="w-72" />
        <span class="font-semibold text-deepteal-700"
          >{{ filteredTemplates.length }} {{ textsTemplate.plural }}</span
        >
      </div>

      <div class="flex gap-4 justify-self-end xl:col-span-3">
        <MailTemplateTypeFilter v-model="filterType" :templates="templates" />
        <MailTemplateAddButton />
      </div>
    </div>
    <List>
      <template v-slot:header>
        <ListItemColumn :flex="0"><div class="h-6 w-6"></div></ListItemColumn>
        <ListItemColumn :flex="2">{{ textsTemplate.name }}</ListItemColumn>
        <ListItemColumn hide>{{ textsTemplate.type }}</ListItemColumn>
        <ListItemColumn :flex="0"><div class="h-6 w-6"></div></ListItemColumn>
      </template>
      <MailTemplateItem
        v-for="template in filteredTemplates"
        :key="template.id"
        :template="template"
        @copy="clickCopy(template)"
        @delete="clickDelete(template)"
      />
    </List>
  </Page>
  <MailingTemplateDeleteModal
    v-if="mailingTemplateId"
    v-model:visible="deleteMailingTemplateModalVisible"
    :mailingTemplateId="mailingTemplateId"
    @delete:succeeded="initialize"
  />
  <EmailTemplateDeleteModal
    v-if="emailTemplateId"
    v-model:visible="deleteEmailTemplateModalVisible"
    :emailTemplateId="emailTemplateId"
    @delete:succeeded="initialize"
  ></EmailTemplateDeleteModal>
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import Search from "@/components/common/search/Search.vue";
import EmailTemplate from "@/models/emailTemplates";
import MailingTemplate from "@/models/mailing-templates";
import { MailTemplate, MailTemplateType } from "@/models/template";
import logger from "@/plugins/logger";
import {
  copyEmailTemplate,
  getEmailTemplates,
} from "@/services/emailTemplates.service";
import {
  copyMailingTemplate,
  getMailingTemplates,
} from "@/services/mailing-templates.service";
import MailTemplateItem from "@/views/settings/emails/components/MailTemplateItem.vue";
import { computed, ref, watch } from "vue";
import MailTemplateAddButton from "@/views/settings/emails/components/MailTemplateAddButton.vue";
import texts from "@/utils/texts";
import MailTemplateTypeFilter from "@/views/settings/emails/components/MailTemplateTypeFilter.vue";
import Notify from "@/utils/notify";
import MailingTemplateDeleteModal from "@/views/settings/emails/components/mailing-template/MailingTemplateDeleteModal.vue";
import EmailTemplateDeleteModal from "@/views/settings/emails/components/email-template/EmailTemplateDeleteModal.vue";
import { useRouter } from "vue-router";
import { RouteNamesSettings } from "@/router/routeNames";
import { EmailTemplateType, MailingType } from "@/enums";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { loadEmailTemplates } from "@/store/context/settings.context";

const textsTemplate = texts.models.mailingTemplate;

const loading = ref(false);
const error = ref(false);

// Get Email-templates
const mailingTemplates = ref<MailingTemplate[]>([]);
const emailTemplates = ref<EmailTemplate[]>([]);

const templates = computed<MailTemplate[]>(() => {
  return [
    ...mailingTemplates.value.map((mailingTemplate) =>
      MailTemplate.fromMailingTemplate(mailingTemplate),
    ),
    ...emailTemplates.value.map((emailTemplate) =>
      MailTemplate.fromEmailTemplate(emailTemplate),
    ),
  ].sort((a, b) => {
    const aTypeText =
      texts.enums.mailingType[a.type as MailingType] ||
      texts.enums.emailTemplateType[a.type as EmailTemplateType];
    const bTypeText =
      texts.enums.mailingType[b.type as MailingType] ||
      texts.enums.emailTemplateType[b.type as EmailTemplateType];

    return aTypeText.localeCompare(bTypeText) || a.name.localeCompare(b.name);
  });
});

const search = ref<string | undefined>();
const filterType = ref<MailTemplateType>();

const filteredTemplates = computed<MailTemplate[]>(() =>
  templates.value
    .filter(
      (template) =>
        !search.value ||
        template.name?.toLowerCase().includes(search.value.toLowerCase()),
    )
    .filter(
      (template) => !filterType.value || template.type === filterType.value,
    ),
);

const initialize = () => {
  loading.value = true;
  Promise.all([getMailingTemplates(), getEmailTemplates()])
    .then(([mailingTemplatesResponse, emailTemplatesResponse]) => {
      mailingTemplates.value = mailingTemplatesResponse;
      emailTemplates.value = emailTemplatesResponse;
    })
    .catch((e) => {
      error.value = true;
      logger.log(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

initialize();

const router = useRouter();

const clickCopy = async (template: MailTemplate) => {
  if (template.type in MailingType) {
    return clickCopyMailingTemplate(template.id);
  }
  if (template.type in EmailTemplateType) {
    return clickCopyEmailTemplate(template.id);
  }
};

const clickDelete = async (template: MailTemplate) => {
  if (template.type in MailingType) {
    return clickDeleteMailingTemplate(template.id);
  }
  if (template.type in EmailTemplateType) {
    return clickDeleteEmailTemplate(template.id);
  }
};

// Copy a MailingTemplate
const clickCopyMailingTemplate = async (id: string) => {
  try {
    const response = await copyMailingTemplate(id);
    Notify.success(texts.notifications.copy.success, [
      texts.models.mailing.title,
    ]);
    await router.push({
      name: RouteNamesSettings.EMAIL_EDIT_MAILING_TEMPLATE,
      params: { id: response.id },
    });
  } catch (e) {
    Notify.failure(texts.notifications.copy.failure, [
      texts.models.mailing.title,
    ]);
    logger.error(e);
  }
};

// Delete a MailingTemplate
const mailingTemplateId = ref<string>();
const deleteMailingTemplateModalVisible = ref(false);
const clickDeleteMailingTemplate = async (id: string) => {
  mailingTemplateId.value = id;
  deleteMailingTemplateModalVisible.value = true;
};

watch(deleteMailingTemplateModalVisible, (newValue) => {
  if (!newValue) mailingTemplateId.value = undefined;
});

// Copy a EmailTemplate
const clickCopyEmailTemplate = async (id: string) => {
  try {
    const response = await copyEmailTemplate(id);
    await loadEmailTemplates();
    Notify.success(texts.notifications.copy.success, [
      texts.models.mailing.title,
    ]);
    await router.push({
      name: RouteNamesSettings.EMAIL_EDIT_EMAIL_TEMPLATE,
      params: { id: response.id },
    });
  } catch (e) {
    Notify.failure(texts.notifications.copy.failure, [
      texts.models.mailing.title,
    ]);
    logger.error(e);
  }
};

// Delete a EmailTemplate
const emailTemplateId = ref<string>();
const deleteEmailTemplateModalVisible = ref(false);
const clickDeleteEmailTemplate = (id: string) => {
  emailTemplateId.value = id;
  deleteEmailTemplateModalVisible.value = true;
};

watch(deleteEmailTemplateModalVisible, (newValue) => {
  if (!newValue) emailTemplateId.value = undefined;
});
</script>
