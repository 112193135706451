<template>
  <template v-if="edit">
    <div>
      <Combobox
        :id="fieldId"
        v-model="value"
        :name="fieldId"
        :options="items"
        :formatOptionDisplayString="optionFormatter"
        :searchFilter="searchFilter"
        :valid="!errorMessage"
      >
      </Combobox>
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <span>
      {{
        props.context.preEducationLevels
          .filter((level) => criterion.preEducationLevelIds?.includes(level.id))
          .map(
            (level) =>
              level.localizations.find((l) => l.locale === culture)?.name ??
              level.localizations[0].name,
          )
          .join(", ")
      }}
    </span>
  </template>
</template>

<script setup lang="ts">
import Combobox from "@/components/common/combobox/Combobox.vue";
import { RootState } from "@/store/index";
import { Culture } from "@/enums";
import { computed } from "vue";
import { useStore } from "vuex";
import * as yup from "yup";
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import { useField } from "vee-validate";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";

const store = useStore<RootState>();
const culture = store.getters["cultureStore/active"] as Culture;

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().preEducationLevelIds;

const { value, errorMessage } = useField<
  typeof props.criterion.preEducationLevelIds
>(fieldId, yup.array().required().min(1), {
  initialValue: [...(props.criterion.preEducationLevelIds ?? [])], // Shallow-copy to decouple field-array from the original
});

const items = computed(() =>
  props.context.preEducationLevels.map((level) => ({
    id: level.id,
    name:
      level.localizations.find((l) => l.locale === culture)?.name ??
      level.localizations[0].name,
  })),
);

const searchFilter = (item: { id: string; name: string }, query: string) =>
  item.name.toLowerCase().includes(query.toLowerCase());

const optionFormatter = (answer: { id: string; name: string }) => answer.name;
</script>
