<template>
  <SlideOver
    v-model:visible="slideOverVisible"
    :title="texts.navigationItems.account.create.title"
  >
    <AccountForm @submit="onSubmit">
      <ButtonSubmit data-testid="submitForm" :loading="actionLoading" />
      <Button
        :color="Color.White"
        :label="texts.actions.cancel"
        class="mr-4"
        @click="cancelUpdate"
      ></Button>
    </AccountForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import Button from "@/components/common/button/Button.vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Color, Culture } from "@/enums";
import AccountForm from "./AccountForm.vue";
import { AccountCreateDTO } from "@/models/account";
import { createAccount } from "@/services/account.service";
import logger from "@/plugins/logger";
import { AccountFormValues } from "./AccountForm.types";
import Notify from "@/utils/notify";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";

const emit = defineEmits<{
  "update:visible": [value: boolean];
}>();

const props = defineProps<{
  visible: boolean;
}>();

const slideOverVisible = computed({
  get() {
    return props.visible;
  },
  set(value) {
    emit("update:visible", value);
  },
});

// Store and router setup
const store = useStore<RootState>();

const actionLoading = ref<boolean>(false);

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const onSubmit = (values: AccountFormValues) => {
  const createdAccount: AccountCreateDTO = {
    name: values.name,
    email: values.username,
    roles: values.roles,
    accountType: values.accountType,
    studyProgramIds: values.studyProgramIds,
    activityTypeIds: values.activityTypeIds,
    cantViewPersonalData: values.cantViewPersonalData,
    cantViewAndSendMailings: values.cantViewAndSendMailings,
  };

  actionLoading.value = true;

  return createAccount(createdAccount)
    .then(() => {
      Notify.success(texts.navigationItems.account.create.success);
      slideOverVisible.value = false;

      store.dispatch("accountStore/loadAccounts");
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.account.create.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const cancelUpdate = (): void => {
  slideOverVisible.value = false;
};
</script>
