import { MailingType } from "@/enums";
import {
  MailingTemplateCreateDTO,
  MailingTemplateExtended,
  MailingTemplateUpdateDTO,
} from "@/models/mailing-templates";
import { SaveResponse } from "@/utils/bee/bee-instance";

export enum MailingTemplateFormKeys {
  name = "name",
}

export interface MailingTemplateFormValues {
  [MailingTemplateFormKeys.name]: string;
}

export function toCreateDto(
  formValues: MailingTemplateFormValues,
  mailingType: MailingType,
  saveResponse: SaveResponse,
) {
  const createDto: MailingTemplateCreateDTO = {
    name: formValues.name,
    configuration: saveResponse[0],
    mailingType,
  };
  return createDto;
}

export function toUpdateDto(
  formValues: MailingTemplateFormValues,
  id: string,
  mailingType: MailingType,
  saveResponse: SaveResponse,
) {
  const updateDto: MailingTemplateUpdateDTO = {
    id,
    mailingType,
    name: formValues.name,
    configuration: saveResponse[0],
  };
  return updateDto;
}

export function toFormValues(mailingTemplate: MailingTemplateExtended) {
  const formValues: MailingTemplateFormValues = {
    name: mailingTemplate.name,
  };
  return formValues;
}
