import { MailingType } from "@/enums";
import MailingTemplate, {
  MailingTemplateCreateDTO,
  MailingTemplateCopyDTO,
  MailingTemplateDTO,
  MailingTemplateExtended,
  MailingTemplateExtendedDTO,
  MailingTemplateUpdateDTO,
} from "@/models/mailing-templates";
import HttpClient from "@/utils/httpClient";

export const getMailingTemplates = async (): Promise<MailingTemplate[]> => {
  return await HttpClient.educonfig
    .get<MailingTemplateDTO[]>(`mailings/templates`)
    .then((response) => response.data.map((dto) => new MailingTemplate(dto)));
};

export const getMailingTemplatesByType = async (
  type: MailingType,
): Promise<MailingTemplate[]> => {
  const templates = await getMailingTemplates();
  return templates.filter((t) => t.mailingType === type);
};

export const getMailingTemplate = async (
  templateId: string,
): Promise<MailingTemplateExtended> => {
  return await HttpClient.educonfig
    .get<MailingTemplateExtendedDTO>(`mailings/templates/${templateId}`)
    .then((response) => new MailingTemplateExtended(response.data));
};

export const createMailingTemplate = async (
  dto: MailingTemplateCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(`mailings/templates`, dto);
};

export const copyMailingTemplate = async (
  templateId: string,
): Promise<MailingTemplateCopyDTO> => {
  return await HttpClient.educonfig
    .post<MailingTemplateCopyDTO>(`mailings/templates/${templateId}/copy`)
    .then((response) => response.data);
};

export const updateMailingTemplate = async (
  mailingTemplate: MailingTemplateUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `mailings/templates/${mailingTemplate.id}`,
    mailingTemplate,
  );
};

export const deleteMailingTemplate = async (
  templateId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(`mailings/templates/${templateId}`);
};
