<template>
  <form class="flex flex-col gap-8" @submit="submitForm">
    <FormFieldTextInput
      :id="fieldIds.notificationEmailAddress"
      :label="
        texts.navigationItems.organize.activity.notificationSettings
          .notificationEmailAddress
      "
      :type="TextInputType.TEXT"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldToggle
      :id="fieldIds.notifyOnRegistrationChanges"
      :label="
        texts.navigationItems.organize.activity.notificationSettings
          .notifyOnRegistrationChanges
      "
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldToggle
      :id="fieldIds.notifyOnRegistrationCancellations"
      :label="
        texts.navigationItems.organize.activity.notificationSettings
          .notifyOnRegistrationCancellations
      "
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldToggle
      :id="fieldIds.notifyOnCapacityFull"
      :label="
        texts.navigationItems.organize.activity.notificationSettings
          .notifyOnCapacityFull
      "
      :displayMode="FormFieldDisplayMode.Row"
    />
    <SectionDivider />
    <slot name="actions" />
  </form>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import { useForm } from "vee-validate";
import SectionDivider from "@/components/common/section/SectionDivider.vue";
import { IActivityNotificationSettingsDTO } from "@/lib/eduConfigurationServiceClient";
import * as yup from "yup";
import { fields } from "@/utils/miscellaneous";
import { watch } from "vue";

type FormValues = {
  notificationEmailAddress: string;
  notifyOnRegistrationChanges: boolean;
  notifyOnRegistrationCancellations: boolean;
  notifyOnCapacityFull: boolean;
};

const props = defineProps<{
  activityId: string;
  formValues?: FormValues;
}>();

const emit = defineEmits<{
  submit: [values: IActivityNotificationSettingsDTO];
}>();

const validationSchema = yup.object({
  notificationEmailAddress: yup.string().email().required(),
  notifyOnRegistrationChanges: yup.boolean().default(false).required(),
  notifyOnRegistrationCancellations: yup.boolean().default(false).required(),
  notifyOnCapacityFull: yup.boolean().default(false).required(),
});

const fieldIds = fields<FormValues>();
const form = useForm<FormValues>({ validationSchema });

const submitForm = form.handleSubmit((values) => {
  emit("submit", { ...values, activityId: props.activityId });
});

watch(
  () => props.formValues,
  (newValue) => {
    if (!newValue) {
      form.resetForm();
    } else {
      form.setValues(newValue);
    }
  },
  { immediate: true },
);
</script>
