<template>
  <form class="mt-4 flex flex-col gap-2" @submit="onSubmit">
    <div>
      <NavigationTabContainer>
        <NavigationTab
          v-for="(locale, index) in form.values.localizations"
          :key="index"
          :data-testid="
            locale.locale !== defaultCulture
              ? testIds.action.delete
              : testIds.action.view
          "
          :current="activeCulture === locale.locale"
          @click="changeLocale(locale.locale)"
        >
          <div class="flex items-center gap-2">
            <span>{{ texts.enums.culture[locale.locale] }}</span>
            <button
              v-if="locale.locale !== defaultCulture"
              type="button"
              @click="openDeleteModal(locale.locale)"
            >
              <Icon
                icon="close"
                :size="IconSize.xs"
                :color="Color.DeepTeal"
              ></Icon>
            </button></div
        ></NavigationTab>
        <template
          v-if="
            !bee.state.loading &&
            notAddedCultures.length !== 0 &&
            form.values.localizations &&
            form.values.localizations.length > 0
          "
        >
          <NavigationTab
            v-for="culture in notAddedCultures"
            :key="culture"
            icon="add_circle"
            :name="interpolate(texts.actions.add, texts.enums.culture[culture])"
            :data-testid="testIds.action.create"
            @click="
              addLocale(
                culture,
                form.values.localizations![0].contentBeeConfiguration,
              )
            "
          >
          </NavigationTab>
        </template>
      </NavigationTabContainer>
    </div>
    <FormFieldTextInput
      :id="keys.name"
      :label="texts.models.emailTemplate.name"
      :displayMode="FormFieldDisplayMode.Row"
      :disabled="activeCulture !== defaultCulture"
      :rules="yup.string().max(256).required()"
    />
    <FormFieldSelect
      :id="keys.fromEmailAddress"
      :label="texts.models.emailTemplate.fromEmailAddress"
      :displayMode="FormFieldDisplayMode.Row"
      :rules="yup.string().max(256).required()"
      :disabled="activeCulture !== defaultCulture"
      :items="fromEmailOptions"
    />
    <FormFieldSelect
      :id="keys.replyToEmailAddress"
      :label="texts.models.emailTemplate.replyToEmailAddress"
      :displayMode="FormFieldDisplayMode.Row"
      :rules="yup.string().max(256).required()"
      :disabled="activeCulture !== defaultCulture"
      :items="replyToEmailOptions"
    />
    <div
      v-for="(locale, index) in form.values.localizations"
      v-show="activeCulture === locale.locale"
      :key="index"
      class="flex flex-col gap-2"
    >
      <FormFieldTextInput
        :id="`${keys.localizations}[${index}].subject`"
        :label="texts.models.emailTemplate.subject"
        :displayMode="FormFieldDisplayMode.Row"
        :rules="yup.string().max(256).required()"
        :placeholder="defaultLocale?.subject"
      />
      <FormFieldTextInput
        :id="`${keys.localizations}[${index}].fromName`"
        :label="texts.models.emailTemplate.fromName"
        :displayMode="FormFieldDisplayMode.Row"
        :rules="yup.string().max(256).required()"
        :placeholder="defaultLocale?.fromName"
      />
    </div>
    <div :id="containerId" class="h-beeEditor shadow"></div>
    <div class="flex flex-col items-center gap-2 lg:flex-row-reverse">
      <slot :bee="bee"></slot>
    </div>
  </form>

  <EmailTemplateDeleteLocaleModal
    v-if="form"
    v-model:visible="modalRemoveLocaleVisible"
    :form="form"
    :locale="activeCulture"
    @delete:confirmed="removeLocale"
  />
</template>

<script setup lang="ts">
import EmailTemplateDeleteLocaleModal from "./EmailTemplateDeleteLocaleModal.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { FromEmailAddress, ReplyToEmailAddress } from "@/models/email-address";
import texts from "@/utils/texts";
import { computed, ref, watch } from "vue";
import {
  EmailTemplateFormValues,
  createNewLocale,
} from "./EmailTemplateForm.types";
import { useForm } from "vee-validate";
import * as yup from "yup";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import { Color, Culture } from "@/enums";
import { fields } from "@/utils/miscellaneous";
import { useBee } from "@/utils/bee/bee-instance";
import { IconSize } from "@/components/common/icon/Icon.types";
import NavigationTabContainer from "@/components/common/navigation/NavigationTabContainer.vue";
import NavigationTab from "@/components/common/navigation/NavigationTab.vue";
import { testIds } from "@/utils/testing";
import { interpolate } from "@/dictionary";
import { Localization } from "@/models/helpers/localizable";
import { EmailTemplateLocalizableProperties } from "@/models/emailTemplates";
import EmailTemplateTypeVariables from "@/models/emailTemplateVariables";
import Icon from "@/components/common/icon/Icon.vue";
import settings from "@/store/context/settings.context";

const props = defineProps<{
  initialValues?: EmailTemplateFormValues;
  fromEmailAddresses: FromEmailAddress[];
  replyToEmailAddresses: ReplyToEmailAddress[];
  emailTemplateTypeVariables: EmailTemplateTypeVariables;
}>();

const emit = defineEmits<{
  submit: [values: EmailTemplateFormValues];
}>();

const keys = fields<EmailTemplateFormValues>();
const form = useForm<EmailTemplateFormValues>({
  initialValues: props.initialValues,
});

const fromEmailOptions = computed(() =>
  props.fromEmailAddresses.map((emailAddress) => ({
    value: emailAddress.emailAddress,
    label: emailAddress.emailAddress,
  })),
);

const replyToEmailOptions = computed(() =>
  props.replyToEmailAddresses.map((emailAddress) => ({
    value: emailAddress.emailAddress,
    label: emailAddress.emailAddress,
  })),
);

const containerId = "bee-container-id";
const bee = useBee(containerId);

const defaultCulture = settings.mainLanguage.locale.value as Culture;
const defaultLocale = computed(() =>
  form.values.localizations?.find((l) => l.locale === defaultCulture),
);

const activeCulture = ref(defaultCulture);
const activeLocale = computed(() =>
  form.values.localizations?.find((l) => l.locale === activeCulture.value),
);

if (!defaultLocale.value) {
  addLocale(defaultCulture, undefined);
}

const notAddedCultures = computed(() =>
  settings.availableLanguages
    .map((language) => language.locale.value as Culture)
    .filter(
      (culture) =>
        !form.values.localizations?.find((locale) => locale.locale === culture),
    ),
);

function changeLocale(culture: Culture) {
  if (bee.state.loading) {
    return;
  }

  activeCulture.value = culture;
}

function addLocale(culture: Culture, beeConfig: string | undefined) {
  if (bee.state.loading) {
    return;
  }

  const newLocale = createNewLocale(culture, beeConfig);
  saveToForm(newLocale);

  activeCulture.value = culture;
}

const modalRemoveLocaleVisible = ref(false);
const localeToRemove = ref<Culture>();

function openDeleteModal(culture: Culture) {
  localeToRemove.value = culture;
  modalRemoveLocaleVisible.value = true;
}

const componentTexts = texts.navigationItems.emailTemplates;
const removeLocale = () => {
  if (!form.values.localizations) {
    throw new Error(componentTexts.deleteLocale.noLocalesFound);
  }

  const index = form.values.localizations.findIndex(
    (l) => l.locale === localeToRemove.value,
  );

  if (index === -1 || index === undefined) {
    throw new Error(componentTexts.deleteLocale.noLocaleFound);
  }

  const newLocalizations =
    form.values.localizations?.map((l) => ({ ...l })) ?? [];

  newLocalizations.splice(index, 1);

  form.setFieldValue("localizations", newLocalizations);

  activeCulture.value = defaultCulture;
  modalRemoveLocaleVisible.value = false;
};

watch(activeCulture, async (toCulture, fromCulture) => {
  await changeCulture(fromCulture, toCulture);
});

async function changeCulture(fromCulture: Culture, toCulture: Culture) {
  const fromLocale = form.values.localizations?.find(
    (l) => l.locale === fromCulture,
  );
  const toLocale = form.values.localizations?.find(
    (l) => l.locale === toCulture,
  );

  if (!toLocale) {
    throw new Error("No to locale found");
  }

  if (fromLocale) {
    const saveResponse = await bee.save();
    const newLocale: Localization<Partial<EmailTemplateLocalizableProperties>> =
      {
        ...fromLocale,
        contentBeeConfiguration: saveResponse[0],
        contentHtml: saveResponse[1],
      };

    saveToForm(newLocale);
  }

  await bee.load(
    toLocale.contentBeeConfiguration
      ? JSON.parse(toLocale.contentBeeConfiguration)
      : JSON.parse(defaultLocale.value?.contentBeeConfiguration ?? ""),
  );
}

bee.init({
  template: defaultLocale.value?.contentBeeConfiguration
    ? JSON.parse(defaultLocale.value.contentBeeConfiguration)
    : undefined,
  mergeTags: props.emailTemplateTypeVariables.mergeTags,
  mergeContents: props.emailTemplateTypeVariables.mergeContents,
  specialLinks: props.emailTemplateTypeVariables.specialLinks,
});

const onSubmit = form.handleSubmit(async () => {
  if (!activeLocale.value) {
    throw new Error("No active locale found");
  }

  const saveResponse = await bee.save();

  const newLocale: Localization<Partial<EmailTemplateLocalizableProperties>> = {
    ...activeLocale.value,
    contentBeeConfiguration: saveResponse[0],
    contentHtml: saveResponse[1],
  };

  saveToForm(newLocale);

  emit("submit", form.values);
});

function saveToForm(
  localization: Localization<Partial<EmailTemplateLocalizableProperties>>,
) {
  const newLocalizations =
    form.values.localizations?.map((l) => ({ ...l })) ?? [];

  const index = newLocalizations.findIndex(
    (l) => l.locale === localization.locale,
  );

  if (index === -1) {
    if (form.values.localizations && localization.locale === defaultCulture) {
      // Edge case where only non-default localization exists for entity
      // We then need to put the new mandatory localization before the existing one
      newLocalizations.unshift(localization);
    } else {
      newLocalizations.push(localization);
    }
  } else {
    newLocalizations[index] = localization;
  }

  form.setFieldValue("localizations", newLocalizations);
}
</script>
