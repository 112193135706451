<template>
  <div class="flex flex-col gap-4">
    <Search
      v-model="search"
      :placeholder="texts.actions.search"
      class="mb-5"
    ></Search>
    <div class="flex flex-col gap-4 rounded bg-white p-4 shadow-sm">
      <div class="flex items-center justify-between gap-1.5">
        <h2 class="text-base font-semibold">
          {{ texts.generic.filters }}
        </h2>
        <span
          class="cursor-pointer text-xs text-emerald-500 underline hover:text-emerald-700"
          @click="resetFilters"
          >{{ texts.actions.reset }}</span
        >
      </div>
      <StudyProgramFilter
        :studyPrograms="studyProgramsModel"
        :counts="studyProgramFilter.counts"
        :reset="reset"
        @filter="(values) => (studyProgramFilter.ids = values)"
      />
      <Divider />
      <Filter
        v-if="locationFilter.items.length > 0"
        v-model:expanded="locationFilter.expanded"
        :title="texts.models.activityLocation.title"
      >
        <FilterItem
          v-for="item in locationFilter.items"
          :id="item.id"
          :key="item.id"
          v-model="locationFilter.ids"
          :name="item.name"
          :count="locationFilter.counts[item.id] || 0"
        ></FilterItem>
      </Filter>
      <Divider />
      <Filter
        v-if="typeFilter.items.length > 0"
        v-model:expanded="typeFilter.expanded"
        :title="texts.models.sessionType.title"
      >
        <FilterItem
          v-for="item in typeFilter.items"
          :id="item.id"
          :key="item.id"
          v-model="typeFilter.ids"
          :name="item.name"
          :count="typeFilter.counts[item.id] || 0"
        ></FilterItem>
      </Filter>
      <Divider />
      <Filter
        v-if="linkFilter.items.length > 0"
        v-model:expanded="linkFilter.expanded"
        :title="texts.models.registrationLink.title"
      >
        <FilterItem
          v-for="item in linkFilter.items"
          :id="item.id"
          :key="item.id"
          v-model="linkFilter.ids"
          :name="item.name"
          :count="linkFilter.counts[item.id] || 0"
        ></FilterItem>
      </Filter>
      <Divider />
      <Filter
        v-if="modeFilter.items.length > 0"
        v-model:expanded="modeFilter.expanded"
        :title="texts.models.sessionMode.title"
      >
        <FilterItem
          v-for="item in modeFilter.items"
          :id="item.id"
          :key="item.id"
          v-model="modeFilter.ids"
          :name="item.name"
          :count="modeFilter.counts[item.id] || 0"
        ></FilterItem>
      </Filter>
    </div>
  </div>
</template>

<script setup lang="ts">
import Divider from "@/components/common/divider/Divider.vue";
import Search from "@/components/common/search/Search.vue";
import { SessionStorageKey } from "@/enums";
import { SessionMode } from "@/models/session";
import { countBy } from "@/utils/array";
import texts from "@/utils/texts";
import { ref, watch, computed, reactive } from "vue";
import StudyProgramFilter from "@/components/study-programs/StudyProgramFilter.vue";
import FilterItem from "@/components/common/filter/FilterItem.vue";
import {
  FilterEntity,
  compare,
  compareArray,
} from "@/components/common/filter/FilterItem.types";
import Filter from "@/components/common/filter/Filter.vue";
import {
  LocalizedSessionCountDto,
  LocalizedStudyProgramDTO,
} from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{
  sessions: LocalizedSessionCountDto[];
  studyPrograms: LocalizedStudyProgramDTO[];
  locations: FilterEntity[];
  types: FilterEntity[];
  registrationLinks: FilterEntity[];
}>();

const studyProgramsModel: LocalizedStudyProgramDTO[] = props.studyPrograms;

const emit = defineEmits<{
  (e: "filter", value: LocalizedSessionCountDto[]): void;
  (e: "filter:studyPrograms", value: string[] | undefined): void;
}>();

// Search Field
const search = ref<string>(
  sessionStorage.getItem(SessionStorageKey.SessionsSearchTerm) || "",
);
watch(search, (newValue) =>
  newValue
    ? sessionStorage.setItem(SessionStorageKey.SessionsSearchTerm, newValue)
    : sessionStorage.removeItem(SessionStorageKey.SessionsSearchTerm),
);

// Filter definitions
const studyProgramFilter = reactive({
  ids: undefined as string[] | undefined,
  counts: computed(() =>
    countBy(props.sessions, (item) =>
      item.studyPrograms?.map((studyProgram) => studyProgram.id),
    ),
  ),
});

const locationFilter = reactive({
  ids: [] as string[],
  items: props.locations.map((location) => ({
    id: location.id,
    name: location.name,
    modelValue: false,
  })),
  counts: computed(() => countBy(props.sessions, (item) => item.locationId)),
  expanded: true,
});

const typeFilter = reactive({
  ids: [] as string[],
  items: props.types.map((type) => ({
    id: type.id,
    name: type.name,
    modelValue: false,
  })),
  counts: computed(() => countBy(props.sessions, (item) => item.typeId)),
  expanded: false,
});

const linkFilter = reactive({
  ids: [] as string[],
  items: props.registrationLinks.map((link) => ({
    id: link.id,
    name: link.name,
    modelValue: false,
  })),
  counts: computed(() =>
    countBy(props.sessions, (item) => item.registrationLinkIds),
  ),
  expanded: false,
});

const modeFilter = reactive({
  ids: [] as string[],
  items: [
    {
      id: SessionMode.Online,
      name: texts.enums.sessionMode[SessionMode.Online],
      modelValue: false,
    },
    {
      id: SessionMode.Physical,
      name: texts.enums.sessionMode[SessionMode.Physical],
      modelValue: false,
    },
  ],
  counts: computed(() => countBy(props.sessions, (item) => item.mode)),
  expanded: false,
});

const reset = ref(false);
const resetFilters = () => {
  reset.value = !reset.value;
  typeFilter.ids = [];
  locationFilter.ids = [];
  modeFilter.ids = [];
  linkFilter.ids = [];
};

// Filter function
const filteredSessions = computed<LocalizedSessionCountDto[]>(() => {
  return props.sessions
    .filter(
      (session) =>
        !search.value ||
        session.name?.toLowerCase().includes(search.value.toLowerCase()) ||
        session.studyPrograms
          ?.map((studyProgram) => studyProgram.name)
          .some((name) =>
            name.toLowerCase().includes(search.value.toLowerCase()),
          ),
    )
    .filter((session) => compare(locationFilter.ids, session.locationId))
    .filter((session) => compare(typeFilter.ids, session.typeId))
    .filter((session) =>
      compareArray(linkFilter.ids, session.registrationLinkIds ?? []),
    )
    .filter((session) => compare(modeFilter.ids, session.mode))
    .filter((session) =>
      compareArray(
        studyProgramFilter.ids,
        session.studyPrograms?.map((studyProgram) => studyProgram.id) || [],
      ),
    );
});

watch(filteredSessions, (value) => emit("filter", value), {
  immediate: true,
});

watch(
  () => studyProgramFilter.ids,
  (value) => emit("filter:studyPrograms", value),
  {
    immediate: true,
  },
);
</script>
