import { Culture } from "@/enums";
import { TrialDayConfigurationStudyProgramSettingsDto } from "@/lib/eduConfigurationServiceClient";

export interface TrialDayStudyProgramFormValues {
  studyProgramId: string;
  notificationEmailAddress?: string;
  localizations: TrialDayStudyProgramFormValuesLocalization[];
}

export interface TrialDayStudyProgramFormValuesLocalization {
  locale: Culture;
  additionalFormContent: string;
}

export const convertToFormValues = (
  settings: TrialDayConfigurationStudyProgramSettingsDto,
): TrialDayStudyProgramFormValues => {
  return {
    ...settings,
    localizations:
      settings.localizations as TrialDayStudyProgramFormValuesLocalization[],
  };
};

export const convertToDTO = (
  formValues: TrialDayStudyProgramFormValues,
): TrialDayConfigurationStudyProgramSettingsDto => {
  return new TrialDayConfigurationStudyProgramSettingsDto({
    ...formValues,
    localizations: formValues.localizations.filter(
      (l) => l.additionalFormContent,
    ),
  });
};
