<template>
  <FormLayout @submit="submit">
    <FormFieldTextInput
      :id="fieldIds.uri"
      :label="texts.models.brochure.uri"
      :type="TextInputType.URL"
      :displayMode="FormFieldDisplayMode.Column"
      :data-testid="fieldIds.uri"
      :button="
        copyToClipboard
          ? {
              icon: hasCopied ? 'inventory' : 'content_copy',
            }
          : undefined
      "
      @button:click="(value) => copyValueToClipboard(value)"
    />
    <FormFieldArrayHelper
      v-slot="{ field, key }"
      :fieldId="fieldId"
      :fields="localizationFields"
    >
      <FormFieldTextInput
        :id="`${key}.name`"
        :label="`${texts.models.brochure.name} (${
          texts.enums.culture[field.locale]
        })`"
        :displayMode="FormFieldDisplayMode.Column"
        :data-testid="`name_${field.locale}`"
      />
    </FormFieldArrayHelper>
    <StudyProgramFormField
      :id="fieldIds.studyProgramIds"
      :label="texts.models.brochure.studyPrograms"
      :displayMode="FormFieldDisplayMode.Column"
      :data-testid="fieldIds.studyProgramIds"
    />
    <FormFieldCheckbox
      :id="fieldIds.isHardCopy"
      :label="texts.models.brochure.hardCopy"
      :displayMode="FormFieldDisplayMode.Column"
      :data-testid="fieldIds.isHardCopy"
    />
    <FormFieldCheckbox
      :id="fieldIds.isPublic"
      :label="texts.models.brochure.public"
      :displayMode="FormFieldDisplayMode.Column"
      :data-testid="fieldIds.isPublic"
    />
    <template v-slot:actions> <slot></slot> </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { useForm } from "vee-validate";
import * as yup from "yup";
import texts from "@/utils/texts";

import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import StudyProgramFormField from "@/components/study-programs/StudyProgramFormField.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";

import { fields } from "@/utils/miscellaneous";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import {
  useLocalizationFields,
  normalizeLocalizations,
} from "@/models/helpers/localizable";
import { BrochureFormValues } from "@/views/information-requests/brochures/components/BrochureForm.types";
import FormFieldCheckbox from "@/components/common/checkbox/FormFieldCheckbox.vue";
import { Culture } from "@/enums";
import FormLayout from "@/components/common/form/FormLayout.vue";

const fieldIds = fields<BrochureFormValues>();

const props = defineProps<{
  formValues?: BrochureFormValues;
  copyToClipboard?: boolean;
}>();

const emit = defineEmits<{
  submit: [BrochureFormValues];
}>();

const form = useForm<BrochureFormValues>({
  validationSchema: yup.object({
    uri: yup.string().url().required(),
    isHardCopy: yup.boolean().required(),
    isPublic: yup.boolean().required(),
    studyProgramIds: yup.array(),
    localizations: yup.array().of(
      yup.object({
        locale: yup.mixed<Culture>().required(),
        name: yup.string().max(256).requiredWhenMainLocale(),
      }),
    ),
  }),
  initialValues: {
    isPublic: true,
    isHardCopy: false,
    uri: "",
    localizations: [],
  },
});

const hasCopied = ref(false);
const copyValueToClipboard = async (value: string | number | undefined) => {
  if (!value) return;

  hasCopied.value = false;
  await navigator.clipboard.writeText(value.toString());
  hasCopied.value = true;
  setTimeout(() => (hasCopied.value = false), 1000);
};

const { fieldId, fields: localizationFields } = useLocalizationFields();

watch(
  () => props.formValues,
  (values) => {
    const localizations = normalizeLocalizations(values?.localizations);
    form.setValues({ ...values, localizations });
  },
  { immediate: true },
);

const submit = form.handleSubmit((values) => emit("submit", values));
</script>
