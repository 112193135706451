<template>
  <div class="flex h-full w-full max-w-3xl flex-col gap-8">
    <div class="flex flex-col gap-2">
      <PageHeading>{{ textsActivity.form.basicInformationTitle }}</PageHeading>
      <PageSubTitle class="text-sm text-gray-500"
        >{{ textsActivity.form.basicInformationDescription }}
      </PageSubTitle>
    </div>
    <ActivitySettingsForm :formValues="formValues" @submit="onSubmit">
      <template v-slot:actions>
        <div class="flex items-center justify-between gap-4">
          <ButtonSubmit
            v-if="!id"
            :loading="actionLoading"
            :trailingIcon="'arrow_right_alt'"
          >
            <span class="inline-flex flex-wrap items-center gap-1"
              ><span class="font-semibold">{{ texts.actions.submit }},</span>
              <span class="font-normal">{{
                textsActivity.btnToStudyPrograms
              }}</span></span
            >
          </ButtonSubmit>
          <ButtonSubmit v-else :loading="actionLoading" />
          <ButtonDelete v-if="id" @click="deleteModalVisible = true">
          </ButtonDelete>
        </div>
      </template>
    </ActivitySettingsForm>
    <Modal
      v-model:visible="deleteModalVisible"
      :type="ModalType.danger"
      :description="textsActivity.modal.deleteActivity.description"
      :title="textsActivity.modal.deleteActivity.title"
    >
      <template v-slot:buttons>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button
            :data-testid="testIds.action.confirm"
            class="sm:ml-3"
            :color="Color.Alert"
            :label="texts.actions.delete"
            :loading="actionLoading"
            :disabled="!deleteModalVisible"
            @click="doDeleteActivity()"
          />
          <Button
            class="mt-3 sm:mt-0"
            :color="Color.White"
            :label="texts.actions.cancel"
            :disabled="actionLoading || !deleteModalVisible"
            @click="deleteModalVisible = false"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import ActivitySettingsForm from "./components/ActivitySettingsForm.vue";
import Button from "@/components/common/button/Button.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";

import logger from "@/plugins/logger";
import { RouteNames, RouteNamesActivityOverview } from "@/router/routeNames";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import {
  ActivitySettingsFormValues,
  convertToCreateDTO,
  convertToUpdateDTO,
  convertToFormValues,
} from "@/views/organize/activities/activity/settings/components/ActivitySettings.types";
import {
  ActivityRouteProps,
  ActivityRoutePropsCreate,
} from "@/router/guards/activityContextGuard";

import activityContext, {
  loadActivity,
} from "@/store/context/activity.context";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import { Color, Culture } from "@/enums";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { ValidationErrorResponse } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";

const props = defineProps<ActivityRouteProps | ActivityRoutePropsCreate>();

const textsActivity = texts.navigationItems.organize.activity;

// Store and router setup
const router = useRouter();

const formValues = ref<ActivitySettingsFormValues>();

const mainCulture = settings.mainLanguage.locale.value as Culture;

formValues.value = convertToFormValues(props.activity, mainCulture);

// Form actions
const actionLoading = ref<boolean>(false);

const onSubmit = async (values: ActivitySettingsFormValues) => {
  actionLoading.value = true;

  if (!props.id) {
    const dto = convertToCreateDTO(values);

    eduConfigurationServiceClient
      .createActivity(dto)
      .then(async (response) => {
        Notify.success(textsActivity.create.success);

        activityContext.activity =
          await eduConfigurationServiceClient.getActivityById(response.id);

        router.push({
          name: RouteNamesActivityOverview.STUDYPROGRAMS,
          params: { id: response.id },
        });
      })
      .catch((e) => {
        Notify.failure(textsActivity.create.failure);
        logger.error(e);
      })
      .finally(() => {
        actionLoading.value = false;
      });
  } else {
    const id = props.id;
    const dto = convertToUpdateDTO(id, values);

    eduConfigurationServiceClient
      .updateActivity(id, dto)
      .then(() => {
        Notify.success(textsActivity.edit.success);
        loadActivity(id);
      })
      .catch((e) => {
        Notify.failure(textsActivity.edit.failure);
        logger.error(e);
      })
      .finally(() => {
        actionLoading.value = false;
      });
  }
};

// Delete activity feature
const deleteModalVisible = ref<boolean>(false);

const doDeleteActivity = () => {
  if (!props.activity) throw new Error("Activity is undefined");

  actionLoading.value = true;

  return eduConfigurationServiceClient
    .deleteActivity(props.activity.id)
    .then(() => {
      deleteModalVisible.value = false;
      Notify.success(textsActivity.delete.success);
      router.push({ name: RouteNames.ORGANIZE });
    })
    .catch((error: unknown) => {
      logger.error(error);

      if (error instanceof ValidationErrorResponse) {
        deleteModalVisible.value = false;
        Notify.failure(texts.validationErrors[error.errorCode]);
        return;
      }

      Notify.failure(textsActivity.delete.failure);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
