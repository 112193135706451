import { IconNames } from "@/components/common/icon/Icon.types";
import { RouteLocationRaw } from "vue-router";

export interface GridListWithActionsProps {
  actions: GridListAction[];
}

export type GridListAction = {
  title: string;
  description: string;
  toRoute: RouteLocationRaw;
  icon: IconNames;
  color: GridListColorCss;
};

export enum GridListColorCss {
  Red = "text-rose-700 bg-rose-50",
  Blue = "text-royal-700 bg-royal-50",
  Indigo = "text-lavender-700 bg-lavender-50",
  Green = "text-emerald-700 bg-emerald-50",
  Purple = "text-purple-700 bg-purple-50",
  Yellow = "text-warning-700 bg-warning-50",
}
