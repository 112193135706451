<template>
  <FormLayout @submit="submitForm">
    <FormFieldTextInput
      :id="fieldIds.notificationEmailAddress"
      v-model="form.values.notificationEmailAddress"
      :label="componentTexts.details.notificationEmailAddress"
    />

    <NavigationTabContainer>
      <NavigationTab
        v-for="lang in settings.availableLanguages"
        :key="lang.locale.value"
        :current="activeCulture === lang.locale.value"
        @click="activeCulture = lang.locale.value as Culture"
      >
        {{ texts.enums.culture[lang.locale.value as Culture] }}
      </NavigationTab>
    </NavigationTabContainer>
    <FormFieldArrayHelper
      v-slot="{ field, fieldId, localeLabel }"
      :fieldId="localizationFieldId"
      :fields="localizationFields"
    >
      <FormFieldWysiwyg
        v-show="field.locale === activeCulture"
        :id="fieldId('additionalFormContent')"
        :label="`${componentTexts.details.additionalFormContent} (${localeLabel})`"
      />
    </FormFieldArrayHelper>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import FormLayout from "@/components/common/form/FormLayout.vue";
import NavigationTab from "@/components/common/navigation/NavigationTab.vue";
import NavigationTabContainer from "@/components/common/navigation/NavigationTabContainer.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldWysiwyg from "@/components/common/wysiwyg/FormFieldWysiwyg.vue";
import { Culture } from "@/enums";
import {
  normalizeLocalizations,
  useLocalizationFields,
} from "@/models/helpers/localizable";
import { fields } from "@/utils/miscellaneous";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import { ref, watch } from "vue";
import * as yup from "yup";
import {
  TrialDayStudyProgramFormValues,
  TrialDayStudyProgramFormValuesLocalization,
} from "./TrialDayStudyProgramSettingsForm.types";
import settings from "@/store/context/settings.context";

const componentTexts = texts.navigationItems.organize.trialDays;

const props = defineProps<{
  formValues?: TrialDayStudyProgramFormValues;
}>();

const emit = defineEmits<{
  submit: [values: TrialDayStudyProgramFormValues];
}>();

const activeCulture = ref(settings.mainLanguage.locale.value);
const form = useForm<TrialDayStudyProgramFormValues>({
  validationSchema: yup.object({
    notificationEmailAddress: yup.string().email(),
    localizations: yup.array().of(
      yup.object().shape({
        locale: yup.mixed<Culture>().required(),
        additionalFormContent: yup.string(),
      }),
    ),
  }),
});

const fieldIds = fields<TrialDayStudyProgramFormValues>();
const { fieldId: localizationFieldId, fields: localizationFields } =
  useLocalizationFields<TrialDayStudyProgramFormValuesLocalization>();

const submitForm = form.handleSubmit((values) => emit("submit", values));

watch(
  () => props.formValues,
  (values) => {
    const localizations = normalizeLocalizations(values?.localizations);
    form.setValues({ ...values, localizations });
  },
  { immediate: true },
);
</script>
