<template>
  <div class="flex flex-col gap-8">
    <Loader v-if="loading" />
    <ErrorComponent v-else-if="error" />
    <template v-else>
      <StatsGrid v-if="stats?.number" :statsItems="[stats]" />
      <ActivitySMSSettingsForm
        :key="reloadForm"
        :activityStartDateTime="startDateTime"
        :formValues="formValues"
        :editMode="editMode"
        @submit="submitForm"
      >
        <ButtonSubmit
          v-if="editMode && !activityStarted"
          :disabled="actionLoading"
          :loading="actionLoading"
          >{{ texts.actions.schedule }}</ButtonSubmit
        >
        <Button
          v-if="!editMode && !activityStarted"
          :disabled="actionLoading"
          :loading="actionLoading"
          @click="handleClick"
          >{{ texts.actions.edit }}</Button
        >
        <ButtonDelete
          v-if="smsCampaign && !activityStarted"
          :disabled="actionLoading"
          @click="deleteCampaign()"
        >
          {{ texts.actions.delete }}
        </ButtonDelete>
      </ActivitySMSSettingsForm>
      <SMSDeleteModal
        :visible="deleteModalVisible"
        :activityId="props.id"
        @update:visible="deleteModalVisible = $event"
        @delete:succeeded="deleteCampaign"
    /></template>
  </div>
</template>

<script setup lang="ts">
import {
  ActivitySMSSettingsFormValues,
  convertToCreateDTO,
  convertToFormValues,
  convertToUpdateDTO,
} from "@/views/organize/activities/activity/mailings-sms/sms/components/ActivitySMSSettingsForm.types";
import ActivitySMSSettingsForm from "@/views/organize/activities/activity/mailings-sms/sms/components/ActivitySMSSettingsForm.vue";
import texts from "@/utils/texts";
import Notify from "@/utils/notify";
import {
  ActivitySMSCampaignDTO,
  createActivitySMSCampaign,
  getActivitySMSCampaign,
  updateActivitySMSCampaign,
} from "@/services/activity.service";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import logger from "@/plugins/logger";
import Loader from "@/App.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import { StatsGridItem } from "@/components/common/stats-grid/StatsGrid.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import StatsGrid from "@/components/common/stats-grid/StatsGrid.vue";
import Button from "@/components/common/button/Button.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import { ref, computed } from "vue";
import SMSDeleteModal from "@/views/organize/activities/activity/mailings-sms/sms/components/SMSDeleteModal.vue";
import { DateTime } from "luxon";

const props = defineProps<{ id: string; startDateTime: DateTime }>();

const textsSMS = texts.navigationItems.organize.activity.shortMessageCampaign;

const smsCampaign = ref<ActivitySMSCampaignDTO>();
const formValues = ref<ActivitySMSSettingsFormValues>();
const stats = ref<StatsGridItem>();
const actionLoading = ref(false);
const loading = ref<boolean>(true);
const error = ref(false);
const editMode = ref<boolean>(false) ?? false;
const deleteModalVisible = ref(false);
const reloadForm = ref(0);
const activityStarted = computed(() => props.startDateTime < DateTime.now());

const handleClick = () => {
  if (editMode.value) {
    editMode.value = false;
  } else {
    editMode.value = true;
  }
};

getActivitySMSCampaign(props.id)
  .then((response) => {
    smsCampaign.value = response;
    formValues.value = convertToFormValues(response);
    eduConfigurationServiceClient
      .getActivityStats(props.id)
      .then((response) => {
        stats.value = {
          title:
            texts.navigationItems.organize.activity.reports.sentShortMessages
              .title,
          number: response.sentShortMessages,
          icon: "sms",
        };
      });
  })
  .catch((e) => {
    if (e.request.status != 404) {
      logger.log(e);
      error.value = true;
    }
    if (activityStarted.value) {
      editMode.value = false;
    } else {
      if (smsCampaign.value) {
        editMode.value = false;
      } else {
        editMode.value = true;
      }
    }

    formValues.value = { message: null!, plannedDateTime: null! };
  })
  .finally(() => (loading.value = false));

const submitForm = async (values: ActivitySMSSettingsFormValues) => {
  if (smsCampaign.value) {
    try {
      actionLoading.value = true;
      await updateActivitySMSCampaign(props.id, convertToUpdateDTO(values));
      Notify.success(textsSMS.edit.success);
    } catch (err) {
      Notify.failure(textsSMS.edit.failure);
      throw err;
    } finally {
      actionLoading.value = false;
      editMode.value = false;
    }
  } else {
    try {
      actionLoading.value = true;
      await createActivitySMSCampaign(props.id, convertToCreateDTO(values));
      smsCampaign.value = await getActivitySMSCampaign(props.id);
      Notify.success(textsSMS.edit.success);
    } catch (err) {
      Notify.failure(textsSMS.edit.failure);
      throw err;
    } finally {
      actionLoading.value = false;
      editMode.value = false;
    }
  }
  getActivitySMSCampaign(props.id).then((response) => {
    formValues.value = convertToFormValues(response);
  });
};

const deleteCampaign = () => {
  if (deleteModalVisible.value) {
    smsCampaign.value = undefined;
    editMode.value = true;
    deleteModalVisible.value = false;
    formValues.value = undefined;
  } else {
    deleteModalVisible.value = true;
  }
};
</script>
