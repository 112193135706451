import { ApiClientDTO } from "@/models/apiKey";
import HttpClient from "@/utils/httpClient";

export const createAPIClient = async (): Promise<ApiClientDTO> => {
  return await HttpClient.account
    .post<ApiClientDTO>("client/")
    .then((res) => res.data);
};

export const getAPIClient = async (): Promise<ApiClientDTO> => {
  return await HttpClient.account
    .get<ApiClientDTO>("client/")
    .then((res) => res.data);
};
