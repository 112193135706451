<template>
  <Modal
    v-model:visible="visibleInternal"
    :type="ModalType.danger"
    :title="texts.models.formTemplate.editModal.title"
    :description="texts.models.formTemplate.editModal.description"
  >
    <template v-slot:content>
      <ul class="ml-4 list-disc">
        <li v-for="name in activityNames" :key="name" class="text-sm">
          {{ name }}
        </li>
      </ul>
    </template>
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <ButtonSubmit
          class="sm:ml-3"
          :disabled="!visibleInternal"
          @click="emit('update:submit')"
        />
        <ButtonCancel
          class="mt-3 sm:mt-0"
          :disabled="!visibleInternal"
          @click="visibleInternal = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import texts from "@/utils/texts";
import { computed } from "vue";

const props = defineProps<{
  activityNames: string[];
  visible: boolean;
}>();

const emit = defineEmits<{
  "update:visible": [value: boolean];
  "update:submit": [];
}>();

const visibleInternal = computed({
  get() {
    return props.visible;
  },
  set(val) {
    emit("update:visible", val);
  },
});
</script>
