<template>
  <StudyProgramForm @submit="onSubmit">
    <slot></slot>
  </StudyProgramForm>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { useRouter } from "vue-router";
import { RouteNamesSettings } from "@/router/routeNames";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import logger from "@/plugins/logger";
import StudyProgramForm from "./StudyProgramForm.vue";
import {
  StudyProgramFormValues,
  convertToCreateDTO,
} from "./StudyProgramForm.types";
import Notify from "@/utils/notify";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { CreateStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import { loadStudyPrograms } from "@/store/context/settings.context";

// Store and router setup
const store = useStore<RootState>();
const router = useRouter();

const emit = defineEmits(["submit"]);

const actionLoading = ref<boolean>(false);

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const onSubmit = (values: StudyProgramFormValues) => {
  actionLoading.value = true;
  return eduConfigurationServiceClient
    .postStudyProgram(new CreateStudyProgramDTO(convertToCreateDTO(values)))
    .then(() => loadStudyPrograms())
    .then(() => {
      Notify.success(texts.notifications.create.successCached, [
        texts.models.studyProgram.title,
      ]);
      router.push({ name: RouteNamesSettings.STUDY_PROGRAMS });
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.studyProgram.create.failure);
      logger.error(e);
    })
    .finally(() => {
      emit("submit");
      actionLoading.value = false;
    });
};
</script>
