<template>
  <MenuButton :as="ButtonBase" :color="colorInternal">
    <slot></slot>
    <Icon
      :icon="'keyboard_arrow_down'"
      :size="IconSize.sm"
      :color="getForegroundColor(colorInternal)"
    />
  </MenuButton>
</template>
<script setup lang="ts">
import { MenuButton } from "@headlessui/vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import ButtonBase from "./ButtonBase.vue";
import { Color } from "@/enums";
import { getForegroundColor } from "@/utils/color";
import { computed } from "vue";
import { ButtonGroupInjectionKey } from "@/components/common/button/ButtonGroup.types";
import { inject } from "vue";

const props = withDefaults(
  defineProps<{
    color?: Color;
  }>(),
  {
    color: Color.Emerald,
  },
);

const colorInternal = computed<Color>(() =>
  inject(ButtonGroupInjectionKey.Color, props.color),
);
</script>
