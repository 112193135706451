import {
  RecruitmentYear,
  RecruitmentYearDTO,
  RecruitmentYearEnd,
  RecruitmentYearEndDTO,
} from "@/models/recruitmentYear";
import HttpClient from "@/utils/httpClient";

export const getRecruitmentYear = async (): Promise<RecruitmentYear> => {
  return await HttpClient.educonfig
    .get<RecruitmentYearDTO>("recruitment-years")
    .then((response) => new RecruitmentYear(response.data));
};

export const getRecruitmentYearEnd = async (
  dayStart: string,
  monthStart: string,
): Promise<RecruitmentYearEnd> => {
  return await HttpClient.educonfig
    .get<RecruitmentYearEndDTO>(
      `recruitment-years/end?daystart=${dayStart}&monthstart=${monthStart}`,
    )
    .then((response) => new RecruitmentYearEnd(response.data));
};

export const updateRecruitementYear = async (
  dayStart?: string,
  monthStart?: string,
): Promise<void> => {
  return await HttpClient.educonfig.put("recruitment-years", {
    dayStart,
    monthStart,
  });
};
