import {
  ProspectDTO,
  ProspectUpdateDTO,
  ProspectQueryDTO,
  ProspectSearchResultDTO,
} from "@/models/contact";
import HttpClient from "@/utils/httpClient";

export const getContactQueryResult = async (
  query: string | undefined,
): Promise<ProspectSearchResultDTO[]> => {
  const payload: ProspectQueryDTO = {
    textQuery: query,
  };

  return await HttpClient.educonfig
    .post("prospects/search", payload)
    .then((response) => response.data);
};

export const getContact = async (id: string): Promise<ProspectDTO> => {
  return await HttpClient.educonfig
    .get(`prospects/${id}`)
    .then((response) => response.data);
};

export const updateContact = async (
  contact: ProspectUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(`prospects/${contact.id}`, contact);
};

export const sendRegistrationConfirmation = async (
  id: string,
): Promise<void> => {
  return await HttpClient.educonfig.post(
    `registrations/${id}/sendconfirmation`,
  );
};
