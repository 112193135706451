<template>
  <LayoutTwoCol>
    <template v-slot:left>
      <ProspectCountStats
        :prospectCount="prospectCount"
        :title="texts.navigationItems.brochures.stats.uniqueProspects"
        :subtitle="texts.navigationItems.brochures.stats.currentRecruitmentYear"
        :color="color"
      />
    </template>
    <div class="flex flex-col gap-8 xl:flex-row">
      <Card
        v-if="props.hasHardCopyBrochures"
        class="h-44 min-w-64 flex-initial xl:max-w-64"
        :size="CardSize.sm"
      >
        <Pie
          :height="250"
          :data="pieChartData"
          :options="pieChartOptions"
          class="h-full w-full"
        />
      </Card>
      <Card class="h-44 min-w-0 flex-1" :size="CardSize.sm">
        <Bar
          class="h-full w-full"
          :data="barChartData"
          :options="barChartOptions"
        />
      </Card>
    </div>
  </LayoutTwoCol>
</template>

<script setup lang="ts">
import { CardSize } from "@/components/common/card/Card.types";
import Card from "@/components/common/card/Card.vue";
import LayoutTwoCol from "@/components/common/layout/LayoutTwoCol.vue";
import ProspectCountStats from "@/components/stats/ProspectCountStats.vue";
import { Color, Months } from "@/enums";
import {
  BrochureStatsDto,
  BrochureStatsPerMonthDto,
} from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import { getCssColor } from "@/utils/color";
import texts from "@/utils/texts";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import { DateTime } from "luxon";
import { ref, watch } from "vue";
import { Bar, Pie } from "vue-chartjs";

const props = defineProps<{
  hasHardCopyBrochures: boolean;
  color: Color;
}>();

const loading = ref<boolean>(true);
const fromDate = ref<DateTime>(DateTime.now());
const toDate = ref<DateTime>(DateTime.now());
const prospectCount = ref<number>(0);
const brochureStatsPerMonth = ref<BrochureStatsPerMonthDto[]>([]);

const digitalDataColor = Color.Emerald;
const hardCopyDataColor = Color.DeepTeal;

getRecruitmentYear()
  .then((recruitmentYear) => {
    const currentRecruitmentYearDates =
      recruitmentYear.toCurrentRecruitmentYearDates();

    fromDate.value = currentRecruitmentYearDates.start;
    toDate.value = currentRecruitmentYearDates.end;
  })
  .then(() => {
    Promise.all([
      eduConfigurationServiceClient.getBrochuresStats(
        fromDate.value,
        toDate.value,
      ),
      eduConfigurationServiceClient.getBrochureStatsPerMonth(
        fromDate.value,
        toDate.value,
      ),
    ]).then(([brochureStatsResponse, brochureStatsPerMonthResponse]) => {
      prospectCount.value = brochureStatsResponse.prospectCount;
      brochureStatsPerMonth.value = brochureStatsPerMonthResponse;
      processPieStats(brochureStatsResponse);
      processBarStats(brochureStatsPerMonthResponse);
    });
  })
  .catch((error) => {
    logger.error(error);
    error.value = true;
  })
  .finally(() => {
    loading.value = false;
  });

// Pie Chart for totals
const pieChartData = ref<ChartData<"pie", number[], unknown>>({ datasets: [] });

const pieChartOptions: ChartOptions<"pie"> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      align: "start",
      text: `${texts.navigationItems.brochures.stats.digitalRequests} vs ${texts.navigationItems.brochures.stats.hardCopyRequests}`,
      font: {
        size: 16,
        weight: 600,
        family: "'Messina Sans', 'sans-serif'",
      },
    },
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 20,
        font: {
          family: "'Messina Sans', 'sans-serif'",
        },
      },
    },
  },
};

const processPieStats = (input: BrochureStatsDto) => {
  const labels = [
    `${texts.navigationItems.brochures.stats.digitalRequests} ${input.digitalCount}`,
    `${texts.navigationItems.brochures.stats.hardCopyRequests} ${input.hardCopyCount}`,
  ];
  const datasets = [
    {
      data: [input.digitalCount, input.hardCopyCount],
      backgroundColor: [
        getCssColor(digitalDataColor),
        getCssColor(hardCopyDataColor),
      ],
    },
  ];
  pieChartData.value = {
    labels,
    datasets,
  };
};

// Bar Chart for counts per month
const barChartData = ref<ChartData<"bar", number[], unknown>>({ datasets: [] });

const barChartOptions: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      align: "start",
      text: texts.navigationItems.brochures.stats.prospectsPerMonth,
      font: {
        size: 16,
        weight: 600,
        family: "'Messina Sans', 'sans-serif'",
      },
    },
    legend: {
      display: false,
    },
  },
};

// Dataset options for border radius and max bar thickness
const barChartDatasetOptions = {
  borderRadius: 20,
  maxBarThickness: 12,
};

const processBarStats = (input: BrochureStatsPerMonthDto[]) => {
  const labels = getShortMonthNames(input);
  const datasets = [
    {
      data: input.map((item) => item.digitalCount),
      backgroundColor: [getCssColor(digitalDataColor)],
      ...barChartDatasetOptions,
    },
  ];

  if (props.hasHardCopyBrochures) {
    datasets.push({
      data: input.map((item) => item.hardCopyCount),
      backgroundColor: [getCssColor(hardCopyDataColor)],
      ...barChartDatasetOptions,
    });
  }

  barChartData.value = {
    labels,
    datasets,
  };
};

const getShortMonthNames = (input: BrochureStatsPerMonthDto[]) => {
  return input.map(
    (item) => texts.enums.monthsShort[Object.values(Months)[item.month - 1]],
  );
};

Chart.register(...registerables);

watch(
  () => props.hasHardCopyBrochures,
  () => processBarStats(brochureStatsPerMonth.value),
  { immediate: true },
);
</script>
