<template>
  <div class="flex w-full flex-col items-stretch gap-3">
    <div class="flex flex-row">
      <div class="flex flex-1 flex-col gap-1">
        <label class="text-sm font-semibold text-deepteal-500"
          >{{ formTexts.confirmationPage.redirectTitle }}
        </label>
        <p class="text-sm text-gray-500">
          {{ formTexts.confirmationPage.redirectDescription }}
        </p>
      </div>
      <FormFieldToggle id="redirectEnabled" />
    </div>
    <div v-show="redirectEnabled">
      <template
        v-for="localization in localizations"
        :key="localization.locale"
      >
        <FormFieldTextInput
          v-show="currentLocale === localization.locale"
          :id="`localizations.${localization.locale}.redirectUrl`"
          icon="captive_portal"
          :type="TextInputType.URL"
          :placeholder="formTexts.confirmationPage.redirectUrlPlaceholder"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import { FormLocalizationDTO } from "@/lib/formsServiceClient";
import texts from "@/utils/texts";

const formTexts = texts.navigationItems.manage.settings.formTemplates;

defineProps<{
  redirectEnabled: boolean;
  localizations: FormLocalizationDTO[];
  currentLocale: string;
}>();
</script>
