<template>
  <div
    v-for="(option, index) in options"
    :key="option.getRadioButtonKey()"
    class="mt-4 space-y-4"
  >
    <div class="flex items-center">
      <RadioButton
        :id="getIdentifier(index)"
        v-model="checked"
        :name="groupName"
        :value="option"
      ></RadioButton>
      <label
        class="ml-3 block text-sm font-medium text-gray-700"
        :for="getIdentifier(index)"
        >{{ option.getRadioButtonText() }}</label
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import RadioButton from "./RadioButton.vue";
import { RadioButtonOption } from "./RadioButtonOption";

export interface RadioButtonListProps {
  options: RadioButtonOption[];
  groupName: string;
  modelValue?: RadioButtonOption;
}

const props = defineProps<{
  options: RadioButtonOption[];
  groupName: string;
  modelValue?: RadioButtonOption | undefined;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: RadioButtonOption | undefined): void;
}>();

const getIdentifier = (index: number) =>
  `radio-${props.groupName}-${index.toString()}`;

const checked = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  },
});
</script>
