import { FormQuestionDTO, IFormQuestionDTO } from "@/lib/formsServiceClient";

export interface QuestionSettingsFormValues {
  id: string;
  isInline?: boolean;
  isRequired?: boolean;
  conditionalQuestions: ConditionalQuestionFormValues[];
}

export interface ConditionalQuestionFormValues {
  questionId: string;
  answerOptionId: string;
  isInline?: boolean;
  isRequired?: boolean;
}

export const convertToFormValues = (
  question: FormQuestionDTO,
  conditionalQuestions: FormQuestionDTO[],
): QuestionSettingsFormValues => {
  return {
    id: question.id!,
    isInline: question.isInline,
    isRequired: question.isRequired,
    conditionalQuestions: conditionalQuestions.flatMap((conditionalQuestion) =>
      conditionalQuestion.answerOptionIdDependencies
        .filter((depId) =>
          question.answers.some((answer) => answer.id === depId),
        )
        .map((depId) => ({
          questionId: conditionalQuestion.id!,
          answerOptionId: depId,
          isInline: conditionalQuestion.isInline,
          isRequired: conditionalQuestion.isRequired,
        })),
    ),
  };
};

export const convertToDto = (
  questionSettings: QuestionSettingsFormValues,
): FormQuestionDTO[] => {
  return questionSettings.conditionalQuestions.map((conditionalQuestion) => {
    const question: IFormQuestionDTO = {
      id: conditionalQuestion.questionId,
      sequenceNumber: 0,
      isInline: conditionalQuestion.isInline,
      isRequired: conditionalQuestion.isRequired,
      answerOptionIdDependencies: [conditionalQuestion.answerOptionId],
      answers: [],
      localizations: [],
    };

    return new FormQuestionDTO(question);
  });
};
