import {
  TenantDTO,
  TenantDTOStatus,
  TenantStatus,
} from "@/lib/eduConfigurationServiceClient";

export { TenantDTO };

export class Tenant {
  name: string;
  description: string;
  uri: string;
  status: TenantDTOStatus;

  constructor(dto: TenantDTO) {
    this.name = dto.name;
    this.description = dto.description;
    this.uri = dto.uri;
    this.status = dto.status;
  }
}

export interface TenantCreateDTO {
  name: string;
  description: string;
  status: TenantStatus;
  domainNames: string[];
  mainLanguageId: string;
}
