<template>
  <FormLayout @submit="submitForm">
    <Section class="w-full">
      <SectionHeading
        :title="componentTexts.personalDetailsTitle"
        :divider="false"
      />

      <InputField
        class="mt-4 w-full"
        fullWidth
        :formFieldId="firstNameFormFieldId"
        :label="componentTexts.firstNameLabel"
      />
      <InputField
        fullWidth
        :formFieldId="infixFormFieldId"
        :label="componentTexts.infixLabel"
      />
      <InputField
        fullWidth
        :formFieldId="lastNameFormFieldId"
        :label="componentTexts.lastNameLabel"
      />
      <DateField
        fullWidth
        :formFieldId="dateOfBirthFormFieldId"
        :label="componentTexts.dateOfBirthLabel"
      />
      <!-- Disabled until we sort out how to edit contact data with options -->
      <InputField
        fullWidth
        :formFieldId="genderFormFieldId"
        :label="componentTexts.genderLabel"
        :disabled="true"
      />
    </Section>
    <Section>
      <SectionHeading
        :title="componentTexts.contactDetailsTitle"
        :divider="false"
      />
      <InputField
        fullWidth
        class="mt-4"
        :formFieldId="emailAddressFormFieldId"
        :label="componentTexts.emailAddressLabel"
      />
      <div class="mb-5">
        <CheckboxField
          fullWidth
          :formFieldId="receiveMarketingEmailFormFieldId"
          :label="componentTexts.receiveMarketingEmailLabel"
        />
      </div>
      <InputField
        fullWidth
        :formFieldId="mobilePhoneNumberFormFieldId"
        :label="componentTexts.mobilePhoneNumberLabel"
      />
      <InputField
        fullWidth
        :formFieldId="phoneNumberFormFieldId"
        :label="componentTexts.phoneNumberLabel"
      />
    </Section>
    <Section>
      <SectionHeading
        :title="componentTexts.addressDetailsTitle"
        :divider="false"
      />
      <InputField
        fullWidth
        class="mt-4"
        :formFieldId="streetFormFieldId"
        :label="componentTexts.streetLabel"
      />
      <InputField
        fullWidth
        :formFieldId="houseNumberFormFieldId"
        :label="componentTexts.houseNumberLabel"
      />
      <InputField
        fullWidth
        :formFieldId="houseNumberExtensionFormFieldId"
        :label="componentTexts.houseNumberExtensionLabel"
      />
      <InputField
        fullWidth
        :formFieldId="postalCodeFormFieldId"
        :label="componentTexts.postalCodeLabel"
      />
      <InputField
        fullWidth
        :formFieldId="cityFormFieldId"
        :label="componentTexts.cityLabel"
      />
      <!-- Disabled until we sort out how to edit contact data with options -->
      <InputField
        fullWidth
        :formFieldId="countryFormFieldId"
        :label="componentTexts.countryLabel"
        :disabled="true"
      />
    </Section>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { ContactFormValues } from "./ContactForm.types";
import Section from "@/components/common/section/Section.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import InputField from "@/components/common/text-input/InputField.vue";
import DateField from "@/components/common/date/DateField.vue";
import CheckboxField from "@/components/common/checkbox/CheckboxField.vue";
import { useStore } from "vuex";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { RootState } from "@/store";
import FormLayout from "@/components/common/form/FormLayout.vue";

const props = defineProps<{
  formValues: ContactFormValues;
}>();

const emit = defineEmits<{
  (e: "submit", values: ContactFormValues): void;
}>();

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];
const componentTexts = texts.navigationItems.contact.form;

const firstNameFormFieldId: keyof ContactFormValues = "firstName";
const infixFormFieldId: keyof ContactFormValues = "infix";
const lastNameFormFieldId: keyof ContactFormValues = "lastName";
const emailAddressFormFieldId: keyof ContactFormValues = "emailAddress";
const receiveMarketingEmailFormFieldId: keyof ContactFormValues =
  "receiveMarketingEmail";
const dateOfBirthFormFieldId: keyof ContactFormValues = "dateOfBirth";
const genderFormFieldId: keyof ContactFormValues = "gender";
const phoneNumberFormFieldId: keyof ContactFormValues = "phoneNumber";
const mobilePhoneNumberFormFieldId: keyof ContactFormValues =
  "mobilePhoneNumber";
const countryFormFieldId: keyof ContactFormValues = "country";
const postalCodeFormFieldId: keyof ContactFormValues = "postalCode";
const cityFormFieldId: keyof ContactFormValues = "city";
const streetFormFieldId: keyof ContactFormValues = "street";
const houseNumberFormFieldId: keyof ContactFormValues = "houseNumber";
const houseNumberExtensionFormFieldId: keyof ContactFormValues =
  "houseNumberExtension";

const form = useForm<ContactFormValues>({
  initialValues: props.formValues,
});

const submitForm = form.handleSubmit(async (values: ContactFormValues) => {
  emit("submit", values);
});
</script>
