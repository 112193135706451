<template>
  <input
    v-model="checked"
    type="radio"
    data-testid="radiobutton"
    class="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
    :value="value"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { RadioButtonOption } from "./RadioButtonOption";

const props = withDefaults(
  defineProps<{
    modelValue?: RadioButtonOption | undefined;
    value?: RadioButtonOption;
  }>(),
  { modelValue: undefined, value: undefined },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: RadioButtonOption | undefined): void;
}>();

const checked = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>
