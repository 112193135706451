<template>
  <Page :loading="loading" :error="error" class="flex flex-col pb-8">
    <ButtonBack :toRouteName="texts.generic.previous" @click="router.go(-1)" />
    <PageHeading
      >{{ texts.navigationItems.manage.settings.templates.templateFor }}
      {{ texts.enums.mailingType[mailingType] }}</PageHeading
    >
    <MailingTemplateForm :beeContainerId="containerId" @submit="onSubmit">
      <ButtonSubmit
        class="w-full lg:w-auto"
        :loading="isSubmitting"
        :disabled="bee.state.loading"
      />
      <ButtonCancel class="w-full lg:w-auto" @click="clickCancel" />
      <Toggle
        v-model="bee.state.preview"
        data-testid="toggle-preview"
        class="lg:flex-grow"
        :label="texts.actions.showPreview"
      />
    </MailingTemplateForm>
  </Page>
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import logger from "@/plugins/logger";
import { createMailingTemplate } from "@/services/mailing-templates.service";
import { ref } from "vue";
import {
  MailingTemplateFormValues,
  toCreateDto,
} from "@/views/settings/emails/components/mailing-template/MailingTemplateForm.types";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { useRouter } from "vue-router";
import { RouteNamesSettings } from "@/router/routeNames";
import texts from "@/utils/texts";
import Notify from "@/utils/notify";
import { MailingType as MailingTypeEnum } from "@/enums";
import ButtonBack from "@/components/common/button/ButtonBack.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import { useBee } from "@/utils/bee/bee-instance";
import MailingTemplateForm from "@/views/settings/emails/components/mailing-template/MailingTemplateForm.vue";
import Toggle from "@/components/common/toggle/Toggle.vue";
import settings from "@/store/context/settings.context";

const props = defineProps<{
  mailingType: MailingTypeEnum;
}>();

const loading = ref(false);
const error = ref(false);

const containerId = "bee-container";
const bee = useBee(containerId);

const initialize = () => {
  const mailingType = settings.mailingTypes.filter(
    (type) => type.mailingType === props.mailingType,
  )[0];

  bee.init({
    mergeTags: mailingType.mergeTags,
    mergeContents: mailingType.mergeContents,
    specialLinks: mailingType.specialLinks,
  });
};

initialize();

const isSubmitting = ref(false);
const onSubmit = async (formValues: MailingTemplateFormValues) => {
  const values = formValues as MailingTemplateFormValues;

  isSubmitting.value = true;

  try {
    const saveResponse = await bee.save();
    const dto = toCreateDto(values, props.mailingType, saveResponse);

    await createMailingTemplate(dto);
    Notify.success(texts.notifications.create.success, [
      texts.models.mailing.title,
    ]);
    router.push({ name: RouteNamesSettings.EMAIL_OVERVIEW });
  } catch (e) {
    Notify.failure(texts.notifications.create.failure, [
      texts.models.mailing.title,
    ]);
    logger.error(e);
  } finally {
    isSubmitting.value = false;
  }
};

const router = useRouter();

const clickCancel = () => {
  router.push({ name: RouteNamesSettings.EMAIL_OVERVIEW });
};
</script>
