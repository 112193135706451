<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.navigationItems.thirdPartyProspectSources.create.title"
    :subTitle="
      texts.navigationItems.thirdPartyProspectSources.create.description
    "
  >
    <ThirdPartyProspectSourceForm @submit="submitCreate">
      <ButtonSubmit :loading="actionLoading" />
      <ButtonCancel @click="() => (visibleInternal = false)" />
    </ThirdPartyProspectSourceForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { computed, ref } from "vue";
import {
  ThirdPartyProspectSourceFormValues,
  convertToCreateDTO,
} from "./ThirdPartyProspectSourceForm.types";
import ThirdPartyProspectSourceForm from "./ThirdPartyProspectSourceForm.vue";
import { ValidationErrorResponse } from "@/lib/eduConfigurationServiceClient";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";

const props = defineProps<{
  visible: boolean;
}>();

const emit = defineEmits(["update:visible", "create:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const actionLoading = ref<boolean>(false);
const submitCreate = (values: ThirdPartyProspectSourceFormValues) => {
  actionLoading.value = true;

  eduConfigurationServiceClient
    .createThirdPartyProspectSource(convertToCreateDTO(values))
    .then(() => {
      Notify.success(
        texts.navigationItems.thirdPartyProspectSources.create.success,
      );
      visibleInternal.value = false;
      emit("create:succeeded");
    })
    .catch((error: unknown) => {
      logger.error(error);

      if (error instanceof ValidationErrorResponse) {
        Notify.failure(texts.validationErrors[error.errorCode]);
        return;
      }

      Notify.failure(
        texts.navigationItems.thirdPartyProspectSources.create.failure,
      );
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
