<template>
  <input
    v-show="focusValue"
    :id="id"
    ref="inputElement"
    v-model="modelValueInternal"
    :name="name"
    :class="[
      'w-full rounded-sm border-gray-300 bg-transparent text-black placeholder-gray-400 transition-all',
      valid
        ? {
            'outline-emerald-500 ring-emerald-500 focus:border-emerald-500 focus:ring-emerald-500':
              !required || modelValueInternal,
            'outline-gray-200 ring-gray-200 focus:border-gray-200 focus:ring-gray-200':
              required && !modelValueInternal,
          }
        : 'outline-alert-500 ring-alert-500 focus:border-alert-500 focus:ring-alert-500',
      css,
    ]"
    :placeholder="placeholder"
    @keydown.enter.prevent="focusValue = false"
    @change="focusValue = false"
    @blur="focusValue = false"
  />
  <div
    v-show="!focusValue"
    class="group inline-flex items-center gap-2"
    @click="focusValue = true"
  >
    <span
      :class="[
        css,
        {
          'opacity-50': required && !modelValueInternal,
        },
      ]"
      >{{ modelValueInternal || placeholder }}</span
    >
    <Icon
      :class="[
        'cursor-pointer transition-opacity duration-300 lg:group-hover:opacity-100',
        {
          'lg:opacity-0': !!modelValueInternal,
          'lg:opacity-40': !modelValueInternal && !required,
          'lg:opacity-100':
            pencilAlwaysVisible || (!modelValueInternal && required),
        },
      ]"
      icon="edit"
      :size="IconSize.sm"
      :stroke="IconStroke.md"
      @click="focusValue = true"
    ></Icon>
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize, IconStroke } from "../icon/Icon.types";
import { TextInputType } from "./TextInput.types";
import { computed, ref, watch, nextTick } from "vue";

const props = withDefaults(
  defineProps<{
    id?: string;
    name?: string;
    modelValue?: string | number | undefined;
    type?: TextInputType;
    placeholder?: string;
    css?: string;
    required?: boolean;
    pencilAlwaysVisible?: boolean;
    valid?: boolean;
  }>(),
  {
    id: undefined,
    name: undefined,
    modelValue: undefined,
    type: TextInputType.TEXT,
    placeholder: undefined,
    required: false,
    pencilAlwaysVisible: false,
    valid: false,
    css: undefined,
  },
);

const inputElement = ref();
const emit = defineEmits<{
  (e: "update:modelValue", value: string | undefined | number): void;
}>();

const modelValueInternal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value || undefined),
});

const focusValue = ref<boolean>(false);

watch(
  focusValue,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        const element = inputElement.value;
        if (element instanceof HTMLInputElement) {
          element.focus();
        }
      });
    }
  },
  { immediate: true },
);
</script>
