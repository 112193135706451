import { LocalizedFormDTO } from "@/lib/formsServiceClient";

export class Form {
  id: string;
  uri: string;
  name: string;
  label: string;
  isHidden: boolean;

  constructor(dto: LocalizedFormDTO) {
    this.id = dto.id;
    this.uri = dto.uri;
    this.name = dto.name;
    this.label = dto.label;
    this.isHidden = dto.isHidden;
  }
}
