<template>
  <Listbox v-model="selectedInternal" as="div" :disabled="disabled">
    <ListboxLabel
      v-if="label"
      data-testid="label"
      class="mb-1 block text-sm font-medium text-gray-700"
    >
      {{ label }}
    </ListboxLabel>
    <div class="relative">
      <ListboxButton data-testid="button" :class="inputClass">
        <span
          class="inset-y-0 flex min-h-5 w-full items-center gap-3 truncate text-center"
        >
          <Icon
            v-if="selectedInternal && selectedInternal.icon"
            :icon="selectedInternal.icon"
            :size="IconSize.sm"
            :color="color"
          ></Icon>
          <span data-testid="button-text" class="items-center truncate">{{
            selectedInternal ? selectedInternal.label : optionsPlaceholder
          }}</span>
          <span
            v-if="selectedInternal && selectedInternal.subText"
            class="ml-2 truncate"
            >{{ selectedInternal.subText }}</span
          >
        </span>

        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <Icon icon="unfold_more" :size="IconSize.sm" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leaveActiveClass="transition ease-in duration-100"
        leaveFromClass="opacity-100"
        leaveToClass="opacity-0"
      >
        <ListboxOptions
          data-testid="options"
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-if="allowUnset"
            v-slot="{ active, selected }"
            data-testid="option-unset"
            :value="null"
          >
            <li
              :class="[
                active ? (color ? backgroundColor : 'bg-emerald-600') : '',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
            >
              <div class="flex">
                <span data-testid="option-text">&nbsp;</span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active
                    ? 'text-white'
                    : color
                      ? textColor
                      : 'text-emerald-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <Icon icon="check" :color="color" aria-hidden="true" />
              </span></li
          ></ListboxOption>

          <template v-for="(option, index) in options" :key="index.toString()">
            <ListboxOption
              v-slot="{ active, selected }"
              as="template"
              :data-testid="testIds.option"
              :value="option"
              :disabled="option.disabled"
            >
              <li
                :class="[
                  active ? backgroundColor + ' text-gray-900' : '',
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  { 'opacity-50': option.disabled },
                ]"
              >
                <div class="flex gap-3">
                  <Icon
                    v-if="option.icon"
                    :icon="option.icon"
                    :size="IconSize.sm"
                    :color="color"
                  ></Icon>
                  <span
                    data-testid="option-text"
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'truncate',
                    ]"
                  >
                    {{ option.label || "&nbsp;" }}
                  </span>
                  <span
                    v-if="option.subText"
                    data-testid="option-subtext"
                    :class="[
                      active ? 'text-lavender-200' : 'text-gray-500',
                      'ml-2 truncate',
                    ]"
                  >
                    {{ option.subText }}
                  </span>
                </div>

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-emerald-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <Icon
                    :icon="'check'"
                    :color="color"
                    :size="IconSize.sm"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>

            <!-- 
              This empty invisible option (height 0) is here to solve the issue of options scrolling off screen when no option is selected.
              This is by design, as described in this github issue: https://github.com/tailwindlabs/headlessui/issues/30
              Functionality is also described in more detail in this components tests
            -->
            <ListboxOption
              v-if="index === Math.round(options.length / 2) - 1"
              data-testid="option-scroll-hack"
              :value="undefined"
            ></ListboxOption>
          </template>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { computed, PropType } from "vue";
import { SelectOption } from "./SelectOption";
import { testIds } from "@/utils/testing";
import { Color } from "@/enums";
import {
  getBgColor,
  getFocusBorderColor,
  getFocusRingColor,
  getTextColor,
} from "@/utils/color";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";

const props = defineProps({
  options: {
    type: Array as PropType<SelectOption[]>,
    required: true,
  },
  optionsPlaceholder: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    default: undefined,
  },
  modelValue: {
    type: [Object, null] as PropType<SelectOption | null>,
    default: undefined,
  },
  valid: {
    type: Boolean as PropType<boolean>,
    default: undefined,
  },
  disabled: {
    type: Boolean,
  },
  allowUnset: {
    type: Boolean,
  },
  color: {
    type: String as PropType<Color>,
    default: undefined,
  },
  size: {
    type: String as PropType<"sm" | "md" | "lg">,
    default: "md",
  },
});

const emit = defineEmits<{
  (e: "update:modelValue", value: SelectOption | null | undefined): void;
}>();

const selectedInternal = computed<SelectOption | null | undefined>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const textColor = props.color ? getTextColor(props.color) : "text-black";
const backgroundColor = props.color
  ? getBgColor(props.color, true)
  : "bg-white";
const ringColor = props.color
  ? getFocusRingColor(props.color) + " " + getFocusBorderColor(props.color)
  : "focus:ring-emerald-500 focus:border-emerald-500";

const displayInvalid = computed(() => props.valid === false);
const inputClass = computed(() => [
  "relative w-full border rounded-md shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm",
  {
    "border-gray-300 text-gray-400 bg-gray-100 cursor-not-allowed":
      props.disabled && !displayInvalid.value,
    "border-alert-300 text-gray-400 bg-gray-100 cursor-not-allowed":
      props.disabled && displayInvalid.value,
    "border-gray-300": !props.disabled && !displayInvalid.value,
    "border-alert-300 text-alert-900 focus:ring-alert-500 focus:border-alert-500":
      !props.disabled && displayInvalid.value,
  },
  !props.disabled && !displayInvalid.value ? ringColor : "",
  !props.disabled && !displayInvalid.value ? textColor : "",
  !props.disabled && !displayInvalid.value ? backgroundColor : "",
  {
    "py-1": props.size === "sm",
    "py-2": props.size === "md",
    "py-3": props.size === "lg",
  },
]);
</script>
