import { ContactMomentDTO } from "@/models/contact-moment";
import HttpClient from "@/utils/httpClient";

export const getContactMoments = async (
  prospectId: string,
): Promise<ContactMomentDTO[]> => {
  return await HttpClient.educonfig
    .get(`prospects/${prospectId}/contactmoments`)
    .then((response) => response.data);
};
