<template>
  <FormLayout @submit="submitForm">
    <h2
      v-if="!initialValues?.activityTypeId"
      class="text-lg font-semibold text-deepteal-700"
    >
      {{ texts.navigationItems.organize.styling.title }}
    </h2>
    <div
      v-if="!initialValues?.activityTypeId"
      class="flex items-center justify-between"
    >
      <h3 class="text-base font-semibold text-deepteal-700">
        {{ texts.navigationItems.organize.styling.publicActivityPage.title }}
      </h3>
    </div>

    <FormFieldTextInput
      id="color"
      :label="texts.models.publicActivityPageSettings.color"
      :type="TextInputType.COLOR"
    />
    <FormFieldTextInput
      id="bannerUrl"
      :label="texts.models.publicActivityPageSettings.bannerUrl"
      :type="TextInputType.URL"
    />
    <FormFieldTextInput
      id="gtmCode"
      :label="texts.models.publicActivityPageSettings.gtmCode"
    />
    <Card v-if="form.values.bannerUrl" class="flex flex-col gap-4">
      <CardHeader :text="texts.generic.preview"></CardHeader>
      <img :src="form.values.bannerUrl" />
    </Card>
    <LocalizationTabs
      v-slot="{ current }"
      :locales
      @add="addLocalization"
      @remove="removeLocalization"
    >
      <LocalizationSlot v-slot="{ locale }" :locales :current>
        <FormFieldTextInput
          :id="`localizations.${locale}.title`"
          :label="`${texts.models.publicActivityPageSettings.pagetitle} (${
            texts.enums.culture[locale]
          })`"
        />
        <FormFieldWysiwyg
          :id="`localizations.${locale}.description`"
          :label="`${texts.models.publicActivityPageSettings.pagedescription} (${
            texts.enums.culture[locale]
          })`"
        />
      </LocalizationSlot>
    </LocalizationTabs>
    <template v-slot:actions> <slot></slot> </template>
  </FormLayout>
</template>

<script setup lang="ts">
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import FormFieldWysiwyg from "@/components/common/wysiwyg/FormFieldWysiwyg.vue";
import { Culture } from "@/enums";
import { IPublicActivityPageSettingsDTO } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import { computed, watch } from "vue";
import * as yup from "yup";
import FormLayout from "@/components/common/form/FormLayout.vue";
import settings from "@/store/context/settings.context";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import LocalizationSlot from "@/components/localization-tabs/LocalizationSlot.vue";

export interface PublicActivityPageSettingsFormValues {
  activityTypeId?: string;
  color: string;
  bannerUrl?: string;
  gtmCode?: string;
  localizations: PublicActivityPageSettingsFormValuesLocalizations;
}

type PublicActivityPageSettingsFormValuesLocalizations = Partial<
  Record<Culture, PublicActivityPageSettingsFormValuesLocalization>
>;
interface PublicActivityPageSettingsFormValuesLocalization {
  title: string;
  description?: string;
}

const props = defineProps<{
  initialValues: IPublicActivityPageSettingsDTO;
}>();

const emit = defineEmits<{
  submit: [values: PublicActivityPageSettingsFormValues];
}>();

const localizationSchema =
  yup.object<PublicActivityPageSettingsFormValuesLocalization>(
    settings.availableLanguages.reduce(
      (acc, language) => ({
        ...acc,
        [language.locale.value as Culture]: yup.lazy((value) =>
          value
            ? yup.object({
                title: yup.string().max(256).required(),
                description: yup.string().max(1024),
              })
            : yup.mixed(),
        ),
      }),
      {},
    ),
  );

const form = useForm<PublicActivityPageSettingsFormValues>({
  validationSchema: yup.object({
    activityTypeId: yup.string(),
    color: yup.string().required(),
    bannerUrl: yup.string(),
    gtmCode: yup.string(),
    localizations: localizationSchema,
  }),
});

const submitForm = form.handleSubmit((values) => emit("submit", values));

const initializeFormValues = (newSettings: IPublicActivityPageSettingsDTO) => {
  if (newSettings) {
    const valueslocalizations: PublicActivityPageSettingsFormValuesLocalizations =
      newSettings.localizations.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.locale as Culture]: {
            title: cur.title,
            description: cur.description,
          },
        }),
        {},
      );

    const values: PublicActivityPageSettingsFormValues = {
      activityTypeId: newSettings.activityTypeId,
      color: newSettings.color,
      bannerUrl: newSettings.bannerUrl,
      gtmCode: newSettings.gtmCode,
      localizations: valueslocalizations,
    };

    form.setValues(values);
  }
};

watch(
  props.initialValues,
  (newSettings) => {
    initializeFormValues(newSettings);
  },
  { immediate: true },
);

const locales = computed(
  () => Object.keys(form.values.localizations) as Culture[],
);

function addLocalization(culture: Culture) {
  form.setFieldValue("localizations", {
    ...form.values.localizations,
    [culture]: { title: "" },
  });
}

function removeLocalization(culture: Culture) {
  const newDict = { ...form.values.localizations };
  delete newDict[culture];
  form.setFieldValue("localizations", newDict);
}
</script>
