<template>
  <form class="flex flex-col gap-4" @submit="submitForm">
    <Alert
      :content="
        texts.navigationItems.organize.activity.shortMessageCampaign.extraCosts
      "
    ></Alert>
    <FormFieldTextArea
      id="message"
      :label="
        texts.navigationItems.organize.activity.shortMessageCampaign.message
      "
      :placeholder="
        texts.navigationItems.organize.activity.shortMessageCampaign.message
      "
      :disabled="!editMode"
    />
    <FormFieldDateTime
      id="plannedDateTime"
      :max="activityStartDateTime"
      :label="
        editMode
          ? texts.navigationItems.organize.activity.shortMessageCampaign
              .plannedDateTime
          : texts.navigationItems.organize.activity.shortMessageCampaign
              .scheduledAt
      "
      :disabled="!editMode"
    />
    <SectionDivider />
    <div class="flex items-center justify-between gap-4">
      <slot></slot>
    </div>
  </form>
</template>

<script setup lang="ts">
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { ActivitySMSSettingsFormValues } from "./ActivitySMSSettingsForm.types";
import FormFieldTextArea from "@/components/common/text-area/FormFieldTextArea.vue";
import SectionDivider from "@/components/common/section/SectionDivider.vue";
import { watch } from "vue";
import { DateTime } from "luxon";
import Alert from "@/components/common/alert/Alert.vue";

const props = defineProps<{
  activityStartDateTime: DateTime;
  formValues?: ActivitySMSSettingsFormValues;
  editMode: boolean;
}>();

const emit = defineEmits<{
  submit: [values: ActivitySMSSettingsFormValues];
}>();

const form = useForm<ActivitySMSSettingsFormValues>({
  validationSchema: yup.object({
    message: yup.string().max(160).required(),
    plannedDateTime: yup
      .mixed()
      .isInFuture(texts.validation.dateInFuture)
      .isEarlierThan(
        props.activityStartDateTime,
        texts.validation.dateBeforeStartDateTime,
      )
      .required(),
  }),
  initialValues: {
    message: "",
    plannedDateTime: undefined,
  },
});

const submitForm = form.handleSubmit((values) => {
  emit("submit", values);
});

watch(
  () => props.formValues,
  (formValues) => {
    if (formValues) {
      form.setValues(formValues);
    } else {
      form.resetForm();
    }
  },
  { immediate: true },
);
</script>
