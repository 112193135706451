<template>
  <SlideOver
    v-model:visible="visible"
    :title="texts.navigationItems.organize.activity.affiliateLinks.create"
  >
    <AffiliateLinkForm
      :affiliates
      :registrationLinks
      :preEducationSchools
      @submit="createAffiliateLink"
    >
      <template v-slot:actions>
        <ButtonSubmit
          v-if="affiliates.length > 0"
          :disabled="state === AsyncState.Loading"
        >
          {{ texts.actions.create }}
        </ButtonSubmit>
        <ButtonCancel
          :disabled="state === AsyncState.Loading"
          @click="visible = false"
        />
      </template>
    </AffiliateLinkForm>
  </SlideOver>
</template>
<script setup lang="ts">
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import AffiliateLinkForm from "@/views/organize/activities/activity/affiliate-links/AffiliateLinkForm.vue";
import {
  CreateAffiliateLinkDto,
  IAffiliateDto,
  ICreateAffiliateLinkDto,
  IPreEducationSchool,
  IRegistrationLinkWithRegistrationCountDTO,
} from "@/lib/eduConfigurationServiceClient";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import texts from "@/utils/texts";
import {
  AsyncState,
  useAsyncState,
} from "@/components/common/async/Async.types";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";

defineProps<{
  affiliates: IAffiliateDto[];
  registrationLinks: IRegistrationLinkWithRegistrationCountDTO[];
  preEducationSchools: IPreEducationSchool[];
}>();

const emit = defineEmits<{
  stale: [];
}>();

const visible = defineModel<boolean>("visible", { required: true });

const { state, handler: createAffiliateLink } = useAsyncState(
  (values: ICreateAffiliateLinkDto) =>
    eduConfigurationServiceClient
      .createAffiliateLink(new CreateAffiliateLinkDto(values))
      .then(() => {
        visible.value = false;
        emit("stale");
      }),
  texts.notifications.create,
  [texts.models.affiliateLink.title],
);
</script>
