<template>
  <Async :state="state">
    <template v-slot:failure>
      <ErrorComponent />
    </template>
    <template v-slot:loading>
      <Loader />
    </template>
    <template v-slot>
      <form class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
          <h2 class="text-lg font-semibold text-deepteal-700">
            {{ recruitmentYearTexts.title }}
          </h2>
          <span class="text-sm font-light text-deepteal-700">
            {{ recruitmentYearTexts.description }}
          </span>
        </div>
        <div class="grid grid-cols-2 items-end gap-4">
          <FormFieldSelect
            :id="RecruitmentYearSettingsFormKeys.StartDay"
            :label="recruitmentYearTexts.recruitmentYearStartLabel"
            :items="dayOptions"
          />
          <FormFieldSelect
            :id="RecruitmentYearSettingsFormKeys.StartMonth"
            :items="monthOptions"
          />
        </div>
        <div class="grid grid-cols-2 items-end gap-4">
          <FormFieldTextInput
            :id="RecruitmentYearSettingsFormKeys.EndDay"
            :label="recruitmentYearTexts.recruitmentYearEndLabel"
            disabled
          />
          <FormFieldTextInput
            :id="RecruitmentYearSettingsFormKeys.EndMonth"
            disabled
          />
        </div>
      </form>
    </template>
  </Async>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import {
  getDaysArray,
  RecruitmentYearSettingsFormKeys,
  RecruitmentYearSettingsFormValues,
} from "@/views/settings/general/RecruitmentYearSettingsForm.types";
import { SelectOption } from "@/components/common/select/SelectOption";
import { ref, watch } from "vue";
import {
  getRecruitmentYear,
  getRecruitmentYearEnd,
  updateRecruitementYear,
} from "@/services/recruitmentYear.service";
import Notify from "@/utils/notify";
import logger from "@/plugins/logger";
import texts from "@/utils/texts";
import { Months } from "@/enums";
import { useAsyncState } from "@/components/common/async/Async.types";
import Loader from "@/components/common/loader/Loader.vue";
import Async from "@/components/common/async/Async.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";

const recruitmentYearTexts =
  texts.navigationItems.manage.settings.general.recruitmentYear;

const actionLoading = ref(false);
const form = useForm<RecruitmentYearSettingsFormValues>({});
const dayOptions = ref<SelectOption[]>(daysOptionsForMonth(Months.September));

const monthOptions: SelectOption[] = (
  Object.keys(Months) as Array<keyof typeof Months>
).map<SelectOption>((month, monthIndex) => ({
  value: (monthIndex + 1).toString(),
  label: texts.enums.months[month],
}));

function daysOptionsForMonth(month: Months) {
  return getDaysArray(month).map<SelectOption>((dayIndex) => ({
    value: (dayIndex + 1).toString(),
    label: (dayIndex + 1).toString(),
  }));
}

const setNewDayOptions = (recruitmentYearStartMonth: string) => {
  const monthIndex = parseInt(recruitmentYearStartMonth) - 1;
  const month = (Object.keys(Months) as Array<keyof typeof Months>)[
    monthIndex
  ] as Months;

  const newDayOptions: SelectOption[] = daysOptionsForMonth(month);
  if (month == Months.February) {
    newDayOptions.push({
      value: "29",
      label: "29",
      disabled: true,
      subText: recruitmentYearTexts.leapDay,
    });
  }

  dayOptions.value = newDayOptions;
};

const { state, handler } = useAsyncState(async () => {
  const response = await getRecruitmentYear();
  form.setValues({
    recruitmentYearStartDay: response.dayStart.toString(),
    recruitmentYearStartMonth: response.monthStart.toString(),
    recruitmentYearEndDay: response.dayEnd.toString(),
    recruitmentYearEndMonth:
      monthOptions[parseInt(response.monthEnd.toString()) - 1].label,
  });

  watch(
    [
      () => form.values.recruitmentYearStartDay,
      () => form.values.recruitmentYearStartMonth,
    ],
    async ([newStartDay, newStartMonth]) => {
      if (newStartDay === undefined || newStartMonth === undefined) {
        return;
      }

      setNewDayOptions(newStartMonth);

      const response = await getRecruitmentYearEnd(newStartDay, newStartMonth);
      const monthIndex = response.monthEnd - 1;
      form.setFieldValue(
        RecruitmentYearSettingsFormKeys.EndDay,
        response.dayEnd.toString(),
      );
      form.setFieldValue(
        RecruitmentYearSettingsFormKeys.EndMonth,
        monthOptions[monthIndex].label,
      );

      const validation = await form.validate();

      if (validation.valid) {
        actionLoading.value = true;
        try {
          await updateRecruitementYear(
            form.values.recruitmentYearStartDay,
            form.values.recruitmentYearStartMonth,
          );
          Notify.success(texts.notifications.edit.success);
        } catch (e) {
          logger.error(e);
          Notify.failure(texts.notifications.edit.failure);
        } finally {
          actionLoading.value = false;
        }
      }
    },
    {},
  );
});

handler();
</script>
