<template>
  <Filter v-model:expanded="expanded" :title="texts.models.mailing.type">
    <FilterItem
      v-for="(mailingType, index) in mailingTypes"
      :id="mailingType"
      :key="index"
      v-model="checked"
      :name="texts.enums.mailingType[mailingType]"
      :color="getMailingTypeColor(mailingType)"
      :count="counts[mailingType] || 0"
    ></FilterItem>
  </Filter>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { ref, watch } from "vue";
import FilterItem from "@/components/common/filter/FilterItem.vue";
import Filter from "@/components/common/filter/Filter.vue";
import { MailingType } from "@/enums";
import { getMailingTypeColor } from "@/models/mailingType";

const props = defineProps<{
  mailingTypes: MailingType[];
  reset: boolean;
  counts: Record<string, number>;
}>();

const emit = defineEmits<{
  (e: "filter", value: MailingType[] | undefined): void;
}>();

const expanded = ref(true);
const checked = ref<MailingType[]>([]);

watch(checked, (values) => {
  emit("filter", values.length > 0 ? values : undefined);
});

watch(
  () => props.reset,
  () => (checked.value = []),
);
</script>
