import { GetterTree, ModuleTree, Store, StoreOptions } from "vuex";
import activityStore, {
  ActivityStore,
  ActivityStoreState,
} from "./modules/activity.store";
import cultureStore, {
  CultureStore,
  CultureStoreState,
} from "./modules/culture.store";
import accountStore, {
  AccountStore,
  AccountStoreState,
} from "./modules/account.store";

export type RootState = {
  activityStore: ActivityStoreState;
  cultureStore: CultureStoreState;
  accountStore: AccountStoreState;
};

interface RootModules extends ModuleTree<RootState> {
  activityStore: ActivityStore;
  cultureStore: CultureStore;
  accountStore: AccountStore;
}

const allModules: RootModules = {
  activityStore,
  cultureStore,
  accountStore,
};

type RootGetters = GetterTree<RootState, RootState>;

const getters: RootGetters = {};

export interface RootStoreConfig extends StoreOptions<RootState> {
  state?: RootState;
  modules: RootModules;
  getters: RootGetters;
}

export const storeConfig: RootStoreConfig = {
  modules: allModules,
  getters,
};

const store = new Store<RootState>(storeConfig);

export default store;
export type RootStore = Store<RootState>;
