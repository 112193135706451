import { Color } from "@/enums";
import { IconNames } from "@/components/common/icon/Icon.types";

export interface ButtonProps {
  label: string;
  color: Color;
  size: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  buttonElementType?: ButtonElementType;
  leadingIcon?: IconNames;
  trailingIcon?: IconNames;
}

export enum ButtonSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

export enum ButtonElementType {
  button = "button",
  submit = "submit",
  reset = "reset",
}
