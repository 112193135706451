export interface AccountDTO {
  id: string;
  accountType: AccountType;
  email: string;
  name: string;
  roles: Role[];
  picture: string;
  isBlocked: boolean;
  studyProgramIds: string[];
  activityTypeIds: string[];
  cantViewPersonalData: boolean;
  cantViewAndSendMailings: boolean;
}

export interface AccountCreateDTO {
  name: string;
  email: string;
  roles: Role[];
  accountType: AccountType;
  studyProgramIds: string[];
  activityTypeIds: string[];
  cantViewPersonalData: boolean;
  cantViewAndSendMailings: boolean;
}

export interface AccountUpdateDTO {
  id: string;
  name: string;
  email: string;
  roles: Role[];
  studyProgramIds: string[];
  activityTypeIds: string[];
  cantViewPersonalData: boolean;
  cantViewAndSendMailings: boolean;
}

export interface AccountStudyProgramsUpdateDTO {
  id: string;
  studyProgramIds: string[];
}

export enum AccountType {
  SUMMIT = "SummitAccount",
  SURFCONEXT = "SurfConextAccount",
}

export enum Role {
  ADMINISTRATOR = "Administrator",
  EVENTORGANIZER = "EventOrganizer",
  ACTIVITYVIEWER = "ActivityViewer",
}

export default class Account {
  id: string;
  accountType: AccountType;
  email: string;
  name: string;
  roles: Role[];
  picture: string;
  isBlocked: boolean;
  studyProgramIds: string[];
  activityTypeIds: string[];
  cantViewPersonalData: boolean;
  cantViewAndSendMailings: boolean;

  constructor(dto: AccountDTO) {
    this.id = dto.id;
    this.accountType = dto.accountType;
    this.email = dto.email;
    this.name = dto.name;
    this.roles = dto.roles;
    this.picture = dto.picture;
    this.isBlocked = dto.isBlocked;
    this.studyProgramIds = dto.studyProgramIds;
    this.activityTypeIds = dto.activityTypeIds;
    this.cantViewPersonalData = dto.cantViewPersonalData;
    this.cantViewAndSendMailings = dto.cantViewAndSendMailings;
  }
}
