<template>
  <div
    v-if="hasValidationErrors(form.errors.value)"
    class="flex flex-col gap-2"
  >
    <template v-for="localization in localizations" :key="localization.locale">
      <Alert
        v-if="
          hasValidationErrorsForLocalizations(
            form.errors.value,
            localization.locale,
          )
        "
        :color="Color.Info"
        >{{ formatFormIncompleteText(localization.locale) }}
      </Alert>
    </template>
  </div>
</template>

<script setup lang="ts">
import Alert from "@/components/common/alert/Alert.vue";
import { interpolate } from "@/dictionary";
import { Color, Culture } from "@/enums";
import { FormLocalizationDTO } from "@/lib/formsServiceClient";
import texts from "@/utils/texts";
import { FormContext } from "vee-validate";

const formTexts = texts.navigationItems.manage.settings.formTemplates;

defineProps<{
  form: FormContext;
  localizations: FormLocalizationDTO[];
  currentLocale: string;
}>();

type FormErrors = Partial<Record<string, string | undefined>>;

const hasValidationErrors = (errors: FormErrors) => {
  return Object.keys(errors).some((key) => key.includes(`localizations`));
};

// Form validation
const hasValidationErrorsForLocalizations = (
  errors: FormErrors,
  locale: string,
) => {
  return Object.keys(errors).some((key) =>
    key.includes(`localizations.${locale}`),
  );
};

const formatFormIncompleteText = (locale: string) => {
  return interpolate(
    formTexts.localization.formIncompleteForLocale,
    texts.enums.culture[locale as Culture],
  );
};
</script>
