import { Culture } from "@/enums";
import NL from "@/dictionary.nl-NL.json";
import EN from "@/dictionary.en-US.json";
import FR from "@/dictionary.fr-FR.json";
import DE from "@/dictionary.de-DE.json";
import { QueryableLocalizations } from "@/models/helpers/localizable";
import { StudyProgramMetadataLocalization } from "@/models/study-program-metadata-localization";
import { ValidationErrorResponseErrorCode } from "@/lib/eduConfigurationServiceClient";
import { NotificationTexts } from "@/utils/notify";

export type Localizable<T> = Record<Culture, T>;

export type ApplicationDictionary = typeof NL & {
  validationErrors: Record<ValidationErrorResponseErrorCode, NotificationTexts>;
};

const dictionary: Localizable<ApplicationDictionary> = {
  [Culture.NL]: NL,
  [Culture.EN]: EN,
  [Culture.FR]: FR,
  [Culture.DE]: DE,
};

export default dictionary;

export const culturePicker = {
  [Culture.NL]: "Nederlands",
  [Culture.EN]: "English",
  [Culture.FR]: "Français",
  [Culture.DE]: "Deutsch",
};

export const alterStudyProgramMetadataLocalizationsInDictionary = (
  localizations: StudyProgramMetadataLocalization,
) => {
  alterModelTitleInDictionary(
    localizations.areaOfInterestLocalizations,
    "studyProgramAreaOfInterest",
  );
  alterModelTitleInDictionary(
    localizations.departmentLocalizations,
    "studyProgramDepartment",
  );
  alterModelTitleInDictionary(
    localizations.levelLocalizations,
    "studyProgramLevel",
  );
  alterModelTitleInDictionary(
    localizations.locationLocalizations,
    "studyProgramLocation",
  );
  alterModelTitleInDictionary(
    localizations.modeLocalizations,
    "studyProgramMode",
  );
};

function alterModelTitleInDictionary<T extends { name: string }>(
  localizations: QueryableLocalizations<T>,
  modelName: keyof ApplicationDictionary["models"],
) {
  let culture: Culture;
  for (culture in dictionary) {
    dictionary[culture].models[modelName].title =
      localizations[culture]?.name ||
      dictionary[culture].models[modelName].title;
  }
}

export const interpolate = (text: string, ...replaces: string[]) => {
  let result = text;
  for (let i = 0; i < replaces.length; i++) {
    result = result.replaceAll(`{${i}}`, replaces[i]);
  }
  return result;
};
