<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-2 pb-8">
    <ButtonBack :toRouteName="texts.generic.previous" @click="router.go(-1)" />
    <PageHeading>
      {{ texts.navigationItems.manage.settings.templates.templateFor }}
      {{ texts.enums.emailTemplateType[emailTemplateType] }}
    </PageHeading>
    <EmailTemplateForm
      v-if="
        fromEmailAddresses &&
        replyToEmailAddresses &&
        emailTemplateTypeVariables
      "
      v-slot="{ bee }"
      :fromEmailAddresses="fromEmailAddresses"
      :replyToEmailAddresses="replyToEmailAddresses"
      :emailTemplateTypeVariables="emailTemplateTypeVariables"
      @submit="onSubmit"
    >
      <ButtonSubmit
        class="w-full lg:w-auto"
        :loading="isSubmitting"
        :disabled="bee.state.loading"
      />
      <ButtonCancel class="w-full lg:w-auto" @click="clickCancel" />
      <Toggle
        v-model="bee.state.preview"
        data-testid="toggle-preview"
        class="lg:flex-grow"
        :label="texts.actions.showPreview"
      />
    </EmailTemplateForm>
  </Page>
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import logger from "@/plugins/logger";
import { ref } from "vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { useRouter } from "vue-router";
import { RouteNamesSettings } from "@/router/routeNames";
import texts from "@/utils/texts";
import Notify from "@/utils/notify";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import ButtonBack from "@/components/common/button/ButtonBack.vue";
import {
  createEmailTemplate,
  getEmailTemplateTypeVariables,
} from "@/services/emailTemplates.service";
import { FromEmailAddress, ReplyToEmailAddress } from "@/models/email-address";
import {
  getFromEmailAdresses,
  getReplyToEmailAdresses,
} from "@/services/email-address";
import EmailTemplateForm from "@/views/settings/emails/components/email-template/EmailTemplateForm.vue";
import { EmailTemplateType } from "@/enums";
import Toggle from "@/components/common/toggle/Toggle.vue";
import {
  EmailTemplateFormValues,
  toCreateDto,
} from "./components/email-template/EmailTemplateForm.types";
import { loadEmailTemplates } from "@/store/context/settings.context";
import EmailTemplateTypeVariables from "@/models/emailTemplateVariables";

const props = defineProps<{
  emailTemplateType: EmailTemplateType;
}>();

const loading = ref(false);
const error = ref(false);

const fromEmailAddresses = ref<FromEmailAddress[] | undefined>();
const replyToEmailAddresses = ref<ReplyToEmailAddress[] | undefined>();
const emailTemplateTypeVariables = ref<EmailTemplateTypeVariables>();

const initialize = async () => {
  loading.value = true;
  try {
    const [variables, fromAddresses, replyToAddresses] = await Promise.all([
      getEmailTemplateTypeVariables(),
      getFromEmailAdresses(true),
      getReplyToEmailAdresses(),
    ]);
    fromEmailAddresses.value = fromAddresses;
    replyToEmailAddresses.value = replyToAddresses;
    emailTemplateTypeVariables.value = variables[props.emailTemplateType];
  } catch (e) {
    error.value = true;
    logger.error(e);
  } finally {
    loading.value = false;
  }
};

initialize();

const isSubmitting = ref(false);
const onSubmit = async (values: EmailTemplateFormValues) => {
  isSubmitting.value = true;
  try {
    const dto = toCreateDto(values, props.emailTemplateType);
    await createEmailTemplate(dto);
    await loadEmailTemplates();

    Notify.success(texts.notifications.create.success, [
      texts.models.emailTemplate.title,
    ]);
    router.push({ name: RouteNamesSettings.EMAIL_OVERVIEW });
  } catch (e) {
    Notify.failure(texts.notifications.create.failure, [
      texts.models.emailTemplate.title,
    ]);
    logger.error(e);
  } finally {
    isSubmitting.value = false;
  }
};

const router = useRouter();

const clickCancel = () => {
  router.push({ name: RouteNamesSettings.EMAIL_OVERVIEW });
};
</script>
