<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.navigationItems.organize.sessionGroups.create.title"
    :subTitle="texts.navigationItems.organize.sessionGroups.create.subTitle"
    data-testid="create-slide-over"
  >
    <SessionGroupForm @submit="submitCreate">
      <ButtonSubmit
        :data-testid="testIds.action.submit"
        :loading="actionLoading"
        >{{ texts.actions.create }}</ButtonSubmit
      >
      <Button
        data-testid="cancel-create"
        :color="Color.White"
        :label="texts.actions.cancel"
        @click="cancelCreate"
      ></Button>
    </SessionGroupForm>
  </SlideOver>
</template>

<script setup lang="ts">
import Button from "@/components/common/button/Button.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import dictionary from "@/dictionary";
import { Color, Culture } from "@/enums";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { RootState } from "@/store";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import {
  SessionGroupFormValues,
  convertToCreateDto,
} from "@/views/organize/activities/activity/sessions/components/session-groups/SessionGroupForm.types";
import SessionGroupForm from "@/views/organize/activities/activity/sessions/components/session-groups/SessionGroupForm.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const props = defineProps<{
  activityId: string;
  visible: boolean;
}>();

const emit = defineEmits(["update:visible", "create:succeeded"]);
const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const actionLoading = ref<boolean>(false);
const submitCreate = async (values: SessionGroupFormValues) => {
  actionLoading.value = true;
  await eduConfigurationServiceClient
    .createSessionGroup(props.activityId, convertToCreateDto(values))
    .then(() => {
      Notify.success(
        texts.navigationItems.organize.sessionGroups.create.success,
      );
      visibleInternal.value = false;
      emit("create:succeeded");
    })
    .catch((e) => {
      Notify.failure(
        texts.navigationItems.organize.sessionGroups.create.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const cancelCreate = (): void => {
  visibleInternal.value = false;
};
</script>
