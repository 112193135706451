<template>
  <Collapsible
    v-model:expanded="expanded"
    :data-testid="testIds.studyProgramFilter.department"
  >
    <Checkbox
      v-model="groupChecked"
      :data-testid="testIds.action.filter"
      @update:modelValue="updateStudyProgramsChecked"
    ></Checkbox>
    <button
      class="flex items-center"
      type="button"
      @click="expanded = !expanded"
    >
      <span class="text-sm font-medium">{{ title }}</span>
      <Chevron :expanded="expanded" />
    </button>
    <template v-slot:expanded>
      <List compact>
        <StudyProgramPickerListItem
          v-for="studyProgram in studyPrograms"
          :key="studyProgram.id"
          v-model="checked"
          :studyProgram="studyProgram"
          :data-testid="testIds.studyProgramPicker.studyProgram"
        />
      </List>
    </template>
  </Collapsible>
</template>

<script setup lang="ts">
import Collapsible from "@/components/common/collapsible/Collapsible.vue";
import List from "@/components/common/list/List.vue";
import StudyProgramPickerListItem from "./StudyProgramPickerListItem.vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import { ref, watchEffect } from "vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import Chevron from "@/components/common/chevron/Chevron.vue";
import { testIds } from "@/utils/testing";

const props = defineProps<{
  title: string;
  studyPrograms: LocalizedStudyProgramDTO[];
}>();

const checked = defineModel<string[]>({ default: [] });

const expanded = defineModel<boolean>("expanded", { default: false });

const groupChecked = ref<boolean | unknown[] | null>(false);

watchEffect(() => {
  const allChecked = props.studyPrograms.every((item) =>
    checked.value.includes(item.id),
  );
  const someChecked = props.studyPrograms.some((item) =>
    checked.value.includes(item.id),
  );

  groupChecked.value = allChecked ? true : someChecked ? null : false;
  if (!someChecked) {
    expanded.value = false;
  }
});

function updateStudyProgramsChecked(value: boolean | unknown[] | null) {
  checked.value = checked.value
    .filter((id) => !props.studyPrograms.some((sp) => sp.id === id))
    .concat(value ? props.studyPrograms.map((sp) => sp.id) : []);
}
</script>
