export enum DropdownOrigin {
  TopLeft = "top-left",
  TopRight = "top-right",
  TopCenter = "top-center",
}

export enum DropdownType {
  primary = "primary",
  white = "white",
}

export interface DropdownProps {
  label: string;
  origin: DropdownOrigin;
  type?: DropdownType;
  divider?: boolean;
  loading?: boolean;
}
