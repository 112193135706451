<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-2 pb-8">
    <ButtonBack :toRouteName="texts.generic.previous" @click="router.go(-1)" />
    <PageHeading>{{ emailTemplate?.name }}</PageHeading>
    <EmailTemplateForm
      v-if="
        fromEmailAddresses &&
        replyToEmailAddresses &&
        emailTemplateTypeVariables
      "
      v-slot="{ bee }"
      :initialValues="initialValues"
      :fromEmailAddresses="fromEmailAddresses"
      :replyToEmailAddresses="replyToEmailAddresses"
      :emailTemplateTypeVariables="emailTemplateTypeVariables"
      @submit="onSubmit"
    >
      <ButtonSubmit
        class="w-full lg:w-auto"
        :loading="isSubmitting"
        :disabled="bee.state.loading"
      />
      <ButtonCancel class="w-full lg:w-auto" @click="clickCancel" />
      <ButtonCopy class="w-full lg:w-auto" @click="clickCopy" />
      <ButtonDelete
        class="w-full lg:w-auto"
        @click="modalDeleteVisible = true"
      />
      <Toggle
        v-model="bee.state.preview"
        data-testid="toggle-preview"
        class="lg:flex-grow"
        :label="texts.actions.showPreview"
      />
    </EmailTemplateForm>
  </Page>
  <EmailTemplateDeleteModal
    v-if="emailTemplate"
    v-model:visible="modalDeleteVisible"
    :emailTemplateId="emailTemplate.id"
    @delete:succeeded="router.push({ name: RouteNamesSettings.EMAIL_OVERVIEW })"
  />
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import logger from "@/plugins/logger";
import { ref } from "vue";
import {
  EmailTemplateFormValues,
  toFormValues,
  toUpdateDto,
} from "@/views/settings/emails/components/email-template/EmailTemplateForm.types";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonCopy from "@/components/common/button/ButtonCopy.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import { useRouter } from "vue-router";
import { RouteNamesSettings } from "@/router/routeNames";
import texts from "@/utils/texts";
import Notify from "@/utils/notify";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import ButtonBack from "@/components/common/button/ButtonBack.vue";
import { EmailTemplateExtended } from "@/models/emailTemplates";
import {
  copyEmailTemplate,
  getEmailTemplate,
  getEmailTemplateTypeVariables,
  updateEmailTemplate,
} from "@/services/emailTemplates.service";
import EmailTemplateDeleteModal from "@/views/settings/emails/components/email-template/EmailTemplateDeleteModal.vue";
import { FromEmailAddress, ReplyToEmailAddress } from "@/models/email-address";
import {
  getFromEmailAdresses,
  getReplyToEmailAdresses,
} from "@/services/email-address";
import EmailTemplateForm from "@/views/settings/emails/components/email-template/EmailTemplateForm.vue";
import Toggle from "@/components/common/toggle/Toggle.vue";
import { loadEmailTemplates } from "@/store/context/settings.context";
import EmailTemplateTypeVariables from "@/models/emailTemplateVariables";

const props = defineProps<{
  id: string;
}>();

const loading = ref(false);
const error = ref(false);

const initialValues = ref<EmailTemplateFormValues>();
const emailTemplate = ref<EmailTemplateExtended>();
const fromEmailAddresses = ref<FromEmailAddress[] | undefined>();
const replyToEmailAddresses = ref<ReplyToEmailAddress[] | undefined>();
const emailTemplateTypeVariables = ref<EmailTemplateTypeVariables>();

const initialize = async () => {
  loading.value = true;
  try {
    const [template, variables, fromAddresses, replyToAddresses] =
      await Promise.all([
        getEmailTemplate(props.id),
        getEmailTemplateTypeVariables(),
        getFromEmailAdresses(true),
        getReplyToEmailAdresses(),
      ]);
    emailTemplate.value = template;
    fromEmailAddresses.value = fromAddresses;
    replyToEmailAddresses.value = replyToAddresses;
    emailTemplateTypeVariables.value = variables[template.type];
    initialValues.value = toFormValues(emailTemplate.value);
  } catch (e) {
    error.value = true;
    logger.error(e);
  } finally {
    loading.value = false;
  }
};

initialize();

const isSubmitting = ref(false);
const onSubmit = async (values: EmailTemplateFormValues) => {
  isSubmitting.value = true;
  try {
    if (!emailTemplate.value) {
      throw new Error("Cannot submit. No emailTemplate data initialized.");
    }
    const dto = toUpdateDto(values, emailTemplate.value.type);
    await updateEmailTemplate(emailTemplate.value.id, dto);
    await loadEmailTemplates();

    Notify.success(texts.notifications.edit.success, [
      texts.models.emailTemplate.title,
    ]);
  } catch (e) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.emailTemplate.title,
    ]);
    logger.error(e);
  } finally {
    isSubmitting.value = false;
  }
};

const router = useRouter();

const clickCancel = () => {
  router.push({ name: RouteNamesSettings.EMAIL_OVERVIEW });
};

const isCopying = ref<boolean>(false);
const clickCopy = async () => {
  isCopying.value = true;
  try {
    if (!emailTemplate.value) {
      throw new Error("Cannot copy. No emailTemplate data initialized.");
    }

    const response = await copyEmailTemplate(emailTemplate.value.id);
    await loadEmailTemplates();

    Notify.success(texts.notifications.copy.success, [
      texts.models.emailTemplate.title,
    ]);
    await router.push({
      name: RouteNamesSettings.EMAIL_OVERVIEW,
      params: { id: response.id },
    });
  } catch (e) {
    Notify.failure(texts.notifications.copy.failure, [
      texts.models.emailTemplate.title,
    ]);
    logger.error(e);
  } finally {
    isCopying.value = false;
  }
};

const modalDeleteVisible = ref(false);
</script>
