<template>
  <FormLayout @submit.prevent="submitForm">
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <FormFieldTextInput
      v-if="!disabled"
      :id="fieldIds.name"
      :label="texts.models.affiliate.name"
    />
    <FormFieldTextInput
      :id="fieldIds.emailAddress"
      :label="texts.models.affiliate.emailAddress"
      :disabled
    />
    <FormFieldSingleSelectCombobox
      :displayMode="FormFieldDisplayMode.Column"
      :formFieldId="fieldIds.preEducationSchoolId"
      :options="schools"
      :formatOptionDisplayString="(s) => s.name"
      :searchFilter="
        (school, query) =>
          school.name.toLowerCase().includes(query.toLowerCase())
      "
      :label="texts.models.affiliate.preEducationSchool"
      :disabled
    />
    <FormFieldSelect
      v-if="!disabled"
      :id="fieldIds.locale"
      :items="
        settings.availableLanguages.map((l) => ({
          label: l.name,
          value: l.locale.value,
        }))
      "
      :label="texts.models.affiliate.locale"
    ></FormFieldSelect>
    <template v-slot:actions>
      <slot name="actions"></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import FormLayout from "@/components/common/form/FormLayout.vue";
import {
  IAffiliateDto,
  IPreEducationSchool,
} from "@/lib/eduConfigurationServiceClient";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { Culture } from "@/enums";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import texts from "@/utils/texts";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { fields } from "@/utils/miscellaneous";
import FormFieldSingleSelectCombobox from "@/components/common/combobox/FormFieldSingleSelectCombobox.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import settings from "@/store/context/settings.context";

const props = defineProps<{
  initialValues?: IAffiliateDto;
  schools: IPreEducationSchool[];
  disabled?: boolean;
}>();

const fieldIds = fields<IAffiliateDto>();

const form = useForm({
  initialValues: props.initialValues,
  validationSchema: yup.object({
    id: yup.string(),
    name: yup.string().required(),
    locale: yup.mixed<Culture>().required(),
    emailAddress: yup.string().email().required(),
    preEducationSchoolId: yup.string(),
  }),
});

const emit = defineEmits<{
  submit: [values: IAffiliateDto];
}>();

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>
