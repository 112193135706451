<template>
  <Button
    :data-testid="testIds.action.delete"
    :color="Color.Alert"
    :buttonElementType="ButtonElementType.button"
    :leadingIcon="'delete'"
    :loading="loading"
    :disabled="disabled"
  >
    <template v-if="!$slots.default">{{ props.label }}</template>
    <slot v-else></slot>
  </Button>
</template>

<script setup lang="ts">
import { testIds } from "@/utils/testing";
import Button from "@/components/common/button/Button.vue";
import { ButtonElementType } from "@/components/common/button/Button.types";
import texts from "@/utils/texts";
import { Color } from "@/enums";

const props = withDefaults(
  defineProps<{
    loading?: boolean;
    disabled?: boolean;
    label?: string;
  }>(),
  {
    label: texts.actions.delete,
  },
);
</script>
