import {
  AnalyticDTO,
  IAnalyticDTO,
  IStyleDTOWithSassVariables,
  StyleDTO,
} from "@/lib/formsServiceClient";

export interface FormStylingFormValues {
  useDefaultGTM?: boolean;
  gtmCode?: string;
  gtmNoMessage?: boolean;
  useDefaultStyle?: boolean;
  bannerUrl?: string;
  bannerFile?: File[];
  faviconUrl?: string;
  faviconFile?: File[];
  bannerMargin?: string;
  bannerStretch?: boolean;
  colorBackground?: string;
  colorLink?: string;
  colorPrimary?: string;
  colorSecondary?: string;
  colorHeader?: string;
  colorText?: string;
  formWidth?: string;
  fontSize?: string;
  linkDecoration?: string;
  linkWeight?: string;
  fontText?: string;
  fontHeader?: string;
}

export const convertAnalyticToFormValues = (gtmSettings?: IAnalyticDTO) => {
  return {
    gtmCode: gtmSettings?.tagManagerCode,
    gtmNoMessage: gtmSettings?.tagManagerNoMsg,
  };
};

export const convertStyleToFormValues = (
  style?: IStyleDTOWithSassVariables,
) => {
  return {
    bannerUrl: style?.bannerUrl,
    bannerFile: [],
    faviconUrl: style?.faviconUrl,
    faviconFile: [],
    bannerMargin: style?.sass.smtBannerMargin,
    bannerStretch: style?.sass.smtBannerStretch,
    colorBackground: style?.sass.smtColorBackground,
    colorLink: style?.sass.smtColorLink,
    colorPrimary: style?.sass.smtColorPrimary,
    colorSecondary: style?.sass.smtColorSecondary,
    colorHeader: style?.sass.smtColorHeader,
    colorText: style?.sass.smtColorText,
    formWidth: style?.sass.smtContainerMaxWidth,
    fontSize: style?.sass.smtFontSize,
    linkDecoration: style?.sass.smtLinkTextDecoration,
    linkWeight: style?.sass.smtLinkFontWeight,
    fontText: style?.sass.smtFontText,
    fontHeader: style?.sass.smtFontHeader,
  };
};

export const convertFormValuesToAnalyticDto = (
  values: FormStylingFormValues,
) => {
  const dto = new AnalyticDTO({
    tagManagerCode: values.gtmCode,
    tagManagerNoMsg: values.gtmNoMessage,
    consoleLoggerDisable: false,
    googleAnalyticsDisable: false,
    piwikDisable: false,
    tagManagerDisable: false,
  });

  return dto;
};

export const convertFormValuesToStyleDto = (values: FormStylingFormValues) => {
  return new StyleDTO({
    bannerUrl: values.bannerUrl,
    faviconUrl: values.faviconUrl,
    sass: {
      smtBannerMargin: values.bannerMargin,
      smtBannerStretch: values.bannerStretch,
      smtBannerWidth: "auto",
      smtColorBackground: values.colorBackground,
      smtColorLink: values.colorLink,
      smtColorPrimary: values.colorPrimary,
      smtColorSecondary: values.colorSecondary,
      smtColorText: values.colorText,
      smtColorHeader: values.colorHeader,
      smtContainerMaxWidth: values.formWidth,
      smtFontHeader: values.fontHeader,
      smtFontSize: values.fontSize,
      smtFontText: values.fontText,
      smtLinkFontWeight: values.linkWeight,
      smtLinkTextDecoration: values.linkDecoration,
      smtColorSuccess: "#28a745",
      smtColorDanger: "#dc3545",
      smtLabelFontWeight: "normal",
      smtBranding: true,
    },
  });
};
