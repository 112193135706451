<template>
  <div :class="wrapperClass">
    <label
      v-if="label"
      :for="formFieldId"
      class="block text-sm font-medium text-gray-700"
      >{{ label }}</label
    >
    <Select
      :id="formFieldId"
      v-model="selectedOption"
      :name="formFieldId"
      :options="items"
      :disabled="disabled"
      :valid="!errorMessage"
      :allowUnset="allowUnset"
      :class="[
        { 'col-span-2': fullWidth && label, 'col-span-3': fullWidth && !label },
      ]"
      @update:modelValue="handleUpdate"
    ></Select>

    <div
      v-if="errorMessage"
      :class="['text-sm text-alert-500', { 'sm:col-start-2': label }]"
      data-testid="error-message"
    >
      {{ errorMessage }}
    </div>
    <div v-else-if="$slots.footer" :class="[{ 'sm:col-start-2': label }]">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";

import { SelectOption } from "./SelectOption";
import Select from "./Select.vue";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    formFieldId: string;
    items: SelectOption[];
    label?: string;
    disabled?: boolean;
    allowUnset?: boolean;
    fullWidth?: boolean;
    wrapperClass?: string;
  }>(),
  {
    label: undefined,
    disabled: false,
    allowUnset: false,
    fullWidth: false,
    wrapperClass:
      "mb-5 grid grid-cols-1 gap-1 sm:grid-cols-3 sm:items-center sm:gap-x-4",
  },
);

const emit = defineEmits<{
  (e: "update:selectedOption", value: SelectOption | undefined): void;
}>();

const { value, errorMessage } = useField<string | undefined>(props.formFieldId);

const selectedOption = computed<SelectOption | undefined>({
  get: () => {
    return props.items.filter((item) => item.value === value.value)[0];
  },
  set: (newValue) => {
    value.value = newValue?.value;
  },
});

const handleUpdate = () => {
  emit("update:selectedOption", selectedOption.value);
};
</script>
