import {
  getLocalizationsQueryable,
  Localization,
  QueryableLocalizations,
} from "./helpers/localizable";

export interface StudyProgramAreaOfInterestDTOLocalizableProperties {
  name: string;
}

export interface StudyProgramAreaOfInterestCreateDTO {
  sequenceNumber?: number;
  localizations: Localization<StudyProgramAreaOfInterestDTOLocalizableProperties>[];
}

export interface StudyProgramAreaOfInterestUpdateDTO
  extends StudyProgramAreaOfInterestCreateDTO {
  id: string;
}

export interface StudyProgramAreaOfInterestDTO {
  id: string;
  sequenceNumber?: number;
  name: string;
}

export interface StudyProgramAreaOfInterestExtendedDTO {
  id: string;
  sequenceNumber?: number;
  localizations: Localization<StudyProgramAreaOfInterestDTOLocalizableProperties>[];
}

export class StudyProgramAreaOfInterest {
  id: string;
  sequenceNumber?: number;
  name: string;

  constructor(dto: StudyProgramAreaOfInterestDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.name = dto.name;
  }
}

export class StudyProgramAreaOfInterestExtended {
  id: string;
  sequenceNumber?: number;
  localizations: QueryableLocalizations<StudyProgramAreaOfInterestDTOLocalizableProperties>;

  constructor(dto: StudyProgramAreaOfInterestExtendedDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.localizations = getLocalizationsQueryable(dto.localizations);
  }
}
