import env from "@/env";
import { Coordinates } from "@/lib/eduConfigurationServiceClient";
import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import { ref } from "vue";

const mapGlToken = env.VITE_MAPBOXGL_TOKEN;

let map: mapboxgl.Map;

// This Ref can be used by components rendering a map to get a printable snapshot of the map.
//  The snapshot is updated when the map stops moving, see map.on("moveend", () => { ... })
export const printableSnapshot = ref<string>();

export const createMap = (containerId: string, data: Coordinates[] = []) => {
  mapboxgl.accessToken = mapGlToken;

  map = new mapboxgl.Map({
    container: containerId,
    style: "mapbox://styles/mapbox/light-v10",
    scrollZoom: true,
    preserveDrawingBuffer: true,
  });

  // Add zoom and rotation controls to the map.
  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: true,
      showZoom: true,
    }),
  );

  // When the map stops moving, update the printable snapshot.
  map.on("moveend", () => {
    printableSnapshot.value = map.getCanvas().toDataURL();
  });

  map.on("load", () => {
    map.addSource("prospects", {
      type: "geojson",
      data: createMapBoxData(data),
      cluster: true,
      clusterMaxZoom: 15, // Max zoom to cluster points on
      clusterRadius: 40, // Radius of each cluster when clustering points (defaults to 50)
    });

    map.addLayer({
      id: "clusters",
      type: "circle",
      source: "prospects",
      // filter: ["has", "point_count"],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * 594EE7, 10px, 0.8 circles when point count is less than 5
        //   * 594EE7, 15px, 0.8 circles when point count is between 5 and 25
        //   * 7368F9, 20px, 0.6 circles when point count is between 25 and 100
        //   * A39BFC, 25px, 0.6 circles when point count is between 100 and 250
        //   * B3ADFA, 35px, 0.4 circles when point count is between 250 and 500
        //   * B3ADFA, 45px, 0.4 circles when point count is greater than 500
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#594EE7",
          5,
          "#594EE7",
          25,
          "#7368F9",
          100,
          "#A39BFC",
          250,
          "#B3ADFA",
          500,
          "#B3ADFA",
        ],
        "circle-radius": [
          "step",
          ["get", "point_count"],
          10,
          5,
          15,
          25,
          20,
          100,
          25,
          250,
          35,
          500,
          45,
        ],
        "circle-opacity": [
          "step",
          ["get", "point_count"],
          0.8,
          5,
          0.8,
          25,
          0.6,
          100,
          0.6,
          250,
          0.4,
          500,
          0.4,
        ],
        "circle-stroke-width": 1,
        "circle-stroke-opacity": 0.3,
        "circle-stroke-color": "#fff",
      },
    });

    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "prospects",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": [
          "step",
          ["get", "point_count"],
          10,
          5,
          12,
          25,
          14,
          100,
          15,
          250,
          16,
          500,
          18,
        ],
      },
    });

    map.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "prospects",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": "#7368F9",
        "circle-radius": 5,
      },
    });

    // inspect a cluster on click
    map.on("click", "clusters", (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      }) as GeoJSON.Feature<GeoJSON.Point>[];
      const clusterId = features[0]?.properties?.cluster_id;
      const source = map.getSource("prospects") as GeoJSONSource;
      source.getClusterExpansionZoom(
        clusterId,
        (error: Error | null | undefined, zoom: number | null | undefined) => {
          if (error) return;

          map.easeTo({
            center: features[0]?.geometry?.coordinates as [number, number],
            zoom: zoom ? zoom : undefined,
          });
        },
      );
    });

    map.on("mouseenter", "clusters", () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "clusters", () => {
      map.getCanvas().style.cursor = "";
    });

    flyToCenter(data);
  });
};

export const setMapData = (coordinates: Coordinates[]) => {
  if (!map) {
    return;
  }

  const prospectSource = map.getSource("prospects") as GeoJSONSource;
  if (!prospectSource) {
    return;
  }

  flyToCenter(coordinates);

  prospectSource.setData(createMapBoxData(coordinates));
};

const createMapBoxData = (coordinates: Coordinates[]) => {
  const collection: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: "FeatureCollection",
    features: [
      ...coordinates.map((coordinate) => {
        const feature: GeoJSON.Feature<GeoJSON.Point> = {
          properties: {},
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [coordinate.longitude, coordinate.latitude],
          },
        };
        return feature;
      }),
    ],
  };
  return collection;
};

const flyToCenter = (coordinates: Coordinates[]) => {
  if (coordinates.length === 0) return;

  const center = calculateCentroid(coordinates);

  map.flyTo({
    zoom: 7,
    center: [center.lng, center.lat],
    essential: true, // this animation is considered essential with respect to prefers-reduced-motion
  });
};

function calculateCentroid(coordinates: Coordinates[]) {
  const toRadians = (degree: number) => degree * (Math.PI / 180);
  const toDegrees = (radian: number) => radian * (180 / Math.PI);

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  coordinates.forEach((coord) => {
    const latitude = toRadians(coord.latitude);
    const longitude = toRadians(coord.longitude);

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  });

  const total = coordinates.length;

  x = x / total;
  y = y / total;
  z = z / total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    lat: toDegrees(centralLatitude),
    lng: toDegrees(centralLongitude),
  };
}
