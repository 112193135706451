import { Culture } from "@/enums";
import { FormDTO } from "@/lib/formsServiceClient";

export interface FormTemplateFormValues {
  redirectEnabled: boolean;
  localizations: Record<string, FormTemplateFormValuesLocalization>;
}

export interface FormTemplateFormValuesLocalization {
  label: string;
  confirmationPage?: string;
  redirectUrl?: string;
}

export function mapToDto(dto: FormDTO, values: FormTemplateFormValues) {
  dto.localizations.forEach((localization) => {
    const locale = localization.locale as Culture;

    localization.name = values.localizations[locale].label;
    localization.label = values.localizations[locale].label;

    localization.confirmationPage = values.redirectEnabled
      ? undefined
      : values.localizations[locale].confirmationPage;

    localization.redirectUrl = values.redirectEnabled
      ? values.localizations[locale].redirectUrl
      : undefined;
  });
}

export function convertToFormValues(dto: FormDTO): FormTemplateFormValues {
  return {
    redirectEnabled: !!dto.localizations[0]?.redirectUrl,
    localizations: dto.localizations.reduce(
      (acc, localization) => ({
        ...acc,
        [localization.locale]: {
          label: localization.label,
          confirmationPage: localization.confirmationPage,
          redirectUrl: localization.redirectUrl,
        },
      }),
      {},
    ),
  };
}
