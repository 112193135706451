import {
  StudyProgramMetadataLocalization,
  StudyProgramMetadataLocalizationDTO,
} from "@/models/study-program-metadata-localization";
import HttpClient from "@/utils/httpClient";

const studyProgramMetadataUrl = "localizations/study-program-metadata";

export const getStudyProgramMetadataLocalizations =
  async (): Promise<StudyProgramMetadataLocalization> => {
    return await HttpClient.educonfig
      .get<StudyProgramMetadataLocalizationDTO>(studyProgramMetadataUrl)
      .then((response) => new StudyProgramMetadataLocalization(response.data));
  };

export const updateStudyProgramMetadataLocalizations = async (
  studyProgramMetadataLocalizations: StudyProgramMetadataLocalizationDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    studyProgramMetadataUrl,
    studyProgramMetadataLocalizations,
  );
};
