import { Timezone } from "@/enums";
import {
  RegistrationLinkDTO,
  RegistrationLinkExtendedDTO,
  RegistrationLink,
  RegistrationLinkExtended,
  RegistrationLinkWithRegistrationCount,
  RegistrationLinkWithRegistrationCountDTO,
} from "@/models/registrationLink";
import { downloadFileResponse } from "@/utils/downloadFile";
import HttpClient from "@/utils/httpClient";

export const getRegistrationLinks = async (
  activityId: string,
): Promise<RegistrationLink[]> => {
  return await HttpClient.educonfig
    .get<RegistrationLinkDTO[]>(`activities/${activityId}/registration-links`)
    .then((response) => response.data.map((dto) => new RegistrationLink(dto)));
};

export const getRegistrationLinksWithRegistrationCount = async (
  activityId: string,
): Promise<RegistrationLinkWithRegistrationCount[]> => {
  return await HttpClient.educonfig
    .get<
      RegistrationLinkWithRegistrationCountDTO[]
    >(`activities/${activityId}/registration-links/with-registration-count`)
    .then((response) =>
      response.data.map(
        (dto) => new RegistrationLinkWithRegistrationCount(dto),
      ),
    );
};

export const getRegistrationLink = async (
  activityId: string,
  registrationLinkId: string,
): Promise<RegistrationLinkExtended> => {
  return await HttpClient.educonfig
    .get<RegistrationLinkExtendedDTO>(
      `activities/${activityId}/registration-links/${registrationLinkId}`,
    )
    .then((response) => new RegistrationLinkExtended(response.data));
};

export const deleteRegistrationLink = async (
  activityId: string,
  registrationLinkId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(
    `activities/${activityId}/registration-links/${registrationLinkId}`,
  );
};

export const downloadRegistrationLinkRegistrantsExportCSV = async (
  registrationLinkId: string,
): Promise<void> => {
  return await HttpClient.educonfig
    .get<Blob>(
      `registration-links/${registrationLinkId}/registrations/export`,
      { responseType: "blob", params: { timezone: Timezone.Amsterdam } },
    )
    .then((response) => downloadFileResponse(response));
};

export const downloadPreselectedRegistrationLinksExcel = async (
  registrationLinkId: string,
): Promise<void> => {
  return await HttpClient.educonfig
    .get<Blob>(
      `registration-links/${registrationLinkId}/links/export/preselected`,
      {
        responseType: "blob",
      },
    )
    .then((response) => downloadFileResponse(response));
};

export const downloadPrefilteredRegistrationLinksExcel = async (
  registrationLinkId: string,
): Promise<void> => {
  return await HttpClient.educonfig
    .get<Blob>(
      `registration-links/${registrationLinkId}/links/export/prefiltered`,
      {
        responseType: "blob",
      },
    )
    .then((response) => downloadFileResponse(response));
};

export const downloadOnSiteRegistrationLinksExcel = async (
  registrationLinkId: string,
): Promise<void> => {
  return await HttpClient.educonfig
    .get<Blob>(`registration-links/${registrationLinkId}/links/export/onsite`, {
      responseType: "blob",
    })
    .then((response) => downloadFileResponse(response));
};
