<template>
  <Async :state="state">
    <template v-slot:failure>
      <ErrorComponent />
    </template>
    <template v-slot:loading>
      <Loader />
    </template>
    <template v-slot>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
          <h2 class="text-lg font-semibold text-deepteal-700">
            {{ apiKeyTexts.title }}
          </h2>
          <span class="text-sm font-light text-deepteal-700">
            {{ apiKeyTexts.description }}
            <a
              :href="`${apiBaseUrl}/docs/v1`"
              target="_blank"
              class="font-semibold text-royal-500 underline hover:text-royal-800"
              >{{ apiKeyTexts.linkToApiDocs }}</a
            >.</span
          >
        </div>
        <template v-if="!!apiCredentials">
          <div class="flex flex-col gap-1">
            <label
              for="client-id"
              class="text-sm font-semibold text-deepteal-700"
              >{{ texts.models.apiClient.clientId }}</label
            >
            <TextInputWithCopy
              id="client-id"
              v-model="apiCredentials.clientId"
              data-testid="clientId"
            ></TextInputWithCopy>
          </div>
          <div class="flex flex-col gap-1">
            <label
              for="client-secret"
              class="text-sm font-semibold text-deepteal-700"
              >{{ texts.models.apiClient.clientSecret }}</label
            >
            <TextInputWithCopy
              id="client-secret"
              v-model="apiCredentials.clientSecret"
              data-testid="clientSecret"
              :isSecret="true"
            ></TextInputWithCopy>
          </div>
          <div class="flex flex-col gap-1">
            <label
              for="audience"
              class="text-sm font-semibold text-deepteal-700"
              >{{ texts.models.apiClient.audience }}</label
            >
            <TextInputWithCopy
              id="audience"
              v-model="apiCredentials.audience"
              data-testid="audience"
            ></TextInputWithCopy>
          </div>
        </template>
        <template v-else>
          <Button
            data-testid="generateAPIClientButton"
            :loading="loadingGenerateAPIClient"
            :label="apiKeyTexts.createLabel"
            @click="generateAPIClient"
          ></Button>
        </template>
      </div>
    </template>
  </Async>
</template>

<script setup lang="ts">
import Button from "@/components/common/button/Button.vue";
import TextInputWithCopy from "@/components/common/text-input/TextInputWithCopy.vue";
import { ApiClientDTO } from "@/models/apiKey";
import { createAPIClient, getAPIClient } from "@/services/apiKey.service";
import { ref } from "vue";
import Notify from "@/utils/notify";
import { useAsyncState } from "@/components/common/async/Async.types";
import { AxiosError } from "axios";
import Async from "@/components/common/async/Async.vue";
import Loader from "@/components/common/loader/Loader.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import texts from "@/utils/texts";
import logger from "@/plugins/logger";

const apiKeyTexts = texts.navigationItems.manage.settings.general.apiKey;

const apiCredentials = ref<ApiClientDTO | undefined>(undefined);

const { state, handler } = useAsyncState(async () => {
  try {
    const response = await getAPIClient();
    apiCredentials.value = response;
  } catch (e) {
    // When API returns a 404, no result was found, but we shouldn't throw an error page, instead we show a button to create credentials
    if ((e as AxiosError).response?.status === 404) {
      apiCredentials.value = undefined;
    } else {
      throw e;
    }
  }
});

handler();

// creating API credentials
const loadingGenerateAPIClient = ref(false);
const generateAPIClient = () => {
  loadingGenerateAPIClient.value = true;
  return createAPIClient()
    .then((response) => {
      apiCredentials.value = response;
      Notify.success(apiKeyTexts.create.success);
    })
    .catch((error) => {
      logger.error(error);
      Notify.failure(apiKeyTexts.create.failure);
    })
    .finally(() => {
      loadingGenerateAPIClient.value = false;
    });
};

const apiBaseUrl = import.meta.env.VITE_EXTERNAL_API_BASE_URL;
</script>
