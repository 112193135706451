<template>
  <PopoverWrapper :panelOrigin>
    <template v-slot:button>
      <PopoverButton
        :as="Button"
        :color="Color.Gray"
        :size="ButtonSize.sm"
        :trailingIcon="'keyboard_arrow_down'"
        :disabled
        round
        :data-testid="testIds.dashboard.studyProgramFilter"
      >
        <span>{{ componentTexts.studyProgramsPicker.title }}</span>
        <span
          class="flex items-center justify-center rounded-full bg-deepteal-400 px-1.5 text-xs font-medium text-white"
          >{{ definition.filters.studyProgramIds.length }}</span
        >
      </PopoverButton>
    </template>
    <template v-slot:panel="{ close }">
      <FormLayout
        class="min-w-96 rounded-lg bg-white pt-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:min-h-[32rem] sm:w-[37rem]"
        @submit.prevent="onSubmit(close)"
      >
        <FormFieldStudyProgramPicker id="selectedIds" :studyPrograms />
        <template v-slot:actions>
          <div class="flex flex-row gap-4">
            <button
              class="cursor-pointer text-sm font-medium text-emerald-500 hover:text-emerald-700"
              type="submit"
              :data-testid="testIds.action.confirm"
            >
              {{ texts.actions.apply }}
            </button>
            <button
              class="cursor-pointer text-sm font-medium text-emerald-500 hover:text-emerald-500"
              type="button"
              :data-testid="testIds.action.cancel"
              @click="handleCancel(close)"
            >
              {{ texts.actions.cancel }}
            </button>
          </div>
        </template>
      </FormLayout>
    </template>
  </PopoverWrapper>
</template>

<script setup lang="ts">
import {
  ISelectionDefinitionDto,
  LocalizedStudyProgramDTO,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { watch } from "vue";
import FormFieldStudyProgramPicker from "@/components/study-programs/study-program-picker/FormFieldStudyProgramPicker.vue";
import PopoverWrapper from "@/components/common/popover/PopoverWrapper.vue";
import { PanelOrigin } from "@/components/common/popover/PopoverWrapper.types";
import FormLayout from "@/components/common/form/FormLayout.vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { Color } from "@/enums";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import { PopoverButton } from "@headlessui/vue";
import { testIds } from "@/utils/testing";

withDefaults(
  defineProps<{
    studyPrograms: LocalizedStudyProgramDTO[];
    panelOrigin?: PanelOrigin;
    disabled?: boolean;
  }>(),
  {
    panelOrigin: PanelOrigin.TopLeft,
    disabled: false,
  },
);

const componentTexts = texts.navigationItems.dashboard;

const definition = defineModel<ISelectionDefinitionDto>("definition", {
  required: true,
});

type FormValues = {
  selectedIds: string[];
};
const form = useForm<FormValues>({
  validationSchema: yup.object({
    selectedIds: yup.array(yup.string()).required(),
  }),
});

const onSubmit = (closePopover: () => void) => {
  form.handleSubmit(() => {
    definition.value.filters.studyProgramIds = form.values.selectedIds;
    closePopover();
  })();
};

const handleCancel = async (close: () => void) => {
  form.resetForm({
    values: {
      selectedIds: definition.value.filters.studyProgramIds,
    },
  });
  close();
};

watch(
  () => definition.value.filters.studyProgramIds,
  (values) => {
    form.setValues({
      selectedIds: values,
    });
  },
  { immediate: true },
);
</script>
