import { Culture } from "@/enums";
import {
  IStudyProgramMetadataLocalizationDto,
  StudyProgramMetadataLocalizationDto,
} from "@/lib/eduConfigurationServiceClient";
import { normalizeLocalizations } from "@/models/helpers/localizable";

export enum StudyProgramMetadataLocalizationFormKeys {
  areaOfInterestLocalizationNL = "areaOfInterestLocalizationNL",
  areaOfInterestLocalizationEN = "areaOfInterestLocalizationEN",
  departmentLocalizationNL = "departmentLocalizationNL",
  departmentLocalizationEN = "departmentLocalizationEN",
  levelLocalizationNL = "levelLocalizationNL",
  levelLocalizationEN = "levelLocalizationEN",
  locationLocalizationNL = "locationLocalizationNL",
  locationLocalizationEN = "locationLocalizationEN",
  modeLocalizationNL = "modeLocalizationNL",
  modeLocalizationEN = "modeLocalizationEN",
}

export interface StudyProgramMetadataLocalizationFormValues {
  areaOfInterestLocalizations: StudyProgramMetadataLocalizationFormValuesLocalization[];
  departmentLocalizations: StudyProgramMetadataLocalizationFormValuesLocalization[];
  levelLocalizations: StudyProgramMetadataLocalizationFormValuesLocalization[];
  locationLocalizations: StudyProgramMetadataLocalizationFormValuesLocalization[];
  modeLocalizations: StudyProgramMetadataLocalizationFormValuesLocalization[];
}

type StudyProgramMetadataLocalizationFormValuesLocalization = {
  locale: Culture;
  name: string;
};

export const convertToFormValues = (
  localizations: StudyProgramMetadataLocalizationDto,
): StudyProgramMetadataLocalizationFormValues => {
  const areaOfInterestLocalizations = normalizeLocalizations(
    localizations.areaOfInterestLocalizations as StudyProgramMetadataLocalizationFormValuesLocalization[],
  );
  const departmentLocalizations = normalizeLocalizations(
    localizations.departmentLocalizations as StudyProgramMetadataLocalizationFormValuesLocalization[],
  );
  const levelLocalizations = normalizeLocalizations(
    localizations.levelLocalizations as StudyProgramMetadataLocalizationFormValuesLocalization[],
  );
  const locationLocalizations = normalizeLocalizations(
    localizations.locationLocalizations as StudyProgramMetadataLocalizationFormValuesLocalization[],
  );
  const modeLocalizations = normalizeLocalizations(
    localizations.modeLocalizations as StudyProgramMetadataLocalizationFormValuesLocalization[],
  );

  return {
    areaOfInterestLocalizations,
    departmentLocalizations,
    levelLocalizations,
    locationLocalizations,
    modeLocalizations,
  };
};

export const convertToDTO = (
  formValues: StudyProgramMetadataLocalizationFormValues,
): IStudyProgramMetadataLocalizationDto => {
  return {
    areaOfInterestLocalizations: formValues.areaOfInterestLocalizations.filter(
      (loc) => loc.name,
    ),
    departmentLocalizations: formValues.departmentLocalizations.filter(
      (loc) => loc.name,
    ),
    levelLocalizations: formValues.levelLocalizations.filter((loc) => loc.name),
    locationLocalizations: formValues.locationLocalizations.filter(
      (loc) => loc.name,
    ),
    modeLocalizations: formValues.modeLocalizations.filter((loc) => loc.name),
  };
};
