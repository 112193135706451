<template>
  <Section>
    <Section>
      <PageHeading :title="texts.navigationItems.informationRequests.title" />
    </Section>
    <RouterNavigationTabs
      :tabs="[
        {
          name: texts.navigationItems.brochures.title,
          to: { name: RouteNamesInformationRequests.BROCHURES },
          icon: 'menu_book',
        },
        {
          name: texts.navigationItems.keepMeInformed.title,
          to: { name: RouteNamesInformationRequests.KEEPMEINFORMED },
          icon: 'loyalty',
        },
        {
          name: texts.navigationItems.thirdPartyProspectSources.title,
          to: { name: RouteNamesInformationRequests.THIRDPARTYPROSPECTSOURCES },
          icon: 'alt_route',
        },
      ]"
    ></RouterNavigationTabs>
  </Section>
  <RouterView />
</template>

<script setup lang="ts">
import { useStore } from "vuex";
import { RootState } from "@/store";

import dictionary from "@/dictionary";
import { Culture } from "@/enums";

import Section from "@/components/common/section/Section.vue";
import RouterNavigationTabs from "@/components/router/router-navigation-tabs/RouterNavigationTabs.vue";
import { RouteNamesInformationRequests } from "@/router/routeNames";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import { RouterView } from "vue-router";

const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];
</script>
