<template>
  <span
    :class="[
      {
        'text-xs font-bold': size === CardSize.xs,
        'text-sm font-bold': size === CardSize.sm,
        'text-base font-semibold': size === CardSize.md,
        'text-lg font-semibold': size === CardSize.lg,
        'text-xl font-medium': size === CardSize.xl,
      },
    ]"
  >
    {{ strippedText }}<slot></slot>
  </span>
</template>

<script setup lang="ts">
import { CardSize } from "@/components/common/card/Card.types";
import { computed } from "vue";

const props = withDefaults(defineProps<{ size?: CardSize; text: string }>(), {
  size: CardSize.md,
});

// Computed property to strip HTML tags while allowing single < characters
const strippedText = computed(() => {
  return props.text.replace(/<[^<>]+>/g, "");
});
</script>
