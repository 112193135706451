import { BadgeType } from "@/components/common/badge/BadgeProps";
import { MailingStatus, MailingType } from "@/enums";
import { Mailing } from "@/models/mailing";
import { DateTime } from "luxon";

export const convertToBadgeType = (mailingStatus: MailingStatus) => {
  switch (mailingStatus) {
    case MailingStatus.Concept:
      return BadgeType.default;
    case MailingStatus.Planned:
      return BadgeType.info;
    case MailingStatus.InProgress:
      return BadgeType.info;
    case MailingStatus.Sent:
      return BadgeType.success;
    case MailingStatus.Failed:
      return BadgeType.danger;
    default:
      return BadgeType.default;
  }
};

export const convertMailingTypeToBadgeType = (type: MailingType) => {
  switch (type) {
    case MailingType.Activity:
      return BadgeType.emerald;
    case MailingType.ActivityInvite:
      return BadgeType.sunflower;
    case MailingType.ActivityVisitedSurvey:
      return BadgeType.coral;
    case MailingType.ActivityReminder:
      return BadgeType.lavender;
    default:
      return BadgeType.default;
  }
};

export const formattedDateRange = (mailing: Mailing) => {
  if (
    mailing.status === MailingStatus.Concept ||
    mailing.status === MailingStatus.InProgress ||
    mailing.status === MailingStatus.Failed
  ) {
    return undefined;
  }

  return mailing.plannedDateTime
    ? mailing.plannedDateTime.toLocaleString(DateTime.DATETIME_MED)
    : undefined;
};
