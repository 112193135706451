<template>
  <Modal
    v-model:visible="visibleInternal"
    :type="ModalType.warning"
    :description="
      account.isBlocked
        ? texts.navigationItems.account.modal.unblockAccount.description
        : texts.navigationItems.account.modal.blockAccount.description
    "
    :title="
      account.isBlocked
        ? texts.navigationItems.account.modal.unblockAccount.title
        : texts.navigationItems.account.modal.blockAccount.title
    "
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          :data-testid="account.isBlocked ? 'confirm-unblock' : 'confirm-block'"
          class="sm:ml-3"
          :color="Color.Warning"
          :label="
            account.isBlocked ? texts.actions.unblock : texts.actions.block
          "
          :loading="actionLoading"
          :disabled="!visibleInternal"
          @click="toggleBlock"
        />
        <Button
          class="mt-3 sm:mt-0"
          :color="Color.White"
          :label="texts.actions.cancel"
          :disabled="actionLoading || !visibleInternal"
          @click="visibleInternal = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import Button from "@/components/common/button/Button.vue";
import { computed, ref } from "vue";
import texts from "@/utils/texts";
import Account from "@/models/account";
import { blockAccount, unblockAccount } from "@/services/account.service";
import Notify from "@/utils/notify";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Color } from "@/enums";

const props = defineProps<{
  account: Account;
  visible: boolean;
}>();

const emit = defineEmits<{
  "update:visible": [value: boolean];
}>();

const store = useStore<RootState>();

const actionLoading = ref(false);
const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const toggleBlock = () => {
  actionLoading.value = true;

  const blockPromise = props.account.isBlocked
    ? unblockAccount(props.account.id)
    : blockAccount(props.account.id);

  return blockPromise
    .then(() => {
      Notify.success(texts.navigationItems.account.edit.success);

      store.dispatch("accountStore/loadAccounts");

      visibleInternal.value = false;
    })
    .catch((err) => {
      Notify.failure(texts.navigationItems.account.edit.failure);
      throw err;
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
