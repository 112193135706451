<template>
  <FormLayout @submit="submitForm">
    <SectionHeading
      :title="texts.navigationItems.account.form.basicInformationTitle"
      :description="
        texts.navigationItems.account.form.basicInformationDescription
      "
      :divider="false"
    ></SectionHeading>
    <SelectField
      formFieldId="accountType"
      :label="texts.navigationItems.account.form.accountTypeLabel"
      data-testid="accountType"
      :items="selectItems"
      :disabled="!!formValues"
      :fullWidth="true"
    />
    <InputField
      formFieldId="username"
      :label="texts.navigationItems.account.form.usernameLabel"
      data-testid="username"
      :disabled="!!formValues"
      :fullWidth="true"
    />
    <InputField
      formFieldId="name"
      :label="texts.navigationItems.account.form.nameLabel"
      data-testid="name"
      :fullWidth="true"
    />

    <SectionDivider />

    <SectionHeading
      :title="texts.navigationItems.account.form.rolesTitle"
      :description="texts.navigationItems.account.form.rolesDescription"
      :divider="false"
    ></SectionHeading>
    <MultiCheckboxField
      formFieldId="roles"
      :items="checkboxItems"
      data-testid="roles"
      :fullWidth="true"
    />
    <SectionDivider />
    <SectionHeading
      :title="texts.navigationItems.account.form.studyPrograms"
      :description="texts.navigationItems.account.form.studyProgramsDescription"
      :divider="false"
    ></SectionHeading>
    <StudyProgramsField
      class="flex"
      formFieldId="studyProgramIds"
      :studyPrograms="settings.allStudyPrograms"
      :selectedLabel="
        texts.navigationItems.account.form.studyProgramsFieldSelected
      "
      :slideOverTitle="
        texts.navigationItems.account.form.studyProgramsFieldSlideOver.title
      "
      :slideOverSubTitle="
        texts.navigationItems.account.form.studyProgramsFieldSlideOver.subTitle
      "
      :submitLabelText="texts.actions.select"
    ></StudyProgramsField>
    <SectionDivider />
    <SectionHeading
      :title="texts.navigationItems.account.form.permissionsTitle"
      :description="texts.navigationItems.account.form.permissionsDescription"
      :divider="false"
    ></SectionHeading>
    <div class="flex flex-col">
      <MultiCheckboxField
        formFieldId="activityTypeIds"
        :items="activityTypes"
      />
      <SectionDivider />
      <FormFieldCheckbox
        id="cantViewPersonalData"
        :label="texts.navigationItems.account.form.cantViewPersonalDataLabel"
        data-testid="cantViewPersonalData"
      />
      <div class="mb-5"></div>
      <SectionDivider />
      <FormFieldCheckbox
        id="cantViewAndSendMailings"
        :label="texts.navigationItems.account.form.cantViewAndSendMailingsLabel"
        data-testid="cantViewAndSendMailings"
      />
    </div>
    <template v-slot:actions> <slot></slot> </template>
  </FormLayout>
</template>

<script setup lang="ts">
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import SectionDivider from "@/components/common/section/SectionDivider.vue";
import InputField from "@/components/common/text-input/InputField.vue";
import SelectField from "@/components/common/select/SelectField.vue";
import MultiCheckboxField from "@/components/common/checkbox/MultiCheckboxField.vue";
import StudyProgramsField from "@/components/study-programs/study-program-picker/StudyProgramsField.vue";
import { MultiCheckboxFieldItem } from "@/components/common/checkbox/MultiCheckboxField.types";
import { SelectOption } from "@/components/common/select/SelectOption";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { AccountType, Role } from "@/models/account";
import { AccountFormValues } from "@/views/settings/users/AccountForm.types";
import settings from "@/store/context/settings.context";
import FormFieldCheckbox from "@/components/common/checkbox/FormFieldCheckbox.vue";
import FormLayout from "@/components/common/form/FormLayout.vue";

const props = defineProps<{ formValues?: AccountFormValues }>();

const emit = defineEmits<{
  (e: "submit", values: AccountFormValues): void;
}>();

// Store and router setup
const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const activityTypes = settings.activityTypes.map((activityType) => ({
  id: activityType.id,
  label: activityType.name,
  value: activityType.id,
}));

const selectItems: Array<SelectOption> = [
  {
    value: AccountType.SUMMIT,
    label: texts.enums.accountTypes[AccountType.SUMMIT],
  },
  {
    value: AccountType.SURFCONEXT,
    label: texts.enums.accountTypes[AccountType.SURFCONEXT],
  },
];

const checkboxItems: Array<MultiCheckboxFieldItem> = [
  {
    id: Role.ADMINISTRATOR,
    value: Role.ADMINISTRATOR,
    label: texts.navigationItems.account.roles.administrator.title,
    description: texts.navigationItems.account.roles.administrator.description,
  },
  {
    id: Role.EVENTORGANIZER,
    value: Role.EVENTORGANIZER,
    label: texts.navigationItems.account.roles.eventOrganizer.title,
    description: texts.navigationItems.account.roles.eventOrganizer.description,
  },
  {
    id: Role.ACTIVITYVIEWER,
    value: Role.ACTIVITYVIEWER,
    label: texts.navigationItems.account.roles.activityViewer.title,
    description: texts.navigationItems.account.roles.activityViewer.description,
  },
];

const form = useForm({
  validationSchema: yup.object({
    accountType: yup
      .string()
      .required()
      .oneOf([AccountType.SUMMIT, AccountType.SURFCONEXT])
      .required(),
    username: yup.string().required().email().max(254),
    name: yup.string().max(32).required(),
    roles: yup
      .array()
      .of(
        yup
          .string()
          .oneOf([Role.ADMINISTRATOR, Role.EVENTORGANIZER, Role.ACTIVITYVIEWER])
          .required(),
      )
      .min(1)
      .required(),
    studyProgramIds: yup.array().of(yup.string().required()).min(0).required(),
    activityTypeIds: yup.array().of(yup.string()).min(0),
    cantViewPersonalData: yup.boolean(),
    cantViewAndSendMailings: yup.boolean(),
  }),
});

if (props.formValues) {
  form.setValues({
    accountType: props.formValues.accountType,
    username: props.formValues.username,
    name: props.formValues.name,
    roles: props.formValues.roles,
    studyProgramIds: props.formValues.studyProgramIds,
    activityTypeIds: props.formValues.activityTypeIds,
    cantViewPersonalData: props.formValues.cantViewPersonalData,
    cantViewAndSendMailings: props.formValues.cantViewAndSendMailings,
  });
} else {
  form.setValues({
    accountType: AccountType.SUMMIT,
    activityTypeIds: [],
  });
}

const submitForm = form.handleSubmit((values) => {
  // I've been unable to remove this type assertion. Fixable through updating yup to v1.2.0,
  // and using vee-validate/yup extension toTypedSchema. However, it broke many other things so I've reverted it.
  // https://summit-dev.atlassian.net/jira/software/projects/EDU/boards/2?selectedIssue=EDU-3964
  emit("submit", values as AccountFormValues);
});
</script>
