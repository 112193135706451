<template>
  <AnchorMenu :items="items" />
</template>

<script setup lang="ts">
import { AnchorMenuItem } from "@/components/common/anchor-menu/AnchorMenu.types";
import texts from "@/utils/texts";
import AnchorMenu from "@/components/common/anchor-menu/AnchorMenu.vue";
import ModeOverview from "@/views/settings/study-programs/modes/Overview.vue";
import LevelOverview from "@/views/settings/study-programs/levels/Overview.vue";
import LocationOverview from "@/views/settings/study-programs/locations/Overview.vue";
import DepartmentOverview from "@/views/settings/study-programs/departments/Overview.vue";
import AreasOfInterestOverview from "@/views/settings/study-programs/areas-of-interest/Overview.vue";
import StudyProgramSettings from "@/views/settings/study-programs/study-program-settings/StudyProgramSettings.vue";

const items: AnchorMenuItem[] = [
  {
    id: "modes",
    label: texts.models.studyProgramMode.title,
    component: ModeOverview,
  },
  {
    id: "levels",
    label: texts.models.studyProgramLevel.title,
    component: LevelOverview,
  },
  {
    id: "locations",
    label: texts.models.studyProgramLocation.title,
    component: LocationOverview,
  },
  {
    id: "departments",
    label: texts.models.studyProgramDepartment.title,
    component: DepartmentOverview,
  },
  {
    id: "areas-of-interest",
    label: texts.models.studyProgramAreaOfInterest.title,
    component: AreasOfInterestOverview,
  },
  {
    id: "study-program-settings",
    label: texts.navigationItems.manage.settings.studyProgramSettings.title,
    component: StudyProgramSettings,
  },
];
</script>
