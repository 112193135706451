import { Localization } from "@/models/helpers/localizable";

export interface ActivityTypeDTO {
  id: string;
  name: string;
}

export interface ActivityTypeDTOLocalizableProperties {
  name: string;
}

export interface ActivityTypeExtendedDTO extends ActivityTypeDTO {
  localizations: Localization<ActivityTypeDTOLocalizableProperties>[];
}
export interface ActivityTypeCreateDTO {
  localizations: Localization<ActivityTypeDTOLocalizableProperties>[];
}

export interface ActivityTypeUpdateDTO extends ActivityTypeCreateDTO {
  id: string;
}

export default class ActivityType {
  id: string;
  name: string;

  constructor(dto: ActivityTypeDTO) {
    this.id = dto.id;
    this.name = dto.name;
  }
}

export class ActivityTypeExtended extends ActivityType {
  localizations: Localization<ActivityTypeDTOLocalizableProperties>[];

  constructor(dto: ActivityTypeExtendedDTO) {
    super(dto);
    this.localizations = dto.localizations;
  }
}
