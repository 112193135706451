import { RouteLocation } from "vue-router";
import auth from "@/store/context/auth.context";
import { RouteMeta } from "@/router/routeNames";
import { Permission } from "@/lib/eduConfigurationServiceClient";

export const userCanGoTo = (to: RouteLocation): boolean => {
  // If the to-location does not require authentication
  // then the user is authorized to go to location
  const noAuthRequired = to.meta[RouteMeta.NO_AUTH] as boolean;
  if (noAuthRequired) return true;

  // The user should always be able to read tenant data
  const defaultPermissions = [Permission.CurrenttenantRead];

  // If the user has all permissions given by to-location
  // then it is authorized to go to location
  const requiredPermissions = [
    ...defaultPermissions,
    ...((to.meta["permissions"] as Permission[]) ?? []),
  ];

  return hasPermissions(requiredPermissions);
};

export const hasPermissions = (permissions: Permission[]) => {
  return permissions.every((permission) =>
    auth.permissions.includes(permission),
  );
};

export const canViewPersonalData = () => {
  return hasPermissions([Permission.PersonaldataRead]);
};
