<template>
  <LayoutTwoCol>
    <template v-slot:left>
      <ProspectCountStats
        :prospectCount="prospectCountStats?.prospectCount"
        :title="componentTexts.stats.uniqueProspects"
        :subtitle="componentTexts.stats.currentRecruitmentYear"
        :color="color"
      />
    </template>
    <ProspectStatsPerMonth
      :color="color"
      :fromDate="fromDate"
      :toDate="toDate"
      :prospectCountsPerMonth="prospectCountsPerMonth"
      class="h-48"
    />
  </LayoutTwoCol>
</template>

<script setup lang="ts">
import LayoutTwoCol from "@/components/common/layout/LayoutTwoCol.vue";
import ProspectCountStats from "@/components/stats/ProspectCountStats.vue";
import ProspectStatsPerMonth from "@/components/stats/ProspectStatsPerMonth.vue";
import { Color } from "@/enums";
import {
  ProspectCountPerMonthDto,
  ProspectCountStatsDto,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import texts from "@/utils/texts";
import { DateTime } from "luxon";
import { ref } from "vue";

const componentTexts = texts.navigationItems.organize.trialDays;

defineProps<{
  color: Color;
}>();

const fromDate = ref<DateTime>(DateTime.now());
const toDate = ref<DateTime>(DateTime.now());

const prospectCountStats = ref<ProspectCountStatsDto>();
const prospectCountsPerMonth = ref<ProspectCountPerMonthDto[]>();

async function fetchStats() {
  getRecruitmentYear()
    .then((recruitmentYear) => {
      const currentRecruitmentYearDates =
        recruitmentYear.toCurrentRecruitmentYearDates();

      fromDate.value = currentRecruitmentYearDates.start;
      toDate.value = currentRecruitmentYearDates.end;
    })
    .then(async () => {
      const [stats, perMonth] = await Promise.all([
        eduConfigurationServiceClient.getTrialDaysStats(
          fromDate.value,
          toDate.value,
        ),
        eduConfigurationServiceClient.getTrialDaysStatsPerMonth(
          fromDate.value,
          toDate.value,
        ),
      ]);
      prospectCountStats.value = stats;
      prospectCountsPerMonth.value = perMonth;
    });
}

fetchStats();
</script>
