<template>
  <div>
    <label for="tabs" class="sr-only">Select a tab</label>
    <select
      v-model="selected"
      class="block w-full rounded-md border-gray-300 focus:border-deepteal-500 focus:ring-deepteal-500"
      data-testid="router-navigation-select"
    >
      <option
        v-for="option in optionsInternal"
        :key="option.name"
        data-testid="router-navigation-select-option"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { userCanGoTo } from "@/router/helpers/userCanGoTo";
import { ref, watch } from "vue";

import { RouteLocation, useRoute, useRouter } from "vue-router";
import { RouterNavigationOption } from "./RouterNavigationSelect.types";

interface NavigationOptionInternal {
  name: string;
  toRouteLocation: RouteLocation;
}

const props = defineProps<{ options: RouterNavigationOption[] }>();

const router = useRouter();
const currentRoute = useRoute();

const optionsInternal: NavigationOptionInternal[] = props.options
  .map((option) => ({
    name: option.name,
    toRouteLocation: router.resolve(option.to),
  }))
  .filter((option) => userCanGoTo(option.toRouteLocation));

const selected = ref<string | undefined>(
  optionsInternal.filter(
    (option) => option.toRouteLocation.name === currentRoute.name,
  )[0]?.name,
);

watch(selected, (newValue: string | undefined) => {
  if (!newValue) return;

  const newOption: NavigationOptionInternal = optionsInternal.filter(
    (option) => option.name === newValue,
  )[0];

  router.push(newOption.toRouteLocation);
});
</script>
