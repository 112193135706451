<template>
  <FormLayout @submit="addEnrollment">
    <StudyProgramFormField
      id="studyProgramIds"
      :label="componentTexts.form.studyProgram"
      :displayMode="FormFieldDisplayMode.Column"
      data-testid="studyProgramIds"
    />
    <FormFieldDateTime
      id="enrolledAt"
      :label="componentTexts.form.enrolledAt"
      :displayMode="FormFieldDisplayMode.Column"
      data-testid="enrolledAt"
    />
    <FormFieldDateTime
      id="studyProgramStartsAt"
      :label="componentTexts.form.studyProgramStartsAt"
      :displayMode="FormFieldDisplayMode.Column"
      data-testid="studyProgramStartsAt"
    />
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { RootState } from "@/store/index";
import { useStore } from "vuex";
import Notify from "@/utils/notify";
import { createEnrollmentInStudyProgram } from "@/services/enrollment.service";
import { Prospect } from "@/models/contact";
import { ContactEnrollmentCreateFormValues } from "./ContactForm.types";
import { useForm } from "vee-validate";
import StudyProgramFormField from "@/components/study-programs/StudyProgramFormField.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import logger from "@/plugins/logger";
import { DateTime } from "luxon";
import FormLayout from "@/components/common/form/FormLayout.vue";

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];
const componentTexts =
  texts.navigationItems.contact.contactMoments.addEnrollment;

const props = defineProps<{
  prospect: Prospect;
}>();

const emit = defineEmits<{
  (e: "added:enrollment"): void;
}>();

const addEnrollmentLoading = ref<boolean>(false);

const validationSchema = yup.object({
  studyProgramIds: yup.array().required().min(1).max(1),
  studyProgramStartsAt: yup.mixed<DateTime>().required(),
  enrolledAt: yup.mixed<DateTime>().required(),
});

const initialCreateValues: Partial<ContactEnrollmentCreateFormValues> = {
  studyProgramStartsAt: DateTime.now(),
  enrolledAt: DateTime.now(),
};

const form = useForm<ContactEnrollmentCreateFormValues>({
  validationSchema,
});

form.setValues(initialCreateValues);

const addEnrollment = form.handleSubmit(
  async (values: ContactEnrollmentCreateFormValues) => {
    addEnrollmentLoading.value = true;

    await createEnrollmentInStudyProgram({
      prospectId: props.prospect.id,
      studyProgramId: values.studyProgramIds[0],
      studyProgramStartsAt: values.studyProgramStartsAt,
      enrolledAt: values.enrolledAt,
    })
      .then(() => {
        emit("added:enrollment");
        Notify.success(componentTexts.addEnrollmentResult.success);
      })
      .catch((error) => {
        logger.error(error);
        Notify.failure(componentTexts.addEnrollmentResult.failure);
      })
      .finally(() => {
        addEnrollmentLoading.value = false;
      });
  },
);
</script>
