<template>
  <FormLayout @submit="submitForm">
    <div class="flex flex-col items-center gap-4 sm:flex-row">
      <h2 class="text-lg font-semibold text-deepteal-700">
        {{ "Google Tag Manager" }}
      </h2>
      <FormFieldCheckbox
        v-if="formUri"
        id="useDefaultGTM"
        :label="texts.models.formStylingSettings.useDefault"
      ></FormFieldCheckbox>
    </div>
    <FormFieldTextInput
      id="gtmCode"
      label="GTM-code"
      :type="TextInputType.TEXT"
      placeholder="XXX-XXX"
      :disabled="form.values.useDefaultGTM"
    />
    <FormFieldCheckbox
      id="gtmNoMessage"
      :label="texts.models.formStylingSettings.gtmNoMsg"
      :disabled="form.values.useDefaultGTM"
    ></FormFieldCheckbox>
    <h2 class="text-xs italic text-deepteal-700">
      {{ texts.models.formStylingSettings.gtmNoMsgExplanation }}
    </h2>

    <div class="flex flex-col items-center gap-4 sm:flex-row">
      <h2 class="text-lg font-semibold text-deepteal-700">
        {{ texts.models.formStylingSettings.title }}
      </h2>
      <FormFieldCheckbox
        v-if="formUri"
        id="useDefaultStyle"
        :label="texts.models.formStylingSettings.useDefault"
      ></FormFieldCheckbox>
    </div>

    <FormFieldSelect
      id="formWidth"
      :label="texts.models.formStylingSettings.formWidth"
      :items="formWidthItems"
      :disabled="form.values.useDefaultStyle"
    ></FormFieldSelect>

    <div class="flex flex-col gap-2">
      <FormFieldTextInput
        id="bannerUrl"
        :label="texts.models.formStylingSettings.bannerUrlUpload"
        :type="TextInputType.URL"
        :disabled="form.values.useDefaultStyle"
      />
      <FileUploadField
        v-show="!form.values.useDefaultStyle"
        formFieldId="bannerFile"
        :maxFileSize="FileSize.MB1"
        :maxFileSizeNotification="
          texts.commonComponents.import.form.sizeExceededNotification
        "
        :fileTypes="[FileType.JPEG, FileType.PNG]"
      />
      <Card v-show="!form.values.useDefaultStyle" class="flex flex-col gap-4">
        <CardHeader :text="texts.generic.preview"></CardHeader>
        <img
          v-if="
            form.values.bannerUrl &&
            (!form.values.bannerFile || form.values.bannerFile.length === 0)
          "
          :src="form.values.bannerUrl"
        />
        <span v-else>{{ texts.models.formStylingSettings.uploadPreview }}</span>
      </Card>
    </div>

    <FormFieldSelect
      id="bannerMargin"
      :label="texts.models.formStylingSettings.bannerMargin"
      :items="bannerMarginItems"
      :disabled="form.values.useDefaultStyle"
    ></FormFieldSelect>

    <FormFieldCheckbox
      id="bannerStretch"
      :label="texts.models.formStylingSettings.bannerStretch"
      :disabled="form.values.useDefaultStyle"
    ></FormFieldCheckbox>
    <div class="flex flex-col gap-2">
      <FormFieldTextInput
        id="faviconUrl"
        :label="texts.models.formStylingSettings.faviconUrlUpload"
        :type="TextInputType.URL"
        :disabled="form.values.useDefaultStyle"
      />
      <FileUploadField
        v-show="!form.values.useDefaultStyle"
        formFieldId="faviconFile"
        :maxFileSize="FileSize.KB100"
        :maxFileSizeNotification="
          texts.commonComponents.import.form.sizeExceededNotification
        "
        :fileTypes="[FileType.ICO, FileType.JPEG, FileType.GIF, FileType.PNG]"
      />
      <Card
        v-show="!form.values.useDefaultStyle"
        :size="CardSize.xs"
        class="flex flex-shrink-0 gap-4"
      >
        <CardHeader :text="texts.generic.preview"></CardHeader>
        <img
          v-if="
            form.values.faviconUrl &&
            (!form.values.faviconFile || form.values.faviconFile.length === 0)
          "
          :src="form.values.faviconUrl"
          width="25"
        />
        <span v-else>{{ texts.models.formStylingSettings.uploadPreview }}</span>
      </Card>
    </div>

    <h2 class="text-lg font-semibold text-deepteal-700">
      {{ texts.models.formStylingSettings.formColors }}
    </h2>
    <FormFieldTextInput
      id="colorBackground"
      :label="texts.models.formStylingSettings.backgroundColor"
      :type="TextInputType.COLOR"
      :disabled="form.values.useDefaultStyle"
    />
    <FormFieldTextInput
      id="colorPrimary"
      :label="texts.models.formStylingSettings.primaryColor"
      :type="TextInputType.COLOR"
      :disabled="form.values.useDefaultStyle"
    />
    <FormFieldTextInput
      id="colorSecondary"
      :label="texts.models.formStylingSettings.secondaryColor"
      :type="TextInputType.COLOR"
      :disabled="form.values.useDefaultStyle"
    />
    <h2 class="text-lg font-semibold text-deepteal-700">
      {{ texts.models.formStylingSettings.textAndColors }}
    </h2>
    <FormFieldSelect
      id="fontSize"
      :label="texts.models.formStylingSettings.fontSize"
      :items="fontSizeItems"
      :disabled="form.values.useDefaultStyle"
    />
    <FormFieldTextInput
      id="colorText"
      :label="texts.models.formStylingSettings.textColor"
      :type="TextInputType.COLOR"
      :disabled="form.values.useDefaultStyle"
    />
    <FormFieldTextInput
      id="fontText"
      :label="texts.models.formStylingSettings.fontText"
      :type="TextInputType.TEXT"
      :disabled="form.values.useDefaultStyle"
    />
    <FormFieldTextInput
      id="colorHeader"
      :label="texts.models.formStylingSettings.headerColor"
      :type="TextInputType.COLOR"
      :disabled="form.values.useDefaultStyle"
    />
    <FormFieldTextInput
      id="fontHeader"
      :label="texts.models.formStylingSettings.fontHeader"
      :type="TextInputType.TEXT"
      :disabled="form.values.useDefaultStyle"
    />
    <FormFieldTextInput
      id="colorLink"
      :label="texts.models.formStylingSettings.colorLink"
      :type="TextInputType.COLOR"
      :disabled="form.values.useDefaultStyle"
    />
    <FormFieldSelect
      id="linkDecoration"
      :label="texts.models.formStylingSettings.linkDecoration"
      :items="linkDecorationsItems"
      :disabled="form.values.useDefaultStyle"
    ></FormFieldSelect>
    <FormFieldSelect
      id="linkWeight"
      :label="texts.models.formStylingSettings.linkWeight"
      :items="linkWeightItems"
      :disabled="form.values.useDefaultStyle"
    ></FormFieldSelect>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { CardSize } from "@/components/common/card/Card.types";
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import FormFieldCheckbox from "@/components/common/checkbox/FormFieldCheckbox.vue";
import FileUploadField from "@/components/common/file-upload/FileUploadField.vue";
import {
  FileSize,
  FileType,
} from "@/components/common/file-upload/fileUploadField.types";
import FormLayout from "@/components/common/form/FormLayout.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import {
  IAnalyticDTO,
  IStyleDTOWithSassVariables,
} from "@/lib/formsServiceClient";
import texts from "@/utils/texts";
import {
  FormStylingFormValues,
  convertAnalyticToFormValues,
  convertStyleToFormValues,
} from "@/views/settings/forms/FormStylingSettingsForm.types";
import { useForm } from "vee-validate";
import * as yup from "yup";

const formWidthItems = [
  { value: "950px", label: "950 px" },
  { value: "1000px", label: "1000 px" },
  { value: "1050px", label: "1050 px" },
  { value: "1100px", label: "1100 px" },
  { value: "1150px", label: "1150 px" },
  { value: "1200px", label: "1200 px" },
];
const bannerMarginItems = [
  { value: "0px", label: "0 px" },
  { value: "8px", label: "8 px" },
  { value: "16px", label: "16 px" },
  { value: "24px", label: "24 px" },
  { value: "32px", label: "32 px" },
  { value: "40px", label: "40 px" },
  { value: "48px", label: "48 px" },
];
const fontSizeItems = [
  { value: "0.9rem", label: "0.9 rem" },
  { value: "1rem", label: "1 rem" },
  { value: "1.1rem", label: "1.1 rem" },
];
const linkDecorationsItems = [
  { value: "underline", label: "underline" },
  { value: "none", label: "none" },
];
const linkWeightItems = [
  { value: "normal", label: "normal" },
  { value: "bold", label: "bold" },
];

const emit = defineEmits<{
  submit: [values: FormStylingFormValues];
}>();

const props = defineProps<{
  formUri?: string;
  gtmSettings?: IAnalyticDTO;
  gtmFallbackSettings?: IAnalyticDTO;
  pageSettings?: IStyleDTOWithSassVariables;
  pageFallbackSettings?: IStyleDTOWithSassVariables;
}>();

const form = useForm<FormStylingFormValues>({
  validationSchema: yup.object({
    useDefaultGTM: yup.boolean(),
    gtmCode: yup
      .string()
      .matches(/^GTM-[A-Z0-9]{1,8}?$/, texts.validation.gtmCode)
      .nullable(),
    gtmNoMessage: yup.boolean(),
    useDefaultStyle: yup.boolean(),
    bannerUrl: yup.string().nullable(),
    bannerFile: yup.array(yup.mixed()).max(1),
    faviconUrl: yup.string().nullable(),
    faviconFile: yup.array(yup.mixed()).max(1),
    bannerMargin: yup.string(),
    bannerStretch: yup.boolean(),
    colorBackground: yup.string(),
    colorLink: yup.string(),
    colorPrimary: yup.string(),
    colorSecondary: yup.string(),
    colorHeader: yup.string(),
    colorText: yup.string(),
    formWidth: yup.string(),
    fontSize: yup.string(),
    linkDecoration: yup.string(),
    linkWeight: yup.string(),
    fontText: yup.string(),
    fontHeader: yup.string(),
  }),
  initialValues: {
    useDefaultGTM: !props.gtmSettings,
    ...convertAnalyticToFormValues(
      props.gtmSettings ?? props.gtmFallbackSettings,
    ),
    useDefaultStyle: !props.pageSettings,
    ...convertStyleToFormValues(
      props.pageSettings ?? props.pageFallbackSettings,
    ),
  },
});

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>
