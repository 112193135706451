<template>
  <ListItem
    :title="`${session.name} - ${formattedDateRange}`"
    :clickable="canWrite"
    :data-testid="testIds.session.item"
  >
    <ListItemColumn :flex="2" @click="canWrite && emit('update')">
      <span
        :class="[
          'text-xs xl:text-xs',
          isFull ? 'font-medium text-lavender-700' : 'text-gray-400',
        ]"
      >
        {{ formattedDateRange }}
      </span>
    </ListItemColumn>
    <ListItemColumn :flex="4" accent @click="canWrite && emit('update')">
      <span class="truncate">{{ session.name }}</span>
      <div
        v-if="session.mode === SessionMode.Online"
        class="flex items-center gap-1 rounded-full bg-lavender-100 px-2 py-1 leading-none text-lavender-700"
      >
        <Icon icon="wifi" :size="IconSize.xs" />
        <span class="font-medium">{{
          texts.enums.sessionMode[SessionMode.Online]
        }}</span>
      </div>
    </ListItemColumn>
    <ListItemColumn hide @click="canWrite && emit('update')">
      <CapacityIndicator
        :count="session.expectedVisitorCount"
        :capacity="session.capacity"
        :accented="isFull"
      />
    </ListItemColumn>
    <ListItemColumn :flex="0" class="justify-end">
      <SessionItemActions
        v-if="canWrite || canViewPersonalData()"
        @download="emit('download')"
        @copy="emit('copy')"
        @delete="emit('delete')"
      />
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import { Permission } from "@/lib/eduConfigurationServiceClient";
import { SessionMode } from "@/models/session";
import texts from "@/utils/texts";
import { IconSize } from "@/components/common/icon/Icon.types";
import { computed } from "vue";
import CapacityIndicator from "@/components/common/capacity-indicator/CapacityIndicator.vue";
import SessionItemActions from "./SessionItemActions.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { testIds } from "@/utils/testing";
import { LocalizedSessionCountDto } from "@/lib/eduConfigurationServiceClient";
import { DateTime, Interval } from "luxon";
import Icon from "@/components/common/icon/Icon.vue";
import {
  canViewPersonalData,
  hasPermissions,
} from "@/router/helpers/userCanGoTo";

const props = defineProps<{
  activityId: string;
  session: LocalizedSessionCountDto;
}>();

const emit = defineEmits<{
  update: [];
  download: [];
  copy: [];
  delete: [];
}>();

const canWrite = hasPermissions([
  Permission.ActivitiesWrite,
  Permission.SessionsWrite,
]);

const isFull = computed(
  () =>
    !!props.session.capacity &&
    props.session.expectedVisitorCount >= props.session.capacity,
);

const formattedDateRange = computed(() =>
  Interval.fromDateTimes(
    props.session.startDateTime,
    props.session.endDateTime,
  ).toLocaleString(DateTime.DATETIME_MED),
);
</script>
