<template>
  <div class="flex h-full flex-col gap-8">
    <div class="flex w-full items-start justify-between">
      <div class="flex flex-col gap-2">
        <PageHeading>{{ componentTexts.sectionTitle }}</PageHeading>
        <PageSubTitle>{{ componentTexts.sectionDescription }}</PageSubTitle>
      </div>
      <Button
        v-if="registrationLinks.length === 0"
        :data-testid="testIds.action.submit"
        :loading="actionLoading"
        :trailingIcon="'arrow_right_alt'"
        @click="updateActivityLocations(true)"
      >
        <span class="inline-flex flex-wrap items-center gap-1">
          <span class="font-semibold">{{ texts.actions.submit }},</span>
          <span class="font-normal">
            {{ componentTexts.btnToRegistrationLinks }}
          </span>
        </span>
      </Button>
      <Button
        v-else
        :data-testid="testIds.action.submit"
        :loading="actionLoading"
        @click="updateActivityLocations(false)"
      >
        {{ texts.actions.submit }}
      </Button>
    </div>
    <div
      class="flex items-center justify-end gap-4 text-sm font-normal text-deepteal-500 underline"
    >
      <button class="hover:text-deepteal-700" @click="updateAllSelected(true)">
        {{ texts.actions.selectAll }}
      </button>
      <button class="hover:text-deepteal-700" @click="updateAllSelected(false)">
        {{ texts.actions.clearAll }}
      </button>
    </div>
    <List>
      <ListItem
        v-for="location in settings.activityLocations"
        :key="location.id"
        :class="[
          'h-10 border-l-2 transition-colors duration-75',
          selectedIds.includes(location.id)
            ? 'border-deepteal-500'
            : 'border-transparent',
        ]"
        :data-testid="testIds.activityLocations.item"
      >
        <ListItemColumn :flex="2">
          <label class="flex w-full items-center gap-4">
            <Checkbox
              :id="location.id"
              v-model="selectedIds"
              name="activity-locations"
              :value="location.id"
            />
            <span class="truncate">
              {{ location.name }}
            </span>
          </label>
        </ListItemColumn>
        <ListItemColumn
          v-if="selectedIds.includes(location.id)"
          class="cursor-pointer self-end"
          @click="() => openScanConfigurationSlideOver(location.id)"
        >
          <Icon icon="qr_code_2" :color="Color.DeepTeal" />
          <span>
            {{ componentTexts.scanConfiguration }}
          </span>
        </ListItemColumn>
      </ListItem>
    </List>

    <SlideOverScanConfiguration
      v-if="activityContext.activity && selectedLocation"
      :key="selectedLocation.id"
      v-model:visible="slideOverScanConfigurationOpen"
      :activity="activityContext.activity"
      :location="selectedLocation"
    />
  </div>
</template>

<script setup lang="ts">
import Button from "@/components/common/button/Button.vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import Icon from "@/components/common/icon/Icon.vue";
import List from "@/components/common/list/List.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import { Color } from "@/enums";
import {
  ActivityLocationLocalizedDTO,
  ActivityLocationsDto,
} from "@/lib/eduConfigurationServiceClient";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import router from "@/router/index";
import { RouteNamesActivityOverview } from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import activityContext from "@/store/context/activity.context";
import settings from "@/store/context/settings.context";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import SlideOverScanConfiguration from "@/views/organize/activities/activity/locations/components/SlideOverScanConfiguration.vue";
import { ref } from "vue";

const props = defineProps<ActivityRouteProps>();

const componentTexts = texts.navigationItems.organize.activity.locations;

const actionLoading = ref(false);
const selectedIds = ref<string[]>([]);
selectedIds.value = props.activity.locationIds;

const updateAllSelected = (value: boolean) => {
  const studyPrograms = settings.activityLocations.map((sp) => sp.id);

  selectedIds.value = selectedIds.value
    .filter((id) => !studyPrograms.some((sp) => sp === id))
    .concat(value ? studyPrograms : []);
};

const fetchActivitySettings = async () => {
  activityContext.activity =
    await eduConfigurationServiceClient.getActivityById(props.id);
};

const updateActivityLocations = async (redirect: boolean) => {
  try {
    actionLoading.value = true;
    await eduConfigurationServiceClient.updateActivityLocations(
      props.id,
      new ActivityLocationsDto({ locationIds: selectedIds.value }),
    );

    Notify.success(texts.notifications.edit.success, [
      texts.models.activity.title,
    ]);

    fetchActivitySettings();

    if (redirect) {
      router.push({
        name: RouteNamesActivityOverview.REGISTRATIONLINKS,
        params: { id: props.id },
      });
    }
  } catch (err) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.activity.title,
    ]);
    throw err;
  } finally {
    actionLoading.value = false;
  }
};

const slideOverScanConfigurationOpen = ref(false);
const selectedLocation = ref<ActivityLocationLocalizedDTO>();
const openScanConfigurationSlideOver = (locationId: string) => {
  slideOverScanConfigurationOpen.value = true;

  selectedLocation.value = settings.activityLocations.find(
    (location) => location.id === locationId,
  );
};
</script>
