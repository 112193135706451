<template>
  <ListItem clickable>
    <ListItemColumn :flex="0" @click="() => emit('details', item.id)">
      <Icon icon="alt_route" :color="color"></Icon>
    </ListItemColumn>
    <ListItemColumn :flex="8" accent @click="() => emit('details', item.id)">
      <span :class="'truncate'">
        {{ item.name }}
      </span>
    </ListItemColumn>
    <ListItemColumn class="justify-end" @click="() => emit('details', item.id)">
      <span :class="getTextColor(color)" class="text-base font-semibold">
        {{ item.prospectCount }}
      </span>
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import Icon from "@/components/common/icon/Icon.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { ThirdPartyProspectSourceOverviewDto } from "@/lib/eduConfigurationServiceClient";
import { Color } from "@/enums";
import { getTextColor } from "@/utils/color";

defineProps<{
  item: ThirdPartyProspectSourceOverviewDto;
  color: Color;
}>();

const emit = defineEmits<{
  (e: "details", value: string): void;
}>();
</script>
