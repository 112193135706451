<template>
  <DropdownWrapper
    :type="selected ? DropdownType.primary : DropdownType.white"
    :origin
  >
    <template v-slot:button>
      <ButtonGroupMenuButton
        :color="selected ? Color.Emerald : Color.White"
        :size="ButtonSize.sm"
        class="w-full"
        >{{ selected?.name || placeholder }}</ButtonGroupMenuButton
      >
    </template>
    <template v-slot:items>
      <DropdownItem
        label="&nbsp;"
        @click="() => (selected = undefined)"
      ></DropdownItem>
      <DropdownItem
        v-for="item in items"
        :key="item.id"
        :label="item.name"
        @click="() => (selected = item)"
      ></DropdownItem>
    </template>
  </DropdownWrapper>
</template>

<script setup lang="ts" generic="T extends { id: string; name: string }">
import {
  DropdownOrigin,
  DropdownType,
} from "@/components/common/dropdown/Dropdown.types";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import { Color } from "@/enums";

defineProps<{
  placeholder: string;
  items?: T[];
  origin?: DropdownOrigin;
}>();

const selected = defineModel<T>();
</script>
