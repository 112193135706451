import { DateTime } from "luxon";

export interface ContactMomentDTO {
  id: string;
  type: ContactMomentType;
  activityName?: string;
  mailingName?: string;
  dateTime: string;
  details: {
    locationName?: string;
    registrationLinkName?: string;
    brochureNames?: string[];
    editLink?: string;
    confirmationCanBeResent?: boolean;
    studyProgramName?: string;
    studyProgramStartsAt?: string;
    sessionId?: string;
    sessionName?: string;
    sessionStartsAt?: string;
    studyProgramNames?: string[];
    messageText?: string;
    ticketCode?: string;
    sourceName?: string;
  };
}

export enum ContactMomentType {
  Registration = "Registration",
  Visit = "Visit",
  GeneralMail = "GeneralMail",
  ActivityMail = "ActivityMail",
  ActivityReminderMail = "ActivityReminderMail",
  ActivityInviteMail = "ActivityInviteMail",
  ActivityVisitedSurveyMail = "ActivityVisitedSurveyMail",
  BrochureRequest = "BrochureRequest",
  KeepMeInformed = "KeepMeInformed",
  Enrollment = "Enrollment",
  SessionVisit = "SessionVisit",
  ProspectImport = "ProspectImport",
  ShortMessage = "ShortMessage",
  ActivityVisit = "ActivityVisit",
  Application = "Application",
  TrialDayRegistration = "TrialDayRegistration",
  ThirdPartyProspectImport = "ThirdPartyProspectImport",
}

export class ContactMoment {
  id: string;
  type: ContactMomentType;
  activityName?: string;
  mailingName?: string;
  dateTime: DateTime;
  locationName?: string;
  registrationLinkName?: string;
  brochureNames?: string[];
  studyProgramName?: string;
  studyProgramStartsAt?: DateTime;
  editLink?: string;
  confirmationCanBeResent?: boolean;
  sessionId?: string;
  sessionName?: string;
  sessionStartsAt?: DateTime;
  studyProgramNames?: string[];
  messageText?: string;
  ticketCode?: string;
  sourceName?: string;

  constructor(dto: ContactMomentDTO) {
    this.id = dto.id;
    this.type = dto.type;
    this.activityName = dto.activityName;
    this.mailingName = dto.mailingName;
    this.dateTime = DateTime.fromISO(dto.dateTime);
    this.locationName = dto.details.locationName;
    this.registrationLinkName = dto.details.registrationLinkName;
    this.brochureNames = dto.details.brochureNames;
    this.studyProgramName = dto.details.studyProgramName;
    this.studyProgramStartsAt = !dto.details.studyProgramStartsAt
      ? undefined
      : DateTime.fromISO(dto.details.studyProgramStartsAt);
    this.editLink = dto.details.editLink;
    this.confirmationCanBeResent = dto.details.confirmationCanBeResent;
    this.sessionId = dto.details.sessionId;
    this.sessionName = dto.details.sessionName;
    this.sessionStartsAt = !dto.details.sessionStartsAt
      ? undefined
      : DateTime.fromISO(dto.details.sessionStartsAt);
    this.studyProgramNames = dto.details.studyProgramNames;
    this.messageText = dto.details.messageText;
    this.ticketCode = dto.details.ticketCode;
    this.sourceName = dto.details.sourceName;
  }
}

export interface CreateEnrollmentInStudyProgramDTO {
  prospectId: string;
  studyProgramId: string;
  studyProgramStartsAt: DateTime;
  enrolledAt: DateTime;
}
