export interface FromEmailAddressDTO {
  id: string;
  emailAddress: string;
  readyToUse: boolean;
}

export interface ReplyToEmailAddressDTO {
  id: string;
  emailAddress: string;
}

export class FromEmailAddress {
  id: string;
  emailAddress: string;
  readyToUse: boolean;

  constructor(dto: FromEmailAddressDTO) {
    this.id = dto.id;
    this.emailAddress = dto.emailAddress;
    this.readyToUse = dto.readyToUse;
  }
}

export class ReplyToEmailAddress {
  id: string;
  emailAddress: string;

  constructor(dto: ReplyToEmailAddressDTO) {
    this.id = dto.id;
    this.emailAddress = dto.emailAddress;
  }
}
