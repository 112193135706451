export enum ButtonGroupInjectionKey {
  Grouped = "button-group",
  Color = "button-group-color",
  Size = "button-group-size",
  Type = "button-group-type",
  Round = "button-group-round",
  Flat = "button-group-flat",
  Disabled = "button-group-disabled",
  Gap = "button-group-gap",
}
