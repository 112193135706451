import Session, { SessionDTO } from "@/models/session";
import { IActivityStatsDto } from "@/lib/eduConfigurationServiceClient";

export class ActivityStats {
  expectedVisitors: number;
  registrants: number;
  visitors: number;
  visitedSurveys: number;

  constructor(dto: IActivityStatsDto) {
    this.expectedVisitors = dto.expectedVisitors;
    this.registrants = dto.registrantCount;
    this.visitors = dto.visitorCount;
    this.visitedSurveys = dto.visitedSurveyCount;
  }
}

export interface UniqueVisitorsPerLocationDTO {
  locationName: string;
  visitorCount: number;
}

export class UniqueVisitorsPerLocation {
  locationName: string;
  visitorsCount: number;

  constructor(dto: UniqueVisitorsPerLocationDTO) {
    this.locationName = dto.locationName;
    this.visitorsCount = dto.visitorCount;
  }
}

export interface SessionReportDTO extends SessionDTO {
  prospectCount: number;
  expectedVisitorCount: number;
}

export class SessionReport extends Session {
  prospectCount: number;
  expectedVisitorCount: number;

  constructor(dto: SessionReportDTO) {
    super(dto);
    this.prospectCount = dto.prospectCount;
    this.expectedVisitorCount = dto.expectedVisitorCount;
  }
}
