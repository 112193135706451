<template>
  <SlideOver v-model:visible="visibleInternal" :title="componentTexts.title">
    <ImportForm
      :downloadTemplateAction="
        eduConfigurationServiceClient.generateThirdPartyProspectImportTemplate.bind(
          eduConfigurationServiceClient,
        )
      "
      :hideDescription="true"
      @submit="handleImportSubmit"
    >
      <template v-slot:feedback>
        <ImportResults :importCount="importedCount" :errors="importErrors" />
      </template>
      <ButtonSubmit :loading="submitLoading" class="truncate">
        {{ importMessage ?? texts.actions.submit }}
      </ButtonSubmit>
      <ButtonCancel @click="visibleInternal = false">
        {{ texts.actions.cancel }}
      </ButtonCancel>
    </ImportForm>
  </SlideOver>
</template>

<script setup lang="ts">
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { computed, ref } from "vue";
import texts from "@/utils/texts";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import Notify from "@/utils/notify";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import ImportForm, {
  ImportFormValues,
} from "@/components/import/ImportForm.vue";
import ImportResults from "@/components/import/ImportResults.vue";
import HttpClient from "@/utils/httpClient";

const componentTexts =
  texts.navigationItems.thirdPartyProspectSources.overview.import;

const props = defineProps<{
  visible: boolean;
}>();

const emit = defineEmits<{
  "update:visible": [value: boolean];
  "import:success": [];
}>();

const visibleInternal = computed({
  get() {
    return props.visible;
  },
  set(value) {
    emit("update:visible", value);
  },
});

const submitLoading = ref(false);
const importMessage = ref<string>();
const importedCount = ref<number>(0);
const importErrors = ref<string[]>();

const handleImportSubmit = async (values: ImportFormValues) => {
  importMessage.value = undefined;
  importedCount.value = 0;
  importErrors.value = undefined;

  try {
    submitLoading.value = true;
    const content = new FormData();
    content.append("file", values.file[0], values.file[0].name);

    await HttpClient.stream(
      {
        Message: (message) => {
          importMessage.value = message.message;
        },
        Result: (result) => {
          importErrors.value = result.errors;
          importedCount.value = result.importCount;
          if (result.success) {
            visibleInternal.value = false;
            emit("import:success");
            Notify.success(componentTexts.submitSuccessNotification);
          } else {
            Notify.failure(componentTexts.submitErrorNotification);
          }
        },
      },
      "educonfig",
      "third-party-prospect-sources/import",
      {
        method: "POST",
        body: content,
      },
    );
  } catch (e) {
    Notify.failure(componentTexts.submitErrorNotification);
    throw e;
  } finally {
    importMessage.value = undefined;
    submitLoading.value = false;
  }
};
</script>
