import {
  getLocalizationsQueryable,
  Localization,
  QueryableLocalizations,
} from "./helpers/localizable";

export interface StudyProgramLocationDTOLocalizableProperties {
  name: string;
}

export interface StudyProgramLocationCreateDTO {
  sequenceNumber?: number;
  localizations: Localization<StudyProgramLocationDTOLocalizableProperties>[];
}

export interface StudyProgramLocationUpdateDTO
  extends StudyProgramLocationCreateDTO {
  id: string;
}

export interface StudyProgramLocationDTO {
  id: string;
  sequenceNumber?: number;
  name: string;
}

export interface StudyProgramLocationExtendedDTO {
  id: string;
  sequenceNumber?: number;
  localizations: Localization<StudyProgramLocationDTOLocalizableProperties>[];
}

export class StudyProgramLocation {
  id: string;
  sequenceNumber?: number;
  name: string;

  constructor(dto: StudyProgramLocationDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.name = dto.name;
  }
}

export class StudyProgramLocationExtended {
  id: string;
  sequenceNumber?: number;
  localizations: QueryableLocalizations<StudyProgramLocationDTOLocalizableProperties>;

  constructor(dto: StudyProgramLocationExtendedDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.localizations = getLocalizationsQueryable(dto.localizations);
  }
}
