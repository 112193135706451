<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.navigationItems.organize.sessionGroups.edit.title"
    :subTitle="texts.navigationItems.organize.sessionGroups.edit.subTitle"
  >
    <Loader v-if="loading" data-testid="loading-session-group"></Loader>
    <SessionGroupForm
      v-if="!loading && !!sessionGroup"
      :formValues="convertToFormValues(sessionGroup)"
      @submit="submitUpdate"
    >
      <ButtonSubmit
        v-if="!deletingSessionGroup"
        :data-testid="testIds.action.submit"
        :loading="actionLoading"
      ></ButtonSubmit>
      <ButtonCancel
        v-if="!deletingSessionGroup"
        data-testid="cancel-update"
        :color="Color.White"
        @click="cancelUpdate"
      ></ButtonCancel>
      <SlideOverDeleteConfirm
        v-if="deletingSessionGroup"
        @confirm="submitDelete"
        @cancel="cancelDelete"
      ></SlideOverDeleteConfirm>
      <ButtonDelete
        v-if="!deletingSessionGroup"
        @click="deletingSessionGroup = true"
      ></ButtonDelete>
    </SessionGroupForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import Loader from "@/components/common/loader/Loader.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import SlideOverDeleteConfirm from "@/components/common/slideover/SlideOverDeleteConfirm.vue";
import dictionary from "@/dictionary";
import { Color, Culture } from "@/enums";
import { SessionGroupDto } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { RootState } from "@/store";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import {
  SessionGroupFormValues,
  convertToFormValues,
  convertToUpdateDto,
} from "@/views/organize/activities/activity/sessions/components/session-groups/SessionGroupForm.types";
import SessionGroupForm from "@/views/organize/activities/activity/sessions/components/session-groups/SessionGroupForm.vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const props = defineProps<{
  visible: boolean;
  activityId: string;
  sessionGroup?: SessionGroupDto;
  loading?: boolean;
}>();

const emit = defineEmits([
  "update:visible",
  "update:succeeded",
  "delete:succeeded",
]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

// Updating Brochure
const actionLoading = ref<boolean>(false);
const submitUpdate = async (values: SessionGroupFormValues) => {
  actionLoading.value = true;

  if (!props.sessionGroup) {
    throw new Error(`Cannot perform action. No session group provided.`);
  }

  await eduConfigurationServiceClient
    .updateSessionGroup(
      props.activityId,
      props.sessionGroup.id,
      convertToUpdateDto(values),
    )
    .then(() => {
      Notify.success(texts.navigationItems.organize.sessionGroups.edit.success);
      visibleInternal.value = false;
      emit("update:succeeded");
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.organize.sessionGroups.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

// Deleting Brochure
const deletingSessionGroup = ref<boolean>(false);
const submitDelete = async () => {
  actionLoading.value = true;

  if (!props.sessionGroup) {
    throw new Error(`Cannot perform action. No session group provided.`);
  }

  await eduConfigurationServiceClient
    .deleteSessionGroup(props.activityId, props.sessionGroup.id)
    .then(() => {
      Notify.success(
        texts.navigationItems.organize.sessionGroups.delete.success,
      );
      visibleInternal.value = false;
      emit("delete:succeeded");
    })
    .catch((e) => {
      Notify.failure(
        texts.navigationItems.organize.sessionGroups.delete.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

// Reset state when opening or closing updateSlideOver
watch(visibleInternal, () => (deletingSessionGroup.value = false));

const cancelUpdate = (): void => {
  deletingSessionGroup.value = false;
  visibleInternal.value = false;
};

const cancelDelete = () => {
  deletingSessionGroup.value = false;
};
</script>
