import { Culture } from "@/enums";
import {
  BrochureDto,
  ICreateBrochureDto,
  IUpdateBrochureDto,
} from "@/lib/eduConfigurationServiceClient";

export interface BrochureFormValues {
  uri: string;
  localizations: BrochureFormValuesLocalization[];
  studyProgramIds?: string[];
  isHardCopy: boolean;
  isPublic: boolean;
}

export interface BrochureFormValuesLocalization {
  locale: Culture;
  name: string;
}

export const convertToFormValues = (dto: BrochureDto): BrochureFormValues => ({
  uri: dto.uri,
  studyProgramIds: dto.studyProgramIds,
  isHardCopy: dto.isHardCopy,
  isPublic: dto.isPublic,
  localizations: dto.localizations as BrochureFormValuesLocalization[],
});

export const convertToUpdateDto = (
  values: BrochureFormValues,
): IUpdateBrochureDto => {
  return {
    uri: values.uri,
    isHardCopy: values.isHardCopy,
    isPublic: values.isPublic,
    studyProgramIds: values.studyProgramIds,
    localizations: values.localizations.filter((l) => l.name),
  };
};

export const convertToCreateDto = (
  values: BrochureFormValues,
): ICreateBrochureDto => {
  return {
    uri: values.uri,
    isHardCopy: values.isHardCopy,
    isPublic: values.isPublic,
    studyProgramIds: values.studyProgramIds,
    localizations: values.localizations.filter((l) => l.name),
  };
};
