<template>
  <TransitionFade>
    <Card v-if="data && data.length > 0" class="relative flex flex-col gap-8">
      <CardHeader :text="componentTexts.demoTiles.demo4"></CardHeader>
      <Pie :data="chartData" :options="chartOptions" />
    </Card>
  </TransitionFade>
</template>

<script setup lang="ts">
import Card from "@/components/common/card/Card.vue";
import texts from "@/utils/texts";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import { ref, watchEffect } from "vue";
import { Pie } from "vue-chartjs";
import CardHeader from "@/components/common/card/CardHeader.vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";

const componentTexts = texts.navigationItems.dashboard;
const chartData = ref<ChartData<"pie", number[], unknown>>({ datasets: [] });
const data = ref<{ name: string; count: number }[]>([]);

const props = defineProps<{
  uniqueProspectOptInCount: number | undefined;
  uniqueProspectOptOutCount: number | undefined;
}>();

const init = () => {
  const uniqueProspectOptInCount = props.uniqueProspectOptInCount ?? 0;
  const uniqueProspectOptOutCount = props.uniqueProspectOptOutCount ?? 0;

  if (uniqueProspectOptInCount === 0 && uniqueProspectOptOutCount === 0) {
    data.value = [];
  } else {
    data.value = [
      {
        name: componentTexts.uniqueProspectOptInCount,
        count: uniqueProspectOptInCount,
      },
      {
        name: componentTexts.uniqueProspectOptOutCount,
        count: uniqueProspectOptOutCount,
      },
    ];
  }

  const sortedList = data.value.sort((a, b) => b.count - a.count);
  processStats(sortedList);
};

const baseColor = "#7368F9";

const dataColors = (dataCount: number) => {
  const colors: string[] = [];

  // Parse the baseColor as a hex color.
  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  // Convert RGB to a hex color string.
  const rgbToHex = (r: number, g: number, b: number) => {
    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
  };

  // Get the RGB components of the baseColor.
  const [baseR, baseG, baseB] = hexToRgb(baseColor);

  // Generate shades going up to white based on the dataCount.
  for (let i = 0; i < dataCount; i++) {
    const shadeR = Math.min(255, baseR + (i * (255 - baseR)) / dataCount);
    const shadeG = Math.min(255, baseG + (i * (255 - baseG)) / dataCount);
    const shadeB = Math.min(255, baseB + (i * (255 - baseB)) / dataCount);
    const shadeHex = rgbToHex(shadeR, shadeG, shadeB);
    colors.push(shadeHex);
  }

  return colors;
};

const dataSetOptions = (dataCount: number) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    radius: 140,
    backgroundColor: dataColors(dataCount),
  };
};

const chartOptions: ChartOptions<"pie"> = {
  plugins: {
    legend: {
      position: "left",
    },
  },
};

const processStats = (input: { name: string; count: number }[]) => {
  const labels = input.map((x) => x.name);
  const datasets = [
    {
      data: input.map((x) => x.count),
      ...dataSetOptions(labels.length),
    },
  ];
  chartData.value = {
    labels,
    datasets,
  };
};

watchEffect(() => {
  init();
});

Chart.register(...registerables);
</script>
