<template>
  <Loader v-if="!items" />
  <ComparableStatsChart
    v-for="(item, i) in items"
    v-else-if="items.length > 0"
    :key="i"
    :items="item.stats"
    :chartTitle="item.title"
  />
</template>

<script setup lang="ts">
import Loader from "@/components/common/loader/Loader.vue";
import ComparableStatsChart, {
  ComparableItem,
} from "@/views/organize/activities/activity/hub/components/ComparableStatsChart.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { ref } from "vue";
import texts from "@/utils/texts";

const props = defineProps<{ activityId: string }>();

const items = ref<{ title: string; stats: ComparableItem[] }[] | undefined>();
eduConfigurationServiceClient
  .getRemainingStats(props.activityId)
  .then((response) => {
    items.value = [];

    Object.keys(response).forEach((key) => {
      items.value?.push({
        title: key,
        stats: response[key].map((r) => ({
          countsWithLegends: [
            {
              count: r.count,
              legend:
                texts.navigationItems.organize.activity.reports.registrantCount,
            },
            {
              count: r.countWithVisit ?? 0,
              legend:
                texts.navigationItems.organize.activity.reports.registrantCount,
            },
          ],
          label: r.name,
        })),
      });
    });
  });
</script>
