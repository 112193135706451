<template>
  <div
    :class="[
      'col-span-10 w-full rounded-md lg:col-span-11',
      {
        'h-1': size === StatsBarSize.xs,
        'h-2': size === StatsBarSize.sm,
        'h-3': size === StatsBarSize.md,
        'h-5': size === StatsBarSize.lg,
        'h-7': size === StatsBarSize.xl,
      },
      { 'hover:opacity-90': !!title },
    ]"
    :title="`${title}: ${count}`"
  >
    <div
      :class="[
        'h-full rounded-md shadow-sm transition-all duration-500',
        getBgColor(color),
        {
          'bg-opacity-100': intensity === 5,
          'bg-opacity-75': intensity === 4,
          'bg-opacity-50': intensity === 3,
          'bg-opacity-30': intensity === 2,
          'bg-opacity-20': intensity === 1,
        },
      ]"
      :style="{
        width: `${percentage * 100}%`,
      }"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { StatsBarIntensity, StatsBarSize } from "./StatsBar.types";
import { Color } from "@/enums";
import { getBgColor } from "@/utils/color";

const props = withDefaults(
  defineProps<{
    count: number;
    max: number;
    size: StatsBarSize;
    color: Color;
    intensity: StatsBarIntensity;
    title?: string;
  }>(),
  {
    size: StatsBarSize.md,
    color: Color.Emerald,
    intensity: 5,
    title: undefined,
  },
);

watch(
  () => props.count,
  () => {
    updatePercentage();
  },
);

watch(
  () => props.max,
  () => {
    updatePercentage();
  },
);

const percentage = ref(0);

const updatePercentage = () => {
  /* This short delay is for animating the width of the bar */
  setTimeout(() => {
    percentage.value = props.count / props.max;
  }, 200);
};

updatePercentage();
</script>
