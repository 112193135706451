<template>
  <FormLayout @submit="submitForm" @keydown.enter.prevent>
    <FormFieldSelect
      id="type"
      :displayMode="FormFieldDisplayMode.Row"
      :label="texts.models.question.type"
      :items="newQuestion ? newQuestionTypeList : allQuestionTypeList"
      :disabled="!newQuestion"
      size="sm"
      class="mt-1 w-3/5 text-nowrap"
      :initialValue="initialValues?.type"
      :color="newQuestion ? Color.Royal : Color.Gray"
    >
    </FormFieldSelect>
    <div class="mb-1 flex items-center">
      <FormFieldCheckbox
        id="isSurveyQuestion"
        :displayMode="FormFieldDisplayMode.Row"
      />
      <span class="text-sm font-medium text-gray-900">
        {{ texts.models.question.isSurveyQuestion }}
      </span>
    </div>

    <LocalizationTabs
      v-model="activeCulture"
      :locales="locales"
      @add="addLocale"
      @remove="removeLocale"
    />
    <FormFieldTextInputInline
      v-for="culture in locales"
      v-show="activeCulture === culture"
      :id="`localizations.${culture}.label`"
      :key="culture"
      :type="TextInputType.TEXT"
      css="font-medium leading-6 text-gray-900"
      required
    />

    <AnswerSettings
      :originalQuestion="localQuestion"
      :newQuestion="props.newQuestion"
      :definedLocalizations="locales"
      :activeCulture="activeCulture"
      :form="form"
    ></AnswerSettings>
    <div class="text-sm text-alert-500">
      {{ form.errors.value.answers }}
    </div>
    <MinMaxAnswerSettings
      :destination="localQuestion.destination"
      :form="form"
    />
    <template v-slot:actions>
      <ButtonSubmit></ButtonSubmit>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { Culture, Color } from "@/enums";
import { QuestionEditorQuestionDTO } from "@/lib/formsServiceClient";
import { computed, ref } from "vue";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import AnswerSettings from "@/views/settings/questions/components/question-settings/AnswerSettings.vue";
import FormFieldTextInputInline from "@/components/common/text-input/FormFieldTextInputInline.vue";
import MinMaxAnswerSettings from "@/views/settings/questions/components/question-settings/MinMaxAnswerSettings.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import * as yup from "yup";
import { useForm } from "vee-validate";
import texts from "@/utils/texts";
import { QuestionEditorFormValues } from "./QuestionSettings.types";
import { QuestionType } from "@/lib/formsServiceClient";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import FormLayout from "@/components/common/form/FormLayout.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldCheckbox from "@/components/common/checkbox/FormFieldCheckbox.vue";
import { leadingIconForQuestionType } from "./QuestionSettings.types";
import settings from "@/store/context/settings.context";

const props = defineProps<{
  question: QuestionEditorQuestionDTO;
  initialValues?: QuestionEditorFormValues;
  newQuestion?: boolean;
}>();
const emit = defineEmits(["update:question"]);

const localQuestion = ref<QuestionEditorQuestionDTO>(props.question);

const form = useForm<QuestionEditorFormValues>({
  initialValues: props.initialValues,
  validationSchema: yup.object({
    localizations: yup.object(
      settings.availableLanguages.reduce(
        (acc, language) => ({
          ...acc,
          [language.locale.value as Culture]: yup.lazy((value) =>
            value
              ? yup.object({ label: yup.string().required() })
              : yup.mixed(),
          ),
        }),
        {},
      ),
    ),
    answers: yup
      .array()
      .of(
        yup.object({
          identifier: yup.string().required(),
          disabled: yup.boolean(),
          localizations: yup.object(
            settings.availableLanguages.reduce(
              (acc, language) => ({
                ...acc,
                [language.locale.value as Culture]: yup.lazy((value) =>
                  value
                    ? yup.object({ label: yup.string().required() })
                    : yup.mixed(),
                ),
              }),
              {},
            ),
          ),
        }),
      )
      .test("unique-labels", texts.models.answer.uniqueAnswers, (): boolean => {
        if (!form.values.answers) {
          return true;
        }

        for (const language of settings.availableLanguages) {
          const labels: Set<string> = new Set();
          for (const answer of form.values.answers) {
            const locale = language.locale;
            if (answer.localizations === undefined) {
              return false; // When unmounting localizations is undefined. This if statment prevents console errors.
            }
            const label = answer.localizations[locale.value as Culture]?.label;

            if (label) {
              if (labels.has(label)) {
                return false;
              }
              labels.add(label);
            }
          }
        }

        return true;
      }),
    type: yup.mixed().oneOf(Object.values(QuestionType)).required(),
    minNumberOfAnswers: yup.number().min(1),
    maxNumberOfAnswers: yup.number().when("minNumberOfAnswers", {
      is: (minNumberOfAnswers?: number) => minNumberOfAnswers !== undefined,
      then: (schema) => schema.min(yup.ref("minNumberOfAnswers")),
    }),
    isSurveyQuestion: yup.boolean().required(),
  }),
});

const locales = computed(
  () => Object.keys(form.values.localizations) as Culture[],
);
const defaultCulture = settings.mainLanguage.locale.value as Culture;
const activeCulture = ref(defaultCulture);

const submitForm = form.handleSubmit((values: QuestionEditorFormValues) => {
  emit("update:question", values);
});

const newQuestionTypeList = Object.values(QuestionType)
  .filter(
    (type) =>
      type === QuestionType.CheckBox ||
      type === QuestionType.RadioButton ||
      type === QuestionType.DropDown ||
      type === QuestionType.Info ||
      type === QuestionType.Rating ||
      type === QuestionType.Text,
  )
  .map((type) => ({
    label: texts.enums.questionType[type],
    value: type,
    icon: leadingIconForQuestionType(type),
  }));

const allQuestionTypeList = Object.values(QuestionType).map((type) => ({
  label: texts.enums.questionType[type],
  value: type,
  icon: leadingIconForQuestionType(type),
}));

function addLocale(culture: Culture) {
  const defaultQuestionLabel = form.values.localizations[defaultCulture]?.label;

  form.setFieldValue(
    "localizations",
    addToLocalizations(form.values.localizations, culture, {
      label: defaultQuestionLabel ?? "",
    }),
  );

  form.setFieldValue(
    "answers",
    form.values.answers?.map((answer) => {
      const defaultAnswerLabel = answer.localizations[defaultCulture].label;

      return {
        ...answer,
        localizations: addToLocalizations(answer.localizations, culture, {
          label: defaultAnswerLabel ?? "",
        }),
      };
    }),
  );
}

const removeLocale = (culture: Culture) => {
  form.setFieldValue(
    "localizations",
    removeFromLocalizations(form.values.localizations, culture),
  );

  form.setFieldValue(
    "answers",
    form.values.answers?.map((answer) => ({
      ...answer,
      localizations: removeFromLocalizations(answer.localizations, culture),
    })),
  );
};

function addToLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
  value: T,
) {
  return { ...localizations, [culture]: value };
}

function removeFromLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
) {
  const newDict = { ...localizations };
  delete newDict[culture];
  return newDict;
}
</script>
