<template>
  <div
    class="flex flex-col gap-4 sm:flex-row sm:items-start sm:justify-between"
  >
    <div class="flex flex-row items-start gap-2">
      <div>
        <template
          v-for="localization in localizations"
          :key="localization.locale"
        >
          <FormFieldTextInputInline
            v-show="currentLocale === localization.locale"
            :id="`localizations.${localization.locale}.label`"
            v-model:focus="labelFocus"
            :placeholder="formTexts.newFormTemplate"
            class="text-xl font-medium text-deepteal-600"
            required
          />
        </template>
      </div>
      <Badge
        v-if="formType"
        :icon="formTypeIcons[formType as FormType]"
        :label="texts.enums.formType[formType as FormType]"
        :type="BadgeType.info"
        :class="['ml-4']"
      ></Badge>
    </div>

    <div
      v-if="$slots.default"
      class="flex flex-col gap-4 sm:flex-row-reverse sm:items-center"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import FormFieldTextInputInline from "@/components/common/text-input/FormFieldTextInputInline.vue";
import { FormLocalizationDTO } from "@/lib/formsServiceClient";
import texts from "@/utils/texts";
import { formTypeIcons } from "@/views/settings/forms/FormExtensions";
import { FormType } from "@/enums";

const formTexts = texts.navigationItems.manage.settings.formTemplates;

defineProps<{
  formType?: string;
  localizations: FormLocalizationDTO[];
  currentLocale: string;
}>();

const labelFocus = ref(false);
</script>
