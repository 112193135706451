<template>
  <DropdownWrapper :origin="DropdownOrigin.TopRight">
    <template v-slot:button>
      <ButtonGroup :color="Color.Gray" :size="ButtonSize.sm" flat round gap>
        <Button @click="openPublicPageUrl">
          <IconAsync
            :state="state"
            icon="captive_portal"
            :color="Color.Gray"
            :size="IconSize.sm"
          />
          <span>{{ componentTexts.openPublicPage }}</span>
        </Button>
        <ButtonGroupMenuButton />
      </ButtonGroup>
    </template>
    <template v-slot:items>
      <DropdownItem
        icon="download"
        :label="componentTexts.downloadPublicPageActivityTypeLinks"
        @click="downloadActivityTypeLinks"
      />
      <DropdownItem
        icon="download"
        :label="componentTexts.downloadPublicPageStudyProgramLinks"
        @click="downloadStudyProgramLinks"
      />
      <DropdownItem
        icon="download"
        :label="componentTexts.downloadStudyProgramLanguageLinks"
        @click="downloadStudyProgramLanguageLinks"
      />
      <DropdownItem
        icon="download"
        :label="componentTexts.downloadAreaOfInterestLinks"
        @click="downloadAreaOfInterestLinks"
      />
      <DropdownItem
        icon="download"
        :label="
          componentTexts.downloadPublicPageActivityTypeAndStudyProgramLinks
        "
        @click="downloadActivityTypeAndStudyProgramLinks"
      />
      <DropdownItem
        icon="download"
        :label="componentTexts.downloadActivityTypeStudyProgramLanguageLinks"
        @click="downloadActivityTypeStudyProgramLanguageLinks"
      />
      <DropdownItem
        icon="download"
        :label="
          componentTexts.downloadActivityTypeStudyProgramAreaOfInterestLinks
        "
        @click="downloadActivityTypeStudyProgramAreaOfInterestLinks"
      />
    </template>
  </DropdownWrapper>
</template>

<script setup lang="ts">
import { AsyncState } from "@/components/common/async/Async.types";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import ButtonGroup from "@/components/common/button/ButtonGroup.vue";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import { Color } from "@/enums";
import env from "@/env";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import tenant from "@/store/context/tenant.context";
import { downloadFileBySimulatingClickOnTemporaryAnchorTag } from "@/utils/downloadFile";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { ref, watch } from "vue";

const publicPageUrl = `${env.VITE_PROSPECT_APP_BASE_URL}/activities/${tenant.uri}`;

const componentTexts = texts.navigationItems.organize.publicPage;

const openPublicPageUrl = () => {
  window.open(publicPageUrl, "_blank");
};

const defaultState = AsyncState.Content;
const state = ref<AsyncState>(defaultState);

const downloadProspectAppLinks = async (
  perActivityType: boolean | undefined,
  perAreaOfInterest: boolean | undefined,
  perLanguage: boolean | undefined,
  perStudyProgram: boolean | undefined,
) => {
  try {
    state.value = AsyncState.Loading;

    const response = await eduConfigurationServiceClient.exportProspectAppLinks(
      perActivityType,
      perAreaOfInterest,
      perLanguage,
      perStudyProgram,
    );

    downloadFileBySimulatingClickOnTemporaryAnchorTag(
      response.data,
      response.fileName ?? "export.xlsx",
    );

    Notify.success(componentTexts.downloadLinks.success);
    state.value = AsyncState.Success;
  } catch (error) {
    state.value = AsyncState.Failure;
    Notify.failure(componentTexts.downloadLinks.failure);
    logger.error(error);
  }
};

const downloadActivityTypeLinks = async () =>
  downloadProspectAppLinks(true, false, false, false);

const downloadStudyProgramLinks = async () =>
  downloadProspectAppLinks(false, false, false, true);

const downloadActivityTypeAndStudyProgramLinks = async () =>
  downloadProspectAppLinks(true, false, false, true);

const downloadStudyProgramLanguageLinks = async () =>
  downloadProspectAppLinks(false, false, true, false);

const downloadActivityTypeStudyProgramLanguageLinks = async () =>
  downloadProspectAppLinks(true, false, true, false);

const downloadAreaOfInterestLinks = async () =>
  downloadProspectAppLinks(false, true, false, false);

const downloadActivityTypeStudyProgramAreaOfInterestLinks = async () =>
  downloadProspectAppLinks(true, true, false, false);

watch(state, (value) => {
  if (value === AsyncState.Success) {
    setTimeout(() => (state.value = defaultState), 1000);
  }
  if (value === AsyncState.Failure) {
    setTimeout(() => (state.value = defaultState), 3000);
  }
});
</script>
