<template>
  <Collapsible v-model:expanded="expandedInternal">
    <button
      type="button"
      class="flex items-center gap-1"
      @click="expandedInternal = !expandedInternal"
    >
      <Chevron :expanded="expandedInternal" />
      <h2 class="text-lg font-semibold text-deepteal-700">{{ title }}</h2>
    </button>
    <template v-slot:expanded>
      <slot></slot>
    </template>
  </Collapsible>
</template>

<script setup lang="ts">
import Chevron from "@/components/common/chevron/Chevron.vue";
import Collapsible from "@/components/common/collapsible/Collapsible.vue";
import { computed } from "vue";

const props = defineProps<{ title?: string; expanded: boolean }>();
const emit = defineEmits<{
  "update:expanded": [value: boolean];
}>();

const expandedInternal = computed({
  get() {
    return props.expanded;
  },
  set(val) {
    emit("update:expanded", val);
  },
});
</script>
