<template>
  <Modal
    v-model:visible="visibleInternal"
    :type="ModalType.danger"
    :description="texts.navigationItems.account.modal.deleteAccount.description"
    :title="texts.navigationItems.account.modal.deleteAccount.title"
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <ButtonDelete
          :data-testid="testIds.action.confirm"
          class="sm:ml-3"
          :loading="actionLoading"
          :disabled="!visibleInternal"
          @click="performDelete"
        />
        <ButtonCancel
          class="mt-3 sm:mt-0"
          :disabled="actionLoading || !visibleInternal"
          @click="visibleInternal = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import { testIds } from "@/utils/testing";
import { computed, ref } from "vue";
import texts from "@/utils/texts";
import Notify from "@/utils/notify";
import logger from "@/plugins/logger";
import { deleteAccount } from "@/services/account.service";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { useStore } from "vuex";
import { RootState } from "@/store";

const store = useStore<RootState>();

const props = defineProps<{
  deletingId?: string | undefined;
}>();

const emit = defineEmits<{
  (e: "update:deletingId", value: string | undefined): void;
}>();

const visibleInternal = computed({
  get: () => !!props.deletingId,
  set: (value) => {
    if (!value) emit("update:deletingId", undefined);
  },
});

// Delete session modal
const actionLoading = ref(false);
const performDelete = () => {
  if (!props.deletingId) {
    throw new Error("No id provided");
  }

  actionLoading.value = true;

  deleteAccount(props.deletingId)
    .then(async () => {
      Notify.success(texts.navigationItems.account.delete.success);

      await store.dispatch("accountStore/loadAccounts");

      visibleInternal.value = false;
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.account.delete.failure);

      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
