<template>
  <div class="flex flex-col gap-8" :data-testid="testIds.mailingsFilter">
    <Search v-model="search" :placeholder="texts.actions.search" />
    <div class="flex flex-col gap-4 rounded bg-white p-4 shadow-sm">
      <div class="flex items-center justify-between gap-1.5">
        <h2 class="text-base font-semibold">
          {{ texts.generic.filters }}
        </h2>
        <span
          class="cursor-pointer text-xs text-emerald-500 underline hover:text-emerald-700"
          @click="resetFilters"
          >{{ texts.actions.reset }}</span
        >
      </div>
      <MailingStatusFilter
        :mailingStates="mailingStates"
        :reset="resetMailingStatusFilter"
        :counts="mailingStatusFilter.counts"
        @filter="(values) => (mailingStatusFilter.states = values)"
      />
      <Divider />
      <div class="flex flex-col gap-4">
        <MailingTypeFilter
          :mailingTypes="mailingTypes"
          :reset="resetMailingTypeFilter"
          :counts="mailingTypeFilter.counts"
          @filter="(values) => (mailingTypeFilter.types = values)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import { MailingStatus, SessionStorageKey, MailingType } from "@/enums";
import Search from "@/components/common/search/Search.vue";
import texts from "@/utils/texts";
import Divider from "@/components/common/divider/Divider.vue";
import { countBy } from "@/utils/array";
import MailingStatusFilter from "./MailingStatusFilter.vue";
import MailingTypeFilter from "./MailingTypeFilter.vue";
import { compareArray } from "@/components/common/filter/FilterItem.types";
import { testIds } from "@/utils/testing";
import { ActivityRelatedMailingOverviewDTO } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{
  mailings: ActivityRelatedMailingOverviewDTO[];
  mailingTypes: MailingType[];
  mailingStates: MailingStatus[];
}>();

const search = ref<string | undefined>(
  sessionStorage.getItem(SessionStorageKey.MailingSearchTerm) || undefined,
);

watch(search, (newValue) =>
  newValue
    ? sessionStorage.setItem(SessionStorageKey.MailingSearchTerm, newValue)
    : sessionStorage.removeItem(SessionStorageKey.MailingSearchTerm),
);

const emit = defineEmits<{
  (e: "filter", value: ActivityRelatedMailingOverviewDTO[]): void;
  (e: "selectedTypes", value: MailingStatus[] | undefined): void;
}>();

const mailingStatusFilter = reactive({
  states: undefined as MailingStatus[] | undefined,
  counts: computed(() =>
    countBy(props.mailings, (item) =>
      flattenStatus(item.status as MailingStatus),
    ),
  ),
});

//function to put failed and inpgrogress under the 'planned' umbrella
const flattenStatus = (status: MailingStatus) => {
  if ([MailingStatus.Planned, MailingStatus.InProgress].indexOf(status) > 0)
    return MailingStatus.Planned;
  else return status;
};

const mailingTypeFilter = reactive({
  types: undefined as MailingType[] | undefined,
  counts: computed(() => countBy(props.mailings, (item) => item.type)),
});

const resetMailingStatusFilter = ref(false);
const resetMailingTypeFilter = ref(false);

const resetFilters = () => {
  resetMailingStatusFilter.value = !resetMailingStatusFilter.value;
  resetMailingTypeFilter.value = !resetMailingTypeFilter.value;
};

const filteredMailings = computed<ActivityRelatedMailingOverviewDTO[]>(() => {
  return props.mailings
    .filter(
      (mailing) =>
        !search.value ||
        mailing.name?.toLowerCase().includes(search.value.toLowerCase()),
    )
    .filter((mailing) => {
      const flattened = flattenStatus(mailing.status as MailingStatus);
      return compareArray(
        mailingStatusFilter.states,
        flattened ? [flattened] : [],
      );
    })
    .filter((mailing) => {
      return compareArray(
        mailingTypeFilter.types,
        mailing.type ? [mailing.type] : [],
      );
    });
});

watch(
  filteredMailings,
  (value) => {
    emit("filter", value);
  },
  { immediate: true },
);

watch(
  mailingStatusFilter,
  (value) => {
    emit("selectedTypes", value.states);
  },
  { immediate: true },
);
</script>
