<template>
  <Page class="flex flex-col gap-4">
    <ButtonBack :toRouteName="texts.generic.previous" @click="router.go(-1)" />

    <FormTemplateEditor v-if="dto" :dto :submitting @submit="handleSubmit" />
  </Page>
</template>

<script setup lang="ts">
import { FormDTO } from "@/lib/formsServiceClient";
import texts from "@/utils/texts";
import { ref } from "vue";
import { useRouter } from "vue-router";
import "./FormExtensions";
import Page from "@/components/common/page/Page.vue";
import ButtonBack from "@/components/common/button/ButtonBack.vue";
import FormTemplateEditor from "@/views/settings/forms/components/form-template-editor/FormTemplateEditor.vue";
import { formsServiceClient } from "@/services/formsService.client.service";
import { FormType } from "@/enums";
import Notify from "@/utils/notify";
import settings, { loadForms } from "@/store/context/settings.context";

const props = defineProps<{
  type: FormType;
}>();

const formTexts = texts.navigationItems.manage.settings.formTemplates;

const router = useRouter();

const dto = ref<FormDTO>(new FormDTO());
dto.value.type = props.type;

const submitting = ref(false);
const handleSubmit = async (values: FormDTO) => {
  try {
    submitting.value = true;

    const usedUris = (await formsServiceClient.getForms(undefined))?.map(
      (f) => f.uri,
    );
    const mainLocalization = values.localizations.find(
      (localization) =>
        localization.locale === settings.mainLanguage.locale.value,
    );

    if (!mainLocalization?.label) {
      Notify.warning(formTexts.notifications.warningLabelNotDefined);
      return;
    }

    const newUri = generateUri(mainLocalization.label);

    if (usedUris?.includes(newUri)) {
      Notify.warning(formTexts.notifications.warningDuplicateFormURL);
      return;
    }

    values.formUri = newUri;
    await formsServiceClient.createForm(values);
    await loadForms();

    Notify.success(texts.notifications.create.success, [
      texts.models.formTemplate.title,
    ]);
    router.go(-1);
  } catch (err) {
    Notify.failure(texts.notifications.create.failure, [
      texts.models.formTemplate.title,
    ]);
    throw err;
  } finally {
    submitting.value = false;
  }
};

function generateUri(label: string) {
  return label
    .trim()
    .toLowerCase()
    .replace(/[^\w|\d|\s]+/g, "")
    .replace(/[\s]+/g, "-");
}
</script>
