<template>
  <div :class="['grid gap-8 lg:grid-cols-2']">
    <div
      :class="['flex flex-col gap-4 py-6', { 'opacity-50': updatingFilter }]"
      :data-testid="testIds.dashboard.totals"
    >
      <CardHeader :text="componentTexts.totalsHeader"></CardHeader>
      <div class="flex flex-col gap-8 lg:flex-row">
        <StatsItem
          :title="componentTexts.uniqueProspects"
          :value="prospectStats?.uniqueProspectCount"
        >
          <template v-slot:icon>
            <Card :size="CardSize.xs">
              <Icon icon="group" :color="Color.Lavender" />
            </Card>
          </template>
        </StatsItem>
        <StatsItem
          :title="componentTexts.uniqueApplicantProspects"
          :value="prospectStats?.uniqueApplicantProspectCount"
        >
          <template v-slot:icon>
            <Card :size="CardSize.xs">
              <Icon icon="hotel_class" :color="Color.Orchid" />
            </Card>
          </template>
        </StatsItem>
        <StatsItem
          :title="componentTexts.uniqueEnrolledProspects"
          :value="prospectStats?.uniqueEnrolledProspectCount"
        >
          <template v-slot:icon>
            <Card :size="CardSize.xs">
              <Icon icon="school" />
            </Card>
          </template>
        </StatsItem>
      </div>
    </div>
    <Card
      :class="['flex flex-col gap-4', { 'opacity-50': updatingFilter }]"
      :data-testid="testIds.dashboard.informationRequests"
    >
      <CardHeader :text="componentTexts.InformationRequestsHeader"></CardHeader>
      <div :class="['flex flex-col gap-8 lg:flex-row']">
        <StatsItem
          :title="componentTexts.BrochureRequestsTitle"
          :value="
            prospectStats?.prospectCountPerInformationRequestType
              .brochureRequestCount
          "
        >
          <template v-slot:icon>
            <Card :size="CardSize.xs" :color="Color.DeepTeal" flat>
              <Icon icon="menu_book" :color="Color.DeepTeal" />
            </Card>
          </template>
        </StatsItem>
        <StatsItem
          :title="componentTexts.KeepMeInformedRequestsTitle"
          :value="
            prospectStats?.prospectCountPerInformationRequestType
              .keepMeInformedRequestCount
          "
        >
          <template v-slot:icon>
            <Card :size="CardSize.xs" :color="Color.Royal" flat>
              <Icon icon="loyalty" :color="Color.Royal" />
            </Card>
          </template>
        </StatsItem>
        <StatsItem
          :title="componentTexts.ThirdPartyProspectSourcesTitle"
          :value="
            prospectStats?.prospectCountPerInformationRequestType
              .thirdPartyProspectSourcesCount
          "
        >
          <template v-slot:icon>
            <Card :size="CardSize.xs" :color="Color.Sunflower" flat>
              <Icon icon="alt_route" :color="Color.Sunflower" />
            </Card>
          </template>
        </StatsItem>
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { testIds } from "@/utils/testing";
import { CardSize } from "@/components/common/card/Card.types";
import { Color } from "@/enums";
import Card from "@/components/common/card/Card.vue";
import Icon from "@/components/common/icon/Icon.vue";
import StatsItem from "@/components/common/stats-item/StatsItem.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import { IProspectStatsDTO } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";

defineProps<{
  updatingFilter: boolean;
  prospectStats?: IProspectStatsDTO;
}>();

const componentTexts = texts.navigationItems.dashboard;
</script>
