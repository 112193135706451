import {
  StudyProgramDTO,
  StudyProgramCreateDTO,
  StudyProgramCopyDTO,
  StudyProgramUpdateDTO,
  StudyProgramExtendedDTO,
} from "@/models/study-program";
import {
  StudyProgramAreaOfInterest,
  StudyProgramAreaOfInterestCreateDTO,
  StudyProgramAreaOfInterestDTO,
  StudyProgramAreaOfInterestExtended,
  StudyProgramAreaOfInterestExtendedDTO,
  StudyProgramAreaOfInterestUpdateDTO,
} from "@/models/study-program-area-of-interest";
import {
  StudyProgramDepartment,
  StudyProgramDepartmentDTO,
  StudyProgramDepartmentExtended,
  StudyProgramDepartmentExtendedDTO,
  StudyProgramDepartmentCreateDTO,
  StudyProgramDepartmentUpdateDTO,
} from "@/models/study-program-department";
import {
  StudyProgramLevel,
  StudyProgramLevelDTO,
  StudyProgramLevelExtended,
  StudyProgramLevelExtendedDTO,
  StudyProgramLevelCreateDTO,
  StudyProgramLevelUpdateDTO,
} from "@/models/study-program-level";
import {
  StudyProgramLocation,
  StudyProgramLocationCreateDTO,
  StudyProgramLocationDTO,
  StudyProgramLocationExtended,
  StudyProgramLocationExtendedDTO,
  StudyProgramLocationUpdateDTO,
} from "@/models/study-program-location";
import {
  StudyProgramMode,
  StudyProgramModeDTO,
  StudyProgramModeExtended,
  StudyProgramModeExtendedDTO,
  StudyProgramModeCreateDTO,
  StudyProgramModeUpdateDTO,
} from "@/models/study-program-mode";
import HttpClient from "@/utils/httpClient";

// Study programs
export const getStudyPrograms = async (
  includeArchived = false,
): Promise<StudyProgramDTO[]> => {
  return await HttpClient.educonfig
    .get<StudyProgramDTO[]>(`study-programs?includeArchived=${includeArchived}`)
    .then((res) => res.data);
};

export const getStudyProgram = async (
  id: string,
): Promise<StudyProgramExtendedDTO> => {
  return await HttpClient.educonfig
    .get<StudyProgramExtendedDTO>(`study-programs/${id}`)
    .then((res) => res.data);
};

export const createStudyProgram = async (
  studyProgram: StudyProgramCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(`study-programs`, studyProgram);
};

export const copyStudyProgram = async (
  id: string,
): Promise<StudyProgramCopyDTO> => {
  return await HttpClient.educonfig
    .post(`study-programs/${id}/copy`)
    .then((response) => response.data);
};

export const updateStudyProgram = async (
  studyProgram: StudyProgramUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `study-programs/${studyProgram.id}`,
    studyProgram,
  );
};

export const deleteStudyProgram = async (
  studyProgramId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(`study-programs/${studyProgramId}`);
};

// Study program locations
export const getStudyProgramLocations = async (): Promise<
  StudyProgramLocation[]
> => {
  return await HttpClient.educonfig
    .get<StudyProgramLocationDTO[]>(`study-programs/locations`)
    .then((res) => res.data.map((dto) => new StudyProgramLocation(dto)));
};

export const getStudyProgramLocation = async (
  id: string,
): Promise<StudyProgramLocationExtended> => {
  return await HttpClient.educonfig
    .get<StudyProgramLocationExtendedDTO>(`study-programs/locations/${id}`)
    .then((res) => new StudyProgramLocationExtended(res.data));
};

export const createStudyProgramLocation = async (
  studyProgramLocation: StudyProgramLocationCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(
    `study-programs/locations`,
    studyProgramLocation,
  );
};

export const updateStudyProgramLocation = async (
  studyProgramLocation: StudyProgramLocationUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `study-programs/locations/${studyProgramLocation.id}`,
    studyProgramLocation,
  );
};

export const deleteStudyProgramLocation = async (
  studyProgramLocationId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(
    `study-programs/locations/${studyProgramLocationId}`,
  );
};

// Study program modes
export const getStudyProgramModes = async (): Promise<StudyProgramMode[]> => {
  return await HttpClient.educonfig
    .get<StudyProgramModeDTO[]>(`study-programs/modes`)
    .then((res) => res.data.map((dto) => new StudyProgramMode(dto)));
};

export const getStudyProgramMode = async (
  id: string,
): Promise<StudyProgramModeExtended> => {
  return await HttpClient.educonfig
    .get<StudyProgramModeExtendedDTO>(`study-programs/modes/${id}`)
    .then((res) => new StudyProgramModeExtended(res.data));
};

export const createStudyProgramMode = async (
  studyProgramMode: StudyProgramModeCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(
    `study-programs/modes`,
    studyProgramMode,
  );
};

export const updateStudyProgramMode = async (
  studyProgramMode: StudyProgramModeUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `study-programs/modes/${studyProgramMode.id}`,
    studyProgramMode,
  );
};

export const deleteStudyProgramMode = async (
  studyProgramModeId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(
    `study-programs/modes/${studyProgramModeId}`,
  );
};

// Study program departments
export const getStudyProgramDepartments = async (): Promise<
  StudyProgramDepartment[]
> => {
  return await HttpClient.educonfig
    .get<StudyProgramDepartmentDTO[]>(`study-programs/departments`)
    .then((res) => res.data.map((dto) => new StudyProgramDepartment(dto)));
};

export const getStudyProgramDepartment = async (
  id: string,
): Promise<StudyProgramDepartmentExtended> => {
  return await HttpClient.educonfig
    .get<StudyProgramDepartmentExtendedDTO>(`study-programs/departments/${id}`)
    .then((res) => new StudyProgramDepartmentExtended(res.data));
};

export const createStudyProgramDepartment = async (
  studyProgramDepartment: StudyProgramDepartmentCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(
    `study-programs/departments`,
    studyProgramDepartment,
  );
};

export const updateStudyProgramDepartment = async (
  studyProgramDepartment: StudyProgramDepartmentUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `study-programs/departments/${studyProgramDepartment.id}`,
    studyProgramDepartment,
  );
};

export const deleteStudyProgramDepartment = async (
  studyProgramDepartmentId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(
    `study-programs/departments/${studyProgramDepartmentId}`,
  );
};

// Study program levels
export const getStudyProgramLevels = async (): Promise<StudyProgramLevel[]> => {
  return await HttpClient.educonfig
    .get<StudyProgramLevelDTO[]>(`study-programs/levels`)
    .then((res) => res.data.map((dto) => new StudyProgramLevel(dto)));
};

export const getStudyProgramLevel = async (
  id: string,
): Promise<StudyProgramLevelExtended> => {
  return await HttpClient.educonfig
    .get<StudyProgramLevelExtendedDTO>(`study-programs/levels/${id}`)
    .then((res) => new StudyProgramLevelExtended(res.data));
};

export const createStudyProgramLevel = async (
  studyProgramLevel: StudyProgramLevelCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(
    `study-programs/levels`,
    studyProgramLevel,
  );
};

export const updateStudyProgramLevel = async (
  studyProgramLevel: StudyProgramLevelUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `study-programs/levels/${studyProgramLevel.id}`,
    studyProgramLevel,
  );
};

export const deleteStudyProgramLevel = async (
  studyProgramLevelId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(
    `study-programs/levels/${studyProgramLevelId}`,
  );
};

// Study program areas of interest
export const getStudyProgramAreasOfInterest = async (): Promise<
  StudyProgramAreaOfInterest[]
> => {
  return await HttpClient.educonfig
    .get<StudyProgramAreaOfInterestDTO[]>(`study-programs/areas-of-interest`)
    .then((res) => res.data.map((dto) => new StudyProgramAreaOfInterest(dto)));
};

export const getStudyProgramAreaOfInterest = async (
  id: string,
): Promise<StudyProgramAreaOfInterestExtended> => {
  return await HttpClient.educonfig
    .get<StudyProgramAreaOfInterestExtendedDTO>(
      `study-programs/areas-of-interest/${id}`,
    )
    .then((res) => new StudyProgramAreaOfInterestExtended(res.data));
};

export const createStudyProgramAreaOfInterest = async (
  studyProgramAreaOfInterest: StudyProgramAreaOfInterestCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(
    `study-programs/areas-of-interest`,
    studyProgramAreaOfInterest,
  );
};

export const updateStudyProgramAreaOfInterest = async (
  studyProgramAreaOfInterest: StudyProgramAreaOfInterestUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `study-programs/areas-of-interest/${studyProgramAreaOfInterest.id}`,
    studyProgramAreaOfInterest,
  );
};

export const deleteStudyProgramAreaOfInterest = async (
  studyProgramAreaOfInterestId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(
    `study-programs/areas-of-interest/${studyProgramAreaOfInterestId}`,
  );
};
