<template>
  <Modal
    v-model:visible="visibleInternal"
    :type="ModalType.danger"
    :description="
      texts.navigationItems.contact.contactMoments.modal.delete.description
    "
    :title="texts.navigationItems.contact.contactMoments.modal.delete.title"
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <ButtonDelete
          :data-testid="testIds.action.confirm"
          class="sm:ml-3"
          :loading="actionLoading"
          :disabled="!visibleInternal"
          @click="performDelete"
        />
        <ButtonCancel
          class="mt-3 sm:mt-0"
          :disabled="actionLoading || !visibleInternal"
          @click="visibleInternal = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import { testIds } from "@/utils/testing";
import { computed, ref } from "vue";
import texts from "@/utils/texts";
import Notify from "@/utils/notify";
import logger from "@/plugins/logger";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { deleteEnrollmentInStudyProgram } from "@/services/enrollment.service";

const props = defineProps<{
  deletingId?: string | undefined;
}>();

const emit = defineEmits<{
  (e: "update:deletingId", value: string | undefined): void;
}>();

const visibleInternal = computed({
  get: () => !!props.deletingId,
  set: (value) => {
    if (!value) emit("update:deletingId", undefined);
  },
});

const componentTexts = texts.navigationItems.contact.contactMoments.details;

const deleteContactMomentLoading = ref<boolean>(false);
const deletingContactMoment = ref<boolean>(false);
// Delete contact moment modal
const actionLoading = ref(false);
// Deleting contact moment
const performDelete = () => {
  if (!props.deletingId) {
    throw new Error(`Cannot perform action. No contactmomentId provided.`);
  }

  return deleteEnrollmentInStudyProgram(props.deletingId)
    .then(() => {
      Notify.success(componentTexts.deleteEnrollmentResult.success);
    })
    .catch((error) => {
      logger.error(error);
      Notify.failure(componentTexts.deleteEnrollmentResult.failure);
    })
    .finally(() => {
      deletingContactMoment.value = false;
      deleteContactMomentLoading.value = false;
    });
};
</script>
