<template>
  <TransitionFade>
    <div v-if="mailingRecipientsCountDescription">
      <Alert :content="mailingRecipientsCountDescription" />
    </div>
  </TransitionFade>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Alert from "@/components/common/alert/Alert.vue";
import { Culture } from "@/enums";
import dictionary, { interpolate } from "@/dictionary";
import { RootState } from "@/store/index";
import { useStore } from "vuex";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { SelectionResultsCountDto } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{
  selectionResultCounts?: SelectionResultsCountDto;
}>();

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const getAlertText = (
  selectionResultCount: number,
  prospectCount: number,
): string =>
  interpolate(
    texts.models.mailing.recipientsCount,
    selectionResultCount.toString(),
    prospectCount.toString(),
  );

const mailingRecipientsCountDescription = computed(() => {
  const counts = props.selectionResultCounts;
  if (counts === undefined) {
    return;
  }
  return getAlertText(counts.selectionResultCount, counts.prospectCount);
});
</script>
