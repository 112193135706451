<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-12">
    <Statistics :color="pageColor" />
    <LayoutTwoCol v-if="overview && settings.studyPrograms">
      <template v-slot:left>
        <TrialDayFilter
          :color="pageColor"
          :items="overview"
          :studyPrograms="settings.studyPrograms"
          @filter="(event) => (filteredOverview = event)"
        />
      </template>

      <div>
        <div class="mb-5 flex items-center justify-between">
          <h3 class="text-deapteal-500 text-xl font-semibold xl:text-2xl">
            {{ filteredOverview.filter((o) => o.studyProgramId).length }}
            {{ texts.models.studyProgram.plural }}
          </h3>
          <div class="flex items-center gap-3">
            <ButtonAdd
              v-if="canWriteTrialDays()"
              @click="slideOverConfigurationCreateOpen = true"
            />
          </div>
        </div>
        <span
          v-if="!loading && overview.length === 0"
          class="text-sm italic text-gray-500"
        >
          {{ componentTexts.overview.noResultsFound }}
        </span>
        <div class="flex flex-col gap-3">
          <template
            v-for="(items, key) in studyProgramsPerConfiguration"
            :key="key"
          >
            <TrialDayItemConfiguration
              v-if="items.length > 0"
              :configuration="items[0].configuration"
              :items="items"
              :color="pageColor"
              @configuration:update="
                () => openUpdateConfigurationSlideOver(key.toString())
              "
              @settings:update="
                (value) =>
                  openStudyProgramSettingsSlideOver(
                    value.configurationId,
                    value.studyProgramId,
                  )
              "
            />
          </template>
        </div>
      </div>
    </LayoutTwoCol>
  </Page>

  <SlideOverTrialDayConfigurationUpdate
    v-model:visible="slideOverConfigurationUpdateOpen"
    :configuration="configuration"
    :selectedFormIds="
      configurations
        .filter((c) => c.id != configuration?.id && c.formId)
        .map((c) => c.formId!)
    "
    :loading="loading"
    @update:succeeded="() => fetchOverview()"
  />

  <SlideOverTrialDayConfigurationCreate
    v-model:visible="slideOverConfigurationCreateOpen"
    :selectedFormIds="
      configurations.filter((c) => c.formId).map((c) => c.formId!)
    "
    @create:succeeded="() => fetchOverview()"
  />

  <SlideOverTrialDayStudyProgramSettings
    v-model:visible="slideOverStudyProgramSettingsOpen"
    :configurationId="studyProgramSettingsConfigurationId"
    :settings="studyProgramSettings"
    @update:succeeded="() => fetchOverview()"
  />
</template>

<script setup lang="ts">
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import LayoutTwoCol from "@/components/common/layout/LayoutTwoCol.vue";
import Page from "@/components/common/page/Page.vue";
import { Color } from "@/enums";
import {
  TrialDayConfigurationDto,
  TrialDayConfigurationStudyProgramSettingsDto,
} from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import settings from "@/store/context/settings.context";
import { groupBy } from "@/utils/array";
import texts from "@/utils/texts";
import { DateTime } from "luxon";
import { computed, ref } from "vue";
import {
  TrialDayOverviewItem,
  canWriteTrialDays,
  toTrialDayOverviewItems,
} from "./Overview.types";
import SlideOverTrialDayConfigurationCreate from "./components/SlideOverTrialDayConfigurationCreate.vue";
import SlideOverTrialDayConfigurationUpdate from "./components/SlideOverTrialDayConfigurationUpdate.vue";
import SlideOverTrialDayStudyProgramSettings from "./components/SlideOverTrialDayStudyProgramSettings.vue";
import Statistics from "./components/Statistics.vue";
import TrialDayFilter from "./components/TrialDayFilter.vue";
import TrialDayItemConfiguration from "./components/TrialDayItemConfiguration.vue";

const componentTexts = texts.navigationItems.organize.trialDays;

const loading = ref<boolean>(true);
const error = ref<boolean>(false);
const pageColor = Color.Magenta;

const fromDate = ref<DateTime>(DateTime.now());
const toDate = ref<DateTime>(DateTime.now());

getRecruitmentYear()
  .then((recruitmentYear) => {
    const currentRecruitmentYearDates =
      recruitmentYear.toCurrentRecruitmentYearDates();

    fromDate.value = currentRecruitmentYearDates.start;
    toDate.value = currentRecruitmentYearDates.end;
  })
  .then(() => {
    fetchOverview();
  })
  .catch((e) => {
    error.value = true;
    logger.error(e);
  })
  .finally(() => {
    loading.value = false;
  });

// Get Trial Day Overview & StudyPrograms
const configurations = ref<TrialDayConfigurationDto[]>([]);
const configuration = ref<TrialDayConfigurationDto>();
const overview = ref<Array<TrialDayOverviewItem>>([]);
const filteredOverview = ref<Array<TrialDayOverviewItem>>([]);

const studyProgramsPerConfiguration = computed(() => {
  const studyProgramsWithConfig = groupBy(
    filteredOverview.value.filter((o) => o.configuration.id),
    (item) => item.configuration.id,
  );

  const studyProgramsWithoutConfig = groupBy(
    filteredOverview.value.filter((o) => o.isOrphaned && !o.configuration.id),
    (item) => item.configuration.name,
  );

  const allConfigs: Record<string, TrialDayOverviewItem[]> = {};
  configurations.value.forEach((group) => {
    allConfigs[group.id] = studyProgramsWithConfig[group.id] || [];
  });

  return { ...allConfigs, ...studyProgramsWithoutConfig };
});

const fetchOverview = () => {
  loading.value = true;
  Promise.all([
    eduConfigurationServiceClient.getTrialDayCountsForOverview(
      fromDate.value,
      toDate.value,
      true,
    ),
    eduConfigurationServiceClient.getAllTrialDayConfigurations(true),
  ])
    .then(([ov, cfg]) => {
      overview.value = toTrialDayOverviewItems(
        cfg,
        ov,
        settings.allStudyPrograms,
      );
      configurations.value = cfg;
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

const slideOverConfigurationUpdateOpen = ref(false);
const openUpdateConfigurationSlideOver = (configurationId: string) => {
  slideOverConfigurationUpdateOpen.value = true;

  configuration.value = configurations.value.find(
    (c) => c.id === configurationId,
  );
};

const slideOverConfigurationCreateOpen = ref(false);

const studyProgramSettingsConfigurationId = ref<string>();
const studyProgramSettings =
  ref<TrialDayConfigurationStudyProgramSettingsDto>();
const slideOverStudyProgramSettingsOpen = ref(false);
const openStudyProgramSettingsSlideOver = (
  configurationId: string,
  studyProgramId: string,
) => {
  slideOverStudyProgramSettingsOpen.value = true;

  studyProgramSettingsConfigurationId.value = configurationId;
  studyProgramSettings.value = configurations.value
    .find((c) => c.id === configurationId)
    ?.studyProgramSettings.find((s) => s.studyProgramId === studyProgramId);
};
</script>
