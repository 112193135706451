import {
  CreateTrialDayConfigurationDto,
  TrialDayConfigurationDto,
  UpdateTrialDayConfigurationDto,
} from "@/lib/eduConfigurationServiceClient";

export interface TrialDayConfigurationFormValues {
  name: string;
  formId?: string;
  confirmationEmailTemplateId?: string;
  studyProgramIds: string[];
  maximumStudyProgramsPerRegistrant?: number;
}

export const convertToFormValues = (
  configuration: TrialDayConfigurationDto,
): TrialDayConfigurationFormValues => {
  return {
    ...configuration,
    studyProgramIds: configuration.studyProgramSettings.map(
      (s) => s.studyProgramId,
    ),
  };
};

export const convertToDTO = (
  formValues: TrialDayConfigurationFormValues,
): CreateTrialDayConfigurationDto | UpdateTrialDayConfigurationDto => {
  return new CreateTrialDayConfigurationDto({
    ...formValues,
  });
};
