<template>
  <Card
    v-for="chartData in chartDatas"
    :key="chartData.title"
    :size="CardSize.lg"
    class="flex h-44 flex-col gap-2"
  >
    <CardHeader :text="chartData.title"></CardHeader>
    <Bar :data="chartData.data" :options="chartOptions" />
  </Card>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import { CardSize } from "@/components/common/card/Card.types";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartOptions,
} from "chart.js";
import { ref } from "vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { interpolate } from "@/dictionary";

const props = defineProps<{ activityId: string }>();

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
);

// Define the interface for your chart data
interface DailyChartData {
  title: string;
  data: {
    labels: string[];
    datasets: Array<{
      label: string;
      data: number[];
      backgroundColor: string;
      borderRadius: number;
      maxBarThickness: number;
    }>;
  };
}

const chartDatas = ref<DailyChartData[]>([]);

const dataColor = "#73DD4A";

const dataSetOptions = {
  backgroundColor: dataColor,
  borderRadius: 20,
  maxBarThickness: 15,
  responsive: true,
  maintainAspectRatio: false,
};

eduConfigurationServiceClient
  .getVisitsPerHour(props.activityId)
  .then((data) => {
    const dataSets: DailyChartData[] = [];
    let maxValue = 0;

    for (const day of data) {
      const labels = day.counts.map((hour) => getHourLabel(hour.hour));
      const visitCounts = day.counts.map((item) => item.visitCount);

      maxValue = Math.max(maxValue, ...visitCounts);

      const datasets = [
        {
          label: "",
          data: visitCounts,
          ...dataSetOptions,
        },
      ];
      dataSets.push({
        title: interpolate(
          texts.navigationItems.organize.activity.reports.visitsPerDay,
          day.day.toLocaleString(),
        ),
        data: {
          labels,
          datasets,
        },
      });
    }

    chartDatas.value = dataSets;

    // Dynamically adjust the Y-axis max value based on the data
    if (chartOptions.scales && chartOptions.scales.y) {
      chartOptions.scales.y.max = Math.ceil(maxValue / 5) * 5;
    }
  });

function getHourLabel(hour: number): string {
  return `${hour}:00 - ${hour + 1}:00`;
}

const chartOptions: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 5,
        font: {
          size: 12,
          family: "'Messina Sans', 'sans-serif'",
        },
      },
    },
    x: {
      ticks: {
        font: {
          size: 12,
          family: "'Messina Sans', 'sans-serif'",
        },
      },
    },
  },
  plugins: {
    legend: {
      align: "start",
      labels: {
        boxWidth: 0,
        font: {
          size: 0,
          weight: 600,
          family: "'Messina Sans', 'sans-serif'",
        },
      },
    },
  },
};
</script>
