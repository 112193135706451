<template>
  <PageAsync
    :state="state"
    :data-testid="testIds.targetAudiences"
    class="flex flex-col gap-5"
  >
    <div class="flex items-center">
      <h2 class="flex-grow text-lg font-semibold text-deepteal-700">
        {{ texts.navigationItems.organize.targetAudiences.title }}
      </h2>
      <ButtonAdd v-if="showCreateButton" @click="createOpen = true"></ButtonAdd>
    </div>
    <Section>
      <span
        v-if="context && context.targetAudiences.length === 0"
        data-testid="no-activity-types-found-message"
        class="text-sm italic text-gray-500"
        >{{
          texts.navigationItems.organize.targetAudiences.overview.noResultsFound
        }}</span
      >
      <List v-if="context && context.targetAudiences.length > 0">
        <template v-slot:header>
          <ListItemColumn>{{ texts.models.activityType.name }}</ListItemColumn>
          <ListItemColumn></ListItemColumn>
        </template>
        <ListItem
          v-for="targetAudience in context.targetAudiences || []"
          :key="targetAudience.name"
          :data-testid="testIds.action.edit"
          clickable
          @click="editTargetAudience(targetAudience.id)"
        >
          <ListItemColumn>{{ targetAudience.name }} </ListItemColumn>
          <ListItemColumn :flex="0" class="text-right"
            ><ListItemActions @click.stop>
              <DropdownItem @click="editTargetAudience(targetAudience.id)">
                <Icon icon="edit" :color="Color.DeepTeal"></Icon>
                <span class="text-deepteal-500">{{ texts.actions.edit }}</span>
              </DropdownItem>
              <Divider class="mx-2 my-1" />
              <DropdownItem @click="openDeleteModal(targetAudience.id)">
                <Icon icon="delete" :color="Color.Alert"></Icon>
                <span class="text-alert-500">{{ texts.actions.delete }}</span>
              </DropdownItem>
            </ListItemActions></ListItemColumn
          >
        </ListItem>
      </List>
    </Section>
  </PageAsync>

  <TargetAudienceEdit
    v-if="context"
    v-model:targetAudienceId="editId"
    :selectionContext="context"
    @targetAudiencesStale="handler"
  />
  <TargetAudienceCreate
    v-if="context"
    v-model:visible="createOpen"
    :selectionContext="context"
    @targetAudiencesStale="handler"
  />
  <DeleteTargetAudienceModal
    v-if="targetAudienceIdToDelete"
    :deletingId="targetAudienceIdToDelete"
    @update:deletingId="deleteTargetAudience"
  ></DeleteTargetAudienceModal>
</template>

<script setup lang="ts">
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import Section from "@/components/common/section/Section.vue";
import { testIds } from "@/utils/testing";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import store from "@/store";
import { ref } from "vue";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import List from "@/components/common/list/List.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Divider from "@/components/common/divider/Divider.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { Color } from "@/enums";
import TargetAudienceEdit from "@/views/settings/organization/target-audiences/TargetAudienceEdit.vue";
import TargetAudienceCreate from "@/views/settings/organization/target-audiences/TargetAudienceCreate.vue";
import { loadCriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";
import { useAsyncState } from "@/components/common/async/Async.types";
import PageAsync from "@/components/common/page/PageAsync.vue";
import { loadTargetAudiences } from "@/store/context/settings.context";
import DeleteTargetAudienceModal from "./DeleteTargetAudienceModal.vue";
import { hasPermissions } from "@/router/helpers/userCanGoTo";

const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const showCreateButton = hasPermissions([Permission.TargetaudiencesWrite]);

const {
  state,
  handler,
  response: context,
} = useAsyncState(() => {
  loadTargetAudiences();
  return loadCriterionFieldContext();
});

const editId = ref();
const createOpen = ref(false);
const editTargetAudience = (value?: string) => {
  editId.value = value;
};

handler();

const targetAudienceIdToDelete = ref<string | undefined>(undefined);
// Delete target audience
const openDeleteModal = (targetAudienceId: string) => {
  targetAudienceIdToDelete.value = targetAudienceId;
};

const deleteTargetAudience = (targetAudienceId: string | undefined) => {
  targetAudienceIdToDelete.value = targetAudienceId;
  handler();
};
</script>
