<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.navigationItems.thirdPartyProspectSources.edit.title"
    :subTitle="texts.navigationItems.thirdPartyProspectSources.edit.description"
  >
    <Loader v-if="loading" data-testid="loading-sources" />
    <ThirdPartyProspectSourceForm
      v-else-if="source"
      :formValues="convertToFormValues(source)"
      @submit="submitUpdate"
    >
      <template v-if="deletingSource">
        <ConfirmDeleteDialogButtons
          :loading="actionLoading"
          class="md:col-span-3"
          @confirm="submitDelete"
          @cancel="() => (deletingSource = false)"
        ></ConfirmDeleteDialogButtons>
      </template>
      <template v-else>
        <ButtonSubmit :loading="actionLoading" />
        <ButtonCancel @click="() => (visibleInternal = false)" />
        <ButtonDelete
          class="md:col-start-2"
          @click="() => (deletingSource = true)"
        />
      </template>
    </ThirdPartyProspectSourceForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import Loader from "@/components/common/loader/Loader.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import {
  ThirdPartyProspectSourceOverviewDto,
  ValidationErrorResponse,
} from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { computed, ref, watch } from "vue";
import {
  ThirdPartyProspectSourceFormValues,
  convertToFormValues,
  convertToUpdateDTO,
} from "./ThirdPartyProspectSourceForm.types";
import ThirdPartyProspectSourceForm from "./ThirdPartyProspectSourceForm.vue";
import ConfirmDeleteDialogButtons from "@/components/common/button/ConfirmDeleteDialogButtons.vue";

const props = defineProps<{
  visible: boolean;
  source?: ThirdPartyProspectSourceOverviewDto;
  loading?: boolean;
}>();

const emit = defineEmits([
  "update:visible",
  "update:succeeded",
  "delete:succeeded",
]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const actionLoading = ref<boolean>(false);
const submitUpdate = (values: ThirdPartyProspectSourceFormValues) => {
  if (!props.source) {
    throw new Error("Update values lacks source");
  }

  actionLoading.value = true;
  eduConfigurationServiceClient
    .updateThirdPartyProspectSource(
      props.source.id,
      convertToUpdateDTO(props.source.id, values),
    )
    .then(() => {
      Notify.success(
        texts.navigationItems.thirdPartyProspectSources.edit.success,
      );
      visibleInternal.value = false;
      emit("update:succeeded");
    })
    .catch((error: unknown) => {
      logger.error(error);

      if (error instanceof ValidationErrorResponse) {
        Notify.failure(texts.validationErrors[error.errorCode]);
        return;
      }

      Notify.failure(
        texts.navigationItems.thirdPartyProspectSources.edit.failure,
      );
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const deletingSource = ref<boolean>(false);
const submitDelete = () => {
  if (!props.source) {
    throw new Error(`Cannot perform action. No source provided.`);
  }

  actionLoading.value = true;
  eduConfigurationServiceClient
    .deleteThirdPartyProspectSource(props.source.id)
    .then(() => {
      Notify.success(
        texts.navigationItems.thirdPartyProspectSources.delete.success,
      );
      visibleInternal.value = false;
      emit("delete:succeeded");
    })
    .catch((error: unknown) => {
      logger.error(error);

      if (error instanceof ValidationErrorResponse) {
        Notify.failure(texts.validationErrors[error.errorCode]);
        return;
      }

      Notify.failure(
        texts.navigationItems.thirdPartyProspectSources.delete.failure,
      );
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

// Reset state when opening or closing updateSlideOver
watch(visibleInternal, () => (deletingSource.value = false));
</script>
