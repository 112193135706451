<template>
  <template v-if="edit">
    <div>
      <Combobox
        :id="fieldId"
        v-model="value"
        :name="fieldId"
        :options="context.activities"
        :formatOptionDisplayString="optionFormatter"
        :searchFilter="searchFilter"
        :valid="!errorMessage"
      ></Combobox>
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <span>
      {{
        context.activities
          .filter((activity) => criterion.activityIds?.includes(activity.id))
          .map((i) => i.name)
          .join(", ")
      }}
    </span>
  </template>
</template>

<script setup lang="ts">
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import { useField } from "vee-validate";
import * as yup from "yup";
import {
  LocalizedActivityDTO,
  SelectionCriterionDto,
} from "@/lib/eduConfigurationServiceClient";
import { fields } from "@/utils/miscellaneous";
import Combobox from "@/components/common/combobox/Combobox.vue";

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().activityIds;

const { value, errorMessage } = useField<typeof props.criterion.activityIds>(
  fieldId,
  yup.array().of(yup.string()).min(1).required(),
  {
    initialValue: [...(props.criterion.activityIds ?? [])], // Shallow-copy to decouple field-array from the original
  },
);

const searchFilter = (activity: LocalizedActivityDTO, query: string) =>
  activity.name?.toLowerCase().includes(query.toLowerCase()) ?? false;

const optionFormatter = (activity: LocalizedActivityDTO) => activity.name ?? "";
</script>
