<template>
  <ActivityDrawer v-model:visible="drawer" />
  <div>
    <div class="flex h-16 justify-between bg-deepteal-700 shadow print:hidden">
      <button
        class="border-r border-deepteal-500 bg-deepteal-600 px-5 text-deepteal-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-emerald-500 md:hidden"
        @click="() => (drawer = true)"
      >
        <span class="sr-only">Open sidebar</span>
        <Icon icon="menu" filled aria-hidden="true" />
      </button>
      <ApplicationMenu
        class="px-5"
        :origin="ApplicationMenuOrigin.TopRight"
        showName
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ApplicationMenuOrigin } from "@/components/application-shell/ApplicationMenu.types";
import ApplicationMenu from "@/components/application-shell/ApplicationMenu.vue";
import Icon from "@/components/common/icon/Icon.vue";
import ActivityDrawer from "@/views/organize/activities/activity/components/ActivityDrawer.vue";
import { ref } from "vue";

const drawer = ref(false);
</script>
