<template>
  <RouterLinkAuth v-slot="{ navigate }" :to="to" custom
    ><button
      :class="[
        'transition-color flex items-center justify-between gap-2 text-left text-base text-deepteal-500 duration-75',
        isActive ? 'font-semibold' : 'font-normal',
        disabled ? 'opacity-50' : 'opacity-100',
        disabled ? '' : 'hover:text-deepteal-300',
      ]"
      :disabled="disabled"
      @click="navigate"
    >
      <Icon
        v-if="icon"
        :icon="icon"
        :size="IconSize.sm"
        :color="Color.DeepTeal"
      ></Icon>
      <span class="flex-1" :data-testid="testId"> <slot></slot></span>
      <TransitionFade>
        <Icon
          v-if="completed"
          icon="check_circle"
          filled
          :size="IconSize.sm"
          :color="Color.Emerald"
        ></Icon>
      </TransitionFade>
    </button>
  </RouterLinkAuth>
</template>

<script setup lang="ts">
import { IconSize, IconNames } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { Color } from "@/enums";
import { computed } from "vue";
import { RouteLocationNamedRaw, useRoute } from "vue-router";

const props = defineProps<{
  to: RouteLocationNamedRaw;
  disabled?: boolean;
  completed?: boolean;
  icon?: IconNames;
  testId?: string;
}>();

const isActive = computed(() => props.to.name === useRoute()?.name);
</script>
