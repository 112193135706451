import { EmailTemplateType } from "@/enums";
import { Localization } from "./helpers/localizable";

export interface EmailTemplateDTO {
  id: string;
  name: string;
  type: EmailTemplateType;
}

export interface EmailTemplateLocalizableProperties {
  contentBeeConfiguration: string;
  contentHtml: string;
  fromName: string;
  subject: string;
}

export interface EmailTemplateExtendedDTO extends EmailTemplateDTO {
  fromEmailAddress: string;
  replyToEmailAddress: string;
  localizations: Localization<EmailTemplateLocalizableProperties>[];
}

export interface EmailTemplateCreateOrUpdateDTO {
  name: string;
  fromEmailAddress: string;
  replyToEmailAddress: string;
  type: EmailTemplateType;
  localizations: Localization<EmailTemplateLocalizableProperties>[];
}

export interface EmailTemplateCopyDTO {
  id: string;
}

export default class EmailTemplate {
  id: string;
  name: string;
  type: EmailTemplateType;

  constructor(dto: EmailTemplateDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.type = dto.type;
  }
}

export class EmailTemplateExtended extends EmailTemplate {
  fromEmailAddress: string;
  replyToEmailAddress: string;
  localizations: Localization<EmailTemplateLocalizableProperties>[];

  constructor(dto: EmailTemplateExtendedDTO) {
    super(dto);

    this.fromEmailAddress = dto.fromEmailAddress;
    this.replyToEmailAddress = dto.replyToEmailAddress;
    this.localizations = dto.localizations;
  }
}
