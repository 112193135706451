<template>
  <FormLayout @submit="submitForm">
    <InputField formFieldId="name" label="Naam" :fullWidth="true" />
    <InputField
      formFieldId="domainName"
      label="Domeinnaam (e.g. 'summit.nl')"
      :fullWidth="true"
    />
    <SelectField
      formFieldId="mainLanguageId"
      label="Standaardtaal"
      :items="languages"
      :disabled="languages.length == 0"
    />
    <template v-slot:actions>
      <Button
        label="Annuleren"
        :color="Color.White"
        :buttonElementType="ButtonElementType.button"
        :disabled="saving"
        class="mr-4"
        @click="cancelCreate"
      ></Button>
      <Button
        label="Opslaan"
        :buttonElementType="ButtonElementType.submit"
        :loading="saving"
      ></Button>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import InputField from "@/components/common/text-input/InputField.vue";
import SelectField from "@/components/common/select/SelectField.vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import Button from "@/components/common/button/Button.vue";
import { ButtonElementType } from "@/components/common/button/Button.types";
import { onMounted, ref } from "vue";
import Notify from "@/utils/notify";
import logger from "@/plugins/logger";
import { SelectOption } from "@/components/common/select/SelectOption";
import HttpClient from "@/utils/httpClient";
import { Color } from "@/enums";
import FormLayout from "@/components/common/form/FormLayout.vue";
import { createTenant } from "@/services/tenants.service";
import { TenantStatus } from "@/lib/eduConfigurationServiceClient";

interface TenantCreateFormValues {
  name: string;
  domainName: string;
  mainLanguageId: string;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .matches(/^[a-zA-Z0-9\s-]+$/, "Invalid name for tenant, URI not allowed")
    .required(),
  domainName: yup.string().isDomainName("Invalid domain name").required(),
  mainLanguageId: yup.string().required(),
});

const emit = defineEmits(["close"]);

// Language loading
const languages = ref<SelectOption[]>([]);
onMounted(() => {
  HttpClient.getLanguages().then((result) => {
    languages.value = result.data.map<SelectOption>((l) => ({
      value: l.id,
      label: l.name,
    }));
  });
});

const saving = ref(false);

const cancelCreate = () => {
  emit("close");
};

const form = useForm<TenantCreateFormValues>({
  validationSchema,
});

const submitForm = form.handleSubmit((values: TenantCreateFormValues) => {
  saving.value = true;

  createTenant({
    name: values.name,
    domainNames: [values.domainName],
    mainLanguageId: values.mainLanguageId,
    description: "",
    status: TenantStatus.Default,
  })
    .then(() => {
      Notify.success({
        title: "Klant aangemaakt",
        content: "De klant is succesvol aangemaakt.",
      });
      emit("close");
    })
    .catch((e) => {
      Notify.failure({
        title: "Klant aanmaken mislukt",
        content: "Het aanmaken van de klant is helaas mislukt.",
      });
      logger.log(e);
    })
    .finally(() => {
      saving.value = false;
    });
});
</script>
