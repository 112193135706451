<template>
  <TransitionFade>
    <div
      v-if="stats"
      class="grid grid-cols-1 items-stretch gap-4 lg:grid-cols-3 lg:gap-16"
    >
      <StatsCard
        :color="Color.Lavender"
        :value="stats.registrantCount"
        :max="activityCapacity"
        class="w-full"
        :header="textsReports.registrations.title"
      >
        <div class="flex flex-col">
          <span
            v-if="
              stats.expectedVisitors &&
              stats.expectedVisitors !== stats.registrantCount
            "
            class="text-xs font-normal text-gray-400"
          >
            {{ textsReports.includedFollowing }}:
            {{ stats.expectedVisitors }}</span
          >
        </div>

        <template
          v-if="stats.expectedVisitors > 0 && canViewPersonalData()"
          v-slot:items
        >
          <DropdownItem
            :data-testid="testIds.action.download"
            :disabled="downloadRegistrantsState !== AsyncState.Content"
            @click="downloadRegistrantsHandler"
          >
            <div class="flex gap-5">
              <IconAsync
                :state="downloadRegistrantsState"
                icon="download"
                :color="Color.Lavender"
              ></IconAsync>
              <span class="text-deepteal-500">
                {{ textsReports.registrations.exportBtn }}
              </span>
            </div>
          </DropdownItem>
        </template>
      </StatsCard>
      <StatsCard
        :color="Color.Lime"
        :value="stats.visitorCount"
        class="w-full"
        :header="textsReports.visitors.title"
      >
        <template v-if="canViewPersonalData()" v-slot:items>
          <template v-if="stats.visitorCount > 0">
            <DropdownItem
              :data-testid="testIds.action.download"
              :disabled="downloadVisitorsState !== AsyncState.Content"
              @click="downloadVisitorsHandler"
            >
              <div class="flex gap-5">
                <IconAsync
                  :state="downloadVisitorsState"
                  icon="download"
                  :color="Color.Lime"
                ></IconAsync>
                <span class="text-deepteal-500">
                  {{ textsReports.visitors.exportBtn }}
                </span>
              </div>
            </DropdownItem>
          </template>
          <DropdownItem
            :data-testid="testIds.action.import"
            :disabled="downloadVisitorsState !== AsyncState.Content"
            @click="showImport = true"
          >
            <div class="flex gap-5">
              <Icon icon="upload" :color="Color.Lime"></Icon>
              <span class="text-deepteal-500">
                {{ textsReports.visitors.importBtn }}
              </span>
            </div>
          </DropdownItem>
        </template>
      </StatsCard>
      <StatsCard
        :color="Color.Fuchsia"
        :value="stats.visitedSurveyCount"
        :header="textsReports.visitedSurveys.cards.submitted.title"
      >
        <template
          v-if="stats.visitedSurveyCount > 0 && canViewPersonalData()"
          v-slot:items
        >
          <RouterLinkAuth
            :to="{
              name: RouteNamesActivityReports.VISITED_SURVEYS,
              params: {
                id: activityId,
              },
            }"
          >
            <DropdownItem>
              <div class="flex gap-5">
                <Icon icon="pie_chart" :color="Color.Fuchsia"></Icon>
                <span class="text-deepteal-500">
                  {{ textsReports.visitedSurveys.cards.submitted.linkTitle }}
                </span>
              </div>
            </DropdownItem>
          </RouterLinkAuth>
        </template>
      </StatsCard>
    </div>
  </TransitionFade>

  <VisitorsImport
    v-model:visible="showImport"
    :activityId="props.activityId"
    @success="initialize"
  />
</template>

<script setup lang="ts">
import {
  downloadRegistrantsCSV,
  downloadVisitorsCSV,
} from "../../../../../../services/report.service";
import texts from "../../../../../../utils/texts";
import { testIds } from "../../../../../../utils/testing";
import { ActivityStatsDto } from "../../../../../../lib/eduConfigurationServiceClient";
import StatsCard from "../../../../../../components/common/stats-card/StatsCard.vue";
import DropdownItem from "../../../../../../components/common/dropdown/DropdownItem.vue";
import IconAsync from "../../../../../../components/common/icon/IconAsync.vue";
import {
  AsyncState,
  useAsyncState,
} from "../../../../../../components/common/async/Async.types";
import RouterLinkAuth from "../../../../../../components/router/router-link-auth/RouterLinkAuth.vue";
import { RouteNamesActivityReports } from "../../../../../../router/routeNames";
import { eduConfigurationServiceClient } from "../../../../../../services/eduConfigurationService.client.service";
import { ref, watch } from "vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { Color } from "@/enums";
import { canViewPersonalData } from "@/router/helpers/userCanGoTo";
import Icon from "@/components/common/icon/Icon.vue";
import VisitorsImport from "@/views/organize/activities/activity/hub/VisitorsImport.vue";

const props = defineProps<{ activityId: string; activityCapacity?: number }>();
const stats = ref<ActivityStatsDto>();

const initialize = async () => {
  stats.value = await eduConfigurationServiceClient.getActivityStats(
    props.activityId,
  );
};

watch(
  () => props.activityId,
  () => {
    initialize();
  },
  { immediate: true },
);

const textsReports = texts.navigationItems.organize.activity.reports;

const { handler: downloadRegistrantsHandler, state: downloadRegistrantsState } =
  useAsyncState(
    () => downloadRegistrantsCSV(props.activityId),
    textsReports.registrations.export,
  );

const { handler: downloadVisitorsHandler, state: downloadVisitorsState } =
  useAsyncState(
    () => downloadVisitorsCSV(props.activityId),
    textsReports.visitors.export,
  );

// Import visitors
const showImport = ref(false);
</script>
