<template>
  <FormLayout @submit="submit">
    <FormFieldTextInput
      :id="fieldIds.name"
      :label="componentTexts.name"
      :displayMode="FormFieldDisplayMode.Column"
      :data-testid="fieldIds.name"
    />
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { fields } from "@/utils/miscellaneous";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import { watch } from "vue";
import * as yup from "yup";
import { ThirdPartyProspectSourceFormValues } from "./ThirdPartyProspectSourceForm.types";
import FormLayout from "@/components/common/form/FormLayout.vue";

const componentTexts = texts.models.thirdPartyProspectSources;

const props = defineProps<{
  formValues?: ThirdPartyProspectSourceFormValues;
}>();

const emit = defineEmits<{
  (e: "submit", values: ThirdPartyProspectSourceFormValues): void;
}>();

const form = useForm<ThirdPartyProspectSourceFormValues>({
  validationSchema: yup.object({
    name: yup.string().max(256).required(),
  }),
});

const fieldIds = fields<ThirdPartyProspectSourceFormValues>();

const submit = form.handleSubmit((values) => emit("submit", values));

watch(
  () => props.formValues,
  (values) => {
    form.setValues({ ...values });
  },
  { immediate: true },
);
</script>
