import { IconNames } from "@/components/common/icon/Icon.types";

export interface TextInputButtonProps {
  text?: string;
  icon?: IconNames;
}
export interface TextInputProps {
  id?: string;
  name?: string;
  modelValue?: string;
  type?: TextInputType;
  placeholder?: string;
  valid?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  icon?: IconNames;
  button?: TextInputButtonProps;
  min?: number | string;
  max?: number | string;
}

export enum TextInputType {
  TEXT = "text",
  EMAIL = "email",
  SEARCH = "search",
  NUMBER = "number",
  DATE = "date",
  DATE_TIME = "datetime-local",
  TIME = "time",
  URL = "url",
  PASSWORD = "password",
  COLOR = "color",
}
