import { Culture } from "@/enums";

const CULTURE_STORAGE_KEY = "summit-edu-culture";

export const getCultureFromLocalStorage = (): string | null => {
  return window.localStorage.getItem(CULTURE_STORAGE_KEY);
};

export const setCultureInLocalStorage = (culture: Culture): void => {
  window.localStorage.setItem(CULTURE_STORAGE_KEY, culture);
};
