<template>
  <template v-if="edit">
    <div>
      <Select
        :id="fieldId"
        v-model="typeOption"
        :name="fieldId"
        :options="items"
      />
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <div
      :class="[
        'border border-transparent text-sm text-deepteal-600 xl:text-lg xl:font-normal',
        !criterion.isReadOnly ? '' : '',
      ]"
    >
      {{ texts.enums.selectionType[criterion.type] }}
    </div>
    <div
      v-if="isObsoleteCriterion(criterion.type)"
      class="bg-warning-50 text-warning-600"
    >
      {{ texts.selections.obsoleteMessage }}
      {{ obsoleteMessageForCriterionType }}
    </div>
  </template>
</template>

<script setup lang="ts">
import Select from "@/components/common/select/Select.vue";
import texts from "@/utils/texts";
import { computed } from "vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import { useField } from "vee-validate";
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import * as yup from "yup";
import {
  SelectionCriterionDto,
  SelectionCriterionDtoType,
} from "@/lib/eduConfigurationServiceClient";
import { fields } from "@/utils/miscellaneous";
import { isObsoleteCriterion } from "@/models/selection-criterion";

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().type;

const { value, errorMessage } = useField(
  fieldId,
  yup.mixed<SelectionCriterionDtoType>().required(),
  { initialValue: props.criterion.type },
);

const items = computed<SelectOption[]>(() =>
  Object.values(SelectionCriterionDtoType)
    .filter(
      (type) =>
        type !== SelectionCriterionDtoType.Unknown &&
        ((props.includeObsoleteCriteria ?? true) || !isObsoleteCriterion(type)),
    )
    .map((type) => ({
      label: getLabelForCriterionType(type),
      value: type,
    }))
    .filter(
      (type) => !props.exclude || props.exclude?.includes(type.value) === false,
    ),
);

function getLabelForCriterionType(type: SelectionCriterionDtoType) {
  let label = texts.enums.selectionType[type];
  if (isObsoleteCriterion(type)) {
    return (label = label + ` (${texts.selections.obsoleteMarker})`);
  }
  return label;
}

const typeOption = computed({
  get: () => items.value.find((item) => item.value === value.value),
  set: (option: SelectOption | undefined) => {
    value.value = option?.value as SelectionCriterionDtoType;
  },
});

const obsoleteMessageForCriterionType = computed(() => {
  switch (props.criterion.type) {
    case SelectionCriterionDtoType.IsInterestedInStudyProgram:
      return texts.selections.selectionTypeObsoleteMessage
        .IsInterestedInStudyProgram;
    case SelectionCriterionDtoType.ShowedInterestInCurrentYear:
      return texts.selections.selectionTypeObsoleteMessage
        .ShowedInterestInCurrentYear;
    default:
      return "";
  }
});
</script>
