<template>
  <FormField
    :id="id"
    :label="label"
    :displayMode="displayMode"
    :rules="rules"
    :initialValue="initialValue"
  >
    <template v-slot="{ value, errorMessage }">
      <TextInput
        :id="id"
        v-model="value.value"
        :name="id"
        :type="type"
        :placeholder="placeholder"
        :valid="!errorMessage.value"
        :disabled="disabled"
        :min="min"
        :max="max"
        :button="button"
        @button:click="(event) => emit('button:click', event)"
      ></TextInput>
    </template>
  </FormField>
</template>

<script setup lang="ts">
import FormField from "@/components/common/form/FormField.vue";
import TextInput from "@/components/common/text-input/TextInput.vue";
import {
  TextInputButtonProps,
  TextInputType,
} from "@/components/common/text-input/TextInput.types";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import { AnySchema } from "yup";

withDefaults(
  defineProps<{
    id: string;
    displayMode?: FormFieldDisplayMode;
    rules?: AnySchema;
    label?: string;
    type?: TextInputType;
    placeholder?: string;
    disabled?: boolean;
    min?: number | string;
    max?: number | string;
    button?: TextInputButtonProps;
    initialValue?: string | number;
  }>(),
  {
    type: TextInputType.TEXT,
    displayMode: undefined,
    rules: undefined,
    label: undefined,
    placeholder: undefined,
    disabled: false,
    min: undefined,
    max: undefined,
    button: undefined,
    initialValue: undefined,
  },
);

const emit = defineEmits<{
  (e: "button:click", value: string | undefined | number): void;
}>();
</script>
