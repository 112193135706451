import { DateTime } from "luxon";
import { Localization } from "@/models/helpers/localizable";

interface RegistrationLinkDTOLocalizableProperties {
  name: string;
}

export interface RegistrationLinkDTO {
  id: string;
  name?: string;
}

export interface RegistrationLinkExtendedDTO extends RegistrationLinkDTO {
  name?: string;
  uri: string;
  formId: string;
  activityId: string;
  confirmationEmailTemplateId?: string;
  registrationStartDateTime?: string;
  registrationEndDateTime?: string;
  localizations: Localization<RegistrationLinkDTOLocalizableProperties>[];
}

export interface RegistrationLinkWithRegistrationCountDTO
  extends RegistrationLinkDTO {
  formId: string;
  confirmationEmailTemplateName: string;
  registrationCount: number;
}

export interface RegistrationLinkUpdateDTO {
  id: string;
  localizations: Localization<RegistrationLinkDTOLocalizableProperties>[];
  confirmationEmailTemplateId?: string;
  registrationStartDateTime?: string;
  registrationEndDateTime?: string;
}

export class RegistrationLink {
  id: string;
  name?: string;

  constructor(dto: RegistrationLinkDTO) {
    this.id = dto.id;
    this.name = dto.name;
  }
}

export class RegistrationLinkWithRegistrationCount extends RegistrationLink {
  formId: string;
  confirmationEmailTemplateName: string;
  registrationCount: number;

  constructor(dto: RegistrationLinkWithRegistrationCountDTO) {
    super(dto);

    this.formId = dto.formId;
    this.confirmationEmailTemplateName = dto.confirmationEmailTemplateName;
    this.registrationCount = dto.registrationCount;
  }
}

export class RegistrationLinkExtended extends RegistrationLink {
  uri: string;
  formId: string;
  confirmationEmailTemplateId?: string;
  registrationStartDateTime?: DateTime;
  registrationEndDateTime?: DateTime;

  constructor(dto: RegistrationLinkExtendedDTO) {
    super(dto);
    this.uri = dto.uri;
    this.formId = dto.formId;
    this.confirmationEmailTemplateId = dto.confirmationEmailTemplateId;
    this.registrationStartDateTime =
      dto.registrationStartDateTime != undefined
        ? DateTime.fromISO(dto.registrationStartDateTime)
        : undefined;
    this.registrationEndDateTime =
      dto.registrationEndDateTime != undefined
        ? DateTime.fromISO(dto.registrationEndDateTime)
        : undefined;
  }
}
