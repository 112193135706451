<template>
  <div class="flex flex-col">
    <!-- The negative margin fixes the visual bug with tables that use box-shadows. However negative margins have some side-effect in some cases in combination with overflow rules. 
    To prevent this side-effect, please add a p-1 to the wrapper element -->
    <div class="overflow-x-auto">
      <div class="inline-block min-w-full p-0.5 align-middle">
        <div
          class="overflow-hidden border-b border-gray-200 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <slot name="header"></slot>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <slot></slot>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
