<template>
  <DropdownWrapper :origin="DropdownOrigin.TopRight">
    <template v-slot:button>
      <MenuButton :as="ButtonAdd" />
    </template>
    <template v-slot:items>
      <DropdownItem
        v-for="item in items"
        :key="item.label"
        @click="router.push(item.to)"
        >{{ item.label }}</DropdownItem
      >
    </template>
  </DropdownWrapper>
</template>

<script setup lang="ts">
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { EmailTemplateType, MailingType } from "@/enums";
import { RouteNamesSettings } from "@/router/routeNames";
import texts from "@/utils/texts";
import { MenuButton } from "@headlessui/vue";
import { RouteLocationRaw, useRouter } from "vue-router";

const router = useRouter();

const mailingTypeRoutes: [MailingType, RouteNamesSettings][] = [
  [
    MailingType.General,
    RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_GENERAL,
  ],
  [
    MailingType.Activity,
    RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_INFORMATION,
  ],
  [
    MailingType.ActivityInvite,
    RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_ACTIVITY_INVITE,
  ],
  [
    MailingType.ActivityReminder,
    RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_ACTIVITY_REMINDER,
  ],
  [
    MailingType.ActivityVisitedSurvey,
    RouteNamesSettings.EMAIL_CREATE_MAILING_TEMPLATE_ACTIVITY_VISITED_SURVEY,
  ],
];

const emailTemplateTypeRoutes: [EmailTemplateType, RouteNamesSettings][] = [
  [
    EmailTemplateType.RegistrationConfirmation,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_CONFIRMATION,
  ],
  [
    EmailTemplateType.BrochureRequest,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_BROCHURE,
  ],
  [
    EmailTemplateType.BrochureHardCopyRequest,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_BROCHURE_HARDCOPY,
  ],
  [
    EmailTemplateType.BrochureMailRoom,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_BROCHURE_MAILROOM,
  ],
  [
    EmailTemplateType.RegistrationNotification,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_REGISTRATION_NOTIFICATION,
  ],
  [
    EmailTemplateType.RegistrationCanceledNotification,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_REGISTRATION_CANCELED_NOTIFICATION,
  ],
  [
    EmailTemplateType.KeepMeInformedRequest,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_KEEPMEINFORMED,
  ],
  [
    EmailTemplateType.ApplicationConfirmation,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_APPLICATION_CONFIRMATION,
  ],
  [
    EmailTemplateType.ActivityCapacityFullNotification,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_ACTIVITY_CAPACITY_NOTIFICATION,
  ],
  [
    EmailTemplateType.TrialDayConfirmation,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_TRIAL_DAY_CONFIRMATION,
  ],
  [
    EmailTemplateType.TrialDayNotification,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_TEMPLATE_TRIAL_DAY_NOTIFICATION,
  ],
  [
    EmailTemplateType.AffiliateRegistrationsReportLink,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_AFFILIATE_REGISTRATIONS_REPORT_LINK,
  ],
  [
    EmailTemplateType.AffiliateRegistrationsReport,
    RouteNamesSettings.EMAIL_CREATE_EMAIL_AFFILIATE_REGISTRATIONS_REPORT,
  ],
];

const items: Array<{
  label: string;
  to: RouteLocationRaw;
}> = [
  ...mailingTypeRoutes.map(([type, route]) => ({
    label: texts.enums.mailingType[type],
    to: { name: route },
  })),
  ...emailTemplateTypeRoutes.map(([type, route]) => ({
    label: texts.enums.emailTemplateType[type],
    to: { name: route },
  })),
].sort((a, b) => a.label.localeCompare(b.label));
</script>
