<template>
  <span
    :class="[
      'isolate inline-flex',
      {
        'rounded-full': round,
        'rounded-md': !round,
      },
      {
        'shadow-sm': !flat,
      },
      { 'gap-0.5': gap },
    ]"
  >
    <slot v-bind="props"></slot>
  </span>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    color?: Color;
    size?: ButtonSize;
    type?: ButtonElementType;
    flat?: boolean;
    round?: boolean;
    disabled?: boolean;
    gap?: boolean;
  }>(),
  {
    color: Color.DeepTeal,
    size: ButtonSize.md,
    type: ButtonElementType.button,
    flat: false,
    round: false,
    disabled: false,
    gap: false,
  },
);

import { provide, watchEffect } from "vue";
import { ButtonElementType, ButtonSize } from "./Button.types";
import { ButtonGroupInjectionKey } from "./ButtonGroup.types";
import { Color } from "@/enums";

watchEffect(() => {
  provide(ButtonGroupInjectionKey.Grouped, true);
  provide(ButtonGroupInjectionKey.Color, props.color);
  provide(ButtonGroupInjectionKey.Size, props.size);
  provide(ButtonGroupInjectionKey.Type, props.type);
  provide(ButtonGroupInjectionKey.Round, props.round);
  provide(ButtonGroupInjectionKey.Flat, props.flat);
  provide(ButtonGroupInjectionKey.Disabled, props.disabled);
  provide(ButtonGroupInjectionKey.Gap, props.gap);
});
</script>
