<template>
  <div class="flex items-center gap-4">
    <Icon icon="stacks" :size="IconSize.lg" class="ml-2 text-deepteal-600" />
    <TransitionFade>
      <span
        v-if="selectionResults"
        class="text-xl font-normal text-deepteal-600"
        >{{
          `${selectionResults.prospectCount} ${texts.navigationItems.mailing.steps.selectionDefinition.title}
          `
        }}
      </span>
    </TransitionFade>
  </div>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { ref, watchEffect } from "vue";
import {
  SelectionDefinitionDto,
  SelectionResultsCountDto,
} from "@/lib/eduConfigurationServiceClient";
import { fetchSelectionResults } from "@/components/selection/SelectionDefinition.helpers";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";

const props = defineProps<{
  definition: SelectionDefinitionDto;
}>();

const emit = defineEmits<{
  (e: "results", value: SelectionResultsCountDto | undefined): void;
}>();

const selectionResults = ref<SelectionResultsCountDto>();
const loadSelectionResults = async () => {
  selectionResults.value = await fetchSelectionResults(props.definition);

  emit("results", selectionResults.value);
};

watchEffect(() => {
  loadSelectionResults();
});
</script>
