<template>
  <div class="flex flex-col gap-2 text-deepteal-700">
    <div class="grid grid-cols-1 md:grid-cols-12">
      <div class="flex items-center gap-2 md:col-span-9">
        <span class="text-sm font-medium capitalize md:text-base">
          {{ title }}
        </span>
      </div>
      <div class="hidden items-center gap-1 md:col-span-3 md:flex">
        <RegistrantAndVisitorLegend
          :lavender="texts.models.activity.registrants"
          :lime="texts.models.activity.visitors"
        />
      </div>
    </div>
    <ol
      v-for="activity in activities"
      :key="activity.id"
      class="flex flex-col gap-2"
    >
      <slot :activity="activity"></slot>
    </ol>
  </div>
</template>

<script setup lang="ts">
import Activity from "@/models/activity";
import texts from "@/utils/texts";
import { DateTime } from "luxon";
import { computed } from "vue";
import RegistrantAndVisitorLegend from "@/components/common/legend/RegistrantAndVisitorLegend.vue";

const props = defineProps<{ date?: DateTime; activities: Activity[] }>();

const title = computed(() => {
  return props.date?.toLocaleString(DateTime.DATE_HUGE);
});
</script>
