<template>
  <TransitionFade>
    <Card
      v-if="coordinates && coordinates.length > 0"
      :size="CardSize.lg"
      class="relative flex flex-col gap-4 print:block"
    >
      <CardHeader :text="componentTexts.demoTiles.demo1"></CardHeader>
      <ProspectsMap :coordinates="coordinates" />
    </Card>
  </TransitionFade>
</template>

<script setup lang="ts">
import Card from "@/components/common/card/Card.vue";
import texts from "@/utils/texts";
import { CardSize } from "@/components/common/card/Card.types";
import ProspectsMap from "@/components/maps/prospects-map/ProspectsMap.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import {
  Coordinates,
  ISelectionDefinitionDto,
  SelectionDto,
} from "@/lib/eduConfigurationServiceClient";
import { ref, watch } from "vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";

const componentTexts = texts.navigationItems.dashboard;

const props = defineProps<{
  selection?: ISelectionDefinitionDto;
  activityId?: string;
}>();

const coordinates = ref<Coordinates[]>();

const loadMapData = async () => {
  if (!props.selection) {
    return;
  }

  coordinates.value = undefined;
  coordinates.value =
    await eduConfigurationServiceClient.getProspectCoordinates(
      props.activityId,
      new SelectionDto({ ...props.selection }),
    );
};

watch(
  () => props.selection?.criteria,
  async () => {
    await loadMapData();
  },
  { immediate: true, deep: true },
);
</script>
