<template>
  <TransitionFade>
    <div
      v-if="
        prospectCountPerActivityTypeItems &&
        prospectCountPerActivityTypeItems.length > 0
      "
      class="flex flex-col gap-4"
    >
      <CardHeader :text="componentTexts.activityTypeTotalsHeader"></CardHeader>
      <div class="grid gap-4 lg:grid-cols-4 xl:grid-cols-5">
        <Card
          v-for="item in prospectCountPerActivityTypeItems"
          :key="item.title"
          :size="CardSize.sm"
          :data-testid="testIds.dashboard.activityType"
        >
          <StatsItem :title="item.title" :value="item.value">
            <template v-slot:icon>
              <div
                class="h-5 w-5 rounded-full"
                :style="{
                  backgroundColor: item.id ? getGuidColor(item.id) : '#000000',
                  boxShadow: 'inset 0 0 0 4px rgb(255 255 255 / 0.8)',
                }"
              ></div>
            </template>
          </StatsItem>
        </Card>
        <Card
          v-if="
            prospectStats?.prospectCountPerInformationRequestType
              ?.trialDayRegistrationCount
          "
          :key="componentTexts.TrialDayRequestsTitle"
          :size="CardSize.sm"
        >
          <StatsItem
            :title="componentTexts.TrialDayRequestsTitle"
            :value="
              prospectStats?.prospectCountPerInformationRequestType
                .trialDayRegistrationCount
            "
          >
            <template v-slot:icon>
              <div
                class="h-5 w-5 rounded-full"
                :style="{
                  backgroundColor: '#b4358b',
                  boxShadow: 'inset 0 0 0 4px rgb(255 255 255 / 0.8)',
                }"
              ></div>
            </template>
          </StatsItem>
        </Card>
      </div>
    </div>
  </TransitionFade>
</template>

<script setup lang="ts">
import StatsItem from "@/components/common/stats-item/StatsItem.vue";
import Card from "@/components/common/card/Card.vue";
import { getGuidColor } from "@/utils/color";
import { testIds } from "@/utils/testing";
import CardHeader from "@/components/common/card/CardHeader.vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { IProspectStatsDTO } from "@/lib/eduConfigurationServiceClient";
import { computed } from "vue";
import texts from "@/utils/texts";
import { CardSize } from "@/components/common/card/Card.types";

const props = defineProps<{
  prospectStats?: IProspectStatsDTO;
}>();

const componentTexts = texts.navigationItems.dashboard;

const prospectCountPerActivityTypeItems = computed(() => {
  return props.prospectStats?.prospectCountPerActivityType.map(
    (activityType) => {
      return {
        id: activityType.id,
        title: activityType.name,
        value: activityType.count,
      };
    },
  );
});
</script>
