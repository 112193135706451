<template>
  <form @submit="submitForm">
    <FormFieldTextInput
      id="email"
      :type="TextInputType.EMAIL"
      :disabled="true"
      class="mb-5"
      :label="
        texts.navigationItems.mailing.steps.template.testMail.modal.recipient
      "
    />
    <FormFieldSingleSelectCombobox
      formFieldId="sourceId"
      :options="filteredSources"
      :disabled="!canSendTestMail"
      :formatOptionDisplayString="sourceOptionDisplayFormatter"
      :searchFilter="sourceSearchFilter"
      :displayMode="FormFieldDisplayMode.Column"
      :loading="false"
      class="mb-5"
    />
    <Alert
      v-if="!canSendTestMail"
      :content="
        texts.navigationItems.mailing.steps.template.testMail.modal.cantSend
      "
      class="mb-5"
    />
    <Button
      v-if="canSendTestMail"
      class="w-full"
      :color="Color.DeepTeal"
      :label="
        texts.navigationItems.mailing.steps.template.testMail.modal.button
      "
      :buttonElementType="ButtonElementType.submit"
      :loading="loading"
    />
    <ButtonCancel v-if="!canSendTestMail" class="w-full" @click="closeModal" />
  </form>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { computed } from "vue";
import * as yup from "yup";

import { Color } from "@/enums";
import { TestMailingSource } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";

import Alert from "@/components/common/alert/Alert.vue";
import { ButtonElementType } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import FormFieldSingleSelectCombobox from "@/components/common/combobox/FormFieldSingleSelectCombobox.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import auth from "@/store/context/auth.context";

const props = defineProps<{
  loading: boolean;
  canSendTestMail: boolean;
  testMailingSources: TestMailingSource[];
}>();

interface TestMailForm {
  sourceId: string;
  email: string;
}

const emit = defineEmits<{
  "update:visible": [value: boolean];
  submit: [values: TestMailForm];
}>();

const validationSchema = yup.object({
  sourceId: yup.string().required(),
  email: yup.string().email().required(),
});

const form = useForm<TestMailForm>({
  validationSchema,
  initialValues: {
    sourceId: "",
    email: auth.email ?? "",
  },
});

const closeModal = () => {
  emit("update:visible", false);
};

const submitForm = form.handleSubmit((values: TestMailForm) => {
  emit("submit", values);
});

const sourceSearchFilter = (option: TestMailingSource, query: string) => {
  return (
    option.name.toLowerCase().includes(query.toLowerCase()) ||
    option.email.toLowerCase().includes(query.toLowerCase())
  );
};

const sourceOptionDisplayFormatter = (option: TestMailingSource) => {
  return `${option.name} (${option.email})`;
};

// Select top 100 testMailingSources and the ones with the same email as the user because of performance reasons
const filteredSources = computed(() => {
  const sources = props.testMailingSources;
  const userMail = auth.email ?? "";
  const userSources = sources.filter((s) => s.email === userMail);
  const otherSources = sources.filter((s) => s.email !== userMail);
  return [...userSources, ...otherSources.slice(0, 100 - userSources.length)];
});
</script>
