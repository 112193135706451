<template>
  <li class="flex items-center gap-4" :data-testid="testIds.listItem">
    <span
      v-if="slots.default"
      :class="'flex-1 text-xs text-deepteal-400 lg:flex-2'"
      ><slot></slot>
    </span>
    <StatsBar
      :count="count"
      :max="max"
      :percentage="percentage"
      :color="color"
      :size="size"
      :intensity="intensity"
      :legend="legend"
      class="flex-1 lg:flex-4"
    />
  </li>
</template>

<script setup lang="ts">
import {
  StatsBarIntensity,
  StatsBarSize,
} from "@/components/common/stats-bar/StatsBar.types";
import StatsBar from "@/components/common/stats-bar/StatsBar.vue";
import { Color } from "@/enums";
import { testIds } from "@/utils/testing";

defineProps<{
  color?: Color;
  count: number;
  max: number;
  percentage?: number;
  size?: StatsBarSize;
  intensity?: StatsBarIntensity;
  legend?: string;
}>();

const slots = defineSlots<{
  default?: () => void;
}>();
</script>
