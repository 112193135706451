<!-- 
  Modeled after https://tailwindui.com/components/application-ui/navigation/steps#component-1f0d948b6dc88b825bef07f7503e3087
  Anchor has been replaced by a span and hover styling is removed, because links are not yet necessary. 
  Will need to restore those (using RouterLinkAuth components) when needed
-->
<template>
  <span data-testid="step" :class="stepClasses">
    <span v-if="title" :class="titleClasses" data-testid="title">{{
      title
    }}</span>
    <span
      v-if="description"
      class="text-sm font-medium"
      data-testid="description"
      >{{ description }}</span
    >
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { NavigationStepStatus } from "@/components/common/navigation/NavigationStep.types";

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    status?: NavigationStepStatus;
  }>(),
  {
    title: undefined,
    description: undefined,
    status: NavigationStepStatus.Upcoming,
  },
);

const stepClasses = computed(() => [
  "pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4",
  {
    "border-emerald-600":
      props.status === NavigationStepStatus.Complete ||
      props.status === NavigationStepStatus.Current,
    "border-gray-200": props.status === NavigationStepStatus.Upcoming,
  },
]);

const titleClasses = computed(() => [
  "text-xs font-semibold tracking-wide uppercase",
  {
    "text-emerald-600":
      props.status === NavigationStepStatus.Complete ||
      props.status === NavigationStepStatus.Current,
    "text-gray-500": props.status === NavigationStepStatus.Upcoming,
  },
]);
</script>
