<template>
  <div
    :data-testid="id"
    :class="[
      'grid grid-cols-1 gap-x-4 gap-y-1',
      {
        'sm:grid-cols-3 sm:items-center':
          displayMode === FormFieldDisplayMode.Row,
        'sm:grid-cols-1': displayMode === FormFieldDisplayMode.Column,
      },
    ]"
  >
    <label
      v-if="label"
      :for="id"
      class="block text-sm font-semibold text-deepteal-800"
      >{{ label }}
    </label>

    <div
      :class="{
        'sm:col-span-2': displayMode === FormFieldDisplayMode.Row && !!label,
        'sm:col-span-3':
          displayMode === FormFieldDisplayMode.Column ||
          (FormFieldDisplayMode.Row && !label),
      }"
    >
      <slot v-bind="field"></slot>
    </div>

    <span
      v-if="field.errorMessage.value"
      :class="[
        'text-sm text-alert-500',
        {
          'sm:col-span-2 sm:col-start-2':
            displayMode === FormFieldDisplayMode.Row && !label,
          'sm:col-span-3':
            displayMode === FormFieldDisplayMode.Column && !!label,
        },
      ]"
      :data-testid="testIds.form.errorMessage"
    >
      {{ field.errorMessage.value }}
    </span>
  </div>
</template>

<script setup lang="ts" generic="T">
import { testIds } from "@/utils/testing";
import { useField } from "vee-validate";
import { AnySchema } from "yup";
import { FormFieldDisplayMode } from "./FormField.types";

const props = withDefaults(
  defineProps<{
    id: string;
    label?: string;
    displayMode?: FormFieldDisplayMode;
    rules?: AnySchema;
    initialValue?: T;
  }>(),
  {
    label: undefined,
    displayMode: FormFieldDisplayMode.Column,
    rules: undefined,
    initialValue: undefined,
  },
);

const field = useField<T>(
  props.id,
  props.rules,
  props.initialValue
    ? {
        initialValue: props.initialValue,
      }
    : undefined,
);
</script>
