<template>
  <ComparableStatsChart
    v-if="!!items"
    :items="items"
    :chartTitle="
      interpolate(componentTexts.title, texts.models.studyProgramMode.title)
    "
  />
</template>

<script setup lang="ts">
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import texts from "@/utils/texts";
import ComparableStatsChart from "./ComparableStatsChart.vue";
import { ProspectCount } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { ref } from "vue";
import { ComparableItem } from "@/views/organize/activities/activity/hub/components/ComparableStatsChart.vue";
import { interpolate } from "@/dictionary";

const props = defineProps<{ activityId: string }>();

const componentTexts =
  texts.navigationItems.organize.activity.reports.studyProgramModesGraph;

const items = ref<ComparableItem[]>();

eduConfigurationServiceClient
  .getProspectNumbersForStudyProgramModes(props.activityId)
  .then((response) => (items.value = mapApiResponse(response)))
  .catch((e) => logger.error(e));

const mapApiResponse = (dto: ProspectCount[]): ComparableItem[] =>
  dto.map((item) => ({
    countsWithLegends: [
      {
        count: item.count,
        legend: texts.navigationItems.organize.activity.reports.registrantCount,
      },
      {
        count: item.countWithVisit!,
        legend: texts.navigationItems.organize.activity.reports.visitorCount,
      },
    ],
    label: item.name,
  }));
</script>
