import { Identifier } from "@/typings";
import { ActivityLocationDTOLocalizableProperties } from "./activityLocation";
import {
  getLocalizationsQueryable,
  Localization,
  QueryableLocalizations,
} from "./helpers/localizable";
import StudyProgram, { StudyProgramDTO } from "./study-program";
import { DateTime } from "luxon";
import { SessionDto } from "@/lib/eduConfigurationServiceClient";

export type SessionId = Identifier<Session>;

export interface SessionDTO {
  id: SessionId;
  name?: string;
  startDateTime: string;
  endDateTime: string;
  studyPrograms?: Array<StudyProgramDTO>;
  mode?: SessionMode;
  prospectCount: number;
  expectedVisitorCount: number;
  capacity?: number;
  locationId?: string;
  typeId?: string;
  groupId?: string;
  room?: string;
  registrationLinkId?: string;
}

export interface SessionDTOLocalizableProperties {
  name: string;
  descriptionForRegistrationForm?: string;
  descriptionForRegistrationConfirmation?: string;
}

export interface SessionExtendedDTO {
  id: SessionId;
  name?: string;
  startDateTime: string;
  endDateTime: string;
  mode?: SessionMode;
  locationId?: string;
  locationLocalizations?: Localization<ActivityLocationDTOLocalizableProperties>[];
  room?: string;
  localizations?: Localization<SessionDTOLocalizableProperties>[];
  studyProgramIds: Array<string>;
  capacity?: number;
  sequenceNumber?: number;
  registerFromDateTime?: string;
  registerUntilDateTime?: string;
  externalReference?: string;
  onlineSessionUrl?: string;
  typeId?: string;
  groupId?: string;
  registrationLinkId?: string;
}

export interface SessionCreateAndUpdateDTO {
  localizations: SessionDTOLocalizableProperties[];
  startDateTime: string;
  endDateTime: string;
  studyProgramIds?: Array<string>;
  locationId?: string;
  room?: string;
  capacity?: number;
  sequenceNumber?: number;
  registerFromDateTime?: string;
  registerUntilDateTime?: string;
  externalReference?: string;
  mode?: SessionMode;
  onlineSessionUrl?: string;
  typeId?: string;
  groupId?: string;
  registrationLinkId?: string;
}

export enum SessionMode {
  Physical = "Physical",
  Online = "Online",
}

export default class Session {
  id: SessionId;
  name?: string;
  startDateTime: DateTime;
  endDateTime: DateTime;
  studyPrograms?: Array<StudyProgram>;
  mode?: SessionMode;
  count: number;
  capacity?: number;
  locationId?: string;
  typeId?: string;
  groupId?: string;
  room?: string;
  registrationLinkId?: string;

  constructor(dto: SessionDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.startDateTime = DateTime.fromISO(dto.startDateTime);
    this.endDateTime = DateTime.fromISO(dto.endDateTime);
    this.studyPrograms = dto.studyPrograms
      ? dto.studyPrograms.map((studyProgram) => new StudyProgram(studyProgram))
      : undefined;
    this.capacity = dto.capacity;
    this.count = dto.expectedVisitorCount;
    this.mode = dto.mode;
    this.locationId = dto.locationId;
    this.typeId = dto.typeId;
    this.groupId = dto.groupId;
    this.room = dto.room;
    this.registrationLinkId = dto.registrationLinkId;
  }

  matchesFilter(search: string) {
    const normalized = search.toLowerCase();
    return (
      (this.name && this.name.toLowerCase().includes(normalized)) ||
      (this.studyPrograms &&
        this.studyPrograms.some((studyProgram) =>
          studyProgram.name.toLowerCase().includes(normalized),
        ))
    );
  }
}

export class SessionExtended {
  id: SessionId;
  name?: string;
  startDateTime: DateTime;
  endDateTime: DateTime;
  mode?: SessionMode;
  locationId?: string;
  locationLocalizations?: QueryableLocalizations<ActivityLocationDTOLocalizableProperties>;
  room?: string;
  localizations?: QueryableLocalizations<SessionDTOLocalizableProperties>;
  studyProgramIds: Array<string>;
  capacity?: number;
  sequenceNumber?: number;
  registerFromDateTime?: DateTime;
  registerUntilDateTime?: DateTime;
  externalReference?: string;
  onlineSessionUrl?: string;
  typeId?: string;
  groupId?: string;
  registrationLinkId?: string;

  constructor(dto: SessionExtendedDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.startDateTime = DateTime.fromISO(dto.startDateTime);
    this.endDateTime = DateTime.fromISO(dto.endDateTime);
    this.mode = dto.mode;
    this.locationId = dto.locationId;
    this.locationLocalizations = dto.locationLocalizations
      ? getLocalizationsQueryable(dto.locationLocalizations)
      : undefined;
    this.room = dto.room;
    this.localizations = dto.localizations
      ? getLocalizationsQueryable(dto.localizations)
      : undefined;
    this.studyProgramIds = dto.studyProgramIds;
    this.capacity = dto.capacity;
    this.sequenceNumber = dto.sequenceNumber;
    this.registerFromDateTime = dto.registerFromDateTime
      ? DateTime.fromISO(dto.registerFromDateTime)
      : undefined;
    this.registerUntilDateTime = dto.registerUntilDateTime
      ? DateTime.fromISO(dto.registerUntilDateTime)
      : undefined;
    this.externalReference = dto.externalReference;
    this.onlineSessionUrl = dto.onlineSessionUrl;
    this.typeId = dto.typeId;
    this.groupId = dto.groupId;
    this.registrationLinkId = dto.registrationLinkId;
  }
}

export interface SessionProgramSettingsUpdateDTO {
  forbidOverlappingSessionRegistrations: boolean;
  forbidMultipleSessionRegistrationsPerStudyProgram: boolean;
  minimumSessionRegistrationsPerRegistrant?: number;
  maximumSessionRegistrationsPerRegistrant?: number;
  allowGroupSize: boolean;
  maximumGroupSizePerRegistrant?: number;
}

export interface SessionsForActivity {
  activityId?: string;
  sessions: SessionDto[];
}
