<template>
  <div class="flex h-full flex-col gap-8">
    <div class="flex w-full items-start justify-between">
      <div class="flex flex-col gap-2">
        <PageHeading>{{ textsStudyPrograms.sectionTitle }}</PageHeading>
        <PageSubTitle>{{ textsStudyPrograms.sectionDescription }}</PageSubTitle>
      </div>
      <Button
        v-if="registrationLinks.length === 0"
        :data-testid="testIds.action.submit"
        :loading="actionLoading"
        :trailingIcon="'arrow_right_alt'"
        @click="updateActivityStudyPrograms(true)"
      >
        <span class="inline-flex flex-wrap items-center gap-1">
          <span class="font-semibold">{{ texts.actions.submit }},</span>
          <span class="font-normal">
            {{
              activityContext.activity?.isOnline
                ? textsStudyPrograms.btnToRegistrationLinks
                : textsStudyPrograms.btnToLocations
            }}
          </span>
        </span>
      </Button>
      <Button
        v-else
        :data-testid="testIds.action.submit"
        :loading="actionLoading"
        @click="updateActivityStudyPrograms(false)"
      >
        {{ texts.actions.submit }}
      </Button>
    </div>
    <div class="grid grid-cols-1 gap-8 xl:grid-cols-3">
      <div>
        <StudyProgramFilter
          :studyPrograms="studyPrograms"
          @filter="(event) => (filteredStudyPrograms = event)"
        ></StudyProgramFilter>
      </div>
      <div class="flex flex-col gap-8 xl:col-span-2">
        <div
          class="flex flex-col gap-4 xl:flex-row xl:items-center xl:justify-between"
        >
          <h3 class="text-lg font-semibold text-deepteal-500">
            {{ filteredStudyPrograms?.length }}
            {{ texts.generic.results }}
          </h3>
          <div
            class="flex items-center gap-4 text-sm font-normal text-deepteal-500 underline"
          >
            <button
              class="hover:text-deepteal-700"
              @click="updateAllSelected(true)"
            >
              {{ texts.actions.selectAll }}
            </button>
            <button
              class="hover:text-deepteal-700"
              @click="updateAllSelected(false)"
            >
              {{ texts.actions.clearAll }}
            </button>
          </div>
        </div>
        <StudyProgramListItemSection
          v-for="(group, key) in filteredStudyProgramsByDepartment"
          :key="key"
          v-model:checked="selectedIds"
          :title="key"
          :studyPrograms="group"
        />
        <template v-if="filteredStudyProgramsNoDepartment.length > 0">
          <StudyProgramListItemSection
            v-model:checked="selectedIds"
            :title="`${
              texts.generic.without
            } ${texts.models.studyProgramDepartment.title.toLowerCase()}`"
            :studyPrograms="filteredStudyProgramsNoDepartment"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import router from "@/router/index";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import activityContext from "@/store/context/activity.context";
import settings from "@/store/context/settings.context";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";

import { computed, ref } from "vue";
import { ActivityStatus } from "@/models/activity";
import StudyProgramFilter from "./components/StudyProgramFilter.vue";
import Button from "@/components/common/button/Button.vue";
import StudyProgramListItemSection from "./components/StudyProgramListItemSection.vue";
import { groupBy, sort } from "@/utils/array";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import { RouteNamesActivityOverview } from "@/router/routeNames";
import { testIds } from "@/utils/testing";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import {
  ActivityStudyProgramsDTO,
  LocalizedStudyProgramDTO,
} from "@/lib/eduConfigurationServiceClient";

const textsStudyPrograms =
  texts.navigationItems.organize.activity.studyPrograms;
const props = defineProps<ActivityRouteProps>();

const showStudyProgramPicker = ref(false);

const studyPrograms = computed(() => {
  if (props.activity.status === ActivityStatus.finished) {
    return settings.allStudyPrograms.filter(
      (sp) => props.activity.studyProgramIds.includes(sp.id) || !sp.isArchived,
    );
  }

  return settings.studyPrograms;
});

const selectedIds = ref<string[]>([]);

const activityStudyPrograms = computed<LocalizedStudyProgramDTO[]>(() =>
  settings.allStudyPrograms.filter((sp) => {
    return props.activity.studyProgramIds.includes(sp.id) || !sp.isArchived;
  }),
);
const filteredStudyPrograms = ref(activityStudyPrograms.value);

const filteredStudyProgramsByDepartment = computed(() =>
  sort(
    groupBy(
      filteredStudyPrograms.value,
      (studyProgram) => studyProgram.studyProgramDepartment?.name,
    ),
  ),
);

const filteredStudyProgramsNoDepartment = computed(() =>
  filteredStudyPrograms.value.filter(
    (studyProgram) => !studyProgram.studyProgramDepartment,
  ),
);

const actionLoading = ref(false);

selectedIds.value = props.activity.studyProgramIds;

const fetchActivitySettings = async () => {
  activityContext.activity =
    await eduConfigurationServiceClient.getActivityById(props.id);
};

const updateActivityStudyPrograms = async (redirect: boolean) => {
  try {
    showStudyProgramPicker.value = false;
    actionLoading.value = true;
    await eduConfigurationServiceClient.updateActivityStudyPrograms(
      props.id,
      new ActivityStudyProgramsDTO({ studyProgramIds: selectedIds.value }),
    );

    Notify.success(texts.notifications.edit.success, [
      texts.models.activity.title,
    ]);

    fetchActivitySettings();

    if (redirect) {
      router.push({
        name: activityContext.activity?.isOnline
          ? RouteNamesActivityOverview.REGISTRATIONLINKS
          : RouteNamesActivityOverview.LOCATIONS,
        params: { id: props.id },
      });
    }
  } catch (err) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.activity.title,
    ]);
    throw err;
  } finally {
    actionLoading.value = false;
  }
};

const updateAllSelected = (value: boolean) => {
  const studyPrograms = filteredStudyPrograms.value.map((sp) => sp.id);

  selectedIds.value = selectedIds.value
    .filter((id) => !studyPrograms.some((sp) => sp === id))
    .concat(value ? studyPrograms : []);
};
</script>
