import { EmailTemplateType } from "@/enums";
import {
  IMergeContent,
  IMergeTag,
  ISpecialLink,
} from "@beefree.io/sdk/dist/types/bee";

export interface EmailTemplateTypeVariablesDTO {
  type: EmailTemplateType;
  mergeTags: IMergeTag[];
  mergeContents: IMergeContent[];
  specialLinks: ISpecialLink[];
}

export default class EmailTemplateTypeVariables {
  type: EmailTemplateType;
  mergeTags: IMergeTag[];
  mergeContents: IMergeContent[];
  specialLinks: ISpecialLink[];

  constructor(dto: EmailTemplateTypeVariablesDTO) {
    this.type = dto.type;
    this.mergeTags = dto.mergeTags;
    this.mergeContents = dto.mergeContents;
    this.specialLinks = dto.specialLinks;
  }
}
