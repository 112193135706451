<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.generic.settings"
    :subTitle="texts.navigationItems.keepMeInformed.settings.description"
  >
    <Loader v-if="loading" data-testid="loading-configuration"></Loader>
    <KeepMeInformedConfigurationForm
      v-else-if="configuration"
      :formValues="convertToFormValues(configuration)"
      :selectedFormIds="selectedFormIds"
      @submit="submitUpdate"
    >
      <ButtonSubmit :loading="actionLoading" class="md:col-start-2" />
      <ButtonCancel @click="visibleInternal = false" />
    </KeepMeInformedConfigurationForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import Loader from "@/components/common/loader/Loader.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { KeepMeInformedConfigurationDTO } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { computed, ref } from "vue";
import {
  KeepMeInformedConfigurationFormValues,
  convertToDTO,
  convertToFormValues,
} from "./KeepMeInformedConfigurationForm.types";
import KeepMeInformedConfigurationForm from "./KeepMeInformedConfigurationForm.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";

const props = defineProps<{
  visible: boolean;
  configuration?: KeepMeInformedConfigurationDTO;
  selectedFormIds?: string[];
  loading?: boolean;
}>();

const emit = defineEmits(["update:visible", "update:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

// Updating KeepMeInformedConfiguration
const actionLoading = ref<boolean>(false);
const submitUpdate = (values: KeepMeInformedConfigurationFormValues) => {
  if (!props.configuration) {
    throw new Error("Update values lacks configuration");
  }

  actionLoading.value = true;
  eduConfigurationServiceClient
    .updateKeepMeInformedConfiguration(
      props.configuration.id,
      convertToDTO(values),
    )
    .then(() => {
      Notify.success(texts.notifications.edit.successCached);
      visibleInternal.value = false;
      emit("update:succeeded");
    })
    .catch((e) => {
      Notify.failure(
        texts.navigationItems.keepMeInformed.settings.edit.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
