<template>
  <PrimaryNavigationItem
    :to="{ name: RouteNames.DASHBOARD }"
    icon="monitoring"
    :title="texts.navigationItems.dashboard.title"
  />
  <PrimaryNavigationItem
    :to="{ name: RouteNames.ORGANIZE }"
    icon="calendar_month"
    :title="texts.navigationItems.organize.title"
  />
  <PrimaryNavigationItem
    :to="{ name: RouteNames.INFORMATIONREQUESTS }"
    icon="info"
    :title="texts.navigationItems.informationRequests.title"
  />
  <PrimaryNavigationItem
    :to="{ name: RouteNames.APPLICATIONSENROLLMENTS }"
    icon="school"
    :title="`${texts.navigationItems.applications.title} / ${texts.navigationItems.enrollments.title}`"
  />
  <PrimaryNavigationItem
    :to="{ name: RouteNames.MAILINGS }"
    icon="mail"
    :title="texts.navigationItems.mailings.title"
  />
  <PrimaryNavigationItem
    :to="{ name: RouteNames.CONTACTS }"
    icon="person_search"
    :title="texts.navigationItems.contacts.title"
  />
  <PrimaryNavigationItem
    :to="{ name: RouteNames.SETTINGS }"
    icon="settings"
    :title="texts.navigationItems.manage.settings.title"
  />
</template>

<script setup lang="ts">
import PrimaryNavigationItem from "./PrimaryNavigationItem.vue";
import { RouteNames } from "@/router/routeNames";

import dictionary from "@/dictionary";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Culture } from "@/enums";

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];
</script>
