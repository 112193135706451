<template>
  <Page
    :loading="state === AsyncState.Loading"
    :error="state === AsyncState.Failure"
  >
    <AffiliatesHeader>
      <ButtonAdd @click="slideOverCreateOpen = true" />
    </AffiliatesHeader>

    <AffiliatesList
      v-if="affiliates && schools"
      :schools
      :affiliates
      @click="currentlyEditing = $event"
    />
  </Page>

  <CreateAffiliate
    v-if="schools"
    v-model:visible="slideOverCreateOpen"
    :schools
    @stale="refreshList"
  />

  <UpdateAffiliate
    v-if="schools"
    v-model:affiliate="currentlyEditing"
    :schools
    @stale="refreshList"
  />
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import {
  AsyncState,
  useAsyncState,
} from "@/components/common/async/Async.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { ref } from "vue";
import {
  AffiliateDto,
  PreEducationSchool,
} from "@/lib/eduConfigurationServiceClient";
import AffiliatesList from "@/views/settings/organization/affiliates/AffiliatesList.vue";
import CreateAffiliate from "@/views/settings/organization/affiliates/CreateAffiliate.vue";
import UpdateAffiliate from "@/views/settings/organization/affiliates/UpdateAffiliate.vue";
import AffiliatesHeader from "@/views/settings/organization/affiliates/AffiliatesHeader.vue";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";

const fetchData = async () => {
  affiliates.value = await eduConfigurationServiceClient.getAllAffiliates();
  schools.value =
    await eduConfigurationServiceClient.getAllPreEducationSchools();
};

const schools = ref<PreEducationSchool[]>();
const affiliates = ref<AffiliateDto[]>();
const { state, handler: refreshList } = useAsyncState(fetchData);
refreshList();

const slideOverCreateOpen = ref<boolean>(false);
const currentlyEditing = ref();
</script>
