<template>
  <PageAsync :state="state" class="flex flex-col gap-4 py-8">
    <div class="flex flex-wrap justify-between gap-4">
      <div class="flex items-center gap-4">
        <Search v-model="search" />
        <SwitchToggle
          v-model="registrationQuestion"
          :labels="['Registratie', 'Enquête']"
          :icons="['subject', 'apps']"
          :size="ButtonSize.sm"
        ></SwitchToggle>
        <span class="text-nowrap font-semibold text-deepteal-700">
          {{ filteredItems?.length }} {{ texts.models.question.plural }}
        </span>
      </div>
      <div class="flex items-center gap-4">
        <QuestionTypeFilter v-model="questionType" />
        <ButtonAdd @click="addNewQuestion"></ButtonAdd>
      </div>
    </div>
    <List v-if="filteredItems && filteredItems.length > 0">
      <template v-slot:header>
        <ListItemColumn :flex="0"><div class="h-6 w-6"></div></ListItemColumn>
        <ListItemColumn :flex="2">{{
          texts.models.question.title
        }}</ListItemColumn>
        <ListItemColumn hide>{{ texts.models.question.type }}</ListItemColumn>
      </template>
      <QuestionItem
        v-for="item in filteredItems"
        :key="item.identifier"
        :question="item"
        :label="getQuestionLabel(item)"
        @click="
          selectedQuestion = item;
          slideOverOpen = true;
        "
      />
    </List>
    <SlideOver
      v-model:visible="slideOverOpen"
      :title="selectedQuestion ? getQuestionLabel(selectedQuestion) : undefined"
    >
      <QuestionSettings
        v-if="selectedQuestion && mainCulture"
        :question="selectedQuestion"
        :initialValues="convertToFormValues(selectedQuestion, mainCulture)"
        :newQuestion="isNewQuestion(selectedQuestion)"
        @update:question="submitForm"
      >
        <ButtonCancel @click="cancelForm"></ButtonCancel>
      </QuestionSettings>
    </SlideOver>
  </PageAsync>
</template>

<script setup lang="ts">
import { useAsyncState } from "@/components/common/async/Async.types";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import PageAsync from "@/components/common/page/PageAsync.vue";
import Search from "@/components/common/search/Search.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { Culture } from "@/enums";
import { QuestionType } from "@/lib/formsServiceClient";
import { QuestionEditorQuestionDTO } from "@/lib/formsServiceClient";
import { formsServiceClient } from "@/services/formsService.client.service";
import texts from "@/utils/texts";
import QuestionItem from "@/views/settings/questions/components/QuestionItem.vue";
import QuestionSettings from "@/views/settings/questions/components/question-settings/QuestionSettings.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { computed, ref, watch } from "vue";
import Notify from "@/utils/notify";
import store from "@/store/index";
import {
  QuestionEditorFormValues,
  convertToDto,
  convertToFormValues,
} from "@/views/settings/questions/components/question-settings/QuestionSettings.types";
import QuestionTypeFilter from "@/views/settings/questions/components/QuestionTypeFilter.vue";
import { QuestionFilterType } from "@/views/settings/questions/components/RegistrationOrSurveyQuestionsFilter.types";
import SwitchToggle from "@/components/common/toggle/SwitchToggle.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import settings from "@/store/context/settings.context";

const {
  state,
  handler,
  response: items,
} = useAsyncState(async () => await getEditableQuestions());
handler();

const mainCulture = settings.mainLanguage.locale.value as Culture;

const addNewQuestion = () => {
  if (mainCulture === undefined) {
    return;
  }
  slideOverOpen.value = true;
  selectedQuestion.value = new QuestionEditorQuestionDTO({
    type: QuestionType.Text,
    // Backend assigns a new guid to questions that have -1 as identifier
    identifier: "-1",
    destination: "",
    localizations: [
      {
        language: mainCulture,
        label: texts.models.question.newQuestion,
      },
    ],
    isSurveyQuestion: showSurveyQuestions.value,
  });
};

const isNewQuestion = (q: QuestionEditorQuestionDTO | undefined) =>
  q?.identifier === "-1";

const filteredItems = computed(() =>
  items.value
    ?.filter(
      (item) =>
        !search.value ||
        getQuestionLabel(item)
          .toLowerCase()
          .includes(search.value.toLowerCase()),
    )
    .filter((item) => !questionType.value || item.type == questionType.value)
    .filter((item) => item.isSurveyQuestion === showSurveyQuestions.value),
);

const userCulture = store.getters["cultureStore/active"] as Culture;
const search = ref<string>();
const questionType = ref<QuestionType>();
const actionLoading = ref(false);

const slideOverOpen = ref(false);

const selectedQuestion = ref<QuestionEditorQuestionDTO>();

const getQuestionLabel = (question: QuestionEditorQuestionDTO) => {
  const userLabel = question.localizations?.find(
    (l) => l.language === userCulture,
  )?.label;
  const mainLabel = question.localizations?.find(
    (l) => l.language === mainCulture,
  )?.label;
  return userLabel ?? mainLabel ?? "";
};

const cancelForm = async () => {
  slideOverOpen.value = false;
  selectedQuestion.value = undefined;
};

const submitForm = async (values: QuestionEditorFormValues) => {
  if (!selectedQuestion.value) {
    return;
  }
  actionLoading.value = true;
  await formsServiceClient
    .saveQuestion(convertToDto(values, selectedQuestion.value, mainCulture))
    .then(() => {
      Notify.success(texts.notifications.edit.successCached, [
        texts.models.question.title,
      ]);
      handler();
    })
    .catch((error) => {
      console.warn(error);
      Notify.failure(texts.notifications.edit.failure);
    })
    .finally(() => {
      actionLoading.value = false;
    });
  slideOverOpen.value = false;
  selectedQuestion.value = undefined;
};

watch(
  () => slideOverOpen.value,
  async (newValue) => {
    if (!newValue) {
      try {
        await getEditableQuestions();
      } catch (error) {
        console.warn(error);
      }
    }
  },
);

async function getEditableQuestions() {
  return (await formsServiceClient.getEditableQuestions()).sort((a, b) =>
    getQuestionLabel(a).localeCompare(getQuestionLabel(b)),
  );
}

const registrationOrSurveyQuestionsFilter = computed(() =>
  registrationQuestion.value
    ? QuestionFilterType.Registration
    : QuestionFilterType.Survey,
);

const registrationQuestion = ref<boolean>(true);
const showSurveyQuestions = computed(
  () => registrationOrSurveyQuestionsFilter.value === QuestionFilterType.Survey,
);
</script>
