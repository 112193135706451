import { Culture } from "@/enums";
import { LocalizedLanguage } from "@/lib/eduConfigurationServiceClient";
import {
  ActivityTypeCreateDTO,
  ActivityTypeExtended,
  ActivityTypeUpdateDTO,
} from "@/models/activityType";

export interface ActivityTypeFormValues {
  localizations: ActivityTypeFormValuesLocalization[];
}

export type ActivityTypeFormValuesLocalization = {
  locale: Culture;
  name: string;
};

export const convertToFormValues = (
  activityType: ActivityTypeExtended,
  availableLanguages: LocalizedLanguage[],
): ActivityTypeFormValues => {
  const localizations = availableLanguages.map((available) => {
    const locale = activityType.localizations.find(
      (loc) => loc.locale === available.locale.value,
    );
    return {
      locale: available.locale.value as Culture,
      name: locale?.name ?? "",
    };
  });

  return {
    localizations,
  };
};

export const convertToCreateDto = (
  values: ActivityTypeFormValues,
): ActivityTypeCreateDTO => {
  const localizations = values.localizations.filter((loc) => !!loc.name);

  return {
    localizations,
  };
};

export const convertToUpdateDto = (
  id: string,
  values: ActivityTypeFormValues,
): ActivityTypeUpdateDTO => {
  const localizations = values.localizations.filter((loc) => !!loc.name);

  return {
    id: id,
    localizations,
  };
};
