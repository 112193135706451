import { ActivitySMSCampaignDTO } from "@/services/activity.service";
import { DateTime } from "luxon";

export interface ActivitySMSSettingsFormValues {
  message: string;
  plannedDateTime: DateTime;
}

export const convertToFormValues = (
  dto: ActivitySMSCampaignDTO,
): ActivitySMSSettingsFormValues => {
  return {
    message: dto.message,
    plannedDateTime: DateTime.fromISO(dto.plannedDateTime),
  };
};

export const convertToCreateDTO = (
  values: ActivitySMSSettingsFormValues,
): ActivitySMSCampaignDTO => {
  return {
    message: values.message,
    plannedDateTime: values.plannedDateTime.toISO() ?? "invalid",
  };
};

export const convertToUpdateDTO = (
  values: ActivitySMSSettingsFormValues,
): ActivitySMSCampaignDTO => {
  return {
    message: values.message,
    plannedDateTime: values.plannedDateTime.toISO() ?? "invalid",
  };
};
