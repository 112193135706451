<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="componentTexts.preEducationSchools.title"
  >
    <ImportForm
      :downloadTemplateAction="
        eduConfigurationServiceClient.generateImportSchoolsTemplate.bind(
          eduConfigurationServiceClient,
        )
      "
      @submit="handleImportSubmit"
    >
      <template v-slot:feedback>
        <ImportResults :importCount="importedCount" :errors="importErrors" />
      </template>
      <ButtonSubmit :loading="submitLoading">
        {{ texts.actions.submit }}
      </ButtonSubmit>
      <ButtonCancel @click="visibleInternal = false">
        {{ texts.actions.cancel }}
      </ButtonCancel>
    </ImportForm>
  </SlideOver>
</template>

<script setup lang="ts">
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { computed, ref } from "vue";
import texts from "@/utils/texts";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import Notify from "@/utils/notify";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import ImportForm, {
  ImportFormValues,
} from "@/components/import/ImportForm.vue";
import ImportResults from "@/components/import/ImportResults.vue";

const componentTexts = texts.navigationItems.organize.schools.import;

const props = defineProps<{
  visible: boolean;
}>();

const emit = defineEmits<{
  "update:visible": [value: boolean];
  success: [];
}>();

const visibleInternal = computed({
  get() {
    return props.visible;
  },
  set(value) {
    emit("update:visible", value);
  },
});

const submitLoading = ref(false);
const importedCount = ref<number>(0);
const importErrors = ref<string[]>();

const handleImportSubmit = async (values: ImportFormValues) => {
  submitLoading.value = true;

  await eduConfigurationServiceClient
    .importSchools({
      data: values.file[0],
      fileName: values.file[0].name,
    })
    .then((result) => {
      importErrors.value = result.errors;
      importedCount.value = result.importCount;

      if (result.errors.length == 0) {
        emit("success");
        Notify.success(componentTexts.submitSuccessNotification);
      } else {
        Notify.failure(componentTexts.submitErrorNotification);
      }
    })
    .catch((e) => {
      importErrors.value = e.response.data.errors;
      logger.error(e);
      Notify.failure(componentTexts.submitErrorNotification);
    })
    .finally(() => {
      submitLoading.value = false;
    });
};
</script>
