<template>
  <div class="flex flex-col gap-5">
    <Search v-model="search" :placeholder="texts.actions.search" />
    <div class="flex flex-col gap-4 rounded bg-white p-4 shadow-sm">
      <div class="flex items-center justify-between gap-1.5">
        <h2 class="text-base font-semibold">
          {{ texts.navigationItems.enrollments.filter.title }}
        </h2>
        <span
          :class="getTextColor(color) + ' ' + getTextHoverColor(color)"
          class="cursor-pointer text-xs underline"
          @click="resetFilters"
          >{{ texts.actions.reset }}</span
        >
      </div>
      <StudyProgramFilter
        v-if="studyPrograms"
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        :counts="studyProgramCounts"
        @filter="(values) => (filteredStudyProgramIds = values)"
      />
      <Divider />
      <StudyProgramMetadataFilter
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        @filter="(values) => (filteredStudyProgramIds = values)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Divider from "@/components/common/divider/Divider.vue";
import { compare } from "@/components/common/filter/FilterItem.types";
import Search from "@/components/common/search/Search.vue";
import StudyProgramFilter from "@/components/study-programs/StudyProgramFilter.vue";
import StudyProgramMetadataFilter from "@/components/study-programs/StudyProgramMetadataFilter.vue";
import { countBy } from "@/utils/array";
import texts from "@/utils/texts";
import { computed, ref, watch } from "vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import { Color } from "@/enums";
import { getTextColor, getTextHoverColor } from "@/utils/color";

const props = defineProps<{
  studyProgramIds: string[];
  studyPrograms: LocalizedStudyProgramDTO[];
  color: Color;
}>();

const search = ref<string>();

const emit = defineEmits<{
  (e: "filter", value: string[]): void;
}>();

const lookup = new Map(
  props.studyProgramIds.map((id) => [
    id,
    props.studyPrograms.find((sp) => sp.id === id),
  ]),
);

const filteredStudyProgramIds = ref<string[] | undefined>();
const filteredStudyProgramMetaData = ref<string[] | undefined>();

const studyProgramCounts = computed(() =>
  countBy(props.studyProgramIds, (id) => id),
);

const resetStudyProgramFilter = ref(false);
const resetFilters = () => {
  resetStudyProgramFilter.value = !resetStudyProgramFilter.value;
};

const filteredItems = computed<string[]>(() => {
  return props.studyProgramIds
    .filter(
      (id) =>
        !search.value ||
        lookup
          .get(id)
          ?.displayName?.toLowerCase()
          .includes(search.value.toLowerCase()),
    )
    .filter((id) => compare(filteredStudyProgramIds.value, id))
    .filter((id) => compare(filteredStudyProgramMetaData.value, id));
});

watch(filteredItems, (value) => emit("filter", value), { immediate: true });
</script>
