<template>
  <FormField :id="id" :label="label">
    <template v-slot="{ errorMessage }">
      <EmailDomainPicker
        v-model="email"
        :domainItems="domainItems"
        :placeholder="placeholder"
        :disabled="disabled"
        :valid="!errorMessage.value"
        @update:email="updateFormField"
      ></EmailDomainPicker>
    </template>
  </FormField>
</template>

<script setup lang="ts">
import { SelectOption } from "@/components/common/select/SelectOption";
import FormField from "@/components/common/form/FormField.vue";
import EmailDomainPicker from "@/components/common/email/EmailDomainPicker.vue";
import { useField } from "vee-validate";

withDefaults(
  defineProps<{
    id: string;
    label?: string;
    placeholder?: string;
    modelValue?: string | undefined;
    domainItems: SelectOption[] | undefined;
    disabled?: boolean;
  }>(),
  {
    domainItems: undefined,
    label: undefined,
    placeholder: undefined,
    modelValue: undefined,
  },
);

const email = defineModel("email", {
  type: String,
  default: undefined,
});

const updateFormField = (value: string | undefined) => {
  emailFormField.value.value = value;
};

const emailFormField = useField<string | undefined>("email");
</script>
