<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="visibleInternal">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-slideOver overflow-hidden"
      :open="visibleInternal"
      @close="visibleInternal = false"
    >
      <div
        class="absolute inset-0 overflow-hidden"
        :data-testid="testIds.slideOver"
      >
        <TransitionChild
          as="template"
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-300 sm:duration-500"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-300 sm:duration-500"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <div
                class="flex h-screen flex-col overflow-y-auto bg-white shadow-xl"
              >
                <div class="px-4 py-6 sm:px-6">
                  <div class="flex items-start justify-between space-x-3">
                    <div class="space-y-6">
                      <DialogTitle>
                        <PageHeading>
                          <div class="flex flex-col gap-2">
                            <PageHeading data-testid="slideover-title">{{
                              title
                            }}</PageHeading>
                            <PageSubTitle
                              v-if="subTitle"
                              data-testid="slideover-subtitle"
                              >{{ subTitle }}</PageSubTitle
                            >
                          </div>
                        </PageHeading>
                      </DialogTitle>
                    </div>
                    <div class="flex h-7 items-center">
                      <button
                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                        :data-testid="testIds.action.close"
                        @click="visibleInternal = false"
                      >
                        <span class="sr-only">Close panel</span>
                        <Icon
                          icon="close"
                          :size="IconSize.lg"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <slot></slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { testIds } from "@/utils/testing";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { computed } from "vue";
import PageHeading from "../page-heading/PageHeading.vue";
import PageSubTitle from "../page-heading/PageSubTitle.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";

const props = defineProps<{
  visible: boolean;
  title?: string;
  subTitle?: string;
}>();

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});
</script>
