import {
  BrochureConfigurationDto,
  UpsertBrochureConfigurationDto,
} from "@/lib/eduConfigurationServiceClient";

export interface BrochureConfigurationFormValues {
  name: string;
  formId?: string;
  confirmationEmailTemplateId?: string;
  isHardCopy: boolean;
  mailRoomEmailAddress?: string;
  mailRoomEmailTemplateId?: string;
  brochureIds: string[];
}

export const convertToFormValues = (
  configuration: BrochureConfigurationDto,
): BrochureConfigurationFormValues => {
  return {
    ...configuration,
  };
};

export const convertToDTO = (
  formValues: BrochureConfigurationFormValues,
): UpsertBrochureConfigurationDto => {
  return new UpsertBrochureConfigurationDto({
    ...formValues,
  });
};
