<template>
  <li
    class="flex w-full flex-col gap-1 sm:flex-row-reverse sm:items-center sm:gap-4 lg:gap-6"
  >
    <span
      v-if="slots.default"
      :class="'flex-1 text-xs text-deepteal-400 lg:flex-2'"
      ><slot></slot
    ></span>
    <div class="flex w-full flex-2 items-center gap-4 lg:flex-4">
      <div class="flex flex-row gap-4 sm:flex-row sm:gap-2">
        <div
          v-for="({ count, legend }, index) in items"
          :key="`stat-${index}`"
          class="flex items-center justify-end gap-1 sm:w-7"
        >
          <span
            :class="[
              'text-sm font-bold',
              getTextColor(colors[index]),
              { 'hover:opacity-90': !!legend },
            ]"
            :title="legend"
          >
            {{ count }}
          </span>
          <span
            :class="[
              'inline truncate text-xs font-semibold sm:hidden',
              getTextColor(colors[index]),
            ]"
            >{{ legend }}</span
          >
        </div>
      </div>
      <div class="hidden flex-1 gap-1 sm:flex sm:flex-col">
        <!-- only render a bar if its value > 0 -->
        <StatsBarBase
          v-for="({ count, legend }, index) in items.filter(
            (item) => !!item.count,
          )"
          :key="`stat-${index}`"
          :count="count"
          :max="max"
          :size="StatsBarSize.sm"
          :color="colors[index]"
          :intensity="intensity"
          :title="legend"
        />
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import { getTextColor } from "@/utils/color";
import { StatsBarIntensity, StatsBarSize } from "./StatsBar.types";
import StatsBarBase from "./StatsBarBase.vue";
import { Color } from "@/enums";

withDefaults(
  defineProps<{
    colors?: Color[];
    items: {
      count: number;
      legend?: string;
    }[];
    max: number;
    intensity?: StatsBarIntensity;
  }>(),
  {
    intensity: 5,
    colors: () => [Color.Lavender, Color.Lime],
  },
);

const slots = defineSlots<{
  default?: () => void;
}>();
</script>
