<template>
  <div class="flex flex-col">
    <template v-for="localization in localizations" :key="localization.locale">
      <div
        v-show="currentLocale === localization.locale"
        class="flex w-full flex-col gap-3"
      >
        <label class="text-lg font-semibold text-deepteal-500"
          >{{ formTexts.confirmationPage.title }}
        </label>
        <BrowserWindow>
          <template v-slot:banner>
            <div class="bg-gray-100">
              <img
                v-if="style?.bannerUrl"
                :src="style.bannerUrl"
                alt="Form banner"
              />
              <img
                v-else
                :src="hippoLogo"
                alt="Form banner"
                class="w-96 py-8"
              />
            </div>
          </template>
          <template v-slot>
            <div class="flex w-full max-w-2xl flex-col gap-4">
              <FormFieldWysiwyg
                :id="`localizations.${localization.locale}.confirmationPage`"
                :placeholder="
                  formTexts.confirmationPage.confirmationPlaceholder
                "
                :inlineCss="style?.sass"
              />
            </div>
          </template>
        </BrowserWindow>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  FormLocalizationDTO,
  IStyleDTOWithSassVariables,
} from "@/lib/formsServiceClient";
import { inject } from "vue";
import { FormTemplateEditContextKey } from "@/views/settings/forms/FormExtensions";
import BrowserWindow from "@/views/settings/forms/components/form-template-editor/BrowserWindow.vue";
import hippoLogo from "@/assets/images/logo-hippo.svg";
import texts from "@/utils/texts";
import FormFieldWysiwyg from "@/components/common/wysiwyg/FormFieldWysiwyg.vue";

const editContext = inject(FormTemplateEditContextKey);
if (!editContext) throw new Error("FormTemplateEditContextKey not found");

defineProps<{
  localizations: FormLocalizationDTO[];
  currentLocale: string;
  style?: IStyleDTOWithSassVariables;
}>();

const formTexts = texts.navigationItems.manage.settings.formTemplates;
</script>
