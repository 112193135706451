<template>
  <textarea
    :id="id"
    v-model="modelValueInternal"
    :class="inputClass"
    :disabled="disabled"
    :placeholder="placeholder"
  ></textarea>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  id?: string;
  modelValue: string;
  readonly?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  placeholder?: string;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: string];
}>();

const modelValueInternal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const inputClass = computed(() => [
  "block w-full sm:text-sm rounded-md",
  {
    "border-gray-300 text-deepteal-700 placeholder-gray-400 bg-transparent":
      props.readonly && !props.invalid,
    "border-alert-300 text-deepteal-700 placeholder-gray-400 bg-transparent":
      props.readonly && props.invalid,
    "border-gray-300 text-gray-400 placeholder-gray-200 bg-gray-100":
      props.disabled && !props.invalid,
    "border-alert-300 text-gray-400 placeholder-gray-200 bg-gray-100":
      props.disabled && props.invalid,
    "border-gray-300 text-black placeholder-gray-400 focus:ring-emerald-500 focus:border-emerald-500":
      !props.disabled && !props.invalid,
    "border-alert-300 text-alert-900 placeholder-alert-100 focus:outline-none focus:ring-alert-500 focus:border-alert-500":
      !props.disabled && props.invalid,
  },
]);
</script>
