import { TagSize } from "@/components/common/tag/Tag.types";
import { Color } from "@/enums";

export enum BadgeSize {
  normal = "normal",
  large = "large",
}
export enum BadgeType {
  default = "default",
  success = "success",
  warning = "warning",
  danger = "danger",
  info = "info",
  lavender = "lavender",
  fuchsia = "fuchsia",
  sunflower = "sunflower",
  gray = "gray",
  emerald = "emerald",
  coral = "coral",
}

export const badgeTypeToColor = {
  [BadgeType.default]: Color.Emerald,
  [BadgeType.success]: Color.Success,
  [BadgeType.warning]: Color.Warning,
  [BadgeType.danger]: Color.Alert,
  [BadgeType.info]: Color.Info,
  [BadgeType.lavender]: Color.Lavender,
  [BadgeType.fuchsia]: Color.Fuchsia,
  [BadgeType.sunflower]: Color.Sunflower,
  [BadgeType.gray]: Color.Gray,
  [BadgeType.emerald]: Color.Emerald,
  [BadgeType.coral]: Color.Coral,
};

export const badgeSizeToTagSize = {
  [BadgeSize.normal]: TagSize.xs,
  [BadgeSize.large]: TagSize.sm,
};

export interface BadgeProps {
  label: string;
  size?: BadgeSize;
  type?: BadgeType;
}
