<template>
  <div class="flex h-full flex-col gap-8">
    <div class="flex w-full flex-row items-start justify-between gap-2">
      <PageHeading>{{
        texts.navigationItems.organize.navigationTabs.sessions
      }}</PageHeading>
      <div v-show="canWrite" class="flex items-center gap-2">
        <CreateSessionButton
          @createSession="slideOverCreateSessionOpen = true"
          @importSessions="showImport = true"
        ></CreateSessionButton>
      </div>
    </div>

    <ErrorComponent v-if="error" />
    <Loader v-else-if="loadingSessionGroups" />
    <div v-else class="mb-8 grid gap-8 xl:grid-cols-12">
      <div class="hidden flex-col gap-8 xl:col-span-3 xl:flex">
        <SessionFilter
          :sessions="sessions"
          :studyPrograms="activityStudyPrograms"
          :locations="activityLocations"
          :types="settings.sessionTypes"
          :registrationLinks="registrationLinks"
          @filter="(value) => (filteredSessions = value)"
          @filter:studyPrograms="(value) => (studyProgramFilterIds = value)"
        />
      </div>
      <div class="xl:col-span-9">
        <div class="flex flex-col gap-4">
          <div class="mb-5 flex flex-col justify-end gap-4 xl:flex-row">
            <h3
              class="mr-auto flex-shrink-0 text-xl font-semibold text-deepteal-500 md:text-2xl"
            >
              {{ filteredSessions.length }}
              {{ texts.generic.results }}
            </h3>

            <div
              class="flex flex-col gap-3 xl:flex-row-reverse xl:items-center"
            >
              <ButtonSettings
                v-show="canWrite"
                icon="settings"
                @click="settingsSlideOverVisible = true"
              >
                {{ componentTexts.sessionProgramSettings }}
              </ButtonSettings>
              <DropdownWrapper :origin="DropdownOrigin.TopRight">
                <template v-slot:button>
                  <ButtonGroup
                    :color="Color.Gray"
                    :size="ButtonSize.sm"
                    flat
                    round
                    gap
                  >
                    <Button
                      :data-testid="testIds.action.download"
                      @click="downloadSessionCounts"
                    >
                      <IconAsync
                        :state="state"
                        icon="download"
                        :color="Color.Gray"
                        :size="IconSize.sm"
                      />
                      <span>
                        {{ componentTexts.exportSessionsButton }}
                      </span>
                    </Button>
                    <ButtonGroupMenuButton />
                  </ButtonGroup>
                </template>
                <template v-slot:items>
                  <DropdownItem
                    v-if="canViewPersonalData()"
                    icon="download"
                    :label="componentTexts.exportSessionRegistrantsButton"
                    @click="downloadSessionsClick"
                  />
                </template>
              </DropdownWrapper>
            </div>
          </div>

          <Loader v-if="loadingSessions" />
          <template v-else>
            <TextButton
              v-show="canWrite"
              :label="componentTexts.newSessionGroupButton"
              :color="Color.Black"
              trailingIcon="add"
              @click="slideOverCreateGroupOpen = true"
            />
            <span
              v-if="!sessions || sessions.length === 0"
              class="text-sm italic text-gray-500"
              >{{ componentTexts.noResultsFound }}</span
            >

            <ul v-else class="flex flex-col gap-4">
              <SessionItemSection
                v-for="(grouped, key) in groupedSessionsPerGroup"
                :key="key"
                :title="groups.find((g) => g.id === key)?.name"
                :sessions="grouped"
                :activity="activity"
                :groupHeaderEditLink="true"
                @download="downloadSessionClick"
                @update="updateSessionClick"
                @copy="copySessionClick"
                @delete="deleteSessionClick"
                @group:update="() => openUpdateSessionGroupSlideOver(key)"
                @group:delete="deleteGroup(key)"
              />
              <SessionItemSection
                v-for="(grouped, key) in groupedSessionsPerStudyProgram"
                :key="key"
                :title="
                  settings.studyPrograms.find((sp) => sp.id === key)
                    ?.displayName
                "
                :sessions="grouped"
                :activity="activity"
                @download="downloadSessionClick"
                @update="updateSessionClick"
                @copy="copySessionClick"
                @delete="deleteSessionClick"
              />
            </ul>
          </template>
        </div>
      </div>
    </div>
    <SlideOverSessionCreate
      v-model:visible="slideOverCreateSessionOpen"
      :activity="activity"
      :groups="groups"
      :locations="activityLocations"
      :types="settings.sessionTypes"
      :registrationLinks="registrationLinks"
      @create:succeeded="getSessions()"
    />
    <SlideOverSessionEdit
      v-model:visible="slideOverEditSessionOpen"
      :sessionId="updateSessionId"
      :activity="activity"
      :groups="groups"
      :locations="activityLocations"
      :types="settings.sessionTypes"
      :registrationLinks="registrationLinks"
      @edit:succeeded="getSessions()"
      @click:copy="copySessionClick"
      @click:delete="deleteSessionClick"
    />
    <SlideOverSessionSettings
      v-model:viewSessionProgramSettings="settingsSlideOverVisible"
      :activityId="activity.id"
    />
    <SlideOverSessionGroupCreate
      v-model:visible="slideOverCreateGroupOpen"
      :activityId="id"
      :loading="loadingSessionGroups"
      @create:succeeded="() => getSessionGroups()"
    />

    <SlideOverSessionGroupUpdate
      v-model:visible="slideOverUpdateGroupOpen"
      :activityId="id"
      :sessionGroup="currentSessionGroup"
      :loading="loadingSessionGroups"
      @update:succeeded="() => getSessionGroups()"
      @delete:succeeded="() => getSessionGroups()"
    />

    <SessionsImport
      v-model:visible="showImport"
      :activityId="id"
      @success="getSessions"
    />
    <ModalDelete
      v-if="deleteSessionId"
      v-model:visible="deleteModalVisible"
      :sessionId="deleteSessionId"
      :activityId="id"
      @delete:succeeded="deleteSucceeded"
    />
    <DeleteSessionGroupModal
      v-if="deleteSessionGroupId"
      :deletingId="deleteSessionGroupId"
      :activityId="activity.id"
      @update:deletingId="deleteGroupSucceeded"
    >
    </DeleteSessionGroupModal>
  </div>
</template>

<script setup lang="ts">
import {
  AsyncState,
  useAsyncState,
} from "@/components/common/async/Async.types";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import ButtonGroup from "@/components/common/button/ButtonGroup.vue";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import Loader from "@/components/common/loader/Loader.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import dictionary, { interpolate } from "@/dictionary";
import { Color, Culture } from "@/enums";
import logger from "@/plugins/logger";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import settings from "@/store/context/settings.context";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import { groupBy } from "@/utils/array";
import { downloadFileBySimulatingClickOnTemporaryAnchorTag } from "@/utils/downloadFile";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import ModalDelete from "@/views/organize/activities/activity/sessions/components/ModalDelete.vue";
import SessionsImport from "@/views/organize/activities/activity/sessions/components/import/SessionsImport.vue";
import SessionFilter from "@/views/organize/activities/activity/sessions/components/session-filter/SessionFilter.vue";
import {
  convertToDto,
  convertToFormValues,
} from "@/views/organize/activities/activity/sessions/components/session-form/SessionForm.types";
import SlideOverSessionCreate from "@/views/organize/activities/activity/sessions/components/session-form/SlideOverSessionCreate.vue";
import SlideOverSessionEdit from "@/views/organize/activities/activity/sessions/components/session-form/SlideOverSessionEdit.vue";
import SlideOverSessionGroupCreate from "@/views/organize/activities/activity/sessions/components/session-groups/SlideOverSessionGroupCreate.vue";
import SlideOverSessionGroupUpdate from "@/views/organize/activities/activity/sessions/components/session-groups/SlideOverSessionGroupUpdate.vue";
import SessionItemSection from "@/views/organize/activities/activity/sessions/components/session-item/SessionItemSection.vue";
import SlideOverSessionSettings from "@/views/organize/activities/activity/sessions/components/session-settings/SlideOverSessionSettings.vue";
import { computed, ref, watch } from "vue";
import {
  ExportSessionFilter,
  LocalizedSessionCountDto,
  LocalizedSessionGroupDto,
  SessionGroupDto,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import DeleteSessionGroupModal from "@/views/organize/activities/activity/sessions/components/session-groups/DeleteSessionGroupModal.vue";
import CreateSessionButton from "@/views/organize/activities/activity/sessions/components/CreateSessionButton.vue";
import TextButton from "@/components/common/button/TextButton.vue";
import {
  canViewPersonalData,
  hasPermissions,
} from "@/router/helpers/userCanGoTo";

const props = defineProps<ActivityRouteProps>();

const componentTexts =
  texts.navigationItems.organize.activity.sessions.overview;

const loadingSessions = ref<boolean>(false);
const loadingSessionGroups = ref<boolean>(false);
const error = ref<boolean>(false);

const canWrite = hasPermissions([
  Permission.ActivitiesWrite,
  Permission.SessionsWrite,
]);

// Sessions per study program
const sessions = ref<LocalizedSessionCountDto[]>([]);
const filteredSessions = ref<LocalizedSessionCountDto[]>([]);
const studyProgramFilterIds = ref<string[] | undefined>();
const groupedSessionsPerStudyProgram = computed(() =>
  groupBy(
    filteredSessions.value.filter(
      (session) => session.studyPrograms && session.studyPrograms.length > 0,
    ),
    (item) =>
      item.studyPrograms
        ?.filter(
          (studyProgram) =>
            !studyProgramFilterIds.value ||
            studyProgramFilterIds.value?.includes(studyProgram.id),
        )
        .map((studyProgram) => studyProgram.id), // Filter out StudyProgram groupings that are not included in the filter
  ),
);
const groupedSessionsPerGroup = computed(() => {
  const sessionsWithGroup = groupBy(
    filteredSessions.value.filter((session) => session.groupId),
    (item) => item.groupId,
  );

  const allGroups: Record<string, LocalizedSessionCountDto[]> = {};
  groups.value.forEach((group) => {
    allGroups[group.id] = sessionsWithGroup[group.id] || [];
  });

  return allGroups;
});

const getSessions = () =>
  eduConfigurationServiceClient
    .getSessionsLocalized(props.id)
    .then((response) => (sessions.value = response));

const getSessionGroups = () =>
  eduConfigurationServiceClient
    .getSessionGroups(props.id)
    .then((response) => (groups.value = response));

loadingSessions.value = true;
Promise.all([getSessions(), getSessionGroups()]).then(() => {
  loadingSessions.value = false;
});

// Metadata for filtering
const activityStudyPrograms = computed(() =>
  settings.studyPrograms.filter((studyProgram) =>
    props.activity.studyProgramIds.includes(studyProgram.id),
  ),
);
const activityLocations = computed(() =>
  settings.activityLocations.filter((location) =>
    props.activity.locationIds.includes(location.id),
  ),
);
const groups = ref<LocalizedSessionGroupDto[]>([]);

// Settings Slide Over
const settingsSlideOverVisible = ref(false);

// Create Session Slide Over
const slideOverCreateSessionOpen = ref<boolean>(false);

// Edit Session Slide Over
const slideOverEditSessionOpen = ref<boolean>(false);
const updateSessionId = ref<string>();

const updateSessionClick = (sessionId: string) => {
  updateSessionId.value = sessionId;
  slideOverEditSessionOpen.value = true;
};

// Create Group Slide Over
const slideOverCreateGroupOpen = ref<boolean>(false);

// Update Group Slide Over
const slideOverUpdateGroupOpen = ref<boolean>(false);
const currentSessionGroup = ref<SessionGroupDto | undefined>(undefined);

const openUpdateSessionGroupSlideOver = async (sessionGroupId: string) => {
  loadingSessionGroups.value = true;
  slideOverUpdateGroupOpen.value = true;

  eduConfigurationServiceClient
    .getSessionGroupById(props.id, sessionGroupId)
    .then((response) => {
      currentSessionGroup.value = response;
    })
    .catch((e) => {
      slideOverUpdateGroupOpen.value = false;
      Notify.failure(
        texts.navigationItems.organize.sessionGroups.delete.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      loadingSessionGroups.value = false;
    });
};

// Download Session
const downloadSessionClick = async (sessionId: string) => {
  try {
    await exportSessionRegistrations([sessionId]);

    Notify.success(texts.notifications.download.success, [
      texts.models.session.title,
    ]);
  } catch (e) {
    Notify.failure(texts.notifications.download.failure, [
      texts.models.session.title,
    ]);
    logger.error(e as Error);
  }
};

// Download (filtered) Sessions
const downloadSessionsClick = async () => {
  try {
    state.value = AsyncState.Loading;

    await exportSessionRegistrations(
      filteredSessions.value.map((session) => session.id),
    );

    Notify.success(texts.notifications.download.success, [
      texts.models.session.title,
    ]);
    state.value = AsyncState.Success;
  } catch (e) {
    state.value = AsyncState.Failure;
    Notify.failure(texts.notifications.download.failure, [
      texts.models.session.title,
    ]);
    logger.error(e as Error);
  }
};

const exportSessionRegistrations = async (sessionIds: string[]) => {
  const response =
    await eduConfigurationServiceClient.exportRegisteredProspectsForSessions(
      props.id,
      new ExportSessionFilter({ sessionIds: sessionIds }),
    );

  downloadFileBySimulatingClickOnTemporaryAnchorTag(
    response.data,
    response.fileName!,
  );
};

// Copy Session
const copySessionClick = async (sessionId: string) => {
  try {
    const session = await eduConfigurationServiceClient.getSessionById(
      props.id,
      sessionId,
    );

    const copyFormValues = convertToFormValues(session);

    Object.entries(copyFormValues.localizations).forEach(
      ([locale, localization]) => {
        localization.name = interpolate(
          dictionary[locale as Culture].generic.copyOf,
          localization.name,
        );
      },
    );

    const copyDto = convertToDto(copyFormValues);
    await eduConfigurationServiceClient.createSession(props.id, copyDto);

    Notify.success(texts.notifications.copy.success, [
      texts.models.session.title,
    ]);
    await getSessions();
  } catch (e) {
    Notify.failure(texts.notifications.copy.failure, [
      texts.models.session.title,
    ]);

    logger.error(e as Error);
  }
};

const deleteSessionGroupId = ref<string>();
// Delete group
const deleteGroup = (groupId: string) => {
  deleteSessionGroupId.value = groupId;
};

const deleteGroupSucceeded = () => {
  deleteSessionGroupId.value = undefined;
  getSessionGroups();
};

// Delete Session
const deleteModalVisible = ref(false);
const deleteSessionId = ref<string>();

const deleteSessionClick = (sessionId: string) => {
  deleteSessionId.value = sessionId;
  deleteModalVisible.value = true;
};

watch(deleteModalVisible, (val) => {
  if (!val) deleteSessionId.value = undefined;
});

const deleteSucceeded = () => {
  deleteModalVisible.value = false;
  deleteSessionId.value = undefined;
  getSessions();
};

// Import sessions
const showImport = ref(false);

// Download session export
const exportSessionCounts = async () => {
  const response = await eduConfigurationServiceClient.getSessionsCountsExport(
    props.id,
  );

  downloadFileBySimulatingClickOnTemporaryAnchorTag(
    response.data,
    response.fileName ?? "export.xlsx",
  );
};

const { state, handler: downloadSessionCounts } = useAsyncState(
  exportSessionCounts,
  texts.navigationItems.organize.activity.sessions.exportCounts,
);

watch(state, (value) => {
  if (value === AsyncState.Success) {
    setTimeout(() => (state.value = AsyncState.Content), 1000);
  }
  if (value === AsyncState.Failure) {
    setTimeout(() => (state.value = AsyncState.Content), 3000);
  }
});
</script>
