<template>
  <div class="flex flex-col gap-5">
    <Search v-model="search" :placeholder="texts.actions.search" />
    <div class="flex flex-col gap-4 rounded bg-white p-4 shadow-sm">
      <div class="flex items-center justify-between gap-1.5">
        <h2 class="text-base font-semibold">
          {{ texts.navigationItems.organize.trialDays.overview.filter.title }}
        </h2>
        <span
          :class="getTextColor(color) + ' ' + getTextHoverColor(color)"
          class="cursor-pointer text-xs underline"
          @click="resetFilters"
        >
          {{ texts.actions.reset }}
        </span>
      </div>
      <StudyProgramFilter
        v-if="studyPrograms"
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        :counts="studyProgramCounts"
        @filter="filteredStudyProgramIds = $event"
      />
      <Divider />
      <StudyProgramMetadataFilter
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        @filter="filteredStudyProgramIds = $event"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Divider from "@/components/common/divider/Divider.vue";
import { compare } from "@/components/common/filter/FilterItem.types";
import Search from "@/components/common/search/Search.vue";
import StudyProgramFilter from "@/components/study-programs/StudyProgramFilter.vue";
import StudyProgramMetadataFilter from "@/components/study-programs/StudyProgramMetadataFilter.vue";
import { Color, SessionStorageKey } from "@/enums";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import { countBy } from "@/utils/array";
import { getTextColor, getTextHoverColor } from "@/utils/color";
import texts from "@/utils/texts";
import { computed, ref, watch } from "vue";
import { TrialDayOverviewItem } from "../Overview.types";

const props = defineProps<{
  items: TrialDayOverviewItem[];
  studyPrograms: LocalizedStudyProgramDTO[];
  color: Color;
}>();

const search = ref<string | undefined>(
  sessionStorage.getItem(SessionStorageKey.TrialDaySearchTerm) || undefined,
);

watch(search, (newValue) =>
  newValue
    ? sessionStorage.setItem(SessionStorageKey.TrialDaySearchTerm, newValue)
    : sessionStorage.removeItem(SessionStorageKey.TrialDaySearchTerm),
);

const emit = defineEmits<{
  (e: "filter", value: TrialDayOverviewItem[]): void;
}>();

const filteredStudyProgramIds = ref<string[] | undefined>();
const filteredStudyProgramMetaData = ref<string[] | undefined>();

const studyProgramCounts = computed(() =>
  countBy(props.items, (item) => item.studyProgramId),
);

const resetStudyProgramFilter = ref(false);
const resetFilters = () => {
  resetStudyProgramFilter.value = !resetStudyProgramFilter.value;
};

const filteredItems = computed<TrialDayOverviewItem[]>(() => {
  return props.items
    .filter(
      (item) =>
        !search.value ||
        item.studyProgramDisplayName
          ?.toLowerCase()
          .includes(search.value.toLowerCase()),
    )
    .filter((item) =>
      compare(filteredStudyProgramIds.value, item.studyProgramId),
    )
    .filter((item) =>
      compare(filteredStudyProgramMetaData.value, item.studyProgramId),
    );
});

watch(filteredItems, (value) => emit("filter", value), { immediate: true });
</script>
