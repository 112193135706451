<template>
  <div :id="containerId" class="h-96 rounded-lg shadow print:hidden"></div>
  <img
    v-if="snapshot"
    class="hidden rounded-lg shadow print:block"
    :src="snapshot"
    alt="printable snapshot the prospects map"
  />
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";
import { Coordinates } from "@/lib/eduConfigurationServiceClient";
import { createMap, printableSnapshot, setMapData } from "./ProspectMap.types";

const props = defineProps<{
  coordinates: Coordinates[];
}>();

const containerId = "prospectsMapContainer";
const snapshot = printableSnapshot;
onMounted(() => {
  createMap(containerId, props.coordinates);
});
watch(
  () => props.coordinates,
  (values) => {
    setMapData(values);
  },
);
</script>
