<template>
  <FormLayout @submit.prevent="submitForm">
    <InputField
      formFieldId="nameNL"
      :label="texts.models.sessionType.localizations.nameNL"
      data-testid="nameNL"
      :fullWidth="true"
    />
    <InputField
      formFieldId="nameEN"
      :label="texts.models.sessionType.localizations.nameEN"
      data-testid="nameEN"
      :fullWidth="true"
    />
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import InputField from "@/components/common/text-input/InputField.vue";

import { useForm } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { SessionTypeFormValues } from "./SessionTypeForm.types";
import FormLayout from "@/components/common/form/FormLayout.vue";

const props = defineProps<{ formValues?: SessionTypeFormValues }>();

const emit = defineEmits<{
  (e: "submit", values: SessionTypeFormValues): void;
}>();

// Store and router setup
const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const form = useForm({
  validationSchema: yup.object({
    nameNL: yup.string().required(),
    nameEN: yup.string(),
  }),
});

if (props.formValues) {
  form.setValues({
    nameNL: props.formValues.nameNL,
    nameEN: props.formValues.nameEN,
  });
}

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>
