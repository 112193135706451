import { Component } from "vue";
import { RouterLinkProps } from "vue-router";

export enum IconAlignment {
  Left = "left",
  Right = "right",
}

export interface RouterLinkWithIconProps extends RouterLinkProps {
  text: string;
  icon: Component;
  iconAlignment: IconAlignment;
}
