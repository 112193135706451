<template>
  <FormLayout @submit="submitForm">
    <div class="flex flex-col gap-2">
      <CheckboxField
        :formFieldId="forbidOverlappingSessionRegistrationsId"
        :title="
          texts.models.sessionProgram.forbidOverlappingSessionRegistrations
            .title
        "
        :description="
          texts.models.sessionProgram.forbidOverlappingSessionRegistrations
            .description
        "
      />
      <div class="max-w-lg pl-6">
        <Alert
          :content="
            texts.models.sessionProgram.forbidOverlappingSessionRegistrations
              .exactlyOverlappingDateTimesExplanation
          "
        />
      </div>
    </div>
    <CheckboxField
      :formFieldId="forbidMultipleSessionRegistrationsForStudyProgramId"
      :title="
        texts.models.sessionProgram
          .forbidMultipleSessionRegistrationsPerStudyProgram.title
      "
      :description="
        texts.models.sessionProgram
          .forbidMultipleSessionRegistrationsPerStudyProgram.description
      "
    />

    <div class="ml-7 flex flex-col items-start gap-1">
      <FormFieldTextInput
        :id="minimumSessionRegistrationsPerRegistrantId"
        :data-testid="minimumSessionRegistrationsPerRegistrantId"
        :label="
          texts.models.sessionProgram.minimumSessionRegistrationsPerRegistrant
        "
        :displayMode="FormFieldDisplayMode.Column"
        :type="TextInputType.NUMBER"
        :min="1"
      />
      <FormFieldTextInput
        :id="maximumSessionRegistrationsPerRegistrantId"
        :data-testid="maximumSessionRegistrationsPerRegistrantId"
        :label="
          texts.models.sessionProgram.maximumSessionRegistrationsPerRegistrant
        "
        :displayMode="FormFieldDisplayMode.Column"
        :type="TextInputType.NUMBER"
        :min="1"
      />
    </div>
    <CheckboxField
      v-model="form.values.allowGroupSize"
      :formFieldId="allowGroupSizeId"
      :title="texts.models.sessionProgram.allowGroupSize.title"
      :description="texts.models.sessionProgram.allowGroupSize.description"
    />
    <div
      v-if="form.values.allowGroupSize"
      class="ml-7 flex flex-col items-start gap-1"
    >
      <label
        :for="maximumGroupSizePerRegistrantId"
        class="block text-sm font-medium text-gray-700"
      >
        {{ texts.models.sessionProgram.maximumGroupSizePerRegistrant.title }}
      </label>
      <InputField
        class="max-w-sm"
        :formFieldId="maximumGroupSizePerRegistrantId"
        :type="TextInputType.NUMBER"
        :min="1"
        :max="25"
      />
    </div>

    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import Alert from "@/components/common/alert/Alert.vue";
import CheckboxField from "@/components/common/checkbox/CheckboxField.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormLayout from "@/components/common/form/FormLayout.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import InputField from "@/components/common/text-input/InputField.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { CultureStore } from "@/store/modules/culture.store";
import { SessionProgramSettingsFormValues } from "@/views/organize/activities/activity/sessions/components/session-settings/SlideOverSessionSettingsForm.types";
import { useForm } from "vee-validate";
import { useStore } from "vuex";
import * as yup from "yup";

const store = useStore<CultureStore>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const props = defineProps<{
  formValues: SessionProgramSettingsFormValues;
}>();

const emit = defineEmits<{
  (e: "submit", values: SessionProgramSettingsFormValues): void;
}>();

const forbidOverlappingSessionRegistrationsId: keyof SessionProgramSettingsFormValues =
  "forbidOverlappingSessionRegistrations";
const forbidMultipleSessionRegistrationsForStudyProgramId: keyof SessionProgramSettingsFormValues =
  "forbidMultipleSessionRegistrationsForStudyProgram";
const minimumSessionRegistrationsPerRegistrantId: keyof SessionProgramSettingsFormValues =
  "minimumSessionRegistrationsPerRegistrant";
const maximumSessionRegistrationsPerRegistrantId: keyof SessionProgramSettingsFormValues =
  "maximumSessionRegistrationsPerRegistrant";
const allowGroupSizeId: keyof SessionProgramSettingsFormValues =
  "allowGroupSize";
const maximumGroupSizePerRegistrantId: keyof SessionProgramSettingsFormValues =
  "maximumGroupSizePerRegistrant";

const form = useForm<SessionProgramSettingsFormValues>({
  validationSchema: yup.object().shape(
    {
      forbidMultipleSessionRegistrationsForStudyProgram: yup
        .boolean()
        .required(),
      forbidOverlappingSessionRegistrations: yup.boolean().required(),
      minimumSessionRegistrationsPerRegistrant: yup
        .number()
        .integer()
        .positive()
        .nullable()
        .when("maximumSessionRegistrationsPerRegistrant", {
          is: (maximum: number | undefined) => maximum !== undefined,
          then: (schema) =>
            schema.max(yup.ref("maximumSessionRegistrationsPerRegistrant")),
          otherwise: (schema) => schema,
        }),
      maximumSessionRegistrationsPerRegistrant: yup
        .number()
        .integer()
        .positive()
        .nullable()
        .when("minimumSessionRegistrationsPerRegistrant", {
          is: (minimum: number | undefined) => minimum !== undefined,
          then: (schema) =>
            schema.min(yup.ref("minimumSessionRegistrationsPerRegistrant")),
          otherwise: (schema) => schema,
        }),
      allowGroupSize: yup.boolean().required(),
      maximumGroupSizePerRegistrant: yup
        .number()
        .min(
          1,
          texts.models.sessionProgram.maximumGroupSizePerRegistrant.validation
            .mustBeGreaterThanZero,
        )
        .max(
          25,
          texts.models.sessionProgram.maximumGroupSizePerRegistrant.validation
            .mustBeMaximum,
        )
        .integer(
          texts.models.sessionProgram.maximumGroupSizePerRegistrant.validation
            .mustBeAWholeNumber,
        )
        .when("allowGroupSize", {
          is: true,
          then: (schema) =>
            schema.required(
              texts.models.sessionProgram.maximumGroupSizePerRegistrant
                .validation.required,
            ),
          otherwise: (schema) => schema.nullable(),
        }),
    },
    [
      [
        "minimumSessionRegistrationsPerRegistrant",
        "maximumSessionRegistrationsPerRegistrant",
      ],
    ], //This exclusion counters cyclic yup dependencies
  ),
  initialValues: {
    forbidMultipleSessionRegistrationsForStudyProgram:
      props.formValues?.forbidMultipleSessionRegistrationsForStudyProgram,
    forbidOverlappingSessionRegistrations:
      props.formValues?.forbidOverlappingSessionRegistrations,
    minimumSessionRegistrationsPerRegistrant:
      props.formValues?.minimumSessionRegistrationsPerRegistrant,
    maximumSessionRegistrationsPerRegistrant:
      props.formValues?.maximumSessionRegistrationsPerRegistrant,
    allowGroupSize: props.formValues?.allowGroupSize ?? false,
    maximumGroupSizePerRegistrant:
      props.formValues?.maximumGroupSizePerRegistrant,
  },
});

const submitForm = form.handleSubmit(() => {
  emit("submit", form.values);
});
</script>
