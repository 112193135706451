<template>
  <div class="flex flex-col gap-4">
    <LayoutTwoCol class="items-center">
      <template v-slot:left>
        <Search v-model="search" :placeholder="texts.actions.search" />
      </template>

      <div class="flex items-center justify-between">
        <span class="font-semibold text-deepteal-700"
          >{{ searchedStudyPrograms.length }}
          {{ texts.models.studyProgram.plural }}</span
        >
        <ButtonAdd @click="slideOverCreateOpen = true"></ButtonAdd>
      </div>
    </LayoutTwoCol>
    <div>
      <LayoutTwoCol>
        <template v-slot:left>
          <div class="h-11"></div>
          <StudyProgramExtendedFilter
            v-model:filteredStudyPrograms="filteredStudyProgramsIds"
            :studyPrograms="props.studyPrograms"
          />
        </template>
        <List>
          <template v-slot:header>
            <ListItemColumn :flex="0"
              ><div class="h-6 w-6"></div
            ></ListItemColumn>
            <ListItemColumn :flex="2">
              <span class="truncate">{{
                texts.models.studyProgram.title
              }}</span></ListItemColumn
            >
            <ListItemColumn :flex="1" hide
              ><span class="truncate"
                >{{ texts.models.studyProgramDepartment.title }}
              </span></ListItemColumn
            >
            <ListItemColumn :flex="1" hide
              ><span class="truncate"
                >{{ texts.models.studyProgramLocation.title }}
              </span></ListItemColumn
            >
            <ListItemColumn :flex="0"></ListItemColumn>
          </template>

          <ListItem
            v-for="(studyProgram, index) in searchedStudyPrograms"
            :key="index"
            clickable
            :title="studyProgram.displayName"
            @click="editStudyProgram(studyProgram.id)"
          >
            <ListItemColumn :flex="0">
              <Icon icon="school"></Icon>
            </ListItemColumn>
            <ListItemColumn :flex="2" accent>
              <span class="truncate">{{
                studyProgram.displayName
              }}</span></ListItemColumn
            >
            <ListItemColumn :flex="1" hide>
              <span class="truncate">{{
                studyProgram.studyProgramDepartment?.name
              }}</span></ListItemColumn
            >
            <ListItemColumn :flex="1" hide
              ><span class="truncate">{{
                studyProgram.studyProgramLocations
                  .map((sp) => sp.name)
                  .join(", ")
              }}</span></ListItemColumn
            >
            <ListItemActions @click.stop>
              <DropdownItem
                data-testid="delete"
                @click="toggleDeleteModal(studyProgram.id)"
                ><Icon icon="delete" :color="Color.Alert" />
                <span class="text-alert-500">{{ texts.actions.delete }}</span>
              </DropdownItem>
              <DropdownItem
                data-testid="copy"
                :label="texts.actions.copy"
                icon="content_copy"
                @click="onCopyStudyProgram(studyProgram.id)"
              ></DropdownItem>
              <DropdownItem
                v-if="!studyProgram.isArchived"
                data-testid="archive"
                :label="texts.actions.archive"
                icon="archive"
                @click="onArchiveStudyProgram(studyProgram.id)"
              ></DropdownItem>
              <DropdownItem
                v-if="studyProgram.isArchived"
                data-testid="unarchive"
                :label="texts.actions.unarchive"
                icon="unarchive"
                @click="onUnArchiveStudyProgram(studyProgram.id)"
              ></DropdownItem>
            </ListItemActions>
          </ListItem>
        </List>
      </LayoutTwoCol>
    </div>
  </div>
  <SlideOver
    :title="texts.models.studyProgram.newStudyProgram"
    :visible="slideOverCreateOpen"
    @update:visible="slideOverCreateOpen = $event"
  >
    <StudyProgramCreate @submit="slideOverCreateOpen = false">
      <ButtonSubmit></ButtonSubmit>
      <ButtonCancel @click="slideOverCreateOpen = false"></ButtonCancel>
    </StudyProgramCreate>
  </SlideOver>
  <SlideOver
    :title="texts.models.studyProgram.editStudyProgram"
    :visible="slideOverEditOpen"
    @update:visible="slideOverEditOpen = $event"
  >
    <StudyProgramEdit
      v-if="slideOverEditId"
      :id="slideOverEditId"
      @cancel="stopEditStudyProgram"
      @submit="stopEditStudyProgram"
    >
      <ButtonSubmit></ButtonSubmit>
      <ButtonCancel @click="slideOverEditId = undefined"></ButtonCancel>
    </StudyProgramEdit>
  </SlideOver>
  <Modal
    v-if="studyProgramToDeleteId"
    visible
    :type="ModalType.danger"
    :description="texts.navigationItems.studyProgram.modal.delete.description"
    :title="texts.navigationItems.studyProgram.modal.delete.title"
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          data-testid="confirm-delete"
          class="sm:ml-3"
          :color="Color.Alert"
          :label="texts.actions.delete"
          :loading="actionLoading"
          :disabled="!studyProgramToDeleteId"
          @click="deleteStudyProgram()"
        />
        <Button
          class="mt-3 sm:mt-0"
          :color="Color.White"
          :label="texts.actions.cancel"
          :disabled="actionLoading || !studyProgramToDeleteId"
          @click="toggleDeleteModal"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import Search from "@/components/common/search/Search.vue";
import dictionary from "@/dictionary";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Color, Culture, SessionStorageKey } from "@/enums";
import List from "@/components/common/list/List.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import {
  LocalizedStudyProgramDTO,
  ValidationErrorResponse,
} from "@/lib/eduConfigurationServiceClient";
import Icon from "@/components/common/icon/Icon.vue";
import LayoutTwoCol from "@/components/common/layout/LayoutTwoCol.vue";
import StudyProgramExtendedFilter from "@/views/settings/study-programs/StudyProgramExtendedFilter.vue";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import StudyProgramCreate from "@/views/settings/study-programs/StudyProgramCreate.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import StudyProgramEdit from "@/views/settings/study-programs/StudyProgramEdit.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import logger from "@/plugins/logger";
import { loadStudyPrograms } from "@/store/context/settings.context";
import { copyStudyProgram } from "@/services/study-program.service";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Modal from "@/components/common/modal/Modal.vue";
import Button from "@/components/common/button/Button.vue";

const props = withDefaults(
  defineProps<{
    studyPrograms: LocalizedStudyProgramDTO[];
    showLinkToDetails?: boolean;
  }>(),
  {
    showLinkToDetails: false,
  },
);

// Store setup
const store = useStore<RootState>();

const filteredStudyProgramsIds = ref<string[]>([]);

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const search = ref<string | undefined>(
  sessionStorage.getItem(SessionStorageKey.StudyProgramsSearchTerm) ||
    undefined,
);

watch(search, (value) => {
  if (!value) {
    sessionStorage.removeItem(SessionStorageKey.StudyProgramsSearchTerm);
    return;
  }
  sessionStorage.setItem(SessionStorageKey.StudyProgramsSearchTerm, value);
});

const searchedStudyPrograms = computed<LocalizedStudyProgramDTO[]>(() =>
  props.studyPrograms
    .filter((studyProgram: LocalizedStudyProgramDTO) =>
      search.value
        ? studyProgram.displayName
            .toLowerCase()
            .includes(search.value?.toLowerCase()) ||
          studyProgram.studyProgramDepartment?.name
            .toLowerCase()
            .includes(search.value.toLowerCase()) ||
          studyProgram.studyProgramLocations
            .map((location) => location.name.toLowerCase())
            .some((location) =>
              search.value
                ? location.includes(search.value.toLowerCase())
                : true,
            )
        : true,
    )
    .filter((studyProgram) => {
      if (filteredStudyProgramsIds.value) {
        return filteredStudyProgramsIds.value.includes(studyProgram.id);
      }
      return true;
    }),
);

const slideOverCreateOpen = ref<boolean>(false);
const slideOverEditOpen = ref<boolean>(false);
const slideOverEditId = ref<string | undefined>(undefined);

const editStudyProgram = (id: string) => {
  slideOverEditId.value = id;
  slideOverEditOpen.value = true;
};

const stopEditStudyProgram = () => {
  slideOverEditId.value = undefined;
  slideOverEditOpen.value = false;
};

const actionLoading = ref<boolean>(false);

const studyProgramToDeleteId = ref<string | undefined>(undefined);

const toggleDeleteModal = (id: string) => {
  if (studyProgramToDeleteId.value) {
    studyProgramToDeleteId.value = undefined;
    return;
  }
  studyProgramToDeleteId.value = id;
};

const onArchiveStudyProgram = async (id: string) => {
  actionLoading.value = true;

  return eduConfigurationServiceClient
    .archiveStudyProgram(id)
    .then(() => loadStudyPrograms())
    .then(() => {
      Notify.success(texts.navigationItems.studyProgram.edit.success);
    })
    .catch((e) => {
      logger.error(e);
      if (e instanceof ValidationErrorResponse) {
        Notify.failure(texts.validationErrors[e.errorCode]);
      } else {
        Notify.failure(texts.navigationItems.studyProgram.edit.failure);
      }
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const onUnArchiveStudyProgram = async (id: string) => {
  actionLoading.value = true;

  return eduConfigurationServiceClient
    .unarchiveStudyProgram(id)
    .then(() => loadStudyPrograms())
    .then(() => {
      Notify.success(texts.navigationItems.studyProgram.edit.success);
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.studyProgram.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const onCopyStudyProgram = async (id: string) => {
  if (id === undefined) {
    Notify.failure(texts.navigationItems.studyProgram.copy.failure);
    return;
  }

  try {
    actionLoading.value = true;

    await copyStudyProgram(id);
    await loadStudyPrograms();

    Notify.success(texts.navigationItems.studyProgram.copy.success);
  } catch (e) {
    Notify.failure(texts.navigationItems.studyProgram.copy.failure);
    logger.error(e);
  } finally {
    actionLoading.value = false;
  }
};

const deleteStudyProgram = () => {
  actionLoading.value = true;

  if (studyProgramToDeleteId.value === undefined) {
    Notify.failure(texts.navigationItems.studyProgram.delete.failure);
    return;
  }

  return eduConfigurationServiceClient
    .deleteStudyProgram(studyProgramToDeleteId.value)
    .then(() => loadStudyPrograms())
    .then(() => {
      Notify.success(texts.navigationItems.studyProgram.delete.success);
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.studyProgram.delete.failure);
      logger.error(e);
    })
    .finally(() => {
      toggleDeleteModal("");
      actionLoading.value = false;
    });
};
</script>
