import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import selection, {
  loadSelectionContext,
} from "@/store/context/selection.context";

export async function beforeEnterDashboard(
  _: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (!selection.definition) {
    await loadSelectionContext();
  }

  next();
}
