<template>
  <TransitionFade>
    <Card
      v-if="prospectCountsPerMonth && prospectCountsPerMonth.length > 0"
      :size="CardSize.sm"
      class="relative"
    >
      <Bar :data="chartData" :options="chartOptions" class="h-full w-full" />
    </Card>
  </TransitionFade>
</template>

<script setup lang="ts">
import Card from "@/components/common/card/Card.vue";
import { Color, Months } from "@/enums";
import { ProspectCountPerMonthDto } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import { computed, ref, watch } from "vue";
import { Bar } from "vue-chartjs";
import { DateTime } from "luxon";
import { getCssColor } from "@/utils/color";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { CardSize } from "@/components/common/card/Card.types";

const props = defineProps<{
  color: Color;
  fromDate: DateTime;
  toDate: DateTime;
  prospectCountsPerMonth?: ProspectCountPerMonthDto[];
}>();

const chartData = ref<ChartData<"bar", number[], unknown>>({ datasets: [] });

const dataSetOptions = computed(() => ({
  backgroundColor: getCssColor(props.color),
  borderRadius: 20,
  maxBarThickness: 15,
  responsive: true,
  maintainAspectRatio: false,
}));

watch(
  () => props.prospectCountsPerMonth,
  (prospectCountsPerMonth) => {
    processStats(prospectCountsPerMonth ?? []);
  },
  { immediate: true },
);

watch(dataSetOptions, () => {
  processStats(props.prospectCountsPerMonth ?? []);
});

function processStats(input: ProspectCountPerMonthDto[]) {
  const labels = getShortMonthNames(input);
  const datasets = [
    {
      label: texts.navigationItems.brochures.stats.prospectsPerMonth,
      data: input.map((item) => item.prospectCount),
      ...dataSetOptions.value,
    },
  ];
  chartData.value = {
    labels,
    datasets,
  };
}

const chartOptions: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      align: "start",
      labels: {
        boxWidth: 0,
        font: {
          size: 16,
          weight: 600,
          family: "'Messina Sans', 'sans-serif'",
        },
      },
    },
  },
};

function getShortMonthNames(input: ProspectCountPerMonthDto[]) {
  return input.map(
    (item) => texts.enums.monthsShort[Object.values(Months)[item.month - 1]],
  );
}

Chart.register(...registerables);
</script>
