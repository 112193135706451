<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.models.brochure.title"
  >
    <Loader v-if="loading" data-testid="loading-brochure"></Loader>
    <BrochureForm
      v-if="!loading && brochure"
      :formValues="formValues"
      copyToClipboard
      @submit="submitUpdate"
    >
      <template v-if="deletingBrochure">
        <ConfirmDeleteDialogButtons
          :loading="actionLoading"
          class="md:col-span-3"
          @confirm="submitDelete"
          @cancel="() => (deletingBrochure = false)"
        ></ConfirmDeleteDialogButtons>
      </template>
      <template v-else>
        <ButtonSubmit :loading="actionLoading" />
        <ButtonCancel @click="() => (visibleInternal = false)" />
        <ButtonDelete
          class="md:col-start-2"
          @click="() => (deletingBrochure = true)"
        />
      </template>
    </BrochureForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import Loader from "@/components/common/loader/Loader.vue";

import SlideOver from "@/components/common/slideover/SlideOver.vue";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import {
  BrochureDto,
  UpdateBrochureDto,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import logger from "@/plugins/logger";
import {
  BrochureFormValues,
  convertToFormValues,
  convertToUpdateDto,
} from "@/views/information-requests/brochures/components/BrochureForm.types";
import BrochureForm from "@/views/information-requests/brochures/components/BrochureForm.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ConfirmDeleteDialogButtons from "@/components/common/button/ConfirmDeleteDialogButtons.vue";

const props = defineProps<{
  visible: boolean;
  brochure?: BrochureDto;
  loading?: boolean;
}>();

const emit = defineEmits([
  "update:visible",
  "update:succeeded",
  "delete:succeeded",
]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const formValues = computed(() =>
  props.brochure ? convertToFormValues(props.brochure) : undefined,
);

// Updating Brochure
const actionLoading = ref<boolean>(false);
const submitUpdate = (values: BrochureFormValues) => {
  if (!props.brochure) {
    throw new Error("Update brochure values lacks id");
  }

  actionLoading.value = true;
  eduConfigurationServiceClient
    .updateBrochure(
      props.brochure.id,
      new UpdateBrochureDto(convertToUpdateDto(values)),
    )
    .then(() => {
      Notify.success(texts.notifications.edit.successCached);
      visibleInternal.value = false;
      emit("update:succeeded");
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.brochures.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

// Deleting Brochure
const deletingBrochure = ref<boolean>(false);

const submitDelete = () => {
  if (!props.brochure) {
    throw new Error(`Cannot perform action. No brochure provided.`);
  }

  actionLoading.value = true;
  eduConfigurationServiceClient
    .deleteBrochure(props.brochure.id)
    .then(() => {
      Notify.success(texts.navigationItems.brochures.delete.success);
      visibleInternal.value = false;
      emit("delete:succeeded");
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.brochures.delete.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

// Reset state when opening or closing updateSlideOver
watch(visibleInternal, () => (deletingBrochure.value = false));
</script>
