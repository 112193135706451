import logger from "@/plugins/logger";
import { RouteMeta, RouteNames } from "@/router/routeNames";
import auth from "@/store/context/auth.context";
import tenant, {
  getTenantUriFromStore,
  setTenantContext,
} from "@/store/context/tenant.context";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

const tenantGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const noTenantRequired = to.meta[RouteMeta.NO_TENANT] as boolean;
  if (noTenantRequired) {
    next();
    return;
  }

  if (tenant.uri) {
    next();
    return;
  }

  const uri = auth.tenantUri ?? getTenantUriFromStore();
  if (!uri) {
    next({ name: RouteNames.TENANTS });
    return;
  }

  try {
    await setTenantContext(uri);
    next();
  } catch (e) {
    logger.error(e);
    next({ name: RouteNames.UNAVAILABLE });
  }
};

export default tenantGuard;
