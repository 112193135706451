<template>
  <div
    v-if="showLoader"
    class="mt-4 flex w-full items-center justify-center"
    :data-testid="testIds.loading"
  >
    <TransitionAppear :show="showLoader">
      <Spinner :size="IconSize.xl" :color="Color.Emerald" />
    </TransitionAppear>
  </div>
  <slot v-else-if="error" :data-testid="testIds.error" name="error"></slot>
  <slot v-else v-bind="$attrs"></slot>
</template>

<script setup lang="ts">
import { testIds } from "@/utils/testing";
import { ref, watch } from "vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Spinner from "@/components/common/spinner/Spinner.vue";
import TransitionAppear from "@/components/common/transition/TransitionAppear.vue";
import { Color } from "@/enums";
import { Timeout } from "@/typings";

const props = withDefaults(
  defineProps<{ loading?: boolean; error?: boolean; delay?: number }>(),
  { loading: false, error: false, delay: 500 },
);

const showLoader = ref(false);

const timeout = ref<Timeout>();
watch(
  () => props.loading,
  (value) => {
    if (!value) {
      clearTimeout(timeout.value);
      showLoader.value = false;
      return;
    }

    timeout.value = setTimeout(() => (showLoader.value = true), props.delay);
  },
  { immediate: true },
);
</script>
