<template>
  <ListItem :title="item.studyProgramDisplayName">
    <ListItemColumn :flex="0">
      <Icon icon="calendar_today" :color="Color.Magenta" />
    </ListItemColumn>
    <ListItemColumn :flex="6" accent>
      <span :class="['truncate', { 'text-gray-400': item.isOrphaned }]">
        {{ item.studyProgramDisplayName }}
      </span>
    </ListItemColumn>
    <ListItemColumn hide :flex="0">
      <span :class="getTextColor(color)" class="text-base font-semibold">
        {{ item.prospectCount }}
      </span>
    </ListItemColumn>
    <ListItemColumn :flex="0">
      <ListItemActions v-if="!item.isOrphaned" @click.stop>
        <DropdownItem
          :data-testid="testIds.action.copyForm"
          @click="copyToClipboard"
        >
          <IconAsync :state="state" icon="link" :color="Color.Gray" />
          <span class="text-deepteal-500">
            {{ componentTexts.overview.copyLinkBtn }}
          </span>
        </DropdownItem>
        <template v-if="canViewPersonalData()">
          <Divider class="mx-2 my-1" />
          <DropdownItem
            v-if="canViewPersonalData()"
            :data-testid="testIds.action.download"
            @click="exportRegistrationsForStudyProgramClick"
          >
            <Icon icon="download" :color="Color.Gray" />
            <span>
              {{ componentTexts.overview.downloadBtn }}
            </span>
          </DropdownItem>
        </template>
      </ListItemActions>
      <div v-else class="w-6"></div>
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import {
  AsyncState,
  useAsyncState,
} from "@/components/common/async/Async.types";
import Divider from "@/components/common/divider/Divider.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { Color, FormType } from "@/enums";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import { getTextColor } from "@/utils/color";
import { downloadFileBySimulatingClickOnTemporaryAnchorTag } from "@/utils/downloadFile";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { watch } from "vue";
import { TrialDayOverviewItem } from "../Overview.types";
import Icon from "@/components/common/icon/Icon.vue";
import { canViewPersonalData } from "@/router/helpers/userCanGoTo";

const props = defineProps<{
  item: TrialDayOverviewItem;
  color: Color;
}>();

const componentTexts = texts.navigationItems.organize.trialDays;

// Copy to clipboard
const createCopyToClipboardUrl = async () => {
  const forms = await formsServiceClient.getForms(
    FormType.TrialDayRegistration,
  );
  const formUri = forms.find(
    (f) => f.id === props.item.configuration.formId,
  )?.uri;

  if (!formUri) {
    throw new Error(`Could not find trial day form uri!`);
  }

  const url = await formsServiceClient.getPreselectTrialDayRegistrationLink(
    formUri,
    props.item.studyProgramId,
  );
  await navigator.clipboard.writeText(url);
};

const { state, handler: copyToClipboard } = useAsyncState(
  createCopyToClipboardUrl,
  componentTexts.overview.copyToClipboard,
);

// Download registrations for study program
const exportRegistrationsForStudyProgramClick = async () => {
  if (!props.item.studyProgramId) {
    throw new Error("No study program id!");
  }

  try {
    state.value = AsyncState.Loading;

    const recruitmentYear = await getRecruitmentYear();
    const currentRecruitmentYearDates =
      recruitmentYear.toCurrentRecruitmentYearDates();

    const response =
      await eduConfigurationServiceClient.exportTrialDaysRegistrationsForConfigurationAndForStudyProgram(
        props.item.configuration.id,
        props.item.studyProgramId,
        currentRecruitmentYearDates.start,
        currentRecruitmentYearDates.end,
      );

    downloadFileBySimulatingClickOnTemporaryAnchorTag(
      response.data,
      response.fileName ?? "export.xlsx",
    );

    Notify.success(texts.notifications.download.success, [
      texts.models.keepMeInformed.title,
    ]);
    state.value = AsyncState.Success;
  } catch (e) {
    state.value = AsyncState.Failure;
    Notify.failure(texts.notifications.download.failure, [
      texts.models.keepMeInformed.title,
    ]);
    logger.error(e as Error);
  }
};

watch(state, (value) => {
  if (value === AsyncState.Success) {
    setTimeout(() => (state.value = AsyncState.Content), 1500);
  }
  if (value === AsyncState.Failure) {
    setTimeout(() => (state.value = AsyncState.Content), 3000);
  }
});
</script>
