<template>
  <FormField
    :id="id"
    :label="label"
    :displayMode="displayMode"
    :rules="rules"
    :initialValue="initialValue"
  >
    <template v-slot="{ value, errorMessage }">
      <Combobox
        :id="id"
        v-model="value.value"
        :name="label"
        :valid="!errorMessage.value"
        :options="options"
        :formatOptionDisplayString="formatOptionDisplayString"
        :searchFilter="searchFilter"
        :searchResultsCompare="searchResultsCompare"
        :loading="loadingStudyPrograms"
        :disabled="disabled"
        @update:modelValue="selectedOptions($event)"
      />
    </template>
  </FormField>
</template>

<script setup lang="ts">
import Combobox from "@/components/common/combobox/Combobox.vue";
import FormField from "@/components/common/form/FormField.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import { AnySchema } from "yup";
import { ref } from "vue";
import StudyProgram from "@/models/study-program";
import { getStudyPrograms } from "@/services/study-program.service";
import logger from "@/plugins/logger";

const props = defineProps<{
  id: string;
  displayMode?: FormFieldDisplayMode;
  rules?: AnySchema;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  filteredStudyProgramIds?: string[];
  initialValue?: string[];
}>();

const emit = defineEmits<{
  (e: "update:selectedOptions", value: string[] | undefined): void;
}>();

const selectedOptions = (value: string[] | undefined) => {
  emit("update:selectedOptions", value);
};

const loadingStudyPrograms = ref(true);
const options = ref<StudyProgram[]>();

getStudyPrograms()
  .then((dtos) => {
    if (props.filteredStudyProgramIds) {
      options.value = dtos
        .filter((dto) => props.filteredStudyProgramIds?.includes(dto.id))
        .map((dto) => new StudyProgram(dto));
    } else {
      options.value = dtos.map((dto) => new StudyProgram(dto));
    }
  })
  .catch((e) => {
    logger.error(e);
  })
  .finally(() => {
    loadingStudyPrograms.value = false;
  });

const formatOptionDisplayString = (option: StudyProgram) => option.displayName;

const searchFilter = (option: StudyProgram, query: string) =>
  option.displayName.toLowerCase().includes(query.toLowerCase());

const searchResultsCompare = (left: StudyProgram, right: StudyProgram) =>
  left.displayName.localeCompare(right.displayName);
</script>
