<template>
  <div
    class="mt-4 flex w-full items-center justify-center"
    data-testid="loader"
  >
    <TransitionRoot
      appear
      enter="ease-in-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      :show="showLoader"
    >
      <Spinner :size="IconSize.xl" :color="Color.Emerald" />
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";
import { ref } from "vue";
import Spinner from "@/components/common/spinner/Spinner.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { Color } from "@/enums";

const showLoader = ref(false);
const showLoaderDelayMs = 500;

setTimeout(() => (showLoader.value = true), showLoaderDelayMs);
</script>
