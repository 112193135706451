import { Culture, FormType } from "@/enums";
import {
  AnalyticDTO,
  AnswerOption,
  AnswerOptionLocalization,
  FileParameter,
  FormAnswerDTO,
  FormAnswerLocalizationDTO,
  FormDTO,
  FormLocalizationDTO,
  FormPageDTO,
  FormPageLocalizationDTO,
  FormQuestionDTO,
  FormQuestionLocalizationDTO,
  IAnalyticDTO,
  IFormAnswerDTO,
  IFormAnswerLocalizationDTO,
  IFormPageDTO,
  IFormQuestionDTO,
  IFormQuestionLocalizationDTO,
  IStyleDTOWithSassVariables,
  Question,
  QuestionLocalization,
  StyleDTO,
} from "@/lib/formsServiceClient";
import { InjectionKey, ref } from "vue";
import {
  convertToDto,
  QuestionSettingsFormValues,
} from "./components/form-template-editor/FormTemplateQuestionSettingsForm.types";
import { formsServiceClient } from "@/services/formsService.client.service";
import { ApiException } from "@/lib/formsServiceClient";
import { IconNames } from "@/components/common/icon/Icon.types";

declare module "@/lib/formsServiceClient" {
  interface IStyleDTOWithSassVariables extends IStyleDTO {
    sass: {
      smtBannerMargin?: string;
      smtBannerStretch?: boolean;
      smtBannerWidth?: string;
      smtBranding?: boolean;
      smtColorBackground?: string;
      smtColorDanger?: string;
      smtColorHeader?: string;
      smtColorLink?: string;
      smtColorPrimary?: string;
      smtColorSecondary?: string;
      smtColorSuccess?: string;
      smtColorText?: string;
      smtContainerMaxWidth?: string;
      smtFontHeader?: string;
      smtFontSize?: string;
      smtFontText?: string;
      smtLabelFontWeight?: string;
      smtLinkFontWeight?: string;
      smtLinkTextDecoration?: string;
    };
  }

  interface FormPageDTO {
    updateQuestion(question: FormQuestionDTO): void;
    updateQuestionSettings(
      questionSettings: QuestionSettingsFormValues,
      question: FormQuestionDTO,
      allQuestions: Question[],
    ): void;
    createQuestion(
      questionSettings: QuestionSettingsFormValues,
      allQuestions: Question[],
    ): void;
    deleteQuestion(atPosition: number): void;
    getQuestionCount(): number;
    moveQuestionUp(atPosition: number): void;
    moveQuestionDown(atPosition: number): void;
    getQuestions(): FormQuestionDTO[];
    getConditionalQuestions(question: FormQuestionDTO): FormQuestionDTO[];
  }

  interface FormQuestionDTO {
    setQuestionId(id: string): void;
    setQuestionLocalizations(
      localizations: FormQuestionLocalizationDTO[],
    ): void;
    setQuestionAnswers(answers: FormAnswerDTO[]): void;
    getLocalizedLabel(locale: Culture): string | undefined;
  }

  interface FormAnswerDTO {
    getLocalizedLabel(locale: Culture): string;
  }

  interface FormDTO {
    deletePage(atPosition: number): void;
    addPage(atPosition: number): void;
    movePageUp(atPosition: number): void;
    movePageDown(atPosition: number): void;
    getLocales(): Culture[];
    addLocalization(locale: Culture): void;
    removeLocalization(locale: Culture): void;
  }

  interface QuestionLocalization {
    mapToDto(): FormQuestionLocalizationDTO;
  }

  interface AnswerOption {
    mapToDto(): FormAnswerDTO;
  }

  interface AnswerOptionLocalization {
    mapToDto(): FormAnswerLocalizationDTO;
  }
}

//#region FormPageDTO
FormPageDTO.prototype.updateQuestion = function (question: FormQuestionDTO) {
  this.questions.splice(
    this.questions.findIndex((q) => q.id === question.id),
    1,
    question,
  );
};

FormPageDTO.prototype.updateQuestionSettings = function (
  formValues: QuestionSettingsFormValues,
  question: FormQuestionDTO,
  allQuestions: Question[],
) {
  const questionRef = allQuestions.find((q) => q.id === formValues.id);
  if (!questionRef)
    throw new Error(
      `Question with id ${formValues.id} not found in question collection`,
    );

  /* Garbage collection of old conditional questions
   *
   * Find all questions which have answerOptionIdDependencies for the answerOptions
   * of the current question. Remove the answerOptionIds from these answerOptionIdDependencies
   * and remove the conditional question if no answerOptionDependencies are left. */

  const answerOptionIds = question!.answers.map((a) => a.id);
  const conditionalQuestions = this.questions.filter((q) =>
    q.answerOptionIdDependencies.some((id) => answerOptionIds.includes(id)),
  );

  conditionalQuestions.forEach((cq) => {
    cq.answerOptionIdDependencies = cq.answerOptionIdDependencies.filter(
      (id) => !answerOptionIds.includes(id),
    );
    if (cq.answerOptionIdDependencies.length === 0) {
      this.deleteQuestion(this.questions.findIndex((q) => q.id === cq.id));
    }
  });

  // Mutate the current question to the submitted question.
  question.setQuestionId(formValues.id);
  question.isInline = formValues.isInline;
  question.isRequired = formValues.isRequired;

  question.setQuestionLocalizations(
    questionRef.localizations.map((l) => l.mapToDto()) ?? [],
  );
  question.setQuestionAnswers(
    questionRef.answerOptions?.map((a) => a.mapToDto()) ?? [],
  );

  this.updateQuestion(question);

  // Add the conditionalQuestions from the submitted values after the current question.
  const questionIndex = this.questions.findIndex((q) => q.id === formValues.id);
  const nextQuestionIndex = this.questions.findIndex(
    (q, index) =>
      index > questionIndex && q.answerOptionIdDependencies.length === 0,
  );
  this.questions.splice(
    questionIndex + 1,
    nextQuestionIndex > -1
      ? nextQuestionIndex - questionIndex - 1
      : this.questions.length - questionIndex - 1,
    ...convertToDto(formValues),
  );

  // Update Sequence numbers of all questions on page
  updateQuestionSequenceNumbers(this);
};

FormPageDTO.prototype.createQuestion = function (
  questionSettings,
  allQuestions,
) {
  const question: IFormQuestionDTO = {
    id: undefined,
    sequenceNumber: 0,
    isInline: true,
    isRequired: true,
    localizations: [],
    answers: [],
    answerOptionIdDependencies: [],
  };

  question.id = questionSettings.id;

  const newQuestion = new FormQuestionDTO(question);
  this.questions.push(newQuestion);

  updateQuestionSequenceNumbers(this);

  this.updateQuestionSettings(questionSettings, newQuestion, allQuestions);
};

FormPageDTO.prototype.deleteQuestion = function (atPosition: number) {
  // Find all conditional questions and remove them as well.
  const question = this.questions[atPosition];
  const answerOptionIds = question.answers.map((a) => a.id);
  const conditionalQuestions = this.questions.filter((q) =>
    q.answerOptionIdDependencies.some((id) => answerOptionIds.includes(id)),
  );

  conditionalQuestions.forEach((cq) => {
    this.questions.splice(
      this.questions.findIndex((q) => q.id === cq.id),
      1,
    );
  });

  // Remove the question itself.
  this.questions.splice(
    this.questions.findIndex((q) => q.id === question.id),
    1,
  );

  updateQuestionSequenceNumbers(this);
};

FormPageDTO.prototype.getQuestionCount = function () {
  return this.questions.length;
};

FormPageDTO.prototype.moveQuestionUp = function (atPosition) {
  if (atPosition === 0) return;

  const question = this.questions[atPosition];

  this.questions.splice(atPosition, 1);
  this.questions.splice(atPosition - 1, 0, question);

  updateQuestionSequenceNumbers(this);
};

FormPageDTO.prototype.moveQuestionDown = function (atPosition) {
  const question = this.questions[atPosition];

  this.questions.splice(atPosition, 1);
  this.questions.splice(atPosition + 1, 0, question);

  updateQuestionSequenceNumbers(this);
};

FormPageDTO.prototype.getQuestions = function () {
  return this.questions.filter((q) => q.answerOptionIdDependencies.length == 0);
};

FormPageDTO.prototype.getConditionalQuestions = function (
  question: FormQuestionDTO,
) {
  const answerOptionIds = question.answers.map((a) => a.id);
  return this.questions.filter((q) =>
    q.answerOptionIdDependencies.some((id) => answerOptionIds.includes(id)),
  );
};

const updateQuestionSequenceNumbers = (page: FormPageDTO) => {
  page.questions = page.questions.map((q: FormQuestionDTO, index) => {
    q.sequenceNumber = index;

    return q;
  });
};
//#endregion

//#region FormQuestionDTO
FormQuestionDTO.prototype.setQuestionId = function (questionId: string) {
  this.id = questionId;
};

FormQuestionDTO.prototype.setQuestionLocalizations = function (
  localizations: FormQuestionLocalizationDTO[],
) {
  this.localizations = localizations;
};

FormQuestionDTO.prototype.setQuestionAnswers = function (
  answers: FormAnswerDTO[],
) {
  this.answers = answers;
};

FormQuestionDTO.prototype.getLocalizedLabel = function (locale: Culture) {
  const localization = this.localizations.find((l) => l.locale === locale);

  return localization?.label ?? undefined;
};
//#endregion

//#region FormAnswerDTO
FormAnswerDTO.prototype.getLocalizedLabel = function (locale: Culture) {
  const localization = this.localizations.find((l) => l.locale === locale);

  return localization?.label ?? "";
};
//#endregion

//#region FormDTO
FormDTO.prototype.deletePage = function (atPosition: number) {
  this.pages.splice(atPosition, 1);

  updatePageSequenceNumbers(this);
};

FormDTO.prototype.addPage = function (atPosition: number) {
  const currentLocales = this.getLocales();

  const page: IFormPageDTO = {
    id: undefined,
    sequenceNumber: atPosition,
    questions: [],
    localizations: currentLocales.map((locale) => {
      return {
        locale: locale,
        title: undefined,
        label: undefined,
        header: undefined,
        footer: undefined,
        pageFooter: undefined,
      };
    }),
  };

  this.pages.splice(atPosition, 0, new FormPageDTO(page));

  updatePageSequenceNumbers(this);
};

FormDTO.prototype.movePageUp = function (atPosition: number) {
  if (atPosition === 0) return;

  const page = this.pages[atPosition];

  this.pages.splice(atPosition, 1);
  this.pages.splice(atPosition - 1, 0, page);

  updatePageSequenceNumbers(this);
};

FormDTO.prototype.movePageDown = function (atPosition: number) {
  const page = this.pages[atPosition];

  this.pages.splice(atPosition, 1);
  this.pages.splice(atPosition + 1, 0, page);

  updatePageSequenceNumbers(this);
};

FormDTO.prototype.getLocales = function (): Culture[] {
  return this.localizations.map((l) => l.locale as Culture);
};

FormDTO.prototype.addLocalization = function (locale: Culture) {
  if (!Object.values(Culture).includes(locale as Culture)) {
    throw new Error(
      `Unsupported locale: ${locale}, allowed: ${Object.values(Culture).join(
        ", ",
      )}`,
    );
  }

  if (this.localizations.some((l) => l.locale === locale)) {
    throw new Error(`Locale ${locale} already exists`);
  }

  this.localizations.push(
    new FormLocalizationDTO({
      locale,
      name: "",
      label: "",
      confirmationPage: "",
    }),
  );

  this.pages.forEach((p) => {
    p.localizations.push(
      new FormPageLocalizationDTO({
        locale,
        title: "",
        label: "",
        header: "",
        footer: "",
        pageFooter: "",
      }),
    );
  });
};

FormDTO.prototype.removeLocalization = function (locale: Culture) {
  if (!Object.values(Culture).includes(locale as Culture)) {
    throw new Error(
      `Unsupported locale: ${locale}, allowed: ${Object.values(Culture).join(
        ", ",
      )}`,
    );
  }

  if (!this.localizations.some((l) => l.locale === locale)) {
    throw new Error(`Locale ${locale} does not exist`);
  }

  this.localizations = this.localizations.filter((l) => l.locale !== locale);
  this.pages.forEach((page) => {
    page.localizations = page.localizations.filter((l) => l.locale !== locale);
  });
};

const updatePageSequenceNumbers = (form: FormDTO) => {
  form.pages = form.pages.map((p: FormPageDTO, index) => {
    p.sequenceNumber = index;

    return p;
  });
};
//#endregion

//#region Question
QuestionLocalization.prototype.mapToDto =
  function (): FormQuestionLocalizationDTO {
    const localization: IFormQuestionLocalizationDTO = {
      locale: this.locale.value ?? "",
      label: this.label,
      header: this.header,
      footer: this.footer,
    };
    return new FormQuestionLocalizationDTO(localization);
  };

AnswerOption.prototype.mapToDto = function (): FormAnswerDTO {
  const answer: IFormAnswerDTO = {
    id: this.id,
    key: this.key,
    sequenceNumber: this.sequenceNumber,
    isOtherOption: this.isOtherOption,
    isDisabled: this.isDisabled,
    localizations: this.localizations.map((l) => l.mapToDto()),
  };
  return new FormAnswerDTO(answer);
};

AnswerOptionLocalization.prototype.mapToDto =
  function (): FormAnswerLocalizationDTO {
    const localization: IFormAnswerLocalizationDTO = {
      locale: this.locale.value ?? "",
      label: this.label,
    };
    return new FormAnswerLocalizationDTO(localization);
  };
//#endregion

//#region FormEditorContext
export type FormTemplateEditContext = {
  formType: FormType;
  questions: Question[];
  formQuestions: FormQuestionDTO[];
};
export const FormTemplateEditContextKey =
  Symbol() as InjectionKey<FormTemplateEditContext>;
//#endregion

const defaultStyle: IStyleDTOWithSassVariables = {
  sass: {
    smtBannerWidth: "auto",
    smtBannerMargin: "0",
    smtBannerStretch: false,
    smtContainerMaxWidth: "950px",
    smtColorPrimary: "#00c890",
    smtColorSecondary: "#00c890",
    smtColorSuccess: "#28a745",
    smtColorDanger: "#dc3545",
    smtColorBackground: "#ffffff",
    smtColorLink: "#0a4b4d",
    smtColorText: "#0a4b4d",
    smtColorHeader: "#0a4b4d",
    smtFontSize: "1rem",
    smtFontText:
      '"MessinaSansWeb-Book", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    smtFontHeader:
      '"MessinaSansWeb-Light", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    smtLabelFontWeight: "normal",
    smtLinkTextDecoration: "none",
    smtLinkFontWeight: "normal",
    smtBranding: true,
  },
};

const defaultAnalytic: IAnalyticDTO = {
  consoleLoggerDisable: false,
  googleAnalyticsDisable: false,
  tagManagerDisable: false,
  tagManagerCode: undefined,
  tagManagerNoMsg: false,
  piwikDisable: false,
};

const errorIs404 = (error: unknown) => {
  return error instanceof ApiException && error.status === 404;
};

export const useFormStyling = (formUri?: string) => {
  const data = ref<IStyleDTOWithSassVariables>();

  const load = async () => {
    try {
      data.value = await getStyle(formUri);
    } catch (error) {
      if (!errorIs404(error)) throw error;

      // If we are loading tenant styling, but we find none,
      // then we load the default style settings.
      data.value = formUri ? undefined : defaultStyle;
    }
  };

  return {
    data,
    load,
  };
};

export const useAnalytic = (formUri?: string) => {
  const data = ref<IAnalyticDTO>();

  const load = async () => {
    try {
      data.value = await getAnalytic(formUri);
    } catch (error) {
      if (!errorIs404(error)) throw error;

      // If we are loading tenant analytics, but we find none,
      // then we load the default analytic settings.
      data.value = formUri ? undefined : defaultAnalytic;
    }
  };

  return {
    data,
    load,
  };
};

export function getAnalytic(formUri?: string) {
  if (formUri) {
    return formsServiceClient.getAnalyticForForm(formUri);
  } else return formsServiceClient.getAnalytic();
}

export function getStyle(formUri?: string) {
  if (formUri) {
    return formsServiceClient.getStyleForForm(formUri);
  } else return formsServiceClient.getStyle();
}

export function putAnalytic(gtmSettings: AnalyticDTO, formUri?: string) {
  if (formUri) {
    return formsServiceClient.putAnalyticForForm(formUri, gtmSettings);
  } else return formsServiceClient.putAnalytic(gtmSettings);
}

export function putStyle(style: StyleDTO, formUri?: string) {
  if (formUri) {
    return formsServiceClient.putStyleForForm(formUri, style);
  } else return formsServiceClient.putStyle(style);
}

export function uploadBanner(file: FileParameter, formUri?: string) {
  if (formUri) {
    return formsServiceClient.uploadBannerForForm(formUri, file);
  } else return formsServiceClient.uploadBanner(file);
}

export function uploadFavicon(file: FileParameter, formUri?: string) {
  if (formUri) {
    return formsServiceClient.uploadFaviconForForm(formUri, file);
  } else return formsServiceClient.uploadFavicon(file);
}

export function deleteAnalytic(formUri?: string) {
  if (formUri) {
    return formsServiceClient.deleteAnalyticForForm(formUri);
  } else return formsServiceClient.deleteAnalytic();
}

export function deleteStyle(formUri?: string) {
  if (formUri) {
    return formsServiceClient.deleteStyleForForm(formUri);
  } else return formsServiceClient.deleteStyle();
}

export const formTypeIcons: Record<FormType, IconNames> = {
  [FormType.Application]: "hotel_class",
  [FormType.Registration]: "calendar_month",
  [FormType.ActivityVisitedSurvey]: "reviews",
  [FormType.DigitalBrochureRequest]: "menu_book",
  [FormType.HardCopyBrochureRequest]: "menu_book",
  [FormType.KeepMeInformed]: "loyalty",
  [FormType.TrialDayRegistration]: "calendar_today",
};
