<template>
  <div class="flex">
    <div
      class="relative flex flex-grow items-stretch rounded-md shadow-sm focus-within:z-10"
    >
      <div
        v-if="icon && type !== TextInputType.PASSWORD"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <Icon
          :icon="icon"
          aria-hidden="true"
          class="text-gray-400"
          :size="IconSize.sm"
        />
      </div>

      <input
        :id="id"
        v-model="modelValueInternal"
        :name="name"
        :type="
          type === TextInputType.PASSWORD
            ? showPassword
              ? 'text'
              : 'password'
            : type
        "
        :class="inputClass"
        :placeholder="disabled ? '' : placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :min="min"
        :max="max"
        @focus="onFocus"
      />

      <div
        v-if="type === TextInputType.PASSWORD"
        class="pointer-events-auto absolute inset-y-0 right-0 flex items-center pr-3"
        @click="showPassword = !showPassword"
      >
        <component
          :is="showPassword ? 'visibility_off' : 'visibility'"
          aria-hidden="true"
          class="h-5 w-5 cursor-pointer text-gray-400"
        />
      </div>
    </div>

    <button
      v-if="button"
      type="button"
      class="ml-2 flex rounded-full bg-deepteal-100 text-sm text-deepteal-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 focus-within:ring-offset-deepteal-300"
      @click="onButtonClick"
    >
      <Icon
        v-if="button.icon"
        :icon="button.icon"
        aria-hidden="true"
        class="p-2"
        :size="IconSize.lg"
      />
      <span v-if="button.text">{{ button.text }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { TextInputButtonProps, TextInputType } from "./TextInput.types";
import { IconNames, IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";

const props = withDefaults(
  defineProps<{
    id?: string;
    name?: string;
    modelValue?: string | number | undefined;
    type?: TextInputType;
    valid?: boolean | undefined;
    disabled?: boolean;
    readonly?: boolean;
    button?: TextInputButtonProps;
    min?: number | string;
    max?: number | string;
    placeholder?: string;
    icon?: IconNames;
  }>(),
  {
    id: undefined,
    name: undefined,
    modelValue: undefined,
    type: TextInputType.TEXT,
    valid: undefined,
    disabled: false,
    readonly: false,
    min: undefined,
    max: undefined,
    placeholder: undefined,
    icon: undefined,
    button: undefined,
  },
);

const showPassword = ref(false);

const emit = defineEmits<{
  (e: "update:modelValue", value: string | undefined | number): void;
  (e: "button:click", value: string | undefined | number): void;
}>();

const modelValueInternal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", getSanitizedValue(value)),
});

if (props.modelValue !== getSanitizedValue(props.modelValue)) {
  modelValueInternal.value = getSanitizedValue(props.modelValue);
}

function getSanitizedValue(
  value: string | number | undefined | null,
): string | undefined | number {
  // Return either non-empty string, number or undefined
  if (value === "") return undefined;
  return value ?? undefined;
}

const displayInvalid = computed(() => props.valid === false);
const inputClass = computed(() => [
  "block w-full sm:text-sm rounded-md",
  {
    "border-gray-300 text-deepteal-700 placeholder-gray-400 bg-transparent":
      props.readonly && !displayInvalid.value,
    "border-alert-300 text-deepteal-700 placeholder-gray-400 bg-transparent":
      props.readonly && displayInvalid.value,
    "border-gray-300 text-gray-400 placeholder-gray-200 bg-gray-100":
      props.disabled && !displayInvalid.value,
    "border-alert-300 text-gray-400 placeholder-gray-200 bg-gray-100":
      props.disabled && displayInvalid.value,
    "border-gray-300 text-black placeholder-gray-400 focus:ring-emerald-500 focus:border-emerald-500":
      !props.disabled && !displayInvalid.value,
    "border-alert-300 text-alert-900 placeholder-alert-100 focus:outline-none focus:ring-alert-500 focus:border-alert-500":
      !props.disabled && displayInvalid.value,
    "pl-10": props.icon,
  },
]);

const onFocus = props.readonly
  ? (event: FocusEvent) => (event.target as HTMLInputElement).select()
  : () => undefined;

const onButtonClick = () => emit("button:click", modelValueInternal.value);
</script>
