<template>
  <Collapsible v-model:expanded="expanded">
    <Checkbox
      v-model="groupChecked"
      @update:modelValue="updateStudyProgramsChecked"
    ></Checkbox>
    <button class="flex items-center gap-2" @click="expanded = !expanded">
      <span class="text-base font-medium">{{ title }}</span>
      <Chevron :expanded="expanded" />
    </button>
    <template v-slot:expanded>
      <List compact>
        <StudyProgramListItem
          v-for="studyProgram in studyPrograms"
          :key="studyProgram.id"
          v-model:checked="checkedInternal"
          :studyProgram="studyProgram"
          :data-testid="testIds.studyProgramPicker.studyProgram"
        />
      </List>
    </template>
  </Collapsible>
</template>

<script setup lang="ts">
import Collapsible from "@/components/common/collapsible/Collapsible.vue";
import List from "@/components/common/list/List.vue";
import StudyProgramListItem from "./StudyProgramListItem.vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import { computed, ref, watchEffect } from "vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import Chevron from "@/components/common/chevron/Chevron.vue";
import { testIds } from "@/utils/testing";

const props = defineProps<{
  title: string;
  studyPrograms: LocalizedStudyProgramDTO[];
  checked: string[];
}>();

const emit = defineEmits<{
  "update:checked": [string[]];
}>();

const checkedInternal = computed({
  get: () => props.checked,
  set: (value) => emit("update:checked", value),
});

const expanded = ref(true);

const groupChecked = ref<boolean | unknown[] | null>(false);

watchEffect(() => {
  const allChecked = props.studyPrograms.every((item) =>
    checkedInternal.value.includes(item.id),
  );
  const someChecked = props.studyPrograms.some((item) =>
    checkedInternal.value.includes(item.id),
  );

  groupChecked.value = allChecked ? true : someChecked ? null : false;
});

function updateStudyProgramsChecked(value: boolean | unknown[] | null) {
  checkedInternal.value = checkedInternal.value
    .filter((id) => !props.studyPrograms.some((sp) => sp.id === id))
    .concat(value ? props.studyPrograms.map((sp) => sp.id) : []);
}
</script>
