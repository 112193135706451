<template>
  <ListItem :title="item.brochure.name" clickable>
    <ListItemColumn
      :flex="0"
      @click="() => emit('item:update', item.brochure.id)"
    >
      <Icon icon="menu_book" :color="color"></Icon>
    </ListItemColumn>
    <ListItemColumn
      :flex="4"
      accent
      @click="() => emit('item:update', item.brochure.id)"
    >
      <span
        class="truncate"
        :class="{
          'text-gray-400': !item.brochure.isPublic || item.isOrphaned,
        }"
      >
        {{ item.brochure.name }}
      </span>
    </ListItemColumn>
    <ListItemColumn
      :flex="4"
      hide
      @click="() => emit('item:update', item.brochure.id)"
    >
      <span class="truncate">
        {{
          brochureStudyPrograms
            ?.map((studyProgram) => studyProgram.displayName)
            .join(", ")
        }}
      </span>
    </ListItemColumn>
    <ListItemColumn :flex="2" class="justify-end">
      <span
        v-if="!item.configuration.isHardCopy || !item.configuration.id"
        class="text-base font-semibold text-emerald-600"
      >
        {{ item.digitalProspectCount }}
      </span>
      <span v-if="!item.configuration.id">/</span>
      <span
        v-if="item.configuration.isHardCopy || !item.configuration.id"
        class="text-base font-semibold text-deepteal-600"
      >
        {{ item.hardcopyProspectCount }}
      </span>
    </ListItemColumn>
    <ListItemColumn :flex="0" class="justify-end overflow-visible">
      <ListItemActions v-if="!item.isOrphaned">
        <DropdownItem
          :disabled="state !== AsyncState.Content || !item.configuration.formId"
          @click="copyToClipboard"
        >
          <Icon icon="link" :color="Color.DeepTeal"></Icon>
          <span>
            {{ texts.navigationItems.brochures.overview.copyFormLink }}
          </span>
        </DropdownItem>
        <slot></slot>
      </ListItemActions>
      <div v-else class="w-6"></div>
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import {
  AsyncState,
  useAsyncState,
} from "@/components/common/async/Async.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Icon from "@/components/common/icon/Icon.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { Color, FormType } from "@/enums";
import { formsServiceClient } from "@/services/formsService.client.service";
import settings from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { BrochureOverviewItem } from "@/views/information-requests/brochures/Overview.types";
import { computed, watch } from "vue";

const props = defineProps<{
  item: BrochureOverviewItem;
  color: Color;
}>();

const emit = defineEmits<{
  "item:update": [value: string];
  "item:delete": [value: string];
}>();

const brochureStudyPrograms = computed(() =>
  settings.studyPrograms?.filter((studyProgram) =>
    props.item.brochure.studyProgramIds?.includes(studyProgram.id),
  ),
);

const brochureTexts = texts.navigationItems.brochures;

const getBrochureFormUrlAndCopy = async () => {
  const formType = props.item.configuration.isHardCopy
    ? FormType.HardCopyBrochureRequest
    : FormType.DigitalBrochureRequest;
  const forms = await formsServiceClient.getForms(formType);
  const formUri = forms.find(
    (f) => f.id === props.item.configuration.formId,
  )?.uri;

  if (!formUri) {
    throw new Error(`Could not find digital brochure form uri!`);
  }

  const url = await formsServiceClient.getPreselectBrochureRequestLink(
    formUri,
    props.item.brochure.id,
  );
  await navigator.clipboard.writeText(url);
};

const { state, handler: copyToClipboard } = useAsyncState(
  getBrochureFormUrlAndCopy,
  brochureTexts.copyToClipboard,
);

watch(state, (value) => {
  if (value === AsyncState.Success) {
    setTimeout(() => (state.value = AsyncState.Content), 1500);
  }
  if (value === AsyncState.Failure) {
    setTimeout(() => (state.value = AsyncState.Content), 3000);
  }
});
</script>
