<template>
  <Card v-if="max > 0" class="flex flex-grow flex-col gap-4">
    <CardHeader v-if="chartTitle" :text="chartTitle"></CardHeader>
    <RegistrantAndVisitorLegend />
    <StatsBarComparableListItem
      v-for="(item, index) in items"
      :key="`comparable-item-${index}`"
      :items="item.countsWithLegends"
      :max="max"
    >
      <span :title="item.label">{{ item.label }}</span>
    </StatsBarComparableListItem>
  </Card>
</template>

<script setup lang="ts">
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import StatsBarComparableListItem from "@/components/common/stats-bar/StatsBarComparableListItem.vue";
import { computed } from "vue";
import RegistrantAndVisitorLegend from "@/components/common/legend/RegistrantAndVisitorLegend.vue";

export type ComparableItem = {
  countsWithLegends: { count: number; legend: string }[];
  label: string;
};

const props = defineProps<{
  items: ComparableItem[];
  chartTitle?: string;
}>();

const max = computed(() =>
  Math.max(
    ...props.items.flatMap((item) =>
      item.countsWithLegends.map((c) => c.count),
    ),
  ),
);
</script>
