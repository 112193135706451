import {
  getLocalizationsQueryable,
  Localization,
  QueryableLocalizations,
} from "@/models/helpers/localizable";

export interface SessionTypeDTO {
  id: string;
  name: string;
}

export interface SessionTypeDTOLocalizableProperties {
  name: string;
}

export interface SessionTypeExtendedDTO extends SessionTypeDTO {
  localizations: Localization<SessionTypeDTOLocalizableProperties>[];
}

export interface SessionTypeCreateDTO {
  localizations: Localization<SessionTypeDTOLocalizableProperties>[];
}

export interface SessionTypeUpdateDTO extends SessionTypeCreateDTO {
  id: string;
}

export default class SessionType {
  id: string;
  name: string;

  constructor(dto: SessionTypeDTO) {
    this.id = dto.id;
    this.name = dto.name;
  }
}

export class SessionTypeExtended extends SessionType {
  localizations: QueryableLocalizations<SessionTypeDTOLocalizableProperties>;

  constructor(dto: SessionTypeExtendedDTO) {
    super(dto);
    this.localizations = getLocalizationsQueryable(dto.localizations);
  }
}
