<template>
  <SlideOver
    v-model:visible="visible"
    :title="texts.navigationItems.dashboard.selection.title"
  >
    <div class="h-full">
      <SectionFlex v-if="selectionDefinition" class="px-4 pb-6 sm:px-6">
        <SelectionDefinition
          v-model:modelValue="selectionDefinition"
          :context
          :exclude="[SelectionCriterionDtoType.IsRegisteredForSession]"
        >
          <SelectionCountForDashboard :definition="selectionDefinition" />
        </SelectionDefinition>
        <template v-slot:footer>
          <div class="flex gap-2">
            <ButtonSubmit @click="onSubmit" />
            <ButtonCancel @click="visible = false">
              {{ texts.actions.cancel }}
            </ButtonCancel>
          </div>
        </template>
      </SectionFlex>
    </div>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SectionFlex from "@/components/common/section/SectionFlex.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { CriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";
import SelectionDefinition from "@/components/selection/SelectionDefinition.vue";
import {
  SelectionCriterionDtoType,
  SelectionDefinitionDto,
} from "@/lib/eduConfigurationServiceClient";
import selection from "@/store/context/selection.context";
import texts from "@/utils/texts";
import SelectionCountForDashboard from "@/views/dashboard/components/SelectionCountForDashboard.vue";
import { watch } from "vue";
import { ref } from "vue";

defineProps<{ context: CriterionFieldContext }>();

const selectionDefinition = ref<SelectionDefinitionDto>();

function deepCopySelectionDefinition() {
  if (!selection.definition) {
    return new SelectionDefinitionDto();
  }
  return SelectionDefinitionDto.fromJS(selection.definition.toJSON());
}

const visible = defineModel<boolean>("visible", { required: true });

watch(
  visible,
  (newValue) => {
    if (newValue) {
      selectionDefinition.value = deepCopySelectionDefinition();
    }
  },
  { immediate: true },
);

const onSubmit = () => {
  selection.definition = selectionDefinition.value;
  visible.value = false;
};
</script>
