<template>
  <Modal
    v-model:visible="visibleInternal"
    :type="ModalType.danger"
    :title="
      interpolate(texts.modal.delete.title, texts.models.emailTemplate.title)
    "
    :description="
      interpolate(
        texts.modal.delete.description,
        texts.models.emailTemplate.title,
      )
    "
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <ButtonDelete
          class="sm:ml-3"
          :loading="deleteLoading"
          :disabled="deleteLoading || !visibleInternal"
          @click="clickDelete"
        />
        <ButtonCancel
          class="mt-3 sm:mt-0"
          :disabled="deleteLoading || !visibleInternal"
          @click="visibleInternal = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import { interpolate } from "@/dictionary";
import logger from "@/plugins/logger";
import { deleteEmailTemplate } from "@/services/emailTemplates.service";
import { loadEmailTemplates } from "@/store/context/settings.context";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { computed, ref } from "vue";

const props = defineProps<{
  emailTemplateId: string;
  visible: boolean;
}>();

const emit = defineEmits<{
  "update:visible": [value: boolean];
  "delete:succeeded": [];
}>();

const visibleInternal = computed({
  get() {
    return props.visible;
  },
  set(val) {
    emit("update:visible", val);
  },
});

const deleteLoading = ref(false);
const clickDelete = async () => {
  deleteLoading.value = true;
  try {
    await deleteEmailTemplate(props.emailTemplateId);
    await loadEmailTemplates();
    Notify.success(texts.notifications.delete.success, [
      texts.models.emailTemplate.title,
    ]);
    visibleInternal.value = false;
    emit("delete:succeeded");
  } catch (e) {
    Notify.failure(texts.notifications.delete.failure, [
      texts.models.emailTemplate.title,
    ]);
    logger.error(e);
  } finally {
    deleteLoading.value = false;
  }
};
</script>
