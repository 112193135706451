<template>
  <template v-if="edit">
    <div>
      <Combobox
        :id="fieldId"
        v-model="value"
        :name="fieldId"
        :options="context.targetAudiences"
        :formatOptionDisplayString="optionFormatter"
        :searchFilter="searchFilter"
        :valid="!errorMessage"
      />
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <span>
      {{
        context.targetAudiences
          .filter((audience) =>
            criterion.targetAudienceIds?.includes(audience.id),
          )
          .map((audience) => audience.name)
          .join(", ")
      }}
    </span>
  </template>
</template>

<script setup lang="ts">
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import { useField } from "vee-validate";
import * as yup from "yup";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import Combobox from "@/components/common/combobox/Combobox.vue";
import TargetAudience from "@/models/targetAudience";

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().targetAudienceIds;

const { value, errorMessage } = useField<
  typeof props.criterion.targetAudienceIds
>(fieldId, yup.array().of(yup.string()).min(1).required(), {
  initialValue: [...(props.criterion.targetAudienceIds ?? [])], // Shallow-copy to decouple field-array from the original
});

const searchFilter = (audience: TargetAudience, query: string) =>
  audience.name.toLowerCase().includes(query.toLowerCase());

const optionFormatter = (audience: TargetAudience) => audience.name;
</script>
