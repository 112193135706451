<template>
  <div class="text-center md:py-24">
    <h1
      class="mt-1 text-2xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight"
    >
      {{ texts.notFound.title }}
    </h1>
    <p class="text-l mx-auto mt-5 max-w-xl text-gray-500">
      {{ texts.notFound.description }}
    </p>
  </div>
</template>
<script setup lang="ts">
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { RootState } from "@/store";
import { useStore } from "vuex";

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];
</script>
