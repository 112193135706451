import { TenantDTO } from "@/lib/eduConfigurationServiceClient";
import { loadSettings } from "@/store/context/settings.context";
import HttpClient from "@/utils/httpClient";
import { Settings } from "luxon";
import { reactive } from "vue";

type TenantContext = {
  uri: string | undefined;
  name: string | undefined;
};

const tenant = reactive<TenantContext>({
  uri: undefined,
  name: undefined,
});

export default tenant;

const TENANT_STORE_KEY = `${window.document.location.host}_tenant`;

export function getTenantUriFromStore() {
  return sessionStorage.getItem(TENANT_STORE_KEY) ?? undefined;
}

export async function setTenantContext(uri: string) {
  sessionStorage.setItem(TENANT_STORE_KEY, uri);

  HttpClient.setTenant(uri);

  Settings.defaultZone = "Europe/Amsterdam";

  const dto = await loadTenant();
  await loadSettings();

  Object.assign<TenantContext, TenantContext>(tenant, {
    uri: dto.uri,
    name: dto.name,
  });
}

export function clearTenantContext() {
  sessionStorage.removeItem(TENANT_STORE_KEY);

  Object.assign<TenantContext, TenantContext>(tenant, {
    uri: undefined,
    name: undefined,
  });
}

async function loadTenant() {
  return (await HttpClient.educonfig.get<TenantDTO>("tenant")).data;
}
