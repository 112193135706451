import {
  getLocalizationsQueryable,
  Localization,
  QueryableLocalizations,
} from "./helpers/localizable";

export interface StudyProgramDepartmentDTOLocalizableProperties {
  name: string;
}

export interface StudyProgramDepartmentCreateDTO {
  sequenceNumber?: number;
  localizations: Localization<StudyProgramDepartmentDTOLocalizableProperties>[];
}

export interface StudyProgramDepartmentUpdateDTO
  extends StudyProgramDepartmentCreateDTO {
  id: string;
}

export interface StudyProgramDepartmentDTO {
  id: string;
  sequenceNumber?: number;
  name: string;
}

export interface StudyProgramDepartmentExtendedDTO {
  id: string;
  sequenceNumber?: number;
  localizations: Localization<StudyProgramDepartmentDTOLocalizableProperties>[];
}

export class StudyProgramDepartment {
  id: string;
  sequenceNumber?: number;
  name: string;

  constructor(dto: StudyProgramDepartmentDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.name = dto.name;
  }
}

export class StudyProgramDepartmentExtended {
  id: string;
  sequenceNumber?: number;
  localizations: QueryableLocalizations<StudyProgramDepartmentDTOLocalizableProperties>;

  constructor(dto: StudyProgramDepartmentExtendedDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.localizations = getLocalizationsQueryable(dto.localizations);
  }
}
