import { IAffiliateLinkOverviewItemDto } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { interpolate } from "@/dictionary";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { downloadFileBySimulatingClickOnTemporaryAnchorTag } from "@/utils/downloadFile";
import Notify from "@/utils/notify";

export const affiliateMailToHref = (
  affiliationLink: IAffiliateLinkOverviewItemDto,
): string => {
  return `mailto:${affiliationLink.affiliate.emailAddress}?subject=${
    texts.navigationItems.organize.activity.affiliateLinks.mail.subject
  }&body=${interpolate(
    texts.navigationItems.organize.activity.affiliateLinks.mail.body,
    affiliationLink.affiliate.name,
    affiliationLink.uri,
  )}`;
};

export const downloadAffiliateLinkReport = async (
  activityId: string,
  affiliateId: string,
) => {
  try {
    const response =
      await eduConfigurationServiceClient.exportRegistrationsForAffiliate(
        activityId,
        affiliateId,
      );
    downloadFileBySimulatingClickOnTemporaryAnchorTag(
      response.data,
      response.fileName ?? "export.xlsx",
    );
    Notify.success(
      texts.navigationItems.organize.activity.affiliateLinks.downloadRequests
        .success,
    );
  } catch {
    Notify.failure(
      texts.navigationItems.organize.activity.affiliateLinks.downloadRequests
        .failure,
    );
  }
};

export const sendAffiliateLinkRegistrationsReportMail = async (
  activityId: string,
  affiliateId: string,
) => {
  try {
    await eduConfigurationServiceClient.sendAffiliateLink(
      activityId,
      affiliateId,
    );
    Notify.success(
      texts.navigationItems.organize.activity.affiliateLinks
        .mailRequestsDownloadLink.success,
    );
  } catch {
    Notify.failure(
      texts.navigationItems.organize.activity.affiliateLinks
        .mailRequestsDownloadLink.failure,
    );
  }
};

export const allAffiliates = async () => {
  const response = await eduConfigurationServiceClient.getAllAffiliates();
  return response;
};
