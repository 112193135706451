<template>
  <Card v-if="max > 0" class="flex flex-grow flex-col gap-4">
    <Loader v-if="loading" />
    <template v-else>
      <CardHeader :text="componentTexts.title"></CardHeader>
      <RegistrantAndVisitorLegend />

      <div :class="['flex flex-col gap-4', loading ? 'opacity-40' : '']">
        <Collapsible
          v-for="(level, index) of items"
          :key="level.id"
          v-model:expanded="level.expanded"
        >
          <button
            type="button"
            class="flex gap-2 self-start print:hidden"
            @click="clickHandler(level)"
          >
            <Chevron v-if="!level.loading" :expanded="level.expanded" />
            <Spinner
              v-if="level.loading"
              :color="Color.Lavender"
              :size="IconSize.xs"
            />
          </button>
          <StatsBarComparableListItem
            :key="`comparable-item-${index}`"
            :items="level.items"
            :max="max"
            class="flex-1"
          >
            <span>{{ level.name }}</span>
          </StatsBarComparableListItem>
          <template
            v-if="level.areas && level.areas?.length > 0"
            v-slot:expanded
          >
            <StatsBarComparableListItem
              v-for="(area, i) in level.areas"
              :key="`comparable-sub-item-${i}`"
              :items="area.items"
              :intensity="4"
              :max="max"
              class="pl-6"
            >
              <span>{{ area.name }}</span>
            </StatsBarComparableListItem>
          </template>
        </Collapsible>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import texts from "@/utils/texts";
import logger from "@/plugins/logger";
import { computed, ref } from "vue";
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import StatsBarComparableListItem from "@/components/common/stats-bar/StatsBarComparableListItem.vue";
import RegistrantAndVisitorLegend from "@/components/common/legend/RegistrantAndVisitorLegend.vue";
import { Color } from "@/enums";
import { IconSize } from "@/components/common/icon/Icon.types";
import Chevron from "@/components/common/chevron/Chevron.vue";
import Spinner from "@/components/common/spinner/Spinner.vue";
import Collapsible from "@/components/common/collapsible/Collapsible.vue";
import Loader from "@/components/common/loader/Loader.vue";

const props = defineProps<{ activityId: string }>();

type PreEducationLevelStat = {
  id: string;
  name: string;
  items: { count: number; legend: string }[];
  expanded: boolean;
  loading: boolean;
  areas?: {
    id: string;
    name: string;
    items: { count: number; legend: string }[];
  }[];
};

const componentTexts =
  texts.navigationItems.organize.activity.reports.preEducationLevelsGraph;

const loading = ref(true);
const items = ref<PreEducationLevelStat[]>([]);

eduConfigurationServiceClient
  .getPreEducationLevelStats(props.activityId)
  .then((response) => {
    items.value = response.map((level) => {
      return {
        id: level.id,
        name: level.preEducationLevelName,
        expanded: false,
        loading: false,
        items: [
          {
            count: level.registrantCount,
            legend:
              texts.navigationItems.organize.activity.reports.registrations
                .title,
          },
          {
            count: level.visitorCount,
            legend:
              texts.navigationItems.organize.activity.reports.visitors.title,
          },
        ],
      };
    });

    loading.value = false;
  })
  .catch((e) => logger.error(e));

const clickHandler = (level: PreEducationLevelStat) => {
  level.expanded = !level.expanded;
  if (level.areas) return;
  level.loading = true;

  const promise = eduConfigurationServiceClient.getPreEducationAreaStats(
    props.activityId,
    level.id,
  );

  promise
    .then((response) => {
      level.areas = response.map((area) => ({
        id: area.areaId,
        name: area.areaName,
        items: [
          {
            count: area.registrantCount,
            legend:
              texts.navigationItems.organize.activity.reports.registrations
                .title,
          },
          {
            count: area.visitorCount ?? 0,
            legend:
              texts.navigationItems.organize.activity.reports.visitors.title,
          },
        ],
      }));

      console.log(level.areas);
    })
    .catch((e) => logger.error(e))
    .finally(() => (level.loading = false));
};

const max = computed(() =>
  Math.max(...items.value.flatMap((item) => item.items.map((c) => c.count))),
);
</script>
