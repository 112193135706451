import { Culture } from "@/enums";
import {
  CreateKeepMeInformedConfigurationDTO,
  KeepMeInformedConfigurationDTO,
  UpdateKeepMeInformedConfigurationDTO,
} from "@/lib/eduConfigurationServiceClient";

export interface KeepMeInformedConfigurationFormValues {
  name: string;
  formId?: string;
  confirmationEmailTemplateId?: string;
  allowNoInterest: boolean;
  studyProgramIds: string[];
  localizations: KeepMeInformedConfigurationFormValuesLocalization[];
}

export interface KeepMeInformedConfigurationFormValuesLocalization {
  locale: Culture;
  noInterestLabel: string;
}

export const convertToFormValues = (
  configuration: KeepMeInformedConfigurationDTO,
): KeepMeInformedConfigurationFormValues => {
  return {
    ...configuration,
    localizations:
      configuration.localizations as KeepMeInformedConfigurationFormValuesLocalization[],
  };
};

export const convertToDTO = (
  formValues: KeepMeInformedConfigurationFormValues,
):
  | CreateKeepMeInformedConfigurationDTO
  | UpdateKeepMeInformedConfigurationDTO => {
  return new CreateKeepMeInformedConfigurationDTO({
    ...formValues,
    localizations: formValues.localizations.filter((l) => l.noInterestLabel),
  });
};
