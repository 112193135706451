import { Localization } from "@/models/helpers/localizable";

export interface ActivityLocationDTO {
  id: string;
  name: string;
  informationUrl?: string;
}

export interface ActivityLocationDTOLocalizableProperties {
  name: string;
  city?: string;
}

export interface ActivityLocationExtendedDTO extends ActivityLocationDTO {
  localizations: Localization<ActivityLocationDTOLocalizableProperties>[];
  informationUrl?: string;
}

export interface ActivityLocationCreateDTO {
  localizations: Localization<ActivityLocationDTOLocalizableProperties>[];
  informationUrl?: string;
}

export interface ActivityLocationUpdateDTO extends ActivityLocationCreateDTO {
  id: string;
}

export default class ActivityLocation {
  id: string;
  name: string;
  informationUrl?: string;

  constructor(dto: ActivityLocationDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.informationUrl = dto.informationUrl;
  }
}

export class ActivityLocationExtended extends ActivityLocation {
  localizations: Localization<ActivityLocationDTOLocalizableProperties>[];

  constructor(dto: ActivityLocationExtendedDTO) {
    super(dto);

    this.localizations = dto.localizations;
  }
}
