<template>
  <div class="flex h-full min-h-0 flex-col">
    <header
      v-if="$slots.header"
      class="flex flex-shrink flex-grow-0 flex-col px-1 py-0.5"
    >
      <slot name="header"></slot>
    </header>
    <div
      class="flex flex-shrink flex-grow flex-col gap-8 overflow-y-auto px-1 py-1"
    >
      <slot></slot>
    </div>
    <footer
      v-if="$slots.footer"
      class="flex-shrink flex-grow-0 justify-end px-1 py-0.5"
    >
      <slot name="footer"></slot>
    </footer>
  </div>
</template>
