<template>
  <ButtonBase round :size="buttonSize" :data-testid="testIds.action.create">
    <Icon icon="add" :size="iconSize" :color="Color.White" />
  </ButtonBase>
</template>

<script setup lang="ts">
import { testIds } from "@/utils/testing";
import { IconSize } from "../icon/Icon.types";
import { ButtonSize } from "./Button.types";
import ButtonBase from "./ButtonBase.vue";
import { Color } from "@/enums";
import { computed } from "vue";
import Icon from "@/components/common/icon/Icon.vue";

const props = defineProps<{
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}>();

const buttonSize = computed(() => {
  switch (props.size) {
    case "xs":
      return ButtonSize.xs;
    case "sm":
      return ButtonSize.sm;
    case "md":
      return ButtonSize.md;
    case "lg":
      return ButtonSize.lg;
    case "xl":
      return ButtonSize.xl;
    default:
      return ButtonSize.md;
  }
});

const iconSize = computed(() => {
  switch (props.size) {
    case "xs":
      return IconSize.xs;
    case "sm":
      return IconSize.sm;
    case "md":
      return IconSize.md;
    case "lg":
      return IconSize.lg;
    case "xl":
      return IconSize.xl;
    default:
      return IconSize.md;
  }
});
</script>
