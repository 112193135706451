<template>
  <li class="flex flex-col items-start gap-4">
    <div class="flex-inline flex gap-2">
      <TextButton
        v-if="groupHeaderEditLink && title"
        :label="title"
        :color="Color.Black"
        @click="emit('group:update')"
      ></TextButton>
      <span v-else class="text-base font-normal">{{ title }}</span>
      <Icon
        v-if="sessions.length <= 0"
        icon="delete"
        :color="Color.Alert"
        :size="IconSize.sm"
        clickable
        @click="deleteSessionGroup"
      ></Icon>
    </div>

    <List compact class="w-full">
      <SessionItem
        v-for="(session, index) in sessions"
        :key="index"
        :session="session"
        :activityId="activity.id"
        @update="emit('update', session.id)"
        @download="emit('download', session.id)"
        @copy="emit('copy', session.id)"
        @delete="emit('delete', session.id)"
      />
    </List>
  </li>
</template>

<script setup lang="ts">
import List from "@/components/common/list/List.vue";
import SessionItem from "@/views/organize/activities/activity/sessions/components/session-item/SessionItem.vue";
import {
  ActivityDTO,
  LocalizedSessionCountDto,
} from "@/lib/eduConfigurationServiceClient";
import Icon from "@/components/common/icon/Icon.vue";
import { Color } from "@/enums";
import { IconSize } from "@/components/common/icon/Icon.types";
import TextButton from "@/components/common/button/TextButton.vue";

withDefaults(
  defineProps<{
    title?: string;
    sessions: LocalizedSessionCountDto[];
    activity: ActivityDTO;
    groupHeaderEditLink?: boolean;
  }>(),
  {
    title: undefined,
    groupHeaderEditLink: false,
  },
);

const emit = defineEmits<{
  update: [value: string];
  download: [value: string];
  copy: [value: string];
  delete: [value: string];
  "group:delete": [];
  "group:update": [];
}>();

const deleteSessionGroup = () => {
  emit("group:delete");
};
</script>
