import HttpClient from "@/utils/httpClient";

import {
  ActivityTypeCreateDTO,
  ActivityTypeDTO,
  ActivityTypeExtendedDTO,
  ActivityTypeUpdateDTO,
} from "@/models/activityType";

export const getActivityTypes = async (): Promise<ActivityTypeDTO[]> => {
  return await HttpClient.educonfig
    .get<ActivityTypeDTO[]>("activitytypes")
    .then((response) => response.data);
};

export const getActivityType = async (
  activityTypeId: string,
): Promise<ActivityTypeExtendedDTO> => {
  return await HttpClient.educonfig
    .get<ActivityTypeExtendedDTO>(`activitytypes/${activityTypeId}`)
    .then((response) => response.data);
};

export const createActivityType = async (
  activityType: ActivityTypeCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post("activitytypes", activityType);
};

export const updateActivityType = async (
  activityType: ActivityTypeUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `activitytypes/${activityType.id}`,
    activityType,
  );
};

export const deleteActivityType = async (
  activityTypeId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(`activitytypes/${activityTypeId}`);
};
