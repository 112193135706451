<template>
  <span v-if="!endAmount || isNaN(endAmount)" :data-testid="testIds.count"
    >--</span
  >

  <Vue3Autocounter
    v-else
    :startAmount="startAmount"
    :endAmount="endAmount"
    :duration="duration"
    :separator="separator"
    :prefix="prefix"
    :suffix="suffix"
    :data-testid="testIds.count"
  />
</template>

<script setup lang="ts">
import { isTesting } from "@/env";
import { testIds } from "@/utils/testing";
import { ref, watch } from "vue";
import Vue3Autocounter from "vue3-autocounter";

const props = defineProps<{
  value: number | undefined;
  prefix?: string;
  suffix?: string;
  separator?: string;
}>();

// For testing we want to have the counter instantly
// Otherwise intermediate values are tested
const duration = isTesting ? 0.0001 : 1;
const startAmount = ref();
const endAmount = ref(props.value);

watch(
  () => props.value,
  (value) => {
    endAmount.value = value;

    setTimeout(() => {
      startAmount.value = value;
    }, duration * 1000);
  },
);
</script>
