<template>
  <template v-if="edit">
    <div>
      <Combobox
        :id="fieldId"
        v-model="value"
        :name="fieldId"
        :options="context.mailings"
        :formatOptionDisplayString="optionFormatter"
        :searchFilter="searchFilter"
        :valid="!errorMessage"
      />
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <span>
      {{
        context.mailings
          .filter((mailing) => criterion.mailingIds?.includes(mailing.id))
          .map((mailing) => formatLabel(mailing))
          .join(", ")
      }}
    </span>
  </template>
</template>

<script setup lang="ts">
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import { useField } from "vee-validate";
import {
  MailingSelectionDefinitionDto,
  SelectionCriterionDto,
} from "@/lib/eduConfigurationServiceClient";
import * as yup from "yup";
import { fields } from "@/utils/miscellaneous";
import { DateTime } from "luxon";
import Combobox from "@/components/common/combobox/Combobox.vue";

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().mailingIds;

const { value, errorMessage } = useField<typeof props.criterion.mailingIds>(
  fieldId,
  yup.array().of(yup.string()).min(1).required(),
  {
    initialValue: [...(props.criterion.mailingIds ?? [])], // Shallow-copy to decouple field-array from the original
  },
);

const searchFilter = (mailing: MailingSelectionDefinitionDto, query: string) =>
  mailing.name.toLowerCase().includes(query.toLowerCase());

const optionFormatter = (mailing: MailingSelectionDefinitionDto) =>
  formatLabel(mailing);

const formatLabel = (mailing?: MailingSelectionDefinitionDto) => {
  if (!mailing) return "";
  const mailingDateTime = mailing.sentDateTime ?? mailing.plannedDateTime;
  return `${mailing.name} (${
    mailingDateTime
      ? mailingDateTime.toLocaleString(DateTime.DATETIME_SHORT)
      : ""
  })`;
};
</script>
