<template>
  <Page class="flex h-full w-full flex-col gap-8">
    <PageHeading>{{ textsActivity.mailings.title }}</PageHeading>
    <MailingsOverview :id />
    <Divider />
    <div class="flex flex-col gap-2">
      <PageHeading>
        {{ textsActivity.shortMessageCampaign.title }}
      </PageHeading>
      <PageSubTitle>{{
        textsActivity.shortMessageCampaign.description
      }}</PageSubTitle>
    </div>
    <SMS :id :startDateTime="activity.startDateTime" />
  </Page>
</template>

<script setup lang="ts">
import Divider from "@/components/common/divider/Divider.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import Page from "@/components/common/page/Page.vue";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import texts from "@/utils/texts";
import MailingsOverview from "@/views/organize/activities/activity/mailings-sms/mailings/MailingsOverview.vue";
import SMS from "@/views/organize/activities/activity/mailings-sms/sms/SMS.vue";

const textsActivity = texts.navigationItems.organize.activity;

defineProps<ActivityRouteProps>();
</script>
