<template>
  <DropdownWrapper :origin="DropdownOrigin.TopRight">
    <template v-slot:button>
      <MenuButton :as="ButtonAdd" />
    </template>
    <template v-slot:items>
      <DropdownItem @click="emit('createSession')">
        <Icon icon="add" />
        {{ componentTexts.newSessionButton }}
      </DropdownItem>
      <DropdownItem @click="emit('importSessions')">
        <Icon icon="forms_add_on" />
        {{ componentTexts.importSessionsButton }}</DropdownItem
      >
    </template>
  </DropdownWrapper>
</template>
<script setup lang="ts">
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { MenuButton } from "@headlessui/vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import texts from "@/utils/texts";
import Icon from "@/components/common/icon/Icon.vue";

const componentTexts =
  texts.navigationItems.organize.activity.sessions.overview;

const emit = defineEmits([
  "createSessionGroup",
  "createSession",
  "importSessions",
]);
</script>
