<template>
  <TextInput
    :id="id"
    v-model="modelValueInternal"
    :name="name"
    :type="TextInputType.DATE"
    :valid="valid"
    :disabled="disabled"
    :min="minDateString"
    :max="maxDateString"
  ></TextInput>
</template>

<script setup lang="ts">
import { computed } from "vue";
import TextInput from "@/components/common/text-input/TextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import { DateTime } from "luxon";

const props = withDefaults(
  defineProps<{
    id?: string;
    name?: string;
    modelValue?: DateTime;
    valid?: boolean;
    disabled?: boolean;
    min?: DateTime;
    max?: DateTime;
  }>(),
  {
    id: undefined,
    name: undefined,
    modelValue: undefined,
    valid: false,
    disabled: false,
    min: undefined,
    max: undefined,
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", value?: DateTime): void;
}>();

const modelValueInternal = computed({
  get: () => props.modelValue?.toFormat("yyyy-MM-dd"),
  set: (value) =>
    emit(
      "update:modelValue",
      value ? DateTime.fromFormat(value, "yyyy-MM-dd") : undefined,
    ),
});

const minDateString = computed(() => {
  return props.min ? (props.min.toISO() ?? undefined) : undefined;
});

const maxDateString = computed(() => {
  return props.max ? (props.max.toISO() ?? undefined) : undefined;
});
</script>
