<template>
  <div class="flex h-full flex-1 flex-col">
    <slot name="header"></slot>
    <Section class="hidden md:block">
      <NavigationSteps
        :steps="steps"
        :currentStepIndex="currentStepIndex"
      ></NavigationSteps>
    </Section>
    <component
      :is="content"
      v-for="(content, i) in steps.map((s) => s.content)"
      v-show="i === currentStepIndex"
      :key="i"
      class="-m-1 flex-1 overflow-y-auto p-1"
      :style="{ height: stepHeightStyle }"
      :data="data"
      :contextData="contextData"
      :isNavigatingForward="i === currentStepIndex && isNavigatingForward"
      :isNavigatingBack="i === currentStepIndex && isNavigatingBack"
      :isSaving="i === currentStepIndex && isSaving"
      @forwardNavigationValidated="forwardNavigationValidated"
      @backNavigationValidated="backNavigationValidated"
      @saveValidated="saveValidated"
    ></component>
    <Section class="mt-5 grid border-t border-t-gray-200 pt-5 lg:grid-cols-2">
      <div class="flex flex-col lg:flex-row">
        <Button
          :color="Color.White"
          :label="texts.wizard.saveConcept"
          data-testid="save"
          class="w-full lg:mr-4 lg:w-auto"
          :loading="saveLoading"
          :disabled="isLoading || saveDisabled"
          :leadingIcon="'save'"
          @click="$emit('save')"
        ></Button>
        <Button
          class="mt-1 w-full lg:mt-0 lg:w-auto"
          data-testid="close"
          :color="Color.White"
          :label="texts.wizard.close"
          :disabled="isLoading || closeDisabled"
          :leadingIcon="'close'"
          @click="$emit('close')"
        ></Button>
      </div>
      <div class="mt-5 flex flex-col lg:mt-0 lg:flex-row lg:justify-end">
        <Button
          v-if="currentStepIndex > 0"
          class="w-full lg:mr-4 lg:w-auto"
          data-testid="previous"
          :label="texts.wizard.previous"
          :disabled="isLoading || previousDisabled"
          @click="$emit('previous')"
        ></Button>
        <Button
          class="mt-1 w-full lg:mt-0 lg:w-auto"
          data-testid="next"
          :label="nextLabel"
          :loading="nextLoading"
          :disabled="isLoading || nextDisabled"
          @click="$emit('next')"
        ></Button>
      </div>
    </Section>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import NavigationSteps from "@/components/common/navigation/NavigationSteps.vue";
import Section from "../section/Section.vue";
import Button from "@/components/common/button/Button.vue";
import { Color, Culture } from "@/enums";
import dictionary from "@/dictionary";
import { WizardStep } from "@/components/common/wizard/Wizard.types";

const emit = defineEmits([
  "next",
  "previous",
  "save",
  "close",
  "forwardNavigationValidated",
  "backNavigationValidated",
  "saveValidated",
]);

const props = withDefaults(
  defineProps<{
    steps: WizardStep[];
    stepHeightStyle?: string;
    currentStepIndex: number;
    culture?: Culture;
    isNavigatingForward?: boolean;
    isNavigatingBack?: boolean;
    isSaving?: boolean;
    data: Record<string, unknown>;
    contextData?: Record<string, unknown>;
    nextDisabled?: boolean;
    nextLoading?: boolean;
    previousDisabled?: boolean;
    closeDisabled?: boolean;
    saveDisabled?: boolean;
    saveLoading?: boolean;
  }>(),
  {
    culture: Culture.NL,
    nextDisabled: false,
    nextLoading: false,
    previousDisabled: false,
    closeDisabled: false,
    saveDisabled: false,
    saveLoading: false,
    contextData: undefined,
    stepHeightStyle: undefined,
  },
);

// Translations
const texts = dictionary[props.culture];

const isLoading = computed(
  () => props.isNavigatingForward || props.isNavigatingBack || props.isSaving,
);

const nextLabel = computed(() =>
  props.currentStepIndex === props.steps.length - 1
    ? texts.wizard.finish
    : texts.wizard.next,
);

const forwardNavigationValidated = (isValid: boolean) =>
  emit("forwardNavigationValidated", isValid);

const backNavigationValidated = (isValid: boolean) =>
  emit("backNavigationValidated", isValid);

const saveValidated = (isValid: boolean) => emit("saveValidated", isValid);
</script>
