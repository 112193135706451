<template>
  <Filter v-model:expanded="expanded" :title="texts.models.activityType.title">
    <FilterItem
      v-for="activityType in activityTypes"
      :id="activityType.id"
      :key="activityType.id"
      v-model="checked"
      :name="activityType.name"
      :color="getGuidColor(activityType.id)"
      :count="counts[activityType.id] || 0"
    ></FilterItem>
  </Filter>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { ref, watch } from "vue";

import ActivityType from "@/models/activityType";
import FilterItem from "@/components/common/filter/FilterItem.vue";
import { getGuidColor } from "@/utils/color";
import Filter from "@/components/common/filter/Filter.vue";

const props = defineProps<{
  activityTypes: ActivityType[];
  reset: boolean;
  counts: Record<string, number>;
}>();

const emit = defineEmits<{
  (e: "filter", value: string[] | undefined): void;
}>();

const expanded = ref(true);
const checked = ref<string[]>([]);

watch(checked, (values) => {
  emit("filter", values.length > 0 ? values : undefined);
});

watch(
  () => props.reset,
  () => (checked.value = []),
);
</script>
