<template>
  <PopoverWrapper :panelOrigin>
    <template v-slot:button>
      <PopoverButton
        :as="Button"
        :color="Color.Gray"
        :size="ButtonSize.sm"
        :trailingIcon="'keyboard_arrow_down'"
        :disabled
        round
      >
        <span v-if="definition.filters.dateRange">{{
          Interval.fromDateTimes(
            definition.filters.dateRange.from,
            definition.filters.dateRange.to,
          ).toLocaleString(DateTime.DATE_SHORT)
        }}</span>
        <span v-else>&nbsp;–&nbsp; </span>
      </PopoverButton>
    </template>
    <template v-slot:panel="{ close }">
      <FormLayout
        class="min-w-96 rounded-lg bg-white pt-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        @submit.prevent="onSubmit(close)"
      >
        <div class="flex flex-col gap-1">
          <FormFieldDate
            id="start"
            :label="componentTexts.start"
            :displayMode="FormFieldDisplayMode.Row"
          />

          <FormFieldDate
            id="end"
            :label="componentTexts.end"
            :displayMode="FormFieldDisplayMode.Row"
          />
        </div>
        <div class="flex flex-col gap-1">
          <Button
            v-if="recruitmentYear"
            :size="ButtonSize.sm"
            :color="Color.White"
            :leadingIcon="'calendar_month'"
            :label="componentTexts.current"
            class="text-nowrap"
            @click="
              setValuesFromRecruitmentYearDates(
                recruitmentYear.toCurrentRecruitmentYearDates(),
              )
            "
          />
          <Button
            v-if="recruitmentYear"
            :size="ButtonSize.sm"
            :color="Color.White"
            :leadingIcon="'calendar_month'"
            :label="componentTexts.previous"
            class="text-nowrap"
            @click="
              setValuesFromRecruitmentYearDates(
                recruitmentYear.toPreviousRecruitmentYearDates(),
              )
            "
          />
        </div>
        <template v-slot:actions>
          <div class="flex flex-row gap-4">
            <button
              class="cursor-pointer text-sm font-medium text-emerald-500 hover:text-emerald-700"
              type="submit"
            >
              {{ texts.actions.apply }}
            </button>
            <button
              class="cursor-pointer text-sm font-medium text-emerald-500 hover:text-emerald-700"
              type="button"
              @click="handleCancel(close)"
            >
              {{ texts.actions.cancel }}
            </button>
          </div>
        </template>
      </FormLayout>
    </template>
  </PopoverWrapper>
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import FormFieldDate from "@/components/common/date/FormFieldDate.vue";
import FormLayout from "@/components/common/form/FormLayout.vue";
import { Color } from "@/enums.js";
import {
  DateRangeDto,
  ISelectionDefinitionDto,
  RecruitmentYearDTO,
} from "@/lib/eduConfigurationServiceClient";
import {
  RecruitmentYear,
  RecruitmentYearDates,
} from "@/models/recruitmentYear";
import texts from "@/utils/texts";
import { DateTime, Interval } from "luxon";
import { useForm } from "vee-validate";
import { computed, watch } from "vue";
import * as yup from "yup";
import PopoverWrapper from "@/components/common/popover/PopoverWrapper.vue";
import { PanelOrigin } from "@/components/common/popover/PopoverWrapper.types";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import { PopoverButton } from "@headlessui/vue";

const componentTexts = texts.navigationItems.dashboard.selection.filters.period;

const props = withDefaults(
  defineProps<{
    recruitmentYear: RecruitmentYearDTO;
    panelOrigin?: PanelOrigin;
    disabled?: boolean;
  }>(),
  {
    panelOrigin: PanelOrigin.TopLeft,
    disabled: false,
  },
);

const recruitmentYear = computed<RecruitmentYear>(() => {
  return new RecruitmentYear(props.recruitmentYear);
});

const definition = defineModel<ISelectionDefinitionDto>("definition", {
  required: true,
});

type SelectionPeriodFormValues = {
  start: DateTime;
  end: DateTime;
};

const form = useForm<SelectionPeriodFormValues>({
  validationSchema: yup.object({
    start: yup.mixed<DateTime>().required(),
    end: yup
      .mixed<DateTime>()
      .required()
      .isLaterThan("start", texts.validation.endDateTimeMinimumStartDateTime),
  }),
});

const handleCancel = (closePopover: () => void) => {
  form.resetForm({
    values: {
      start: definition.value.filters.dateRange?.from,
      end: definition.value.filters.dateRange?.to,
    },
  });
  closePopover();
};

const onSubmit = (closePopover: () => void) => {
  form.handleSubmit(() => {
    definition.value.filters.dateRange = new DateRangeDto({
      from: form.values.start,
      to: form.values.end,
    });
    closePopover();
  })();
};

const setValuesFromRecruitmentYearDates = (dates: RecruitmentYearDates) => {
  form.setValues({
    start: dates.start,
    end: dates.end,
  });
};

watch(
  () => definition.value.filters.dateRange,
  (value) => {
    form.setValues({
      start: value?.from,
      end: value?.to,
    });
  },
  { immediate: true },
);
</script>
