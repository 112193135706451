<template>
  <SlideOver
    v-model:visible="slideOverOpen"
    :title="texts.routes.settings.affiliates.edit"
  >
    <AffiliateForm
      :schools
      :initialValues="affiliate"
      @submit="updateAffiliate"
    >
      <template v-slot:actions>
        <ButtonSubmit :disabled="state === AsyncState.Loading">
          {{ texts.actions.create }}
        </ButtonSubmit>
        <ButtonCancel
          :disabled="state === AsyncState.Loading"
          @click="affiliate = undefined"
        />
      </template>
    </AffiliateForm>
  </SlideOver>
</template>

<script setup lang="ts">
import {
  IAffiliateDto,
  IPreEducationSchool,
  IUpdateAffiliateDto,
  UpdateAffiliateDto,
} from "@/lib/eduConfigurationServiceClient";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { computed } from "vue";
import AffiliateForm from "@/views/settings/organization/affiliates/AffiliateForm.vue";
import {
  AsyncState,
  useAsyncState,
} from "@/components/common/async/Async.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import texts from "@/utils/texts";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";

defineProps<{
  schools: IPreEducationSchool[];
}>();

const emit = defineEmits<{
  stale: [];
}>();

const affiliate = defineModel<IAffiliateDto>("affiliate");

const slideOverOpen = computed({
  get: () => !!affiliate.value,
  set: (value: boolean) => {
    if (!value) {
      affiliate.value = undefined;
    }
  },
});

const { state, handler: updateAffiliate } = useAsyncState(
  async (values: IUpdateAffiliateDto) => {
    if (!affiliate.value?.id) {
      return;
    }

    await eduConfigurationServiceClient
      .updateAffiliate(affiliate.value.id, new UpdateAffiliateDto(values))
      .then(() => {
        slideOverOpen.value = false;
        emit("stale");
      });
  },
  texts.notifications.edit,
);
</script>
