<template>
  <Page>
    <StylingPerActivityType
      v-if="publicPageSettings && fallbackSettings"
      :publicPageSettings="publicPageSettings"
      :fallbackSettings="fallbackSettings"
      @update:succeeded="reloadSettings"
    />
  </Page>
</template>

<script setup lang="ts">
import { useAsyncState } from "@/components/common/async/Async.types";
import {
  ApiException,
  PublicActivityPageSettingsDTO,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import StylingPerActivityType from "@/views/settings/organization/public-activity-page-settings/StylingPerActivityType.vue";
import { computed } from "vue";
import Page from "@/components/common/page/Page.vue";

const defaultFallbackSettings = new PublicActivityPageSettingsDTO({
  activityTypeId: undefined,
  color: "#ffffff",
  bannerUrl: undefined,
  gtmCode: undefined,
  localizations: [],
});

const { handler: reloadSettings, response: publicPageSettings } = useAsyncState(
  () =>
    eduConfigurationServiceClient
      .getPublicActivityPageSettings()
      .then((response) => {
        if (
          response.filter((settings) => !settings.activityTypeId).length === 0
        ) {
          return response.concat([defaultFallbackSettings]);
        }

        return response;
      })
      .catch((error) => {
        if (error instanceof ApiException && error.status == 404) {
          return [defaultFallbackSettings];
        } else {
          throw error;
        }
      }),
);

const fallbackSettings = computed(() =>
  publicPageSettings.value?.find((i) => i.activityTypeId == null),
);

reloadSettings();
</script>
