<template>
  <Loader v-if="!items" />
  <ComparableStatsChart
    v-else-if="items.length > 0"
    :items="items"
    :chartTitle="
      texts.navigationItems.organize.activity.reports.marketingOptInGraph.title
    "
  />
</template>

<script setup lang="ts">
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { ref } from "vue";
import ComparableStatsChart from "@/views/organize/activities/activity/hub/components/ComparableStatsChart.vue";
import { ComparableItem } from "@/views/organize/activities/activity/hub/components/ComparableStatsChart.vue";
import texts from "@/utils/texts";
import Loader from "@/components/common/loader/Loader.vue";

const props = defineProps<{ activityId: string }>();

const items = ref<ComparableItem[] | undefined>();
eduConfigurationServiceClient.getMarketingOptInStats(props.activityId).then(
  (response) =>
    (items.value = [
      {
        countsWithLegends: [
          {
            count: response.optInProspectsRegistrationCount,
            legend:
              texts.navigationItems.organize.activity.reports.registrantCount,
          },
          {
            count: response.optInProspectsVisitCount,
            legend:
              texts.navigationItems.organize.activity.reports.visitorCount,
          },
        ],
        label: texts.navigationItems.dashboard.uniqueProspectOptInCount,
      },
      {
        countsWithLegends: [
          {
            count: response.optOutProspectsRegistrationCount,
            legend:
              texts.navigationItems.organize.activity.reports.registrantCount,
          },
          {
            count: response.optOutProspectsVisitCount,
            legend:
              texts.navigationItems.organize.activity.reports.visitorCount,
          },
        ],
        label: texts.navigationItems.dashboard.uniqueProspectOptOutCount,
      },
    ]),
);
</script>
