<template>
  <DropdownWrapper
    :origin="DropdownOrigin.TopRight"
    :data-testid="testIds.actions"
  >
    <template v-slot:button>
      <MenuButton :data-testid="testIds.actions">
        <Icon icon="more_vert" :color="Color.Emerald"> </Icon>
      </MenuButton>
    </template>
    <template v-slot:items>
      <DropdownItem
        v-if="canViewPersonalData()"
        :data-testid="testIds.action.download"
        @click="emit('download')"
      >
        <div class="flex gap-5">
          <Icon icon="download" :color="Color.DeepTeal"></Icon>
          <span class="text-deepteal-500">{{ texts.actions.download }}</span>
        </div>
      </DropdownItem>
      <div v-if="canWrite">
        <Divider class="mx-2 my-1" />
        <DropdownItem :data-testid="testIds.action.copy" @click="emit('copy')">
          <div class="flex gap-5">
            <Icon icon="file_copy" :color="Color.DeepTeal"></Icon>
            <span class="text-deepteal-500">{{ texts.actions.copy }}</span>
          </div>
        </DropdownItem>
        <Divider class="mx-2 my-1" />
        <DropdownItem
          :data-testid="testIds.action.delete"
          @click="emit('delete')"
        >
          <div class="flex gap-5">
            <Icon icon="delete" :color="Color.Alert"></Icon>
            <span class="text-alert-500">{{ texts.actions.delete }}</span>
          </div>
        </DropdownItem>
      </div>
    </template>
  </DropdownWrapper>
</template>

<script setup lang="ts">
import Divider from "@/components/common/divider/Divider.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { Color } from "@/enums";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { MenuButton } from "@headlessui/vue";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import {
  canViewPersonalData,
  hasPermissions,
} from "@/router/helpers/userCanGoTo";

const emit = defineEmits<{
  download: [];
  copy: [];
  delete: [];
}>();

const canWrite = hasPermissions([
  Permission.ActivitiesWrite,
  Permission.SessionsWrite,
]);
</script>
