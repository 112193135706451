<template>
  <Page :loading="asyncState === 'loading'" class="flex flex-col gap-12">
    <Statistics :color="pageColor" />
    <LayoutTwoCol v-if="overviewItems && settings.studyPrograms">
      <template v-slot:left>
        <EnrollmentsFilter
          :studyPrograms="settings.allStudyPrograms"
          :studyProgramIds="studyProgramIdsInOverviewItems"
          :color="pageColor"
          @filter="filteredIds = $event"
        />
      </template>

      <div class="xl:col-span-9">
        <div class="flex flex-col gap-3">
          <div class="mb-5 flex items-center justify-between">
            <h3 class="text-xl font-semibold text-deepteal-500 xl:text-2xl">
              {{ filteredOverviewItems.length }}
              {{ texts.models.studyProgram.plural }}
            </h3>
            <div class="flex items-center gap-2">
              <Button
                v-if="canWrite"
                :leadingIcon="'note_add'"
                :size="ButtonSize.sm"
                :color="Color.White"
                :class="['border-none', 'shadow-sm', 'focus:none']"
                @click="showImport = true"
              >
                {{ componentTexts.importEnrollments }}
              </Button>
              <Button
                v-if="canWrite"
                :leadingIcon="'download'"
                :size="ButtonSize.sm"
                :color="Color.Gray"
                :label="componentTexts.download"
                :loading="asyncDownloadState === 'loading'"
                round
                flat
                @click="downloadEnrollments"
              />
            </div>
          </div>
        </div>
      </div>
      <List>
        <template v-slot:header>
          <ListItemColumn :flex="0"></ListItemColumn>
          <ListItemColumn :flex="6">{{
            texts.models.studyProgram.plural
          }}</ListItemColumn>
          <ListItemColumn hide class="justify-end">{{
            componentTexts.stats.enrollments
          }}</ListItemColumn>
        </template>
        <EnrollmentsItem
          v-for="item in filteredOverviewItems"
          :key="item.studyProgramId"
          :label="item.label"
          :count="item.count"
          :color="pageColor"
        />
      </List>
    </LayoutTwoCol>
  </Page>

  <EnrollmentsImport
    v-model:visible="showImport"
    @success="loadEnrollmentsOverview"
  />
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import Statistics from "@/views/applications-enrollments/enrollments/components/Statistics.vue";
import texts from "@/utils/texts";
import { computed, ref } from "vue";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import settings from "@/store/context/settings.context";
import { useAsyncState } from "@/components/common/async/Async.types";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import EnrollmentsItem from "@/views/applications-enrollments/enrollments/components/EnrollmentsItem.vue";
import EnrollmentsImport from "./components/EnrollmentsImport.vue";
import LayoutTwoCol from "@/components/common/layout/LayoutTwoCol.vue";
import EnrollmentsFilter from "@/views/applications-enrollments/enrollments/components/EnrollmentsFilter.vue";
import Button from "@/components/common/button/Button.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import { Color } from "@/enums";
import { downloadFile } from "@/utils/downloadFile";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import { hasPermissions } from "@/router/helpers/userCanGoTo";

const componentTexts = texts.navigationItems.enrollments;
const pageColor = Color.Emerald;

const canWrite = hasPermissions([Permission.EnrollmentsWrite]);

const {
  response: overviewItems,
  handler: loadEnrollmentsOverview,
  state: asyncState,
} = useAsyncState(async () => {
  const recruitmentYearDates = (
    await getRecruitmentYear()
  ).toCurrentRecruitmentYearDates();

  const response =
    await eduConfigurationServiceClient.getStudyProgramEnrollmentsOverview(
      recruitmentYearDates.start,
      recruitmentYearDates.end,
    );

  return response.map((item) => ({
    studyProgramId: item.studyProgramId,
    label:
      settings.allStudyPrograms.find((sp) => sp.id === item.studyProgramId)
        ?.displayName ?? texts.generic.unknown,
    count: item.prospectCount,
  }));
});
loadEnrollmentsOverview();

const filteredIds = ref<string[]>([]);
const filteredOverviewItems = computed(
  () =>
    overviewItems.value?.filter((item) =>
      filteredIds.value.includes(item.studyProgramId),
    ) ?? [],
);

const studyProgramIdsInOverviewItems = computed(
  () => overviewItems.value?.map((item) => item.studyProgramId) ?? [],
);

const { state: asyncDownloadState, handler: downloadEnrollments } =
  useAsyncState(async () => {
    downloadFile(await eduConfigurationServiceClient.exportEnrollments());
  });

// Import enrollments
const showImport = ref(false);
</script>
