<script setup lang="ts">
import env from "@/env";
import { clearAuthContext } from "@/store/context/auth.context";
import { clearTenantContext } from "@/store/context/tenant.context";
import { useAuth0 } from "@auth0/auth0-vue";

const { logout: auth0Logout } = useAuth0();

const logout = () => {
  clearAuthContext();
  clearTenantContext();
  auth0Logout({
    logoutParams: { returnTo: env.VITE_AUTH0_LOGOUT_URL },
  });
};

logout();
</script>
