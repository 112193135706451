<template>
  <Modal
    v-model:visible="visibleInternal"
    :type="ModalType.default"
    :title="title"
    :description="description"
    :centered="true"
    :size="ModalSize.Large"
  >
    <template v-slot:content>
      <div v-for="(group, i) in mailingTypeGroups" :key="i" class="mt-5">
        <h1>{{ group.header }}</h1>
        <GridListWithActions :actions="group.items"></GridListWithActions>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Modal from "@/components/common/modal/Modal.vue";
import { ModalSize, ModalType } from "../common/modal/Modal.types";
import { MailingTypeGroup } from "./MailingTypePicker.types";
import GridListWithActions from "../common/grid-list-with-actions/GridListWithActions.vue";

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    mailingTypeGroups: MailingTypeGroup[];
    visible?: boolean;
  }>(),
  {
    title: undefined,
    description: undefined,
    visible: false,
  },
);

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});
</script>
