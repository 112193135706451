export interface NavigationStep {
  title?: string;
  description?: string;
}

export interface NavigationStepProps extends NavigationStep {
  status?: NavigationStepStatus;
}

export interface NavigationStepsProps {
  steps: NavigationStep[];
  currentStepIndex: number;
}

export enum NavigationStepStatus {
  Complete = "complete",
  Current = "current",
  Upcoming = "upcoming",
}
