import Notify, { NotificationTexts } from "@/utils/notify";
import { ref } from "vue";

export enum AsyncState {
  Loading = "loading",
  Failure = "failure",
  Success = "success",
  Content = "content",
}

export interface Notifications {
  success?: NotificationTexts;
  failure?: NotificationTexts;
}

export function useAsyncState<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (...args: any[]) => Promise<T>,
  notifications?: Notifications,
  replaces?: string[],
) {
  const state = ref(AsyncState.Content);
  const response = ref<T>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handler = async (...args: any[]): Promise<T> => {
    state.value = AsyncState.Loading;

    try {
      response.value = await callback(...args);
      state.value = AsyncState.Success;
      if (notifications?.success) {
        Notify.success(notifications.success, replaces);
      }
      return response.value;
    } catch (e) {
      response.value = undefined;
      state.value = AsyncState.Failure;
      if (notifications?.failure) {
        Notify.failure(notifications.failure, replaces);
      }

      throw e;
    }
  };

  return {
    state,
    handler,
    response,
  };
}
