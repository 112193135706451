export interface FilterEntity {
  id: string;
  name: string;
}

export interface FilterProps {
  id: string;
  name: string;
  modelValue: boolean | null | string[];
  color?: string;
  count?: number;
}

export function compare(ids: string[] | undefined, id?: string) {
  return !ids || ids.length === 0 || (!!id && ids.includes(id));
}

export function compareArray<T>(ids: T[] | undefined, idArray: T[]) {
  return !ids || ids.length === 0 || idArray.some((id) => ids.includes(id));
}
