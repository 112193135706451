<template>
  <div
    class="flex flex-col justify-between gap-2 text-center md:w-auto md:flex-row md:items-center md:gap-4"
  >
    <span class="text-sm">
      {{ texts.actions.confirmDelete.text }}
    </span>
    <div
      class="grid grid-cols-1 justify-items-stretch gap-2 md:grid-cols-2 md:gap-4"
    >
      <ButtonCancel @click="() => emit('cancel')" />
      <Button
        :data-testid="testIds.action.confirm"
        :loading="loading"
        :color="Color.Alert"
        :buttonElementType="ButtonElementType.button"
        @click="() => emit('confirm')"
        >{{ texts.actions.confirmDelete.action }}</Button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { testIds } from "@/utils/testing";
import Button from "@/components/common/button/Button.vue";
import { ButtonElementType } from "@/components/common/button/Button.types";
import ButtonCancel from "./ButtonCancel.vue";
import texts from "@/utils/texts";
import { Color } from "@/enums";

defineProps<{ loading?: boolean }>();
const emit = defineEmits(["cancel", "confirm"]);
</script>
