<template>
  <FormLayout @submit="submitForm">
    <Alert
      v-if="!affiliates.length"
      :content="
        texts.navigationItems.organize.activity.affiliateLinks
          .noAffiliatePartners
      "
    />
    <FormFieldSingleSelectCombobox
      v-if="affiliates.length"
      :displayMode="FormFieldDisplayMode.Column"
      :formFieldId="fieldIds.affiliateId"
      :options="affiliates"
      :label="texts.models.affiliate.title"
      :formatOptionDisplayString="affiliateOptionFormatter"
      :searchFilter="
        (affiliate, query) =>
          affiliateOptionFormatter(affiliate)
            .toLocaleLowerCase()
            .includes(query.toLowerCase())
      "
      :disabled="isExistingLink()"
    />
    <FormFieldSingleSelectCombobox
      v-if="affiliates.length"
      :displayMode="FormFieldDisplayMode.Column"
      :formFieldId="fieldIds.registrationLinkId"
      :options="registrationLinks"
      :label="texts.models.registrationLink.title"
      :formatOptionDisplayString="(r) => r.name"
      :searchFilter="
        (registrationLink, query) =>
          registrationLink.name.toLowerCase().includes(query.toLowerCase())
      "
      :disabled="isExistingLink()"
    />

    <template v-slot:actions>
      <slot name="actions"></slot>
    </template>
  </FormLayout>
</template>
<script setup lang="ts">
import {
  IAffiliateDto,
  IAffiliateLinkDto,
  IPreEducationSchool,
  IRegistrationLinkWithRegistrationCountDTO,
} from "@/lib/eduConfigurationServiceClient";
import FormLayout from "@/components/common/form/FormLayout.vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { fields } from "@/utils/miscellaneous";
import FormFieldSingleSelectCombobox from "@/components/common/combobox/FormFieldSingleSelectCombobox.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import texts from "@/utils/texts";
import Alert from "@/components/common/alert/Alert.vue";

const props = defineProps<{
  affiliates: IAffiliateDto[];
  preEducationSchools: IPreEducationSchool[];
  registrationLinks: IRegistrationLinkWithRegistrationCountDTO[];
  initialValues?: IAffiliateLinkDto;
}>();

const emit = defineEmits<{
  submit: [values: IAffiliateLinkDto];
}>();

const fieldIds = fields<IAffiliateLinkDto>();

const form = useForm({
  initialValues: props.initialValues,
  validationSchema: yup.object({
    affiliateId: yup.string().required(),
    registrationLinkId: yup.string().required(),
  }),
});

const isExistingLink = () => props.initialValues != undefined;

const affiliateOptionFormatter = (affiliate: IAffiliateDto) =>
  `${affiliate.name} (${
    props.preEducationSchools.find(
      (s) => s.id === affiliate.preEducationSchoolId,
    )?.name
  })`;

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>
