<template>
  <template v-if="edit">
    <div>
      <Combobox
        :id="fieldId"
        v-model="value"
        :name="fieldId"
        :options="
          context.languages.map((language) => ({
            id: language,
            language: language,
          }))
        "
        :formatOptionDisplayString="optionFormatter"
        :searchFilter="searchFilter"
        :valid="!errorMessage"
      />
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <span>
      {{
        context.languages
          .filter((language) => criterion.languages?.includes(language))
          .map((language) => texts.enums.culture[language])
          .join(", ")
      }}
    </span>
  </template>
</template>

<script setup lang="ts">
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import texts from "@/utils/texts";
import { useField } from "vee-validate";
import { Culture } from "@/enums";
import * as yup from "yup";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import Combobox from "@/components/common/combobox/Combobox.vue";

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().languages;

const { value, errorMessage } = useField<Culture[] | undefined>(
  fieldId,
  yup.array().of(yup.mixed<Culture>()).min(1).required(),
  {
    initialValue: [...(props.criterion.languages ?? [])] as Culture[], // Shallow-copy to decouple field-array from the original
  },
);

const searchFilter = (item: { id: string; language: Culture }, query: string) =>
  texts.enums.culture[item.language]
    .toLowerCase()
    .includes(query.toLowerCase());

const optionFormatter = (item: { id: string; language: Culture }) =>
  texts.enums.culture[item.language];
</script>
