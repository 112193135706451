<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-12">
    <Statistics :color="pageColor" />
    <div v-if="sources" class="flex flex-col gap-3">
      <div class="flex items-center justify-between gap-4">
        <h3 class="mb-5 text-xl font-semibold text-deepteal-500 xl:text-2xl">
          {{ sources?.length }}
          {{ texts.models.thirdPartyProspectSources.plural }}
        </h3>
        <div class="flex items-center gap-3">
          <Button
            v-if="canWrite"
            :leadingIcon="'note_add'"
            :size="ButtonSize.sm"
            :color="Color.White"
            :class="['border-none', 'shadow-sm', 'focus:none']"
            @click="showImport = true"
          >
            {{ componentTexts.overview.importProspects }}
          </Button>
          <Button
            v-if="canWrite"
            :leadingIcon="'download'"
            :size="ButtonSize.sm"
            :color="Color.Gray"
            :label="componentTexts.overview.download"
            :loading="asyncDownloadState === 'loading'"
            round
            flat
            @click="downloadProspects"
          />
          <ButtonAdd
            v-if="canWrite"
            @click="slideOverSourceCreateOpen = true"
          />
        </div>
      </div>
      <span
        v-if="!loading && sources.length === 0"
        data-testid="no-sources-found-message"
        class="text-sm italic text-gray-500"
      >
        {{ componentTexts.overview.noResultsFound }}
      </span>
      <List>
        <template v-slot:header>
          <ListItemColumn :flex="0">
            <div class="h-6 w-6"></div>
          </ListItemColumn>
          <ListItemColumn :flex="8"></ListItemColumn>
          <ListItemColumn class="justify-end">
            {{ componentTexts.stats.prospects }}
          </ListItemColumn>
        </template>
        <ThirdPartyProspectSourceItem
          v-for="source in sources"
          :key="source.id"
          :item="source"
          :color="pageColor"
          @details="() => openUpdateSourceSlideOver(source)"
        />
      </List>
    </div>
  </Page>

  <SlideOverThirdPartyProspectSourceCreate
    v-model:visible="slideOverSourceCreateOpen"
    @create:succeeded="() => fetchOverview()"
  />

  <SlideOverThirdPartyProspectSourceUpdate
    v-model:visible="slideOverSourceUpdateOpen"
    :source="thirdPartySource"
    :loading="loading"
    @update:succeeded="() => fetchOverview()"
    @delete:succeeded="() => fetchOverview()"
  />

  <ThirdThirdPartyProspectsSourceImport
    v-model:visible="showImport"
    @import:success="() => fetchOverview()"
  />
</template>

<script setup lang="ts">
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import Page from "@/components/common/page/Page.vue";
import { ThirdPartyProspectSourceOverviewDto } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { useAsyncState } from "@/components/common/async/Async.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import texts from "@/utils/texts";
import { DateTime } from "luxon";
import { ref } from "vue";
import SlideOverThirdPartyProspectSourceCreate from "./components/SlideOverThirdPartyProspectSourceCreate.vue";
import SlideOverThirdPartyProspectSourceUpdate from "./components/SlideOverThirdPartyProspectSourceUpdate.vue";
import ThirdThirdPartyProspectsSourceImport from "./components/ThirdPartyProspectsSourceImport.vue";
import Statistics from "./components/Statistics.vue";
import ThirdPartyProspectSourceItem from "./components/ThirdPartyProspectSourceItem.vue";
import { Color } from "@/enums";
import Button from "@/components/common/button/Button.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import { downloadFile } from "@/utils/downloadFile";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import { hasPermissions } from "@/router/helpers/userCanGoTo";

const componentTexts = texts.navigationItems.thirdPartyProspectSources;

const loading = ref<boolean>(true);
const error = ref<boolean>(false);
const pageColor = Color.Sunflower;

const fromDate = ref<DateTime>(DateTime.now());
const toDate = ref<DateTime>(DateTime.now());

const canWrite = hasPermissions([Permission.ThirdpartyprospectsourcesWrite]);

getRecruitmentYear()
  .then((recruitmentYear) => {
    const currentRecruitmentYearDates =
      recruitmentYear.toCurrentRecruitmentYearDates();

    fromDate.value = currentRecruitmentYearDates.start;
    toDate.value = currentRecruitmentYearDates.end;
  })
  .then(() => {
    fetchOverview();
  })
  .catch((e) => {
    error.value = true;
    logger.error(e);
  })
  .finally(() => {
    loading.value = false;
  });

const sources = ref<ThirdPartyProspectSourceOverviewDto[]>([]);
const thirdPartySource = ref<ThirdPartyProspectSourceOverviewDto>();
const fetchOverview = () => {
  loading.value = true;

  eduConfigurationServiceClient
    .getThirdPartyProspectSourcesOverview(fromDate.value, toDate.value)
    .then((response) => {
      sources.value = response;
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

const slideOverSourceUpdateOpen = ref(false);
const openUpdateSourceSlideOver = (
  selectedSource: ThirdPartyProspectSourceOverviewDto,
) => {
  slideOverSourceUpdateOpen.value = true;
  thirdPartySource.value = selectedSource;
};

const slideOverSourceCreateOpen = ref(false);

// Import prospects
const showImport = ref(false);

const { state: asyncDownloadState, handler: downloadProspects } = useAsyncState(
  async () => {
    downloadFile(
      await eduConfigurationServiceClient.exportThirdPartyProspects(),
    );
  },
);
</script>
