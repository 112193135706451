<template>
  <ListItem
    :key="account.id"
    clickable
    data-testid="account-row"
    class="group/listitem"
    @click="emit('clicked:details', { id: account.id })"
  >
    <ListItemColumn :flex="0">
      <img class="h-10 w-10 rounded-full" :src="account.picture" alt="" />
    </ListItemColumn>
    <ListItemColumn :flex="2">
      <div>
        <div
          class="text-sm font-medium text-gray-900"
          data-testid="account-name"
        >
          {{ account.name }}
        </div>
        <div class="text-sm text-gray-500" data-testid="account-email">
          {{ account.email }}
        </div>
      </div>
    </ListItemColumn>
    <ListItemColumn :flex="1" hide>{{
      account.roles.join("; ")
    }}</ListItemColumn>
    <ListItemColumn :flex="1" hide>{{
      texts.enums.accountTypes[account.accountType]
    }}</ListItemColumn>
    <ListItemColumn :flex="1" hide
      ><Badge
        v-if="!account.isBlocked"
        data-testid="is-active-badge"
        :label="texts.status.active"
        :type="BadgeType.success"
      ></Badge>
      <Badge
        v-if="account.isBlocked"
        data-testid="is-blocked-badge"
        :label="texts.status.blocked"
        :type="BadgeType.danger"
      ></Badge
    ></ListItemColumn>
    <ListItemColumn :flex="0">
      <ListItemActions @click.stop>
        <DropdownItem
          :disabled="isAccountCurrentUser(account).value"
          @click="openBlockModal(account)"
        >
          <Icon icon="block" :color="Color.DeepTeal" />
          <span class="text-deepteal-500">{{
            account.isBlocked ? texts.actions.unblock : texts.actions.block
          }}</span>
        </DropdownItem>
        <Divider class="mx-2 my-1" />
        <DropdownItem
          :disabled="isAccountCurrentUser(account).value"
          @click="openDeleteModal(account.id)"
        >
          <Icon icon="delete" :color="Color.Alert"></Icon>
          <span class="text-alert-500">{{ texts.actions.delete }}</span>
        </DropdownItem>
      </ListItemActions>
    </ListItemColumn>
  </ListItem>
  <DeleteAccountModal v-if="deletingId" v-model:deletingId="deletingId" />
  <BlockAccountModal
    v-if="blockModalOpened && accountToBlock"
    v-model:visible="blockModalOpened"
    :account="accountToBlock"
  />
</template>

<script setup lang="ts">
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import Account from "@/models/account";
import { Color, Culture } from "@/enums";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import Badge from "@/components/common/badge/Badge.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import DeleteAccountModal from "@/views/settings/users/DeleteAccountModal.vue";
import BlockAccountModal from "@/views/settings/users/BlockAccountModal.vue";
import Divider from "@/components/common/divider/Divider.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import Icon from "@/components/common/icon/Icon.vue";
import auth from "@/store/context/auth.context";

const emit = defineEmits<{
  "clicked:details": [{ id: string }];
}>();

defineProps<{
  account: Account;
}>();

const isAccountCurrentUser = (account: Account) =>
  computed<boolean>(() => account.id === auth.sub);

const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const deletingId = ref<string | undefined>(undefined);
const openDeleteModal = (id: string) => {
  deletingId.value = id;
};

const accountToBlock = ref<Account | undefined>(undefined);
const blockModalOpened = ref(false);

const openBlockModal = (account: Account) => {
  blockModalOpened.value = true;
  accountToBlock.value = account;
};
</script>
