<template>
  <ListItem
    :data-testid="testIds.templates.formTemplateItem"
    :title="`${template.name}`"
    clickable
  >
    <RouterLinkAuth
      v-slot="{ navigate }"
      :to="{
        name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
        params: { id: template.id },
      }"
      custom
    >
      <ListItemColumn :flex="0" @click="navigate">
        <Icon icon="assignment"></Icon>
      </ListItemColumn>
      <ListItemColumn :flex="2" accent @click="navigate">
        <span class="truncate">{{ template.name }}</span>
      </ListItemColumn>
      <ListItemColumn hide @click="navigate">
        <Icon :icon="formTypeIcons[template.type]" />
        <span class="truncate">{{ texts.enums.formType[template.type] }}</span>
      </ListItemColumn>
    </RouterLinkAuth>
    <ListItemColumn :flex="0">
      <ListItemActions>
        <DropdownItem
          :data-testid="testIds.action.copy"
          @click="handleCopyClick"
        >
          <Icon icon="file_copy" :color="Color.DeepTeal"></Icon>
          <span class="text-deepteal-500">{{ texts.actions.copy }}</span>
        </DropdownItem>
        <Divider class="mx-2 my-1" />
        <DropdownItem
          :data-testid="testIds.action.delete"
          @click="deleteModalVisible = true"
        >
          <Icon icon="delete" :color="Color.Alert"></Icon>
          <span class="text-alert-500">{{ texts.actions.delete }}</span>
        </DropdownItem>
      </ListItemActions>
    </ListItemColumn>
  </ListItem>
  <FormTemplateDeleteModal
    v-model:visible="deleteModalVisible"
    :formTemplateId="template.id"
    @delete:succeeded="emit('delete:succeeded')"
  />
</template>

<script setup lang="ts">
import Icon from "@/components/common/icon/Icon.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { FormTemplate } from "@/models/template";
import { RouteNamesSettings } from "@/router/routeNames";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { Color } from "@/enums";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import FormTemplateDeleteModal from "@/views/settings/forms/components/FormTemplateDeleteModal.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { formsServiceClient } from "@/services/formsService.client.service";
import { ref } from "vue";
import Notify from "@/utils/notify";
import { useRouter } from "vue-router";
import logger from "@/plugins/logger";
import { formTypeIcons } from "@/views/settings/forms/FormExtensions";

const router = useRouter();
const props = defineProps<{
  template: FormTemplate;
}>();

const emit = defineEmits(["delete:succeeded"]);

const handleCopyClick = () => {
  formsServiceClient
    .copyForm(props.template.id)
    .then((response) => {
      Notify.success(texts.notifications.copy.success, [
        texts.models.formTemplate.title,
      ]);

      router.push({
        name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
        params: { id: response.id },
      });
    })
    .catch((error) => {
      logger.log(error);

      Notify.failure(texts.notifications.copy.failure, [
        texts.models.formTemplate.title,
      ]);
    });
};

const deleteModalVisible = ref<boolean>(false);
</script>
