<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-5">
    <PageHeading :title="settingsTexts.title" icon="language">
      <template v-slot:description>{{ settingsTexts.description }}</template>
    </PageHeading>
    <form class="flex flex-col gap-5" @submit="onSubmit">
      <div class="flex flex-col gap-2">
        <FormFieldArrayHelper
          v-slot="{ key, field }"
          :fieldId="areaOfInterestFieldId"
          :fields="areaOfInterestFields"
        >
          <FormFieldTextInput
            :id="`${key}.name`"
            :label="`${texts.models.studyProgramAreaOfInterest.title} (${
              texts.enums.culture[field.locale]
            })`"
            :displayMode="FormFieldDisplayMode.Row"
            :rules="yup.string().required()"
            :data-testid="`name_${field.locale}`"
          />
        </FormFieldArrayHelper>
        <SectionDivider class="mt-4" />
        <FormFieldArrayHelper
          v-slot="{ key, field }"
          :fieldId="departmentFieldId"
          :fields="departmentFields"
        >
          <FormFieldTextInput
            :id="`${key}.name`"
            :label="`${texts.models.studyProgramDepartment.title} (${
              texts.enums.culture[field.locale]
            })`"
            :displayMode="FormFieldDisplayMode.Row"
            :rules="yup.string().required()"
            :data-testid="`name_${field.locale}`"
          />
        </FormFieldArrayHelper>
        <SectionDivider class="mt-4" />
        <FormFieldArrayHelper
          v-slot="{ key, field }"
          :fieldId="levelFieldId"
          :fields="levelFields"
        >
          <FormFieldTextInput
            :id="`${key}.name`"
            :label="`${texts.models.studyProgramLevel.title} (${
              texts.enums.culture[field.locale]
            })`"
            :displayMode="FormFieldDisplayMode.Row"
            :rules="yup.string().required()"
            :data-testid="`name_${field.locale}`"
          />
        </FormFieldArrayHelper>
        <SectionDivider class="mt-4" />
        <FormFieldArrayHelper
          v-slot="{ key, field }"
          :fieldId="locationFieldId"
          :fields="locationFields"
        >
          <FormFieldTextInput
            :id="`${key}.name`"
            :label="`${texts.models.studyProgramLocation.title} (${
              texts.enums.culture[field.locale]
            })`"
            :displayMode="FormFieldDisplayMode.Row"
            :rules="yup.string().required()"
            :data-testid="`name_${field.locale}`"
          />
        </FormFieldArrayHelper>
        <SectionDivider class="mt-4" />
        <FormFieldArrayHelper
          v-slot="{ key, field }"
          :fieldId="modeFieldId"
          :fields="modeFields"
        >
          <FormFieldTextInput
            :id="`${key}.name`"
            :label="`${texts.models.studyProgramMode.title} (${
              texts.enums.culture[field.locale]
            })`"
            :displayMode="FormFieldDisplayMode.Row"
            :rules="yup.string().required()"
            :data-testid="`name_${field.locale}`"
          />
        </FormFieldArrayHelper>
      </div>
      <div class="flex justify-end">
        <ButtonSubmit :loading="loading" class="md:col-start-2" />
      </div>
    </form>
  </Page>
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import SectionDivider from "@/components/common/section/SectionDivider.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";

import { ref } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import * as yup from "yup";

import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { RootState } from "@/store/index";
import logger from "@/plugins/logger";

import {
  StudyProgramMetadataLocalizationFormValues,
  convertToDTO,
  convertToFormValues,
} from "@/views/settings/study-programs/study-program-settings/StudyProgramSettingsForm.types";
import Notify from "@/utils/notify";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { StudyProgramMetadataLocalizationDto } from "@/lib/eduConfigurationServiceClient";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import { useLocalizationFields } from "@/models/helpers/localizable";
import { fields } from "@/utils/miscellaneous";

// Store setup
const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];
const settingsTexts =
  texts.navigationItems.manage.settings.studyProgramSettings;

// Study Program Metadata Localizations
const loading = ref(true);
const error = ref(false);
const getLocalizations = () => {
  loading.value = true;

  eduConfigurationServiceClient
    .getStudyProgramMetaData()
    .then((response) => {
      const formValues = convertToFormValues(response);
      form.setValues(formValues);
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => (loading.value = false));
};
getLocalizations();

const form = useForm<StudyProgramMetadataLocalizationFormValues>();

const keys = fields<StudyProgramMetadataLocalizationFormValues>();

const { fieldId: areaOfInterestFieldId, fields: areaOfInterestFields } =
  useLocalizationFields(keys.areaOfInterestLocalizations);
const { fieldId: departmentFieldId, fields: departmentFields } =
  useLocalizationFields(keys.departmentLocalizations);
const { fieldId: levelFieldId, fields: levelFields } = useLocalizationFields(
  keys.levelLocalizations,
);
const { fieldId: locationFieldId, fields: locationFields } =
  useLocalizationFields(keys.locationLocalizations);
const { fieldId: modeFieldId, fields: modeFields } = useLocalizationFields(
  keys.modeLocalizations,
);

const onSubmit = form.handleSubmit((values) => {
  loading.value = true;

  const dto = convertToDTO(
    values as StudyProgramMetadataLocalizationFormValues,
  );

  eduConfigurationServiceClient
    .updateStudyProgramMetaData(new StudyProgramMetadataLocalizationDto(dto))
    .then(() => {
      location.reload();
    })
    .catch((e) => {
      Notify.failure(
        texts.navigationItems.manage.settings.studyProgramSettings.form.update
          .failure,
      );
      logger.error(e);
      loading.value = false;
    });
});
</script>
