<template>
  <div :class="[inputClass]">
    <input
      v-model="localValue"
      :class="[
        'w-full outline-none ring-0',
        disabled ? 'cursor-not-allowed bg-gray-100' : 'bg-white',
      ]"
      :placeholder="emailTexts.fromEmailAddresses.placeholder"
      :disabled="disabled"
    />
    <select
      v-model="currentDomain"
      :class="[
        'w-56 text-nowrap border-none py-0 pr-5 text-sm outline-none ring-0 focus:ring-0',
        disabled ? 'cursor-not-allowed bg-gray-100' : 'bg-white',
      ]"
      :disabled="disabled"
    >
      <option
        v-for="domain in domainItems"
        :key="domain.label"
        :value="domain.value"
      >
        {{ domain.label }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { SelectOption } from "@/components/common/select/SelectOption";
import texts from "@/utils/texts";
import { computed, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    modelValue?: string | undefined;
    domainItems: SelectOption[] | undefined;
    disabled?: boolean;
    valid?: boolean | undefined;
  }>(),
  {
    domainItems: undefined,
    valid: undefined,
    placeholder: undefined,
    modelValue: undefined,
  },
);

const emailTexts =
  texts.navigationItems.manage.settings.organization.emailAddresses;

const localValue = ref(undefined);

watch(
  () => localValue.value,
  (newValue) => {
    if (!newValue) {
      email.value = undefined;
    } else {
      email.value = `${newValue}@${currentDomain.value}`;
    }
  },
);

const currentDomain = ref(props.domainItems?.[0].value);

watch(
  () => currentDomain.value,
  (newValue) => {
    if (!localValue.value) {
      email.value = undefined;
    } else {
      email.value = `${localValue.value}@${newValue}`;
    }
  },
);

const email = defineModel<string>("email");

const displayInvalid = computed(() => props.valid === false);
const inputClass = computed(() => [
  "flex px-3 py-2 items-center w-full sm:text-sm rounded-md shadow-sm gap-1.5 border-solid border focus-within:ring-1",
  {
    "border-gray-300 text-gray-400 placeholder-gray-200 bg-gray-100":
      props.disabled && !displayInvalid.value,
    "border-alert-300 text-gray-400 placeholder-gray-200 bg-gray-100":
      props.disabled && displayInvalid.value,
    "border-gray-300 text-black placeholder-gray-400 focus-within:ring-emerald-500 focus-within:border-emerald-500":
      !props.disabled && !displayInvalid.value,
    "border-alert-300 text-alert-900 placeholder-alert-100 focus:outline-none focus-within:ring-alert-500 focus:border-alert-500":
      !props.disabled && displayInvalid.value,
  },
]);
</script>
