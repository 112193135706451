import { BadgeProps, BadgeType } from "@/components/common/badge/BadgeProps";
import { ApplicationDictionary } from "@/dictionary";
import { Localization } from "@/models/helpers/localizable";
import { getGuidColor } from "@/utils/color";
import { IShortMessageCampaign } from "@/lib/eduConfigurationServiceClient";
import { DateTime, Interval } from "luxon";

interface ActivityDTOLocalizableProperties {
  name: string;
  prospectAppDescription: string;
}
export interface ActivityDTO {
  id: string;
  activityTypeId: string;
  startDateTime: string;
  endDateTime: string;
  name?: string;
  status?: ActivityStatus;
  isOnline: boolean;
  onlineUrl?: string;
  capacity?: number;
  readonly forbidOverlappingSessionRegistrations: boolean;
  readonly forbidMultipleSessionRegistrationsPerStudyProgram: boolean;
  minimumSessionRegistrationsPerRegistrant?: number;
  maximumSessionRegistrationsPerRegistrant?: number;
  readonly allowGroupSize: boolean;
  studyProgramIds?: string[];
  readonly registrants: number;
  readonly visitors: number;
}

export interface ActivityExtendedDTO {
  id: string;
  startDateTime: string;
  endDateTime: string;
  name?: string;
  localizations: Localization<ActivityDTOLocalizableProperties>[];
  status?: ActivityStatus;
  typeId?: string;
  isOnline: boolean;
  onlineUrl?: string;
  capacity?: number;
  readonly forbidOverlappingSessionRegistrations: boolean;
  readonly forbidMultipleSessionRegistrationsPerStudyProgram: boolean;
  minimumSessionRegistrationsPerRegistrant?: number;
  maximumSessionRegistrationsPerRegistrant?: number;
  readonly allowGroupSize: boolean;
  maximumGroupSizePerRegistrant?: number;
  studyProgramIds?: string[];
  shortMessageCampaign?: IShortMessageCampaign;
}

export interface ActivityCreateDTO {
  startDateTime: string;
  endDateTime: string;
  localizations: Localization<ActivityDTOLocalizableProperties>[];
  typeId: string;
  isOnline: boolean;
  onlineUrl?: string;
  capacity?: number;
  studyProgramIds: string[];
  shortMessageCampaignValues?: {
    message: string;
    plannedDateTime: string;
  };
}

export interface ActivityUpdateDTO {
  id: string;
  startDateTime: string;
  endDateTime: string;
  localizations: Localization<ActivityDTOLocalizableProperties>[];
  typeId: string;
  isOnline: boolean;
  onlineUrl?: string;
  capacity?: number;
  shortMessageCampaignValues?: {
    message: string;
    plannedDateTime: string;
  };
}

export interface ActivityStudyProgramUpdateDTO {
  id: string;
  studyProgramIds: string[];
}

export enum ActivityStatus {
  finished = "Finished",
  inProgress = "InProgress",
  scheduled = "Scheduled",
}

export default class Activity {
  id: string;
  activityTypeId: string;
  startDateTime: DateTime;
  endDateTime: DateTime;
  name?: string;
  status?: ActivityStatus;
  isOnline: boolean;
  onlineUrl?: string;
  capacity?: number;
  readonly forbidOverlappingSessionRegistrations: boolean;
  readonly forbidMultipleSessionRegistrationsPerStudyProgram: boolean;
  minimumSessionRegistrationsPerRegistrant?: number;
  maximumSessionRegistrationsPerRegistrant?: number;
  readonly allowGroupSize: boolean;
  studyProgramIds?: string[];
  readonly registrants: number;
  readonly visitors: number;

  constructor(dto: ActivityDTO) {
    this.id = dto.id;
    this.activityTypeId = dto.activityTypeId;
    this.startDateTime = DateTime.fromISO(dto.startDateTime);
    this.endDateTime = DateTime.fromISO(dto.endDateTime);
    this.name = dto.name;
    this.status = dto.status;
    this.isOnline = dto.isOnline;
    this.onlineUrl = dto.onlineUrl;
    this.capacity = dto.capacity;
    this.forbidOverlappingSessionRegistrations =
      dto.forbidOverlappingSessionRegistrations;
    this.forbidMultipleSessionRegistrationsPerStudyProgram =
      dto.forbidMultipleSessionRegistrationsPerStudyProgram;
    this.minimumSessionRegistrationsPerRegistrant =
      dto.minimumSessionRegistrationsPerRegistrant;
    this.maximumSessionRegistrationsPerRegistrant =
      dto.maximumSessionRegistrationsPerRegistrant;
    this.allowGroupSize = dto.allowGroupSize;
    this.studyProgramIds = dto.studyProgramIds;
    this.registrants = dto.registrants;
    this.visitors = dto.visitors;
  }

  formatStartDateTime() {
    return formatDateTime(this.startDateTime);
  }

  formatEndDateTime() {
    return formatDateTime(this.endDateTime);
  }

  getStatusBadgeProps(texts: ApplicationDictionary) {
    return getStatusBadgeProps(texts, this.status);
  }

  dates(options?: { min?: DateTime; max?: DateTime }) {
    const start = options?.min
      ? DateTime.max(this.startDateTime, options.min)
      : this.startDateTime;
    const end = options?.max
      ? DateTime.min(this.endDateTime, options.max)
      : this.endDateTime;

    return Interval.fromDateTimes(start.startOf("day"), end.endOf("day"));
  }

  get typeColor() {
    return getGuidColor(this.activityTypeId);
  }

  isLongRunning() {
    const longRunningNumberOfDays = 7;
    return (
      this.endDateTime.diff(this.startDateTime, "days").days >
      longRunningNumberOfDays
    );
  }
}

export class ActivityExtended {
  id: string;
  startDateTime: DateTime;
  endDateTime: DateTime;
  name?: string;
  localizations: Localization<ActivityDTOLocalizableProperties>[];
  status?: ActivityStatus;
  typeId?: string;
  isOnline: boolean;
  onlineUrl?: string;
  capacity?: number;
  readonly forbidOverlappingSessionRegistrations: boolean;
  readonly forbidMultipleSessionRegistrationsPerStudyProgram: boolean;
  minimumSessionRegistrationsPerRegistrant?: number;
  maximumSessionRegistrationsPerRegistrant?: number;
  readonly allowGroupSize: boolean;
  studyProgramIds?: string[];
  shortMessageCampaign?: IShortMessageCampaign;

  constructor(dto: ActivityExtendedDTO) {
    this.id = dto.id;
    this.typeId = dto.typeId;
    this.startDateTime = DateTime.fromISO(dto.startDateTime);
    this.endDateTime = DateTime.fromISO(dto.endDateTime);
    this.name = dto.name;
    this.localizations = dto.localizations;
    this.status = dto.status;
    this.isOnline = dto.isOnline;
    this.onlineUrl = dto.onlineUrl;
    this.capacity = dto.capacity;
    this.forbidOverlappingSessionRegistrations =
      dto.forbidOverlappingSessionRegistrations;
    this.forbidMultipleSessionRegistrationsPerStudyProgram =
      dto.forbidMultipleSessionRegistrationsPerStudyProgram;
    this.minimumSessionRegistrationsPerRegistrant =
      dto.minimumSessionRegistrationsPerRegistrant;
    this.maximumSessionRegistrationsPerRegistrant =
      dto.maximumSessionRegistrationsPerRegistrant;
    this.allowGroupSize = dto.allowGroupSize;
    this.studyProgramIds = dto.studyProgramIds;
    this.shortMessageCampaign = dto.shortMessageCampaign;
  }

  formatStartDateTime() {
    return formatDateTime(this.startDateTime);
  }

  formatEndDateTime() {
    return formatDateTime(this.endDateTime);
  }

  getStatusBadgeProps(texts: ApplicationDictionary) {
    return getStatusBadgeProps(texts, this.status);
  }
}

function formatDateTime(date: DateTime) {
  return date.toFormat("d LLLL yyyy, HH:mm");
}

function getStatusBadgeProps(
  texts: ApplicationDictionary,
  status?: ActivityStatus,
): BadgeProps {
  switch (status) {
    case ActivityStatus.finished:
      return {
        type: BadgeType.default,
        label: texts.enums.activityStatus[ActivityStatus.finished],
      };
    case ActivityStatus.inProgress:
      return {
        type: BadgeType.success,
        label: texts.enums.activityStatus[ActivityStatus.inProgress],
      };
    case ActivityStatus.scheduled:
      return {
        type: BadgeType.info,
        label: texts.enums.activityStatus[ActivityStatus.scheduled],
      };
    default:
      throw new Error("ActivityStatus not found");
  }
}
