<template>
  <Modal
    :type="ModalType.warning"
    :visible="closeModalVisible"
    :title="texts.wizard.closeModal.title"
    :description="texts.wizard.closeModal.description"
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          data-testid="confirm-close"
          class="inline-flex w-full justify-center sm:ml-3 sm:w-auto"
          :color="Color.Warning"
          :label="texts.wizard.closeModal.confirmButton"
          @click="closeModal"
        />
        <Button
          data-testid="cancel-close"
          class="mt-3 inline-flex w-full justify-center sm:mt-0 sm:w-auto"
          :color="Color.White"
          :label="texts.wizard.closeModal.cancelButton"
          @click="closeModalVisible = false"
        />
      </div>
    </template>
  </Modal>
  <WizardPresentational
    :data="internalData"
    :contextData="contextData"
    :steps="steps"
    :stepHeightStyle="stepHeightStyle"
    :isNavigatingForward="isNavigatingForward"
    :isNavigatingBack="isNavigatingBack"
    :isSaving="isSaving"
    :currentStepIndex="currentStepIndex"
    :culture="culture"
    :nextDisabled="nextDisabled"
    :nextLoading="nextLoading"
    :previousDisabled="previousDisabled"
    :closeDisabled="closeDisabled"
    :saveDisabled="saveDisabled"
    :saveLoading="saveLoading"
    @next="next"
    @previous="previous"
    @save="save"
    @close="closeModalVisible = true"
    @forwardNavigationValidated="forwardNavigationValidated"
    @backNavigationValidated="backNavigationValidated"
    @saveValidated="saveValidated"
  >
    <template v-slot:header><slot name="header"></slot></template>
  </WizardPresentational>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import WizardPresentational from "@/components/common/wizard/WizardPresentational.vue";
import { WizardStep } from "@/components/common/wizard/Wizard.types";
import Modal from "@/components/common/modal/Modal.vue";
import Button from "@/components/common/button/Button.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import { Color, Culture } from "@/enums";
import { cloneDeep } from "lodash";
import dictionary from "@/dictionary";

const emit = defineEmits(["finish", "save", "close"]);

const props = withDefaults(
  defineProps<{
    steps: WizardStep[];
    stepHeightStyle?: string;
    culture?: Culture;
    data: Record<string, unknown>;
    contextData?: Record<string, unknown>;
    nextDisabled?: boolean;
    nextLoading?: boolean;
    previousDisabled?: boolean;
    closeDisabled?: boolean;
    saveDisabled?: boolean;
    saveLoading?: boolean;
  }>(),
  {
    stepHeightStyle: "calc(100vh - 300px)",
    culture: Culture.NL,
    nextDisabled: false,
    nextLoading: false,
    previousDisabled: false,
    closeDisabled: false,
    saveDisabled: false,
    saveLoading: false,
    contextData: undefined,
  },
);

// Translations
const texts = dictionary[props.culture];

const currentStepIndex = ref(0);
const isNavigatingForward = ref(false);
const isNavigatingBack = ref(false);
const isSaving = ref(false);

const next = () => {
  isNavigatingForward.value = true;
};

const previous = () => {
  isNavigatingBack.value = true;
};

const save = () => {
  isSaving.value = true;
};

const closeModalVisible = ref(false);
const closeModal = () => {
  closeModalVisible.value = false;
  emit("close");
};

const forwardNavigationValidated = (isValid: boolean) => {
  isNavigatingForward.value = false;

  if (!isValid) return;

  if (currentStepIndex.value < props.steps.length - 1) {
    currentStepIndex.value++;
  } else {
    // Emitted data needs to be cloned to prevent staying bound to the input fields in the wizard.
    emit("finish", cloneDeep(internalData.value));
  }
};

const backNavigationValidated = (isValid: boolean) => {
  isNavigatingBack.value = false;

  if (isValid && currentStepIndex.value > 0) {
    currentStepIndex.value--;
  }
};

const saveValidated = (isValid: boolean) => {
  isSaving.value = false;

  if (isValid) {
    // Emitted data needs to be cloned to prevent staying bound to the input fields in the wizard.
    emit("save", cloneDeep(internalData.value));
  }
};

// Initial data needs to be cloned, because...
//  when it originates from an input ref at the parent component
//  and we emit the same object which is then stored in another ref at the parent component
//  the two will be regarded as the same by Vue, updating the input value
const internalData = ref(cloneDeep(props.data));
watch(
  () => props.data,
  (newValue, oldValue) => {
    if (newValue === oldValue) return;

    currentStepIndex.value = 0;
    // Updated data needs to be cloned for the same reason as the initial data
    internalData.value = cloneDeep(newValue);
  },
);
</script>
