<template>
  <input
    v-show="focusInternal"
    :id="id"
    ref="inputElement"
    v-model="modelValueInternal"
    :name="name"
    :class="[
      'rounded-sm bg-transparent transition-all hover:placeholder:opacity-50 focus-visible:placeholder:opacity-50',
      'border-gray-300 text-black placeholder-gray-400 focus:border-emerald-500 focus:ring-emerald-500',
      {
        'outline-emerald-500 ring-emerald-500': !required || modelValueInternal,
        'outline-gray-200 ring-gray-200': required && !modelValueInternal,
      },
      css,
    ]"
    :placeholder="placeholder"
    @keydown.enter="focusInternal = false"
    @change="focusInternal = false"
    @blur="focusInternal = false"
  />
  <div
    v-show="!focusInternal"
    class="group inline-flex items-center gap-2"
    @click="focusInternal = true"
  >
    <span
      :class="[
        css,
        {
          'opacity-50': required && !modelValueInternal,
        },
      ]"
      >{{ modelValueInternal || placeholder }}</span
    >
    <Icon
      :class="[
        'cursor-pointer transition-opacity duration-300 lg:group-hover:opacity-100',
        {
          'lg:opacity-0': !!modelValueInternal,
          'lg:opacity-40': !modelValueInternal && !required,
          'lg:opacity-100':
            pencilAlwaysVisible || (!modelValueInternal && required),
        },
      ]"
      icon="edit"
      :size="IconSize.sm"
      :stroke="IconStroke.md"
      @click="focusInternal = true"
    ></Icon>
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize, IconStroke } from "../icon/Icon.types";
import { TextInputType } from "./TextInput.types";
import { computed, nextTick, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    id?: string;
    name?: string;
    modelValue?: string | number | undefined;
    type?: TextInputType;
    placeholder?: string;
    css?: string;
    focus?: boolean;
    required?: boolean;
    pencilAlwaysVisible?: boolean;
  }>(),
  {
    id: undefined,
    name: undefined,
    modelValue: undefined,
    type: TextInputType.TEXT,
    placeholder: undefined,
    focus: false,
    required: false,
    pencilAlwaysVisible: false,
    css: undefined,
  },
);

const inputElement = ref();
const emit = defineEmits<{
  (e: "update:modelValue", value: string | undefined | number): void;
  (e: "update:focus", value: boolean): void;
}>();

const modelValueInternal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value || undefined),
});

const focusInternal = computed({
  get: () => props.focus,
  set: (value) => emit("update:focus", value),
});

watch(
  focusInternal,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        const element = inputElement.value;
        if (element instanceof HTMLInputElement) {
          element.focus();
        }
      });
    }
  },
  { immediate: true },
);
</script>
