<template>
  <Page
    :loading="loading"
    :error="error"
    :data-testid="testIds.settings.activityTypes"
    class="flex flex-col gap-5"
  >
    <div class="flex items-center">
      <h2 class="flex-grow text-lg font-semibold text-deepteal-700">
        {{ texts.navigationItems.organize.activityTypes.title }}
      </h2>
      <ButtonAdd @click="slideOverCreateOpen = true"></ButtonAdd>
    </div>
    <Section>
      <span
        v-if="!loading && activityTypes.length === 0"
        data-testid="no-activity-types-found-message"
        class="text-sm italic text-gray-500"
        >{{
          texts.navigationItems.organize.activityTypes.overview.noResultsFound
        }}</span
      >
      <List v-if="activityTypes.length > 0">
        <template v-slot:header>
          <ListItemColumn>{{ texts.models.activityType.name }}</ListItemColumn>
        </template>
        <ListItem
          v-for="activityType in activityTypes"
          :key="activityType.name"
          clickable
          @click="openUpdateActivityTypeSlideOver(activityType.id)"
        >
          <ListItemColumn>{{ activityType.name }}</ListItemColumn>
          <ListItemColumn :flex="0">
            <ListItemActions @click.stop>
              <DropdownItem
                @click="openUpdateActivityTypeSlideOver(activityType.id)"
              >
                <Icon icon="edit" :color="Color.DeepTeal"></Icon>
                <span class="text-deepteal-500">{{ texts.actions.edit }}</span>
              </DropdownItem>
              <Divider class="mx-2 my-1" />
              <DropdownItem @click="openDeleteModal(activityType.id)">
                <Icon icon="delete" :color="Color.Alert"></Icon>
                <span class="text-alert-500">{{ texts.actions.delete }}</span>
              </DropdownItem>
            </ListItemActions>
          </ListItemColumn>
        </ListItem>
      </List>
    </Section>
  </Page>
  <!-- SlideOver panel with create ActivityType -->
  <SlideOver
    v-model:visible="slideOverCreateOpen"
    :title="texts.navigationItems.organize.activityTypes.create.title"
    :subTitle="texts.navigationItems.organize.activityTypes.create.subTitle"
    data-testid="create-slide-over"
  >
    <ActivityTypeForm @submit="submitCreate">
      <ButtonSubmit :loading="actionLoading"></ButtonSubmit>
      <ButtonCancel @click="cancelCreate"></ButtonCancel>
    </ActivityTypeForm>
  </SlideOver>

  <!-- SlideOver panel with edit ActivityType -->
  <SlideOver
    v-model:visible="slideOverUpdateOpen"
    :title="texts.navigationItems.organize.activityTypes.edit.title"
    :subTitle="texts.navigationItems.organize.activityTypes.edit.subTitle"
  >
    <Loader
      v-if="activityTypeLoading"
      data-testid="loading-activity-type"
    ></Loader>
    <ActivityTypeForm
      v-if="!activityTypeLoading && !!activityTypeFormValues"
      :formValues="activityTypeFormValues"
      @submit="submitUpdate"
    >
      <ButtonSubmit
        v-if="!deletingActivityType"
        :data-testid="testIds.action.submit"
        :loading="actionLoading"
      ></ButtonSubmit>
      <ButtonCancel
        v-if="!deletingActivityType"
        data-testid="cancel-update"
        :color="Color.White"
        @click="cancelUpdate"
      ></ButtonCancel>
      <SlideOverDeleteConfirm
        v-if="deletingActivityType"
        @confirm="submitDelete"
        @cancel="cancelDelete"
      ></SlideOverDeleteConfirm>
      <Button
        v-if="!deletingActivityType"
        :data-testid="testIds.action.delete"
        :color="Color.Alert"
        :label="texts.actions.delete"
        @click="deletingActivityType = true"
      ></Button>
    </ActivityTypeForm>
  </SlideOver>
  <DeleteActivityTypeModal
    v-if="activityTypeIdToDelete"
    :deletingId="activityTypeIdToDelete"
    @update:deletingId="deleteActivityTypeFinished"
  ></DeleteActivityTypeModal>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { Color, Culture } from "@/enums";
import { RootState } from "@/store";
import { useStore } from "vuex";
import dictionary from "@/dictionary";
import Section from "@/components/common/section/Section.vue";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import {
  createActivityType,
  getActivityTypes,
  getActivityType,
  updateActivityType,
  deleteActivityType,
} from "@/services/activityTypes.service";
import Loader from "@/components/common/loader/Loader.vue";
import Page from "@/components/common/page/Page.vue";
import logger from "@/plugins/logger";
import ActivityType, {
  ActivityTypeDTO,
  ActivityTypeCreateDTO,
  ActivityTypeExtended,
} from "@/models/activityType";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import ActivityTypeForm from "@/views/settings/organization/activity-types/ActivityTypeForm.vue";
import { ActivityTypeFormValues } from "@/views/settings/organization/activity-types/ActivityTypeForm.types";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import {
  convertToFormValues,
  convertToUpdateDto,
  convertToCreateDto,
} from "./ActivityTypeForm.types";
import settings, { loadActivityTypes } from "@/store/context/settings.context";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DeleteActivityTypeModal from "./DeleteActivityTypeModal.vue";
import Divider from "@/components/common/divider/Divider.vue";
import Icon from "@/components/common/icon/Icon.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import SlideOverDeleteConfirm from "@/components/common/slideover/SlideOverDeleteConfirm.vue";
import Button from "@/components/common/button/Button.vue";

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const loading = ref<boolean>(true);
const error = ref<boolean>(false);

// Get ActivityTypes
const activityTypes = ref<Array<ActivityTypeDTO>>([]);

const fetchActivityTypes = () => {
  getActivityTypes()
    .then((response) => {
      activityTypes.value = response.map(
        (activityTypeDto) => new ActivityType(activityTypeDto),
      );
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

fetchActivityTypes();

const actionLoading = ref<boolean>(false);

// Create Slide Over
const slideOverCreateOpen = ref<boolean>(false);

const cancelCreate = (): void => {
  slideOverCreateOpen.value = false;
};

const submitCreate = (values: ActivityTypeFormValues) => {
  actionLoading.value = true;

  const activityTypeCreateDTO: ActivityTypeCreateDTO =
    convertToCreateDto(values);

  return createActivityType(activityTypeCreateDTO)
    .then(() => loadActivityTypes())
    .then(() => {
      Notify.success(
        texts.navigationItems.organize.activityTypes.create.success,
      );
      fetchActivityTypes();
      slideOverCreateOpen.value = false;
    })
    .catch((e) => {
      Notify.failure(
        texts.navigationItems.organize.activityTypes.create.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

// Edit Slide Over
const slideOverUpdateOpen = ref<boolean>(false);
const activityTypeLoading = ref<boolean>(false);

const currentActivityType = ref<ActivityTypeExtended | undefined>(undefined);

const activityTypeFormValues = ref<ActivityTypeFormValues>();

const openUpdateActivityTypeSlideOver = async (activityTypeId: string) => {
  activityTypeLoading.value = true;
  slideOverUpdateOpen.value = true;

  getActivityType(activityTypeId)
    .then((response) => {
      currentActivityType.value = new ActivityTypeExtended(response);
      activityTypeFormValues.value = convertToFormValues(
        currentActivityType.value,
        settings.availableLanguages,
      );
    })
    .catch((e) => {
      slideOverUpdateOpen.value = false;
      Notify.failure(
        texts.navigationItems.organize.activityTypes.getActivityType.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      activityTypeLoading.value = false;
    });
};

const submitUpdate = (values: ActivityTypeFormValues) => {
  actionLoading.value = true;

  if (!currentActivityType.value) {
    throw new Error("Update activity type values lacks id");
  }

  const activityTypeDTO = convertToUpdateDto(
    currentActivityType.value.id,
    values,
  );

  return updateActivityType(activityTypeDTO)
    .then(() => loadActivityTypes())
    .then(() => {
      Notify.success(texts.navigationItems.organize.activityTypes.edit.success);
      fetchActivityTypes();
      slideOverUpdateOpen.value = false;
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.organize.activityTypes.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const cancelUpdate = (): void => {
  slideOverUpdateOpen.value = false;
};

// Deleting ActivityType
const deletingActivityType = ref<boolean>(false);

const submitDelete = () => {
  if (!currentActivityType.value) {
    throw new Error(`Cannot perform action. No activityType provided.`);
  }

  return deleteActivityType(currentActivityType.value.id)
    .then(() => loadActivityTypes())
    .then(() => {
      Notify.success(
        texts.navigationItems.organize.activityTypes.delete.success,
      );
      fetchActivityTypes();
      slideOverUpdateOpen.value = false;
    })
    .catch((e) => {
      Notify.failure(
        texts.navigationItems.organize.activityTypes.delete.failure,
      );
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
      deletingActivityType.value = false;
    });
};

const activityTypeIdToDelete = ref<string | undefined>(undefined);
// Delete ActivityType
const openDeleteModal = (activityTypeId: string) => {
  activityTypeIdToDelete.value = activityTypeId;
};

const cancelDelete = () => {
  deletingActivityType.value = false;
};

const deleteActivityTypeFinished = (activityTypeId: string | undefined) => {
  activityTypeIdToDelete.value = activityTypeId;
  return fetchActivityTypes();
};

// Reset state when closing updateSlideOver
watch(slideOverUpdateOpen, (newValue, oldValue) => {
  if (!newValue && oldValue) {
    cancelDelete();
  }
});
</script>
