<template>
  <DropdownWrapper :origin="DropdownOrigin.TopRight">
    <template v-slot:button>
      <ButtonGroupMenuButton
        :color="Color.Gray"
        :size="ButtonSize.sm"
        flat
        round
      >
        <Icon v-if="currentIcon" :icon="currentIcon" :size="IconSize.sm" />

        {{ label }}</ButtonGroupMenuButton
      >
    </template>
    <template v-slot:items>
      <DropdownItem
        v-for="item in items"
        :key="item.value"
        :icon="item.value ? formTypeIcons[item.value] : undefined"
        @click="modelValueInternal = item.value"
        >{{ item.label }}</DropdownItem
      >
    </template>
  </DropdownWrapper>
</template>

<script setup lang="ts">
import { ButtonSize } from "@/components/common/button/Button.types";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import { Color, FormType } from "@/enums";
import texts from "@/utils/texts";
import { formTypeIcons } from "@/views/settings/forms/FormExtensions";
import { computed } from "vue";

const props = defineProps<{ modelValue: FormType | undefined }>();

const modelValueInternal = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});

const emit = defineEmits<{
  "update:modelValue": [value: FormType | undefined];
}>();

const label = computed(
  () => items.find((item) => item?.value === modelValueInternal.value)?.label,
);

const items: { label: string; value?: FormType }[] = [
  {
    label: texts.navigationItems.manage.settings.templates.typeFilterAll,
    value: undefined,
  },
  ...Object.values(FormType)
    .filter((type) => type !== FormType.ActivityVisitedSurvey)
    .map((type) => ({
      label: texts.enums.formType[type],
      value: type,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)),
];

const currentIcon = computed(() =>
  modelValueInternal.value
    ? formTypeIcons[modelValueInternal.value]
    : undefined,
);
</script>
