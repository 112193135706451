<template>
  <FormLayout @submit.prevent="submitForm">
    <FormFieldEmail
      v-if="fromForm"
      :id="'email'"
      :domainItems="domainItems"
      :disabled="domainItems ? false : true"
      :label="'Email'"
    ></FormFieldEmail>
    <FormFieldTextInput
      v-else
      :id="'email'"
      :type="TextInputType.EMAIL"
      :label="'Email'"
    >
    </FormFieldTextInput>
    <span v-if="fromForm && !domainItems" class="text-sm text-alert-500">{{
      emailTexts.fromEmailAddresses.noDomains
    }}</span>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import * as yup from "yup";
import FormLayout from "@/components/common/form/FormLayout.vue";
import { FromEmailFormValues } from "./FromEmailForm.types";
import { SelectOption } from "@/components/common/select/SelectOption";
import FormFieldEmail from "@/components/common/email/FormFieldEmailDomainPicker.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import texts from "@/utils/texts";

const emailTexts =
  texts.navigationItems.manage.settings.organization.emailAddresses;

withDefaults(
  defineProps<{
    domainItems?: SelectOption[];
    fromForm?: boolean;
  }>(),
  {
    domainItems: undefined,
    fromForm: false,
  },
);

const form = useForm<{ email: string }>({
  validationSchema: yup.object({
    email: yup.string().required(),
  }),
});

const emit = defineEmits<{
  (e: "submit", value: FromEmailFormValues): void;
}>();

const submitForm = form.handleSubmit((value) => emit("submit", value));
</script>
