<template>
  <Card
    :size="size"
    :colorIndicator="!chart ? color : undefined"
    :color="cardBgColor"
    :dark
    :class="['flex items-start']"
    :image="chart ? cardChartSvg : undefined"
  >
    <div :class="['flex flex-1 flex-col gap-2']">
      <div>
        <Counter
          :class="[
            getTextColor(mainStatColor),
            {
              'text-xl font-semibold': CardSize.xs === size,
              'text-2xl font-medium': CardSize.sm === size,
              'text-3xl font-medium': CardSize.md === size,
              'text-4xl font-normal': CardSize.lg === size,
              'text-5xl font-normal': CardSize.xl === size,
            },
          ]"
          :value="value"
          :suffix="type === 'percentage' ? '%' : undefined"
        />
        <template v-if="value && max">
          <span
            :class="[
              getTextColor(mainStatColor),
              {
                'font-medium opacity-100': value >= max,
                'font-medium opacity-60': value < max,
              },
              value >= max && {
                'text-lg': CardSize.xs === size,
                'text-xl': CardSize.sm === size,
                'text-2xl': CardSize.md === size,
                'text-3xl': CardSize.lg === size,
                'text-4xl': CardSize.xl === size,
              },
              value < max && {
                'text-sm': CardSize.xs === size,
                'text-base': CardSize.sm === size,
                'text-lg': CardSize.md === size,
                'text-xl': CardSize.lg === size,
                'text-2xl': CardSize.xl === size,
              },
            ]"
            >/{{ max }}</span
          >
        </template>
      </div>
      <CardHeader :size="size" :text="header"><slot></slot></CardHeader>
    </div>
    <DropdownWrapper
      v-if="$slots.items"
      :origin="DropdownOrigin.TopRight"
      class="self-start"
    >
      <template v-slot:button>
        <button>
          <MenuButton class="print:hidden"
            ><Icon icon="more_vert" :color="mainStatColor" clickable></Icon
          ></MenuButton>
        </button>
      </template>
      <template v-slot:items><slot name="items"></slot></template>
    </DropdownWrapper>
  </Card>
</template>

<script setup lang="ts">
import Card from "@/components/common/card/Card.vue";
import { Color } from "@/enums";
import { getForegroundColor, getTextColor } from "@/utils/color";
import { CardSize } from "@/components/common/card/Card.types";
import Icon from "@/components/common/icon/Icon.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import Counter from "@/components/counter/Counter.vue";
import { MenuButton } from "@headlessui/vue";
import { DropdownOrigin } from "../dropdown/Dropdown.types";
import { computed } from "vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import cardChartSvg from "@/assets/images/card-chart.svg";

const props = withDefaults(
  defineProps<{
    value?: number;
    max?: number;
    color?: Color;
    size?: CardSize;
    type?: "default" | "percentage";
    dark?: boolean;
    chart?: boolean;
    header: string;
  }>(),
  {
    value: undefined,
    max: undefined,
    color: undefined,
    size: CardSize.md,
    type: "default",
    dark: false,
    chart: false,
  },
);

const cardBgColor = computed(() => {
  return props.dark ? props.color : Color.White;
});

const mainStatColor = computed(() => {
  return props.dark ? getForegroundColor(cardBgColor.value) : props.color;
});
</script>
