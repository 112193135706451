<template>
  <Page
    :loading="loadingPage"
    :error="errorLoadingData"
    class="flex flex-col gap-8"
  >
    <div class="grid grid-cols-1 items-center gap-4 pt-8 xl:grid-cols-4">
      <div>
        <Search v-model="search" :placeholder="texts.actions.search" />
      </div>

      <div class="flex items-center gap-4 xl:col-start-4 xl:justify-self-end">
        <div class="flex items-center gap-2">
          <Checkbox
            id="blocked-accounts"
            v-model="includeBlockedAccounts"
            data-testid="blocked-accounts"
          />
          <label
            for="blocked-accounts"
            class="text-sm font-medium text-gray-500"
            >{{ texts.navigationItems.accounts.includeBlockedAccounts }}</label
          >
        </div>
        <ButtonAdd @click="goToCreateAccountPage"></ButtonAdd>
      </div>
    </div>

    <List>
      <template v-slot:header>
        <ListItemColumn :flex="0"><div class="w-10"></div></ListItemColumn>
        <ListItemColumn :flex="2">{{
          texts.navigationItems.accounts.accountTable.headers.account
        }}</ListItemColumn>
        <ListItemColumn :flex="1" hide>{{
          texts.navigationItems.accounts.accountTable.headers.role
        }}</ListItemColumn>
        <ListItemColumn :flex="1" hide>{{
          texts.navigationItems.accounts.accountTable.headers.accountType
        }}</ListItemColumn>
        <ListItemColumn :flex="1" hide>{{
          texts.navigationItems.accounts.accountTable.headers.status
        }}</ListItemColumn>
        <ListItemColumn :flex="0"><div class="w-6"></div></ListItemColumn>
      </template>
      <AccountListItem
        v-for="account in accounts"
        :key="account.id"
        :account="account"
        @clicked:details="($event) => emit('clicked:details', $event)"
      />
    </List>
  </Page>
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import Search from "@/components/common/search/Search.vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { computed } from "vue";
import Account from "@/models/account";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import AccountListItem from "@/views/settings/users/AccountListItem.vue";

const emit = defineEmits<{
  "clicked:create": [];
  "clicked:details": [{ id: string }];
}>();

const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

// Accounts data
store.dispatch("accountStore/loadAccounts");

const accounts = computed<Account[]>(() =>
  store.getters["accountStore/accounts"].filter(
    (account: Account) =>
      (search.value == null ||
        account.name.toLowerCase().indexOf(search.value.toLowerCase()) >= 0 ||
        account.email.toLowerCase().indexOf(search.value.toLowerCase()) >= 0) &&
      (includeBlockedAccounts.value || !account.isBlocked),
  ),
);

const loadingAccounts = computed<boolean>(
  () => store.getters["accountStore/loadingAccounts"],
);

const errorLoadingAccounts = computed<boolean>(
  () => store.getters["accountStore/errorLoadingAccounts"],
);

const goToCreateAccountPage = () => {
  emit("clicked:create");
  // router.push({ name: RouteNamesSettings.ACCOUNT_CREATE });
};

// Loading
const loadingPage = computed<boolean>(() => loadingAccounts.value);

// Errors
const errorLoadingData = computed<boolean>(() => errorLoadingAccounts.value);

// Filters
const search = computed<string | undefined>({
  get: () => store.getters["accountStore/search"] || undefined,
  set: (val) => {
    store.commit("accountStore/setSearch", val || null);
  },
});

const includeBlockedAccounts = computed<boolean>({
  get: () => store.getters["accountStore/includeBlockedAccounts"],
  set: (val) => {
    store.commit("accountStore/setIncludeBlockedAccounts", val);
  },
});
</script>
