<template>
  <FormLayout @submit="submit">
    <FormFieldArrayHelper
      v-slot="{ field, key }"
      :fieldId="fieldId"
      :fields="localizations"
    >
      <FormFieldTextInput
        :id="`${key}.name`"
        :label="`${texts.models.activity.name} (${
          texts.enums.culture[field.locale]
        })`"
        :displayMode="FormFieldDisplayMode.Row"
        :data-testid="`name_${field.locale}`"
      />
    </FormFieldArrayHelper>
    <FormFieldTextInput
      id="sequenceNumber"
      :label="textsView.form.sequenceNumber"
      :displayMode="FormFieldDisplayMode.Row"
      :type="TextInputType.NUMBER"
    />
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldArrayHelper from "@/components/common/form/FormFieldArrayHelper.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import {
  normalizeLocalizations,
  useLocalizationFields,
} from "@/models/helpers/localizable";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { MetadataFormValues } from "./MetadataForm.types";
import { Culture } from "@/enums";
import FormLayout from "@/components/common/form/FormLayout.vue";

const props = defineProps<{
  formValues?: MetadataFormValues;
  loading?: boolean;
}>();

const emit = defineEmits<{
  submit: [MetadataFormValues];
}>();

const textsView = texts.navigationItems.manage.settings.studyProgramSettings;

const form = useForm<MetadataFormValues>({
  validationSchema: yup.object({
    localizations: yup.array().of(
      yup.object({
        locale: yup.mixed<Culture>().required(),
        name: yup.string().max(256).requiredWhenMainLocale(),
      }),
    ),
    sequenceNumber: yup.number(),
  }),
});

const { fieldId, fields: localizations } = useLocalizationFields();

watch(
  () => props.formValues,
  (values) => {
    const localizations = normalizeLocalizations(values?.localizations);
    form.setValues({ ...values, localizations });
  },
  { immediate: true },
);

const submit = form.handleSubmit((values) => emit("submit", values));
</script>
