<template>
  <div class="flex flex-col gap-5">
    <div class="flex flex-col gap-4 rounded bg-white p-4 shadow-sm">
      <div class="flex items-center justify-between gap-1.5">
        <h2 class="text-base font-semibold">
          {{ texts.navigationItems.applications.filter.title }}
        </h2>
        <span class="cursor-pointer text-xs underline" @click="resetFilters">{{
          texts.actions.reset
        }}</span>
      </div>
      <StudyProgramFilter
        v-if="studyPrograms"
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        @filter="(values) => (filteredStudyPrograms = values)"
      />
      <Divider />
      <StudyProgramMetadataFilter
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        @filter="(values) => (filteredStudyPrograms = values)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Divider from "@/components/common/divider/Divider.vue";
import StudyProgramFilter from "@/components/study-programs/StudyProgramFilter.vue";
import StudyProgramMetadataFilter from "@/components/study-programs/StudyProgramMetadataFilter.vue";
import { SessionStorageKey } from "@/enums";
import texts from "@/utils/texts";
import { ref, watch } from "vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";

defineProps<{
  studyPrograms: LocalizedStudyProgramDTO[];
}>();

const search = ref<string | undefined>(
  sessionStorage.getItem(SessionStorageKey.ApplicationsSearchTerm) || undefined,
);

watch(search, (newValue) =>
  newValue
    ? sessionStorage.setItem(SessionStorageKey.ApplicationsSearchTerm, newValue)
    : sessionStorage.removeItem(SessionStorageKey.ApplicationsSearchTerm),
);

const filteredStudyPrograms = defineModel<string[] | undefined>(
  "filteredStudyPrograms",
);

const resetStudyProgramFilter = ref(false);
const resetFilters = () => {
  resetStudyProgramFilter.value = !resetStudyProgramFilter.value;
};
</script>
