import { GridListColorCss } from "@/components/common/grid-list-with-actions/GridListWithActions.types";
import {
  MailingTypeGroup,
  MailingTypeItem,
} from "@/components/mailing-type-picker/MailingTypePicker.types";
import { Culture, MailingType as MailingTypeEnum } from "@/enums";
import { MailingType } from "@/models/mailingType";
import { RouteNamesMailings } from "@/router/routeNames";
import dictionary from "@/dictionary";
import { ActivityRelatedMailingOverviewDTO } from "@/lib/eduConfigurationServiceClient";

export const toMailingTypeGroups = (
  types: MailingType[],
  culture: Culture,
  useDashboardSelection: boolean,
): MailingTypeGroup[] => {
  const items = types.map((type) =>
    toMailingTypeItem(type, culture, useDashboardSelection),
  );
  return groupItems(items, culture);
};

const toMailingTypeItem = (
  type: MailingType,
  culture: Culture,
  useDashboardSelection: boolean,
): MailingTypeItem => {
  const baseItem = {
    title:
      dictionary[culture].navigationItems.mailings.mailingTypePicker
        .mailingTypes[type.mailingType].title,
    description:
      dictionary[culture].navigationItems.mailings.mailingTypePicker
        .mailingTypes[type.mailingType].description,
    mailingType: type,
  };

  switch (type.mailingType) {
    case MailingTypeEnum.General:
      return {
        ...baseItem,
        color: GridListColorCss.Indigo,
        icon: "newspaper",
        toRoute: {
          name: RouteNamesMailings.CREATE_GENERAL,
          query: {
            useDashboardSelection: useDashboardSelection ? "true" : undefined,
          },
        },
      };
    case MailingTypeEnum.Activity:
      return {
        ...baseItem,
        color: GridListColorCss.Green,
        icon: "notifications",
        toRoute: {
          name: RouteNamesMailings.CREATE_INFORMATION,
          query: {
            useDashboardSelection: useDashboardSelection ? "true" : undefined,
          },
        },
      };
    case MailingTypeEnum.ActivityReminder:
      return {
        ...baseItem,
        color: GridListColorCss.Blue,
        icon: "qr_code_2",
        toRoute: {
          name: RouteNamesMailings.CREATE_ACTIVITY_REMINDER,
          query: {
            useDashboardSelection: useDashboardSelection ? "true" : undefined,
          },
        },
      };
    case MailingTypeEnum.ActivityInvite:
      return {
        ...baseItem,
        color: GridListColorCss.Purple,
        icon: "hotel_class",
        toRoute: {
          name: RouteNamesMailings.CREATE_ACTIVITY_INVITE,
          query: {
            useDashboardSelection: useDashboardSelection ? "true" : undefined,
          },
        },
      };
    case MailingTypeEnum.ActivityVisitedSurvey:
      return {
        ...baseItem,
        color: GridListColorCss.Yellow,
        icon: "thumb_up",
        toRoute: {
          name: RouteNamesMailings.CREATE_ACTIVITY_VISITED_SURVEY,
          query: {
            useDashboardSelection: useDashboardSelection ? "true" : undefined,
          },
        },
      };
    default:
      throw new Error(
        `Mapping mailing type ${type.mailingType} to MailingTypeItem has not been implemented yet.`,
      );
  }
};

const groupItems = (
  items: MailingTypeItem[],
  culture: Culture,
): MailingTypeGroup[] => {
  const withActivity: MailingTypeGroup = {
    header:
      dictionary[culture].navigationItems.mailings.mailingTypePicker
        .mailingTypeGroups.withActivity,
    items: [],
  };
  const withoutActivity: MailingTypeGroup = {
    header:
      dictionary[culture].navigationItems.mailings.mailingTypePicker
        .mailingTypeGroups.withoutActivity,
    items: [],
  };

  items.forEach((item) => {
    if (
      [
        MailingTypeEnum.Activity,
        MailingTypeEnum.ActivityReminder,
        MailingTypeEnum.ActivityInvite,
        MailingTypeEnum.ActivityVisitedSurvey,
      ].includes(item.mailingType.mailingType)
    ) {
      withActivity.items.push(item);
    } else {
      withoutActivity.items.push(item);
    }
  });

  return [withActivity, withoutActivity].filter(
    (group) => group.items.length > 0,
  );
};

export const plannedSortFn = (
  left: ActivityRelatedMailingOverviewDTO,
  right: ActivityRelatedMailingOverviewDTO,
) => {
  return left.plannedDateTime &&
    right.plannedDateTime &&
    left.plannedDateTime < right.plannedDateTime
    ? -1
    : 1;
};

export const sentSortFn = (
  left: ActivityRelatedMailingOverviewDTO,
  right: ActivityRelatedMailingOverviewDTO,
) => {
  return left.sentDateTime &&
    right.sentDateTime &&
    left.sentDateTime < right.sentDateTime
    ? 1
    : -1;
};

export const conceptSortFn = (
  left: ActivityRelatedMailingOverviewDTO,
  right: ActivityRelatedMailingOverviewDTO,
) => {
  if (!left.modifiedDateTime) return 1;
  if (!right.modifiedDateTime) return -1;

  return left.modifiedDateTime < right.modifiedDateTime ? 1 : -1;
};
