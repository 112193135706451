<template>
  <div
    :class="
      $attrs.class ??
      'mb-5 grid grid-cols-1 gap-1 sm:grid-cols-3 sm:items-center sm:gap-x-4'
    "
  >
    <label :for="formFieldId" class="block text-sm font-medium text-gray-700">{{
      label
    }}</label>

    <div>
      <span class="text-sm text-gray-500"
        ><span data-testid="study-programs-count">{{
          value ? value.length : 0
        }}</span>
        {{ selectedLabel }}</span
      >
      <Button
        :data-testid="testIds.action.submit"
        class="ml-3"
        :label="texts.actions.addOrUpdate"
        @click="
          {
            showStudyProgramPicker = true;
          }
        "
      >
      </Button>
    </div>

    <SlideOver
      v-model:visible="showStudyProgramPicker"
      :title="slideOverTitle"
      :subTitle="slideOverSubTitle"
    >
      <SectionFlex class="px-5 pb-5">
        <StudyProgramPicker
          v-model:selectedIds="selectedIds"
          :studyPrograms="studyPrograms"
        />
        <template v-slot:footer>
          <div class="flex flex-col gap-2 sm:flex-row sm:items-center">
            <ButtonSubmit @click="submit">{{
              texts.actions.select
            }}</ButtonSubmit>
            <ButtonCancel @click="cancel" />
          </div>
        </template>
      </SectionFlex>
    </SlideOver>
    <div
      v-if="errorMessage"
      class="p-3 text-sm text-alert-500 sm:col-start-2"
      data-testid="error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { testIds } from "@/utils/testing";
import StudyProgramPicker from "@/components/study-programs/study-program-picker/StudyProgramPicker.vue";
import Button from "@/components/common/button/Button.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { useField } from "vee-validate";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import SectionFlex from "@/components/common/section/SectionFlex.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";

const props = defineProps<{
  formFieldId: string;
  label?: string;
  studyPrograms: LocalizedStudyProgramDTO[];
  selectedLabel?: string;
  slideOverTitle?: string;
  slideOverSubTitle?: string;
}>();

const selectedIds = ref<string[]>();

const submit = () => {
  value.value = selectedIds.value;
  closeSlideOver();
};

const cancel = () => {
  closeSlideOver();
};

const { errorMessage, value } = useField<string[] | undefined>(
  props.formFieldId,
);

const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const showStudyProgramPicker = ref(false);
const closeSlideOver = () => (showStudyProgramPicker.value = false);

watch(
  () => value.value,
  (val) => {
    selectedIds.value = val;
  },
  { immediate: true },
);
</script>
