<template>
  <li
    class="align-center group grid grid-cols-12 divide-x rounded-lg bg-white py-1 text-base font-medium text-deepteal-600 shadow hover:bg-gray-100"
  >
    <div
      class="col-span-12 flex flex-1 items-end justify-between gap-2 border-l-3 px-2 py-2 md:col-span-9 md:px-5"
      :style="{
        'border-left-color': activity.typeColor,
      }"
    >
      <RouterLinkAuth
        class="flex w-full items-end justify-between"
        :to="{ name: RouteNames.ACTIVITY_EDIT, params: { id: activity.id } }"
      >
        <div class="flex flex-col items-stretch gap-2 md:gap-4">
          <span class="text-xs">
            {{ currentDateIntervalString }}
          </span>
          <div class="flex items-center gap-2">
            <span
              class="max-w-xl truncate text-lg font-medium leading-tight"
              :title="activity.name"
            >
              {{ activity.name }}
            </span>
            <div
              v-if="activity.isOnline"
              class="flex items-center gap-1 rounded-full bg-lavender-100 px-2 py-1 leading-none text-lavender-700"
            >
              <Icon icon="wifi" :size="IconSize.xs" />
              <span class="text-sm font-medium">
                {{ texts.navigationItems.organize.activity.form.isOnline }}
              </span>
            </div>
          </div>
        </div>
        <ActivityStatus v-if="activity.status" :status="activity.status" />
      </RouterLinkAuth>
    </div>
    <div
      class="hidden items-center px-2 py-2 text-lg font-semibold md:col-span-2 md:flex md:px-5"
    >
      <RouterLinkAuth
        class="flex w-full items-end justify-between"
        :to="{ name: RouteNames.ACTIVITY_EDIT, params: { id: activity.id } }"
      >
        <div class="mx-auto flex gap-1 text-sm">
          <span
            v-if="activity.registrants > 0"
            :class="['font-semibold', 'text-lavender-600']"
          >
            {{ activity.registrants }}
          </span>
          <span
            v-if="activity.registrants > 0 && activity.visitors > 0"
            class="text-gray-400"
            >/</span
          >
          <span
            v-if="activity.visitors > 0"
            :class="['font-semibold', 'text-emerald-600']"
          >
            {{ activity.visitors }}
          </span>
        </div>
      </RouterLinkAuth>
    </div>
    <div
      class="hidden cursor-pointer items-center justify-center px-2 py-2 md:col-span-1 md:flex md:px-5"
    >
      <DropdownWrapper :origin="DropdownOrigin.TopRight" class="h-6">
        <template v-slot:button>
          <MenuButton :data-testid="testIds.actions">
            <Icon :size="IconSize.lg" icon="more_vert" :color="Color.Emerald">
            </Icon>
          </MenuButton>
        </template>
        <template v-slot:items>
          <DropdownItem @click="handleCopyClick">
            <div class="flex gap-5">
              <Icon icon="file_copy" :color="Color.DeepTeal"></Icon>
              <span class="text-deepteal-500">{{ texts.actions.copy }}</span>
            </div>
          </DropdownItem>
        </template>
      </DropdownWrapper>
    </div>
  </li>
</template>

<script setup lang="ts">
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import Activity from "@/models/activity";
import { RouteNames, RouteNamesActivityOverview } from "@/router/routeNames";
import texts from "@/utils/texts";
import { computed } from "vue";
import ActivityStatus from "./ActivityStatus.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import { testIds } from "@/utils/testing";
import { Color } from "@/enums";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { MenuButton } from "@headlessui/vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { useRouter } from "vue-router";
import logger from "@/plugins/logger";
import Notify from "@/utils/notify";
import { DateTime, Interval } from "luxon";
import Icon from "@/components/common/icon/Icon.vue";

const router = useRouter();
const props = defineProps<{
  activity: Activity;
}>();

const emit = defineEmits<{
  copy: [value: boolean];
}>();

const currentDateIntervalString = computed(() =>
  Interval.fromDateTimes(
    props.activity.startDateTime,
    props.activity.endDateTime,
  ).toLocaleString(DateTime.DATE_FULL),
);

const handleCopyClick = () => {
  emit("copy", true);
  eduConfigurationServiceClient
    .copyActivity(props.activity.id)
    .then((response) => {
      Notify.success(texts.notifications.copy.success, [
        texts.models.activity.title,
      ]);

      router.push({
        name: RouteNamesActivityOverview.SETTINGS,
        params: { id: response },
      });
    })
    .catch((error) => {
      emit("copy", false);

      Notify.failure(texts.notifications.copy.failure, [
        texts.models.activity.title,
      ]);
      logger.log(error);
    });
};
</script>
