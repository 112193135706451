<template>
  <div class="flex flex-col gap-5">
    <Search v-model="search" :placeholder="texts.actions.search" />
    <div class="flex flex-col gap-4 rounded bg-white p-4 shadow-sm">
      <div class="flex items-center justify-between gap-1.5">
        <h2 class="text-base font-semibold">{{ texts.generic.filters }}</h2>
        <span
          class="cursor-pointer text-xs text-emerald-500 underline hover:text-emerald-700"
          @click="resetFilters"
          >{{ texts.actions.reset }}</span
        >
      </div>
      <StudyProgramFilter
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        :counts="studyProgramCounts"
        @filter="(values) => (filteredStudyProgramIds = values)"
      />
      <Divider />
      <StudyProgramMetadataFilter
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        @filter="(values) => (filteredStudyProgramMetaData = values)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { SessionStorageKey } from "@/enums";
import Search from "@/components/common/search/Search.vue";
import StudyProgramFilter from "@/components/study-programs/StudyProgramFilter.vue";
import Divider from "@/components/common/divider/Divider.vue";
import texts from "@/utils/texts";
import { compare } from "@/components/common/filter/FilterItem.types";
import { countBy } from "@/utils/array";
import StudyProgramMetadataFilter from "@/components/study-programs/StudyProgramMetadataFilter.vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{
  studyPrograms: LocalizedStudyProgramDTO[];
}>();

const search = ref<string | undefined>(
  sessionStorage.getItem(SessionStorageKey.ActivityStudyProgramsSearchTerm) ||
    undefined,
);

watch(search, (newValue) =>
  newValue
    ? sessionStorage.setItem(
        SessionStorageKey.ActivityStudyProgramsSearchTerm,
        newValue,
      )
    : sessionStorage.removeItem(
        SessionStorageKey.ActivityStudyProgramsSearchTerm,
      ),
);

const emit = defineEmits<{
  (e: "filter", value: LocalizedStudyProgramDTO[]): void;
}>();

const filteredStudyProgramIds = ref<string[] | undefined>();
const filteredStudyProgramMetaData = ref<string[] | undefined>();

const studyProgramCounts = computed(() =>
  countBy(props.studyPrograms, (item) => item.id),
);

const resetStudyProgramFilter = ref(false);
const resetFilters = () => {
  resetStudyProgramFilter.value = !resetStudyProgramFilter.value;
};

const filteredStudyPrograms = computed<LocalizedStudyProgramDTO[]>(() => {
  return props.studyPrograms
    .filter(
      (studyProgram) =>
        !search.value ||
        studyProgram.displayName
          .toLowerCase()
          .includes(search.value.toLowerCase()),
    )
    .filter((studyProgram) =>
      compare(filteredStudyProgramIds.value, studyProgram.id),
    )
    .filter((studyProgram) =>
      compare(filteredStudyProgramMetaData.value, studyProgram.id),
    );
});

watch(filteredStudyPrograms, (value) => emit("filter", value), {
  immediate: true,
});
</script>
