import {
  AccountDTO,
  AccountCreateDTO,
  AccountUpdateDTO,
  AccountStudyProgramsUpdateDTO,
} from "@/models/account";
import HttpClient from "@/utils/httpClient";

export const getAllAccounts = async (): Promise<AccountDTO[]> => {
  return await HttpClient.account
    .get<AccountDTO[]>("accounts")
    .then((res) => res.data);
};

export const getAccount = async (accountId: string): Promise<AccountDTO> => {
  return await HttpClient.account
    .get<AccountDTO>(`accounts/${accountId}`)
    .then((res) => res.data);
};

export const createAccount = async (
  account: AccountCreateDTO,
): Promise<void> => {
  return await HttpClient.account.post(`accounts/`, account);
};

export const putAccount = async (account: AccountUpdateDTO): Promise<void> => {
  return await HttpClient.account.put(`accounts/${account.id}`, account);
};

export const putAccountStudyPrograms = async (
  account: AccountStudyProgramsUpdateDTO,
): Promise<void> => {
  return await HttpClient.account.put(
    `accounts/${account.id}/study-programs`,
    account,
  );
};

export const blockAccount = async (accountId: string): Promise<void> => {
  return await HttpClient.account.post(`accounts/${accountId}/block`);
};

export const unblockAccount = async (accountId: string): Promise<void> => {
  return await HttpClient.account.post(`accounts/${accountId}/unblock`);
};

export const deleteAccount = async (accountId: string): Promise<void> => {
  return await HttpClient.account.delete(`accounts/${accountId}`);
};
