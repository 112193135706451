import {
  CreateThirdPartyProspectSourceDto,
  ThirdPartyProspectSourceOverviewDto,
  UpdateThirdPartyProspectSourceDto,
} from "@/lib/eduConfigurationServiceClient";

export interface ThirdPartyProspectSourceFormValues {
  name: string;
}

export const convertToFormValues = (
  source: ThirdPartyProspectSourceOverviewDto,
): ThirdPartyProspectSourceFormValues => {
  return {
    name: source.name,
  };
};

export const convertToCreateDTO = (
  formValues: ThirdPartyProspectSourceFormValues,
): CreateThirdPartyProspectSourceDto => {
  return new CreateThirdPartyProspectSourceDto({
    ...formValues,
  });
};

export const convertToUpdateDTO = (
  id: string,
  formValues: ThirdPartyProspectSourceFormValues,
): UpdateThirdPartyProspectSourceDto => {
  return new UpdateThirdPartyProspectSourceDto({
    id,
    ...formValues,
  });
};
