<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.generic.settings"
    :subTitle="componentTexts.settings.description"
  >
    <Loader v-if="loading" data-testid="loading-configuration"></Loader>
    <TrialDayConfigurationForm
      v-else-if="configuration"
      :formValues="convertToFormValues(configuration)"
      :selectedFormIds="selectedFormIds"
      @submit="submitUpdate"
    >
      <ButtonSubmit :loading="actionLoading" class="md:col-start-2" />
      <ButtonCancel @click="visibleInternal = false" />
    </TrialDayConfigurationForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import Loader from "@/components/common/loader/Loader.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { TrialDayConfigurationDto } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { computed, ref } from "vue";
import {
  TrialDayConfigurationFormValues,
  convertToDTO,
  convertToFormValues,
} from "./TrialDayConfigurationForm.types";
import TrialDayConfigurationForm from "./TrialDayConfigurationForm.vue";

const componentTexts = texts.navigationItems.organize.trialDays;

const props = defineProps<{
  visible: boolean;
  configuration?: TrialDayConfigurationDto;
  selectedFormIds?: string[];
  loading?: boolean;
}>();

const emit = defineEmits(["update:visible", "update:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

// Updating TrialDayConfiguration
const actionLoading = ref<boolean>(false);
const submitUpdate = (values: TrialDayConfigurationFormValues) => {
  if (!props.configuration) {
    throw new Error("Update values lacks configuration");
  }

  actionLoading.value = true;
  eduConfigurationServiceClient
    .updateTrialDayConfiguration(props.configuration.id, convertToDTO(values))
    .then(() => {
      Notify.success(texts.notifications.edit.successCached);
      visibleInternal.value = false;
      emit("update:succeeded");
    })
    .catch((e) => {
      Notify.failure(componentTexts.settings.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
