import texts from '@/utils/texts';

<template>
  <span
    :class="[
      'w-fit rounded-full px-3 py-1 text-xs',
      {
        'bg-success-100 text-deepteal-700':
          statusEnum === ActivityStatus.scheduled,
        'bg-info-100 text-deepteal-700':
          statusEnum === ActivityStatus.inProgress,
        'bg-gray-100 text-deepteal-700': statusEnum === ActivityStatus.finished,
      },
    ]"
  >
    {{ texts.enums.activityStatus[statusEnum] }}
  </span>
</template>

<script setup lang="ts">
import { ActivityStatus } from "@/models/activity";
import texts from "@/utils/texts";
import { computed } from "vue";

const props = defineProps<{ status: string }>();

const statusEnum = computed(() => props.status as ActivityStatus);
</script>
