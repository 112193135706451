<template>
  <Legend :items="legendItems" />
</template>

<script setup lang="ts">
import Legend from "@/components/common/legend/Legend.vue";
import { Color } from "@/enums";
import texts from "@/utils/texts";
import { computed } from "vue";

const props = defineProps<{
  lavender?: string;
  lime?: string;
}>();

const legendItems = computed(() => [
  {
    text:
      props.lavender ||
      texts.navigationItems.organize.activity.reports.registrations.title,
    color: Color.Lavender,
  },
  {
    text:
      props.lime ||
      texts.navigationItems.organize.activity.reports.visitors.title,
    color: Color.Lime,
  },
]);
</script>
