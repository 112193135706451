<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.generic.settings"
    :subTitle="texts.navigationItems.brochures.settings.description"
  >
    <BrochureConfigurationForm
      :selectedFormIds="selectedFormIds"
      @submit="submitCreate"
    >
      <ButtonSubmit :loading="actionLoading" />
      <ButtonCancel @click="visibleInternal = false" />
    </BrochureConfigurationForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";

import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import {
  BrochureConfigurationFormValues,
  convertToDTO,
} from "./BrochureConfigurationForm.types";
import BrochureConfigurationForm from "./BrochureConfigurationForm.vue";

const props = defineProps<{
  visible: boolean;
  selectedFormIds?: string[];
}>();

const emit = defineEmits(["update:visible", "create:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

// Create BrochureConfiguration
const actionLoading = ref<boolean>(false);
const submitCreate = (values: BrochureConfigurationFormValues) => {
  actionLoading.value = true;

  eduConfigurationServiceClient
    .createBrochureConfiguration(convertToDTO(values))
    .then(() => {
      Notify.success(texts.navigationItems.brochures.settings.create.success);
      visibleInternal.value = false;
      emit("create:succeeded");
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.brochures.settings.create.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
