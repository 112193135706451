<template>
  <div class="flex flex-col gap-5">
    <Search v-model="search" :placeholder="texts.actions.search" />
    <div class="flex flex-col gap-4 rounded bg-white p-4 shadow-sm">
      <div class="flex items-center justify-between gap-1.5">
        <h2 class="text-base font-semibold">
          {{ texts.navigationItems.keepMeInformed.filter.title }}
        </h2>
        <span
          :class="getTextColor(color) + ' ' + getTextHoverColor(color)"
          class="cursor-pointer text-xs underline"
          @click="resetFilters"
        >
          {{ texts.actions.reset }}
        </span>
      </div>
      <StudyProgramFilter
        v-if="studyPrograms"
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        :counts="studyProgramCounts"
        @filter="(values) => (filteredStudyProgramIds = values)"
      />
      <Divider />
      <StudyProgramMetadataFilter
        :reset="resetStudyProgramFilter"
        :studyPrograms="studyPrograms"
        @filter="(values) => (filteredStudyProgramIds = values)"
      />
      <Divider />
      <Filter
        v-model:expanded="keepMeInformedSectionExpanded"
        :title="texts.models.keepMeInformed.title"
      >
        <FilterItem
          :id="showWithoutStudyProgram.id"
          v-model="showWithoutStudyProgram.modelValue"
          :name="showWithoutStudyProgram.name"
        ></FilterItem>
      </Filter>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import { Color, SessionStorageKey } from "@/enums";
import Search from "@/components/common/search/Search.vue";
import StudyProgramFilter from "@/components/study-programs/StudyProgramFilter.vue";
import Divider from "@/components/common/divider/Divider.vue";
import texts from "@/utils/texts";
import {
  FilterProps,
  compare,
} from "@/components/common/filter/FilterItem.types";
import { KeepMeInformedOverviewItem } from "../Overview.types";
import { countBy } from "@/utils/array";
import StudyProgramMetadataFilter from "@/components/study-programs/StudyProgramMetadataFilter.vue";
import FilterItem from "@/components/common/filter/FilterItem.vue";
import Filter from "@/components/common/filter/Filter.vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import { getTextColor, getTextHoverColor } from "@/utils/color";

const props = defineProps<{
  items: KeepMeInformedOverviewItem[];
  studyPrograms: LocalizedStudyProgramDTO[];
  color: Color;
}>();

const search = ref<string | undefined>(
  sessionStorage.getItem(SessionStorageKey.KeepMeInformedSearchTerm) ||
    undefined,
);

watch(search, (newValue) =>
  newValue
    ? sessionStorage.setItem(
        SessionStorageKey.KeepMeInformedSearchTerm,
        newValue,
      )
    : sessionStorage.removeItem(SessionStorageKey.KeepMeInformedSearchTerm),
);

const emit = defineEmits<{
  (e: "filter", value: KeepMeInformedOverviewItem[]): void;
}>();

const keepMeInformedSectionExpanded = ref(false);
const showWithoutStudyProgram: FilterProps = reactive({
  id: "without-studyprogram",
  name: texts.navigationItems.keepMeInformed.filter.withoutStudyProgram,
  modelValue: false,
});
const isWithoutStudyProgram = (item: KeepMeInformedOverviewItem) => {
  return !item.studyProgramId;
};

const filteredStudyProgramIds = ref<string[] | undefined>();
const filteredStudyProgramMetaData = ref<string[] | undefined>();

const studyProgramCounts = computed(() =>
  countBy(props.items, (item) => item.studyProgramId),
);

const resetStudyProgramFilter = ref(false);
const resetFilters = () => {
  resetStudyProgramFilter.value = !resetStudyProgramFilter.value;
  showWithoutStudyProgram.modelValue = false;
};

const filteredItems = computed<KeepMeInformedOverviewItem[]>(() => {
  return props.items
    .filter(
      (item) =>
        !search.value ||
        item.studyProgramDisplayName
          ?.toLowerCase()
          .includes(search.value.toLowerCase()),
    )
    .filter((item) =>
      compare(filteredStudyProgramIds.value, item.studyProgramId),
    )
    .filter((item) =>
      compare(filteredStudyProgramMetaData.value, item.studyProgramId),
    )
    .filter(
      (item) =>
        !showWithoutStudyProgram.modelValue || isWithoutStudyProgram(item),
    );
});

watch(filteredItems, (value) => emit("filter", value), { immediate: true });
</script>
