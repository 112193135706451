import { AxiosRequestConfig } from "axios";

export class ClientBase {
  protected transformOptions = (
    options: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> => {
    options.url = options.url
      ? options.url.replace("/{tenant}/", "/")
      : options.url;

    // Older versions of axios automatically set the Content-Type 
    // header to multipart/form-data when FormData is used.
    if (options.data instanceof FormData && options.headers) {
      options.headers["Content-Type"] = "multipart/form-data";
    }

    return Promise.resolve(options);
  };
}
