import HttpClient from "@/utils/httpClient";

import {
  SessionTypeCreateDTO,
  SessionTypeDTO,
  SessionTypeExtendedDTO,
  SessionTypeUpdateDTO,
} from "@/models/sessionType";

export const getSessionTypes = async (): Promise<SessionTypeDTO[]> => {
  return await HttpClient.educonfig
    .get<SessionTypeDTO[]>("session-types")
    .then((response) => response.data);
};

export const getSessionType = async (
  sessionTypeId: string,
): Promise<SessionTypeExtendedDTO> => {
  return await HttpClient.educonfig
    .get<SessionTypeExtendedDTO>(`session-types/${sessionTypeId}`)
    .then((response) => response.data);
};

export const createSessionType = async (
  sessionType: SessionTypeCreateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post("session-types", sessionType);
};

export const updateSessionType = async (
  sessionType: SessionTypeUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `session-types/${sessionType.id}`,
    sessionType,
  );
};

export const deleteSessionType = async (
  sessionTypeId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(`session-types/${sessionTypeId}`);
};
