<template>
  <Modal
    v-model:visible="visibleInternal"
    :type="ModalType.danger"
    :description="
      interpolate(texts.modal.delete.description, texts.models.session.title)
    "
    :title="interpolate(texts.modal.delete.title, texts.models.session.title)"
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <ButtonDelete
          :data-testid="testIds.action.confirm"
          class="sm:ml-3"
          :loading="actionLoading"
          :disabled="!visibleInternal"
          @click="deleteSession"
        />
        <ButtonCancel
          class="mt-3 sm:mt-0"
          :disabled="actionLoading || !visibleInternal"
          @click="visibleInternal = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import { interpolate } from "@/dictionary";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { computed, ref } from "vue";

const props = defineProps<{
  activityId: string;
  sessionId: string;
  visible: boolean;
}>();

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "delete:succeeded"): void;
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

// Delete session modal
const actionLoading = ref(false);

const deleteSession = () => {
  actionLoading.value = true;
  eduConfigurationServiceClient
    .deleteSession(props.activityId, props.sessionId)
    .then(() => {
      Notify.success(texts.notifications.delete.success, [
        texts.models.session.title,
      ]);
      visibleInternal.value = false;
      emit("delete:succeeded");
    })
    .catch((e) => {
      Notify.failure(texts.notifications.delete.failure, [
        texts.models.session.title,
      ]);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
