<template>
  <div class="flex flex-col gap-1 sm:flex-row">
    <SelectionPeriodFilter
      v-model:definition="definition"
      :recruitmentYear="settings.recruitmentYear"
      :panelOrigin="PanelOrigin.TopRight"
    />
    <SelectionStudyProgramsFilter
      v-model:definition="definition"
      :studyPrograms="settings.allStudyPrograms"
      :panelOrigin="PanelOrigin.TopRight"
    />
  </div>
</template>
<script setup lang="ts">
import { PanelOrigin } from "@/components/common/popover/PopoverWrapper.types";
import { CriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";
import SelectionPeriodFilter from "@/components/selection/filter/SelectionPeriodFilter.vue";
import SelectionStudyProgramsFilter from "@/components/selection/filter/SelectionStudyProgramsFilter.vue";
import { SelectionDefinitionDto } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";

defineProps<{
  context: CriterionFieldContext;
}>();

const definition = defineModel<SelectionDefinitionDto>("definition", {
  required: true,
});
</script>
