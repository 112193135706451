<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="componentTexts.title"
    :subTitle="componentTexts.description"
  >
    <Loader v-if="loading" data-testid="loading-configuration" />
    <TrialDayStudyProgramSettingsForm
      v-else-if="settings"
      :formValues="convertToFormValues(settings)"
      @submit="submitUpdate"
    >
      <ButtonSubmit :loading="actionLoading" class="md:col-start-2" />
      <ButtonCancel @click="visibleInternal = false" />
    </TrialDayStudyProgramSettingsForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import Loader from "@/components/common/loader/Loader.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { TrialDayConfigurationStudyProgramSettingsDto } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { computed, ref } from "vue";
import {
  TrialDayStudyProgramFormValues,
  convertToDTO,
  convertToFormValues,
} from "./TrialDayStudyProgramSettingsForm.types";
import TrialDayStudyProgramSettingsForm from "./TrialDayStudyProgramSettingsForm.vue";

const componentTexts = texts.navigationItems.organize.trialDays.details;

const props = defineProps<{
  visible: boolean;
  configurationId?: string;
  settings?: TrialDayConfigurationStudyProgramSettingsDto;
  loading?: boolean;
}>();

const emit = defineEmits(["update:visible", "update:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

// Updating TrialDayConfigurationStudyProgramSettings
const actionLoading = ref(false);
const submitUpdate = (values: TrialDayStudyProgramFormValues) => {
  if (!props.settings || !props.configurationId) {
    throw new Error("Update values lacks settings");
  }

  actionLoading.value = true;
  eduConfigurationServiceClient
    .updateTrialDayConfigurationStudyProgramSettings(
      props.configurationId,
      props.settings.studyProgramId,
      convertToDTO(values),
    )
    .then(() => {
      Notify.success(componentTexts.edit.success);
      visibleInternal.value = false;
      emit("update:succeeded");
    })
    .catch((error) => {
      Notify.failure(componentTexts.edit.failure);
      logger.error(error);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
