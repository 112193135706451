<template>
  <Button
    :loading="loading"
    :color="Color.Gray"
    :size="ButtonSize.sm"
    :label="texts.navigationItems.dashboard.downloadProspects"
    :leadingIcon="'download'"
    :iconColor="Color.Lavender"
    @click="downloadDashboardProspectsExport"
  />
</template>

<script setup lang="ts">
import { Color } from "@/enums";
import { ButtonSize } from "@/components/common/button/Button.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import {
  ISelectionDefinitionDto,
  SelectionDto,
} from "@/lib/eduConfigurationServiceClient";
import { downloadFile } from "@/utils/downloadFile";
import texts from "@/utils/texts";
import { ref } from "vue";
import Button from "@/components/common/button/Button.vue";

const props = defineProps<{
  selection?: ISelectionDefinitionDto;
}>();

const loading = ref(false);

const downloadDashboardProspectsExport = async () => {
  if (!props.selection) {
    return;
  }

  loading.value = true;

  const response = await eduConfigurationServiceClient.exportDashboardProspects(
    new SelectionDto({ ...props.selection }),
  );

  downloadFile(response);

  loading.value = false;
};
</script>
