<template>
  <div class="grid gap-8 xl:grid-cols-12">
    <div v-if="$slots.left" class="xl:col-span-3">
      <slot name="left"></slot>
    </div>
    <div class="xl:col-span-9">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
