<template>
  <TransitionFade>
    <Card v-if="data && labels" class="flex flex-col gap-4">
      <CardHeader :text="textsReports.registrationsGraph.title"></CardHeader>
      <Line
        data-testid="registration-over-time-graph"
        :data="createLineChart(data, Color.Lavender)"
        :chartOptions="chartOptions(labels)"
      />
    </Card>
  </TransitionFade>
</template>

<script setup lang="ts">
import { Line } from "vue-chartjs";
import { Chart, registerables } from "chart.js";
import Card from "@/components/common/card/Card.vue";
import { computed, ref, watch } from "vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import { getRegistrationsOverTimeForActivity } from "@/services/report.service";
import texts from "@/utils/texts";
import {
  chartOptions,
  toLineChartData,
  createLineChart,
  LineChartData,
} from "./RegistrationsOverTime.types";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { Color } from "@/enums";
import { DateTime } from "luxon";

Chart.register(...registerables);

const props = defineProps<{
  activityId: string;
  startDate: DateTime;
}>();

const textsReports = texts.navigationItems.organize.activity.reports;

const data = ref<LineChartData[]>();

const initialize = async () => {
  const registrationsAtDate = await getRegistrationsOverTimeForActivity(
    props.activityId,
  );
  data.value = toLineChartData(registrationsAtDate);
};

const labels = computed(() =>
  data.value?.map((dataPoint) =>
    differenceInFullWeeksLabel(dataPoint.date, props.startDate),
  ),
);

watch(
  () => props.activityId,
  () => {
    initialize();
  },
  { immediate: true },
);

function differenceInFullWeeksLabel(target: DateTime, compare: DateTime) {
  if (!target) return;
  const diffInDays = target
    .startOf("day")
    .diff(compare.startOf("day"), "day").days;
  const diffInWeeks = Math.floor(diffInDays / 7);
  const differenceLabel =
    diffInWeeks < 0 ? `-${Math.abs(diffInWeeks)}` : `+${Math.abs(diffInWeeks)}`;
  return differenceLabel;
}
</script>
