import { Ref, ref, watch } from "vue";

/**
 * Creates a debounced (delayed) value based on a reactive value.
 * @template T - The type of the reactive value.
 * @param {Ref<T>} reactive - The reactive value to debounce.
 * @param {number} wait - The debounce wait time in milliseconds.
 * @returns {Object} - An object containing a debounced reactive value.
 */
export function useDebouced<T>(reactive: Ref<T>, wait: number) {
  const debounced = ref<T>();
  debounced.value = reactive.value;

  const debouncedSet = debounce((value: T) => (debounced.value = value), wait);
  watch(reactive, (value) => debouncedSet(value));

  return {
    debounced,
  };
}

function debounce<Args>(func: (...args: Args[]) => void, wait: number) {
  // https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086

  let timeout: NodeJS.Timeout;
  return (...args: Args[]) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
