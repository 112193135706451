<template>
  <template v-if="edit">
    <div
      :class="[
        errorMessage ? 'rounded-md border border-alert-500/50 p-0.5' : '',
      ]"
    >
      <StudyProgramPicker
        v-model:selectedIds="value"
        :studyPrograms="settings.allStudyPrograms"
      />
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <ul class="flex max-h-40 flex-col gap-1 overflow-y-auto">
      <li
        v-for="id in criterion.studyProgramIds"
        :key="id"
        class="flex flex-wrap"
      >
        {{
          settings.allStudyPrograms.find(
            (studyProgram) => studyProgram.id === id,
          )?.displayName
        }}
      </li>
    </ul>
  </template>
</template>

<script setup lang="ts">
import StudyProgramPicker from "@/components/study-programs/study-program-picker/StudyProgramPicker.vue";
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import * as yup from "yup";
import { useField } from "vee-validate";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().studyProgramIds;

const { value, errorMessage } = useField<
  typeof props.criterion.studyProgramIds
>(fieldId, yup.array().required().min(1), {
  initialValue: [...(props.criterion.studyProgramIds ?? [])], // Shallow-copy to decouple field-array from the original
});
</script>
