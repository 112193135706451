<template>
  <SlideOver
    v-model:visible="visibleInternal"
    data-testid="create-slide-over"
    :title="texts.models.brochure.title"
  >
    <BrochureForm @submit="submitCreate">
      <ButtonSubmit
        :loading="actionLoading"
        class="md:col-span-2 md:col-start-2"
      >
        {{ texts.actions.create }}
        <span class="text-sm font-light">{{ texts.actions.createSuffix }}</span>
      </ButtonSubmit>
      <ButtonCancel @click="() => (visibleInternal = false)" />
    </BrochureForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import Notify from "@/utils/notify";
import BrochureForm from "@/views/information-requests/brochures/components/BrochureForm.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import texts from "@/utils/texts";
import { CreateBrochureDto } from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import logger from "@/plugins/logger";
import {
  BrochureFormValues,
  convertToCreateDto,
} from "@/views/information-requests/brochures/components/BrochureForm.types";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";

const props = defineProps<{
  visible: boolean;
}>();

const emit = defineEmits(["update:visible", "create:succeeded"]);

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

// Updating Brochure
const actionLoading = ref<boolean>(false);
const submitCreate = (values: BrochureFormValues) => {
  actionLoading.value = true;

  return eduConfigurationServiceClient
    .createBrochure(new CreateBrochureDto(convertToCreateDto(values)))
    .then(() => {
      Notify.success(texts.notifications.create.successCached, [
        texts.models.brochure.title,
      ]);
      visibleInternal.value = false;
      emit("create:succeeded");
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.brochures.create.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
