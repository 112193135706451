<template>
  <div class="flex gap-4" :data-testid="testIds.statsItem">
    <div v-if="$slots.icon" class="flex items-center">
      <slot name="icon"></slot>
    </div>
    <div class="flex flex-col justify-between gap-1">
      <dt class="text-sm leading-none">{{ title }}</dt>
      <dd class="text-2xl font-semibold leading-none">
        <Counter
          :value="value"
          :suffix="suffix"
          :prefix="prefix"
          :separator="separator"
        />
        <span v-if="capacity" class="ml-2 text-sm font-medium opacity-80">
          / {{ capacity }}
        </span>
      </dd>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { isTesting } from "@/env";
import Counter from "@/components/counter/Counter.vue";
import { testIds } from "@/utils/testing";

const props = withDefaults(
  defineProps<{
    title: string;
    value?: number;
    capacity?: number;
    suffix?: string;
    prefix?: string;
    separator?: string;
  }>(),
  {
    separator: "",
    value: undefined,
    capacity: undefined,
    suffix: undefined,
    prefix: undefined,
  },
);

// For testing we want to have the counter instantly
// Otherwise intermdiate values are tested
const duration = isTesting ? 0.0001 : 1;
const startAmount = ref();
const endAmount = ref(props.value);

watch(
  () => props.value,
  (value) => {
    endAmount.value = value;

    setTimeout(() => {
      startAmount.value = value;
    }, duration * 1000);
  },
);
</script>
