<template>
  <template v-if="edit">
    <div>
      <Combobox
        :id="fieldId"
        v-model="value"
        :name="fieldId"
        :options="context.thirdPartyProspectSources"
        :formatOptionDisplayString="optionFormatter"
        :searchFilter="searchFilter"
        :valid="!errorMessage"
      />
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <span>
      {{
        context.thirdPartyProspectSources
          .filter((source) =>
            criterion.thirdPartyProspectSourceIds?.includes(source.id),
          )
          .map((source) => source.name)
          .join(", ")
      }}
    </span>
  </template>
</template>

<script setup lang="ts">
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import { useField } from "vee-validate";
import * as yup from "yup";
import { fields } from "@/utils/miscellaneous";
import {
  SelectionCriterionDto,
  ThirdPartyProspectSourceDto,
} from "@/lib/eduConfigurationServiceClient";
import Combobox from "@/components/common/combobox/Combobox.vue";

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().thirdPartyProspectSourceIds;

const { value, errorMessage } = useField<
  typeof props.criterion.thirdPartyProspectSourceIds
>(fieldId, yup.array().of(yup.string()).min(1).required(), {
  initialValue: [...(props.criterion.thirdPartyProspectSourceIds ?? [])], // Shallow-copy to decouple field-array from the original
});

const searchFilter = (source: ThirdPartyProspectSourceDto, query: string) =>
  source.name.toLowerCase().includes(query.toLowerCase());

const optionFormatter = (source: ThirdPartyProspectSourceDto) => source.name;
</script>
