import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { reactive } from "vue";
import {
  ActivityDTO,
  AffiliateLinkOverviewItemDto,
  RegistrationLinkWithRegistrationCountDTO,
} from "@/lib/eduConfigurationServiceClient";

export interface ActivityContext {
  activity: ActivityDTO | undefined;
  registrationLinks: RegistrationLinkWithRegistrationCountDTO[];
  affiliateLinks: AffiliateLinkOverviewItemDto[];
}

const activityContext: ActivityContext = reactive<ActivityContext>({
  activity: undefined,
  registrationLinks: [],
  affiliateLinks: [],
});

export default activityContext;

export function clearActivityContext() {
  activityContext.activity = undefined;
  activityContext.registrationLinks = [];
  activityContext.affiliateLinks = [];
}

export async function loadActivityContext(id: string) {
  await Promise.all([
    loadActivity(id),
    loadRegistrationLinks(id),
    loadAffiliateLinks(id),
  ]);
}

export async function loadActivity(id: string) {
  activityContext.activity =
    await eduConfigurationServiceClient.getActivityById(id);
}

export async function loadRegistrationLinks(id: string) {
  activityContext.registrationLinks =
    await eduConfigurationServiceClient.getAllRegistrationLinksWithRegistrationCounts(
      id,
    );
}

export async function loadAffiliateLinks(id: string) {
  activityContext.affiliateLinks =
    await eduConfigurationServiceClient.getAffiliateLinkOverview(id);
}
