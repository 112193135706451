<template>
  <SlideOver v-model:visible="visibleInternal" title="Details">
    <SlideOverProfile
      v-if="scanConfiguration"
      :isLoading="loadingConfiguration"
      :title="textsScanConfig.title"
      :subTitle="textsScanConfig.subTitle"
      :imageUrl="`data:image/png;base64,${scanConfiguration?.qrCode}`"
      :titleImage="summitScanLogoSrc"
      :details="scanConfigurationDetails"
    >
      <template v-slot:actions>
        <div class="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
          <Button
            class="w-full flex-1"
            data-testid="download-config"
            :label="textsScanConfig.downloadQR"
            :loading="downloadingFile"
            @click="downloadFile()"
          />
        </div>
      </template>
    </SlideOverProfile>
  </SlideOver>
</template>

<script setup lang="ts">
import summitScanLogoSrc from "@/assets/images/logo-summit-scan.svg";
import Button from "@/components/common/button/Button.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import SlideOverProfile from "@/components/common/slideover/SlideOverProfile.vue";
import {
  ActivityDTO,
  ActivityLocationLocalizedDTO,
  ScanConfigurationDto,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { downloadConfigurationFile } from "@/services/scanConfiguration.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { computed, ref, watch } from "vue";

const props = defineProps<{
  visible: boolean;
  activity: ActivityDTO;
  location: ActivityLocationLocalizedDTO;
}>();

const emit = defineEmits(["update:visible"]);

const textsScanConfig = texts.scanConfiguration.config;

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const loadingConfiguration = ref(false);
const downloadingFile = ref(false);

const scanConfiguration = ref<ScanConfigurationDto>();
const scanConfigurationDetails = ref<{ title: string; content: string }[]>();

const loadScanConfiguration = async () => {
  try {
    loadingConfiguration.value = true;
    scanConfiguration.value =
      await eduConfigurationServiceClient.getScanConfiguration(
        props.activity.id,
        props.location.id,
      );
    scanConfigurationDetails.value = [
      {
        title: textsScanConfig.details.activity,
        content: props.activity.name,
      },
      {
        title: textsScanConfig.details.location,
        content: props.location.name,
      },
    ];
    visibleInternal.value = true;
  } catch (err) {
    Notify.failure(texts.notifications.open.failure, [
      texts.models.scanConfiguration.title,
    ]);
    throw err;
  } finally {
    loadingConfiguration.value = false;
  }
};

const downloadFile = async () => {
  try {
    downloadingFile.value = true;
    await downloadConfigurationFile(props.activity.id, props.location.id);
  } catch (error) {
    Notify.failure(texts.scanConfiguration.downloadFile.failure);
    throw error;
  } finally {
    downloadingFile.value = false;
    visibleInternal.value = false;
  }
};

watch(
  () => visibleInternal.value,
  (newVal) => {
    if (newVal) {
      loadScanConfiguration();
    }
  },
  { immediate: true },
);
</script>
