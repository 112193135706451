<template>
  <AnchorMenu :items="items" />
</template>

<script setup lang="ts">
import { AnchorMenuItem } from "@/components/common/anchor-menu/AnchorMenu.types";
import AnchorMenu from "@/components/common/anchor-menu/AnchorMenu.vue";
import RecruitmentYearSettings from "@/views/settings/general/RecruitmentYearSettings.vue";
import ApiKeySettings from "./general/ApiKeySettings.vue";
import LanguageSettings from "./general/LanguageSettings.vue";
import texts from "@/utils/texts";

const generalTexts = texts.navigationItems.manage.settings.general;

const items: AnchorMenuItem[] = [
  {
    id: "language-settings",
    label: generalTexts.languages.title,
    component: LanguageSettings,
  },
  {
    id: "recruitment-year-settings",
    label: generalTexts.recruitmentYear.title,
    component: RecruitmentYearSettings,
  },
  {
    id: "api-key-settings",
    label: generalTexts.apiKey.title,
    component: ApiKeySettings,
  },
];
</script>
