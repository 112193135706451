<template>
  <div class="flex w-full items-center gap-5">
    <div
      :class="[
        'h-1 w-full rounded bg-gray-200',
        capacity ? 'opacity-100' : 'opacity-0',
      ]"
    >
      <div
        :class="[
          'h-full rounded',
          accented ? 'bg-lavender-500' : 'bg-deepteal-500',
        ]"
        :style="{
          width: `${percentage * 100}%`,
        }"
      ></div>
    </div>

    <div class="flex w-52 gap-1 text-sm">
      <span
        :class="[
          'font-semibold',
          accented ? 'text-lavender-600' : 'text-emerald-600',
        ]"
        >{{ count }}</span
      >
      <span v-if="capacity" class="text-gray-400">/</span>
      <span v-if="capacity" class="text-gray-400">{{ capacity }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  count: number;
  capacity?: number;
  accented?: boolean;
}>();

const percentage = computed(() =>
  props.capacity ? props.count / props.capacity : 0,
);
</script>
