/**
  The groupBy utility function aggregates the given array to a resulting object 
  with its keys generated by the result of the supplied groupBy-function
  and its values being an array of the objects qualifying for this function.

  Example:
    ===    
    type Animal = {id: string, name: string}

    var items = 
      <Animal[]>[ 
        {id: 1, name: 'Elephant'}, 
        {id: 2, name: 'Tiger'}, 
        {id: 3, name: 'Elephant'},
        {id: 4, name: 'Lion'}
      ];

    var groupByFunc =  
      (item: Animal) => item.name;    
    
    var result = groupBy(items, groupByFunc);
    
    ===
    result:
      { 
        'Elephant' : [ 
          {id: 1, name: 'Elephant'}, 
          {id: 3, name: 'Elephant'} 
        ], 
        'Tiger': [ 
          {id:2, name: 'Tiger'} 
        ],
        'Lion': [
          {id:4, name: 'Lion'}
        ]
      }
**/

export function groupBy<T, K extends number | string | symbol>(
  items: T[],
  groupByFunc: (item: T) => K | K[] | undefined,
) {
  return items.reduce(
    (result, item) => {
      const key = groupByFunc(item);

      if (key === undefined) return result;

      if (Array.isArray(key)) {
        for (let i = 0; i < key.length; i++) {
          if (result[key[i]]) result[key[i]].push(item);
          else result[key[i]] = [item];
        }
      } else {
        if (result[key]) result[key].push(item);
        else result[key] = [item];
      }

      return result;
    },
    {} as Record<K, T[]>,
  );
}

/**
 * Sorts the keys of an object by the result of the supplied sortFunc.
 */

export function sort<T extends Record<string | number | symbol, unknown>>(
  unordered: T,
  sortFunc?: (a: string, b: string) => number,
) {
  return Object.keys(unordered)
    .sort(sortFunc)
    .reduce<T>((obj, k) => {
      const key = k as keyof T;
      obj[key] = unordered[key];
      return obj;
    }, {} as T);
}

/**
  The countBy utility function aggregates the given array to a resulting object 
  with its keys generated by the result of the supplied countBy-function
  and its values being the number of objects with this key.

  Example:
    ===    
    type Animal = {id: string, name: string}

    var items = 
      <Animal[]>[ 
        {id: 1, name: 'Elephant'}, 
        {id: 2, name: 'Tiger'}, 
        {id: 3, name: 'Elephant'},
        {id: 4, name: 'Lion'}
      ];

    var countByFunc =
      (item: Animal) => item.name;    
    
    var result = countBy(items, countByFunc);
    
    ===
    result:
      { 
        'Elephant': 2, 
        'Tiger': 1,
        'Lion': 1
      }
**/

export function countBy<T, K extends number | string | symbol>(
  items: T[],
  countByFunc: (item: T) => K | K[] | undefined,
) {
  return items.reduce(
    (result, item) => {
      const key = countByFunc(item);

      if (key === undefined) return result;

      if (Array.isArray(key)) {
        for (let i = 0; i < key.length; i++) {
          if (result[key[i]]) result[key[i]]++;
          else result[key[i]] = 1;
        }
      } else {
        if (result[key]) result[key]++;
        else result[key] = 1;
      }

      return result;
    },
    {} as Record<K, number>,
  );
}

// A function that returns an aggregate array of a given number array with the sum of the previous values.
// Example: [1, 2, 3, 4] => [1, 3, 6, 10]

export function aggregatedSumArray(array: number[]) {
  const sumOfPreviousValues = array.reduce((acc, curr) => {
    acc.push(((acc.length && acc[acc.length - 1]) || 0) + curr);
    return acc;
  }, [] as number[]);
  return sumOfPreviousValues;
}
