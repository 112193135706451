import env from "@/env";
import { MergeContent, MergeTag, SpecialLink } from "@/models/mailingType";
import store from "@/store/index";
import BeefreeSDK from "@beefree.io/sdk";
import {
  BeePluginError,
  IBeeConfig,
  IEntityContentJson,
  IMergeContent,
  IMergeTag,
  ISpecialLink,
} from "@beefree.io/sdk/dist/types/bee";
import { shallowReactive, ShallowReactive, toRaw, watch } from "vue";
import {
  BEE_DEFAULT_TEMPLATE,
  BEE_INVALID_TEMPLATE_STATUSCODE,
  replaceMergeContentValuesWithHandleBars,
} from "./bee.constants";
import tenant from "@/store/context/tenant.context";

const CLIENTID = env.VITE_BEE_CLIENTID;
const CLIENTSECRET = env.VITE_BEE_CLIENTSECRET;

type JsonString = string;
type HtmlString = string;
export type SaveResponse = [JsonString, HtmlString];

class BeeInstance {
  private _bee?: BeefreeSDK;

  // null-checked Bee
  get bee(): BeefreeSDK {
    if (!this._bee) throw new Error("Bee has not been correctly initialized");
    return this._bee;
  }
  set bee(val: BeefreeSDK) {
    this._bee = val;
  }

  public setup = async (
    container: string,
    uid: string,
    language: string,
    isPreviewingCallback: (isPreviewing: boolean) => void,
    mergeTags: IMergeTag[],
    mergeContents: IMergeContent[],
    specialLinks: ISpecialLink[],
    template: IEntityContentJson = { ...BEE_DEFAULT_TEMPLATE },
  ): Promise<JsonString> => {
    const newBee: BeefreeSDK = new BeefreeSDK();
    this.bee = newBee;

    return new Promise<JsonString>((res, rej) => {
      this.resolveLoad = res;
      this.resolveError = rej;

      // Bee api call (https://bee-auth.getbee.io/validator) fails with a bad-request when the uid contains a '.'
      uid = uid.replace(".", "-");

      const config = this.getBeeConfig(
        container,
        uid,
        language,
        isPreviewingCallback,
        mergeTags,
        mergeContents,
        specialLinks,
      );

      return (
        newBee
          .getToken(CLIENTID, CLIENTSECRET)
          // Bee typing is inconsistent for IEntityContentJson types.
          // Last resort is to ignore this rule until fixed in package.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .then(() => newBee.start(config, template))
          .catch(rej)
      );
    });
  };

  load(template: IEntityContentJson): Promise<JsonString> {
    return new Promise<JsonString>((res, rej) => {
      this.resolveLoad = res;
      this.resolveError = rej;

      // Bee editor accepts a IEntityContentJson type, and we currently rely on this behavior
      this.bee.load(template);
    }).catch(this._handleInvalidTemplate);
  }

  save(): Promise<SaveResponse> {
    return new Promise<SaveResponse>((res, rej) => {
      this.resolveSave = res;
      this.resolveError = rej;

      this.bee.save();
    });
  }

  preview() {
    this.bee.preview();
  }

  private _handleInvalidTemplate = (e: BeePluginError) => {
    if (e.code !== BEE_INVALID_TEMPLATE_STATUSCODE) {
      throw e;
    }

    console.warn(
      "The template is malformed and cannot be loaded. Loaded default template instead.",
    );
    return this.load({ ...BEE_DEFAULT_TEMPLATE });
  };

  private resolveLoad?: (json: string) => void;
  private resolveError?: (errorMessage: BeePluginError) => void;
  private resolveSave?: (arr: SaveResponse) => void;
  isPreviewing = false;

  private getBeeConfig(
    container: string,
    uid: string,
    language: string,
    isPreviewingCallback: (isPreviewing: boolean) => void,
    mergeTags: IMergeTag[],
    mergeContents: IMergeContent[],
    specialLinks: ISpecialLink[],
  ): IBeeConfig {
    return {
      container,
      uid,
      language,
      mergeTags,
      mergeContents: replaceMergeContentValuesWithHandleBars(mergeContents),
      specialLinks: specialLinks,
      onLoad: (jsonFile: unknown) => {
        if (!this.resolveLoad)
          throw new Error("Bee load callback is not getting resolved...");

        this.resolveLoad(JSON.stringify(jsonFile) as JsonString);
      },
      onError: (error: BeePluginError) => {
        if (!this.resolveError)
          throw new Error("Bee error callback is not getting resolved...");

        this.resolveError(error);
      },
      onSave: (jsonFile: unknown, htmlFile: unknown) => {
        if (!this.resolveSave)
          throw new Error("Bee save callback is not getting resolved...");

        this.resolveSave([jsonFile as JsonString, htmlFile as HtmlString]);
      },
      onTogglePreview: () => {
        this.isPreviewing = !this.isPreviewing;
        isPreviewingCallback(this.isPreviewing);
      },
    };
  }
}

export const beeInstance = new BeeInstance();

export const useBee = (containerId = "bee-container"): BeeInstanceValues => {
  const state = shallowReactive({
    preview: false,
    loading: false,
  });

  const save = () => {
    state.loading = true;
    return beeInstance.save().finally(() => (state.loading = false));
  };

  const load = (template: IEntityContentJson) => {
    state.loading = true;

    return beeInstance
      .load(toRaw(template))
      .finally(() => (state.loading = false));
  };

  watch(
    () => state.preview,
    (newValue) => {
      if (beeInstance.isPreviewing === newValue) return;
      beeInstance.preview();
    },
  );

  const init = (options?: {
    template?: IEntityContentJson;
    mergeTags?: MergeTag[];
    mergeContents?: MergeContent[];
    specialLinks?: SpecialLink[];
  }) => {
    state.loading = true;

    if (!tenant.uri) throw new Error("Tenant uri is not set");

    return beeInstance
      .setup(
        containerId || "bee-container",
        tenant.uri,
        store.getters["cultureStore/active"],
        (isPreviewing) => (state.preview = isPreviewing),
        options?.mergeTags || [],
        options?.mergeContents || [],
        options?.specialLinks || [],
        toRaw(options?.template),
      )
      .then(() => {
        if (beeInstance.isPreviewing === state.preview) return;
        beeInstance.preview();
      })
      .finally(() => (state.loading = false));
  };

  return {
    state,
    load,
    save,
    init,
  };
};

export type BeeInstanceValues = {
  state: ShallowReactive<{
    preview: boolean;
    loading: boolean;
  }>;
  load: (template: IEntityContentJson) => Promise<JsonString>;
  save: () => Promise<SaveResponse>;
  init: (options?: {
    template?: IEntityContentJson;
    mergeTags?: MergeTag[];
    mergeContents?: MergeContent[];
    specialLinks?: SpecialLink[];
  }) => Promise<void>;
};
