import { Culture } from "@/enums";
import {
  CreateSessionGroupDto,
  SessionGroupDto,
  UpdateSessionGroupDto,
} from "@/lib/eduConfigurationServiceClient";

export interface SessionGroupFormValues {
  localizations: SessionGroupFormValuesLocalization[];
}

interface SessionGroupFormValuesLocalization {
  locale: Culture;
  name: string;
}

export const convertToFormValues = (
  sessionGroup: SessionGroupDto,
): SessionGroupFormValues => {
  return {
    localizations:
      sessionGroup.localizations as SessionGroupFormValuesLocalization[],
  };
};

export const convertToUpdateDto = (
  values: SessionGroupFormValues,
): UpdateSessionGroupDto => {
  return new UpdateSessionGroupDto({
    localizations: values.localizations.filter((loc) => loc.name),
  });
};

export const convertToCreateDto = (
  values: SessionGroupFormValues,
): CreateSessionGroupDto => {
  return new CreateSessionGroupDto({
    localizations: values.localizations.filter((loc) => loc.name),
  });
};
