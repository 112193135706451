import Account from "@/models/account";
import { getAllAccounts } from "@/services/account.service";
import {
  Module,
  ActionTree,
  MutationTree,
  GetterTree,
  ActionContext,
} from "vuex";
import { RootState } from "..";

export interface AccountStoreState {
  accounts: Account[];
  loadingAccounts: boolean;
  errorLoadingAccounts: boolean;

  search: string | null;
  includeBlockedAccounts: boolean;
}

const state: AccountStoreState = {
  accounts: [],
  loadingAccounts: false,
  errorLoadingAccounts: false,

  search: null,
  includeBlockedAccounts: false,
};

interface AccountStoreGetters extends GetterTree<AccountStoreState, RootState> {
  accounts: (state: AccountStoreState) => Account[];
  loadingAccounts: (state: AccountStoreState) => boolean;
  errorLoadingAccounts: (state: AccountStoreState) => boolean;

  search: (state: AccountStoreState) => string | null;
  includeBlockedAccounts: (state: AccountStoreState) => boolean;
}

const getters: AccountStoreGetters = {
  accounts: (state) => state.accounts,
  loadingAccounts: (state) => state.loadingAccounts,
  errorLoadingAccounts: (state) => state.errorLoadingAccounts,
  search: (state) => state.search,
  includeBlockedAccounts: (state) => state.includeBlockedAccounts,
};

interface AccountStoreActions extends ActionTree<AccountStoreState, RootState> {
  loadAccounts(
    context: ActionContext<AccountStoreState, RootState>,
  ): Promise<void>;
}

const actions: AccountStoreActions = {
  async loadAccounts(context) {
    try {
      context.commit("setErrorLoadingAccounts", false);
      context.commit("setLoadingAccounts", true);
      const response = await getAllAccounts();
      const accounts = response.map((dto) => new Account(dto));

      context.commit("setAccounts", accounts);
    } catch (error) {
      context.commit("setErrorLoadingAccounts", true);
      throw error;
    } finally {
      context.commit("setLoadingAccounts", false);
    }
  },
};

interface AccountStoreMutations extends MutationTree<AccountStoreState> {
  setAccounts(state: AccountStoreState, accounts: Account[]): void;
  setLoadingAccounts(state: AccountStoreState, loadAccounts: boolean): void;
  setErrorLoadingAccounts(
    state: AccountStoreState,
    errorLoadingAccounts: boolean,
  ): void;

  setSearch(state: AccountStoreState, search: string | null): void;
  setIncludeBlockedAccounts(
    state: AccountStoreState,
    includeBlockedAccounts: boolean,
  ): void;
}

const mutations: AccountStoreMutations = {
  setAccounts(state, accounts: Account[]): void {
    state.accounts = accounts;
  },
  setLoadingAccounts(state, loadingAccounts) {
    state.loadingAccounts = loadingAccounts;
  },
  setErrorLoadingAccounts(state, errorLoadingAccounts) {
    state.errorLoadingAccounts = errorLoadingAccounts;
  },

  setSearch(state, search) {
    state.search = search;
  },
  setIncludeBlockedAccounts(state, includeBlockedAccounts) {
    state.includeBlockedAccounts = includeBlockedAccounts;
  },
};

export interface AccountStore extends Module<AccountStoreState, RootState> {
  namespaced: true;
  state?: AccountStoreState;
  getters: AccountStoreGetters;
  actions: AccountStoreActions;
  mutations?: AccountStoreMutations;
}

const accountModule: AccountStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default accountModule;
