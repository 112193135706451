import HttpClient from "@/utils/httpClient";
import { CreateEnrollmentInStudyProgramDTO } from "@/models/contact-moment";

export const createEnrollmentInStudyProgram = async (
  newEnrollment: CreateEnrollmentInStudyProgramDTO,
) => {
  return await HttpClient.educonfig.post(
    `enrollments`,
    JSON.stringify(newEnrollment),
  );
};

export const deleteEnrollmentInStudyProgram = async (
  id: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(`enrollments/${id}`);
};
