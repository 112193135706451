<template>
  <li class="mb-4 flex flex-col items-start gap-4">
    <div class="flex w-full items-center justify-between gap-2">
      <span class="text-base font-semibold">
        {{ configuration.name }}
      </span>
      <div v-if="configuration.id" class="flex gap-2">
        <DropdownWrapper
          v-if="canViewPersonalData()"
          :origin="DropdownOrigin.TopRight"
        >
          <template v-slot:button>
            <ButtonGroup
              :color="Color.Gray"
              :size="ButtonSize.sm"
              flat
              round
              gap
            >
              <Button
                :data-testid="testIds.action.copy"
                :disabled="configuration.formId === null"
                @click="copyLinkToClipboardClick"
              >
                <IconAsync
                  :state="state"
                  icon="link"
                  :color="Color.Gray"
                  :size="IconSize.sm"
                />
                <span>
                  {{ componentTexts.overview.copyLinkBtn }}
                </span>
              </Button>
              <ButtonGroupMenuButton />
            </ButtonGroup>
          </template>
          <template v-slot:items>
            <DropdownItem
              icon="download"
              :label="componentTexts.overview.exportRequestsBtn"
              :disabled="configuration.formId === null"
              @click="exportRequestsForConfigClick"
            />
          </template>
        </DropdownWrapper>
        <Button
          v-else
          :data-testid="testIds.action.copy"
          :disabled="configuration.formId === null"
          :color="Color.Gray"
          round
          :size="ButtonSize.sm"
          @click="copyLinkToClipboardClick"
        >
          <IconAsync
            :state="state"
            icon="link"
            :color="Color.Gray"
            :size="IconSize.sm"
          />
          <span>
            {{ componentTexts.overview.copyLinkBtn }}
          </span>
        </Button>
        <ButtonSettings
          v-if="canWrite"
          icon="settings"
          @click="emit('configuration:update')"
        >
          {{ texts.generic.settings }}
        </ButtonSettings>
      </div>
    </div>
    <List compact class="w-full">
      <template v-slot:header>
        <ListItemColumn :flex="0"><div class="h-6 w-6"></div></ListItemColumn>
        <ListItemColumn :flex="6">{{
          texts.models.studyProgram.name
        }}</ListItemColumn>
        <ListItemColumn hide :flex="1" class="justify-end">
          {{ texts.navigationItems.dashboard.uniqueProspects }}
        </ListItemColumn>
        <ListItemColumn :flex="0"><div class="h-6 w-6"></div> </ListItemColumn>
      </template>
      <KeepMeInformedItem
        v-for="item in items"
        :key="item.studyProgramId ?? item.configuration.id"
        :item="item"
        :noInterestLabel="noInterestLabel"
        :color="color"
      />
    </List>
  </li>
</template>

<script setup lang="ts">
import {
  AsyncState,
  useAsyncState,
} from "@/components/common/async/Async.types";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import ButtonGroup from "@/components/common/button/ButtonGroup.vue";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import List from "@/components/common/list/List.vue";
import { Color, Culture, FormType } from "@/enums";
import { KeepMeInformedConfigurationDTO } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import { RootState } from "@/store/index";
import { downloadFileBySimulatingClickOnTemporaryAnchorTag } from "@/utils/downloadFile";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { KeepMeInformedOverviewItem } from "../Overview.types";
import KeepMeInformedItem from "./KeepMeInformedItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { canViewPersonalData } from "@/router/helpers/userCanGoTo";

const store = useStore<RootState>();
const culture = store.getters["cultureStore/active"] as Culture;
const componentTexts = texts.navigationItems.keepMeInformed;

const props = defineProps<{
  configuration: KeepMeInformedConfigurationDTO;
  items: KeepMeInformedOverviewItem[];
  color: Color;
  canWrite: boolean;
}>();

const emit = defineEmits<{
  copy: [value: string];
  "configuration:update": [];
}>();

const noInterestLabel = computed(() =>
  props.configuration.allowNoInterest
    ? props.configuration.localizations.find((l) => l.locale === culture)
        ?.noInterestLabel
    : undefined,
);

// Copy form link
const copyKeepMeInformedFormLinkToClipboard = async () => {
  const forms = await formsServiceClient.getForms(FormType.KeepMeInformed);
  const formUri = forms.find((f) => f.id === props.configuration.formId)?.uri;

  if (!formUri) {
    throw new Error(`Could not find keep me informed form uri!`);
  }

  const url = await formsServiceClient.getKeepMeInformedLink(formUri);
  navigator.clipboard.writeText(url);
};

// Download requests export
const exportRequestsForConfigClick = async () => {
  try {
    state.value = AsyncState.Loading;

    const response =
      await eduConfigurationServiceClient.exportKeepMeInformedRequestsPerConfiguration(
        props.configuration.id,
      );

    downloadFileBySimulatingClickOnTemporaryAnchorTag(
      response.data,
      response.fileName ?? "export.xlsx",
    );

    Notify.success(texts.notifications.download.success, [
      texts.models.keepMeInformed.title,
    ]);
    state.value = AsyncState.Success;
  } catch (e) {
    state.value = AsyncState.Failure;
    Notify.failure(texts.notifications.download.failure, [
      texts.models.keepMeInformed.title,
    ]);
    logger.error(e as Error);
  }
};

const { state, handler: copyLinkToClipboardClick } = useAsyncState(
  copyKeepMeInformedFormLinkToClipboard,
  componentTexts.copyToClipboard,
);

watch(state, (value) => {
  if (value === AsyncState.Success) {
    setTimeout(() => (state.value = AsyncState.Content), 1000);
  }
  if (value === AsyncState.Failure) {
    setTimeout(() => (state.value = AsyncState.Content), 3000);
  }
});
</script>
