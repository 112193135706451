<template>
  <div class="flex flex-col gap-8">
    <NavigationTabContainer>
      <NavigationTab
        v-for="locale in sortedLocales"
        v-show="
          settings.availableLanguages
            .map((l) => l.locale.value)
            .includes(locale)
        "
        :key="locale"
        :data-testid="testIds.action.view"
        :current="current === locale"
        @click="current = locale"
      >
        <div class="flex items-center gap-2">
          <span>{{ texts.enums.culture[locale] }}</span>
          <button
            v-if="!readonly && locale !== defaultCulture"
            type="button"
            :data-testid="testIds.action.delete"
            @click.stop="remove(locale)"
          >
            <Icon
              icon="close"
              :size="IconSize.xs"
              :color="Color.DeepTeal"
              clickable
            ></Icon>
          </button>
        </div>
      </NavigationTab>
      <NavigationTab
        v-for="culture in notAddedCultures"
        :key="culture"
        icon="add_circle"
        :name="interpolate(texts.actions.add, texts.enums.culture[culture])"
        :data-testid="testIds.action.create"
        @click="add(culture)"
      />
    </NavigationTabContainer>
    <slot :current></slot>
  </div>
</template>

<script setup lang="ts" generic="T">
import NavigationTab from "@/components/common/navigation/NavigationTab.vue";
import NavigationTabContainer from "@/components/common/navigation/NavigationTabContainer.vue";
import { Culture, Color } from "@/enums";
import { computed } from "vue";

import { IconSize } from "@/components/common/icon/Icon.types";
import { testIds } from "@/utils/testing";
import { interpolate } from "@/dictionary";
import texts from "@/utils/texts";
import Icon from "@/components/common/icon/Icon.vue";
import settings from "@/store/context/settings.context";

const props = defineProps<{
  locales: Culture[];
  readonly?: boolean;
}>();

const emit = defineEmits<{
  add: [values: Culture];
  remove: [values: Culture];
}>();

const defaultCulture = settings.mainLanguage.locale.value as Culture;

const defaultLocale = computed(() =>
  props.locales.find((l) => l === defaultCulture),
);

const current = defineModel<Culture>({ default: Culture.EN });

if (defaultCulture) {
  current.value = defaultCulture;
}

const sortedLocales = computed(() =>
  props.locales
    .toSorted((a, b) => a.localeCompare(b))
    .toSorted((a, b) => {
      if (a === defaultLocale.value) return -1;
      if (b === defaultLocale.value) return 1;
      return 0;
    }),
);

if (!defaultLocale.value) {
  add(defaultCulture);
}

const notAddedCultures = computed(() =>
  settings.availableLanguages
    .map((language) => language.locale.value as Culture)
    .filter((culture) => !props.locales.find((locale) => locale === culture))
    .toSorted((a, b) => a.localeCompare(b)),
);

function add(culture: Culture) {
  emit("add", culture);

  current.value = culture;
}

function remove(culture: Culture) {
  emit("remove", culture);

  current.value = defaultCulture;
}

if (!defaultLocale.value) {
  add(defaultCulture);
}
</script>
