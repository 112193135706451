<template>
  <ListItem
    :class="[
      'border-l-2 transition-colors duration-75',
      checked.includes(studyProgram.id)
        ? 'border-deepteal-500'
        : 'border-transparent',
    ]"
    :title="studyProgram.displayName"
  >
    <ListItemColumn :flex="2">
      <label
        class="flex w-full items-center gap-2"
        :data-testid="testIds.studyProgramPicker.studyProgramsLabel"
      >
        <Checkbox
          :id="studyProgram.id"
          v-model="checked"
          name="study-programs"
          :value="studyProgram.id"
          :data-testid="testIds.action.filter"
        ></Checkbox>
        <span class="truncate">{{ studyProgram.displayName }}</span></label
      >
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import { testIds } from "@/utils/testing";

defineProps<{
  studyProgram: LocalizedStudyProgramDTO;
}>();

const checked = defineModel<string[]>({ default: [] });
</script>
