import { IconNames } from "@/components/common/icon/Icon.types";

export type StatsGridItem = {
  title: string;
  number: number;
  numberPrefix?: string;
  numberSuffix?: string;
  capacity?: number;
  icon?: IconNames;
  iconDark?: boolean;
  change?: number;
  changeType?: StatsGridItemChangeType;
  linkTitle?: string;
  toRoute?: string;
  toRouteParams?: Record<string, string | string[]>;
};

export enum StatsGridItemChangeType {
  increase = "increase",
  decrease = "decrease",
}
