<template>
  <Filter v-model:expanded="expanded" :title="texts.models.mailing.status">
    <FilterItem
      v-for="(mailingStatus, index) in mailingStates"
      :id="mailingStatus"
      :key="index"
      v-model="checked"
      :name="texts.enums.mailingStatus[mailingStatus]"
      color="#FFF"
      :count="counts[mailingStatus.toString()] || 0"
    ></FilterItem>
  </Filter>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { ref, watch } from "vue";
import { MailingStatus } from "@/enums";
import FilterItem from "@/components/common/filter/FilterItem.vue";
import Filter from "@/components/common/filter/Filter.vue";

const props = defineProps<{
  mailingStates: MailingStatus[];
  reset: boolean;
  counts: Record<string, number>;
}>();

const emit = defineEmits<{
  (e: "filter", value: MailingStatus[] | undefined): void;
}>();

const expanded = ref(true);
const checked = ref<MailingStatus[]>([]);

watch(checked, (values) => {
  emit("filter", values.length > 0 ? values : undefined);
});

watch(
  () => props.reset,
  () => (checked.value = []),
);
</script>
