import env from "@/env";
import { LocalizedLanguageDTO } from "@/lib/eduConfigurationServiceClient";
import { TenantDTO } from "@/models/tenant";
import axios, { AxiosInstance, AxiosResponse } from "axios";

const apiBaseURL = env.VITE_API_BASE_URL;
export default class HttpClient {
  static sharedHeaders = {
    "Content-Type": "application/json",
  };

  static tenants = axios.create({
    headers: this.sharedHeaders,
    baseURL: `${apiBaseURL}/educonfig/tenants/`,
  });

  static educonfig = axios.create({ headers: this.sharedHeaders });
  static mail = axios.create({ headers: this.sharedHeaders });
  static forms = axios.create({ headers: this.sharedHeaders });
  static account = axios.create({ headers: this.sharedHeaders });
  static registration = axios.create({ headers: this.sharedHeaders });

  private static services: { client: AxiosInstance; baseUrl: string }[] = [
    { client: this.educonfig, baseUrl: `${apiBaseURL}/educonfig/` },
    { client: this.mail, baseUrl: `${apiBaseURL}/mail/` },
    { client: this.forms, baseUrl: `${apiBaseURL}/forms/` },
    { client: this.account, baseUrl: `${apiBaseURL}/account/` },
    { client: this.registration, baseUrl: `${apiBaseURL}/registration/` },
    { client: this.tenants, baseUrl: `${apiBaseURL}/educonfig/tenants/` },
  ];

  static setCulture(culture: string): void {
    this.services.forEach((service) => {
      // see https://github.com/axios/axios/issues/4193
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      service.client.defaults.headers["Accept-Language"] = culture;
    });
  }

  static setAuth(token: string | null): void {
    this.services.forEach((service) => {
      if (token != null) {
        // see https://github.com/axios/axios/issues/4193
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        service.client.defaults.headers["Authorization" as string] =
          `Bearer ${token}`;
      } else {
        // see https://github.com/axios/axios/issues/4193
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete service.client.defaults.headers["Authorization"];
      }
    });
  }

  static setTenant(tenantUri: string | null): void {
    this.getTenantAwareServices().forEach((service) => {
      service.client.defaults.baseURL =
        tenantUri != null
          ? `${service.baseUrl}${encodeURIComponent(tenantUri)}/`
          : service.baseUrl;
    });
  }

  static getTenantAwareServices() {
    return this.services.filter(
      // The tenants endpoint is tenant-unaware, so there's no need to add the tenant uri
      (service) => service.client != HttpClient.tenants,
    );
  }

  static getTenants(): Promise<AxiosResponse<TenantDTO[]>> {
    return this.educonfig.get<TenantDTO[]>("tenants", {
      baseURL: this.services.filter(
        (service) => service.client === this.educonfig,
      )[0].baseUrl,
    });
  }

  static getLanguages(): Promise<AxiosResponse<LocalizedLanguageDTO[]>> {
    return this.educonfig.get<LocalizedLanguageDTO[]>("languages", {
      baseURL: this.services.filter(
        (service) => service.client === this.educonfig,
      )[0].baseUrl,
    });
  }

  static async stream(
    callbacks: {
      Message?: (chunk: IMessageStreamChunk) => void;
      Result?: (chunk: IResultStreamChunk) => void;
    },
    name: ServiceName,
    url: string,
    options?: RequestInit,
  ) {
    const response = await this.fetch(name, url, options);

    const reader = response.body?.getReader();
    if (!reader) throw new Error("Response body is not readable");

    const stream = this.iterate(reader);
    let buffer = "";

    for await (const chunk of stream) {
      // Events are sent in a text format. Each event is delimited by two newlines,
      // and each event can have any number of fields (each delimited by a single newline).
      // A single event looks like this:
      //
      // event: the type of the event
      // data: the data of the event
      buffer += chunk;
      const events = buffer.split("\n\n");

      // The last event might be incomplete, so keep it in the buffer
      buffer = events.pop() ?? "";

      events.forEach((event) => {
        const fields = event.split("\n").map((field) => {
          return {
            name: field.substring(0, field.indexOf(":")),
            value: field.substring(field.indexOf(":") + 1).trim(),
          };
        });

        const eventTypeField = fields.find((field) => field.name === "event");
        const dataField = fields.find((field) => field.name === "data");

        if (!eventTypeField || !dataField) {
          console.error("Event or data field is missing:", fields);
          return;
        }

        const eventType = eventTypeField.value;
        const data = dataField.value;

        try {
          const parsedValue = JSON.parse(data);

          if (eventType === "Message" && callbacks.Message) {
            callbacks.Message(parsedValue);
            return;
          }

          if (eventType === "Result" && callbacks.Result) {
            callbacks.Result(parsedValue);
            return;
          }

          throw new Error(`Unknown message type: ${name}`);
        } catch (e) {
          console.error("Failed to parse JSON data:", data, e);
        }
      });
    }

    if (buffer) {
      console.info("Remaining data:", buffer);
    }

    return response;
  }

  static fetch(name: ServiceName, url: string, options?: RequestInit) {
    const service = this.services.find(
      (service) => service.client === this[name],
    );
    if (!service) throw new Error("Service not found");

    const serviceHeaders = service.client.defaults.headers;
    // const serviceHeaders = service.client.defaults.headers as unknown as {
    //   [key: string]: string | undefined;
    //   "Accept-Language"?: string;
    //   Authorization?: string;
    // };

    const headers: HeadersInit = {};

    // see https://github.com/axios/axios/issues/4193
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (serviceHeaders["Accept-Language"]) {
      // see https://github.com/axios/axios/issues/4193
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers["Accept-Language"] = serviceHeaders["Accept-Language"] as string;
    }

    // see https://github.com/axios/axios/issues/4193
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (serviceHeaders["Authorization"]) {
      // see https://github.com/axios/axios/issues/4193
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers["Authorization"] = serviceHeaders["Authorization"] as string;
    }

    return fetch(`${service.client.defaults.baseURL}${url}`, {
      ...options,
      headers: {
        ...headers,
        ...options?.headers,
      },
    });
  }

  private static async *iterate(
    reader: ReadableStreamDefaultReader<Uint8Array>,
  ) {
    while (true) {
      const decoder = new TextDecoder();
      const { value, done } = await reader.read();
      if (done) {
        break;
      }

      const decodedChunk = decoder.decode(value, { stream: true });
      yield decodedChunk;
    }
  }
}

type ServiceName =
  | "educonfig"
  | "mail"
  | "forms"
  | "account"
  | "registration"
  | "tenants";

interface IMessageStreamChunk {
  message: string;
}

interface IResultStreamChunk {
  importCount: number;
  errors: string[];
  success: boolean;
}
