<template>
  <transition
    name="fade"
    mode="out-in"
    enterActiveClass="transition-all duration-150"
    leaveActiveClass="transition-all duration-75"
    enterFromClass="opacity-0"
    leaveToClass="opacity-0"
    enterToClass="opacity-100"
    leaveFromClass="opacity-100"
  >
    <slot></slot>
  </transition>
</template>
