<template>
  <FormField
    :id="id"
    :label="label"
    :displayMode="displayMode"
    :rules="rules"
    :initialValue="initialValue"
  >
    <template v-slot="{ value, errorMessage }">
      <Select
        :id="id"
        :name="id"
        :options="items"
        :disabled="disabled"
        :valid="!errorMessage.value"
        :modelValue="getSelectOption(value.value)"
        :allowUnset="allowUnset"
        :color="color"
        :size="size"
        @update:modelValue="(newValue) => setSelectOption(value, newValue)"
      />
    </template>
  </FormField>
</template>

<script setup lang="ts">
import FormField from "@/components/common/form/FormField.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import { AnySchema } from "yup";
import Select from "./Select.vue";
import { SelectOption } from "./SelectOption";
import { Ref } from "vue";
import { Color } from "@/enums";

const props = defineProps<{
  id: string;
  displayMode?: FormFieldDisplayMode;
  rules?: AnySchema;
  label?: string;
  disabled?: boolean;
  items: SelectOption[];
  allowUnset?: boolean;
  initialValue?: string | undefined;
  color?: Color;
  size?: "sm" | "md" | "lg";
}>();

const getSelectOption = (value: string | undefined) => {
  return props.items.find((item) => item.value === value);
};

const setSelectOption = (
  ref: Ref<string | undefined>,
  option: SelectOption | undefined | null,
) => {
  ref.value = option?.value;
};
</script>
