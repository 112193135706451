<template>
  <div class="md:flex md:items-center md:justify-between md:space-x-5">
    <div class="flex flex-1 items-start space-x-5">
      <Icon
        v-if="!!icon"
        :icon="icon"
        class="flex-shrink-0 stroke-1 text-4xl text-deepteal-500"
        aria-hidden="true"
      />
      <div class="flex flex-1 flex-col">
        <div class="flex flex-1 items-center space-x-2">
          <h1
            class="flex flex-1 items-center gap-2 text-3xl font-light text-deepteal-500"
            :data-testid="testIds.pageHeadingTitle"
          >
            <span v-if="title">{{ title }}</span>
            <slot v-if="slots.default"></slot>
            <Badge
              v-if="badgeLabel"
              :label="badgeLabel"
              :type="badgeType"
              :icon="badgeIcon"
            ></Badge>
          </h1>
        </div>

        <p v-if="slots.description" class="text-sm font-normal text-gray-500">
          <slot name="description"></slot>
        </p>
      </div>
    </div>
    <div
      v-if="slots.actions"
      class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-6"
    >
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import { testIds } from "@/utils/testing";
import { IconNames } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";

export interface PageHeadingProps {
  title?: string;
  icon?: IconNames;
  badgeLabel?: string;
  badgeType?: BadgeType;
  badgeIcon?: IconNames;
}

defineProps<{
  title?: string;
  icon?: IconNames;
  badgeLabel?: string;
  badgeType?: BadgeType;
  badgeIcon?: IconNames;
}>();

const slots = defineSlots<{
  default?: (props: Record<string, never>) => void;
  description?: (props: Record<string, never>) => void;
  actions?: (props: Record<string, never>) => void;
}>();
</script>
