import { interpolate } from "@/dictionary";
import { NotificationType } from "@/enums";
import { notify as vue3Notify } from "@kyvg/vue3-notification";

export interface NotificationTexts {
  title: string;
  content: string;
}

export default class Notify {
  public static success(texts: NotificationTexts, replaces?: string[]) {
    notify(texts, NotificationType.success, replaces);
  }

  public static failure(texts: NotificationTexts, replaces?: string[]) {
    notify(texts, NotificationType.failure, replaces);
  }

  public static warning(texts: NotificationTexts, replaces?: string[]) {
    notify(texts, NotificationType.warning, replaces);
  }

  public static info(texts: NotificationTexts, replaces: string[]) {
    notify(texts, NotificationType.info, replaces);
  }
}

export const notify = (
  texts: NotificationTexts,
  type: NotificationType,
  replaces?: string[],
) => {
  replaces = replaces || [];
  const title = interpolate(texts.title, ...replaces);
  const content = interpolate(texts.content, ...replaces);
  vue3Notify({
    title,
    text: content,
    type,
  });
};
