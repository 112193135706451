import { FileResponse } from "@/lib/eduConfigurationServiceClient";
import {
  AxiosResponse,
  AxiosResponseHeaders,
  RawAxiosResponseHeaders,
} from "axios";
import { DateTime } from "luxon";

export const downloadFile = (response: FileResponse): void => {
  const fileContent = new Blob([response.data], {
    type: response.headers
      ? response.headers["content-type"]
      : "application/octet-stream",
  });

  const filename =
    response.fileName ??
    `file_${DateTime.now()
      .toLocaleString(DateTime.DATETIME_SHORT)
      .replace(/[^0-9]/g, "_")}`;

  downloadFileBySimulatingClickOnTemporaryAnchorTag(fileContent, filename);
};

export const downloadFileResponse = (response: AxiosResponse): void => {
  const fileContent = new Blob([response.data], {
    type: response.headers["content-type"],
  });
  const filename = getFilenameFromHeaders(response.headers);

  downloadFileBySimulatingClickOnTemporaryAnchorTag(fileContent, filename);
};

export const downloadFileBySimulatingClickOnTemporaryAnchorTag = (
  fileContent: Blob,
  filename: string,
): void => {
  // create fake anchor that points to fileContent
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = URL.createObjectURL(fileContent);
  link.download = filename;

  // add anchor to document
  document.body.append(link);

  // click the element so browser will download it
  link.click();

  // cleanup
  link.remove();
};

function getFilenameFromHeaders(
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders,
) {
  const contentDispositionHeader = headers["content-disposition"];

  if (!contentDispositionHeader) {
    return "downloadedFile";
  }
  return contentDispositionHeader
    .split("; ")[1] // get the second part of the header
    .split("filename=")[1] // get the part after filename
    .replaceAll('"', ""); // strip out any double quotes to prevent weird filename
}
