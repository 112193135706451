<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-2 pb-8">
    <ButtonBack :toRouteName="texts.generic.previous" @click="router.go(-1)" />
    <PageHeading>{{ mailingTemplate?.name }}</PageHeading>
    <MailingTemplateForm
      :initialValues="initialValues"
      :beeContainerId="containerId"
      @submit="onSubmit"
    >
      <ButtonSubmit
        class="w-full lg:w-auto"
        :loading="isSubmitting"
        :disabled="bee.state.loading"
      />
      <ButtonCancel class="w-full lg:w-auto" @click="clickCancel" />
      <ButtonCopy class="w-full lg:w-auto" @click="clickCopy" />
      <ButtonDelete
        class="w-full lg:w-auto"
        @click="modalDeleteVisible = true"
      />
      <Toggle
        v-model="bee.state.preview"
        data-testid="toggle-preview"
        class="lg:flex-grow"
        :label="texts.actions.showPreview"
      />
    </MailingTemplateForm>
  </Page>
  <MailingTemplateDeleteModal
    v-if="mailingTemplate"
    v-model:visible="modalDeleteVisible"
    :mailingTemplateId="mailingTemplate.id"
    @delete:succeeded="router.push({ name: RouteNamesSettings.EMAIL_OVERVIEW })"
  />
</template>

<script setup lang="ts">
import Page from "@/components/common/page/Page.vue";
import { MailingTemplateExtended } from "@/models/mailing-templates";
import logger from "@/plugins/logger";
import {
  copyMailingTemplate,
  getMailingTemplate,
  updateMailingTemplate,
} from "@/services/mailing-templates.service";
import { ref } from "vue";
import {
  MailingTemplateFormValues,
  toUpdateDto,
  toFormValues,
} from "@/views/settings/emails/components/mailing-template/MailingTemplateForm.types";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonCopy from "@/components/common/button/ButtonCopy.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import { useRouter } from "vue-router";
import { RouteNamesSettings } from "@/router/routeNames";
import texts from "@/utils/texts";
import Notify from "@/utils/notify";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import ButtonBack from "@/components/common/button/ButtonBack.vue";
import MailingTemplateDeleteModal from "@/views/settings/emails/components/mailing-template/MailingTemplateDeleteModal.vue";
import { useBee } from "@/utils/bee/bee-instance";
import MailingTemplateForm from "@/views/settings/emails/components/mailing-template/MailingTemplateForm.vue";
import Toggle from "@/components/common/toggle/Toggle.vue";
import settings from "@/store/context/settings.context";

const props = defineProps<{
  id: string;
}>();

const loading = ref(false);
const error = ref(false);

const mailingTemplate = ref<MailingTemplateExtended>();

const initialValues = ref<MailingTemplateFormValues>();

const containerId = "bee-container";
const bee = useBee(containerId);

const initialize = async () => {
  loading.value = true;
  try {
    mailingTemplate.value = await getMailingTemplate(props.id);
    const mailingType = settings.mailingTypes.filter(
      (type) => type.mailingType === mailingTemplate.value?.mailingType,
    )[0];
    initialValues.value = toFormValues(mailingTemplate.value);
    bee.init({
      template: mailingTemplate.value.configuration,
      mergeTags: mailingType.mergeTags,
      mergeContents: mailingType.mergeContents,
      specialLinks: mailingType.specialLinks,
    });
  } catch (e) {
    error.value = true;
    logger.error(e);
  } finally {
    loading.value = false;
  }
};

initialize();

const isSubmitting = ref(false);
const onSubmit = async (values: MailingTemplateFormValues) => {
  isSubmitting.value = true;
  try {
    const saveResponse = await bee.save();
    if (!mailingTemplate.value) {
      throw new Error("Cannot submit. No mailingTemplate data initialized.");
    }
    const template = mailingTemplate.value;
    const dto = toUpdateDto(
      values,
      template.id,
      template.mailingType,
      saveResponse,
    );

    await updateMailingTemplate(dto);
    Notify.success(texts.notifications.edit.success, [
      texts.models.mailing.title,
    ]);
  } catch (e) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.mailing.title,
    ]);
    logger.error(e);
  } finally {
    isSubmitting.value = false;
  }
};

const router = useRouter();

const clickCancel = () => {
  router.push({ name: RouteNamesSettings.EMAIL_OVERVIEW });
};

const isCopying = ref<boolean>(false);
const clickCopy = async () => {
  isCopying.value = true;
  try {
    if (!mailingTemplate.value) {
      throw new Error("Cannot copy. No mailingTemplate data initialized.");
    }

    const response = await copyMailingTemplate(mailingTemplate.value.id);
    Notify.success(texts.notifications.copy.success, [
      texts.models.mailing.title,
    ]);
    await router.push({
      name: RouteNamesSettings.EMAIL_EDIT_MAILING_TEMPLATE,
      params: { id: response.id },
    });
  } catch (e) {
    Notify.failure(texts.notifications.copy.failure, [
      texts.models.mailing.title,
    ]);
    logger.error(e);
  } finally {
    isCopying.value = false;
  }
};

const modalDeleteVisible = ref(false);
</script>
