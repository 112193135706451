<template>
  <AnchorMenu :items="items" />
</template>

<script setup lang="ts">
import SessionTypes from "@/views/settings/organization/session-types/SessionTypes.vue";
import ActivityTypes from "@/views/settings/organization/activity-types/ActivityTypes.vue";
import texts from "@/utils/texts";
import AnchorMenu from "@/components/common/anchor-menu/AnchorMenu.vue";
import { AnchorMenuItem } from "@/components/common/anchor-menu/AnchorMenu.types";
import TargetAudiences from "@/views/settings/organization/target-audiences/TargetAudiences.vue";
import Schools from "@/views/settings/organization/schools/Schools.vue";
import ActivityLocations from "@/views/settings/organization/activity-locations/ActivityLocations.vue";
import EmailAddresses from "@/views/settings/organization/email-addresses/EmailAddresses.vue";
import PublicActivityPage from "@/views/settings/organization/public-activity-page-settings/PublicActivityPage.vue";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import Affiliates from "@/views/settings/organization/affiliates/Affiliates.vue";

const items: AnchorMenuItem[] = [
  {
    id: "activity-types",
    label: texts.navigationItems.organize.activityTypes.title,
    component: ActivityTypes,
  },
  {
    id: "activity-locations",
    label: texts.navigationItems.organize.activityLocations.title,
    component: ActivityLocations,
  },
  {
    id: "target-audiences",
    label: texts.navigationItems.organize.targetAudiences.title,
    component: TargetAudiences,
  },
  {
    id: "session-types",
    label: texts.navigationItems.organize.sessionTypes.title,
    component: SessionTypes,
  },
  {
    id: "schools",
    label: texts.navigationItems.organize.schools.title,
    component: Schools,
    permissions: [
      Permission.PreeducationschoolsRead,
      Permission.PreeducationschoolsWrite,
    ],
  },
  {
    id: "email-addresses",
    label:
      texts.navigationItems.manage.settings.organization.emailAddresses.title,
    component: EmailAddresses,
  },
  {
    id: "styling",
    label: texts.navigationItems.organize.styling.title,
    component: PublicActivityPage,
  },
  {
    id: "affiliates",
    label: texts.navigationItems.manage.settings.navigationTabs.affiliates,
    component: Affiliates,
  },
];
</script>
