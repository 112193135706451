<template>
  <input
    v-model="checked"
    type="checkbox"
    :class="checkboxClasses"
    :disabled="disabled"
    :value="value"
    :indeterminate="indeterminate"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    value?: string | Record<string, unknown>;
    modelValue?: unknown[] | boolean | null;
    valid?: boolean | undefined;
    disabled?: boolean;
  }>(),
  {
    valid: undefined,
    disabled: false,
    value: undefined,
    modelValue: undefined,
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean | unknown[] | null): void;
}>();

if (props.modelValue === undefined) {
  emit("update:modelValue", false);
}

const checked = computed({
  get: () => props.modelValue,
  set: (value) => {
    if (props.modelValue == null) {
      emit("update:modelValue", false);
    } else {
      emit("update:modelValue", value ?? null);
    }
  },
});

const displayInvalid = computed(() => props.valid === false);
const checkboxClasses = computed(() => [
  "h-4 w-4 rounded",
  {
    "border-gray-300 text-gray-500 bg-gray-100":
      props.disabled && !displayInvalid.value,
    "border-alert-300 text-gray-500 bg-gray-100":
      props.disabled && displayInvalid.value,
    "focus:ring-deepteal-500 text-deepteal-500":
      !props.disabled && !displayInvalid.value,
    "border-alert-300 text-alert-900 focus:outline-none focus:ring-alert-500 focus:border-alert-500":
      !props.disabled && displayInvalid.value,
  },
]);

const indeterminate = computed(() => checked.value === null);
</script>
