import { RouteMeta, RouteNames } from "@/router/routeNames";
import auth from "@/store/context/auth.context";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

const authenticationGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const noAuthRequired = to.meta[RouteMeta.NO_AUTH] as boolean;
  if (noAuthRequired) {
    next();
    return;
  }

  const nowUTC = Date.now();
  const tokenExpired = !auth.expiry || auth.expiry < nowUTC;

  if (!auth.token || tokenExpired) {
    next({ name: RouteNames.LOGIN });
  } else {
    next();
  }
};

export default authenticationGuard;
