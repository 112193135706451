<template>
  <ListItemBase
    :class="[
      'group/listitem flex items-center bg-white shadow',
      clickable ? 'cursor-pointer transition-colors hover:bg-royal-50/20' : '',
      compact
        ? 'first:rounded-t-md first:pt-1 last:rounded-b-md last:pb-1'
        : 'rounded-md',
    ]"
    :title="title"
  >
    <slot></slot>
  </ListItemBase>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { ListInjectionKeyCompact } from "./List.types";
import ListItemBase from "./ListItemBase.vue";

defineProps<{
  clickable?: boolean;
  title?: string;
  itemColor?: string;
}>();

const compact = inject<boolean>(ListInjectionKeyCompact, false);
</script>
