<template>
  <SlideOver v-model:visible="visible" :title="account?.name">
    <loader v-if="loading"></loader>
    <ErrorComponent v-else-if="error"></ErrorComponent>
    <AccountForm
      v-else-if="account"
      :formValues="{
        accountType: account.accountType,
        username: account.email,
        name: account.name,
        roles: account.roles,
        studyProgramIds: account.studyProgramIds,
        activityTypeIds: account.activityTypeIds,
        cantViewPersonalData: account.cantViewPersonalData,
        cantViewAndSendMailings: account.cantViewAndSendMailings,
      }"
      @submit="onSubmit"
    >
      <ButtonSubmit data-testid="submitForm" :loading="actionLoading" />
      <Button
        :color="Color.White"
        :label="texts.actions.cancel"
        @click="cancelUpdate"
      ></Button>
    </AccountForm>
  </SlideOver>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import Account, { AccountUpdateDTO } from "@/models/account";

import { getAccount, putAccount } from "@/services/account.service";

import Button from "@/components/common/button/Button.vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Color, Culture } from "@/enums";
import logger from "@/plugins/logger";
import { AccountFormValues } from "./AccountForm.types";
import Notify from "@/utils/notify";
import AccountForm from "@/views/settings/users/AccountForm.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import Loader from "@/components/common/loader/Loader.vue";
import ErrorComponent from "@/components/common/error/Error.vue";

// Store and router setup
const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const emit = defineEmits<{
  "update:id": [value: string | undefined];
}>();

const props = defineProps<{
  id: string | undefined;
}>();

const idInternal = computed({
  get() {
    return props.id;
  },
  set(value) {
    emit("update:id", value);
  },
});

const visible = computed({
  get() {
    return idInternal.value !== undefined;
  },
  set(value) {
    if (!value) {
      idInternal.value = undefined;
    }
  },
});

const loading = ref(true);
const actionLoading = ref(false);
const error = ref(false);
const account = ref<Account | undefined>(undefined);
watch(
  () => props.id,
  (value) => {
    if (!value) return;
    loading.value = true;

    getAccount(value)
      .then((response) => {
        account.value = new Account(response);
      })
      .catch((e) => {
        logger.error(e);

        error.value = true;
      })
      .finally(() => {
        loading.value = false;
      });
  },
);

const onSubmit = (values: AccountFormValues) => {
  if (!account.value || !props.id)
    throw new Error("No account exists to update");

  const updatedAccount: AccountUpdateDTO = {
    id: props.id,
    name: values.name,
    email: values.username,
    roles: values.roles,
    studyProgramIds: values.studyProgramIds,
    activityTypeIds: values.activityTypeIds,
    cantViewPersonalData: values.cantViewPersonalData,
    cantViewAndSendMailings: values.cantViewAndSendMailings,
  };

  actionLoading.value = true;

  return putAccount(updatedAccount)
    .then(() => {
      Notify.success(texts.navigationItems.account.edit.success);
      visible.value = false;
      store.dispatch("accountStore/loadAccounts");
    })
    .catch((e) => {
      Notify.failure(texts.navigationItems.account.edit.failure);
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const cancelUpdate = (): void => {
  visible.value = false;
  idInternal.value = undefined;
};
</script>
