<template>
  <Page :loading="loadingTenants" :error="errorLoadingTenants">
    <Section>
      <PageHeading title="Klanten" icon="switch_account">
        <template v-slot:description>
          Hieronder een overzicht van alle onderwijsklanten van Summit. Kies er
          1 om naar het dashboard van de klant te gaan.
        </template>
        <template v-slot:actions>
          <Button
            label="Nieuwe onderwijsklant toevoegen"
            @click="showCreateTenantPanel = true"
          />
        </template>
      </PageHeading>
    </Section>
    <List>
      <template v-slot:header>
        <ListItemColumn :flex="1">KLANTNAAM</ListItemColumn>
        <ListItemColumn :flex="1">STATUS</ListItemColumn>
        <ListItemColumn :flex="2">BESCHRIJVING</ListItemColumn>
        <ListItemColumn :flex="1">URI</ListItemColumn>
        <ListItemColumn :flex="0">
          <div class="w-16"></div>
        </ListItemColumn>
      </template>
      <ListItem
        v-for="tenant in tenants"
        :key="tenant.uri"
        data-testid="tenant-row"
      >
        <ListItemColumn :flex="1">{{ tenant.name }}</ListItemColumn>
        <ListItemColumn
          ><i>{{ tenant.status }}</i></ListItemColumn
        >
        <ListItemColumn :flex="2" :title="tenant.description">
          <i class="truncate text-wrap text-gray-400">{{
            tenant.description
          }}</i>
        </ListItemColumn>

        <ListItemColumn :flex="1" :title="tenant.uri"
          ><code class="text-royal-500">{{ tenant.uri }}</code>
        </ListItemColumn>
        <ListItemColumn :flex="0" class="text-right">
          <Button label="Bekijk" @click="switchTenant(tenant)"></Button>
        </ListItemColumn>
      </ListItem>
    </List>
  </Page>

  <SlideOver
    v-model:visible="showCreateTenantPanel"
    title="Nieuwe onderwijsklant"
    subTitle="Vul onderstaande variabelen in om een nieuwe onderwijsklant toe te voegen."
  >
    <CreateTenant @close="tenantCreateClosed"></CreateTenant>
  </SlideOver>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Tenant } from "@/models/tenant";
import Page from "@/components/common/page/Page.vue";
import Button from "@/components/common/button/Button.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import { useRouter } from "vue-router";
import CreateTenant from "./tenants/CreateTenant.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import Section from "@/components/common/section/Section.vue";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import { RouteNames } from "@/router/routeNames";
import HttpClient from "@/utils/httpClient";
import { setTenantContext } from "@/store/context/tenant.context";
import logger from "@/plugins/logger";

const router = useRouter();

const switchTenant = async (tenant: Tenant) => {
  try {
    await setTenantContext(tenant.uri);
    router.replace({ name: RouteNames.DASHBOARD });
  } catch (e) {
    logger.error(e);
    router.replace({ name: RouteNames.UNAVAILABLE });
  }
};

const tenants = ref<Tenant[]>([]);
const loadingTenants = ref(false);
const errorLoadingTenants = ref(false);

async function initialize() {
  try {
    loadingTenants.value = true;
    const response = await HttpClient.getTenants();
    tenants.value = response.data.map((dto) => new Tenant(dto));
  } catch (e) {
    errorLoadingTenants.value = true;
    throw e;
  } finally {
    loadingTenants.value = false;
  }
}

initialize();

const showCreateTenantPanel = ref<boolean>(false);

const tenantCreateClosed = () => {
  initialize();
  showCreateTenantPanel.value = false;
};
</script>
