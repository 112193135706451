<template>
  <div class="flex items-center gap-4">
    <Icon icon="stacks" :size="IconSize.lg" class="ml-2 text-deepteal-600" />
    <TransitionFade>
      <span class="text-xl font-normal text-deepteal-600">
        {{
          `${selectionResults} ${texts.navigationItems.dashboard.uniqueProspects}`
        }}
      </span>
    </TransitionFade>
  </div>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { ref, watchEffect } from "vue";
import {
  SelectionDefinitionDto,
  SelectionDto,
} from "@/lib/eduConfigurationServiceClient";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { IconSize } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";

const props = defineProps<{
  definition: SelectionDefinitionDto;
}>();

const selectionResults = ref<number>();

watchEffect(async () => {
  selectionResults.value =
    (await eduConfigurationServiceClient.getProspectsCount(
      new SelectionDto({ ...props.definition }),
    )) ?? 0;
});
</script>
