<template>
  <TransitionRoot as="template" :show="visibleInternal">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-40 flex md:hidden"
      :open="visibleInternal"
      @close="visibleInternal = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div class="relative mr-4 flex w-auto flex-col bg-white pb-4 shadow-lg">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div class="absolute -right-0 top-0 -mr-12 pt-2">
              <button
                class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="visibleInternal = false"
              >
                <span class="sr-only">Close sidebar</span>
                <Icon
                  icon="close"
                  filled
                  :size="IconSize.lg"
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </button>
            </div>
          </TransitionChild>
          <div
            class="flex flex-shrink-0 items-center justify-center bg-white px-2 py-2"
          >
            <div class="flex w-9 items-center justify-center">
              <img class="h-9" :src="summitLogoSrc" alt="summit-logo" />
            </div>
          </div>
          <nav class="my-6 flex flex-1 flex-col justify-around px-2">
            <PrimaryNavigation />
          </nav>
        </div>
      </TransitionChild>
      <div class="w-14 flex-shrink-0" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from "vue";
import summitLogoSrc from "@/assets/images/icon-hippo.svg";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import PrimaryNavigation from "./primary-navigation/PrimaryNavigation.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";

const props = defineProps<{ visible: boolean }>();

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});
</script>
