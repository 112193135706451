import { SelectionDefinitionDto } from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { reactive } from "vue";

interface SelectionContext {
  definition: SelectionDefinitionDto | undefined;
}

const selection: SelectionContext = reactive<SelectionContext>({
  definition: undefined,
});

export default selection;

export async function loadSelectionContext() {
  await Promise.all([loadDashboardSelection()]);
}

export async function loadDashboardSelection() {
  selection.definition =
    await eduConfigurationServiceClient.getDefaultDashboardSelectionDefinition();
}
