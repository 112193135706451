<template>
  <RouterLink
    v-if="visible"
    v-slot="{ href, navigate, isActive }"
    :to="to"
    v-bind="$attrs"
    :custom="custom"
    ><slot v-bind="{ href, navigate, isActive }" />
  </RouterLink>
</template>

<script setup lang="ts">
import { RouteLocationRaw, RouterLink, useRouter } from "vue-router";
import { userCanGoTo } from "@/router/helpers/userCanGoTo";
import { computed } from "vue";

const props = defineProps<{
  to: RouteLocationRaw;
  custom?: boolean;
}>();

const router = useRouter();
const toRoute = computed(() => router.resolve(props.to));
const visible = computed(() => userCanGoTo(toRoute.value));
</script>
