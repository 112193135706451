<template>
  <TextInput
    :id="id"
    :data-testid="testIds.action.copyForm"
    :readonly="true"
    :modelValue="modelValue"
    :type="isSecret ? TextInputType.PASSWORD : TextInputType.TEXT"
    :button="{ icon: copyToClipboardIcon }"
    @button:click="onCopyToClipboardClick"
  >
  </TextInput>
</template>

<script setup lang="ts">
import { ref } from "vue";
import TextInput from "@/components/common/text-input/TextInput.vue";
import { testIds } from "@/utils/testing";
import { TextInputType } from "./TextInput.types";
import { IconNames } from "@/components/common/icon/Icon.types";

defineProps<{ id?: string; modelValue: string; isSecret?: boolean }>();

const copyToClipboardIcon = ref("content_copy" as IconNames);
const onCopyToClipboardClick = (value?: string | number) => {
  if (!value) {
    throw new Error("Cannot copy to clipboard. No value in input.");
  }

  navigator.clipboard
    .writeText(value.toString())
    .then(() => {
      copyToClipboardIcon.value = "check_circle";
    })
    .catch(() => (copyToClipboardIcon.value = "close"))
    .finally(() =>
      setTimeout(() => (copyToClipboardIcon.value = "content_copy"), 1500),
    );
};
</script>
