import Activity from "@/models/activity";
import ActivityLocation from "@/models/activityLocation";
import { ScanConfigurationDTO } from "@/models/scanConfiguration";
import { downloadFileResponse } from "@/utils/downloadFile";
import HttpClient from "@/utils/httpClient";

export const getScanConfiguration = async (
  activityId: string,
  locationId: string,
): Promise<ScanConfigurationDTO> => {
  return await HttpClient.educonfig
    .get<ScanConfigurationDTO>(
      `activities/${activityId}/scan-configurations/${locationId}`,
    )
    .then((response) => response.data);
};

export const downloadConfigurationFile = async (
  activityId: string,
  locationId: string,
): Promise<void> => {
  return await HttpClient.educonfig
    .get<Blob>(
      `activities/${activityId}/scan-configurations/${locationId}/document`,
      {
        params: {
          activityId: activityId,
          locationId: locationId,
        },
        responseType: "blob",
      },
    )
    .then((response) => downloadFileResponse(response));
};

export const downloadMultipleConfigurationFiles = async (
  activity: Activity,
  locations: ActivityLocation[],
): Promise<void> => {
  if (!activity.id) {
    throw new Error(
      "Cannot download multiple configuration files for an activity without id",
    );
  }

  // I've used native URL SearchParams here, cause axios defaults to using bracket style syntax for array queryParams (?foo[]="x"&foo[]="y")
  // but our API uses queryParams without brackets (?foo="x"&foo="y")
  const params = new URLSearchParams({
    activityId: activity.id,
  });
  locations.forEach((location) => params.append("locationIds", location.id));

  return await HttpClient.educonfig
    .get<Blob>("scans/configuration/documents", {
      params,
      responseType: "blob",
    })
    .then((response) => downloadFileResponse(response));
};
