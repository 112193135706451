<template>
  <Filter
    v-model:expanded="expandedFilter"
    :title="title || texts.models.studyProgram.title"
  >
    <Collapsible
      v-for="department in departmentFilterItems"
      v-show="
        !visibleIds ||
        department.studyPrograms.some((studyProgram) =>
          visibleIds?.includes(studyProgram.id),
        )
      "
      :key="department.id"
      v-model:expanded="expandedDepartments[department.id]"
      :data-testid="testIds.studyProgramFilter.department"
    >
      <Chevron
        :expanded="expandedDepartments[department.id]"
        @click="
          expandedDepartments[department.id] =
            !expandedDepartments[department.id]
        "
      />
      <FilterItem
        :id="department.id"
        v-model="department.modelValue"
        :name="department.name"
        @update:modelValue="
          (value) => updateStudyProgramsChecked(department, value)
        "
      />
      <template v-slot:expanded>
        <FilterItem
          v-for="studyProgram in department.studyPrograms"
          v-show="!visibleIds || visibleIds.includes(studyProgram.id)"
          :id="studyProgram.id"
          :key="studyProgram.id"
          v-model="studyProgram.modelValue"
          :name="studyProgram.name"
          :count="counts ? counts[studyProgram.id] || 0 : undefined"
          :data-testid="testIds.studyProgramPicker.studyProgramsLabel"
          class="pl-12"
          @update:modelValue="updateDepartmentChecked(department)"
        />
      </template>
    </Collapsible>
    <FilterItem
      v-for="studyProgram in studyProgramFilterItems"
      v-show="!visibleIds || visibleIds.includes(studyProgram.id)"
      :id="studyProgram.id"
      :key="studyProgram.id"
      v-model="studyProgram.modelValue"
      :name="studyProgram.name"
      :count="counts ? counts[studyProgram.id] || 0 : undefined"
      :data-testid="testIds.studyProgramPicker.studyProgramsLabel"
    />
  </Filter>
</template>

<script setup lang="ts">
import FilterItem from "@/components/common/filter/FilterItem.vue";
import texts from "@/utils/texts";
import { computed, reactive, ref, watch, watchEffect } from "vue";
import Filter from "@/components/common/filter/Filter.vue";
import Collapsible from "@/components/common/collapsible/Collapsible.vue";
import {
  FilterPropsWithChildren,
  createDepartmentFilterItems,
  createNoDepartmentStudyProgramFilterItems,
  getCheckedIds,
} from "./StudyProgramFilter.types";
import { FilterProps } from "@/components/common/filter/FilterItem.types";
import { testIds } from "@/utils/testing";
import Chevron from "@/components/common/chevron/Chevron.vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{
  studyPrograms: LocalizedStudyProgramDTO[];
  title?: string;
  counts?: Record<string, number>;
  reset?: boolean;
  initialCheckedIds?: string[];
  initialExpandedDepartment?: string;
  visibleIds?: string[];
}>();

const emit = defineEmits<{
  filter: [value: string[] | undefined];
}>();

const expandedFilter = ref(true);
const expandedDepartments = reactive<Record<string, boolean>>({});

const activeStudyPrograms = computed<LocalizedStudyProgramDTO[]>(() =>
  props.studyPrograms.filter((sp) => {
    return props.initialCheckedIds?.includes(sp.id) || !sp.isArchived;
  }),
);

const departmentFilterItems = ref<FilterPropsWithChildren[]>(
  createDepartmentFilterItems(
    activeStudyPrograms.value,
    props.initialCheckedIds,
  ),
);

if (props.initialExpandedDepartment) {
  expandedDepartments[props.initialExpandedDepartment] = true;
}

departmentFilterItems.value.forEach((department) => {
  updateDepartmentChecked(department);
});

const studyProgramFilterItems = ref<FilterProps[]>(
  createNoDepartmentStudyProgramFilterItems(
    activeStudyPrograms.value,
    props.initialCheckedIds,
  ),
);

function updateStudyProgramsChecked(
  department: FilterPropsWithChildren,
  value: boolean | null | string[],
) {
  if (props.visibleIds) {
    department.studyPrograms.forEach((studyProgram) => {
      if (props.visibleIds?.includes(studyProgram.id)) {
        studyProgram.modelValue = value;
      }
    });
  } else {
    department.studyPrograms.forEach((studyProgram) => {
      studyProgram.modelValue = value;
    });
  }
}

function updateDepartmentChecked(department: FilterPropsWithChildren) {
  const allChecked = department.studyPrograms.every((item) => item.modelValue);
  const someChecked = department.studyPrograms.some((item) => item.modelValue);

  department.modelValue = allChecked ? true : someChecked ? null : false;
}

watchEffect(() => {
  const departmentCheckedIds = departmentFilterItems.value.reduce<string[]>(
    (previous, department) =>
      previous.concat(getCheckedIds(department.studyPrograms)),
    [],
  );

  const studyProgramCheckedIds = getCheckedIds(studyProgramFilterItems.value);
  const checked = departmentCheckedIds.concat(studyProgramCheckedIds);

  emit("filter", checked.length > 0 ? checked : undefined);
});

watch(
  () => props.reset,
  () => {
    departmentFilterItems.value.forEach((department) => {
      department.modelValue = false;
      department.studyPrograms.forEach((studyProgram) => {
        studyProgram.modelValue = false;
      });
    });

    studyProgramFilterItems.value.forEach((studyProgram) => {
      studyProgram.modelValue = false;
    });
  },
);
</script>
