<template>
  <div
    :class="[
      {
        'sm:grid-cols-3': label,
        'sm:grid-cols-1': !label,
      },
      'grid grid-cols-1 gap-1 sm:items-start sm:gap-x-4',
    ]"
  >
    <label
      v-if="label"
      :for="formFieldId"
      class="block text-sm font-medium text-gray-700"
    >
      {{ label }}
    </label>
    <div class="mt-4 sm:mt-0">
      <div class="relative flex max-w-lg items-start">
        <div class="flex h-5 items-center">
          <Checkbox
            :id="formFieldId"
            v-model="value"
            :name="formFieldId"
            :valid="!errorMessage"
          />
        </div>
        <div class="ml-3 text-sm">
          <label
            v-if="title"
            :for="formFieldId"
            class="font-medium text-gray-700"
          >
            {{ title }}
          </label>
          <p v-if="description" class="text-gray-500">
            {{ description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="errorMessage"
    class="text-sm text-alert-500 sm:col-start-2"
    data-testid="error-message"
  >
    {{ errorMessage }}
  </div>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import Checkbox from "./Checkbox.vue";

const props = defineProps<{
  formFieldId: string;
  label?: string;
  title?: string;
  description?: string;
}>();

const { value, errorMessage } = useField<boolean>(props.formFieldId);
</script>
