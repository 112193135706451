<template>
  <div
    class="flex items-center gap-2 text-sm font-medium text-deepteal-700"
    :title="name"
  >
    <Checkbox
      :id="id"
      v-model="modelValueInternal"
      :value="id"
      :name="id"
      :data-testid="testIds.action.filter"
    ></Checkbox>
    <span
      v-if="color"
      class="h-3 w-3 rounded-full"
      v-bind="colorDotColorAttribute()"
    ></span>
    <div class="flex-1 truncate">
      <label :for="id" class="truncate">{{ name }}</label>
    </div>
    <span v-if="count !== undefined" class="text-deepteal-300">
      {{ count }}
    </span>
  </div>
</template>

<script setup lang="ts">
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import { computed } from "vue";
import { FilterProps } from "./FilterItem.types";
import { testIds } from "@/utils/testing";

const props = defineProps<FilterProps>();

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean | null | string[]): void;
}>();

const modelValueInternal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const colorIsClassName = props.color?.substring(0, 2) === "bg";

const colorDotColorAttribute = () => {
  if (props.color && !colorIsClassName) {
    return { style: { backgroundColor: props.color } };
  }
  if (props.color && colorIsClassName) {
    return { class: props.color };
  }

  return null;
};
</script>
