<template>
  <button class="print:hidden" @click="handler">
    <IconAsync
      v-if="canViewPersonalData()"
      :state="state"
      icon="download"
      filled
      :size="IconSize.sm"
      :color="Color.Lavender"
      :stroke="IconStroke.md"
    ></IconAsync>
  </button>
</template>

<script setup lang="ts">
import { downloadRegistrationLinkRegistrantsExportCSV } from "@/services/registrationLink.service";
import { Color } from "@/enums";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import { IconSize, IconStroke } from "@/components/common/icon/Icon.types";
import { useAsyncState } from "@/components/common/async/Async.types";
import texts from "@/utils/texts";
import { canViewPersonalData } from "@/router/helpers/userCanGoTo";

const props = defineProps<{
  registrationLinkId: string;
}>();

const { handler, state } = useAsyncState(
  () => downloadRegistrationLinkRegistrantsExportCSV(props.registrationLinkId),
  texts.navigationItems.organize.activity.registrationLinks.download,
);
</script>
