<template>
  <template v-if="edit">
    <div>
      <SingleSelectCombobox
        :id="fieldId"
        v-model="modelValue"
        :name="fieldId"
        :options="context.activities"
        :formatOptionDisplayString="optionFormatter"
        :searchFilter="searchFilter"
        :valid="!errorMessage"
      ></SingleSelectCombobox>
      <span v-if="errorMessage" class="text-xs text-alert-700">
        {{ errorMessage }}
      </span>
    </div>
  </template>
  <template v-else>
    <span>
      {{
        context.activities.find((activity) => activity.id === modelValue)
          ?.name ?? ""
      }}
    </span>
  </template>
</template>

<script setup lang="ts">
import SingleSelectCombobox from "@/components/common/combobox/SingleSelectCombobox.vue";
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import { useField } from "vee-validate";
import * as yup from "yup";
import {
  LocalizedActivityDTO,
  SelectionCriterionDto,
} from "@/lib/eduConfigurationServiceClient";
import { fields } from "@/utils/miscellaneous";
import { computed } from "vue";

const props = defineProps<CriterionFieldProps>();

const fieldId = fields<SelectionCriterionDto>().activityIds;

const modelValue = computed({
  get() {
    return value.value?.[0];
  },
  set(newValue?: string) {
    value.value = newValue ? [newValue] : [];
  },
});

const { value, errorMessage } = useField<typeof props.criterion.activityIds>(
  fieldId,
  yup.array().of(yup.string()).max(1).required(),
  {
    initialValue: [...(props.criterion.activityIds ?? [])], // Shallow-copy to decouple field-array from the original
  },
);

const searchFilter = (activity: LocalizedActivityDTO, query: string) =>
  activity.name?.toLowerCase().includes(query.toLowerCase()) ?? false;

const optionFormatter = (activity: LocalizedActivityDTO) => activity.name ?? "";
</script>
