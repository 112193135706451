<template>
  <Modal
    v-model:visible="visibleInternal"
    :type="ModalType.danger"
    :description="
      texts.navigationItems.organize.targetAudiences.modal.description
    "
    :title="texts.navigationItems.organize.targetAudiences.modal.title"
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <ButtonDelete
          :data-testid="testIds.action.confirm"
          class="sm:ml-3"
          :loading="actionLoading"
          :disabled="!visibleInternal"
          @click="performDelete"
        />
        <ButtonCancel
          class="mt-3 sm:mt-0"
          :disabled="actionLoading || !visibleInternal"
          @click="visibleInternal = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import { testIds } from "@/utils/testing";
import { computed, ref } from "vue";
import texts from "@/utils/texts";
import Notify from "@/utils/notify";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { ValidationErrorResponse } from "@/lib/eduConfigurationServiceClient";

const componentTexts = texts.navigationItems.organize.targetAudiences.edit;

const props = defineProps<{
  deletingId?: string | undefined;
}>();

const emit = defineEmits<{
  (e: "update:deletingId", value: string | undefined): void;
}>();

const visibleInternal = computed({
  get: () => !!props.deletingId,
  set: (value) => {
    if (!value) emit("update:deletingId", undefined);
  },
});

// Delete target audience modal
const actionLoading = ref(false);
// Deleting target audience
const performDelete = () => {
  if (!props.deletingId) {
    throw new Error("Target audience id is undefined");
  }

  eduConfigurationServiceClient
    .deleteTargetAudience(props.deletingId)
    .then(() => {
      Notify.success(componentTexts.deleteSuccess);

      emit("update:deletingId", undefined);
      visibleInternal.value = false;
    })
    .catch((error) => {
      if (error instanceof ValidationErrorResponse) {
        Notify.failure(texts.validationErrors[error.errorCode]);
        return;
      }

      Notify.failure(componentTexts.deleteFailure);
    });
};
</script>
