<template>
  <Page class="flex h-full flex-col gap-8 pb-8">
    <template v-if="query">
      <PageHeading class="">
        {{ componentTexts.title }}
        <span
          v-if="searchResults"
          class="grow text-nowrap text-xl text-deepteal-600"
        >
          {{ `(${searchResults.length} ${texts.generic.results})` }}
        </span>
        <Search v-model="query" class="w-96 grow"></Search>
      </PageHeading>
      <div
        v-if="loadingQuery"
        class="flex flex-col gap-4 lg:grid lg:items-center"
      >
        <div class="flex items-center justify-between gap-4 lg:col-span-2">
          <TransitionAppear :show="loadingQuery"><Spinner /></TransitionAppear>
        </div>
      </div>
      <List v-if="searchResults && searchResults.length > 0">
        <template v-slot:header>
          <ListItemColumn>
            {{ texts.models.contact.fullName }}
          </ListItemColumn>
          <ListItemColumn>
            {{ texts.models.contact.emailAddress }}
          </ListItemColumn>
        </template>
        <RouterLinkAuth
          v-for="(searchResult, index) in searchResults"
          :key="index"
          v-slot="{ navigate }"
          :to="{
            name: RouteNamesContacts.MAINPAGE,
            params: { id: searchResult.id },
          }"
          custom
        >
          <ListItem
            :data-testId="testIds.contacts.contactRow"
            :title="`${searchResult.fullName} - ${searchResult.emailAddress}`"
            clickable
            @click="navigate"
          >
            <ListItemColumn accent>
              <Icon icon="account_circle" />
              <span class="truncate">{{ searchResult.fullName }}</span>
            </ListItemColumn>
            <ListItemColumn>
              <span class="truncate">{{ searchResult.emailAddress }}</span>
            </ListItemColumn>
          </ListItem>
        </RouterLinkAuth>
      </List>
    </template>
    <template v-else>
      <ContactsSearch :loading="loadingQuery" @submit="doInitialQuery" />
    </template>
  </Page>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { SessionStorageKey } from "@/enums";
import Search from "@/components/common/search/Search.vue";
import List from "@/components/common/list/List.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { ProspectSearchResult } from "@/models/contact";
import { getContactQueryResult } from "@/services/contact.service";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { RouteNamesContacts } from "@/router/routeNames";
import logger from "@/plugins/logger";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Spinner from "@/components/common/spinner/Spinner.vue";
import TransitionAppear from "@/components/common/transition/TransitionAppear.vue";
import ContactsSearch from "./ContactsSearch.vue";
import texts from "@/utils/texts";
import Page from "@/components/common/page/Page.vue";
import Icon from "@/components/common/icon/Icon.vue";

const componentTexts = texts.navigationItems.contacts;

const query = ref<string | undefined>(
  sessionStorage.getItem(SessionStorageKey.ContactsSearchQuery) || undefined,
);
const loadingQuery = ref<boolean>(false);

const searchResults = ref<ProspectSearchResult[]>();

const doInitialQuery = (initialQuery: string | undefined) => {
  query.value = initialQuery;
};

const notifyQueryError = () => {
  Notify.failure({
    title: componentTexts.query.failureTitle,
    content: componentTexts.query.failureContent,
  });
};

watch(
  query,
  (newValue) => {
    if (!newValue) {
      searchResults.value = undefined;
      sessionStorage.removeItem(SessionStorageKey.ContactsSearchQuery);
      return;
    }

    sessionStorage.setItem(SessionStorageKey.ContactsSearchQuery, newValue);

    loadingQuery.value = true;
    getContactQueryResult(newValue)
      .then((dtos) => {
        const mappedResults = dtos.map((dto) => new ProspectSearchResult(dto));

        searchResults.value = mappedResults;
      })
      .catch((e) => {
        notifyQueryError();

        logger.error(e);
      })
      .finally(() => {
        loadingQuery.value = false;
      });
  },
  { immediate: true },
);
</script>
