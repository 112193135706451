export interface ProspectQueryDTO {
  textQuery: string | undefined;
}

export interface ProspectSearchResultDTO {
  id: string;
  fullName: string;
  emailAddress: string;
}

export class ProspectSearchResult {
  id: string;
  fullName: string;
  emailAddress: string;

  constructor(dto: ProspectSearchResultDTO) {
    this.id = dto.id;
    this.fullName = dto.fullName;
    this.emailAddress = dto.emailAddress;
  }
}

export interface ProspectDTO {
  id: string;
  firstName: string;
  infix?: string;
  lastName: string;
  emailAddress: string;
  dateOfBirth?: string;
  gender?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  country?: string;
  postalCode?: string;
  city?: string;
  street?: string;
  houseNumber?: string;
  houseNumberExtension?: string;
  receiveMarketingEmail: boolean;
}

export type ProspectUpdateDTO = ProspectDTO;

export class Prospect {
  id: string;
  firstName: string;
  infix?: string;
  lastName: string;
  emailAddress: string;
  dateOfBirth?: string;
  gender?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  country?: string;
  postalCode?: string;
  city?: string;
  street?: string;
  houseNumber?: string;
  houseNumberExtension?: string;
  receiveMarketingEmail: boolean;

  constructor(dto: ProspectDTO) {
    this.id = dto.id;
    this.firstName = dto.firstName;
    this.infix = dto.infix;
    this.lastName = dto.lastName;
    this.emailAddress = dto.emailAddress;
    this.dateOfBirth = dto.dateOfBirth;
    this.gender = dto.gender;
    this.phoneNumber = dto.phoneNumber;
    this.mobilePhoneNumber = dto.mobilePhoneNumber;
    this.country = dto.country;
    this.postalCode = dto.postalCode;
    this.city = dto.city;
    this.street = dto.street;
    this.houseNumber = dto.houseNumber;
    this.houseNumberExtension = dto.houseNumberExtension;
    this.receiveMarketingEmail = dto.receiveMarketingEmail;
  }
}
