<template>
  <SlideOver
    v-model:visible="slideOverOpen"
    :title="texts.navigationItems.contact.edit.title"
  >
    <ContactForm
      :formValues="{
        ...props.contact,
        dateOfBirth: props.contact.dateOfBirth
          ? DateTime.fromFormat(props.contact.dateOfBirth, 'yyyy-MM-dd')
          : undefined,
      }"
      @submit="onSubmit"
    >
      <ButtonSubmit
        data-testid="submitForm"
        :loading="actionLoading"
      ></ButtonSubmit>

      <ButtonCancel @click="cancelUpdate"></ButtonCancel>
    </ContactForm>
  </SlideOver>
</template>

<script setup lang="ts">
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { Prospect, ProspectUpdateDTO } from "@/models/contact";
import { RootState } from "@/store";
import { useStore } from "vuex";
import ContactForm from "./ContactForm.vue";
import { ContactFormValues } from "./ContactForm.types";
import { updateContact } from "@/services/contact.service";
import { computed, ref } from "vue";
import Notify from "@/utils/notify";
import logger from "@/plugins/logger";
import { DateTime } from "luxon";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];
const componentTexts = texts.navigationItems.contact.edit;

const props = defineProps<{
  contact: Prospect;
  editProfile: boolean;
}>();

const slideOverOpen = computed({
  get() {
    return props.editProfile;
  },
  set(value) {
    emit("update:editProfile", value);
  },
});

const emit = defineEmits<{
  (e: "update:editProfile", value: boolean): void;
}>();

// Update Contact

const actionLoading = ref(false);

const onSubmit = (values: ContactFormValues) => {
  actionLoading.value = true;

  const convertedDateOfBirth = values.dateOfBirth?.toFormat("yyyy-MM-dd");

  const prospectUpdateDTO: ProspectUpdateDTO = {
    id: props.contact.id,
    ...values,
    dateOfBirth: convertedDateOfBirth,
  };

  updateContact(prospectUpdateDTO)
    .then(() => {
      Notify.success(componentTexts.success);
    })
    .catch((error) => {
      logger.error(error);
      Notify.failure(componentTexts.failure);
    })
    .finally(() => {
      actionLoading.value = false;
      slideOverOpen.value = false;
    });
};

const cancelUpdate = () => {
  slideOverOpen.value = false;
};
</script>
