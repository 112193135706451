<template>
  <LayoutTwoCol>
    <template v-slot:left>
      <ProspectCountStats
        :prospectCount="prospectCount"
        :title="texts.navigationItems.enrollments.stats.uniqueProspects"
        :subtitle="
          texts.navigationItems.enrollments.stats.currentRecruitmentYear
        "
        :color="color"
      />
    </template>
    <ProspectStatsPerMonth
      :fromDate="fromDate"
      :toDate="toDate"
      :prospectCountsPerMonth="prospectCountsPerMonth"
      :color="color"
      class="h-44"
    />
  </LayoutTwoCol>
</template>

<script setup lang="ts">
import { ProspectCountPerMonthDto } from "@/lib/eduConfigurationServiceClient";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import texts from "@/utils/texts";
import { ref } from "vue";
import { DateTime } from "luxon";
import LayoutTwoCol from "@/components/common/layout/LayoutTwoCol.vue";
import ProspectCountStats from "@/components/stats/ProspectCountStats.vue";
import ProspectStatsPerMonth from "@/components/stats/ProspectStatsPerMonth.vue";
import { Color } from "@/enums";

const loading = ref<boolean>(true);

defineProps<{
  color: Color;
}>();

const fromDate = ref<DateTime>(DateTime.now());
const toDate = ref<DateTime>(DateTime.now());
const prospectCount = ref<number>(0);
const prospectCountsPerMonth = ref<ProspectCountPerMonthDto[]>();

getRecruitmentYear()
  .then((recruitmentYear) => {
    const currentRecruitmentYearDates =
      recruitmentYear.toCurrentRecruitmentYearDates();

    fromDate.value = currentRecruitmentYearDates.start;
    toDate.value = currentRecruitmentYearDates.end;
  })
  .then(() => {
    eduConfigurationServiceClient
      .getStudyProgramEnrollmentsStats(fromDate.value, toDate.value)
      .then((response) => {
        prospectCount.value = response.prospectCount;
      });
  })
  .then(async () => {
    prospectCountsPerMonth.value =
      await eduConfigurationServiceClient.getStudyProgramEnrollmentsStatsPerMonth(
        fromDate.value,
        toDate.value,
      );
  })
  .catch((error) => {
    logger.error(error);
    error.value = true;
  })
  .finally(() => {
    loading.value = false;
  });
</script>
