import { Months } from "@/enums";

export enum RecruitmentYearSettingsFormKeys {
  StartDay = "recruitmentYearStartDay",
  StartMonth = "recruitmentYearStartMonth",
  EndDay = "recruitmentYearEndDay",
  EndMonth = "recruitmentYearEndMonth",
}

export interface RecruitmentYearSettingsFormValues {
  [RecruitmentYearSettingsFormKeys.StartDay]?: string;
  [RecruitmentYearSettingsFormKeys.StartMonth]?: string;
  [RecruitmentYearSettingsFormKeys.EndDay]: string;
  [RecruitmentYearSettingsFormKeys.EndMonth]: string;
}

export const daysInMonths: Record<Months, number> = {
  January: 31,
  February: 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};

export const getDaysArray = (month: Months) => {
  return [...Array(daysInMonths[month]).keys()];
};
