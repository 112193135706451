<template>
  <Overview
    @clicked:create="createOpen = true"
    @clicked:details="handleDetailsClick($event.id)"
  />
  <AccountCreate v-model:visible="createOpen" />
  <AccountEdit v-model:id="editingId" />
</template>

<script setup lang="ts">
import Overview from "@/views/settings/users/Overview.vue";
import { ref } from "vue";
import AccountCreate from "@/views/settings/users/AccountCreate.vue";
import AccountEdit from "@/views/settings/users/AccountEdit.vue";

const createOpen = ref(false);
const editingId = ref();

const handleDetailsClick = (id: string) => {
  editingId.value = id;
};
</script>
