<template>
  <div class="grid h-full min-h-0 grid-cols-1 xl:grid-cols-12">
    <div
      class="mb-1 flex flex-col items-start gap-4 border-b px-1 py-4 xl:col-span-3 xl:mb-0 xl:border-b-0 xl:border-r xl:py-8 xl:pr-8"
    >
      <button
        v-for="item in observableItems"
        :key="item.id"
        type="button"
        :class="[{ 'font-bold': focusedItem.element === item.element }]"
        @click="item.element?.scrollIntoView()"
      >
        {{ item.label }}
      </button>
    </div>
    <div
      class="flex flex-col gap-12 overflow-y-auto px-1 py-4 xl:col-span-9 xl:py-8 xl:pl-8"
    >
      <div
        v-for="item in observableItems"
        :id="item.id"
        :key="item.id"
        :ref="(el) => setElement(item, el)"
        class="max-w-2xl"
      >
        <component :is="item.component" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  computed,
  ComponentPublicInstance,
  ref,
  reactive,
  markRaw,
} from "vue";
import { AnchorMenuItem, ObservableAnchorMenuItem } from "./AnchorMenu.types";
import { hasPermissions } from "@/router/helpers/userCanGoTo";

const props = defineProps<{
  items: AnchorMenuItem[];
}>();

const observableItems = ref<ObservableAnchorMenuItem[]>(
  props.items
    .filter((item) => !item.permissions || hasPermissions(item.permissions))
    .map((item) =>
      reactive({
        id: item.id,
        label: item.label,
        component: markRaw(item.component),
        visibility: 0,
        element: undefined,
      }),
    ),
);

const focusedItem = computed(() =>
  observableItems.value.reduce((result, item) => {
    if (item.visibility > result.visibility) return item;
    else return result;
  }, observableItems.value[0]),
);

onMounted(() => {
  const observer = new IntersectionObserver(
    (callback) => {
      for (let index = 0; index < observableItems.value.length; index++) {
        const entry = callback.find(
          (entry) => observableItems.value[index].element === entry.target,
        );
        if (entry) {
          observableItems.value[index].visibility = entry.intersectionRatio;
        }
      }
    },
    { threshold: [0.25, 0.5, 0.75, 1] },
  );

  observableItems.value.forEach((item) => {
    if (!item.element) {
      throw new Error("No element to observe.");
    }
    observer.observe(item.element);
  });
});

function setElement(
  item: ObservableAnchorMenuItem,
  ele: Element | ComponentPublicInstance | null,
) {
  if (ele instanceof Element) {
    item.element = ele;
    return;
  }
  item.element = undefined;
}
</script>
