<template>
  <FormField :id :label :displayMode :rules :initialValue>
    <template v-slot="{ value, errorMessage }">
      <Combobox
        :id="id"
        v-model="value.value"
        :name="label"
        :valid="!errorMessage.value"
        :options="options"
        :formatOptionDisplayString="(option) => option.name"
        :searchFilter="
          (option, query) =>
            option.name.toLowerCase().includes(query.toLowerCase())
        "
        :searchResultsCompare="
          (left, right) => left.name.localeCompare(right.name)
        "
        :disabled="disabled"
        @update:modelValue="selectedOptions($event)"
      />
    </template>
  </FormField>
</template>

<script setup lang="ts">
import FormField from "@/components/common/form/FormField.vue";
import Combobox from "@/components/common/combobox/Combobox.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import { AnySchema } from "yup";
import { RegistrationLinkWithRegistrationCountDTO } from "@/lib/eduConfigurationServiceClient";

defineProps<{
  id: string;
  options: RegistrationLinkWithRegistrationCountDTO[];
  displayMode?: FormFieldDisplayMode;
  rules?: AnySchema;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  initialValue?: string[];
}>();

const emit = defineEmits<{
  (e: "update:selectedOptions", value: string[] | undefined): void;
}>();

const selectedOptions = (value: string[] | undefined) => {
  emit("update:selectedOptions", value);
};
</script>
