<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="
      question.getLocalizedLabel(currentLocale) ??
      texts.models.question.newQuestion
    "
  >
    <FormTemplateQuestionSettingsForm
      :formValues="formValues"
      :currentLocale="currentLocale"
      :question="question"
      @submit="submitUpdate"
    >
      <ButtonSubmit :loading="actionLoading" />
      <ButtonCancel @click="cancelUpdate" />
    </FormTemplateQuestionSettingsForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { Culture } from "@/enums";
import { FormQuestionDTO } from "@/lib/formsServiceClient";
import FormTemplateQuestionSettingsForm from "@/views/settings/forms/components/form-template-editor/FormTemplateQuestionSettingsForm.vue";
import { computed, ref } from "vue";
import {
  QuestionSettingsFormValues,
  convertToFormValues,
} from "./FormTemplateQuestionSettingsForm.types";
import { SelectOption } from "@/components/common/select/SelectOption";
import texts from "@/utils/texts";

const props = defineProps<{
  visible: boolean;
  currentLocale: Culture;
  question: FormQuestionDTO;
  conditionalQuestions: FormQuestionDTO[];
  questionOptions: SelectOption[];
}>();

const emit = defineEmits<{
  "update:visible": [boolean];
  "update:succeeded": [boolean];
  "update:cancel": [];
  "update:question:settings": [question: QuestionSettingsFormValues];
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const formValues = computed(() =>
  convertToFormValues(props.question, props.conditionalQuestions),
);

const submitUpdate = (values: QuestionSettingsFormValues) => {
  visibleInternal.value = false;
  emit("update:question:settings", values);
};

const cancelUpdate = () => {
  visibleInternal.value = false;
  emit("update:cancel");
};

const actionLoading = ref<boolean>(false);
</script>
