<template>
  <div class="flex flex-col gap-8">
    <div class="flex flex-col gap-4">
      <h2 class="flex-grow text-lg font-semibold text-deepteal-700">
        {{ componentTexts.title }}
      </h2>
    </div>
    <div class="flex-flex-col gap-4">
      <h3 class="text-base font-semibold text-deepteal-700">
        {{ componentTexts.import.title }}
      </h3>
      <span class="text-sm font-light text-deepteal-700">
        {{ componentTexts.import.description }}
      </span>
    </div>
    <div class="flex-flex-col gap-4">
      <Button
        :leadingIcon="'note_add'"
        :size="ButtonSize.sm"
        @click="showPreEducationSchoolsImport = true"
      >
        {{ componentTexts.importSchoolsButton }}
      </Button>
    </div>

    <PreEducationSchoolsImport
      v-model:visible="showPreEducationSchoolsImport"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import PreEducationSchoolsImport from "@/views/organize/schools/components/PreEducationSchoolsImport.vue";
import texts from "@/utils/texts";

const componentTexts = texts.navigationItems.organize.schools;

// Import pre-education schools
const showPreEducationSchoolsImport = ref(false);
</script>
