<template>
  <ListItem
    :class="[
      'border-l-2 transition-colors duration-75',
      checkedInternal.includes(studyProgram.id)
        ? 'border-deepteal-500'
        : 'border-transparent',
    ]"
    :title="studyProgram.displayName"
  >
    <ListItemColumn :flex="2">
      <label class="flex w-full items-center gap-4">
        <Checkbox
          :id="studyProgram.id"
          v-model="checkedInternal"
          name="activity-study-programs"
          :value="studyProgram.id"
        ></Checkbox>
        <span class="truncate">{{ studyProgram.displayName }}</span></label
      >
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import { computed } from "vue";

const props = defineProps<{
  studyProgram: LocalizedStudyProgramDTO;
  checked: string[];
}>();

const emit = defineEmits<{
  "update:checked": [string[]];
}>();

const checkedInternal = computed({
  get: () => props.checked,
  set: (value) => emit("update:checked", value),
});
</script>
