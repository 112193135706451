<template>
  <Card v-if="stats.length > 0" :size="CardSize.lg" class="flex flex-col gap-2">
    <CardHeader :text="componentTexts.titleActual"></CardHeader>

    <div class="grid grid-cols-6 gap-4">
      <div class="col-span-4 text-sm text-gray-500">
        {{ componentTexts.location }}
      </div>
      <div class="text-sm leading-none text-gray-500">
        {{ componentTexts.visitors }}
      </div>
      <template v-for="stat in stats" :key="stat.locationId">
        <span class="col-span-4 flex-1 truncate text-sm font-medium lg:flex-2">
          {{ stat.locationName }}
        </span>
        <div class="col-span-2 grid grid-cols-6 gap-2">
          <span class="self-center text-right text-sm font-bold text-lime-500">
            {{ stat.registrantCount }}
          </span>
          <span
            v-if="stat.visitorCount != stat.registrantCount"
            class="col-span-5 flex-1 self-center truncate text-xs font-medium lg:flex-2"
          >
            {{ ` (${stat.visitorCount} ${componentTexts.withRelatives})` }}
          </span>
        </div>
      </template>
    </div>
  </Card>
</template>

<script setup lang="ts">
import { VisitorCountPerLocationDto } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import { CardSize } from "@/components/common/card/Card.types";

defineProps<{
  stats: VisitorCountPerLocationDto[];
}>();

const componentTexts =
  texts.navigationItems.organize.activity.reports.visitors.perLocation;
</script>
