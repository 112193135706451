import {
  TrialDayConfigurationDto,
  TrialDayOverviewDto,
} from "@/lib/eduConfigurationServiceClient";
import { StudyProgramDTO } from "@/models/study-program";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";

export interface TrialDayOverviewItem {
  configuration: TrialDayConfigurationDto;
  studyProgramId?: string;
  studyProgramDisplayName?: string;
  isOrphaned: boolean;
  prospectCount: number;
}

export const toTrialDayOverviewItems = (
  trialDayConfigurations: TrialDayConfigurationDto[],
  prospectCountsPerStudyProgram: TrialDayOverviewDto[],
  studyPrograms: StudyProgramDTO[],
): TrialDayOverviewItem[] => {
  const studyProgramItems = trialDayConfigurations
    .flatMap((config) =>
      config.studyProgramSettings.map((settings) => {
        return {
          configurationId: config.id,
          studyProgramId: settings.studyProgramId,
        };
      }),
    )
    .map((cs) => {
      const configuration = trialDayConfigurations.find(
        (config) => config.id == cs.configurationId,
      );
      const studyProgram = studyPrograms.find(
        (studyProgram) => studyProgram.id == cs.studyProgramId,
      );
      const count = prospectCountsPerStudyProgram.find(
        (count) =>
          count.formId == configuration?.formId &&
          count.studyProgramId == studyProgram?.id,
      );
      return {
        configuration: configuration,
        studyProgramId: studyProgram?.id,
        studyProgramDisplayName: studyProgram?.displayName,
        prospectCount: count?.prospectCount || 0,
      } as TrialDayOverviewItem;
    })
    .sort(
      (a, b) =>
        a.configuration.name.localeCompare(b.configuration.name) ||
        (a.studyProgramDisplayName ?? "").localeCompare(
          b.studyProgramDisplayName ?? "",
        ),
    );

  const orphanedItemsWithConfig = prospectCountsPerStudyProgram
    .filter(
      (count) =>
        count.studyProgramId &&
        trialDayConfigurations.find(
          (config) => config.formId == count.formId,
        ) &&
        !trialDayConfigurations.find(
          (config) =>
            config.formId == count.formId &&
            config.studyProgramSettings
              .flatMap((settings) => settings.studyProgramId)
              .includes(count.studyProgramId!),
        ),
    )
    .map((count) => {
      const configuration = trialDayConfigurations.find(
        (config) => config.formId == count.formId,
      );
      const studyProgram = studyPrograms.find(
        (studyProgram) => studyProgram.id == count.studyProgramId,
      );

      return {
        configuration: configuration,
        studyProgramId: count.studyProgramId,
        studyProgramDisplayName: studyProgram?.displayName,
        prospectCount: count.prospectCount,
        isOrphaned: true,
      } as TrialDayOverviewItem;
    })
    .sort(
      (a, b) =>
        a.configuration.name.localeCompare(b.configuration.name) ||
        (a.studyProgramDisplayName ?? "").localeCompare(
          b.studyProgramDisplayName ?? "",
        ),
    );

  const orphanedItemsWithoutConfig = prospectCountsPerStudyProgram
    .filter(
      (count) =>
        count.studyProgramId &&
        !trialDayConfigurations.find((config) => config.formId == count.formId),
    )
    .map((count) => {
      const studyProgram = studyPrograms.find(
        (studyProgram) => studyProgram.id == count.studyProgramId,
      );

      const configuration = {
        name: texts.navigationItems.organize.trialDays.overview.orphanedItems,
      } as TrialDayConfigurationDto;

      return {
        configuration: configuration,
        studyProgramId: count.studyProgramId,
        studyProgramDisplayName: studyProgram?.displayName,
        prospectCount: count.prospectCount,
        isOrphaned: true,
      } as TrialDayOverviewItem;
    })
    .sort(
      (a, b) =>
        a.configuration.name.localeCompare(b.configuration.name) ||
        (a.studyProgramDisplayName ?? "").localeCompare(
          b.studyProgramDisplayName ?? "",
        ),
    );

  return [
    ...studyProgramItems,
    ...orphanedItemsWithConfig,
    ...orphanedItemsWithoutConfig,
  ];
};

export const canWriteTrialDays = () =>
  hasPermissions([Permission.ActivitiesWrite]);
