<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center gap-2">
      <slot></slot>
    </div>
    <TransitionStretch :show="!!expandedInternal" class="flex flex-col gap-4">
      <slot name="expanded"></slot>
    </TransitionStretch>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import TransitionStretch from "@/components/common/transition/TransitionStretch.vue";

const props = defineProps<{
  expanded?: boolean;
}>();

const emit = defineEmits<{
  "update:expanded": [value: boolean];
}>();

const expandedInternal = computed({
  get: () => props.expanded,
  set: (value) => emit("update:expanded", value),
});
</script>
