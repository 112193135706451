<template>
  <TransitionFade
    ><PageHeading v-show="greeting" :title="greeting"
  /></TransitionFade>
</template>

<script setup lang="ts">
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import { interpolate } from "@/dictionary";
import texts from "@/utils/texts";
import { DateTime } from "luxon";
import { computed } from "vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import auth from "@/store/context/auth.context";

const now = DateTime.now();
const hours = now.hour;
const month = now.month;
const day = now.day;

const greetings: [check: () => boolean, text: string][] = [
  [() => month === 1 && day === 1, texts.greetings.newYear],
  [() => month === 2 && day === 14, texts.greetings.valentine],
  [() => month === 3 && day === 21, texts.greetings.spring],
  [() => month === 4 && day === 1, texts.greetings.fools],
  [() => month === 6 && day === 21, texts.greetings.summer],
  [() => month === 9 && day === 21, texts.greetings.autumn],
  [() => month === 10 && day === 31, texts.greetings.halloween],
  [() => month === 12 && day === 21, texts.greetings.winter],
  [() => month === 12 && day === 25, texts.greetings.christmas],
  [() => hours >= 18 && hours <= 23, texts.greetings.evening],
  [() => hours >= 12 && hours < 18, texts.greetings.afternoon],
  [() => hours >= 6 && hours < 12, texts.greetings.morning],
  [() => hours >= 0 && hours < 6, texts.greetings.night],
];

const greeting = computed(() => {
  const greeting = greetings.find(([check]) => check());
  if (!greeting || !auth.name) return;
  return interpolate(greeting[1], auth.name);
});
</script>
