<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-12">
    <Statistics :color="pageColor" />
    <LayoutTwoCol v-if="overview && settings.studyPrograms">
      <template v-slot:left>
        <ApplicationsFilter
          :items="overview"
          :studyPrograms="settings.studyPrograms"
          :color="pageColor"
          class="xl:col-span-3"
          @filter="(event) => (filteredOverview = event)"
        />
      </template>

      <div class="xl:col-span-9">
        <div>
          <div class="flex flex-col gap-3">
            <div class="mb-5 flex items-center justify-between">
              <h3 class="text-xl font-semibold text-deepteal-500 xl:text-2xl">
                {{ filteredOverview.length }}
                {{ texts.models.studyProgram.plural }}
              </h3>
              <div class="flex items-center gap-2">
                <Button
                  v-if="canWrite"
                  :leadingIcon="'note_add'"
                  :size="ButtonSize.sm"
                  :color="Color.White"
                  :class="['border-none', 'shadow-sm', 'focus:none']"
                  @click="showImport = true"
                >
                  {{ componentTexts.overview.importApplications }}
                </Button>
                <ApplicationsDropdownButton />
              </div>
            </div>
            <span
              v-if="!loading && overview.length === 0"
              data-testid="no-applications-found-message"
              class="text-sm italic text-gray-500"
              >{{ componentTexts.overview.noResultsFound }}</span
            >
            <List>
              <template v-slot:header>
                <ListItemColumn :flex="0"
                  ><div class="h-6 w-6"></div>
                </ListItemColumn>
                <ListItemColumn :flex="6">
                  {{ texts.models.studyProgram.plural }}
                </ListItemColumn>
                <ListItemColumn hide class="justify-end">
                  {{ componentTexts.stats.applications }}
                </ListItemColumn>
              </template>
              <ApplicationsItem
                v-for="item in filteredOverview"
                :key="item.studyProgramId"
                :item="item"
                :color="pageColor"
              />
            </List>
          </div>
        </div>
      </div>
    </LayoutTwoCol>
  </Page>

  <ApplicationsImport v-model:visible="showImport" @success="fetchOverview" />
</template>

<script setup lang="ts">
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import Button from "@/components/common/button/Button.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import { Color } from "@/enums";
import Page from "@/components/common/page/Page.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import texts from "@/utils/texts";
import { ref } from "vue";
import {
  ApplicationsOverviewItem,
  toApplicationsOverview,
} from "./Overview.types";
import ApplicationsDropdownButton from "./components/ApplicationsDropdownButton.vue";
import ApplicationsFilter from "./components/ApplicationsFilter.vue";
import ApplicationsItem from "./components/ApplicationsItem.vue";
import ApplicationsImport from "./components/ApplicationsImport.vue";
import Statistics from "./components/Statistics.vue";
import settings from "@/store/context/settings.context";
import { DateTime } from "luxon";
import LayoutTwoCol from "@/components/common/layout/LayoutTwoCol.vue";
import logger from "@/plugins/logger";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import { hasPermissions } from "@/router/helpers/userCanGoTo";

const pageColor = Color.Orchid;
const componentTexts = texts.navigationItems.applications;

const loading = ref<boolean>(true);
const error = ref<boolean>(false);

const fromDate = ref<DateTime>(DateTime.now());
const toDate = ref<DateTime>(DateTime.now());

const canWrite = hasPermissions([Permission.ApplicationsWrite]);

getRecruitmentYear()
  .then((recruitmentYear) => {
    const currentRecruitmentYearDates =
      recruitmentYear.toCurrentRecruitmentYearDates();

    fromDate.value = currentRecruitmentYearDates.start;
    toDate.value = currentRecruitmentYearDates.end;
  })
  .then(() => {
    fetchOverview();
  })
  .catch((e) => {
    error.value = true;
    logger.error(e);
  })
  .finally(() => {
    loading.value = false;
  });

// Get Applications Overview & StudyPrograms
const overview = ref<Array<ApplicationsOverviewItem>>([]);
const filteredOverview = ref<Array<ApplicationsOverviewItem>>([]);

const fetchOverview = () => {
  loading.value = true;
  Promise.all([
    eduConfigurationServiceClient.getStudyProgramApplicationsOverview(
      fromDate.value,
      toDate.value,
    ),
  ])
    .then(([ov]) => {
      overview.value = toApplicationsOverview(ov, settings.studyPrograms);
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

// Import applications
const showImport = ref(false);
</script>
