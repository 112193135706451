import { RegistrationsAtDateDTO } from "@/models/registrationsAtDate";
import { Color } from "@/enums";
import { ChartData, ChartOptions } from "chart.js";
import texts from "@/utils/texts";
import { DateTime } from "luxon";

const colorMap = new Map<Color, [borderColor: string, backgroundColor: string]>(
  [
    [Color.DeepTeal, ["rgba(9, 75, 77, 1)", "rgba(9, 75, 77, 0.1)"]],
    [Color.Emerald, ["rgba(0, 200, 144, 1)", "rgba(0, 200, 144, 0.1)"]],
    [Color.Lavender, ["rgba(115,104,249, 1)", "rgba(115,104,249, 0.1)"]],
  ],
);

export interface LineChartData {
  date: DateTime;
  count: number;
}

export const toLineChartData = (
  registrationsAtDate: RegistrationsAtDateDTO[],
): LineChartData[] => {
  let sum = 0;
  return registrationsAtDate.map((element) => {
    sum = sum + element.count;
    return {
      date: DateTime.fromISO(element.date),
      count: sum,
    };
  });
};

const textsReports = texts.navigationItems.organize.activity.reports;

export const createLineChart = (
  data: LineChartData[],
  color: Color,
): ChartData<"line"> => {
  return {
    labels: data.map((element) =>
      element.date.toLocaleString(DateTime.DATE_FULL),
    ),
    datasets: [
      {
        label: textsReports.registrantCount,
        data: data.map((element) => element.count),
        borderColor: colorMap.get(color)?.[0],
        backgroundColor: colorMap.get(color)?.[1],
        borderWidth: 3,
        cubicInterpolationMode: "monotone", // "Smooth lines"
        fill: true, // Fill area under the line with backgroundColor
        pointRadius: 0, // Don't show points
      },
    ],
  };
};

export const chartOptions = (
  labels?: (string | undefined)[],
): ChartOptions<"line"> => {
  const scaleColor = "rgba(0, 0, 0, 0.4)";
  const scaleFont = { size: 11 };
  const gridColor = "rgba(0, 0, 0, 0.1)";

  const scaleStyle = { color: scaleColor, font: scaleFont };
  const customLabels = labels
    ? {
        callback: (_value: unknown, index: number) => {
          return labels[index] !== labels[index - 1] // Avoid duplication of labels on x-axis
            ? labels[index]
            : undefined;
        },
      }
    : undefined;

  return {
    interaction: {
      mode: "index", // Show the value of the nearest index of the hover location
      intersect: false, // Always show the value of the nearest index (not just on hovering point)
    },
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: {
        title: {
          text: textsReports.registrationsGraph.weeksUntilActivity,
          display: true,
          ...scaleStyle,
        },
        grid: { display: false },
        ticks: {
          ...scaleStyle,
          ...customLabels,
        },
      },
      y: {
        min: 0,
        grid: { color: gridColor },
        ticks: { ...scaleStyle },
      },
    },
  };
};
