import {
  LocalizedStudyProgramDTO,
  StudyProgramApplicationsOverviewDto,
} from "@/lib/eduConfigurationServiceClient";

export interface ApplicationsOverviewItem {
  studyProgramId?: string;
  studyProgramDisplayName?: string;
  prospectCount: number;
}

export const toApplicationsOverview = (
  prospectCountsPerStudyProgram: StudyProgramApplicationsOverviewDto[],
  studyPrograms: LocalizedStudyProgramDTO[],
): ApplicationsOverviewItem[] => {
  return prospectCountsPerStudyProgram
    .map((count) => {
      const studyProgram = studyPrograms.find(
        (studyProgram) => studyProgram.id == count.studyProgramId,
      );
      return {
        studyProgramId: studyProgram?.id,
        studyProgramDisplayName: studyProgram?.displayName,
        prospectCount: count.prospectCount,
      } as ApplicationsOverviewItem;
    })
    .sort((a, b) => {
      if (!a.studyProgramId || !b.studyProgramId) {
        return !a.studyProgramId ? -1 : 1;
      }

      if (a.studyProgramDisplayName && b.studyProgramDisplayName) {
        return a.studyProgramDisplayName.toLowerCase() <
          b.studyProgramDisplayName.toLowerCase()
          ? -1
          : a.studyProgramDisplayName.toLowerCase() >
              b.studyProgramDisplayName.toLowerCase()
            ? 1
            : 0;
      }

      return 0;
    });
};
