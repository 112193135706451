import {
  getLocalizationsQueryable,
  Localization,
  QueryableLocalizations,
} from "./helpers/localizable";

export interface StudyProgramMetadataLocalizationDTOLocalizableProperties {
  name: string;
}

export interface StudyProgramMetadataLocalizationDTO {
  areaOfInterestLocalizations: Localization<StudyProgramMetadataLocalizationDTOLocalizableProperties>[];
  departmentLocalizations: Localization<StudyProgramMetadataLocalizationDTOLocalizableProperties>[];
  levelLocalizations: Localization<StudyProgramMetadataLocalizationDTOLocalizableProperties>[];
  locationLocalizations: Localization<StudyProgramMetadataLocalizationDTOLocalizableProperties>[];
  modeLocalizations: Localization<StudyProgramMetadataLocalizationDTOLocalizableProperties>[];
}

export class StudyProgramMetadataLocalization {
  areaOfInterestLocalizations: QueryableLocalizations<StudyProgramMetadataLocalizationDTOLocalizableProperties>;
  departmentLocalizations: QueryableLocalizations<StudyProgramMetadataLocalizationDTOLocalizableProperties>;
  levelLocalizations: QueryableLocalizations<StudyProgramMetadataLocalizationDTOLocalizableProperties>;
  locationLocalizations: QueryableLocalizations<StudyProgramMetadataLocalizationDTOLocalizableProperties>;
  modeLocalizations: QueryableLocalizations<StudyProgramMetadataLocalizationDTOLocalizableProperties>;

  constructor(dto: StudyProgramMetadataLocalizationDTO) {
    this.areaOfInterestLocalizations = getLocalizationsQueryable(
      dto.areaOfInterestLocalizations,
    );
    this.departmentLocalizations = getLocalizationsQueryable(
      dto.departmentLocalizations,
    );
    this.levelLocalizations = getLocalizationsQueryable(dto.levelLocalizations);
    this.locationLocalizations = getLocalizationsQueryable(
      dto.locationLocalizations,
    );
    this.modeLocalizations = getLocalizationsQueryable(dto.modeLocalizations);
  }
}
