<template>
  <Menu
    as="div"
    class="group relative flex items-center"
    data-testid="profile-menu"
  >
    <div>
      <MenuButton
        class="rounded-full bg-white text-sm group-focus-within:outline-none group-focus-within:ring-white group-focus-within:ring-offset-4 group-focus-within:ring-offset-lavender-500"
        :title="tenant.name"
      >
        <span class="sr-only">Open user menu</span>
        <TransitionFade>
          <img
            v-if="auth.picture"
            data-testid="avatar"
            class="bg-bright absolute h-8 w-8 rounded-full"
            :src="auth.picture"
          />
        </TransitionFade>
        <Icon
          icon="account_circle"
          :size="IconSize.lg"
          class="text-gray-400"
          aria-hidden="true"
        />
      </MenuButton>
    </div>
    <Transition
      enterActiveClass="transition ease-out duration-100"
      enterFromClass="transform opacity-0 scale-95"
      enterToClass="transform opacity-100 scale-100"
      leaveActiveClass="transition ease-in duration-75"
      leaveFromClass="transform opacity-100 scale-100"
      leaveToClass="transform opacity-0 scale-95"
    >
      <MenuItems
        data-testid="menu-items"
        :class="[
          {
            'bottom-14 left-0 z-menuBar origin-bottom-left':
              origin === ApplicationMenuOrigin.BottomLeft,
            'right-0 top-14 z-menuBar origin-top-right':
              origin === ApplicationMenuOrigin.TopRight,
            'left-0 top-14 z-menuBar origin-top-left':
              origin === ApplicationMenuOrigin.TopLeft,
          },
          'absolute w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
        ]"
      >
        <div class="px-4 py-3">
          <p class="text-sm">
            {{ texts.profileMenuItems.signedInAs }}
          </p>
          <p class="truncate text-sm font-medium text-gray-900">
            {{ auth.email }}
          </p>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <RouterLinkAuth
              to="/tenants"
              :class="[
                active ? 'bg-gray-100' : '',
                'group flex items-center justify-between px-4 py-2 text-sm',
              ]"
            >
              <span :class="[active ? 'text-gray-900' : 'text-gray-700']">{{
                texts.profileMenuItems.switchTenant
              }}</span>
              <Icon
                icon="arrow_right_alt"
                :size="IconSize.lg"
                :class="[
                  'mr-3',
                  active ? 'text-lavender-600' : 'text-lavender-500',
                ]"
                aria-hidden="true"
              />
            </RouterLinkAuth>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full px-4 py-2 text-left text-sm',
              ]"
              @click="logout"
            >
              {{ texts.profileMenuItems.signout }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </Transition>
    <MenuButton v-if="showName" class="pl-3 text-left">
      <p class="text-sm font-semibold text-gray-500">{{ auth.name }}</p>
      <p
        v-if="tenant"
        class="text-xs font-medium text-gray-400"
        :data-testid="testIds.tenantName"
      >
        {{ tenant.name }}
      </p>
    </MenuButton>
  </Menu>
</template>

<script setup lang="ts">
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { RootStore } from "@/store/index";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { useStore } from "vuex";
import RouterLinkAuth from "../router/router-link-auth/RouterLinkAuth.vue";
import { ApplicationMenuOrigin } from "@/components/application-shell/ApplicationMenu.types";
import { testIds } from "@/utils/testing";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import tenant from "@/store/context/tenant.context";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { RouteNames } from "@/router/routeNames";
import { useRouter } from "vue-router";
import auth from "@/store/context/auth.context";

withDefaults(
  defineProps<{ origin: ApplicationMenuOrigin; showName?: boolean }>(),
  {
    showName: false,
  },
);

const router = useRouter();

const logout = () => {
  router.push({ name: RouteNames.LOGOUT });
};

const store = useStore<RootStore>();

const texts = dictionary[store.getters["cultureStore/active"] as Culture];
</script>
