import {
  Module,
  ActionTree,
  GetterTree,
  MutationTree,
  ActionContext,
} from "vuex";

import Activity, { ActivityExtended } from "@/models/activity";
import ActivityType from "@/models/activityType";
import { getActivities, getActivity } from "@/services/activity.service";
import { getActivityTypes } from "@/services/activityTypes.service";

import { RootState } from "@/store/index";
import logger from "@/plugins/logger";
import { DateTime } from "luxon";

export interface ActivityStoreState {
  selectedDateTime: DateTime;
  allActivities: Activity[];
  loadingAllActivities: boolean;
  errorLoadingAllActivities: boolean;

  activities: Activity[];
  loadingActivities: boolean;
  errorLoadingActivities: boolean;

  activityTypes: ActivityType[];
  loadingActivityTypes: boolean;
  errorLoadingActivityTypes: boolean;

  activity: ActivityExtended | null;
  loadingActivity: boolean;
  errorLoadingActivity: boolean;

  selectedActivityTypeId: string | null;
  includeOldActivities: boolean;
  inputName: string | null;
}

const state: ActivityStoreState = {
  selectedDateTime: DateTime.now(),
  allActivities: [],
  loadingAllActivities: false,
  errorLoadingAllActivities: false,

  activities: [],
  loadingActivities: false,
  errorLoadingActivities: false,

  activityTypes: [],
  loadingActivityTypes: false,
  errorLoadingActivityTypes: false,

  activity: null,
  loadingActivity: false,
  errorLoadingActivity: false,

  selectedActivityTypeId: null,
  includeOldActivities: false,
  inputName: null,
};

interface ActivityStoreActions
  extends ActionTree<ActivityStoreState, RootState> {
  loadAllActivities(
    context: ActionContext<ActivityStoreState, RootState>,
  ): Promise<void>;
  loadActivities(
    context: ActionContext<ActivityStoreState, RootState>,
  ): Promise<void>;
  loadActivityTypes(
    context: ActionContext<ActivityStoreState, RootState>,
  ): Promise<void>;
  loadActivity(
    context: ActionContext<ActivityStoreState, RootState>,
    activityId: string | null,
  ): void;
  setSelectedActivityTypeId(
    context: ActionContext<ActivityStoreState, RootState>,
    activityType: string | null,
  ): void;
  setIncludeOldActivities(
    context: ActionContext<ActivityStoreState, RootState>,
    includeOldActivities: boolean,
  ): void;
  setInputName(
    context: ActionContext<ActivityStoreState, RootState>,
    inputName: string | null,
  ): void;
}

const actions: ActivityStoreActions = {
  async loadAllActivities(context) {
    try {
      context.commit("setErrorLoadingAllActivities", false);
      context.commit("setLoadingAllActivities", true);
      const response = await getActivities();
      const activities = response.map((dto) => new Activity(dto));

      context.commit("setAllActivities", activities);
    } catch (error) {
      context.commit("setErrorLoadingAllActivities", true);
      logger.error(error as Error);
    } finally {
      context.commit("setLoadingAllActivities", false);
    }
  },
  async loadActivities(context) {
    try {
      context.commit("setErrorLoadingActivities", false);
      context.commit("setLoadingActivities", true);
      const response = await getActivities(
        context.state.selectedActivityTypeId,
        context.state.includeOldActivities,
        context.state.inputName,
      );
      const activities = response.map((dto) => new Activity(dto));

      context.commit("setActivities", activities);
    } catch (error) {
      context.commit("setErrorLoadingActivities", true);
      throw error;
    } finally {
      context.commit("setLoadingActivities", false);
    }
  },
  async loadActivityTypes(context) {
    try {
      context.commit("setErrorLoadingActivityTypes", false);
      context.commit("setLoadingActivityTypes", true);
      const response = await getActivityTypes();
      const activityTypes = response.map((dto) => new ActivityType(dto));

      context.commit("setActivityTypes", activityTypes);
    } catch (error) {
      context.commit("setErrorLoadingActivityTypes", true);
      throw error;
    } finally {
      context.commit("setLoadingActivityTypes", false);
    }
  },
  async loadActivity(context, activityId) {
    if (!activityId)
      throw new Error(`Cannot perform action. No activityId provided.`);
    try {
      context.commit("setErrorLoadingActivity", false);
      context.commit("setLoadingActivity", true);

      const response = await getActivity(activityId);
      const activity = new ActivityExtended(response);

      context.commit("setActivity", activity);
    } catch (error) {
      context.commit("setErrorLoadingActivity", true);
      throw error;
    } finally {
      context.commit("setLoadingActivity", false);
    }
  },
  setSelectedActivityTypeId(context, activityTypeId) {
    context.commit("setSelectedActivityTypeId", activityTypeId);
  },
  setIncludeOldActivities(context, includeOldActivities) {
    context.commit("setIncludeOldActivities", includeOldActivities);
  },
  setInputName(context, inputName) {
    context.commit("setInputName", inputName);
  },
};

interface ActivityStoreGetters
  extends GetterTree<ActivityStoreState, RootState> {
  selectedDateTime: (state: ActivityStoreState) => DateTime | null;
  allActivities: (state: ActivityStoreState) => Activity[];
  loadingAllActivities: (state: ActivityStoreState) => boolean;
  errorLoadingAllActivities: (state: ActivityStoreState) => boolean;

  activities: (state: ActivityStoreState) => Activity[];
  loadingActivities: (state: ActivityStoreState) => boolean;
  errorLoadingActivities: (state: ActivityStoreState) => boolean;

  activityTypes: (state: ActivityStoreState) => ActivityType[];
  loadingActivityTypes: (state: ActivityStoreState) => boolean;
  errorLoadingActivityTypes: (state: ActivityStoreState) => boolean;

  activity: (state: ActivityStoreState) => ActivityExtended | null;
  loadingActivity: (state: ActivityStoreState) => boolean;
  errorLoadingActivity: (state: ActivityStoreState) => boolean;

  selectedActivityTypeId: (state: ActivityStoreState) => string | null;
  includeOldActivities: (state: ActivityStoreState) => boolean;
  inputName: (state: ActivityStoreState) => string | null;
}

const getters: ActivityStoreGetters = {
  selectedDateTime: (state) => state.selectedDateTime,
  allActivities: (state) => state.allActivities,
  loadingAllActivities: (state) => state.loadingAllActivities,
  errorLoadingAllActivities: (state) => state.errorLoadingAllActivities,

  activities: (state) => state.activities,
  loadingActivities: (state) => state.loadingActivities,
  errorLoadingActivities: (state) => state.errorLoadingActivities,

  activityTypes: (state) => state.activityTypes,
  loadingActivityTypes: (state) => state.loadingActivityTypes,
  errorLoadingActivityTypes: (state) => state.errorLoadingActivityTypes,

  activity: (state) => state.activity,
  loadingActivity: (state) => state.loadingActivity,
  errorLoadingActivity: (state) => state.errorLoadingActivity,

  selectedActivityTypeId: (state) => state.selectedActivityTypeId,
  includeOldActivities: (state) => state.includeOldActivities,
  inputName: (state) => state.inputName,
};

interface ActivityStoreMutations extends MutationTree<ActivityStoreState> {
  setSelectedDateTime(
    state: ActivityStoreState,
    selectedDateTime: DateTime,
  ): void;
  setAllActivities(state: ActivityStoreState, activities: Activity[]): void;
  setLoadingAllActivities(state: ActivityStoreState, isLoading: boolean): void;
  setErrorLoadingAllActivities(
    state: ActivityStoreState,
    hasError: boolean,
  ): void;

  setActivities(state: ActivityStoreState, activities: Activity[]): void;
  setLoadingActivities(state: ActivityStoreState, isLoading: boolean): void;
  setErrorLoadingActivities(state: ActivityStoreState, hasError: boolean): void;

  setActivityTypes(state: ActivityStoreState, activity: ActivityType[]): void;
  setLoadingActivityTypes(state: ActivityStoreState, isLoading: boolean): void;
  setErrorLoadingActivityTypes(
    state: ActivityStoreState,
    hasError: boolean,
  ): void;

  setActivity(
    state: ActivityStoreState,
    activity: ActivityExtended | null,
  ): void;
  setLoadingActivity(state: ActivityStoreState, isLoading: boolean): void;
  setErrorLoadingActivity(state: ActivityStoreState, hasError: boolean): void;

  setSelectedActivityTypeId(
    state: ActivityStoreState,
    activityType: string | null,
  ): void;
  setIncludeOldActivities(
    state: ActivityStoreState,
    includeOldActivities: boolean,
  ): void;
  setInputName(state: ActivityStoreState, inputName: string | null): void;
}

const mutations: ActivityStoreMutations = {
  setSelectedDateTime(state, selectedDateTime) {
    state.selectedDateTime = selectedDateTime;
  },
  setAllActivities(state, activities) {
    state.allActivities = activities;
  },
  setLoadingAllActivities(state, isLoading) {
    state.loadingAllActivities = isLoading;
  },
  setErrorLoadingAllActivities(state, hasError) {
    state.errorLoadingAllActivities = hasError;
  },

  setActivities(state, activities) {
    state.activities = activities;
  },
  setLoadingActivities(state, isLoading) {
    state.loadingActivities = isLoading;
  },
  setErrorLoadingActivities(state, hasError) {
    state.errorLoadingActivities = hasError;
  },

  setActivityTypes(state, activityTypes) {
    state.activityTypes = activityTypes;
  },
  setLoadingActivityTypes(state, isLoading) {
    state.loadingActivityTypes = isLoading;
  },
  setErrorLoadingActivityTypes(state, hasError) {
    state.errorLoadingActivityTypes = hasError;
  },

  setActivity(state, activity) {
    state.activity = activity;
  },
  setLoadingActivity(state, isLoading) {
    state.loadingActivity = isLoading;
  },
  setErrorLoadingActivity(state, hasError) {
    state.errorLoadingActivity = hasError;
  },

  setSelectedActivityTypeId(state, activityTypeId) {
    state.selectedActivityTypeId = activityTypeId;
  },
  setIncludeOldActivities(state, includeOldActivities) {
    state.includeOldActivities = includeOldActivities;
  },
  setInputName(state, inputName) {
    state.inputName = inputName;
  },
};

export interface ActivityStore extends Module<ActivityStoreState, RootState> {
  namespaced: true;
  state?: ActivityStoreState;
  getters: ActivityStoreGetters;
  actions: ActivityStoreActions;
  mutations?: ActivityStoreMutations;
}

const activityModule: ActivityStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default activityModule;
