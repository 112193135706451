<template>
  <ListItem
    :data-testid="testIds.templates.mailTemplateItem"
    :title="`${template.name}`"
    clickable
  >
    <RouterLinkAuth
      v-slot="{ navigate }"
      :to="{
        name: mapMailTemplateTypeToRoute(template.type),
        params: { id: template.id },
      }"
      custom
    >
      <ListItemColumn :flex="0" @click="navigate">
        <Icon icon="mail"></Icon>
      </ListItemColumn>
      <ListItemColumn :flex="2" accent @click="navigate">
        <span class="truncate">{{ template.name }}</span>
      </ListItemColumn>
      <ListItemColumn hide @click="navigate">
        <span class="truncate">{{ textsTemplateType[template.type] }}</span>
      </ListItemColumn>
    </RouterLinkAuth>
    <ListItemColumn :flex="0">
      <ListItemActions>
        <DropdownItem :data-testid="testIds.action.copy" @click="emit('copy')">
          <Icon icon="file_copy" :color="Color.DeepTeal"></Icon>
          <span class="text-deepteal-500">{{ texts.actions.copy }}</span>
        </DropdownItem>
        <Divider class="mx-2 my-1" />
        <DropdownItem
          :data-testid="testIds.action.delete"
          @click="emit('delete')"
        >
          <Icon icon="delete" :color="Color.Alert"></Icon>
          <span class="text-alert-500">{{ texts.actions.delete }}</span>
        </DropdownItem>
      </ListItemActions>
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import Icon from "@/components/common/icon/Icon.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { MailTemplate, MailTemplateType } from "@/models/template";
import { RouteNamesSettings } from "@/router/routeNames";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { Color, EmailTemplateType, MailingType } from "@/enums";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Divider from "@/components/common/divider/Divider.vue";

defineProps<{
  template: MailTemplate;
}>();

const emit = defineEmits<{
  copy: [];
  delete: [];
}>();

const mapMailTemplateTypeToRoute = (type: MailTemplateType) => {
  if (type in MailingType) {
    return RouteNamesSettings.EMAIL_EDIT_MAILING_TEMPLATE;
  }
  if (type in EmailTemplateType) {
    return RouteNamesSettings.EMAIL_EDIT_EMAIL_TEMPLATE;
  }
};

const textsTemplateType: Record<MailTemplateType, string> = {
  ...texts.enums.mailingType,
  ...texts.enums.emailTemplateType,
};
</script>
