<template>
  <div
    class="mb-5 grid grid-cols-1 gap-1 sm:items-start sm:gap-x-4"
    :class="{ 'sm:grid-cols-3': label, 'sm:grid-cols-1': !label }"
  >
    <label
      v-if="label"
      :for="formFieldId"
      class="block text-sm font-medium text-gray-500"
    >
      {{ label }}
    </label>
    <div class="mt-4 sm:mt-0">
      <div class="max-w-lg space-y-4">
        <div
          v-for="checkbox in items"
          :key="checkbox.value"
          class="relative flex items-start"
          :data-testid="testIds.targetAudiencePicker.targetAudience"
        >
          <div class="flex h-5 items-center">
            <checkbox
              :id="checkbox.id"
              v-model="checkedValues"
              :name="formFieldId"
              :value="checkbox.value"
              :valid="!errorMessage"
              :disabled="checkbox.disabled"
              :data-testid="testIds.action.filter"
            >
            </checkbox>
          </div>
          <div class="ml-3 text-sm">
            <label
              v-if="checkbox.label"
              :for="checkbox.id"
              class="font-medium text-gray-500"
            >
              {{ checkbox.label }}
            </label>
            <p v-if="checkbox.description" class="text-gray-500">
              {{ checkbox.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="text-sm text-alert-500 sm:col-start-2"
      data-testid="error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { testIds } from "@/utils/testing";
import { useField } from "vee-validate";
import { computed } from "vue";
import Checkbox from "./Checkbox.vue";
import { MultiCheckboxFieldItem } from "./MultiCheckboxField.types";

const props = defineProps<{
  formFieldId: string;
  items: MultiCheckboxFieldItem[];
  label?: string;
  singleSelect?: boolean;
}>();

const { value, errorMessage } = useField<string[] | undefined>(
  props.formFieldId,
);

const checkedValues = computed<string[]>({
  get: () => value.value || [],
  set: (newValue) => {
    value.value = newValue.length > 0 ? newValue : [];
    selectIfSingleSelect(newValue[0] || undefined);
  },
});

const selectIfSingleSelect = (checkedValue?: string) => {
  if (!props.singleSelect) return;

  props.items.forEach((item) => {
    const isNotNewlySelected = checkedValue && item.value !== checkedValue;
    const isNotAlreadyFiltered =
      value.value &&
      value.value.length > 0 &&
      !value.value.includes(item.value);

    item.disabled = isNotNewlySelected || isNotAlreadyFiltered;
  });
};
selectIfSingleSelect();
</script>
