<template>
  <div :class="wrapperClass">
    <label
      v-if="label"
      :for="formFieldId"
      class="block text-sm font-medium text-gray-700"
      >{{ label }}</label
    >
    <TextInput
      :id="formFieldId"
      v-model="value"
      :name="formFieldId"
      :type="type"
      :placeholder="placeholder"
      :valid="!errorMessage"
      :disabled="disabled"
      :min="min"
      :max="max"
      :class="[
        { 'col-span-2': fullWidth && label, 'col-span-3': fullWidth && !label },
      ]"
    ></TextInput>
    <div
      v-if="errorMessage"
      :class="['text-sm text-alert-500', { 'sm:col-start-2': label }]"
      data-testid="error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import TextInput from "./TextInput.vue";
import { useField } from "vee-validate";
import { TextInputType } from "./TextInput.types";

const props = withDefaults(
  defineProps<{
    formFieldId: string;
    type?: TextInputType;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    min?: number | string;
    max?: number | string;
    fullWidth?: boolean;
    wrapperClass?: string;
  }>(),
  {
    type: TextInputType.TEXT,
    label: undefined,
    placeholder: undefined,
    disabled: false,
    min: undefined,
    max: undefined,
    fullWidth: false,
    wrapperClass:
      "mb-5 grid grid-cols-1 gap-1 sm:grid-cols-3 sm:items-center sm:gap-x-4",
  },
);

const { errorMessage, value } = useField<string | undefined>(props.formFieldId);
</script>
