<template>
  <SlideOver
    v-model:visible="slideOverOpen"
    :title="componentTexts.sessionProgramSettingsSlideOver.title"
    :subTitle="componentTexts.sessionProgramSettingsSlideOver.subTitle"
  >
    <SlideOverSessionSettingsForm
      v-if="!actionLoading && sessionProgramSettings"
      v-model:formValues="sessionProgramSettings"
      @submit="submitSessionProgramSettings"
    >
      <ButtonSubmit :loading="actionLoading"></ButtonSubmit>
      <Button
        :label="texts.actions.cancel"
        :color="Color.White"
        @click="closeSlideover"
      ></Button>
    </SlideOverSessionSettingsForm>
  </SlideOver>
</template>

<script setup lang="ts">
import Button from "@/components/common/button/Button.vue";
import dictionary from "@/dictionary";
import { Color, Culture } from "@/enums";
import { SessionProgramSettingsUpdateDTO } from "@/models/session";
import logger from "@/plugins/logger";
import {
  getActivity,
  updateSessionProgramSettings,
} from "@/services/activity.service";
import { RootState } from "@/store/index";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { SessionProgramSettingsFormValues } from "@/views/organize/activities/activity/sessions/components/session-settings/SlideOverSessionSettingsForm.types";
import SlideOverSessionSettingsForm from "@/views/organize/activities/activity/sessions/components/session-settings/SlideOverSessionSettingsForm.vue";
import Notify from "@/utils/notify";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";

const props = defineProps<{
  // TODO: refactor to some SessionProgramSettings model prop type when we
  //  extract the settings from activity.
  activityId: string;
  viewSessionProgramSettings: boolean;
}>();

const emit = defineEmits<{
  (e: "update:viewSessionProgramSettings", value: boolean): void;
}>();

const store = useStore<RootState>();
const texts = dictionary[store.getters["cultureStore/active"] as Culture];
const componentTexts =
  texts.navigationItems.organize.activity.sessions.overview;
const actionLoading = ref<boolean>(false);

const loadProgramSessionSettings = () => {
  actionLoading.value = true;
  getActivity(props.activityId).then((response) => {
    sessionProgramSettings.value = {
      forbidMultipleSessionRegistrationsForStudyProgram:
        response.forbidMultipleSessionRegistrationsPerStudyProgram ?? false,
      forbidOverlappingSessionRegistrations:
        response.forbidOverlappingSessionRegistrations ?? false,
      minimumSessionRegistrationsPerRegistrant:
        response.minimumSessionRegistrationsPerRegistrant,
      maximumSessionRegistrationsPerRegistrant:
        response.maximumSessionRegistrationsPerRegistrant,
      allowGroupSize: response.allowGroupSize ?? false,
      maximumGroupSizePerRegistrant: response.maximumGroupSizePerRegistrant,
    };
    actionLoading.value = false;
  });
};

loadProgramSessionSettings();

const slideOverOpen = computed({
  get() {
    return props.viewSessionProgramSettings;
  },
  set(value) {
    emit("update:viewSessionProgramSettings", value);
  },
});

const sessionProgramSettings = ref<SessionProgramSettingsFormValues>();

const submitSessionProgramSettings = async (
  values: SessionProgramSettingsFormValues,
) => {
  const dto: SessionProgramSettingsUpdateDTO = {
    forbidOverlappingSessionRegistrations:
      values.forbidOverlappingSessionRegistrations,
    forbidMultipleSessionRegistrationsPerStudyProgram:
      values.forbidMultipleSessionRegistrationsForStudyProgram,
    minimumSessionRegistrationsPerRegistrant:
      values.minimumSessionRegistrationsPerRegistrant,
    maximumSessionRegistrationsPerRegistrant:
      values.maximumSessionRegistrationsPerRegistrant,
    allowGroupSize: values.allowGroupSize,
    maximumGroupSizePerRegistrant: values.maximumGroupSizePerRegistrant,
  };

  actionLoading.value = true;

  updateSessionProgramSettings(props.activityId, dto)
    .then(async () => {
      Notify.success(componentTexts.updateSessionProgramSettingsSuccess);

      loadProgramSessionSettings();
      await store.dispatch("activityStore/loadActivity", props.activityId);
    })
    .catch((e) => {
      Notify.failure(componentTexts.updateSessionProgramSettingsFailure);

      logger.error(e);
    });
};

const closeSlideover = () => {
  slideOverOpen.value = false;
};
</script>
