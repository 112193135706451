<template>
  <TransitionFade>
    <Card
      v-if="data && data.length > 0"
      :size="CardSize.lg"
      class="flex flex-col gap-8"
    >
      <CardHeader :text="componentTexts.demoTiles.demo2"></CardHeader>
      <ol class="flex flex-col gap-6">
        <StatsBarListItem
          v-for="school of data"
          :key="school.name"
          :count="school.count"
          :max="max"
          :size="StatsBarSize.md"
          :legend="school.name"
          ><span :title="school.name">{{ school.name }}</span>
        </StatsBarListItem>
      </ol>
    </Card>
  </TransitionFade>
</template>

<script setup lang="ts">
import Card from "@/components/common/card/Card.vue";
import texts from "@/utils/texts";
import { CardSize } from "@/components/common/card/Card.types";
import { StatsBarSize } from "@/components/common/stats-bar/StatsBar.types";
import { ref, watchEffect } from "vue";
import {
  ISelectionDefinitionDto,
  ProspectPreEducationSchoolsStatsDTO,
  SelectionDto,
} from "@/lib/eduConfigurationServiceClient";
import StatsBarListItem from "@/components/common/stats-bar/StatsBarListItem.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";

const componentTexts = texts.navigationItems.dashboard;
const max = ref<number>(0);
const data = ref<ProspectPreEducationSchoolsStatsDTO[]>();

const props = defineProps<{
  selection?: ISelectionDefinitionDto;
}>();

const init = async () => {
  if (!props.selection) return;

  data.value =
    await eduConfigurationServiceClient.getProspectPreEducationSchoolsStats(
      new SelectionDto({ ...props.selection }),
    );

  max.value =
    data.value != null && data.value.length > 0
      ? data.value.sort((a, b) => b.count - a.count)[0].count
      : 0;
};

watchEffect(async () => {
  await init();
});
</script>
