<template>
  <Section>
    <Section>
      <PageHeading
        :title="`${texts.navigationItems.applications.title} / ${texts.navigationItems.enrollments.title}`"
      />
    </Section>
    <RouterNavigationTabs
      :tabs="[
        {
          name: texts.navigationItems.applications.title,
          to: { name: RouteNamesApplicationsEnrollments.APPLICATIONS },
          icon: 'hotel_class',
        },
        {
          name: texts.navigationItems.enrollments.title,
          to: { name: RouteNamesApplicationsEnrollments.ENROLLMENTS },
          icon: 'school',
        },
      ]"
    ></RouterNavigationTabs>
  </Section>
  <RouterView />
</template>

<script setup lang="ts">
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Section from "@/components/common/section/Section.vue";
import RouterNavigationTabs from "@/components/router/router-navigation-tabs/RouterNavigationTabs.vue";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { RouteNamesApplicationsEnrollments } from "@/router/routeNames";
import { RootState } from "@/store";
import { RouterView } from "vue-router";
import { useStore } from "vuex";

const store = useStore<RootState>();

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];
</script>
