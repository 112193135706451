import { Culture } from "@/enums";
import {
  ICreateStudyProgramDTO,
  IUpdateStudyProgramDTO,
  StudyProgramDTO,
} from "@/lib/eduConfigurationServiceClient";

export interface StudyProgramFormValues {
  id?: string;
  localizations: StudyProgramFormValuesLocalization[];
  programLanguageId?: string;
  studyProgramLocationIds?: string[];
  studyProgramAreaOfInterestIds?: string[];
  studyProgramDepartmentId?: string;
  studyProgramModeId?: string;
  studyProgramLevelId?: string;
  externalReference?: string;
}

export interface StudyProgramFormValuesLocalization {
  locale: Culture;
  name: string;
}

export const convertToFormValues = (
  dto: StudyProgramDTO,
): StudyProgramFormValues => {
  return {
    id: dto.id,
    externalReference: dto.externalReference || undefined,
    programLanguageId: dto.programLanguageId,
    studyProgramLocationIds:
      dto.studyProgramLocations.length > 0
        ? dto.studyProgramLocations.map(
            (studyProgramLocation) => studyProgramLocation.id,
          )
        : undefined,
    studyProgramAreaOfInterestIds:
      dto.areasOfInterest.length > 0
        ? dto.areasOfInterest.map(
            (studyProgramAreaOfInterest) => studyProgramAreaOfInterest.id,
          )
        : undefined,
    studyProgramDepartmentId: dto.studyProgramDepartment?.id,
    studyProgramModeId: dto.studyProgramMode?.id,
    studyProgramLevelId: dto.studyProgramLevel?.id,
    localizations: dto.localizations as StudyProgramFormValuesLocalization[],
  };
};

export const convertToCreateDTO = (
  values: StudyProgramFormValues,
): ICreateStudyProgramDTO => ({
  programLanguageId: values.programLanguageId,
  studyProgramLocationIds: values.studyProgramLocationIds || [],
  areaOfInterestIds: values.studyProgramAreaOfInterestIds || [],
  studyProgramDepartmentId: values.studyProgramDepartmentId,
  studyProgramModeId: values.studyProgramModeId,
  studyProgramLevelId: values.studyProgramLevelId,
  externalReference: values.externalReference,
  localizations: values.localizations.filter((loc) => loc.name),
});

export const convertToUpdateDTO = (
  id: string,
  values: StudyProgramFormValues,
  isArchived: boolean,
): IUpdateStudyProgramDTO => {
  return {
    id,
    programLanguageId: values.programLanguageId,
    studyProgramLocationIds: values.studyProgramLocationIds || [],
    areaOfInterestIds: values.studyProgramAreaOfInterestIds || [],
    studyProgramDepartmentId: values.studyProgramDepartmentId,
    studyProgramModeId: values.studyProgramModeId,
    studyProgramLevelId: values.studyProgramLevelId,
    externalReference: values.externalReference,
    localizations: values.localizations.filter((loc) => loc.name),
    isArchived: isArchived,
  };
};
