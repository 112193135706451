import {
  getLocalizationsQueryable,
  Localization,
  QueryableLocalizations,
} from "./helpers/localizable";

export interface StudyProgramLevelDTOLocalizableProperties {
  name: string;
}

export interface StudyProgramLevelCreateDTO {
  sequenceNumber?: number;
  localizations: Localization<StudyProgramLevelDTOLocalizableProperties>[];
}

export interface StudyProgramLevelUpdateDTO extends StudyProgramLevelCreateDTO {
  id: string;
}

export interface StudyProgramLevelDTO {
  id: string;
  sequenceNumber?: number;
  name: string;
}

export interface StudyProgramLevelExtendedDTO {
  id: string;
  sequenceNumber?: number;
  localizations: Localization<StudyProgramLevelDTOLocalizableProperties>[];
}

export class StudyProgramLevel {
  id: string;
  sequenceNumber?: number;
  name: string;

  constructor(dto: StudyProgramLevelDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.name = dto.name;
  }
}

export class StudyProgramLevelExtended {
  id: string;
  sequenceNumber?: number;
  localizations: QueryableLocalizations<StudyProgramLevelDTOLocalizableProperties>;

  constructor(dto: StudyProgramLevelExtendedDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.localizations = getLocalizationsQueryable(dto.localizations);
  }
}
