<template>
  <div class="flex h-full w-full flex-col gap-8">
    <div class="flex flex-col gap-2">
      <PageHeading>
        {{ componentTexts.edit.slideOver.detailsSection.title }}
      </PageHeading>
      <PageSubTitle>
        {{ componentTexts.edit.slideOver.detailsSection.description }}
      </PageSubTitle>
    </div>

    <template v-if="registrationLink">
      <div class="flex items-center gap-16">
        <TextInputWithCopy :modelValue="registrationLink.uri" class="flex-1" />
        <RegistrationLinkEditActions :regLink="registrationLink" />
        <Button
          leadingIcon="settings"
          :color="Color.White"
          :size="ButtonSize.sm"
          :data-testid="testIds.action.edit"
          @click="showLinkDetails = true"
        >
          {{ texts.actions.viewDetails }}
        </Button>
      </div>

      <Divider />

      <div class="flex flex-col gap-8">
        <SectionHeading
          :divider="false"
          :title="componentTexts.edit.slideOver.formSection.title"
        />
        <Alert
          v-if="!localizedForm?.isTemplate"
          class="col-span-2 col-start-2 -mt-4"
          :content="texts.models.registrationLink.formIsInstance"
        />
        <template v-if="localizedForm?.isTemplate">
          <Alert class="-mt-4">
            <span>{{ texts.models.registrationLink.formIsTemplate }}</span>
            <RouterLinkAuth
              :to="{
                name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
                params: { id: localizedForm.id },
              }"
              class="font-semibold"
            >
              <TextButton
                :label="
                  texts.navigationItems.organize.activity.registrationLinks.edit
                    .slideOver.detailSection.editForm
                "
                :color="Color.Info"
              />
            </RouterLinkAuth>
          </Alert>
          <TextInput
            v-model:modelValue="localizedForm.name"
            :disabled="true"
            class="w-1/2"
          />
        </template>

        <FormTemplateEditor
          v-else-if="registrationForm"
          :dto="registrationForm"
          :submitting="submittingRegForm"
          @submit="submitRegForm"
        />
      </div>

      <SlideOver
        v-model:visible="showLinkDetails"
        :title="componentTexts.edit.slideOver.detailSection.title"
        :subTitle="componentTexts.edit.slideOver.detailSection.description"
      >
        <RegistrationLinkEditForm
          :formValues="convertToEditFormValues(registrationLink, activeCulture)"
          @submit="submitRegDetails"
        >
          <div
            v-if="registrationLink && registrationForm"
            class="flex w-full flex-col gap-2 sm:flex-row-reverse sm:items-center"
          >
            <ButtonSubmit
              v-if="!deleting"
              :loading="submittingRegDetails"
              data-testid="submit-details"
            />
            <ButtonCancel v-if="!deleting" @click="showLinkDetails = false" />
            <ButtonDelete v-if="!deleting" @click="deleting = true" />
            <SlideOverDeleteConfirm
              v-if="deleting"
              :loading="deleteLoading"
              @confirm="submitDelete"
              @cancel="deleting = false"
            />
          </div>
        </RegistrationLinkEditForm>
      </SlideOver>
    </template>
  </div>
</template>

<script setup lang="ts">
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import SlideOverDeleteConfirm from "@/components/common/slideover/SlideOverDeleteConfirm.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { Color, Culture } from "@/enums";
import { RegistrationLinkDTO } from "@/lib/eduConfigurationServiceClient";
import { FormDTO, FormType, LocalizedFormDTO } from "@/lib/formsServiceClient";
import router from "@/router/index";
import {
  RouteNamesActivityOverview,
  RouteNamesSettings,
} from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import {
  convertToEditFormValues,
  convertToUpdateDTO,
  RegistrationLinkEditFormValues,
} from "@/views/organize/activities/activity/registration-links/components/RegistrationLinkForm.types";
import FormTemplateEditor from "@/views/settings/forms/components/form-template-editor/FormTemplateEditor.vue";
import { computed, ref, watch } from "vue";
import RegistrationLinkEditActions from "./RegistrationLinkEditActions.vue";
import Alert from "@/components/common/alert/Alert.vue";
import TextButton from "@/components/common/button/TextButton.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import RegistrationLinkEditForm from "@/views/organize/activities/activity/registration-links/components/RegistrationLinkEditForm.vue";
import TextInputWithCopy from "@/components/common/text-input/TextInputWithCopy.vue";
import { ButtonSize } from "@/components/common/button/Button.types";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import Divider from "@/components/common/divider/Divider.vue";
import Button from "@/components/common/button/Button.vue";
import { testIds } from "@/utils/testing";
import TextInput from "@/components/common/text-input/TextInput.vue";

const props = defineProps<{
  linkId: string;
}>();

const componentTexts =
  texts.navigationItems.organize.activity.registrationLinks;

// Fetch registration link
const error = ref(false);
const registrationLink = ref<RegistrationLinkDTO>();
const registrationForm = ref<FormDTO>();
const allForms = ref<LocalizedFormDTO[]>([]);
const activeCulture = ref(Culture.NL);
const showLinkDetails = ref<boolean>(false);

const localizedForm = computed<LocalizedFormDTO | undefined>(() =>
  allForms.value.find((f) => f.id === registrationLink.value?.formId),
);

// Initialization
const fetchRegistrationLinkAndForm = async () => {
  try {
    registrationLink.value =
      await eduConfigurationServiceClient.getRegistrationLinkById(props.linkId);

    allForms.value = await formsServiceClient.getForms(FormType.Registration);

    registrationForm.value = await formsServiceClient.getForm(
      registrationLink.value.formId,
    );
  } catch (e) {
    error.value = true;
    throw e;
  }
};
fetchRegistrationLinkAndForm();

// Submit Registration Form
const submittingRegForm = ref(false);
const submitRegForm = async (values: FormDTO) => {
  if (!registrationLink.value?.formId) {
    throw new Error("No FormId on current Registration Link");
  }
  try {
    submittingRegForm.value = true;

    await formsServiceClient.updateForm(registrationLink.value?.formId, values);

    Notify.success(texts.notifications.edit.successCached, [
      texts.models.formTemplate.title,
    ]);
    fetchRegistrationLinkAndForm();
  } catch (err) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.formTemplate.title,
    ]);
    throw err;
  } finally {
    submittingRegForm.value = false;
  }
};

// Submit RegLink Details
const submittingRegDetails = ref(false);
const submitRegDetails = async (values: RegistrationLinkEditFormValues) => {
  if (!registrationLink.value || !registrationForm.value || !values) {
    throw new Error("Could not submit form because of missing data");
  }

  try {
    submittingRegDetails.value = true;

    const dto = convertToUpdateDTO(props.linkId, values);

    await eduConfigurationServiceClient.updateRegistrationLink(
      registrationLink.value.activityId,
      registrationLink.value.id,
      dto,
    );

    await formsServiceClient.updateForm(
      registrationLink.value.formId,
      registrationForm.value,
    );
    showLinkDetails.value = false;
    Notify.success(componentTexts.edit.success);
    fetchRegistrationLinkAndForm();
  } catch (e) {
    Notify.failure(componentTexts.edit.failure);
    throw e;
  } finally {
    submittingRegDetails.value = false;
  }
};

// Delete
const deleting = ref<boolean>(false);
const deleteLoading = ref<boolean>(false);
const submitDelete = async () => {
  if (!registrationLink.value) {
    throw new Error(
      "Could not delete registration link because of missing data",
    );
  }

  try {
    deleteLoading.value = true;

    await eduConfigurationServiceClient.deleteRegistrationLink(
      registrationLink.value.activityId,
      registrationLink.value.id,
    );

    showLinkDetails.value = false;
    Notify.success(componentTexts.delete.success);

    router.push({
      name: RouteNamesActivityOverview.REGISTRATIONLINKS,
      query: { reload: "true" },
    });
  } catch (e) {
    Notify.failure(componentTexts.delete.failure);
    throw e;
  } finally {
    deleteLoading.value = false;
  }
};

watch(
  registrationForm,
  (newValue) => {
    if (newValue) {
      registrationForm.value = newValue;
    }
  },
  { deep: true },
);
</script>
