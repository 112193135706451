import {
  getLocalizationsQueryable,
  Localization,
  QueryableLocalizations,
} from "@/models/helpers/localizable";
import {
  StudyProgramLocation,
  StudyProgramLocationDTO,
} from "@/models/study-program-location";
import {
  StudyProgramDepartment,
  StudyProgramDepartmentDTO,
} from "@/models/study-program-department";
import {
  StudyProgramLevel,
  StudyProgramLevelDTO,
} from "@/models/study-program-level";
import {
  StudyProgramMode,
  StudyProgramModeDTO,
} from "@/models/study-program-mode";
import {
  StudyProgramAreaOfInterest,
  StudyProgramAreaOfInterestDTO,
} from "./study-program-area-of-interest";

export interface StudyProgramDTO {
  id: string;
  name: string;
  displayName: string;
  uri?: string;
  externalReference?: string;
  programLanguage?: string;
  studyProgramLocations: StudyProgramLocationDTO[];
  areasOfInterest: StudyProgramAreaOfInterestDTO[];
  studyProgramDepartment?: StudyProgramDepartmentDTO;
  studyProgramLevel?: StudyProgramLevelDTO;
  studyProgramMode?: StudyProgramModeDTO;
}

export interface StudyProgramDTOLocalizableProperties {
  name: string;
}

export interface StudyProgramExtendedDTO extends StudyProgramDTO {
  localizations: Localization<StudyProgramDTOLocalizableProperties>[];
  programLanguageId: string;
}

export interface StudyProgramCreateDTO {
  externalReference?: string;
  localizations: Localization<StudyProgramDTOLocalizableProperties>[];
  programLanguageId?: string;
  studyProgramLocationIds: string[];
  areaOfInterestIds: string[];
  studyProgramDepartmentId?: string;
  studyProgramLevelId?: string;
  studyProgramModeId?: string;
}

export interface StudyProgramUpdateDTO extends StudyProgramCreateDTO {
  id: string;
}

export interface StudyProgramCopyDTO {
  id: string;
}

export default class StudyProgram {
  id: string;
  name: string;
  displayName: string;
  uri?: string;
  externalReference?: string;
  localizations?: QueryableLocalizations<StudyProgramDTOLocalizableProperties>;
  programLanguage?: string;
  studyProgramLocations: StudyProgramLocation[];
  studyProgramAreasOfInterest: StudyProgramAreaOfInterest[];
  studyProgramDepartment?: StudyProgramDepartment;
  studyProgramLevel?: StudyProgramLevel;
  studyProgramMode?: StudyProgramMode;

  constructor(dto: StudyProgramDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.displayName = dto.displayName;
    this.uri = dto.uri;
    this.externalReference = dto.externalReference;
    this.programLanguage = dto.programLanguage;
    this.studyProgramLocations = dto.studyProgramLocations.map(
      (studyProgramLocation) => new StudyProgramLocation(studyProgramLocation),
    );
    this.studyProgramAreasOfInterest = dto.areasOfInterest.map(
      (studyProgramAreaOfInterest) =>
        new StudyProgramAreaOfInterest(studyProgramAreaOfInterest),
    );

    this.studyProgramDepartment = dto.studyProgramDepartment
      ? new StudyProgramDepartment(dto.studyProgramDepartment)
      : undefined;

    this.studyProgramLevel = dto.studyProgramLevel
      ? new StudyProgramLevel(dto.studyProgramLevel)
      : undefined;

    this.studyProgramMode = dto.studyProgramMode
      ? new StudyProgramMode(dto.studyProgramMode)
      : undefined;
  }

  matchesFilter(search: string): boolean {
    return (
      search === "" ||
      this.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
      (!!this.externalReference &&
        this.externalReference.toLowerCase().indexOf(search.toLowerCase()) >= 0)
    );
  }
}

export class StudyProgramExtended extends StudyProgram {
  localizations: QueryableLocalizations<StudyProgramDTOLocalizableProperties>;
  programLanguageId: string;

  constructor(dto: StudyProgramExtendedDTO) {
    super(dto);
    this.localizations = getLocalizationsQueryable(dto.localizations);
    this.programLanguageId = dto.programLanguageId;
  }
}
