<template>
  <Page
    :loading="loadingOverview"
    :error="error"
    :data-testid="testIds.settings.activityLocations"
  >
    <Section class="flex items-center pt-8">
      <h2 class="flex-grow text-lg font-semibold text-deepteal-700">
        {{ texts.navigationItems.organize.activityLocations.title }}
      </h2>
      <ButtonAdd
        :data-testid="testIds.action.create"
        @click="openCreateSlideOver"
      />
    </Section>
    <Section>
      <span
        v-if="!loadingOverview && activityLocations.length === 0"
        data-testid="no-activity-locations-found-message"
        class="text-sm italic text-gray-500"
        >{{
          texts.navigationItems.organize.activityLocations.overview
            .noResultsFound
        }}</span
      >
      <List v-if="activityLocations.length > 0">
        <template v-slot:header>
          <ListItemColumn>{{
            texts.models.activityLocation.name
          }}</ListItemColumn>
          <ListItemColumn />
        </template>
        <ListItem
          v-for="activityLocation in activityLocations"
          :key="activityLocation.name"
          clickable
          @click="openEditSlideOver(activityLocation.id)"
        >
          <ListItemColumn>{{ activityLocation.name }} </ListItemColumn>
          <ListItemColumn :flex="0" class="text-right">
            <ListItemActions @click.stop>
              <DropdownItem @click="openEditSlideOver(activityLocation.id)">
                <Icon icon="edit" :color="Color.DeepTeal"></Icon>
                <span class="text-deepteal-500">{{ texts.actions.edit }}</span>
              </DropdownItem>
              <Divider class="mx-2 my-1" />
              <DropdownItem @click="openDeleteModal(activityLocation.id)">
                <Icon icon="delete" :color="Color.Alert"></Icon>
                <span class="text-alert-500">{{ texts.actions.delete }}</span>
              </DropdownItem>
            </ListItemActions>
          </ListItemColumn>
        </ListItem>
      </List>
    </Section>
  </Page>

  <!--  Create slideover-->
  <SlideOver
    v-model:visible="slideOverCreateOpen"
    :title="texts.navigationItems.organize.activityLocations.create.title"
    :subTitle="texts.navigationItems.organize.activityLocations.create.subTitle"
    data-testid="create-slide-over"
  >
    <ActivityLocationForm @submit="submitCreate">
      <ButtonSubmit :loading="actionLoading"></ButtonSubmit>
      <ButtonCancel @click="closeCreateSlideOver"></ButtonCancel>
    </ActivityLocationForm>
  </SlideOver>

  <!--  Edit slideover-->
  <SlideOver
    v-model:visible="slideOverEditOpen"
    :title="texts.navigationItems.organize.activityLocations.edit.title"
    :subTitle="texts.navigationItems.organize.activityLocations.edit.subTitle"
    data-testid="edit-slide-over"
  >
    <Loader
      v-if="activityLocationLoading"
      data-testid="loading-activity-location"
    ></Loader>
    <ActivityLocationForm
      v-if="!activityLocationLoading && !!activityLocationFormValues"
      :formValues="activityLocationFormValues"
      @submit="submitEdit"
    >
      <ButtonSubmit
        v-if="!deletingActivityLocation"
        :data-testid="testIds.action.submit"
        :loading="actionLoading"
      ></ButtonSubmit>
      <ButtonCancel
        v-if="!deletingActivityLocation"
        data-testid="cancel-update"
        :color="Color.White"
        @click="closeEditSlideOver"
      ></ButtonCancel>
      <SlideOverDeleteConfirm
        v-if="deletingActivityLocation"
        @confirm="submitDelete"
        @cancel="cancelDelete"
      ></SlideOverDeleteConfirm>
      <Button
        v-if="!deletingActivityLocation"
        :data-testid="testIds.action.delete"
        :color="Color.Alert"
        :label="texts.actions.delete"
        @click="deletingActivityLocation = true"
      ></Button>
    </ActivityLocationForm>
  </SlideOver>
  <DeleteActivityLocationModal
    v-if="activityLocationIdToDelete"
    :deletingId="activityLocationIdToDelete"
    @update:deletingId="deleteActivityLocationFinished"
  ></DeleteActivityLocationModal>
</template>

<script setup lang="ts">
import Loader from "@/components/common/loader/Loader.vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary, { ApplicationDictionary } from "@/dictionary";
import { Color, Culture } from "@/enums";
import Section from "@/components/common/section/Section.vue";
import Page from "@/components/common/page/Page.vue";
import { ref } from "vue";
import ListItem from "@/components/common/list/ListItem.vue";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Divider from "@/components/common/divider/Divider.vue";
import Icon from "@/components/common/icon/Icon.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import ActivityLocationForm from "@/views/settings/organization/activity-locations/ActivityLocationForm.vue";
import {
  ActivityLocationFormValues,
  convertToCreateDto,
  convertToUpdateDto,
} from "@/views/settings/organization/activity-locations/ActivityLocationForm.types";
import ActivityLocation, {
  ActivityLocationExtended,
} from "@/models/activityLocation";
import logger from "@/plugins/logger";
import {
  createActivityLocation,
  getActivityLocation,
  getActivityLocations,
  updateActivityLocation,
  deleteActivityLocation,
} from "@/services/activityLocations.service";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import { convertToFormValues } from "@/views/settings/organization/activity-locations/ActivityLocationForm.types";
import settings, {
  loadActivityLocations,
} from "@/store/context/settings.context";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import DeleteActivityLocationModal from "./DeleteActivityLocationModal.vue";
import SlideOverDeleteConfirm from "@/components/common/slideover/SlideOverDeleteConfirm.vue";
import Button from "@/components/common/button/Button.vue";

const store = useStore<RootState>();
const texts = dictionary[
  store.getters["cultureStore/active"] as Culture
] as ApplicationDictionary;

// Define local component state
const loadingOverview = ref<boolean>(true);
const error = ref<boolean>(false);
const activityLocations = ref<ActivityLocation[]>([]);
const currentActivityLocation = ref<ActivityLocationExtended | undefined>(
  undefined,
);
const slideOverCreateOpen = ref<boolean>(false);
const slideOverEditOpen = ref<boolean>(false);
const actionLoading = ref<boolean>(false);
const activityLocationLoading = ref<boolean>(false);
const deletingActivityLocation = ref<boolean>(false);

// Define component methods
const fetchActivityLocations = () => {
  getActivityLocations()
    .then((response) => {
      activityLocations.value = response.map(
        (activityLocationDto) => new ActivityLocation(activityLocationDto),
      );
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loadingOverview.value = false;
    });
};

const notifyEditSuccess = () => {
  Notify.success(texts.navigationItems.organize.activityLocations.edit.success);
  slideOverCreateOpen.value = false;
};

const notifyEditError = () => {
  Notify.failure(texts.navigationItems.organize.activityLocations.edit.failure);
};

const notifyCreateSuccess = () => {
  Notify.success(
    texts.navigationItems.organize.activityLocations.create.success,
  );
  slideOverCreateOpen.value = false;
};

const notifyCreateError = () => {
  Notify.failure(
    texts.navigationItems.organize.activityLocations.create.failure,
  );
};

const notifyDeleteSuccess = () => {
  Notify.success(
    texts.navigationItems.organize.activityLocations.delete.success,
  );
  fetchActivityLocations();
};

const notifyDeleteError = () => {
  Notify.failure(
    texts.navigationItems.organize.activityLocations.delete.failure,
  );
};

const notifyGetError = () => {
  Notify.failure(texts.navigationItems.organize.activityLocations.get.failure);
};

const activityLocationFormValues = ref<ActivityLocationFormValues>();
const openEditSlideOver = (activityLocationId: string) => {
  activityLocationLoading.value = true;
  slideOverEditOpen.value = true;

  getActivityLocation(activityLocationId)
    .then((dto) => {
      currentActivityLocation.value = new ActivityLocationExtended(dto);
      activityLocationFormValues.value = convertToFormValues(
        currentActivityLocation.value,
        settings.availableLanguages,
      );
    })
    .catch((error) => {
      slideOverEditOpen.value = false;

      notifyGetError();
      logger.error(error);
    })
    .finally(() => {
      activityLocationLoading.value = false;
    });
};

const closeEditSlideOver = () => {
  slideOverEditOpen.value = false;
};

const closeCreateSlideOver = () => {
  slideOverCreateOpen.value = false;
};

const openCreateSlideOver = () => {
  slideOverCreateOpen.value = true;
};

const submitCreate = (values: ActivityLocationFormValues) => {
  actionLoading.value = true;

  const dto = convertToCreateDto(values);

  return createActivityLocation(dto)
    .then(() => loadActivityLocations())
    .then(() => {
      notifyCreateSuccess();
      fetchActivityLocations();
    })
    .catch((error) => {
      notifyCreateError();
      logger.error(error);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const submitEdit = (values: ActivityLocationFormValues) => {
  actionLoading.value = true;

  if (!currentActivityLocation.value) {
    throw new Error("Edit activity location is is missing an id");
  }

  const dto = convertToUpdateDto(currentActivityLocation.value.id, values);

  return updateActivityLocation(dto)
    .then(() => loadActivityLocations())
    .then(() => {
      notifyEditSuccess();
      fetchActivityLocations();
      slideOverEditOpen.value = false;
    })
    .catch((error) => {
      notifyEditError();
      logger.error(error);
    })
    .finally(() => (actionLoading.value = false));
};

const submitDelete = () => {
  if (!currentActivityLocation.value) {
    throw new Error(`Cannot perform action. No activityLocation provided.`);
  }

  return deleteActivityLocation(currentActivityLocation.value.id)
    .then(() => loadActivityLocations())
    .then(() => {
      notifyDeleteSuccess();
      fetchActivityLocations();
      slideOverEditOpen.value = false;
    })
    .catch((e) => {
      notifyDeleteError();
      logger.error(e);
    })
    .finally(() => {
      actionLoading.value = false;
      deletingActivityLocation.value = false;
    });
};

const activityLocationIdToDelete = ref<string | undefined>(undefined);
// Delete ActivityLocation
const openDeleteModal = (activityLocationId: string) => {
  activityLocationIdToDelete.value = activityLocationId;
};

const cancelDelete = () => {
  deletingActivityLocation.value = false;
};

fetchActivityLocations();

const deleteActivityLocationFinished = (
  activityLocationId: string | undefined,
) => {
  activityLocationIdToDelete.value = activityLocationId;
  fetchActivityLocations();
};
</script>
