<template>
  <slot
    v-if="slots.failure && state === AsyncState.Failure"
    name="failure"
  ></slot>
  <slot
    v-else-if="slots.success && state === AsyncState.Success"
    name="success"
  ></slot>
  <slot
    v-else-if="slots.loading && state === AsyncState.Loading"
    name="loading"
  ></slot>
  <slot v-else :class="$attrs.class"></slot>
</template>

<script setup lang="ts">
import { useSlots } from "vue";
import { AsyncState } from "./Async.types";

defineProps<{
  state: AsyncState;
}>();

const slots = useSlots();
</script>
