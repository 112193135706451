import {
  getLocalizationsQueryable,
  Localization,
  QueryableLocalizations,
} from "./helpers/localizable";

export interface StudyProgramModeDTOLocalizableProperties {
  name: string;
}

export interface StudyProgramModeCreateDTO {
  sequenceNumber?: number;
  localizations: Localization<StudyProgramModeDTOLocalizableProperties>[];
}

export interface StudyProgramModeUpdateDTO extends StudyProgramModeCreateDTO {
  id: string;
}

export interface StudyProgramModeDTO {
  id: string;
  sequenceNumber?: number;
  name: string;
}

export interface StudyProgramModeExtendedDTO {
  id: string;
  sequenceNumber?: number;
  localizations: Localization<StudyProgramModeDTOLocalizableProperties>[];
}

export class StudyProgramMode {
  id: string;
  sequenceNumber?: number;
  name: string;

  constructor(dto: StudyProgramModeDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.name = dto.name;
  }
}

export class StudyProgramModeExtended {
  id: string;
  sequenceNumber?: number;
  localizations: QueryableLocalizations<StudyProgramModeDTOLocalizableProperties>;

  constructor(dto: StudyProgramModeExtendedDTO) {
    this.id = dto.id;
    this.sequenceNumber = dto.sequenceNumber;
    this.localizations = getLocalizationsQueryable(dto.localizations);
  }
}
