import { MailingType } from "@/enums";
import { IEntityContentJson } from "@beefree.io/sdk/dist/types/bee";

export interface MailingTemplateDTO {
  id: string;
  name: string;
  mailingType: MailingType;
}

export interface MailingTemplateExtendedDTO extends MailingTemplateDTO {
  configuration: string;
}

export type MailingTemplateUpdateDTO = {
  id: string;
  mailingType: MailingType;
  name: string;
  configuration: string;
};

export interface MailingTemplateCreateDTO {
  name: string;
  mailingType: MailingType;
  configuration: string;
}

export interface MailingTemplateCopyDTO {
  id: string;
}

export default class MailingTemplate {
  id: string;
  name: string;
  mailingType: MailingType;

  constructor(dto: MailingTemplateDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.mailingType = dto.mailingType;
  }
}

export class MailingTemplateExtended {
  id: string;
  name: string;
  mailingType: MailingType;
  configuration: IEntityContentJson;

  constructor(dto: MailingTemplateExtendedDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.mailingType = dto.mailingType;
    this.configuration = JSON.parse(dto.configuration);
  }
}
