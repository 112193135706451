<template>
  <form class="flex flex-col gap-4" @submit="onSubmit">
    <FormFieldTextInput
      :id="MailingTemplateFormKeys.name"
      :label="texts.models.mailingTemplate.name"
      :displayMode="FormFieldDisplayMode.Column"
    />
    <div :id="beeContainerId" class="h-beeEditor"></div>
    <div class="flex flex-col items-center gap-2 lg:flex-row-reverse">
      <slot></slot>
    </div>
  </form>
</template>

<script setup lang="ts">
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import texts from "@/utils/texts";
import { useForm } from "vee-validate";
import {
  MailingTemplateFormKeys,
  MailingTemplateFormValues,
} from "./MailingTemplateForm.types";
import * as yup from "yup";

const props = defineProps<{
  initialValues?: MailingTemplateFormValues;
  beeContainerId: string;
}>();

const emit = defineEmits<{
  submit: [values: MailingTemplateFormValues];
}>();

const form = useForm<MailingTemplateFormValues>({
  initialValues: props.initialValues,
  validationSchema: { name: yup.string().required() },
});

const onSubmit = form.handleSubmit((values) => emit("submit", values));
</script>
