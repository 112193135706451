<template>
  <li class="mb-4 flex flex-col items-start">
    <div class="flex w-full items-center justify-between gap-2">
      <span class="text-base font-semibold">
        {{ configuration.name }}
      </span>
      <div v-if="configuration.id" class="flex gap-2">
        <BrochureDropdownButton :configuration="configuration" />
        <ButtonSettings
          v-if="canWrite"
          icon="settings"
          @click="emit('configuration:update')"
        >
          {{ texts.generic.settings }}
        </ButtonSettings>
      </div>
    </div>
    <List compact class="w-full">
      <template v-slot:header>
        <ListItemColumn :flex="0">
          <div class="h-6 w-6"></div>
        </ListItemColumn>
        <ListItemColumn :flex="4"></ListItemColumn>
        <ListItemColumn :flex="4" hide>
          {{ texts.models.studyProgram.plural }}
        </ListItemColumn>
        <ListItemColumn :flex="2" class="justify-end">
          <div>
            <div class="text-right">{{ componentTexts.stats.requests }}</div>
            <Legend v-if="!configuration.id" :items="legendItems" />
          </div>
        </ListItemColumn>
        <ListItemColumn :flex="0">
          <div class="h-6 w-6"></div>
        </ListItemColumn>
      </template>
      <BrochureItem
        v-for="item in items"
        :key="item.brochure.id"
        :item="item"
        :color="color"
        @item:update="(value) => emit('item:update', value)"
      >
        <div v-if="canWrite">
          <DropdownItem @click="(value) => emit('item:delete', value)">
            <Icon icon="delete" :color="Color.Alert"></Icon>
            <span class="text-alert-500">{{ texts.actions.delete }}</span>
          </DropdownItem>
          <Divider class="mx-2 my-1" />
        </div>
      </BrochureItem>
    </List>
  </li>
</template>

<script setup lang="ts">
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import Divider from "@/components/common/divider/Divider.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import Icon from "@/components/common/icon/Icon.vue";
import Legend from "@/components/common/legend/Legend.vue";
import List from "@/components/common/list/List.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { Color } from "@/enums";
import { BrochureConfigurationDto } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { BrochureOverviewItem } from "@/views/information-requests/brochures/Overview.types";
import BrochureDropdownButton from "./BrochureDropdownButton.vue";
import BrochureItem from "./BrochureItem.vue";

const componentTexts = texts.navigationItems.brochures;

const legendItems = [
  {
    text: texts.navigationItems.brochures.stats.digitalRequests,
    color: Color.Emerald,
  },
  {
    text: texts.navigationItems.brochures.stats.hardCopyRequests,
    color: Color.DeepTeal,
  },
];

defineProps<{
  configuration: BrochureConfigurationDto;
  items: BrochureOverviewItem[];
  color: Color;
  canWrite: boolean;
}>();

const emit = defineEmits<{
  "configuration:update": [];
  "item:update": [value: string];
  "item:delete": [value: string];
}>();
</script>
