<template>
  <template v-if="edit">
    <div class="flex flex-col gap-1">
      <Select
        :id="questionFieldId"
        v-model="questionOption"
        :name="questionFieldId"
        :options="questions"
        :valid="!questionIdError"
        allowUnset
      />
      <Combobox
        v-if="questionId"
        :id="answerFieldId"
        v-model="answerIds"
        :name="answerFieldId"
        :options="answers"
        :formatOptionDisplayString="answerOptionFormatter"
        :searchFilter="answerSearchFilter"
        :valid="!answerIdsError"
      />
      <span v-if="answerIdsError" class="text-xs text-alert-700">
        {{ answerIdsError }}
      </span>
    </div>
  </template>
  <template v-else>
    <div class="flex flex-col gap-1">
      <span class="font-medium italic">{{
        questions.find((q) => q.value === criterion.questionId)?.label
      }}</span>
      <ul class="flex flex-col gap-1">
        <li
          v-for="id in criterion.answerOptionIds"
          :key="id"
          class="flex flex-wrap"
        >
          {{ answers.find((answer) => answer.id === id)?.name }}
        </li>
      </ul>
    </div>
  </template>
</template>

<script setup lang="ts">
import Select from "@/components/common/select/Select.vue";
import { computed } from "vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import { Answer } from "@/models/question";
import { CriterionFieldProps } from "../SelectionDefinition.helpers";
import Combobox from "@/components/common/combobox/Combobox.vue";
import { useField } from "vee-validate";
import * as yup from "yup";
import { fields } from "@/utils/miscellaneous";
import { SelectionCriterionDto } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<CriterionFieldProps>();

const questionFieldId = fields<SelectionCriterionDto>().questionId;

const { value: questionId, errorMessage: questionIdError } = useField<
  typeof props.criterion.questionId
>(questionFieldId, yup.string().required(), {
  initialValue: props.criterion.questionId,
});

const answerFieldId = fields<SelectionCriterionDto>().answerOptionIds;

const { value: answerIds, errorMessage: answerIdsError } = useField<
  typeof props.criterion.answerOptionIds
>(answerFieldId, yup.array().required().min(1), {
  initialValue: [...(props.criterion.answerOptionIds ?? [])], // Shallow-copy to decouple field-array from the original
});

const questions = computed<SelectOption[]>(() =>
  props.context.questionsWithAnswers.map((question) => ({
    label: question.label,
    value: question.id,
  })),
);

const answers = computed(() =>
  questionId.value
    ? props.context.questionsWithAnswers
        .filter((question) => question.id === questionId.value)[0]
        .answers.map((answer) => ({
          id: answer.id,
          name: answer.label,
        }))
    : [],
);

const questionOption = computed({
  get: () => questions.value.find((item) => item.value === questionId.value),
  set: (option) => {
    questionId.value = option?.value;
    answerIds.value = undefined;
  },
});

const answerSearchFilter = (answer: Answer, query: string) =>
  answer.name.toLowerCase().includes(query.toLowerCase());

const answerOptionFormatter = (answer: Answer) => answer.name;
</script>
