<template>
  <FormLayout @submit="submit">
    <h2 class="text-lg font-semibold text-deepteal-700">
      {{ componentTexts.downloadTemplateHeader }}
    </h2>
    <p class="text-xs text-deepteal-700" data-testid="description">
      {{ componentTexts.downloadTemplateDescription.description }}
    </p>
    <ul v-show="!hideDescription" class="text-md text-gray-500">
      <li>
        {{ componentTexts.downloadTemplateDescription.operations.title }}
        <ul class="ml-5 list-disc">
          <li>
            {{ componentTexts.downloadTemplateDescription.operations.create }}
          </li>
          <li>
            {{ componentTexts.downloadTemplateDescription.operations.update }}
          </li>
          <li>
            {{ componentTexts.downloadTemplateDescription.operations.id }}
          </li>
          <li>
            {{
              componentTexts.downloadTemplateDescription.operations.reference
            }}
          </li>
        </ul>
      </li>
    </ul>

    <Button :loading="actionLoading" class="w-full" @click="downloadTemplate">
      {{ componentTexts.downloadTemplateButton }}
    </Button>

    <Divider />

    <h2 class="text-lg font-semibold text-deepteal-700">
      {{ componentTexts.uploadTemplateHeader }}
    </h2>
    <FileUploadField
      :formFieldId="formFieldIds.file"
      :maxFileSize="FileSize.MB1"
      :maxFileSizeNotification="componentTexts.sizeExceededNotification"
      :fileTypes="[FileType.XLSX]"
    />

    <slot name="feedback"></slot>
    <template v-slot:actions> <slot></slot> </template>
  </FormLayout>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import FileUploadField from "@/components/common/file-upload/FileUploadField.vue";
import {
  FileSize,
  FileType,
} from "@/components/common/file-upload/fileUploadField.types";
import texts from "@/utils/texts";
import Button from "@/components/common/button/Button.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { fields } from "@/utils/miscellaneous";
import logger from "@/plugins/logger";
import Notify from "@/utils/notify";
import { downloadFile } from "@/utils/downloadFile";
import { FileResponse } from "@/lib/eduConfigurationServiceClient";
import type { CancelToken } from "axios";
import FormLayout from "@/components/common/form/FormLayout.vue";

const props = withDefaults(
  defineProps<{
    downloadTemplateAction: (
      cancelToken?: CancelToken,
    ) => Promise<FileResponse>;
    hideDescription?: boolean;
  }>(),
  {
    hideDescription: false,
  },
);

const emit = defineEmits<{
  submit: [values: ImportFormValues];
}>();

const componentTexts = texts.commonComponents.import.form;
export interface ImportFormValues {
  file: File[];
}

const actionLoading = ref(false);

const formFieldIds = fields<ImportFormValues>();

const form = useForm<ImportFormValues>({
  validationSchema: {
    [formFieldIds.file]: yup.array(yup.mixed()).max(1).required(),
  },
  initialValues: {
    [formFieldIds.file]: [],
  },
});

const submit = form.handleSubmit((values) => {
  if (!values.file)
    throw new Error(
      "PreEducationSchoolsImportForm can't submit because file is null",
    );

  emit("submit", values);
});

const downloadTemplate = async () => {
  actionLoading.value = true;

  await props
    .downloadTemplateAction()
    .then((response) => {
      downloadFile(response);
      Notify.success(componentTexts.downloadTemplateSuccessNotification);
    })
    .catch((e) => {
      logger.error(e);
      Notify.failure(componentTexts.downloadTemplateErrorNotification);
    })
    .finally(() => {
      actionLoading.value = false;
    });
};
</script>
