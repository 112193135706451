<template>
  <ListItem
    :data-testid="testIds.mailingsTableRow"
    clickable
    :title="mailing.name"
    class="h-24"
  >
    <div
      :class="[
        'h-full w-0.5',
        getMailingTypeColor(mailing.type as MailingType),
      ]"
    />
    <ListItemColumn :flex="2">
      <div class="ml-4 flex w-full flex-col gap-2">
        <div class="flex items-center gap-1 text-xs lg:text-sm">
          <span>{{ getItemText(mailing) }}</span>
          <Badge
            v-if="isMailingFailed"
            :label="texts.enums.mailingStatus[mailing.status as MailingStatus]"
            :type="BadgeType.danger"
          />
        </div>
        <div class="flex items-center justify-between gap-2">
          <span class="truncate text-lg font-semibold lg:font-normal">{{
            mailing.name
          }}</span>
          <Badge
            :type="convertMailingTypeToBadgeType(mailing.type as MailingType)"
            :label="texts.enums.mailingType[mailing.type as MailingType]"
          />
        </div>
      </div>
    </ListItemColumn>
    <div
      class="hidden h-full cursor-pointer items-center justify-center border-l-2 px-2 py-2 md:col-span-1 md:flex md:px-5"
    >
      <Icon
        icon="arrow_right_alt"
        :size="IconSize.sm"
        class="text-deepteal-500 group-hover:text-deepteal-800"
      />
    </div>
  </ListItem>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { testIds } from "@/utils/testing";
import Badge from "@/components/common/badge/Badge.vue";
import { convertMailingTypeToBadgeType } from "@/views/mailings/mailing/Overview.types";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import texts from "@/utils/texts";

import { MailingStatus, MailingType } from "@/enums";
import { getMailingTypeColor } from "@/models/mailingType";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import { DateTime } from "luxon";
import Icon from "@/components/common/icon/Icon.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { ActivityRelatedMailingOverviewDTO } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<{
  mailing: ActivityRelatedMailingOverviewDTO;
}>();

const getLastStatusChangeText = (
  mailing: ActivityRelatedMailingOverviewDTO,
) => {
  const plannedDateString =
    mailing.plannedDateTime &&
    mailing.plannedDateTime.toLocaleString(DateTime.DATETIME_MED);
  const savedDateString =
    mailing.modifiedDateTime &&
    mailing.modifiedDateTime.toLocaleString(DateTime.DATETIME_MED);
  switch (mailing.status) {
    case MailingStatus.Concept:
      return `${texts.models.mailing.lastModifiedOn} ${savedDateString}`;
    case MailingStatus.Planned:
      return `${texts.models.mailing.scheduledFor} ${plannedDateString}`;
    case MailingStatus.InProgress:
      return `${texts.models.mailing.scheduledFor} ${plannedDateString}`;
    case MailingStatus.Sent:
      if (mailing.sentDateTime)
        return `${
          texts.models.mailing.sentOn
        } ${mailing.sentDateTime.toLocaleString(DateTime.DATETIME_MED)}`;
      else return `${texts.models.mailing.sentOn} ?`;
    case MailingStatus.Failed:
      return `${texts.models.mailing.scheduledFor} ${plannedDateString}`;
  }
};

const getActivityContextText = (mailing: ActivityRelatedMailingOverviewDTO) => {
  if (mailing.activityName) {
    return `${texts.generic.for} ${mailing.activityName}`;
  }
};

const getItemText = (mailing: ActivityRelatedMailingOverviewDTO) => {
  const lastStatusChangeText = getLastStatusChangeText(mailing);
  const activityContextText = getActivityContextText(mailing);

  if (lastStatusChangeText && activityContextText) {
    return `${lastStatusChangeText} | ${activityContextText}`;
  } else if (lastStatusChangeText) {
    return lastStatusChangeText;
  } else if (activityContextText) {
    return activityContextText;
  }
};

const isMailingFailed = computed(() =>
  props.mailing ? props.mailing.status == MailingStatus.Failed : false,
);
</script>
