import {
  Mailing,
  MailingDTO,
  MailingExtended,
  ConceptMailingCreateDTO,
  PlannedMailingCreateDTO,
  ConceptMailingUpdateDTO,
  PlannedMailingUpdateDTO,
  ConceptMailingCreateResponseDTO,
  PlannedMailingCreateResponseDTO,
  TestMailDTO,
  MailingExtendedDTO,
  MailingStatistics,
  MailingStatisticsDTO,
} from "@/models/mailing";
import { MailingType, MailingTypeDTO } from "@/models/mailingType";
import {} from "@/models/registrationLink";
import HttpClient from "@/utils/httpClient";

export const getMailings = async (): Promise<Mailing[]> => {
  return await HttpClient.educonfig
    .get<MailingDTO[]>(`mailings`)
    .then((response) => response.data.map((dto) => new Mailing(dto)));
};

export const getMailing = async (id: string): Promise<MailingExtended> => {
  return await HttpClient.educonfig
    .get<MailingExtendedDTO>(`mailings/${id}`)
    .then((response) => new MailingExtended(response.data));
};

export const getMailingTypes = async (): Promise<MailingType[]> => {
  return await HttpClient.educonfig
    .get<MailingTypeDTO[]>(`mailing-types`)
    .then((response) => response.data.map((dto) => new MailingType(dto)));
};

export const createConceptMailing = async (
  mailing: ConceptMailingCreateDTO,
): Promise<ConceptMailingCreateResponseDTO> => {
  return await HttpClient.educonfig
    .post("mailings", mailing)
    .then((response) => response.data);
};

export const updateConceptMailing = async (
  mailing: ConceptMailingUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(`mailings/${mailing.id}`, mailing);
};

export const createPlannedMailing = async (
  mailing: PlannedMailingCreateDTO,
): Promise<PlannedMailingCreateResponseDTO> => {
  return await HttpClient.educonfig
    .post("mailings/plan", mailing)
    .then((response) => response.data);
};

export const updatePlannedMailing = async (
  mailing: PlannedMailingUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(`mailings/${mailing.id}/plan`, mailing);
};

export const cancelMailing = async (id: string): Promise<void> => {
  return await HttpClient.educonfig.put(`mailings/${id}/cancel`);
};

export const deleteMailing = async (id: string): Promise<void> => {
  return await HttpClient.educonfig.delete(`mailings/${id}`);
};

export const postTestMail = async (dto: TestMailDTO): Promise<void> => {
  return await HttpClient.mail.post(`send-test-mail`, dto);
};

export const getMailingStatistics = async (
  id: string,
): Promise<MailingStatistics> => {
  return await HttpClient.educonfig
    .get<MailingStatisticsDTO>(`/mailings/${id}/statistics`)
    .then((response) => new MailingStatistics(response.data));
};

export const copyMailing = async (id: string): Promise<string> => {
  return await HttpClient.educonfig
    .post<string>(`/mailings/${id}/copy`)
    .then((response) => response.data);
};
