<template>
  <Page :loading="loading" :error="error">
    <div v-if="mailing && mailingData">
      <Section>
        <RouterLinkAuthWithIcon
          data-testid="link-to-accounts"
          :to="{ name: RouteNamesMailings.OVERVIEW }"
          icon="arrow_left_alt"
          :text="texts.actions.backToOverview"
        ></RouterLinkAuthWithIcon>
        <PageHeading
          :title="mailing.name"
          :badgeLabel="
            texts.enums.mailingStatus[mailing.status as MailingStatus]
          "
          :badgeType="statusBadgeType"
        >
          <template v-slot:actions>
            <Dropdown
              v-show="canWrite"
              :label="texts.actions.actions"
              :origin="DropdownOrigin.TopRight"
              :loading="loadingAction"
              :testId="testIds.actions"
            >
              <RouterLinkAuth
                v-if="mailing.status === MailingStatus.Concept"
                :to="{
                  name: RouteNamesMailing.EDIT,
                  params: { id: mailing.id },
                }"
              >
                <DropdownItem
                  :data-testid="testIds.action.edit"
                  :label="texts.navigationItems.mailing.actions.editConcept"
                ></DropdownItem>
              </RouterLinkAuth>
              <DropdownItem
                v-if="mailing.status === MailingStatus.Planned"
                data-testid="set-planned-mailing-back-to-concept-and-edit"
                :label="
                  texts.navigationItems.mailing.actions.backToConceptAndEdit
                "
                @click="setBackToConceptAndEdit"
              ></DropdownItem>
              <DropdownItem
                v-if="mailing.status === MailingStatus.Planned"
                data-testid="set-planned-mailing-back-to-concept"
                :label="texts.navigationItems.mailing.actions.backToConcept"
                @click="setBackToConcept"
              ></DropdownItem>
              <DropdownItem
                :data-testid="testIds.action.copy"
                :label="texts.navigationItems.mailing.actions.copy"
                @click="triggerCopy"
              ></DropdownItem>
              <DropdownItem
                v-if="
                  mailing.status === MailingStatus.Concept ||
                  mailing.status === MailingStatus.Planned ||
                  mailing.status === MailingStatus.Failed
                "
                data-testid="delete-mailing"
                :label="texts.actions.delete"
                @click="toggleDeleteModal()"
              ></DropdownItem>
            </Dropdown>
          </template>
        </PageHeading>
      </Section>
      <div v-if="viewMode === 'statistics'">
        <MailingStatistics :mailingId :data="mailingData" />
      </div>
      <div v-else class="flex flex-col gap-8">
        <SectionHeading
          :title="texts.navigationItems.mailing.overview.title"
          :description="texts.navigationItems.mailing.overview.description"
          :divider="false"
        />
        <MailingDescriptionList :data="mailingData" />
      </div>

      <!-- Confirm delete modal -->
      <Modal
        v-model:visible="deleteModalVisible"
        :type="ModalType.danger"
        :description="texts.actions.confirmDelete.text"
        :title="texts.actions.confirmDelete.action"
      >
        <template v-slot:buttons>
          <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button
              data-testid="confirm-delete"
              class="sm:ml-3"
              :color="Color.Alert"
              :label="texts.actions.delete"
              :disabled="!deleteModalVisible"
              :loading="deletingMail"
              @click="triggerDelete"
            />
            <Button
              data-testid="cancel-delete"
              class="mt-3 sm:mt-0"
              :color="Color.White"
              :label="texts.actions.cancel"
              :disabled="deletingMail || !deleteModalVisible"
              @click="toggleDeleteModal"
            />
          </div>
        </template>
      </Modal>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Color, Culture, MailingStatus } from "@/enums";
import dictionary from "@/dictionary";
import {
  cancelMailing,
  copyMailing,
  deleteMailing,
  getMailing,
} from "@/services/mailings.service";
import { useRoute, useRouter } from "vue-router";
import logger from "@/plugins/logger";
import {
  RouteNames,
  RouteNamesMailing,
  RouteNamesMailings,
} from "@/router/routeNames";
import {
  getMailingStatisticsBasicDataFromMailing as getMailingStatistics,
  MailingStatisticsBasicData,
} from "@/views/mailings/mailing/components/MailingStatistics.types";
import {
  getMailingDescriptionListDataFromMailing as getMailingDescriptionList,
  MailingDescriptionListData,
} from "@/views/mailings/mailing/components/MailingDescriptionList.types";

import Page from "@/components/common/page/Page.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Section from "@/components/common/section/Section.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import MailingStatistics from "@/views/mailings/mailing/components/MailingStatistics.vue";
import MailingDescriptionList from "@/views/mailings/mailing/components/MailingDescriptionList.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import RouterLinkAuthWithIcon from "@/components/router/router-link-auth/RouterLinkAuthWithIcon.vue";
import Dropdown from "@/components/common/dropdown/Dropdown.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import { convertToBadgeType } from "@/views/mailings/mailing/Overview.types";
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import Button from "@/components/common/button/Button.vue";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import { MailingDTO, Permission } from "@/lib/eduConfigurationServiceClient";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";

// Store setup
const store = useStore<RootState>();

// Route
const router = useRouter();

const route = useRoute();
const mailingId = route.params["id"] as string;
if (!mailingId) throw new Error("No id param provided.");

const canWrite = hasPermissions([Permission.MailingsWrite]);

// Translations
const texts = dictionary[store.getters["cultureStore/active"] as Culture];

const mailing = ref<MailingDTO>();
const mailingData = ref<
  MailingDescriptionListData | MailingStatisticsBasicData
>();
const statusBadgeType = computed<BadgeType>(() =>
  mailing.value
    ? convertToBadgeType(mailing.value.status as MailingStatus)
    : BadgeType.default,
);

// Fetch mail data
const loading = ref<boolean>(true);
const error = ref<boolean>(false);

const fetchMailing = () => {
  loading.value = true;
  return (
    Promise.all([eduConfigurationServiceClient.getMailingById(mailingId)])
      // TODO: can't we just use the shallowRefs here, instead of the sorta finicky .then(..) glue?
      .then(([mailingFetched]) => {
        mailing.value = mailingFetched;

        if (viewMode.value === "statistics") {
          return getMailingStatistics(mailing.value);
        } else {
          return getMailingDescriptionList(mailing.value);
        }
      })
      .then((data) => {
        mailingData.value = data;
      })
      .catch((e) => {
        error.value = true;
        logger.error(e);
      })
      .finally(() => {
        loading.value = false;
      })
  );
};

fetchMailing();

// Delete mail

const deleteModalVisible = ref<boolean>(false);
const toggleDeleteModal = () => {
  deleteModalVisible.value = !deleteModalVisible.value;
};

const deletingMail = ref<boolean>(false);

const deleteAction = () => {
  deletingMail.value = true;
  return deleteMailing(mailingId)
    .then(() => {
      router.push({
        name: RouteNames.MAILINGS,
        params: { statusFilter: mailing.value?.status },
      });
      Notify.success(texts.navigationItems.mailing.delete.success);
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.mailing.delete.failure);
      logger.error(error);
    })
    .finally(() => {
      deletingMail.value = false;
      deleteModalVisible.value = false;
    });
};

const cancelling = ref<boolean>(false);
const setBackToConcept = () => {
  cancelling.value = true;
  return cancelMailing(mailingId)
    .then(() => {
      fetchMailing();
      Notify.success(texts.navigationItems.mailing.cancel.success);
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.mailing.cancel.failure);
      logger.error(error);
    })
    .finally(() => {
      cancelling.value = false;
    });
};

const setBackToConceptAndEdit = () => {
  cancelling.value = true;
  cancelMailing(mailingId)
    .then(() => {
      router.push({
        name: RouteNamesMailing.EDIT,
        params: { id: mailingId },
      });
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.mailing.cancel.failure);
      logger.error(error);
    })
    .finally(() => {
      cancelling.value = false;
    });
};

const setBackToConceptAndDeleteAction = () => {
  deletingMail.value = true;
  return cancelMailing(mailingId)
    .then(() => {
      return getMailing(mailingId) // if delete mailing throws, the current state of the mail is still up to date
        .then(() => deleteMailing(mailingId))
        .then(() => {
          router.push({ name: RouteNames.MAILINGS });
          Notify.success(texts.navigationItems.mailing.delete.success);
        })
        .catch((error) => {
          Notify.failure(texts.navigationItems.mailing.delete.deletefailure);
          logger.error(error);
        });
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.mailing.delete.cancelfailure);
      logger.error(error);
    })
    .finally(() => {
      deletingMail.value = false;
      deleteModalVisible.value = false;
    });
};

const triggerDelete = () => {
  if (!mailing.value)
    throw new Error(
      "Cannot delete mailing, because the status is unknown. The status is needed to optionally cancel the mailing first.",
    );

  if (mailing.value.status === MailingStatus.Planned) {
    setBackToConceptAndDeleteAction();
  } else {
    deleteAction();
  }
};

// Copy mail

const copyLoading = ref(false);
const triggerCopy = () => {
  copyLoading.value = true;

  copyMailing(mailingId)
    .then((id) => {
      Notify.success(texts.navigationItems.mailing.copy.success);

      router.push({
        name: RouteNamesMailing.EDIT,
        params: { id },
      });
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.mailing.copy.failure);
      logger.error(error);
    })
    .finally(() => {
      copyLoading.value = false;
    });
};

const loadingAction = computed(
  () => deletingMail.value || cancelling.value || copyLoading.value,
);

const viewMode = computed<"statistics" | "description">(() => {
  if (
    mailing.value?.status === MailingStatus.Sent ||
    mailing.value?.status === MailingStatus.InProgress
  )
    return "statistics";
  else return "description";
});
</script>
