<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-4">
    <ButtonBack :toRouteName="texts.generic.previous" @click="router.go(-1)" />
    <FormTemplateEditor v-if="dto" :dto :submitting @submit="handleSubmit">
      <div class="flex flex-col gap-4 sm:flex-row sm:items-center">
        <ButtonSubmit :loading="submitting" />
        <ButtonCopy :loading="isCopying" @click="handleCopy" />
        <ButtonDelete @click="deleteModalVisible = true" />
      </div>
    </FormTemplateEditor>
  </Page>
  <FormTemplateDeleteModal
    v-model:visible="deleteModalVisible"
    :formTemplateId="id"
    @delete:succeeded="router.push({ name: RouteNamesSettings.FORMS })"
  />
  <FormTemplateEditModal
    v-model:visible="editModalVisible"
    :activityNames="activitiesUsingForm"
    @update:submit="submitFormTemplate(formValues!)"
  />
</template>

<script setup lang="ts">
import ButtonBack from "@/components/common/button/ButtonBack.vue";
import ButtonCopy from "@/components/common/button/ButtonCopy.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import Page from "@/components/common/page/Page.vue";
import { FormType } from "@/enums";
import { FormDTO } from "@/lib/formsServiceClient";
import logger from "@/plugins/logger";
import { RouteNamesSettings } from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import { loadForms } from "@/store/context/settings.context";
import { groupBy } from "@/utils/array";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import FormTemplateEditor from "@/views/settings/forms/components/form-template-editor/FormTemplateEditor.vue";
import FormTemplateDeleteModal from "@/views/settings/forms/components/FormTemplateDeleteModal.vue";
import FormTemplateEditModal from "@/views/settings/forms/components/FormTemplateEditModal.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import "./FormExtensions";

const router = useRouter();

const props = defineProps<{
  id: string;
}>();

const loading = ref(true);
const error = ref(false);

const dto = ref<FormDTO>();

const initialize = async () => {
  loading.value = true;
  error.value = false;
  try {
    const response = await formsServiceClient.getForm(props.id);
    dto.value = response;
  } catch (e) {
    logger.error(e);
    error.value = true;
  } finally {
    loading.value = false;
  }
};

initialize();

const formValues = ref<FormDTO>();
const editModalVisible = ref<boolean>(false);
const activitiesUsingForm = ref<string[]>([]);
const handleSubmit = async (values: FormDTO) => {
  try {
    if (values.type === FormType.Registration) {
      const registrationLinksPerActivity =
        await eduConfigurationServiceClient.getRegistrationLinksForFormTemplate(
          props.id,
        );
      if (registrationLinksPerActivity.length > 0) {
        const groupedRegistrationLinks = groupBy(
          registrationLinksPerActivity,
          (session) => session.activityName,
        );

        activitiesUsingForm.value = Object.keys(groupedRegistrationLinks);
        formValues.value = values;
        editModalVisible.value = true;
        return;
      }
    }

    await submitFormTemplate(values);
    router.go(-1);
  } catch (err) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.formTemplate.title,
    ]);
    throw err;
  }
};

const submitting = ref(false);
const submitFormTemplate = async (values: FormDTO) => {
  try {
    submitting.value = true;

    await formsServiceClient.updateForm(props.id, values);
    await loadForms();

    Notify.success(texts.notifications.edit.successCached, [
      texts.models.formTemplate.title,
    ]);
    router.go(-1);
  } catch (err) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.formTemplate.title,
    ]);
    throw err;
  } finally {
    submitting.value = false;
  }
};

const deleteModalVisible = ref<boolean>(false);

const isCopying = ref(false);
const handleCopy = () => {
  isCopying.value = true;
  formsServiceClient
    .copyForm(props.id)
    .then((response) => {
      Notify.success(texts.notifications.copy.success, [
        texts.models.formTemplate.title,
      ]);

      router.push({
        name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
        params: { id: response.id },
      });
    })
    .catch((error) => {
      logger.log(error);

      Notify.failure(texts.notifications.copy.failure, [
        texts.models.formTemplate.title,
      ]);
    })
    .finally(() => {
      isCopying.value = false;
    });
};
</script>
