<template>
  <FormField :id="formFieldId" :initialValue="initialValue">
    <template v-slot="{ value }">
      <div
        class="mt-1 flex cursor-pointer justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5 hover:border-solid hover:border-deepteal-400"
        @drop.prevent="handleDropZoneDropEvent($event, value)"
        @click="triggerFileInput"
      >
        <div class="space-y-1 text-center">
          <svg
            class="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="flex text-sm text-gray-600">
            <label
              :for="formFieldId"
              class="relative m-auto cursor-pointer rounded-md text-deepteal-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-deepteal-500 focus-within:ring-offset-2 hover:text-deepteal-600"
            >
              <p
                v-if="!value"
                class="flex flex-col flex-wrap justify-center gap-1 lg:flex-row"
              >
                <span class="font-semibold">{{
                  componentTexts.uploadFile
                }}</span>
                <span>{{ componentTexts.orDragAndDrop }}</span>
              </p>
              <p v-else class="font-semibold">
                {{ value.value ? value.value.length : "0" }}
                {{ componentTexts.selectedFiles }}
              </p>
              <input
                :id="formFieldId"
                :key="valueChangedCounter"
                ref="inputRef"
                class="sr-only"
                type="file"
                :accept="accept"
                @change="handleChangeEvent($event, value)"
              />
            </label>
          </div>
          <p
            v-if="!value"
            class="flex flex-col flex-wrap justify-center gap-1 text-xs text-gray-500 lg:flex-row"
          >
            <span>{{ fileTypes.join(", ") }}</span>
            <span>{{ componentTexts.upToSize }} {{ maxFileSize }}</span>
          </p>
          <p v-else class="text-xs text-gray-500">
            {{ fileNames(value.value)?.join(", ") }}
          </p>
          <slot name="content" />
        </div>
      </div>
    </template>
  </FormField>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref, Ref } from "vue";
import {
  FileSize,
  FileSize_To_Bytes,
  FileType,
  FileType_To_Accept,
} from "@/components/common/file-upload/fileUploadField.types";
import Notify, { NotificationTexts } from "@/utils/notify";
import texts from "@/utils/texts";
import FormField from "@/components/common/form/FormField.vue";

const componentTexts = texts.commonComponents.fileUpload;

const props = defineProps<{
  formFieldId: string;
  label?: string;
  fileTypes: FileType[];
  maxFileSize: FileSize;
  maxFileSizeNotification: NotificationTexts;
  initialValue?: File[] | undefined;
}>();

const accept = computed(() =>
  props.fileTypes
    .map((fileType) => FileType_To_Accept.get(fileType))
    .join(", "),
);

const fileNames = (fieldValue?: File[]) =>
  fieldValue ? fieldValue.map((val) => val.name) : undefined;

const maxFileSizeInBytes = computed(
  () => FileSize_To_Bytes.get(props.maxFileSize) || 0,
);

const preventDefaultBrowserBehaviour = (e: Event) => {
  e.preventDefault();
};

// Maybe type these? Would that even be possible?
const dragDropRelatedEvents = ["dragenter", "dragover", "dragleave", "drop"];

onMounted(() => {
  // Removes browser open file default drag/drop behaviour
  dragDropRelatedEvents.forEach((event) => {
    document.body.addEventListener(event, preventDefaultBrowserBehaviour);
  });
});

onUnmounted(() => {
  // Restores browser open file drag/drop behaviour
  dragDropRelatedEvents.forEach((event) => {
    document.body.removeEventListener(event, preventDefaultBrowserBehaviour);
  });
});

const valueChangedCounter = ref(0);
const updateFiles = async (
  fieldValue: Ref<File[] | undefined>,
  fileList?: FileList | null,
) => {
  if (!fileList || fileList.length === 0) {
    fieldValue.value = undefined;
    return;
  }

  const files = Array.from(fileList);

  if (files.some((f) => f.size > maxFileSizeInBytes.value)) {
    Notify.failure(props.maxFileSizeNotification);

    return;
  }

  fieldValue.value = files;

  // Workaround for https://bugs.chromium.org/p/chromium/issues/detail?id=1086707#c7
  // TLDR: chromium does not allow subsequent file uploads after changing said file.
  //  resetting the field itself did not seem to work as the next picked file (via system file picker)
  //  did not come through, while drag/drop kept working with that approach.
  //  This is why we're using a counter to force a re-render of the component,
  //  which in turn also resets the file input field, because it is set as the inputs key.
  valueChangedCounter.value++;
  await nextTick();
};

const handleDropZoneDropEvent = (
  event: DragEvent,
  fieldValue: Ref<File[] | undefined>,
) => {
  updateFiles(fieldValue, event.dataTransfer?.files || null);
};

const handleChangeEvent = (
  event: Event,
  fieldValue: Ref<File[] | undefined>,
) => {
  updateFiles(fieldValue, (event.target as HTMLInputElement).files);
};

const inputRef = ref<HTMLInputElement | undefined>(undefined);
// Function to trigger the input click programmatically
const triggerFileInput = () => {
  if (inputRef.value) {
    inputRef.value.click();
  } else {
    console.error("Input ref is not set");
  }
};
</script>
