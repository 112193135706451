import {
  FromEmailAddress,
  FromEmailAddressDTO,
  ReplyToEmailAddress,
  ReplyToEmailAddressDTO,
} from "@/models/email-address";
import HttpClient from "@/utils/httpClient";

export const getFromEmailAdresses = async (readyToUse?: boolean) => {
  return await HttpClient.educonfig
    .get<FromEmailAddressDTO[]>("from-email-addresses", {
      params: { readyToUse },
    })
    .then((response) => response.data.map((dto) => new FromEmailAddress(dto)));
};

export const getReplyToEmailAdresses = async () => {
  return await HttpClient.educonfig
    .get<ReplyToEmailAddressDTO[]>("reply-to-email-addresses")
    .then((response) =>
      response.data.map((dto) => new ReplyToEmailAddress(dto)),
    );
};
