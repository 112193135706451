import { EmailTemplateType, FormType, MailingType } from "@/enums";
import { LocalizedFormDTO } from "@/lib/formsServiceClient";
import EmailTemplate from "@/models/emailTemplates";
import MailingTemplate from "@/models/mailing-templates";

export type MailTemplateType = MailingType | EmailTemplateType;

export class MailTemplate {
  id: string;
  name: string;
  type: MailTemplateType;

  constructor({ id, name, type }: MailTemplate) {
    this.id = id;
    this.name = name;
    this.type = type;
  }

  static fromMailingTemplate(mailingTemplate: MailingTemplate) {
    return new MailTemplate({
      id: mailingTemplate.id,
      name: mailingTemplate.name,
      type: mailingTemplate.mailingType,
    });
  }

  static fromEmailTemplate(emailTemplate: EmailTemplate) {
    return new MailTemplate({
      id: emailTemplate.id,
      name: emailTemplate.name,
      type: emailTemplate.type,
    });
  }
}

export class FormTemplate {
  id: string;
  name: string;
  type: FormType;

  constructor({ id, name, type }: FormTemplate) {
    this.id = id;
    this.name = name;
    this.type = type;
  }

  static fromDto(dto: LocalizedFormDTO) {
    return new FormTemplate({
      id: dto.id,
      name: dto.name,
      type: FormType[dto.type as keyof typeof FormType],
    });
  }
}
