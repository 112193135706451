import {
  KeepMeInformedConfigurationDTO,
  KeepMeInformedOverviewDto,
} from "@/lib/eduConfigurationServiceClient";
import { StudyProgramDTO } from "@/models/study-program";
import texts from "@/utils/texts";

export interface KeepMeInformedOverviewItem {
  configuration: KeepMeInformedConfigurationDTO;
  studyProgramId?: string;
  studyProgramDisplayName?: string;
  prospectCount: number;
  isOrphaned: boolean;
}

export const toKeepMeInformedOverview = (
  keepMeInformedConfigurations: KeepMeInformedConfigurationDTO[],
  prospectCountsPerStudyProgram: KeepMeInformedOverviewDto[],
  studyPrograms: StudyProgramDTO[],
): KeepMeInformedOverviewItem[] => {
  const noInterestItems = keepMeInformedConfigurations
    .filter((config) => config.allowNoInterest)
    .map((config) => {
      const count = prospectCountsPerStudyProgram.find(
        (count) => count.formId == config.formId && !count.studyProgramId,
      );

      return {
        configuration: config,
        studyProgramId: undefined,
        studyProgramDisplayName: undefined,
        prospectCount: count?.prospectCount || 0,
      } as KeepMeInformedOverviewItem;
    });

  const studyProgramItems = keepMeInformedConfigurations
    .flatMap((config) =>
      config.studyProgramIds.map((id) => {
        return { configurationId: config.id, studyProgramId: id };
      }),
    )
    .map((cs) => {
      const configuration = keepMeInformedConfigurations.find(
        (config) => config.id == cs.configurationId,
      );
      const studyProgram = studyPrograms.find(
        (studyProgram) => studyProgram.id == cs.studyProgramId,
      );
      const count = prospectCountsPerStudyProgram.find(
        (count) =>
          count.formId == configuration?.formId &&
          count.studyProgramId == studyProgram?.id,
      );
      return {
        configuration: configuration,
        studyProgramId: studyProgram?.id,
        studyProgramDisplayName: studyProgram?.displayName,
        prospectCount: count?.prospectCount || 0,
      } as KeepMeInformedOverviewItem;
    })
    .sort(
      (a, b) =>
        a.configuration.name.localeCompare(b.configuration.name) ||
        (a.studyProgramDisplayName ?? "").localeCompare(
          b.studyProgramDisplayName ?? "",
        ),
    );

  const orphanedItemsWithConfig = prospectCountsPerStudyProgram
    .filter(
      (count) =>
        count.studyProgramId &&
        keepMeInformedConfigurations.find(
          (config) => config.formId == count.formId,
        ) &&
        !keepMeInformedConfigurations.find(
          (config) =>
            config.formId == count.formId &&
            config.studyProgramIds.includes(count.studyProgramId!),
        ),
    )
    .map((count) => {
      const configuration = keepMeInformedConfigurations.find(
        (config) => config.formId == count.formId,
      );
      const studyProgram = studyPrograms.find(
        (studyProgram) => studyProgram.id == count.studyProgramId,
      );

      return {
        configuration: configuration,
        studyProgramId: count.studyProgramId,
        studyProgramDisplayName: studyProgram?.displayName,
        prospectCount: count.prospectCount,
        isOrphaned: true,
      } as KeepMeInformedOverviewItem;
    })
    .sort(
      (a, b) =>
        a.configuration.name.localeCompare(b.configuration.name) ||
        (a.studyProgramDisplayName ?? "").localeCompare(
          b.studyProgramDisplayName ?? "",
        ),
    );

  const orphanedItemsWithoutConfig = prospectCountsPerStudyProgram
    .filter(
      (count) =>
        count.studyProgramId &&
        !keepMeInformedConfigurations.find(
          (config) => config.formId == count.formId,
        ),
    )
    .map((count) => {
      const studyProgram = studyPrograms.find(
        (studyProgram) => studyProgram.id == count.studyProgramId,
      );

      const configuration = {
        name: texts.navigationItems.keepMeInformed.overview.orphanedItems,
      } as KeepMeInformedConfigurationDTO;

      return {
        configuration: configuration,
        studyProgramId: count.studyProgramId,
        studyProgramDisplayName: studyProgram?.displayName,
        prospectCount: count.prospectCount,
        isOrphaned: true,
      } as KeepMeInformedOverviewItem;
    })
    .sort(
      (a, b) =>
        a.configuration.name.localeCompare(b.configuration.name) ||
        (a.studyProgramDisplayName ?? "").localeCompare(
          b.studyProgramDisplayName ?? "",
        ),
    );

  return [
    ...noInterestItems,
    ...studyProgramItems,
    ...orphanedItemsWithConfig,
    ...orphanedItemsWithoutConfig,
  ];
};
