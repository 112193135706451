<template>
  <div class="flex h-full w-full flex-col gap-8">
    <div class="flex flex-col gap-4 lg:flex-row lg:justify-between">
      <PageHeading>{{ activity.name }} </PageHeading>
      <Button
        :label="textsReports.printButton"
        :leadingIcon="'download'"
        :iconColor="Color.White"
        :color="Color.Lavender"
        @click="printHub"
      >
      </Button>
    </div>
    <Loader v-if="loading" :data-testid="testIds.loading"></Loader>
    <ErrorComponent
      v-else-if="error"
      :data-testid="testIds.error"
    ></ErrorComponent>
    <div v-else class="flex flex-col gap-8 print:block print:space-y-8">
      <HubStatsCards :activityId="id" :activityCapacity="activity.capacity" />
      <RegistrationsOverTime
        :activityId="id"
        :startDate="activity.startDateTime"
      />
      <RegistrationsPerLink :activityId="id" />
      <GeoChart :activityId="id" />
      <PostalCodeStats :activityId="id" />
      <DepartmentProspectInterests :activityId="id" />
      <AreasOfInterest :activityId="id" />
      <div class="flex w-full flex-row gap-8 print:block print:space-y-8">
        <StudyProgramModes :activityId="id" />
        <StudyProgramLevels :activityId="id" />
      </div>
      <div
        class="flex w-full flex-col gap-8 sm:flex-row print:block print:space-y-8"
      >
        <ExpectedVisitorsPerLocation
          :class="[
            'w-full',
            { 'sm:w-1/2': actualVisitorsPerLocation.length > 0 },
          ]"
          :stats="expectedVisitorsPerLocation"
        />
        <ActualVisitorsPerLocation
          :class="[
            'w-full',
            { 'sm:w-1/2': expectedVisitorsPerLocation.length > 0 },
          ]"
          :stats="actualVisitorsPerLocation"
        />
      </div>
      <HourlyVisitCounts :activityId="id" />
      <div class="flex w-full flex-row gap-8 print:block print:space-y-8">
        <Top10PreEducationSchools :activityId="id" />
        <PreEducationLevels :activityId="id" />
      </div>
      <MarketingOptInStats :activityId="id" />
      <RemainingStats :activityId="id" />
    </div>
  </div>
</template>
<script setup lang="ts">
import PageHeading from "@/components/common/page-heading/PageHeading.vue";

import { SessionReport } from "@/models/activityReport";
import { getSessionTop10ForActivity } from "@/services/report.service";
import texts from "@/utils/texts";
import Top10PreEducationSchools from "@/views/organize/activities/activity/hub/components/Top10PreEducationSchools.vue";
import { ref } from "vue";
import RegistrationsOverTime from "@/views/organize/activities/activity/hub/components/RegistrationsOverTime.vue";
import { Color } from "@/enums";
import Loader from "@/components/common/loader/Loader.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import { testIds } from "@/utils/testing";
import RegistrationsPerLink from "@/views/organize/activities/activity/hub/components/RegistrationsPerLink.vue";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import ExpectedVisitorsPerLocation from "./components/ExpectedVisitorsPerLocation.vue";
import ActualVisitorsPerLocation from "./components/ActualVisitorsPerLocation.vue";
import GeoChart from "@/views/dashboard/components/GeoChart.vue";
import DepartmentProspectInterests from "./components/DepartmentProspectInterests.vue";
import StudyProgramModes from "@/views/organize/activities/activity/hub/components/StudyProgramModes.vue";
import StudyProgramLevels from "@/views/organize/activities/activity/hub/components/StudyProgramLevels.vue";
import PreEducationLevels from "@/views/organize/activities/activity/hub/components/PreEducationLevels.vue";
import AreasOfInterest from "@/views/organize/activities/activity/hub/components/AreasOfInterest.vue";
import Button from "@/components/common/button/Button.vue";
import HubStatsCards from "./components/HubStatsCards.vue";
import RemainingStats from "@/views/organize/activities/activity/hub/components/RemainingStats.vue";
import MarketingOptInStats from "@/views/organize/activities/activity/hub/components/MarketingOptInStats.vue";
import HourlyVisitCounts from "@/views/organize/activities/activity/hub/components/HourlyVisitCounts.vue";
import PostalCodeStats from "@/views/organize/activities/activity/hub/components/PostalCodeStats.vue";
import { VisitorCountPerLocationDto } from "@/lib/eduConfigurationServiceClient";

const props = defineProps<ActivityRouteProps>();

const textsReports = texts.navigationItems.organize.activity.reports;

const sessionTop10 = ref<SessionReport[]>([]);
const expectedVisitorsPerLocation = ref<VisitorCountPerLocationDto[]>([]);
const actualVisitorsPerLocation = ref<VisitorCountPerLocationDto[]>([]);

const loading = ref(false);
const error = ref(false);

const initialize = async () => {
  try {
    loading.value = true;
    const [top10, expectedPerLocation, actualPerLocation] = await Promise.all([
      getSessionTop10ForActivity(props.id),
      eduConfigurationServiceClient.getExpectedVisitorCountPerLocation(
        props.id,
      ),
      eduConfigurationServiceClient.getActualVisitorCountPerLocation(props.id),
    ]);

    expectedVisitorsPerLocation.value = expectedPerLocation;
    actualVisitorsPerLocation.value = actualPerLocation;
    sessionTop10.value = top10.map(
      (sessionDto) => new SessionReport(sessionDto),
    );
  } catch (e) {
    error.value = true;
    throw e;
  } finally {
    loading.value = false;
  }
};
initialize();

const printHub = () => {
  setTimeout(() => {
    print();
  }, 1000);
};
</script>
