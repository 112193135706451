<template>
  <SlideOver v-model:visible="visibleInternal" :title="componentTexts.title">
    <TargetAudienceForm
      :selectionContext="selectionContext"
      @submit="handleSubmit"
    >
      <ButtonSubmit>
        {{ texts.actions.submit }}
      </ButtonSubmit>
      <ButtonCancel @click="visibleInternal = false">
        {{ texts.actions.cancel }}
      </ButtonCancel>
    </TargetAudienceForm>
  </SlideOver>
</template>

<script setup lang="ts">
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { computed } from "vue";
import texts from "@/utils/texts";
import TargetAudienceForm, {
  TargetAudienceSubmitValues,
} from "@/views/settings/organization/target-audiences/TargetAudienceForm.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import Notify from "@/utils/notify";
import { emptyUuid } from "@/utils/miscellaneous";
import { TargetAudienceDTO } from "@/lib/eduConfigurationServiceClient";
import { CriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";

const componentTexts = texts.navigationItems.organize.targetAudiences.create;

const props = defineProps<{
  visible: boolean;
  selectionContext: CriterionFieldContext;
}>();

const emit = defineEmits<{
  "update:visible": [value: boolean];
  "targetAudiences:stale": [];
}>();

const visibleInternal = computed({
  get() {
    return props.visible;
  },
  set(value) {
    emit("update:visible", value);
  },
});

const handleSubmit = (values: TargetAudienceSubmitValues) => {
  const data = new TargetAudienceDTO({
    // this empty id is overwritten by backend
    id: emptyUuid,
    name: values.name,
    description: values.description,
    selectionDefinition: values.selectionDefinition,
  });

  eduConfigurationServiceClient
    .createTargetAudience(data)
    .then(() => {
      Notify.success(componentTexts.success);
      emit("targetAudiences:stale");

      visibleInternal.value = false;
    })
    .catch(() => {
      Notify.failure(componentTexts.failure);
    });
};
</script>
