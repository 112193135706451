<template>
  <TextInput
    :id="id"
    v-model="modelValueInternal"
    :name="name"
    :type="TextInputType.DATE_TIME"
    :valid="valid"
    :disabled="disabled"
    :min="minDateString"
    :max="maxDateString"
  ></TextInput>
</template>

<script setup lang="ts">
import { computed } from "vue";
import TextInput from "@/components/common/text-input/TextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import { DateTime } from "luxon";

const props = withDefaults(
  defineProps<{
    id?: string;
    name?: string;
    modelValue?: DateTime | undefined;
    valid?: boolean;
    disabled?: boolean;
    min?: DateTime;
    max?: DateTime;
  }>(),
  {
    id: undefined,
    name: undefined,
    modelValue: undefined,
    valid: undefined,
    disabled: false,
    min: undefined,
    max: undefined,
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: DateTime | undefined): void;
}>();

const modelValueInternal = computed<string | undefined>({
  get: () => props.modelValue?.toFormat("yyyy-MM-dd'T'HH:mm"),
  set: (value) => {
    if (!value) {
      emit("update:modelValue", undefined);
      return;
    }

    const dateTime = DateTime.fromFormat(value, "yyyy-MM-dd'T'HH:mm");

    if (dateTime.isValid) {
      emit("update:modelValue", dateTime);
    }

    return;
  },
});

const minDateString = computed(() => {
  return props.min ? (props.min.toISO() ?? undefined) : undefined;
});

const maxDateString = computed(() => {
  return props.max ? (props.max.toISO() ?? undefined) : undefined;
});

// Check if the provided props are meaningful
if (props.max && props.min && props?.max < props.min) {
  throw new Error("max value can't be smaller than min value");
}
</script>
